import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import { Button, FormGroup, Row } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { setLoading } from '../../../../store/actions/loadingActions';
import { validateForm } from '../../../../helpers/formValidator';
import { editDevice } from '../../../../services/api/devices';
import history from '../../../../services/history';
import Input from '../../../../components/inputForm/inputText';
import Select from '../../../../components/inputForm/inputSelect';

MyForm.propTypes = {
    companies: PropTypes.array,
    usersOptions: PropTypes.array,
    currentUser: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    schema: PropTypes.object.isRequired,
    userFields: PropTypes.array.isRequired,
    addField: PropTypes.func.isRequired,
    removeField: PropTypes.func.isRequired,
    texts: PropTypes.shape({
        serialNumberText: PropTypes.string.isRequired,
        brandText: PropTypes.string.isRequired,
        modelText: PropTypes.string.isRequired,
        actLumusText: PropTypes.string.isRequired,
        companyText: PropTypes.string.isRequired,
        purchaseDateText: PropTypes.string.isRequired,
        eraseDeviceMemoryText: PropTypes.string.isRequired,
        yesText: PropTypes.string.isRequired,
        noText: PropTypes.string.isRequired,
        successText: PropTypes.string.isRequired,
        saveText: PropTypes.string.isRequired,
        cancelText: PropTypes.string.isRequired,
        addText: PropTypes.string.isRequired,
        removeText: PropTypes.string.isRequired,
        mainPatientText: PropTypes.string.isRequired,
        patientText: PropTypes.string.isRequired,
    }).isRequired,
};

export function MyForm({
    companies,
    usersOptions,
    currentUser,
    device,
    schema,
    userFields,
    addField,
    removeField,
    texts,
}) {
    const formRef = useRef(null);

    const dispatch = useDispatch();

    async function handleSubmit(data) {
        const { parsedData, isValid, errors } = await validateForm(
            data,
            schema
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            delete parsedData['user_name'];

            parsedData.shared_users = parsedData?.shared_users
                ? parsedData.shared_users.map((user) => {
                      const userInfo = usersOptions.find(
                          (userOption) => userOption.value === user.user_id
                      );

                      return {
                          user_id: userInfo.value,
                          user_name: userInfo.label,
                      };
                  })
                : [];

            dispatch(setLoading(true));

            const response = await editDevice(device.id, parsedData);

            dispatch(setLoading(false));

            if (response) {
                toast.success(texts.successText);

                history.push('/devices');
            }
        }
    }

    useEffect(() => {
        formRef.current.reset();

        formRef.current.setData({
            serial_no: device.serial_no,
            brand: device.brand,
            model: {
                label: device.model,
                value: device.model,
            },
            company_id:
                device.company_id !== null
                    ? {
                          label: device.company_name,
                          value: device.company_id,
                      }
                    : {},
            purchased_at: device.purchased_at?.slice(0, 10),
            erase_device_memory: {
                label: device.erase_device_memory
                    ? texts.yesText
                    : texts.noText,
                value: device.erase_device_memory,
            },
            user_name: device?.user_name,
            shared_users: device.shared_users.map((user) => ({
                user_id: {
                    label: user.user_name,
                    value: user.user_id,
                },
            })),
        });
    }, []);

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <Row>
                <FormGroup className="col-xs-12 col-md-2">
                    <Input
                        name="serial_no"
                        type="number"
                        label={`${texts.serialNumberText} <b style="color: darkred">*</b>`}
                        readOnly={currentUser.permissions !== 'condor_admin'}
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-2">
                    <Select
                        name="model"
                        label={`${texts.modelText} <b style="color: darkred">*</b>`}
                        options={[
                            {
                                value: texts.actLumusText,
                                label: texts.actLumusText,
                            },
                        ]}
                        isDisabled={currentUser.permissions !== 'condor_admin'}
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-5">
                    <Select
                        name="company_id"
                        label={texts.companyText}
                        options={companies.map((company) => {
                            return {
                                value: company.id,
                                label: company.name,
                            };
                        })}
                        isDisabled={currentUser.permissions !== 'condor_admin'}
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-2">
                    <Input
                        name="purchased_at"
                        type="date"
                        label={texts.purchaseDateText}
                        readOnly={currentUser.permissions !== 'condor_admin'}
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-5">
                    <Select
                        name="erase_device_memory"
                        label={texts.eraseDeviceMemoryText}
                        options={[
                            {
                                value: true,
                                label: texts.yesText,
                            },
                            {
                                value: false,
                                label: texts.noText,
                            },
                        ]}
                    />
                </FormGroup>
            </Row>

            {currentUser.permissions !== 'condor_admin' && device.user_id && (
                <>
                    <Row>
                        <FormGroup className="col-xs-12 col-md-5">
                            <Input
                                name="user_name"
                                label={texts.mainPatientText}
                                readOnly
                            />
                        </FormGroup>
                    </Row>

                    {userFields.map((userUUID, index) => (
                        <React.Fragment key={userUUID}>
                            <Row>
                                <Scope path={`shared_users[${index}]`}>
                                    <FormGroup className="col-xs-12 col-md-5">
                                        <Select
                                            name="user_id"
                                            label={texts.patientText}
                                            options={usersOptions}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <div className="col-xs-12 col-md-12 text-left d-flex align-items-end h-100">
                                            <Button
                                                color="danger"
                                                type="button"
                                                onClick={() =>
                                                    removeField(index)
                                                }
                                                title={texts.removeText}
                                            >
                                                <FaTrash />
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </Scope>
                            </Row>
                        </React.Fragment>
                    ))}

                    <FormGroup row>
                        <div className="col-xs-12 col-md-12 text-left">
                            <Button
                                color="primary"
                                type="button"
                                onClick={addField}
                                title={texts.addText}
                            >
                                {texts.addText}
                            </Button>
                        </div>
                    </FormGroup>
                </>
            )}

            <Row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button color="primary" type="submit" className="mr-2">
                        {texts.saveText}
                    </Button>

                    <Link to="/devices" className="btn btn-secondary">
                        {texts.cancelText}
                    </Link>
                </div>
            </Row>
        </Form>
    );
}

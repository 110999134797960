import React from 'react';
import PropTypes from 'prop-types';

import { TitleSection } from './titleSection';
import { PageNumber } from './pageNumber';
import { Chart } from './chart';

export function WeeklyEvolution({ data, textData, pageNumbers, weeksByChart }) {
    const { charts } = data;
    const { titleSectionText } = textData;

    return charts.map((chart, index) => {
        let divisionParam;

        switch (true) {
            case weeksByChart[index] >= 17:
                divisionParam = 21;
                break;
            case weeksByChart[index] >= 12:
                divisionParam = 19;
                break;
            default:
                divisionParam = 16;
                break;
        }

        return (
            <div key={index} className="pagePrint">
                <div className="rowPrint">
                    {index === 0 ? (
                        <TitleSection title={titleSectionText} />
                    ) : (
                        <></>
                    )}
                    <Chart
                        data={chart.data}
                        layout={chart.layout}
                        config={chart.config}
                        height={chart.layout.height / divisionParam}
                    />
                </div>

                <PageNumber number={pageNumbers[index]} />
            </div>
        );
    });
}

WeeklyEvolution.propTypes = {
    data: PropTypes.object.isRequired,
    textData: PropTypes.object.isRequired,
    pageNumbers: PropTypes.array.isRequired,
    weeksByChart: PropTypes.array.isRequired,
};

import React, { useMemo } from 'react';
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import useWindowSize from '../../../../../../../hooks/useWindowSize';

const useOptions = () => {
    const options = useMemo(
        () => ({
            style: {
                base: {
                    color: '#495057',
                    letterSpacing: '0.05rem',
                    fontFamily: 'Poppins, sans-serif',
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        }),
        []
    );

    return options;
};

StripeForm.propTypes = {
    setCardNumberComplete: PropTypes.func.isRequired,
    setCardExpiryComplete: PropTypes.func.isRequired,
    setCardCvcComplete: PropTypes.func.isRequired,
};

export function StripeForm({
    setCardNumberComplete,
    setCardExpiryComplete,
    setCardCvcComplete,
}) {
    const options = useOptions();

    return (
        <>
            <div className="d-flex">
                <CardNumberElement
                    className="StripeElement w-100"
                    options={options}
                    onChange={(event) => {
                        setCardNumberComplete(event.complete);
                    }}
                />
            </div>

            <div className="d-flex">
                <CardExpiryElement
                    className="StripeElement w-50"
                    options={options}
                    onChange={(event) => {
                        setCardExpiryComplete(event.complete);
                    }}
                />

                <CardCvcElement
                    className="StripeElement w-50"
                    options={options}
                    onChange={(event) => {
                        setCardCvcComplete(event.complete);
                    }}
                />
            </div>
        </>
    );
}

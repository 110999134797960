import { combineReducers } from 'redux';

import auth from '../reducers/authReducer';
import loading from '../reducers/loadingReducer';
import lang from '../reducers/langReducer';
import locale from '../reducers/localeReducer';
import report from '../reducers/reportReducer';

const rootReducer = combineReducers({
    auth,
    loading,
    lang,
    locale,
    report,
});

export default rootReducer;

import Input from '../../../components/inputForm/inputText';

export default {
    pt: {
        fields: [
            {
                component: Input,
                props: {
                    name: 'password',
                    type: 'password',
                    label: 'Senha',
                },
                className: 'col-xs-12 col-md-12',
            },
            {
                component: Input,
                props: {
                    name: 'confirm_password',
                    type: 'password',
                    label: 'Confirmação de senha',
                },
                className: 'col-xs-12 col-md-12',
            },
        ],
        pageTitle: 'Redefinir senha',
        passwordLabel: 'Senha',
        confirmPasswordLabel: 'Confirmação de senha',
        resetButtonText: 'Redefinir',
        resetButtonLoadingText: 'Aguarde...',
        passwordResetSuccessText: 'Senha alterada com sucesso!',
    },
    en: {
        fields: [
            {
                component: Input,
                props: {
                    name: 'password',
                    type: 'password',
                    label: 'Password',
                },
                className: 'col-xs-12 col-md-12',
            },
            {
                component: Input,
                props: {
                    name: 'confirm_password',
                    type: 'password',
                    label: 'Confirmation of new password',
                },
                className: 'col-xs-12 col-md-12',
            },
        ],
        pageTitle: 'Reset password',
        passwordLabel: 'Password',
        confirmPasswordLabel: 'Confirmation of new password',
        resetButtonText: 'Reset',
        resetButtonLoadingText: 'Loading...',
        passwordResetSuccessText: 'Password successfully changed!',
    },
};

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Plot from '../../../../components/plot';
import { getMinValue } from '../../../../helpers/getMinValue';
import { getMaxValue } from '../../../../helpers/getMaxValue';

CapacitiveSensorsChart.propTypes = {
    width: PropTypes.number.isRequired,
    chartData: PropTypes.shape({
        log_time: PropTypes.arrayOf(PropTypes.string).isRequired,
        cap_sensor_1: PropTypes.arrayOf(PropTypes.number).isRequired,
        cap_sensor_2: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    textData: PropTypes.object.isRequired,
};

export function CapacitiveSensorsChart({ width, chartData, textData }) {
    const lang = useSelector((state) => state.lang.lang);

    const minRange = getMinValue(chartData.log_time);

    const maxRange = getMaxValue(chartData.log_time);

    const data = [
        {
            x: chartData.log_time,
            y: chartData.cap_sensor_1,
            mode: 'lines',
            type: 'scatter',
            name: textData.capacitiveTexts[0].name,
            line: {
                shape: 'linear',
                color: textData.capacitiveTexts[0].color,
                width: 2,
            },
        },
        {
            x: chartData.log_time,
            y: chartData.cap_sensor_2,
            mode: 'lines',
            type: 'scatter',
            name: textData.capacitiveTexts[1].name,
            line: {
                shape: 'linear',
                color: textData.capacitiveTexts[1].color,
                width: 2,
            },
        },
    ];

    const layout = useMemo(() => {
        return {
            margin: {
                t: 0,
                b: 0,
                l: 50,
                r: 10,
            },
            font: { family: 'Raleway, sans-serif' },
            xaxis: {
                type: 'date',
                autorange: true,
                range: [minRange, maxRange],
                fixedrange: true,
                rangeselector: {
                    buttons: [
                        {
                            count: 1,
                            label: textData.oneDayText,
                            step: 'day',
                            stepmode: 'backward',
                        },
                        { step: 'all' },
                    ],
                },
                rangeslider: { range: [minRange, maxRange] },
                visible: true,
                showgrid: false,
                title: {
                    text: textData.hoursText,
                },
            },
            yaxis: {
                tickmode: 'auto',
                type: 'linear',
                autorange: true,
                fixedrange: true,
                visible: true,
                showgrid: true,
                gridcolor: '#cccccc',
                gridwidth: 1,
                zerolinecolor: '#cccccc',
            },
            showlegend: false,
            height: 200,
            width: width - (20 * width) / 100,
            hoverinfo: 'y unified',
        };
    }, [width, lang]);

    const config = {
        responsive: true,
        editable: false,
        scrollzoom: false,
        showAxisDragHandles: false,
        displayModeBar: false,
    };

    return <Plot data={data} layout={layout} config={config} />;
}

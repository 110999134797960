import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, Card, CardBody, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';

import '../../../styles/pages/account/passwordReset/passwordReset.css';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import FormFields from '../../../components/form/formFields';
import { setLoading } from '../../../store/actions/loadingActions';
import { validateForm } from '../../../helpers/formValidator';
import { sendPasswordReset } from '../../../services/api/account';
import history from '../../../services/history';

export default function PasswordReset() {
    const formRef = useRef(null);

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.loading.isLoading);
    const lang = useSelector((state) => state.lang.lang);

    const { token } = useParams();

    const pageData = pageConfig[lang];

    const { schema } = schemaConfig[lang];

    async function handleSubmit(data) {
        const { parsedData, isValid, errors } = await validateForm(
            data,
            schema
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(setLoading(true));

            const response = await sendPasswordReset(token, {
                password: parsedData.password,
            });

            dispatch(setLoading(false));

            if (response) {
                toast.success(pageData.passwordResetSuccessText);

                history.push('/login');
            }
        }
    }

    return (
        <div className="passwordResetContainer">
            <div className="shadow rounded passwordResetBox">
                <Card>
                    <CardBody>
                        <Form ref={formRef} onSubmit={handleSubmit}>
                            <FormGroup row>
                                <FormGroup className="col-md-12 text-center">
                                    <h2>
                                        <b>{pageData.pageTitle}</b>
                                    </h2>
                                </FormGroup>
                            </FormGroup>
                            <FormGroup row>
                                <FormFields fields={pageData.fields} />
                            </FormGroup>
                            <FormGroup row>
                                <div className="col-md-12 text-center">
                                    <Button
                                        color="primary"
                                        type="submit"
                                        disabled={loading}
                                        className="mr-2"
                                    >
                                        {loading
                                            ? pageData.resetButtonLoadingText
                                            : pageData.resetButtonText}
                                    </Button>
                                </div>
                            </FormGroup>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

export function validateUserPermissions({ currentUser, roles }) {
    if (roles?.length > 0) {
        const hasAllRoles = roles.some((role) => {
            return currentUser.permissions.includes(role);
        });

        if (!hasAllRoles) {
            return false;
        }
    }

    return true;
}

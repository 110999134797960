import produce from 'immer';
import { ptBR } from 'date-fns/locale';

import { LOCALE } from '../constants';

const INITIAL_STATE = {
    dateLocale: ptBR,
    currencyLocale: 'pt-BR',
    currencyFormat: 'BRL',
};

export default function locale(state = INITIAL_STATE, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case LOCALE.CHANGE_LOCALE_REQUEST: {
                break;
            }
            case LOCALE.CHANGE_LOCALE: {
                draft.dateLocale = action.payload.dateLocale;
                draft.currencyLocale = action.payload.currencyLocale;
                draft.currencyFormat = action.payload.currencyFormat;
                break;
            }
        }
    });
}

export default {
    pt: {
        customer_admin: {
            warningText: `Sua empresa atingiu o número máximo de pacientes ativos do plano!.
            Para conseguir adicionar mais pacientes, inative pacientes ou
            faça o upgrade do plano.`,
            nameLabelText: 'Nome',
            namePlaceholderText: 'Digite o nome do paciente...',
            secondaryIdLabelText: 'ID Secundário',
            secondaryIdPlaceholderText: 'Digite o id secundário do paciente...',
            emailLabelText: 'e-Mail',
            emailPlaceholderText: 'Digite o e-Mail do paciente...',
            userTypeLabelText: 'Tipo de usuário',
            activeLabelText: 'Ativo',
            trialStartLabelText: 'Início',
            trialEndLabelText: 'Fim',
            diaryIdLabelText: 'Diário',
            languageLabelText: 'Idioma',
            patientText: 'Paciente',
            yesText: 'Sim',
            noText: 'Não',
            defaultDiaryText: 'Diário padrão',
            portugueseText: 'Português',
            englishText: 'Inglês',
            germanyText: 'Alemão',
            sleepDiarySubscriptionFieldsText: 'Configurações do Diário de Sono',
            lumusSubscriptionFieldsText: 'Configurações do ActLumus',
            devicesText: 'Dispositivos',
            addDeviceText: 'Adicionar dispositivo',
            serialNumberText: 'Número de série',
            bodyPartText: 'Parte do corpo',
            wristText: 'Pulso',
            necklaceText: 'Colar',
            receivedAtText: 'Data de recebimento',
            deleteDeviceText: 'Excluir dispositivo',
            addText: 'Adicionar',
            cancelText: 'Cancelar',
            requiredFieldText: 'Campos obrigatórios!',
            successText: 'Paciente adicionado com sucesso!',
        },
        manager: {
            warningText: `Sua empresa atingiu o número máximo de pacientes ativos do plano!.
            Para conseguir adicionar mais pacientes, inative pacientes ou
            faça o upgrade do plano.`,
            nameLabelText: 'Nome',
            namePlaceholderText: 'Digite o nome do paciente...',
            secondaryIdLabelText: 'ID Secundário',
            secondaryIdPlaceholderText: 'Digite o id secundário do paciente...',
            emailLabelText: 'e-Mail',
            emailPlaceholderText: 'Digite o e-Mail do paciente...',
            userTypeLabelText: 'Tipo de usuário',
            activeLabelText: 'Ativo',
            trialStartLabelText: 'Início',
            trialEndLabelText: 'Fim',
            diaryIdLabelText: 'Diário',
            languageLabelText: 'Idioma',
            patientText: 'Paciente',
            yesText: 'Sim',
            noText: 'Não',
            defaultDiaryText: 'Diário padrão',
            portugueseText: 'Português',
            englishText: 'Inglês',
            germanyText: 'Alemão',
            sleepDiarySubscriptionFieldsText: 'Configurações do Diário de Sono',
            lumusSubscriptionFieldsText: 'Configurações do ActLumus',
            devicesText: 'Dispositivos',
            addDeviceText: 'Adicionar dispositivo',
            serialNumberText: 'Número de série',
            bodyPartText: 'Parte do corpo',
            wristText: 'Pulso',
            necklaceText: 'Colar',
            receivedAtText: 'Data de recebimento',
            deleteDeviceText: 'Excluir dispositivo',
            addText: 'Adicionar',
            cancelText: 'Cancelar',
            requiredFieldText: 'Campos obrigatórios!',
            successText: 'Paciente adicionado com sucesso!',
        },
        condor_admin: {
            companyLabelText: 'Empresa',
            nameLabelText: 'Nome',
            namePlaceholderText: 'Digite o nome do usuário...',
            secondaryIdLabelText: 'ID Secundário',
            secondaryIdPlaceholderText: 'Digite o id secundário do usuário...',
            emailLabelText: 'e-Mail',
            emailPlaceholderText: 'Digite o e-Mail do usuário...',
            userTypeLabelText: 'Tipo de usuário',
            activeLabelText: 'Ativo',
            languageLabelText: 'Idioma',
            managerText: 'Gestor',
            condorAdminText: 'Administrador Condor',
            yesText: 'Sim',
            noText: 'Não',
            portugueseText: 'Português',
            englishText: 'Inglês',
            germanyText: 'Alemão',
            successText: 'Usuário adicionado com sucesso!',
            addText: 'Adicionar',
            cancelText: 'Cancelar',
            requiredFieldText: 'Campos obrigatórios!',
        },
    },
    en: {
        customer_admin: {
            warningText: `Your company has reached the maximum number of active patients allowed in your plan!
            To be able to add more patients, inactivate patients or
            upgrade your plan!`,
            nameLabelText: 'Name',
            namePlaceholderText: `Enter the patient's name...`,
            secondaryIdLabelText: 'Secondary ID',
            secondaryIdPlaceholderText: `Enter the patient's secondary id...`,
            emailLabelText: 'e-Mail',
            emailPlaceholderText: `Enter the patient's e-Mail...`,
            userTypeLabelText: 'User type',
            activeLabelText: 'Active',
            trialStartLabelText: 'Start',
            trialEndLabelText: 'End',
            diaryIdLabelText: 'Diary',
            languageLabelText: 'Language',
            patientText: 'Patient',
            yesText: 'Yes',
            noText: 'No',
            defaultDiaryText: 'Default diary',
            portugueseText: 'Portuguese',
            englishText: 'English',
            germanyText: 'German',
            sleepDiarySubscriptionFieldsText: 'Sleep Diary Settings',
            lumusSubscriptionFieldsText: 'ActLumus Settings',
            devicesText: 'Devices',
            addDeviceText: 'Add device',
            serialNumberText: 'Serial number',
            bodyPartText: 'Body part',
            wristText: 'Wrist',
            necklaceText: 'Necklace',
            receivedAtText: 'Received at',
            deleteDeviceText: 'Delete device',
            addText: 'Add',
            cancelText: 'Cancel',
            requiredFieldText: 'Required fields!',
            successText: 'Patient successfully added!',
        },
        manager: {
            warningText: `Your company has reached the maximum number of active patients allowed in your plan!
            To be able to add more patients, inactivate patients or
            upgrade your plan!`,
            nameLabelText: 'Name',
            namePlaceholderText: `Enter the patient's name...`,
            secondaryIdLabelText: 'Secondary ID',
            secondaryIdPlaceholderText: `Enter the patient's secondary id...`,
            emailLabelText: 'e-Mail',
            emailPlaceholderText: `Enter the patient's e-Mail...`,
            userTypeLabelText: 'User type',
            activeLabelText: 'Active',
            trialStartLabelText: 'Start',
            trialEndLabelText: 'End',
            diaryIdLabelText: 'Diary',
            languageLabelText: 'Language',
            patientText: 'Patient',
            yesText: 'Yes',
            noText: 'No',
            defaultDiaryText: 'Default diary',
            portugueseText: 'Portuguese',
            englishText: 'English',
            germanyText: 'German',
            sleepDiarySubscriptionFieldsText: 'Sleep Diary Settings',
            lumusSubscriptionFieldsText: 'ActLumus Settings',
            devicesText: 'Devices',
            addDeviceText: 'Add device',
            serialNumberText: 'Serial number',
            bodyPartText: 'Body part',
            wristText: 'Wrist',
            necklaceText: 'Necklace',
            receivedAtText: 'Received at',
            deleteDeviceText: 'Delete device',
            addText: 'Add',
            cancelText: 'Cancel',
            requiredFieldText: 'Required fields!',
            successText: 'Patient successfully added!',
        },
        condor_admin: {
            companyLabelText: 'Company',
            nameLabelText: 'Name',
            namePlaceholderText: `Enter the user's name...`,
            secondaryIdLabelText: 'Secondary ID',
            secondaryIdPlaceholderText: `Enter the user's secondary id...`,
            emailLabelText: 'e-Mail',
            emailPlaceholderText: `Enter the user's e-Mail...`,
            userTypeLabelText: 'User type',
            activeLabelText: 'Active',
            languageLabelText: 'Language',
            managerText: 'Manager',
            condorAdminText: 'Condor Admin',
            yesText: 'Yes',
            noText: 'No',
            portugueseText: 'Portuguese',
            englishText: 'English',
            germanyText: 'German',
            successText: 'User successfully added!',
            addText: 'Add',
            cancelText: 'Cancel',
            requiredFieldText: 'Required fields!',
        },
    },
};

import { useState, useCallback } from 'react';

export function useOrderByProperty(
    data,
    defaultProperty,
    defaultOrder = 'asc'
) {
    const [filteredData, setFilteredData] = useState([...data]);
    const [activeFilter, setActiveFilter] = useState({
        property: defaultProperty,
        order: defaultOrder,
    });

    const orderByProperty = useCallback(
        (filterProperty, order) => {
            if (
                filterProperty !== activeFilter.property ||
                order !== activeFilter.order
            ) {
                setActiveFilter({ property: filterProperty, order });

                let tempFilteredData = [...filteredData].sort((a, b) => {
                    if (typeof a[filterProperty] === 'string') {
                        let tempAProperty = a[filterProperty]
                            ? a[filterProperty]
                            : '';
                        let tempBProperty = b[filterProperty]
                            ? b[filterProperty]
                            : '';

                        if (
                            tempAProperty.toUpperCase().trim() >
                            tempBProperty.toUpperCase().trim()
                        ) {
                            return order === 'asc' ? 1 : -1;
                        }

                        if (
                            tempAProperty.toUpperCase().trim() <
                            tempBProperty.toUpperCase().trim()
                        ) {
                            return order === 'asc' ? -1 : 1;
                        }
                    } else {
                        if (a[filterProperty] > b[filterProperty]) {
                            return order === 'asc' ? 1 : -1;
                        }

                        if (a[filterProperty] < b[filterProperty]) {
                            return order === 'asc' ? -1 : 1;
                        }
                    }

                    return 0;
                });

                setFilteredData(tempFilteredData);
            }
        },
        [activeFilter, filteredData]
    );

    return {
        activeFilter,
        filteredData,
        setFilteredData,
        orderByProperty,
    };
}

import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            searchByNameInput: 'Procurar empresa por nome',
            addButtonLabel: 'Adicionar',
            addButtonTitle: 'Adicionar empresa',
            ths: [
                {
                    name: 'ID',
                    property: 'id',
                },
                {
                    name: 'Nome',
                    property: 'name',
                },
                {
                    name: 'Compartilha pacientes',
                    property: 'share',
                },
                {
                    name: 'Salva e-Mail de pacientes',
                    property: 'saveEmail',
                },
                {
                    name: 'Ações',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'ID',
                    property: 'id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Nome',
                    property: 'name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Compartilha pacientes',
                    property: 'share',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Salva e-Mail de pacientes',
                    property: 'saveEmail',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Ações',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Assinatura',
                    className: 'btn btn-info',
                    to: 'companies/subscription',
                    icon: faUserCircle,
                },
                {
                    title: 'Editar',
                    className: 'btn btn-secondary ml-2',
                    to: 'companies/edit',
                    icon: faEdit,
                },
                {
                    title: 'Excluir',
                    className: 'btn btn-danger ml-2',
                    to: 'companies/delete',
                    icon: faTrash,
                },
            ],
            shareText: 'Sim',
            dosentShareText: 'Não',
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            searchByNameInput: 'Search company by name',
            addButtonLabel: 'Add',
            addButtonTitle: 'Add company',
            ths: [
                {
                    name: 'ID',
                    property: 'id',
                },
                {
                    name: 'Name',
                    property: 'name',
                },
                {
                    name: 'Share patients',
                    property: 'share',
                },
                {
                    name: 'Save patient e-Mails',
                    property: 'saveEmail',
                },
                {
                    name: 'Actions',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'ID',
                    property: 'id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Name',
                    property: 'name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Share',
                    property: 'share',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Save patient e-Mails',
                    property: 'saveEmail',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Actions',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Subscription',
                    className: 'btn btn-info',
                    to: 'companies/subscription',
                    icon: faUserCircle,
                },
                {
                    title: 'Edit',
                    className: 'btn btn-secondary ml-2',
                    to: 'companies/edit',
                    icon: faEdit,
                },
                {
                    title: 'Delete',
                    className: 'btn btn-danger ml-2',
                    to: 'companies/delete',
                    icon: faTrash,
                },
            ],
            shareText: 'Yes',
            dosentShareText: 'No',
        },
    },
};

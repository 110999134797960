import Input from '../../../../components/inputForm/inputText';

export default {
    pt: {
        customer_admin: null,
        manager: {
            iuguTexts: {
                imgAltTexts: {
                    cardTypes: 'Visa, Master, Diners. Amex',
                    paymentByIugu: 'Pagamentos por Iugu',
                },
                numberErrorText: 'O número do cartão é inválido.',
                cvvErrorText: 'O código de segurança do cartão está incorreto.',
                fullNameErrorText: 'O nome do titular do cartão é inválido.',
                expirationErrorText: 'A data de validade é inválida.',
                defaultErrorText: 'Erro no cartão.',
            },
            successText: 'Fatura paga com sucesso!',
            pendingInvoiceText: 'Fatura pendente',
            planText: 'Plano',
            dueDateText: 'Data de vencimento',
            amountText: 'Valor',
            cardDataText: 'Dados do cartão',
            cardDescriptionText: 'Descrição do cartão',
            cardNumberText: 'Número do cartão',
            expirationDateText: 'Data de validade',
            addNewCardText: 'Adicionar novo cartão',
            requiredCardDescriptionText:
                'Descrição do cartão <b style="color: darkred">*</b>',
            new_card_info_fields: {
                number: {
                    component: Input,
                    props: {
                        name: 'number',
                        type: 'text',
                        'data-iugu': 'number',
                        placeholder: 'Número do cartão',
                        className: 'credit_card_number',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                verification_value: {
                    component: Input,
                    props: {
                        name: 'verification_value',
                        type: 'text',
                        'data-iugu': 'verification_value',
                        placeholder: 'CVV',
                        className: 'credit_card_cvv',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                full_name: {
                    component: Input,
                    props: {
                        name: 'full_name',
                        type: 'text',
                        'data-iugu': 'full_name',
                        placeholder: 'Titular do cartão',
                        className: 'credit_card_name',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                expiration: {
                    component: Input,
                    props: {
                        name: 'expiration',
                        type: 'text',
                        'data-iugu': 'expiration',
                        placeholder: 'MM/AA',
                        className: 'credit_card_expiration',
                    },
                    className: 'col-xs-12 col-md-3',
                },
            },
            cardText: 'Cartão <b style="color: darkred">*</b>',
            deleteCardText: 'Excluir cartão',
            confirmText: 'Confirmar',
            goBackText: 'Voltar',
            requiredFieldsText:
                '<b style="color: darkred">*</b> Campos obrigatórios!',
            unableToUpdateCardText: 'Não foi possível atualizar o cartão!',
            unableToPayInvoiceText: 'Não foi possível pagar a fatura!',
            creditCardLabelText:
                'Dados do cartão <b style="color: darkred">*</b>',
            stripeTexts: {
                expired_card: 'Seu cartão expirou!',
                incorrect_cvc:
                    'O código de segurança do seu cartão está incorreto!',
                processing_error:
                    'Ocorreu um erro ao processar seu cartão. Tente novamente em instantes.',
                incorrect_number: 'O número do seu cartão é inválido.',
                card_declined: 'Seu cartão foi recusado.',
                setup_intent_authentication_failure: `Não conseguimos autenticar o seu método de pagamento.
                Escolha um método de pagamento diferente e tente novamente.`,
            },
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            iuguTexts: {
                imgAltTexts: {
                    cardTypes: 'Visa, Master, Diners. Amex',
                    paymentByIugu: 'Payments by Iugu',
                },
                numberErrorText: 'Card number is invalid!',
                cvvErrorText: 'Security code is incorrect!',
                fullNameErrorText: 'Cardholder is incorrect!',
                expirationErrorText: 'Invalid expiry date!',
                defaultErrorText: 'Card error!',
            },
            successText: 'Invoice successfully paid!',
            pendingInvoiceText: 'Pending invoice',
            planText: 'Plan',
            dueDateText: 'Due date',
            amountText: 'Amount',
            cardDataText: 'Card data',
            cardDescriptionText: 'Card description',
            cardNumberText: 'Card number',
            expirationDateText: 'Expiration date',
            addNewCardText: 'Add new card',
            requiredCardDescriptionText:
                'Card description <b style="color: darkred">*</b>',
            new_card_info_fields: {
                number: {
                    component: Input,
                    props: {
                        name: 'number',
                        type: 'text',
                        'data-iugu': 'number',
                        placeholder: 'Card number',
                        className: 'credit_card_number',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                verification_value: {
                    component: Input,
                    props: {
                        name: 'verification_value',
                        type: 'text',
                        'data-iugu': 'verification_value',
                        placeholder: 'CVV',
                        className: 'credit_card_cvv',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                full_name: {
                    component: Input,
                    props: {
                        name: 'full_name',
                        type: 'text',
                        'data-iugu': 'full_name',
                        placeholder: 'Cardholder',
                        className: 'credit_card_name',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                expiration: {
                    component: Input,
                    props: {
                        name: 'expiration',
                        type: 'text',
                        'data-iugu': 'expiration',
                        placeholder: 'MM/YY',
                        className: 'credit_card_expiration',
                    },
                    className: 'col-xs-12 col-md-3',
                },
            },
            cardText: 'Card <b style="color: darkred">*</b>',
            deleteCardText: 'Delete card',
            confirmText: 'Confirm',
            goBackText: 'Return',
            requiredFieldsText:
                '<b style="color: darkred">*</b> Required fields!',
            unableToUpdateCardText: 'It was not possible to update the card!',
            unableToPayInvoiceText: 'It was not possible to pay the invoice!',
            creditCardLabelText: 'Card data <b style="color: darkred">*</b>',
            stripeTexts: {
                expired_card: 'Your card has expired!',
                incorrect_cvc: "Your card's security code is incorrect!",
                processing_error:
                    'There was an error processing your card. Please try again in a moment.',
                incorrect_number: 'Your card number is invalid.',
                card_declined: 'Your card has been declined.',
                setup_intent_authentication_failure: `We are unable to authenticate your payment method.
                Please choose a different payment method and try again.`,
            },
        },
        condor_admin: null,
    },
};

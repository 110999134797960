import React from 'react';
import { FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';

export function FormFields({ fields, handlePercentOrAmount }) {
    return fields.map((field) => {
        switch (field.props.name) {
            case 'is_percent':
                return (
                    <FormGroup
                        key={field.props.name}
                        className={field.className}
                    >
                        <field.component
                            {...field.props}
                            onChange={(e) => handlePercentOrAmount(e?.value)}
                        />
                    </FormGroup>
                );
            default:
                return (
                    <FormGroup
                        key={field.props.name}
                        className={field.className}
                    >
                        <field.component {...field.props} />
                    </FormGroup>
                );
        }
    });
}

FormFields.propTypes = {
    fields: PropTypes.array.isRequired,
    handlePercentOrAmount: PropTypes.func,
};

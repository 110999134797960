import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import { setLoading } from '../../store/actions/loadingActions';
import { fetchPlans } from '../../services/api/plans';
import { useOrderByProperty } from '../../hooks/useOrderByProperty';
import { SearchAndAddContainer } from './components/searchAndAddContainer';
import { TableContainer } from './components/tableContainer';

export default function Plans({ currentUser }) {
    const [plans, setPlans] = useState([]);
    const { activeFilter, filteredData, setFilteredData, orderByProperty } =
        useOrderByProperty(plans, '', 'asc');

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);
    const { dateLocale } = useSelector((state) => state.locale);
    const isLoading = useSelector((state) => state.loading.isLoading);

    const pageData = pageConfig[lang][currentUser.permissions];

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            const data = await fetchPlans();

            setPlans(data?.plans);

            dispatch(setLoading(false));
        })();
    }, []);

    if (isLoading || pageData === null) {
        return <></>;
    }

    return (
        <>
            <SearchAndAddContainer
                plans={plans}
                setFilteredPlans={setFilteredData}
                searchByNameInput={pageData.searchByNameInput}
                addButtonLabel={pageData.addButtonLabel}
                addButtonTitle={pageData.addButtonTitle}
            />
            <TableContainer
                ths={pageData.ths}
                tds={pageData.tds}
                texts={{
                    yesText: pageData.yesText,
                    noText: pageData.noText,
                }}
                filteredPlans={filteredData}
                actions={pageData.actions}
                locale={dateLocale}
                currency_pt_BR={pageData.currency_pt_BR}
                currency_en_US={pageData.currency_en_US}
                activeFilter={activeFilter}
                orderByProperty={orderByProperty}
            />
        </>
    );
}

Plans.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export function handleCircle(day, sub, days, index) {
    if (sub.days.includes(day)) {
        return 'green circle';
    } else if (sub.trials_configurations.trialStart === null) {
        return 'grey circle';
    } else if (
        sub.trials_configurations.timestampTrialStart > days.timestamp[index]
    ) {
        return 'grey circle';
    } else {
        return 'red circle';
    }
}

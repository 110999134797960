import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { Button, Card, CardBody, CardFooter, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import lodash from 'lodash';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { FormFields } from './components/formFields';
import { LumusPricesFormFields } from './components/lumusPricesFormFields';
import { setLoading } from '../../../store/actions/loadingActions';
import { validateForm } from '../../../helpers/formValidator';
import { addPlan } from '../../../services/api/plans';
import history from '../../../services/history';

AddPlan.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function AddPlan({ currentUser }) {
    const formRef = useRef(null);

    const [planGroup, setPlanGroup] = useState(false);
    const [planType, setPlanType] = useState('sleep_diary');
    const [lumusPricesFields, setLumusPricesFields] = useState({
        monthly: [],
        biannual: [],
        annual: [],
    });

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];
    const {
        sleepDiarySchema,
        sleepDiaryGroupSchema,
        lumusSchema,
        lumusGroupSchema,
    } = schemaConfig[lang][currentUser.permissions];

    function handlePlanGroup(isPlanGroup) {
        setPlanGroup(isPlanGroup);
    }

    function handlePlanType(type) {
        setPlanType(type ?? 'sleep_diary');
    }

    async function handleSubmit(data) {
        const useSchema =
            planType === 'sleep_diary'
                ? planGroup
                    ? sleepDiaryGroupSchema
                    : sleepDiarySchema
                : planGroup
                ? lumusGroupSchema
                : lumusSchema;

        const { parsedData, isValid, errors } = await validateForm(
            data,
            useSchema
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(setLoading(true));

            const response = await addPlan(parsedData);

            dispatch(setLoading(false));

            if (response) {
                toast.success(pageData.addSuccessText);
                history.push('/plans');
            }
        }
    }

    function handleRemoveClick(index, fidelity) {
        let tempLumusPricesFields = lodash.cloneDeep(
            lumusPricesFields[fidelity]
        );

        tempLumusPricesFields.splice(index, 1);

        setLumusPricesFields((prevState) => ({
            ...prevState,
            [fidelity]: tempLumusPricesFields,
        }));
    }

    function handleAddClick(fidelity) {
        let tempLumusPricesFields = lodash.cloneDeep(
            pageData.lumusPricesFields
        );

        let lumusPriceFieldsUUID = crypto.randomUUID();

        tempLumusPricesFields[0].key = lumusPriceFieldsUUID;
        tempLumusPricesFields[1].key = lumusPriceFieldsUUID;
        tempLumusPricesFields[2].key = lumusPriceFieldsUUID;

        setLumusPricesFields((prevState) => ({
            ...prevState,
            [fidelity]: [...lumusPricesFields[fidelity], tempLumusPricesFields],
        }));
    }

    useEffect(() => {
        formRef.current.reset();

        formRef.current.setFieldValue('active', {
            label: pageData.yesText,
            value: true,
        });

        formRef.current.setFieldValue('type', {
            label: pageData.planTypes[planType],
            value: planType,
        });
    }, []);

    useEffect(() => {
        let tempLumusPricesFields = lodash.cloneDeep(lumusPricesFields);

        ['monthly', 'biannual', 'annual'].forEach((fidelity) => {
            if (tempLumusPricesFields[fidelity].length > 0) {
                tempLumusPricesFields[fidelity] = tempLumusPricesFields[
                    fidelity
                ].map((lumusPriceFields) => {
                    lumusPriceFields[0].props.label =
                        pageData.numberOfDevicesText;
                    lumusPriceFields[1].props.label = pageData.priceBRLText;
                    lumusPriceFields[2].props.label = pageData.priceUSDText;

                    return lumusPriceFields;
                });
            }
        });

        setLumusPricesFields(tempLumusPricesFields);

        if (formRef.current) {
            formRef.current.setFieldValue('active', {
                label: pageData.yesText,
                value: true,
            });

            if (formRef.current.getFieldValue('type')) {
                formRef.current.setFieldValue('type', {
                    label: pageData.planTypes[
                        formRef.current.getFieldValue('type')
                    ],
                    value: formRef.current.getFieldValue('type'),
                });
            }
        }
    }, [lang]);

    if (pageData === null) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <FormGroup row>
                        <FormFields
                            fields={pageData.fields}
                            handlePlanGroup={handlePlanGroup}
                            planGroup={planGroup}
                            handlePlanType={handlePlanType}
                            planType={planType}
                        />
                    </FormGroup>
                    {planGroup && planType === 'sleep_diary' && (
                        <>
                            <hr />
                            <span>{pageData.biannualPlanText}</span>
                            <FormGroup row>
                                <FormFields
                                    fields={pageData.biannualPlanFields}
                                    handlePlanGroup={handlePlanGroup}
                                    planGroup={planGroup}
                                />
                            </FormGroup>
                            <hr />
                            <span>{pageData.annualPlanText}</span>
                            <FormGroup row>
                                <FormFields
                                    fields={pageData.annualPlanFields}
                                    handlePlanGroup={handlePlanGroup}
                                    planGroup={planGroup}
                                />
                            </FormGroup>
                        </>
                    )}
                    {planType === 'lumus' && (
                        <LumusPricesFormFields
                            pageData={pageData}
                            lumusPricesFields={lumusPricesFields.monthly}
                            handleAddClick={handleAddClick}
                            handleRemoveClick={handleRemoveClick}
                            scope="prices"
                        />
                    )}
                    {planGroup && planType === 'lumus' && (
                        <>
                            <hr />
                            <FormGroup row>
                                <FormGroup className="col-xs-12 col-md-12">
                                    <span>{pageData.biannualPlanText}</span>
                                </FormGroup>
                            </FormGroup>
                            <LumusPricesFormFields
                                pageData={pageData}
                                lumusPricesFields={lumusPricesFields.biannual}
                                handleAddClick={handleAddClick}
                                handleRemoveClick={handleRemoveClick}
                                scope="biannual_prices"
                            />
                            <hr />
                            <FormGroup row>
                                <FormGroup className="col-xs-12 col-md-12">
                                    <span>{pageData.annualPlanText}</span>
                                </FormGroup>
                            </FormGroup>
                            <LumusPricesFormFields
                                pageData={pageData}
                                lumusPricesFields={lumusPricesFields.annual}
                                handleAddClick={handleAddClick}
                                handleRemoveClick={handleRemoveClick}
                                scope="annual_prices"
                            />
                        </>
                    )}
                    <hr />
                    <FormGroup row>
                        <div className="col-xs-12 col-md-12 text-left">
                            <Button color="primary mr-2" type="submit">
                                {pageData.addButtonText}
                            </Button>
                            <Link to="/plans" className="btn btn-secondary">
                                {pageData.cancelButtonText}
                            </Link>
                        </div>
                    </FormGroup>
                </Form>
            </CardBody>
            <CardFooter>
                <p
                    dangerouslySetInnerHTML={{
                        __html: pageData.requiredFieldPText,
                    }}
                ></p>
            </CardFooter>
        </Card>
    );
}

import React from 'react';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardTitle,
    CardSubtitle,
    CardBody,
    CardFooter,
} from 'reactstrap';
import { FaCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';

import useWindowSize from '../../../../hooks/useWindowSize';
import { ChartLabel } from '../../../../components/chartLabel';
import { ActigraphyChart } from './actigraphyChart';
import { TemperatureChart } from './temperatureChart';
import { LightIntensityChart } from './lightIntensityChart';
import { LuxSensorsChart } from './luxSensorsChart';
import { CapacitiveSensorsChart } from './capacitiveSensorsChart';

Sensors.propTypes = {
    data: PropTypes.shape({
        actigraphyChart: PropTypes.shape({
            log_time: PropTypes.arrayOf(PropTypes.string).isRequired,
            pim: PropTypes.arrayOf(PropTypes.number).isRequired,
            tat: PropTypes.arrayOf(PropTypes.number).isRequired,
            zcm: PropTypes.arrayOf(PropTypes.number).isRequired,
        }),
        temperatureChart: PropTypes.shape({
            log_time: PropTypes.arrayOf(PropTypes.string).isRequired,
            temperature: PropTypes.arrayOf(PropTypes.number).isRequired,
        }),
        lightIntensityChart: PropTypes.shape({
            log_time: PropTypes.arrayOf(PropTypes.string).isRequired,
            f1: PropTypes.arrayOf(PropTypes.number).isRequired,
            f2: PropTypes.arrayOf(PropTypes.number).isRequired,
            f3: PropTypes.arrayOf(PropTypes.number).isRequired,
            f4: PropTypes.arrayOf(PropTypes.number).isRequired,
            f5: PropTypes.arrayOf(PropTypes.number).isRequired,
            f6: PropTypes.arrayOf(PropTypes.number).isRequired,
            f7: PropTypes.arrayOf(PropTypes.number).isRequired,
            f8: PropTypes.arrayOf(PropTypes.number).isRequired,
            clear: PropTypes.arrayOf(PropTypes.number).isRequired,
            nir: PropTypes.arrayOf(PropTypes.number).isRequired,
        }),
        luxChart: PropTypes.shape({
            log_time: PropTypes.arrayOf(PropTypes.string).isRequired,
            lux_photopic: PropTypes.arrayOf(PropTypes.number).isRequired,
            lux_melanopic: PropTypes.arrayOf(PropTypes.number).isRequired,
        }),
        capacitiveChart: PropTypes.shape({
            log_time: PropTypes.arrayOf(PropTypes.string).isRequired,
            cap_sensor_1: PropTypes.arrayOf(PropTypes.number).isRequired,
            cap_sensor_2: PropTypes.arrayOf(PropTypes.number).isRequired,
        }),
    }).isRequired,
    textData: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
};

export function Sensors({ data, textData, visible }) {
    const { width } = useWindowSize();

    const {
        actigraphyChart,
        temperatureChart,
        lightIntensityChart,
        luxChart,
        capacitiveChart,
    } = data;

    return (
        <>
            <Col xs="12">
                <Card className={`cardShadow`}>
                    <CardHeader>
                        <CardTitle>
                            <h5>{textData.actigraphyDataText}</h5>
                        </CardTitle>
                        <CardSubtitle>
                            {textData.actigraphyChartText}
                        </CardSubtitle>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col
                                xs="12"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {actigraphyChart && visible ? (
                                    <ActigraphyChart
                                        width={width}
                                        chartData={actigraphyChart}
                                        textData={textData}
                                    />
                                ) : (
                                    textData.noDataText
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        {textData.actigraphyTexts.map((actigraphyText) => (
                            <ChartLabel
                                key={actigraphyText.name}
                                name={actigraphyText.name}
                                color={actigraphyText.color}
                            />
                        ))}
                    </CardFooter>
                </Card>
            </Col>
            <Col xs="12">
                <Card className={`cardShadow`}>
                    <CardHeader>
                        <CardTitle>
                            <h5>{textData.temperatureDataText}</h5>
                        </CardTitle>
                        <CardSubtitle>
                            {textData.temperatureChartText}
                        </CardSubtitle>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col
                                xs="12"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {temperatureChart && visible ? (
                                    <TemperatureChart
                                        width={width}
                                        chartData={temperatureChart}
                                        textData={textData}
                                    />
                                ) : (
                                    textData.noDataText
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        {textData.temperatureTexts.map((temperatureText) => (
                            <div
                                key={temperatureText.name}
                                className="d-inline-flex justify-content-between align-items-center"
                            >
                                <FaCircle color={temperatureText.color} />
                                <p className="ml-2 mr-3">
                                    {temperatureText.name}
                                </p>
                            </div>
                        ))}
                    </CardFooter>
                </Card>
            </Col>
            <Col xs="12">
                <Card className={`cardShadow`}>
                    <CardHeader>
                        <CardTitle>
                            <h5>{textData.lightIntensityDataText}</h5>
                        </CardTitle>
                        <CardSubtitle>
                            {textData.lightIntensityChartText}
                        </CardSubtitle>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col
                                xs="12"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {lightIntensityChart && visible ? (
                                    <LightIntensityChart
                                        width={width}
                                        chartData={lightIntensityChart}
                                        textData={textData}
                                    />
                                ) : (
                                    textData.noDataText
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        {textData.lightsTexts.map((lightText) => (
                            <div
                                key={lightText.name}
                                className="d-inline-flex justify-content-between align-items-center"
                            >
                                <FaCircle color={lightText.color} />
                                <p className="ml-2 mr-3">{lightText.name}</p>
                            </div>
                        ))}
                    </CardFooter>
                </Card>
            </Col>
            <Col xs="12">
                <Card className={`cardShadow`}>
                    <CardHeader>
                        <CardTitle>
                            <h5>{textData.luxDataText}</h5>
                        </CardTitle>
                        <CardSubtitle>{textData.luxChartText}</CardSubtitle>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col
                                xs="12"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {luxChart && visible ? (
                                    <LuxSensorsChart
                                        width={width}
                                        chartData={luxChart}
                                        textData={textData}
                                    />
                                ) : (
                                    textData.noDataText
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        {textData.luxTexts.map((luxText) => (
                            <div
                                key={luxText.name}
                                className="d-inline-flex justify-content-between align-items-center"
                            >
                                <FaCircle color={luxText.color} />
                                <p className="ml-2 mr-3">{luxText.name}</p>
                            </div>
                        ))}
                    </CardFooter>
                </Card>
            </Col>
            <Col xs="12">
                <Card className={`cardShadow`}>
                    <CardHeader>
                        <CardTitle>
                            <h5>{textData.capacitiveDataText}</h5>
                        </CardTitle>
                        <CardSubtitle>
                            {textData.capacitiveChartText}
                        </CardSubtitle>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col
                                xs="12"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {capacitiveChart && visible ? (
                                    <CapacitiveSensorsChart
                                        width={width}
                                        chartData={capacitiveChart}
                                        textData={textData}
                                    />
                                ) : (
                                    textData.noDataText
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        {textData.capacitiveTexts.map((capacitiveText) => (
                            <div
                                key={capacitiveText.name}
                                className="d-inline-flex justify-content-between align-items-center"
                            >
                                <FaCircle color={capacitiveText.color} />
                                <p className="ml-2 mr-3">
                                    {capacitiveText.name}
                                </p>
                            </div>
                        ))}
                    </CardFooter>
                </Card>
            </Col>
        </>
    );
}

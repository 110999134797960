import Input from '../../../components/inputForm/inputText';
import Select from '../../../components/inputForm/inputSelect';
import { Checkbox } from '../../../components/inputForm/inputCheckbox';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: {
                commonFields: [
                    {
                        component: Input,
                        props: {
                            name: 'name',
                            type: 'text',
                            label: 'Nome <b style="color: darkred">*</b>',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'duration',
                            type: 'number',
                            label: 'Duração (meses) <b style="color: darkred">*</b>',
                            min: 0,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'is_percent',
                            label: 'Tipo de cupom',
                            type: 'text',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                percentFields: [
                    {
                        component: Input,
                        props: {
                            name: 'percentOff',
                            type: 'number',
                            label: 'Percentual de desconto <b style="color: darkred">*</b>',
                            min: 0,
                            step: 0.1,
                            max: 100,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                amountFields: [
                    {
                        component: Input,
                        props: {
                            name: 'amountOff',
                            type: 'number',
                            label: 'Valor de desconto (centavos) <b style="color: darkred">*</b>',
                            min: 0,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                currencyFields: [
                    {
                        component: Checkbox,
                        props: {
                            name: 'currency[0]',
                            type: 'checkbox',
                            label: 'Real: ',
                            value: 'BRL',
                        },
                        className: 'col-xs-12 col-md-1',
                    },
                    {
                        component: Checkbox,
                        props: {
                            name: 'currency[1]',
                            type: 'checkbox',
                            label: 'Dólar: ',
                            value: 'USD',
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                ],
            },
            editSuccessText: 'Cupom editado com sucesso!',
            editText: 'Editar',
            cancelText: 'Cancelar',
            currencyText: 'Moedas',
            percentageText: 'Porcentagem',
            amountText: 'Valor',
            selectCurrencyErrorText:
                'É obrigatório selecionar ao menos uma moeda',
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: {
                commonFields: [
                    {
                        component: Input,
                        props: {
                            name: 'name',
                            type: 'text',
                            label: 'Name <b style="color: darkred">*</b>',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'duration',
                            type: 'number',
                            label: 'Duration (months) <b style="color: darkred">*</b>',
                            min: 0,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'is_percent',
                            label: 'Coupon type',
                            readOnly: true,
                            type: 'text',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                percentFields: [
                    {
                        component: Input,
                        props: {
                            name: 'percentOff',
                            type: 'number',
                            label: 'Percent off <b style="color: darkred">*</b>',
                            min: 0,
                            step: 0.1,
                            max: 100,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                amountFields: [
                    {
                        component: Input,
                        props: {
                            name: 'amountOff',
                            type: 'number',
                            label: 'Amount off (cents) <b style="color: darkred">*</b>',
                            min: 0,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                currencyFields: [
                    {
                        component: Checkbox,
                        props: {
                            name: 'currency[0]',
                            type: 'checkbox',
                            label: 'Real: ',
                            value: 'BRL',
                        },
                        className: 'col-xs-12 col-md-1',
                    },
                    {
                        component: Checkbox,
                        props: {
                            name: 'currency[1]',
                            type: 'checkbox',
                            label: 'Dollar: ',
                            value: 'USD',
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                ],
            },
            editSuccessText: 'Coupon successfully edited!',
            editText: 'Edit',
            cancelText: 'Cancel',
            currencyText: 'Currency',
            percentageText: 'Percentage',
            amountText: 'Value',
            selectCurrencyErrorText:
                'It is required to select at least one currency',
        },
    },
};

import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import FormFields from '../../../../components/form/formFields';
import { LumusPricesFormFields } from './lumusPricesFormFields';
import { setLoading } from '../../../../store/actions/loadingActions';
import { validateForm } from '../../../../helpers/formValidator';
import { editPlan } from '../../../../services/api/plans';
import history from '../../../../services/history';

EditPlanForm.propTypes = {
    plan: PropTypes.object.isRequired,
    fields: PropTypes.array.isRequired,
    schema: PropTypes.object.isRequired,
    texts: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
    lumusPricesFields: PropTypes.array.isRequired,
    handleRemoveClick: PropTypes.func.isRequired,
    handleAddClick: PropTypes.func.isRequired,
};

export function EditPlanForm({
    plan,
    fields,
    schema,
    texts,
    lang,
    lumusPricesFields,
    handleRemoveClick,
    handleAddClick,
}) {
    const formRef = useRef(null);
    const dispatch = useDispatch();

    const {
        editSuccessText,
        editButtonText,
        cancelButtonText,
        intervalTypeOptions,
        payableWithOptions,
        yesText,
        noText,
        planTypes,
    } = texts;

    const handleSubmit = async (data) => {
        const { parsedData, isValid, errors } = await validateForm(
            data,
            schema
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(setLoading(true));

            const response = await editPlan(plan.id, parsedData);

            dispatch(setLoading(false));

            if (response) {
                toast.success(editSuccessText);
                history.push('/plans');
            }
        }
    };

    useEffect(() => {
        formRef.current.reset();

        const initialData = fields.reduce(
            (acc, { props: { name } }) => ({ ...acc, [name]: plan[name] }),
            {}
        );

        formRef.current.setData(initialData);

        formRef.current.setFieldValue('interval_type', {
            label: intervalTypeOptions[plan.interval_type],
            value: plan.interval_type,
        });

        formRef.current.setFieldValue('payable_with', {
            label: payableWithOptions[plan.payable_with],
            value: plan.payable_with,
        });

        formRef.current.setFieldValue('active', {
            label: plan.active ? yesText : noText,
            value: plan.active,
        });

        formRef.current.setFieldValue('visible', {
            label: plan.visible ? yesText : noText,
            value: plan.visible,
        });

        formRef.current.setFieldValue('type', {
            label: planTypes[plan.type],
            value: plan.type,
        });

        if (plan.type === 'lumus') {
            for (let i = 0; i < plan.prices.length; i++) {
                formRef.current.setFieldValue(
                    `prices[${i}].up_to`,
                    plan.prices[i].up_to
                );
                formRef.current.setFieldValue(
                    `prices[${i}].price_brl`,
                    plan.prices[i].price_brl
                );
                formRef.current.setFieldValue(
                    `prices[${i}].price_usd`,
                    plan.prices[i].price_usd
                );
            }
        }
    }, [lang]);

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <FormGroup row>
                <FormFields fields={fields} />
            </FormGroup>
            {plan.type === 'lumus' && (
                <LumusPricesFormFields
                    pageData={texts}
                    lumusPricesFields={lumusPricesFields}
                    handleAddClick={handleAddClick}
                    handleRemoveClick={handleRemoveClick}
                />
            )}
            <hr />
            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button color="primary mr-2" type="submit">
                        {editButtonText}
                    </Button>
                    <Link to="/plans" className="btn btn-secondary">
                        {cancelButtonText}
                    </Link>
                </div>
            </FormGroup>
        </Form>
    );
}

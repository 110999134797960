import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';

import '../../styles/components/infoCard/infoCard.css';

InfoCard.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    title: PropTypes.string.isRequired,
    icon: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
};

export function InfoCard({
    value: Value,
    title,
    icon: Icon,
    className = '',
    ...rest
}) {
    return (
        <Card className={`infoCard ${className}`} {...rest}>
            <CardBody className="infoCardBody">
                <div>
                    <h3>
                        {typeof Value === 'number' ? (
                            Value
                        ) : Value === null ? (
                            '--'
                        ) : (
                            <Value />
                        )}
                    </h3>
                    <p data-cy={rest['data-cy']}>{title}</p>
                </div>
                <div className="text-center">
                    <Icon className="iconCard" />
                </div>
            </CardBody>
        </Card>
    );
}

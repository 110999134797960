import produce from 'immer';

import { LOADING } from '../constants';

const INITIAL_STATE = {
    isLoading: false,
};

export default function loading(state = INITIAL_STATE, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case LOADING.LOADING_REQUEST: {
                break;
            }
            case LOADING.SHOW_LOADING: {
                draft.isLoading = true;
                break;
            }
            case LOADING.HIDE_LOADING: {
                draft.isLoading = false;
                break;
            }
            case LOADING.RESET_STATE: {
                draft.isLoading = INITIAL_STATE.isLoading;
                break;
            }
        }
    });
}

import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, FormGroup } from 'reactstrap';
import { Form } from '@unform/web';
import PropTypes from 'prop-types';

import styles from './form.module.css';

import { validateForm } from '../../../helpers/formValidator';

export function CustomForm({
    schemas,
    fields,
    texts,
    toggle,
    handleAddQuestion,
    handleEditQuestion,
    question,
}) {
    const formRef = useRef(null);

    const lang = useSelector((state) => state.lang.lang);

    const [answerType, setAnswerType] = useState(question?.descriptor?.type);
    const [answerFields, setAnswerFields] = useState(
        fields[question?.descriptor?.type]
    );
    const [multipleChoice, setMultipleChoice] = useState(
        question?.descriptor?.choices?.length ?? 0
    );

    function handleSchema(schemas, answerType) {
        let schema = {};

        if (answerType === 'DateTimeInput') {
            schema = schemas.schemaWithMinMaxDateTime;
        } else if (answerType === 'SliderInput') {
            schema = schemas.schemaWithMinMaxText;
        } else if (answerType === 'ToggleInput') {
            schema = schemas.schemaWithOptions;
        } else if (answerType === 'ChoiceChipsSelect') {
            schema = schemas.schemaWithChoices;
        } else {
            schema = schemas.schemaWithoutOptions;
        }

        return schema;
    }

    async function handleSubmit(data, { reset }, event) {
        event.preventDefault();

        const schema = handleSchema(schemas, answerType);

        if (!question || !question?.descriptor?.default) {
            data.isVisible = true;
        }

        const { isValid, errors } = await validateForm(data, schema);

        formRef.current.setErrors(errors);

        if (isValid) {
            if (question) {
                handleEditQuestion(question, data);
                toggle();
            } else {
                handleAddQuestion(data, answerType);
                toggle();
            }
        }
    }

    function handleMultipleChoice(value) {
        setMultipleChoice(Number(value));
    }

    function handleAnswerTypeChange(value) {
        if (value && value !== 'ChoiceChipsSelect') {
            setMultipleChoice(0);
        }

        setAnswerType(value);
        setAnswerFields(fields[value]);
    }

    useEffect(() => {
        if (question) {
            const initialData = {};

            fields.commonFields.forEach((field) => {
                if (
                    field.props.name !== 'type' &&
                    field.props.name !== 'isVisible'
                ) {
                    initialData[field.props.name] =
                        question.descriptor[field.props.name];
                }
            });

            if (question?.descriptor?.type === 'DateTimeInput') {
                fields.DateTimeInput.forEach((field) => {
                    initialData[field.props.name] =
                        question.descriptor[field.props.name];
                });
            } else if (question?.descriptor?.type === 'SliderInput') {
                fields.SliderInput.forEach((field) => {
                    initialData[field.props.name] =
                        question.descriptor[field.props.name];
                });
            } else if (question?.descriptor?.type === 'ToggleInput') {
                initialData.textdescriptor = [
                    ...question.descriptor.textdescriptor,
                ];
            } else if (question?.descriptor?.type === 'ChoiceChipsSelect') {
                fields.ChoiceChipsSelect.fields.forEach((field) => {
                    if (field.props.name === 'quantity') {
                        initialData[field.props.name] =
                            question.descriptor.choices.length;
                    } else {
                        initialData[field.props.name] =
                            question.descriptor[field.props.name];
                    }
                });

                initialData.choices = [...question.descriptor.choices];
            }

            formRef.current.setData(initialData);

            formRef.current.setFieldValue('type', {
                label: fields.commonFields[1].props.options.find(
                    (option) => option.value === answerType
                )?.label,
                value: answerType,
            });

            formRef.current.setFieldValue('isVisible', {
                label: question.descriptor.isVisible
                    ? texts.yesText
                    : texts.noText,
                value: question.descriptor.isVisible ? true : false,
            });
        }
    }, [answerType]);

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <FormGroup row>
                {fields.commonFields.map((field) => {
                    if (field.props.name === 'type') {
                        let tempChoices = [...field.props.options];

                        if (question?.descriptor?.id === 'sleepSatisfaction') {
                            tempChoices = tempChoices.filter(
                                (choice) =>
                                    choice.id === 'SliderInput' ||
                                    choice.id === 'ChoiceChipsSelect'
                            );
                        }

                        return (
                            <FormGroup
                                key={field.props.name}
                                className={field.className}
                            >
                                <field.component
                                    {...field.props}
                                    options={tempChoices}
                                    isDisabled={
                                        !question?.descriptor
                                            ? false
                                            : question?.descriptor?.id ===
                                                  'sleepSatisfaction' ||
                                              question?.descriptor?.id ===
                                                  'sleepSatisfactionQuality'
                                            ? true
                                            : !question?.descriptor?.editable
                                    }
                                    onChange={(e) =>
                                        handleAnswerTypeChange(e?.value)
                                    }
                                />
                            </FormGroup>
                        );
                    } else if (field.props.name === 'isVisible') {
                        return !question?.descriptor?.default ? null : (
                            <FormGroup
                                key={field.props.name}
                                className={field.className}
                            >
                                <field.component
                                    {...field.props}
                                    isDisabled={
                                        question?.descriptor?.default &&
                                        question?.descriptor?.required
                                    }
                                />
                            </FormGroup>
                        );
                    } else {
                        return (
                            <FormGroup
                                key={field.props.name}
                                className={field.className}
                            >
                                <field.component
                                    {...field.props}
                                    readOnly={
                                        !question?.descriptor
                                            ? false
                                            : question?.descriptor?.id ===
                                                  'sleepSatisfaction' ||
                                              question?.descriptor?.id ===
                                                  'sleepSatisfactionQuality'
                                            ? true
                                            : !question?.descriptor?.editable
                                    }
                                />
                            </FormGroup>
                        );
                    }
                })}
            </FormGroup>
            {!answerType ? null : answerType === 'ChoiceChipsSelect' ? (
                <>
                    <FormGroup row>
                        {answerFields.fields.map((field) => {
                            if (field.props.name === 'quantity') {
                                return (
                                    <FormGroup
                                        key={field.props.name}
                                        className={field.className}
                                    >
                                        <field.component
                                            {...field.props}
                                            onChange={(e) =>
                                                handleMultipleChoice(
                                                    e?.target?.value
                                                )
                                            }
                                            readOnly={
                                                !question?.descriptor
                                                    ? false
                                                    : question?.descriptor
                                                          ?.id ===
                                                          'sleepSatisfaction' ||
                                                      question?.descriptor
                                                          ?.id ===
                                                          'sleepSatisfactionQuality'
                                                    ? true
                                                    : !question?.descriptor
                                                          ?.editable
                                            }
                                        />
                                    </FormGroup>
                                );
                            } else {
                                return (
                                    <FormGroup
                                        key={field.props.name}
                                        className={field.className}
                                    >
                                        <field.component {...field.props} />
                                    </FormGroup>
                                );
                            }
                        })}
                    </FormGroup>
                    <FormGroup row>
                        {answerFields.choices
                            .slice(0, multipleChoice)
                            .map((field) => (
                                <FormGroup
                                    key={field.props.name}
                                    className={field.className}
                                >
                                    <field.component
                                        {...field.props}
                                        readOnly={
                                            !question?.descriptor
                                                ? false
                                                : question?.descriptor?.id ===
                                                      'sleepSatisfaction' ||
                                                  question?.descriptor?.id ===
                                                      'sleepSatisfactionQuality'
                                                ? true
                                                : !question?.descriptor
                                                      ?.editable
                                        }
                                    />
                                </FormGroup>
                            ))}
                    </FormGroup>
                </>
            ) : (
                <FormGroup row>
                    {answerFields.map((field) => (
                        <FormGroup
                            key={field.props.name}
                            className={field.className}
                        >
                            <field.component
                                {...field.props}
                                readOnly={
                                    !question?.descriptor
                                        ? false
                                        : question?.descriptor?.id ===
                                              'sleepSatisfaction' ||
                                          question?.descriptor?.id ===
                                              'sleepSatisfactionQuality'
                                        ? true
                                        : !question?.descriptor?.editable
                                }
                            />
                        </FormGroup>
                    ))}
                </FormGroup>
            )}
            {answerType === 'DateTimeInput' && (
                <FormGroup row>
                    <div className={styles.explanationContainer}>
                        <p>
                            {texts.hoursBeforeText}:{' '}
                            {texts.hoursBeforeExplainText}
                        </p>
                        <p>
                            {texts.hoursAfterText}:{' '}
                            {texts.hoursAfterExplainText}
                        </p>
                    </div>
                </FormGroup>
            )}
            <FormGroup row>
                <div className={styles.exampleImageContainer}>
                    <img src={`/assets/${answerType}_${lang}.jpg`} alt="" />
                </div>
            </FormGroup>
            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button color="primary" className="mr-2" type="submit">
                        {question ? texts.editText : texts.addText}
                    </Button>
                    <Button
                        color="secondary"
                        className="mr-2"
                        type="button"
                        onClick={toggle}
                    >
                        {texts.cancelText}
                    </Button>
                </div>
            </FormGroup>
        </Form>
    );
}

CustomForm.propTypes = {
    schemas: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    texts: PropTypes.object.isRequired,
    toggle: PropTypes.func.isRequired,
    handleAddQuestion: PropTypes.func,
    handleEditQuestion: PropTypes.func,
    question: PropTypes.object,
};

import * as Yup from 'yup';

export default {
    pt: {
        iuguSchema: Yup.object().shape({
            company_name: Yup.string().required('Campo obrigatório'),
            name: Yup.string().required('Campo obrigatório'),
            email: Yup.string()
                .email('Formato inválido')
                .required('Campo obrigatório'),
            share: Yup.bool().required('Campo obrigatório'),
            password: Yup.string()
                .min(8)
                .max(30)
                .oneOf(
                    [Yup.ref('confirm_password'), null],
                    'As senhas devem ser iguais'
                )
                .required('Campo obrigatório'),
            confirm_password: Yup.string()
                .min(8)
                .max(30)
                .oneOf(
                    [Yup.ref('password'), null],
                    'As senhas devem ser iguais'
                )
                .required('Campo obrigatório'),
            type: Yup.string().required('Campo obrigatório'),
            fidelity: Yup.string().required('Campo obrigatório'),
            number: Yup.string().required(),
            verification_value: Yup.string().required(),
            full_name: Yup.string().required(),
            expiration: Yup.string().required(),
        }),
        iuguLumusSchema: Yup.object().shape({
            company_name: Yup.string().required('Campo obrigatório'),
            name: Yup.string().required('Campo obrigatório'),
            email: Yup.string()
                .email('Formato inválido')
                .required('Campo obrigatório'),
            share: Yup.bool().required('Campo obrigatório'),
            password: Yup.string()
                .min(8)
                .max(30)
                .oneOf(
                    [Yup.ref('confirm_password'), null],
                    'As senhas devem ser iguais'
                )
                .required('Campo obrigatório'),
            confirm_password: Yup.string()
                .min(8)
                .max(30)
                .oneOf(
                    [Yup.ref('password'), null],
                    'As senhas devem ser iguais'
                )
                .required('Campo obrigatório'),
            type: Yup.string().required('Campo obrigatório'),
            fidelity: Yup.string().required('Campo obrigatório'),
            number_of_devices: Yup.number().required('Campo obrigatório'),
            number: Yup.string().required(),
            verification_value: Yup.string().required(),
            full_name: Yup.string().required(),
            expiration: Yup.string().required(),
        }),
        stripeSchema: Yup.object().shape({
            company_name: Yup.string().required('Campo obrigatório'),
            name: Yup.string().required('Campo obrigatório'),
            email: Yup.string()
                .email('Formato inválido')
                .required('Campo obrigatório'),
            share: Yup.bool().required('Campo obrigatório'),
            password: Yup.string()
                .min(8)
                .max(30)
                .oneOf(
                    [Yup.ref('confirm_password'), null],
                    'As senhas devem ser iguais'
                )
                .required('Campo obrigatório'),
            confirm_password: Yup.string()
                .min(8)
                .max(30)
                .oneOf(
                    [Yup.ref('password'), null],
                    'As senhas devem ser iguais'
                )
                .required('Campo obrigatório'),
            type: Yup.string().required('Campo obrigatório'),
            fidelity: Yup.string().required('Campo obrigatório'),
        }),
        stripeLumusSchema: Yup.object().shape({
            company_name: Yup.string().required('Campo obrigatório'),
            name: Yup.string().required('Campo obrigatório'),
            email: Yup.string()
                .email('Formato inválido')
                .required('Campo obrigatório'),
            share: Yup.bool().required('Campo obrigatório'),
            password: Yup.string()
                .min(8)
                .max(30)
                .oneOf(
                    [Yup.ref('confirm_password'), null],
                    'As senhas devem ser iguais'
                )
                .required('Campo obrigatório'),
            confirm_password: Yup.string()
                .min(8)
                .max(30)
                .oneOf(
                    [Yup.ref('password'), null],
                    'As senhas devem ser iguais'
                )
                .required('Campo obrigatório'),
            type: Yup.string().required('Campo obrigatório'),
            fidelity: Yup.string().required('Campo obrigatório'),
            number_of_devices: Yup.number().required('Campo obrigatório'),
        }),
    },
    en: {
        iuguSchema: Yup.object().shape({
            company_name: Yup.string().required('Required field'),
            name: Yup.string().required('Required field'),
            email: Yup.string()
                .email('Invalid format')
                .required('Required field'),
            share: Yup.bool().required('Required field'),
            password: Yup.string()
                .min(8)
                .max(30)
                .oneOf(
                    [Yup.ref('confirm_password'), null],
                    'Passwords must match'
                )
                .required('Required field'),
            confirm_password: Yup.string()
                .min(8)
                .max(30)
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Required field'),
            type: Yup.string().required('Required field'),
            fidelity: Yup.string().required('Required field'),
            number: Yup.string().required(),
            verification_value: Yup.string().required(),
            full_name: Yup.string().required(),
            expiration: Yup.string().required(),
        }),
        iuguLumusSchema: Yup.object().shape({
            company_name: Yup.string().required('Required field'),
            name: Yup.string().required('Required field'),
            email: Yup.string()
                .email('Invalid format')
                .required('Required field'),
            share: Yup.bool().required('Required field'),
            password: Yup.string()
                .min(8)
                .max(30)
                .oneOf(
                    [Yup.ref('confirm_password'), null],
                    'Passwords must match'
                )
                .required('Required field'),
            confirm_password: Yup.string()
                .min(8)
                .max(30)
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Required field'),
            type: Yup.string().required('Required field'),
            fidelity: Yup.string().required('Required field'),
            number_of_devices: Yup.number().required('Required field'),
            number: Yup.string().required(),
            verification_value: Yup.string().required(),
            full_name: Yup.string().required(),
            expiration: Yup.string().required(),
        }),
        stripeSchema: Yup.object().shape({
            company_name: Yup.string().required('Required field'),
            name: Yup.string().required('Required field'),
            email: Yup.string()
                .email('Invalid format')
                .required('Required field'),
            share: Yup.bool().required('Required field'),
            password: Yup.string()
                .min(8)
                .max(30)
                .oneOf(
                    [Yup.ref('confirm_password'), null],
                    'Passwords must match'
                )
                .required('Required field'),
            confirm_password: Yup.string()
                .min(8)
                .max(30)
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Required field'),
            type: Yup.string().required('Required field'),
            fidelity: Yup.string().required('Required field'),
        }),
        stripeLumusSchema: Yup.object().shape({
            company_name: Yup.string().required('Required field'),
            name: Yup.string().required('Required field'),
            email: Yup.string()
                .email('Invalid format')
                .required('Required field'),
            share: Yup.bool().required('Required field'),
            password: Yup.string()
                .min(8)
                .max(30)
                .oneOf(
                    [Yup.ref('confirm_password'), null],
                    'Passwords must match'
                )
                .required('Required field'),
            confirm_password: Yup.string()
                .min(8)
                .max(30)
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Required field'),
            type: Yup.string().required('Required field'),
            fidelity: Yup.string().required('Required field'),
            number_of_devices: Yup.number().required('Required field'),
        }),
    },
};

import Input from '../../../components/inputForm/inputText';
import Select from '../../../components/inputForm/inputSelect';

export default {
    pt: {
        customer_admin: {
            editText: 'Editar',
            saveText: 'Salvar',
            deleteText: 'Excluir',
            addText: 'Adicionar',
            addQuestionText: 'Adicionar pergunta',
            cancelText: 'Cancelar',
            yesText: 'Sim',
            noText: 'Não',
            activeText: 'Ativa',
            requiredText: 'Obrigatória',
            answerTypeText: 'Tipo de resposta',
            deleteQuestionText: 'Tem certeza que deseja excluir a pergunta: ',
            successResponseText: 'Diário adicionado com sucesso!',
            diaryText: 'Diário',
            defaultQuestionsText: 'Perguntas padrão',
            customQuestionsText: 'Perguntas customizadas',
            answerTypes: {
                DateInput: 'Data',
                DateTimeInput: 'Data e hora',
                DurationInput: 'Duração',
                IntInput: 'Número',
                SliderInput: 'Escala visual',
                ToggleInput: 'Sim/Não',
                FreeTextInput: 'Texto',
                ChoiceChipsSelect: 'Múltipla escolha',
            },
            fields: {
                diaryFields: [
                    {
                        component: Input,
                        props: {
                            name: 'name',
                            type: 'text',
                            label: 'Nome',
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                ],
                commonFields: [
                    {
                        component: Input,
                        props: {
                            name: 'fieldtext',
                            type: 'text',
                            label: 'Pergunta',
                        },
                        className: 'col-xs-12 col-md-12',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'type',
                            type: 'text',
                            label: 'Tipo de resposta',
                            options: [
                                {
                                    id: 'DateTimeInput',
                                    value: 'DateTimeInput',
                                    label: 'Data e hora',
                                },
                                {
                                    id: 'DurationInput',
                                    value: 'DurationInput',
                                    label: 'Duração',
                                },
                                {
                                    id: 'IntInput',
                                    value: 'IntInput',
                                    label: 'Número',
                                },
                                {
                                    id: 'SliderInput',
                                    value: 'SliderInput',
                                    label: 'Escala visual',
                                },
                                {
                                    id: 'ToggleInput',
                                    value: 'ToggleInput',
                                    label: 'Sim/Não',
                                },
                                {
                                    id: 'FreeTextInput',
                                    value: 'FreeTextInput',
                                    label: 'Texto',
                                },
                                {
                                    id: 'ChoiceChipsSelect',
                                    value: 'ChoiceChipsSelect',
                                    label: 'Múltipla escolha',
                                },
                            ],
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'isVisible',
                            label: 'Ativa',
                            options: [
                                { value: true, label: 'Sim' },
                                { value: false, label: 'Não' },
                            ],
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                ],
                DateTimeInput: [
                    {
                        component: Input,
                        props: {
                            name: 'hoursBefore',
                            type: 'number',
                            label: 'Horas antes',
                            min: 0,
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'hoursAfter',
                            type: 'number',
                            label: 'Horas depois',
                            min: 0,
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                DurationInput: [],
                IntInput: [],
                SliderInput: [
                    {
                        component: Input,
                        props: {
                            name: 'minText',
                            type: 'text',
                            label: 'Mínimo',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'maxText',
                            type: 'text',
                            label: 'Máximo',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                ToggleInput: [
                    {
                        component: Input,
                        props: {
                            name: 'textdescriptor[0]',
                            type: 'text',
                            label: 'Opção 1',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'textdescriptor[1]',
                            type: 'text',
                            label: 'Opção 2',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                FreeTextInput: [],
                ChoiceChipsSelect: {
                    fields: [
                        {
                            component: Input,
                            props: {
                                name: 'quantity',
                                type: 'number',
                                label: 'Quantidade de escolhas',
                                min: 1,
                                max: 9,
                            },
                            className: 'col-xs-12 col-md-6',
                        },
                    ],
                    choices: [
                        {
                            component: Input,
                            props: {
                                name: 'choices[0]',
                                type: 'text',
                                label: 'Escolha 1',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[1]',
                                type: 'text',
                                label: 'Escolha 2',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[2]',
                                type: 'text',
                                label: 'Escolha 3',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[3]',
                                type: 'text',
                                label: 'Escolha 4',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[4]',
                                type: 'text',
                                label: 'Escolha 5',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[5]',
                                type: 'text',
                                label: 'Escolha 6',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[6]',
                                type: 'text',
                                label: 'Escolha 7',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[7]',
                                type: 'text',
                                label: 'Escolha 8',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[8]',
                                type: 'text',
                                label: 'Escolha 9',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                    ],
                },
            },
            defaultQuestions: [
                {
                    descriptor: {
                        id: 'date',
                        type: 'DateInput',
                        fieldtext: 'De qual dia estamos falando?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'sleepDuration',
                        type: 'DurationInput',
                        fieldtext:
                            'Ao todo, quanto tempo acha que dormiu na noite passada?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'triedToSleep',
                        type: 'ToggleInput',
                        fieldtext: 'Você tentou dormir na noite passada?',
                        textdescriptor: ['Sim', 'Não'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'wakeTime',
                        type: 'DateTimeInput',
                        fieldtext: 'A que horas você acordou?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'getUpTime',
                        type: 'DateTimeInput',
                        fieldtext: 'A que horas se levantou da cama?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'bedTime',
                        type: 'DateTimeInput',
                        fieldtext: 'A que horas você foi para a cama?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'trySleepStart',
                        type: 'DateTimeInput',
                        fieldtext: 'A que horas você tentou dormir?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'latencyDuration',
                        type: 'DurationInput',
                        fieldtext: 'Quanto tempo demorou a cair no sono?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'nOfAwk',
                        type: 'IntInput',
                        fieldtext:
                            'Quantas vezes você despertou ao longo da noite?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'totalDurationOfAwk',
                        type: 'DurationInput',
                        fieldtext: 'Qual a duração total dos despertares?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'sleepSatisfaction',
                        type: 'SliderInput',
                        fieldtext:
                            'O quanto satisfeito(a) você está com seu sono?',
                        minText: 'Pouco',
                        maxText: 'Muito',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                // {
                //     descriptor: {
                //         id: 'sleepSatisfactionQuality',
                //         type: 'ChoiceChipsSelect',
                //         fieldtext: 'Como você avalia a qualidade do seu sono?',
                //         choices: [
                //             'Péssimo',
                //             'Ruim',
                //             'Razoável',
                //             'Bom',
                //             'Ótimo',
                //         ],
                //         default: true,
                //         required: false,
                //         isVisible: false,
                //         editable: false,
                //     },
                // },
                {
                    descriptor: {
                        id: 'nOfNaps',
                        type: 'IntInput',
                        fieldtext: 'Quantas vezes você cochilou ontem?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'durationOfNaps',
                        type: 'DurationInput',
                        fieldtext: 'Qual a duração dos cochilos?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'usesMedicine',
                        type: 'ToggleInput',
                        fieldtext:
                            'Você tomou medicamentos para dormir na noite passada?',
                        textdescriptor: ['Sim', 'Não'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'medicineDescription',
                        type: 'FreeTextInput',
                        fieldtext: 'Descreva os medicamentos:',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'dayType',
                        type: 'ToggleInput',
                        fieldtext:
                            'Hoje é um dia útil (de trabalho, estudo, etc...)?',
                        textdescriptor: ['Sim', 'Não'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'comments',
                        type: 'FreeTextInput',
                        fieldtext: 'Você: tem algo a comentar?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
            ],
            hoursBeforeText: 'Horas antes',
            hoursAfterText: 'Horas depois',
            hoursBeforeExplainText:
                'Determina a quantidade de horas antes da hora atual, na qual o paciente poderá responder a pergunta.',
            hoursAfterExplainText:
                'Determina a quantidade de horas depois da hora atual, na qual o paciente poderá responder a pergunta.',
        },
        manager: {
            editText: 'Editar',
            saveText: 'Salvar',
            deleteText: 'Excluir',
            addText: 'Adicionar',
            addQuestionText: 'Adicionar pergunta',
            cancelText: 'Cancelar',
            yesText: 'Sim',
            noText: 'Não',
            activeText: 'Ativa',
            requiredText: 'Obrigatória',
            answerTypeText: 'Tipo de resposta',
            deleteQuestionText: 'Tem certeza que deseja excluir a pergunta: ',
            successResponseText: 'Diário adicionado com sucesso!',
            diaryText: 'Diário',
            defaultQuestionsText: 'Perguntas padrão',
            customQuestionsText: 'Perguntas customizadas',
            answerTypes: {
                DateInput: 'Data',
                DateTimeInput: 'Data e hora',
                DurationInput: 'Duração',
                IntInput: 'Número',
                SliderInput: 'Escala visual',
                ToggleInput: 'Sim/Não',
                FreeTextInput: 'Texto',
                ChoiceChipsSelect: 'Múltipla escolha',
            },
            fields: {
                diaryFields: [
                    {
                        component: Input,
                        props: {
                            name: 'name',
                            type: 'text',
                            label: 'Nome',
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                ],
                commonFields: [
                    {
                        component: Input,
                        props: {
                            name: 'fieldtext',
                            type: 'text',
                            label: 'Pergunta',
                        },
                        className: 'col-xs-12 col-md-12',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'type',
                            type: 'text',
                            label: 'Tipo de resposta',
                            options: [
                                {
                                    id: 'DateTimeInput',
                                    value: 'DateTimeInput',
                                    label: 'Data e hora',
                                },
                                {
                                    id: 'DurationInput',
                                    value: 'DurationInput',
                                    label: 'Duração',
                                },
                                {
                                    id: 'IntInput',
                                    value: 'IntInput',
                                    label: 'Número',
                                },
                                {
                                    id: 'SliderInput',
                                    value: 'SliderInput',
                                    label: 'Escala visual',
                                },
                                {
                                    id: 'ToggleInput',
                                    value: 'ToggleInput',
                                    label: 'Sim/Não',
                                },
                                {
                                    id: 'FreeTextInput',
                                    value: 'FreeTextInput',
                                    label: 'Texto',
                                },
                                {
                                    id: 'ChoiceChipsSelect',
                                    value: 'ChoiceChipsSelect',
                                    label: 'Múltipla escolha',
                                },
                            ],
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'isVisible',
                            label: 'Ativa',
                            options: [
                                { value: true, label: 'Sim' },
                                { value: false, label: 'Não' },
                            ],
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                ],
                DateTimeInput: [
                    {
                        component: Input,
                        props: {
                            name: 'hoursBefore',
                            type: 'number',
                            label: 'Horas antes',
                            min: 0,
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'hoursAfter',
                            type: 'number',
                            label: 'Horas depois',
                            min: 0,
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                DurationInput: [],
                IntInput: [],
                SliderInput: [
                    {
                        component: Input,
                        props: {
                            name: 'minText',
                            type: 'text',
                            label: 'Mínimo',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'maxText',
                            type: 'text',
                            label: 'Máximo',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                ToggleInput: [
                    {
                        component: Input,
                        props: {
                            name: 'textdescriptor[0]',
                            type: 'text',
                            label: 'Opção 1',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'textdescriptor[1]',
                            type: 'text',
                            label: 'Opção 2',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                FreeTextInput: [],
                ChoiceChipsSelect: {
                    fields: [
                        {
                            component: Input,
                            props: {
                                name: 'quantity',
                                type: 'number',
                                label: 'Quantidade de escolhas',
                                min: 1,
                                max: 9,
                            },
                            className: 'col-xs-12 col-md-6',
                        },
                    ],
                    choices: [
                        {
                            component: Input,
                            props: {
                                name: 'choices[0]',
                                type: 'text',
                                label: 'Escolha 1',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[1]',
                                type: 'text',
                                label: 'Escolha 2',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[2]',
                                type: 'text',
                                label: 'Escolha 3',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[3]',
                                type: 'text',
                                label: 'Escolha 4',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[4]',
                                type: 'text',
                                label: 'Escolha 5',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[5]',
                                type: 'text',
                                label: 'Escolha 6',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[6]',
                                type: 'text',
                                label: 'Escolha 7',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[7]',
                                type: 'text',
                                label: 'Escolha 8',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[8]',
                                type: 'text',
                                label: 'Escolha 9',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                    ],
                },
            },
            defaultQuestions: [
                {
                    descriptor: {
                        id: 'date',
                        type: 'DateInput',
                        fieldtext: 'De qual dia estamos falando?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'sleepDuration',
                        type: 'DurationInput',
                        fieldtext:
                            'Ao todo, quanto tempo acha que dormiu na noite passada?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'triedToSleep',
                        type: 'ToggleInput',
                        fieldtext: 'Você tentou dormir na noite passada?',
                        textdescriptor: ['Sim', 'Não'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'wakeTime',
                        type: 'DateTimeInput',
                        fieldtext: 'A que horas você acordou?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'getUpTime',
                        type: 'DateTimeInput',
                        fieldtext: 'A que horas se levantou da cama?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'bedTime',
                        type: 'DateTimeInput',
                        fieldtext: 'A que horas você foi para a cama?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'trySleepStart',
                        type: 'DateTimeInput',
                        fieldtext: 'A que horas você tentou dormir?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'latencyDuration',
                        type: 'DurationInput',
                        fieldtext: 'Quanto tempo demorou a cair no sono?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'nOfAwk',
                        type: 'IntInput',
                        fieldtext:
                            'Quantas vezes você despertou ao longo da noite?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'totalDurationOfAwk',
                        type: 'DurationInput',
                        fieldtext: 'Qual a duração total dos despertares?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'sleepSatisfaction',
                        type: 'SliderInput',
                        fieldtext:
                            'O quanto satisfeito(a) você está com seu sono?',
                        minText: 'Pouco',
                        maxText: 'Muito',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                // {
                //     descriptor: {
                //         id: 'sleepSatisfactionQuality',
                //         type: 'ChoiceChipsSelect',
                //         fieldtext: 'Como você avalia a qualidade do seu sono?',
                //         choices: [
                //             'Péssimo',
                //             'Ruim',
                //             'Razoável',
                //             'Bom',
                //             'Ótimo',
                //         ],
                //         default: true,
                //         required: false,
                //         isVisible: false,
                //         editable: false,
                //     },
                // },
                {
                    descriptor: {
                        id: 'nOfNaps',
                        type: 'IntInput',
                        fieldtext: 'Quantas vezes você cochilou ontem?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'durationOfNaps',
                        type: 'DurationInput',
                        fieldtext: 'Qual a duração dos cochilos?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'usesMedicine',
                        type: 'ToggleInput',
                        fieldtext:
                            'Você tomou medicamentos para dormir na noite passada?',
                        textdescriptor: ['Sim', 'Não'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'medicineDescription',
                        type: 'FreeTextInput',
                        fieldtext: 'Descreva os medicamentos:',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'dayType',
                        type: 'ToggleInput',
                        fieldtext:
                            'Hoje é um dia útil (de trabalho, estudo, etc...)?',
                        textdescriptor: ['Sim', 'Não'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'comments',
                        type: 'FreeTextInput',
                        fieldtext: 'Você: tem algo a comentar?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
            ],
            hoursBeforeText: 'Horas antes',
            hoursAfterText: 'Horas depois',
            hoursBeforeExplainText:
                'Determina a quantidade de horas antes da hora atual, na qual o paciente poderá responder a pergunta.',
            hoursAfterExplainText:
                'Determina a quantidade de horas depois da hora atual, na qual o paciente poderá responder a pergunta.',
        },
        condor_admin: null,
    },
    en: {
        customer_admin: {
            editText: 'Edit',
            saveText: 'Save',
            deleteText: 'Delete',
            addText: 'Add',
            addQuestionText: 'Add question',
            cancelText: 'Cancel',
            yesText: 'Yes',
            noText: 'No',
            activeText: 'Active',
            requiredText: 'Required',
            answerTypeText: 'Answer type',
            deleteQuestionText:
                'Are you sure you want to delete the question: ',
            successResponseText: 'Diary successfully added!',
            diaryText: 'Diary',
            defaultQuestionsText: 'Default questions',
            customQuestionsText: 'Customized questions',
            answerTypes: {
                DateInput: 'Date',
                DateTimeInput: 'Date and time',
                DurationInput: 'Duration',
                IntInput: 'Number',
                SliderInput: 'Visual Scale',
                ToggleInput: 'Yes/No',
                FreeTextInput: 'Text',
                ChoiceChipsSelect: 'Multiple choice',
            },
            fields: {
                diaryFields: [
                    {
                        component: Input,
                        props: {
                            name: 'name',
                            type: 'text',
                            label: 'Name',
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                ],
                commonFields: [
                    {
                        component: Input,
                        props: {
                            name: 'fieldtext',
                            type: 'text',
                            label: 'Question',
                        },
                        className: 'col-xs-12 col-md-12',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'type',
                            type: 'text',
                            label: 'Answer type',
                            options: [
                                {
                                    id: 'DateTimeInput',
                                    value: 'DateTimeInput',
                                    label: 'Date and time',
                                },
                                {
                                    id: 'DurationInput',
                                    value: 'DurationInput',
                                    label: 'Duration',
                                },
                                {
                                    id: 'IntInput',
                                    value: 'IntInput',
                                    label: 'Number',
                                },
                                {
                                    id: 'SliderInput',
                                    value: 'SliderInput',
                                    label: 'Visual Scale',
                                },
                                {
                                    id: 'ToggleInput',
                                    value: 'ToggleInput',
                                    label: 'Yes/No',
                                },
                                {
                                    id: 'FreeTextInput',
                                    value: 'FreeTextInput',
                                    label: 'Text',
                                },
                                {
                                    id: 'ChoiceChipsSelect',
                                    value: 'ChoiceChipsSelect',
                                    label: 'Multiple choice',
                                },
                            ],
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'isVisible',
                            label: 'Active',
                            options: [
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                            ],
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                ],
                DateTimeInput: [
                    {
                        component: Input,
                        props: {
                            name: 'hoursBefore',
                            type: 'number',
                            label: 'Hours before',
                            min: 0,
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'hoursAfter',
                            type: 'number',
                            label: 'Hours after',
                            min: 0,
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                DurationInput: [],
                IntInput: [],
                SliderInput: [
                    {
                        component: Input,
                        props: {
                            name: 'minText',
                            type: 'text',
                            label: 'Minimum',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'maxText',
                            type: 'text',
                            label: 'Maximum',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                ToggleInput: [
                    {
                        component: Input,
                        props: {
                            name: 'textdescriptor[0]',
                            type: 'text',
                            label: 'Option 1',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'textdescriptor[1]',
                            type: 'text',
                            label: 'Option 2',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                FreeTextInput: [],
                ChoiceChipsSelect: {
                    fields: [
                        {
                            component: Input,
                            props: {
                                name: 'quantity',
                                type: 'number',
                                label: 'Choices quantity',
                                min: 1,
                                max: 9,
                            },
                            className: 'col-xs-12 col-md-6',
                        },
                    ],
                    choices: [
                        {
                            component: Input,
                            props: {
                                name: 'choices[0]',
                                type: 'text',
                                label: 'Choice 1',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[1]',
                                type: 'text',
                                label: 'Choice 2',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[2]',
                                type: 'text',
                                label: 'Choice 3',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[3]',
                                type: 'text',
                                label: 'Choice 4',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[4]',
                                type: 'text',
                                label: 'Choice 5',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[5]',
                                type: 'text',
                                label: 'Choice 6',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[6]',
                                type: 'text',
                                label: 'Choice 7',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[7]',
                                type: 'text',
                                label: 'Choice 8',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[8]',
                                type: 'text',
                                label: 'Choice 9',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                    ],
                },
            },
            defaultQuestions: [
                {
                    descriptor: {
                        id: 'date',
                        type: 'DateInput',
                        fieldtext: 'What day are we talking about?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'sleepDuration',
                        type: 'DurationInput',
                        fieldtext:
                            'In total, how long did you sleep last night?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'triedToSleep',
                        type: 'ToggleInput',
                        fieldtext: 'Did you try to sleep last night?',
                        textdescriptor: ['Yes', 'No'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'wakeTime',
                        type: 'DateTimeInput',
                        fieldtext: 'What time was your final awakening?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'getUpTime',
                        type: 'DateTimeInput',
                        fieldtext:
                            'What time did you get out of bed for the day?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'bedTime',
                        type: 'DateTimeInput',
                        fieldtext: 'What time did you get into bed?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'trySleepStart',
                        type: 'DateTimeInput',
                        fieldtext: 'What time did you try to go to sleep?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'latencyDuration',
                        type: 'DurationInput',
                        fieldtext: 'How long did it take you to fall asleep?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'nOfAwk',
                        type: 'IntInput',
                        fieldtext:
                            'How many times did you wake up, not counting your final awakening?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'totalDurationOfAwk',
                        type: 'DurationInput',
                        fieldtext:
                            'In total, how long did these awakenings last?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'sleepSatisfaction',
                        type: 'SliderInput',
                        fieldtext: 'How satisfied are you with your sleep?',
                        minText: 'Little',
                        maxText: 'Much',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                // {
                //     descriptor: {
                //         id: 'sleepSatisfactionQuality',
                //         type: 'ChoiceChipsSelect',
                //         fieldtext:
                //             'How would you rate the quality of your sleep?',
                //         choices: [
                //             'Very poor',
                //             'Poor',
                //             'Fair',
                //             'Good',
                //             'Very good',
                //         ],
                //         default: true,
                //         required: false,
                //         isVisible: false,
                //         editable: false,
                //     },
                // },
                {
                    descriptor: {
                        id: 'nOfNaps',
                        type: 'IntInput',
                        fieldtext:
                            'How many times did you nap or doze yesterday?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'durationOfNaps',
                        type: 'DurationInput',
                        fieldtext: 'How long did the naps or dozes last?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'usesMedicine',
                        type: 'ToggleInput',
                        fieldtext:
                            'Did you take any medication(s) to help you sleep?',
                        textdescriptor: ['Yes', 'No'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'medicineDescription',
                        type: 'FreeTextInput',
                        fieldtext:
                            'If so, list medication(s), dose, and time taken:',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'dayType',
                        type: 'ToggleInput',
                        fieldtext:
                            'Is today a working day (work, study, etc ...)?',
                        textdescriptor: ['Yes', 'No'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'comments',
                        type: 'FreeTextInput',
                        fieldtext: 'Do you: have anything to say?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
            ],
            hoursBeforeText: 'Hours before',
            hoursAfterText: 'Hours after',
            hoursBeforeExplainText:
                'Determines the number of hours before the current time in which the patient will be able to answer the question.',
            hoursAfterExplainText:
                'Determines the number of hours after the current time in which the patient will be able to answer the question.',
        },
        manager: {
            editText: 'Edit',
            saveText: 'Save',
            deleteText: 'Delete',
            addText: 'Add',
            addQuestionText: 'Add question',
            cancelText: 'Cancel',
            yesText: 'Yes',
            noText: 'No',
            activeText: 'Active',
            requiredText: 'Required',
            answerTypeText: 'Answer type',
            deleteQuestionText:
                'Are you sure you want to delete the question: ',
            successResponseText: 'Diary successfully added!',
            diaryText: 'Diary',
            defaultQuestionsText: 'Default questions',
            customQuestionsText: 'Customized questions',
            answerTypes: {
                DateInput: 'Date',
                DateTimeInput: 'Date and time',
                DurationInput: 'Duration',
                IntInput: 'Number',
                SliderInput: 'Visual Scale',
                ToggleInput: 'Yes/No',
                FreeTextInput: 'Text',
                ChoiceChipsSelect: 'Multiple choice',
            },
            fields: {
                diaryFields: [
                    {
                        component: Input,
                        props: {
                            name: 'name',
                            type: 'text',
                            label: 'Name',
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                ],
                commonFields: [
                    {
                        component: Input,
                        props: {
                            name: 'fieldtext',
                            type: 'text',
                            label: 'Question',
                        },
                        className: 'col-xs-12 col-md-12',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'type',
                            type: 'text',
                            label: 'Answer type',
                            options: [
                                {
                                    id: 'DateTimeInput',
                                    value: 'DateTimeInput',
                                    label: 'Date and time',
                                },
                                {
                                    id: 'DurationInput',
                                    value: 'DurationInput',
                                    label: 'Duration',
                                },
                                {
                                    id: 'IntInput',
                                    value: 'IntInput',
                                    label: 'Number',
                                },
                                {
                                    id: 'SliderInput',
                                    value: 'SliderInput',
                                    label: 'Visual Scale',
                                },
                                {
                                    id: 'ToggleInput',
                                    value: 'ToggleInput',
                                    label: 'Yes/No',
                                },
                                {
                                    id: 'FreeTextInput',
                                    value: 'FreeTextInput',
                                    label: 'Text',
                                },
                                {
                                    id: 'ChoiceChipsSelect',
                                    value: 'ChoiceChipsSelect',
                                    label: 'Multiple choice',
                                },
                            ],
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'isVisible',
                            label: 'Active',
                            options: [
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                            ],
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                ],
                DateTimeInput: [
                    {
                        component: Input,
                        props: {
                            name: 'hoursBefore',
                            type: 'number',
                            label: 'Hours before',
                            min: 0,
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'hoursAfter',
                            type: 'number',
                            label: 'Hours after',
                            min: 0,
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                DurationInput: [],
                IntInput: [],
                SliderInput: [
                    {
                        component: Input,
                        props: {
                            name: 'minText',
                            type: 'text',
                            label: 'Minimum',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'maxText',
                            type: 'text',
                            label: 'Maximum',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                ToggleInput: [
                    {
                        component: Input,
                        props: {
                            name: 'textdescriptor[0]',
                            type: 'text',
                            label: 'Option 1',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'textdescriptor[1]',
                            type: 'text',
                            label: 'Option 2',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                FreeTextInput: [],
                ChoiceChipsSelect: {
                    fields: [
                        {
                            component: Input,
                            props: {
                                name: 'quantity',
                                type: 'number',
                                label: 'Choices quantity',
                                min: 1,
                                max: 9,
                            },
                            className: 'col-xs-12 col-md-6',
                        },
                    ],
                    choices: [
                        {
                            component: Input,
                            props: {
                                name: 'choices[0]',
                                type: 'text',
                                label: 'Choice 1',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[1]',
                                type: 'text',
                                label: 'Choice 2',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[2]',
                                type: 'text',
                                label: 'Choice 3',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[3]',
                                type: 'text',
                                label: 'Choice 4',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[4]',
                                type: 'text',
                                label: 'Choice 5',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[5]',
                                type: 'text',
                                label: 'Choice 6',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[6]',
                                type: 'text',
                                label: 'Choice 7',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[7]',
                                type: 'text',
                                label: 'Choice 8',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[8]',
                                type: 'text',
                                label: 'Choice 9',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                    ],
                },
            },
            defaultQuestions: [
                {
                    descriptor: {
                        id: 'date',
                        type: 'DateInput',
                        fieldtext: 'What day are we talking about?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'sleepDuration',
                        type: 'DurationInput',
                        fieldtext:
                            'In total, how long did you sleep last night?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'triedToSleep',
                        type: 'ToggleInput',
                        fieldtext: 'Did you try to sleep last night?',
                        textdescriptor: ['Yes', 'No'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'wakeTime',
                        type: 'DateTimeInput',
                        fieldtext: 'What time was your final awakening?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'getUpTime',
                        type: 'DateTimeInput',
                        fieldtext:
                            'What time did you get out of bed for the day?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'bedTime',
                        type: 'DateTimeInput',
                        fieldtext: 'What time did you get into bed?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'trySleepStart',
                        type: 'DateTimeInput',
                        fieldtext: 'What time did you try to go to sleep?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'latencyDuration',
                        type: 'DurationInput',
                        fieldtext: 'How long did it take you to fall asleep?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'nOfAwk',
                        type: 'IntInput',
                        fieldtext:
                            'How many times did you wake up, not counting your final awakening?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'totalDurationOfAwk',
                        type: 'DurationInput',
                        fieldtext:
                            'In total, how long did these awakenings last?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'sleepSatisfaction',
                        type: 'SliderInput',
                        fieldtext: 'How satisfied are you with your sleep?',
                        minText: 'Little',
                        maxText: 'Much',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                // {
                //     descriptor: {
                //         id: 'sleepSatisfactionQuality',
                //         type: 'ChoiceChipsSelect',
                //         fieldtext:
                //             'How would you rate the quality of your sleep?',
                //         choices: [
                //             'Very poor',
                //             'Poor',
                //             'Fair',
                //             'Good',
                //             'Very good',
                //         ],
                //         default: true,
                //         required: false,
                //         isVisible: false,
                //         editable: false,
                //     },
                // },
                {
                    descriptor: {
                        id: 'nOfNaps',
                        type: 'IntInput',
                        fieldtext:
                            'How many times did you nap or doze yesterday?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'durationOfNaps',
                        type: 'DurationInput',
                        fieldtext: 'How long did the naps or dozes last?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'usesMedicine',
                        type: 'ToggleInput',
                        fieldtext:
                            'Did you take any medication(s) to help you sleep?',
                        textdescriptor: ['Yes', 'No'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'medicineDescription',
                        type: 'FreeTextInput',
                        fieldtext:
                            'If so, list medication(s), dose, and time taken:',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'dayType',
                        type: 'ToggleInput',
                        fieldtext:
                            'Is today a working day (work, study, etc ...)?',
                        textdescriptor: ['Yes', 'No'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'comments',
                        type: 'FreeTextInput',
                        fieldtext: 'Do you: have anything to say?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
            ],
            hoursBeforeText: 'Hours before',
            hoursAfterText: 'Hours after',
            hoursBeforeExplainText:
                'Determines the number of hours before the current time in which the patient will be able to answer the question.',
            hoursAfterExplainText:
                'Determines the number of hours after the current time in which the patient will be able to answer the question.',
        },
        condor_admin: null,
    },
};

import React from 'react';
import { useSelector } from 'react-redux';
import { ptBR, enUS } from 'date-fns/locale';
import PropTypes from 'prop-types';

import {
    localeWeekDayFormatter,
    localeDateFormatter,
} from '../../../../helpers/formatters';
import Td from '../../../../components/table/td';

export function CommentsTableRows({ tds, ths, locale, completeWeekDays }) {
    const lang = useSelector((state) => state.lang.lang);

    const dateLocale = lang === 'pt' ? ptBR : enUS;

    return completeWeekDays.map((day) => {
        const weekDay = day
            ? localeWeekDayFormatter(day, dateLocale)
                ? localeWeekDayFormatter(day, dateLocale)
                : ''
            : '';

        const date = day
            ? localeDateFormatter(day, locale)
                ? localeDateFormatter(day, locale)
                : ''
            : '';

        return (
            <tr key={crypto.randomUUID()}>
                <Td title={ths[0]} className="capitalize">
                    {weekDay} - {date}
                </Td>
                <Td title={ths[1]}>
                    {tds.weekDays.includes(day)
                        ? tds.medicineDescription[
                              tds.weekDays.findIndex(
                                  (weekDay) => weekDay === day
                              )
                          ]
                        : ''}
                </Td>
                <Td title={ths[2]}>
                    {tds.weekDays.includes(day)
                        ? tds.comments[
                              tds.weekDays.findIndex(
                                  (weekDay) => weekDay === day
                              )
                          ]
                        : ''}
                </Td>
            </tr>
        );
    });
}

CommentsTableRows.propTypes = {
    tds: PropTypes.object.isRequired,
    ths: PropTypes.array.isRequired,
    locale: PropTypes.object.isRequired,
    completeWeekDays: PropTypes.array.isRequired,
};

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Scope } from '@unform/core';
import { Button, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import {
    reactivateTrialSubscription,
    extendTrialSubscription,
    activateSubscription,
} from '../../../../services/api/account';
import { validateForm } from '../../../../helpers/formValidator';
import { setLoading } from '../../../../store/actions/loadingActions';

SubscriptionPlanFields.propTypes = {
    formRef: PropTypes.object.isRequired,
    subscription: PropTypes.object.isRequired,
    setSubscription: PropTypes.func.isRequired,
    fields: PropTypes.array.isRequired,
    texts: PropTypes.shape({
        subscriptionPlansText: PropTypes.string.isRequired,
        editText: PropTypes.string.isRequired,
        successText: PropTypes.string.isRequired,
        cancelText: PropTypes.string.isRequired,
        actionOptionsTexts: PropTypes.shape({
            extend_trial: PropTypes.string.isRequired,
            activate_trial: PropTypes.string.isRequired,
            activate: PropTypes.string.isRequired,
            suspend: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    actionOptions: PropTypes.object.isRequired,
    schemas: PropTypes.object.isRequired,
};

export function SubscriptionPlanFields({
    formRef,
    subscription,
    setSubscription,
    fields,
    texts,
    actionOptions,
    schemas,
}) {
    const [disabledSleepDiarySaveButton, setDisabledSleepDiarySaveButton] =
        useState(true);
    const [disabledSleepDiaryCancelButton, setDisabledSleepDiaryCancelButton] =
        useState(true);
    const [disabledLumusSaveButton, setDisabledLumusSaveButton] =
        useState(true);
    const [disabledLumusCancelButton, setDisabledLumusCancelButton] =
        useState(true);
    const [chosenAction, setChosenAction] = useState({
        sleep_diary: null,
        lumus: null,
    });

    const dispatch = useDispatch();

    function handleChangeAction(option, index) {
        setTimeout(function () {
            setChosenAction((prevState) => {
                const newState = { ...prevState };

                newState[subscription.plans[index].type] = option?.value;

                return newState;
            });

            if (option?.value === 'suspend' || option?.value === 'activate') {
                if (subscription.plans[index].type === 'sleep_diary') {
                    setDisabledSleepDiarySaveButton(false);
                    setDisabledSleepDiaryCancelButton(false);
                } else {
                    setDisabledLumusSaveButton(false);
                    setDisabledLumusCancelButton(false);
                }
            } else {
                if (subscription.plans[index].type === 'sleep_diary') {
                    setDisabledSleepDiarySaveButton(true);
                    setDisabledSleepDiaryCancelButton(true);
                } else {
                    setDisabledLumusSaveButton(true);
                    setDisabledLumusCancelButton(true);
                }
            }
        }, 100);
    }

    function handleChangeSubscriptionPlan(index) {
        setTimeout(function () {
            const days_to_extend = Number(
                formRef.current.getFieldValue(
                    `subscriptionPlans[${index}].days_to_extend`
                )
            );

            const new_expires_at = Number(
                formRef.current.getFieldValue(
                    `subscriptionPlans[${index}].new_expires_at`
                )
            );

            if (days_to_extend !== 0 || new_expires_at !== undefined) {
                if (subscription.plans[index].type === 'sleep_diary') {
                    setDisabledSleepDiarySaveButton(false);
                    setDisabledSleepDiaryCancelButton(false);
                } else {
                    setDisabledLumusSaveButton(false);
                    setDisabledLumusCancelButton(false);
                }
            } else {
                if (subscription.plans[index].type === 'sleep_diary') {
                    setDisabledSleepDiarySaveButton(true);
                    setDisabledSleepDiaryCancelButton(true);
                } else {
                    setDisabledLumusSaveButton(true);
                    setDisabledLumusCancelButton(true);
                }
            }
        }, 100);
    }

    function handleResetSubscriptionPlan(index) {
        setTimeout(function () {
            formRef.current.setFieldValue(
                `subscriptionPlans[${index}].action`,
                { value: null, label: null }
            );

            setChosenAction((prevState) => {
                const newState = { ...prevState };

                newState[subscription.plans[index].type] = null;

                return newState;
            });

            if (subscription.plans[index].type === 'sleep_diary') {
                setDisabledSleepDiarySaveButton(true);
                setDisabledSleepDiaryCancelButton(true);
            } else {
                setDisabledLumusSaveButton(true);
                setDisabledLumusCancelButton(true);
            }
        }, 100);
    }

    async function handleSaveSubscriptionPlan(index) {
        const subscriptionPlanData =
            formRef.current.getData().subscriptionPlans[index];

        const { plan_id, days_to_extend, type, new_expires_at, action } =
            subscriptionPlanData;

        let schemaToUse = null;

        if (action === 'extend_trial') {
            schemaToUse = schemas.extendTrialSchema;
        } else if (action === 'activate_trial') {
            schemaToUse = schemas.activateTrialSchema;
        } else if (action === 'activate') {
            schemaToUse = schemas.activateSchema;
        } else if (action === 'suspend') {
            schemaToUse = schemas.suspendSchema;
        }

        if (schemaToUse !== null) {
            const { parsedData, isValid, errors } = await validateForm(
                { plan_id, days_to_extend, type, new_expires_at, action },
                schemaToUse
            );

            formRef.current.setErrors(errors);

            if (isValid) {
                let response;

                dispatch(setLoading(true));

                if (action === 'extend_trial') {
                    response = await extendTrialSubscription(subscription.id, {
                        type,
                        days_to_extend: parsedData.days_to_extend,
                    });
                }

                if (action === 'activate_trial') {
                    response = await reactivateTrialSubscription(
                        subscription.id,
                        {
                            type,
                            expires_at: new_expires_at,
                        }
                    );
                }

                if (action === 'activate') {
                    response = await activateSubscription(
                        subscription.id,
                        parsedData.plan_id,
                        true
                    );
                }

                if (action === 'suspend') {
                    response = await activateSubscription(
                        subscription.id,
                        parsedData.plan_id,
                        false
                    );
                }

                dispatch(setLoading(false));

                if (response) {
                    toast.success(texts.successText);

                    setSubscription(response.subscription);

                    const tempExpiresAt =
                        response.subscription.plans[index].expires_at;

                    formRef.current.setFieldValue(
                        `subscriptionPlans[${index}].expires_at`,
                        tempExpiresAt ? tempExpiresAt?.slice(0, 10) : 'N/A'
                    );

                    formRef.current.setFieldValue(
                        `subscriptionPlans[${index}].days_to_extend`,
                        0
                    );
                }

                if (subscription.plans[index].type === 'sleep_diary') {
                    setDisabledSleepDiarySaveButton(true);
                    setDisabledSleepDiaryCancelButton(true);
                } else {
                    setDisabledLumusSaveButton(true);
                    setDisabledLumusCancelButton(true);
                }
            }
        }
    }

    return (
        <>
            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-12">
                    <span>{texts.subscriptionPlansText}</span>
                </FormGroup>
            </FormGroup>

            {subscription?.plans?.map((subscriptionPlan, index) => (
                <React.Fragment key={subscriptionPlan.plan_id}>
                    <FormGroup row>
                        <Scope path={`subscriptionPlans[${index}]`}>
                            {fields.map((field) => {
                                if (field.props.name === 'action') {
                                    return (
                                        <FormGroup
                                            key={field.props.name}
                                            className={field.className}
                                        >
                                            <field.component
                                                {...field.props}
                                                options={actionOptions[
                                                    subscriptionPlan.type
                                                ].map((action) => ({
                                                    value: action,
                                                    label: texts
                                                        .actionOptionsTexts[
                                                        action
                                                    ],
                                                }))}
                                                isDisabled={
                                                    actionOptions[
                                                        subscriptionPlan.type
                                                    ].map((action) => ({
                                                        value: action,
                                                        label: texts
                                                            .actionOptionsTexts[
                                                            action
                                                        ],
                                                    })).length === 0
                                                }
                                                onChange={(e) =>
                                                    handleChangeAction(e, index)
                                                }
                                            />
                                        </FormGroup>
                                    );
                                } else if (
                                    field.props.name === 'days_to_extend'
                                ) {
                                    return (
                                        chosenAction[subscriptionPlan.type] ===
                                            'extend_trial' && (
                                            <FormGroup
                                                key={field.props.name}
                                                className={field.className}
                                            >
                                                <field.component
                                                    {...field.props}
                                                    onChange={() =>
                                                        handleChangeSubscriptionPlan(
                                                            index
                                                        )
                                                    }
                                                />
                                            </FormGroup>
                                        )
                                    );
                                } else if (
                                    field.props.name === 'new_expires_at'
                                ) {
                                    return (
                                        chosenAction[subscriptionPlan.type] ===
                                            'activate_trial' && (
                                            <FormGroup
                                                key={field.props.name}
                                                className={field.className}
                                            >
                                                <field.component
                                                    {...field.props}
                                                    onChange={() =>
                                                        handleChangeSubscriptionPlan(
                                                            index
                                                        )
                                                    }
                                                />
                                            </FormGroup>
                                        )
                                    );
                                } else if (field.props.name === 'expires_at') {
                                    return (
                                        <FormGroup
                                            key={field.props.name}
                                            className={field.className}
                                        >
                                            <field.component
                                                {...field.props}
                                                type={
                                                    subscriptionPlan.expires_at !==
                                                    null
                                                        ? 'date'
                                                        : 'text'
                                                }
                                            />
                                        </FormGroup>
                                    );
                                } else {
                                    return (
                                        <FormGroup
                                            key={field.props.name}
                                            className={field.className}
                                        >
                                            <field.component {...field.props} />
                                        </FormGroup>
                                    );
                                }
                            })}
                        </Scope>
                    </FormGroup>
                    <FormGroup row>
                        <div className="col-xs-12 col-md-12 text-left">
                            <Button
                                color="primary mr-2"
                                type="button"
                                disabled={
                                    subscriptionPlan.type === 'sleep_diary'
                                        ? disabledSleepDiarySaveButton
                                        : disabledLumusSaveButton
                                }
                                onClick={() =>
                                    handleSaveSubscriptionPlan(index)
                                }
                            >
                                {texts.editText}
                            </Button>
                            <Button
                                type="button"
                                className="btn btn-secondary"
                                disabled={
                                    subscriptionPlan.type === 'sleep_diary'
                                        ? disabledSleepDiaryCancelButton
                                        : disabledLumusCancelButton
                                }
                                onClick={() =>
                                    handleResetSubscriptionPlan(index)
                                }
                            >
                                {texts.cancelText}
                            </Button>
                        </div>
                    </FormGroup>
                    <hr />
                </React.Fragment>
            ))}
        </>
    );
}

import React from 'react';
import PropTypes from 'prop-types';

import { Plot } from '../../../../../components/plot';

export function Chart({ data, layout, config, height }) {
    return (
        <>
            <div className="contentPrint">
                <div
                    className="col-12Print"
                    style={{
                        height: `${height}rem`,
                    }}
                >
                    <Plot
                        data={data}
                        layout={layout}
                        config={config}
                        useResizeHandler={true}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </div>
            </div>
        </>
    );
}

Chart.propTypes = {
    data: PropTypes.array.isRequired,
    layout: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    height: PropTypes.number.isRequired,
};

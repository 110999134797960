import { formatISO9075 } from 'date-fns';

import { addDays } from './date';

export function creaActivityteTraces(actData, color) {
    let traces = [];

    for (let i = 0; i < actData.length; i++) {
        traces.push({
            marker: {
                color,
                line: {
                    width: 1,
                    color,
                },
            },
            name: '',
            type: 'bar',
            x: actData[i][0],
            y: actData[i][1],
            xaxis: `x${i + 1}`,
            yaxis: `y${i + 1}`,
        });
    }

    return traces;
}

export function createDivisionShape(dateRef) {
    let date = new Date(dateRef[0]);

    return {
        type: 'line',
        x0: formatISO9075(addDays(date, 1)),
        y0: 0,
        x1: formatISO9075(addDays(date, 1)),
        yref: 'paper',
        y1: 1,
        line: { color: 'black', width: 2, dash: 'solid' },
    };
}

export function createOffwristShapes(data, offwrist, color) {
    let positions = {};
    let xMin = null;
    let xMax = null;

    for (let i = 0; i < offwrist.length; i++) {
        positions[i] = [];

        xMin = null;
        xMax = null;

        for (let j = 0; j < offwrist[i].length; j++) {
            if (offwrist[i][j] === 1) {
                if (xMin === null) {
                    xMin = data[i][0][j];
                    xMax = data[i][0][j];
                } else {
                    xMax = data[i][0][j];
                }
            } else {
                if (xMin !== null) {
                    if (xMax !== null) {
                        positions[i].push([xMin, xMax]);

                        xMin = null;
                        xMax = null;
                    }
                }
            }
        }

        if (xMin !== null) {
            if (xMax !== null) {
                positions[i].push([xMin, xMax]);
            } else {
                positions[i].push([xMin, data[i][0][data[i][0].length - 1]]);
            }
        }
    }

    let shapes = [];

    for (let index in positions) {
        for (let i = 0; i < positions[index].length; i++) {
            shapes.push({
                type: 'rect',
                xref: `x${Number(index) + 1}`,
                yref: `y${Number(index) + 1}`,
                x0: positions[index][i][0],
                y0: -10,
                x1: positions[index][i][1],
                y1: 10,
                fillcolor: color,
                opacity: 0.2,
                line: { width: 0 },
            });
        }
    }

    return shapes;
}

export function createSleepingShapes(data, sleeping, color) {
    let positions = {};
    let xMin = null;
    let xMax = null;

    for (let i = 0; i < sleeping.length; i++) {
        positions[i] = [];

        xMin = null;
        xMax = null;

        for (let j = 0; j < sleeping[i].length; j++) {
            if (sleeping[i][j] === 1) {
                if (xMin === null) {
                    xMin = data[i][0][j];
                    xMax = data[i][0][j];
                } else {
                    xMax = data[i][0][j];
                }
            } else {
                if (xMin !== null) {
                    if (xMax !== null) {
                        positions[i].push([xMin, xMax]);

                        xMin = null;
                        xMax = null;
                    }
                }
            }
        }

        if (xMin !== null) {
            if (xMax !== null) {
                positions[i].push([xMin, xMax]);
            } else {
                positions[i].push([xMin, data[i][0][data[i][0].length - 1]]);
            }
        }
    }

    let shapes = [];

    for (let index in positions) {
        for (let i = 0; i < positions[index].length; i++) {
            shapes.push({
                type: 'rect',
                xref: `x${Number(index) + 1}`,
                yref: `y${Number(index) + 1}`,
                x0: positions[index][i][0],
                y0: -10,
                x1: positions[index][i][1],
                y1: 10,
                fillcolor: color,
                opacity: 0.2,
                line: { width: 0 },
            });
        }
    }

    return shapes;
}

export function createRestingShapes(data, resting, color) {
    let positions = {};
    let xMin = null;
    let xMax = null;

    for (let i = 0; i < resting.length; i++) {
        positions[i] = [];

        xMin = null;
        xMax = null;

        for (let j = 0; j < resting[i].length; j++) {
            if (resting[i][j] === 1) {
                if (xMin === null) {
                    xMin = data[i][0][j];
                    xMax = data[i][0][j];
                } else {
                    xMax = data[i][0][j];
                }
            } else {
                if (xMin !== null) {
                    if (xMax !== null) {
                        positions[i].push([xMin, xMax]);

                        xMin = null;
                        xMax = null;
                    }
                }
            }
        }

        if (xMin !== null) {
            if (xMax !== null) {
                positions[i].push([xMin, xMax]);
            } else {
                positions[i].push([xMin, data[i][0][data[i][0].length - 1]]);
            }
        }
    }

    let shapes = [];

    for (let index in positions) {
        for (let i = 0; i < positions[index].length; i++) {
            shapes.push({
                type: 'rect',
                xref: `x${Number(index) + 1}`,
                yref: `y${Number(index) + 1}`,
                x0: positions[index][i][0],
                y0: -10,
                x1: positions[index][i][1],
                y1: 10,
                fillcolor: color,
                opacity: 0.2,
                line: { width: 0 },
            });
        }
    }

    return shapes;
}

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, Button, FormGroup } from 'reactstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import { setLoading } from '../../../store/actions/loadingActions';
import history from '../../../services/history';
import { deleteUser } from '../../../services/api/users';
import Input from '../../../components/inputForm/inputText';

DeleteManager.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function DeleteManager({ currentUser }) {
    const formRef = useRef(null);

    const [manager, setManager] = useState({});

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];

    async function handleSubmit(data) {
        dispatch(setLoading(true));

        const response = await deleteUser(manager.id);

        dispatch(setLoading(false));

        if (response) {
            toast.success(pageData.successText);

            history.push('/managers');
        }
    }

    useEffect(() => {
        if (history.location.state?.user) {
            let tempUser = history.location.state.user;

            const initialData = {
                name: tempUser.name,
                secondary_id: tempUser.secondary_id,
                email: tempUser.email,
                active: tempUser.active ? pageData.yesText : pageData.noText,
                share: tempUser.share ? pageData.yesText : pageData.noText,
            };

            formRef.current.setData(initialData);

            setManager(tempUser);
        } else {
            history.push('/managers');
        }
    }, [lang]);

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-5">
                            <Input
                                name="name"
                                type="text"
                                label={pageData.nameText}
                                readOnly
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="secondary_id"
                                type="text"
                                label={pageData.secondaryIdText}
                                readOnly
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="email"
                                type="text"
                                label={pageData.emailText}
                                readOnly
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="active"
                                type="text"
                                label={pageData.activeText}
                                readOnly
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="share"
                                type="text"
                                label={pageData.sharePatientsText}
                                readOnly
                            />
                        </FormGroup>
                    </FormGroup>

                    <FormGroup row>
                        <div className="col-xs-12 col-md-12 text-left">
                            <Button
                                color="primary"
                                type="submit"
                                className="mr-2"
                            >
                                {pageData.deleteText}
                            </Button>
                            <Link to="/managers" className="btn btn-secondary">
                                {pageData.cancelText}
                            </Link>
                        </div>
                    </FormGroup>
                </Form>
            </CardBody>
        </Card>
    );
}

import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            schema: Yup.object().shape({
                name: Yup.string().required('Campo obrigatório'),
                share: Yup.bool()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Campo obrigatório'),
                descriptor: Yup.object().shape({
                    saveEmail: Yup.bool()
                        .transform((curr, orig) =>
                            orig === '' ? undefined : curr
                        )
                        .required('Campo obrigatório'),
                    defaultLanguage: Yup.string().required('Campo obrigatório'),
                    minPasswordLength: Yup.number()
                        .min(8)
                        .max(30)
                        .required('Campo obrigatório'),
                }),
                user_name: Yup.string().required('Campo obrigatório'),
                secondary_id: Yup.string(),
                email: Yup.string()
                    .email('Formato inválido')
                    .required('Campo obrigatório'),
                role_id: Yup.number().required('Campo obrigatório'),
                active: Yup.bool()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Campo obrigatório'),
                plan: Yup.string().required('Campo obrigatório'),
            }),
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            schema: Yup.object().shape({
                name: Yup.string().required('Required field'),
                share: Yup.bool()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Required field'),
                descriptor: Yup.object().shape({
                    saveEmail: Yup.bool()
                        .transform((curr, orig) =>
                            orig === '' ? undefined : curr
                        )
                        .required('Required field'),
                    defaultLanguage: Yup.string().required('Required field'),
                    minPasswordLength: Yup.number()
                        .min(8)
                        .max(30)
                        .required('Required field'),
                }),
                user_name: Yup.string().required('Required field'),
                secondary_id: Yup.string(),
                email: Yup.string()
                    .email('Invalid format')
                    .required('Required field'),
                role_id: Yup.number().required('Required field'),
                active: Yup.bool()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Required field'),
                plan: Yup.string().required('Required field'),
            }),
        },
    },
};

export function saveMoney(basePrice, discount, period) {
    switch (period) {
        case 'biannual':
            return 6 * (basePrice - discount);
        case 'annual':
            return 12 * (basePrice - discount);
        default:
            return 1 * (basePrice - discount);
    }
}

import { LOCALE } from '../constants';

export function setLocale(data) {
    return {
        type: LOCALE.CHANGE_LOCALE_REQUEST,
        payload: data,
    };
}

export function changeLocale(dateLocale, currencyLocale, currencyFormat) {
    return {
        type: LOCALE.CHANGE_LOCALE,
        payload: { dateLocale, currencyLocale, currencyFormat },
    };
}

import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            percentOffSchema: Yup.object().shape({
                name: Yup.string().required('O nome do cupom é obrigatório'),
                percentOff: Yup.number()
                    .min(0)
                    .max(100)
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('A porcentagem é obrigatória'),
                duration: Yup.number()
                    .min(0)
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('A duração do cupom é obrigatória'),
                currency: Yup.array().of(Yup.bool().required()),
            }),
            amountOffSchema: Yup.object().shape({
                name: Yup.string().required('O nome do cupom é obrigatório'),
                amountOff: Yup.number()
                    .min(0)
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('O valor é obrigatório'),
                duration: Yup.number()
                    .min(0)
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('A duração do cupom é obrigatória'),
                currency: Yup.array().of(Yup.bool().required()),
            }),
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            percentOffSchema: Yup.object().shape({
                name: Yup.string().required('Name is required'),
                percentOff: Yup.number()
                    .min(0)
                    .max(100)
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('Percentage is required'),
                duration: Yup.number()
                    .min(0)
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('Duration is required'),
                currency: Yup.array().of(Yup.bool().required()),
            }),
            amountOffSchema: Yup.object().shape({
                name: Yup.string().required('Name is required'),
                amountOff: Yup.number()
                    .min(0)
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('Amount is required'),
                duration: Yup.number()
                    .min(0)
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('Duration is required'),
                currency: Yup.array().of(Yup.bool().required()),
            }),
        },
    },
};

import { localeDateFormatter } from './formatters';

export function currentWeekType(currentWeek, noDataText) {
    if (currentWeek === null) {
        return noDataText;
    }

    return currentWeek;
}

export function trialStartType(trialStart, locale, noDataText) {
    if (trialStart === null) {
        return noDataText;
    }

    if (localeDateFormatter(trialStart, locale) === null) {
        return '--';
    }

    return localeDateFormatter(trialStart, locale);
}

export function filledDaysPercentageType(percentage, noDataText) {
    let filledDaysPercentage;
    let className;

    filledDaysPercentage = `${percentage}%`;

    switch (true) {
        case percentage === null:
            filledDaysPercentage = noDataText;
            className = '';
            break;
        case percentage <= 33:
            className = 'textRed';
            break;
        case percentage > 33 && percentage <= 66:
            className = 'textOrange';
            break;
        default:
            className = 'textGreen';
            break;
    }

    return { filledDaysPercentage, className };
}

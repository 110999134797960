import React, { useCallback, useState } from 'react';
import { FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';

import styles from './iuguCreditCardForm.module.css';

import { cardNumber, cardEpirationDate, cardCvv } from '../../helpers/masks';
import FormRow from './formRow';

export function IuguCreditCardForm({
    fields,
    imgAltTexts,
    creditCardLabelText,
    iugu,
}) {
    const [brand, setBrand] = useState(null);

    const handleKeyUpCardNumber = useCallback((e) => {
        cardNumber(e, iugu.getBrandByCreditCardNumber(e.currentTarget.value));
        setBrand(iugu.getBrandByCreditCardNumber(e.currentTarget.value));
    }, []);

    const handleKeyUpCardExpiry = useCallback((e) => {
        cardEpirationDate(e);
    }, []);

    const handleKeyUpCardCvv = useCallback((e) => {
        cardCvv(e);
    }, []);

    return (
        <>
            <label
                htmlFor="creditCardForm"
                dangerouslySetInnerHTML={{
                    __html: creditCardLabelText,
                }}
            ></label>
            <div
                id="creditCardForm"
                className={`${styles['usable-creditcard-form']} ${styles.iuguCreditCardForm}`}
            >
                <FormRow>
                    <FormGroup
                        className={`col-md-12 text-center ${styles['input-group']} ${styles.nmb_a}`}
                    >
                        <div
                            className={`${brand ? styles[brand] : ''} ${
                                styles.icon
                            } ${styles['ccic-brand']}`}
                        ></div>
                        <fields.number.component
                            {...fields.number.props}
                            onChange={(e) => handleKeyUpCardNumber(e)}
                        />
                    </FormGroup>
                </FormRow>
                <FormRow className="flex-nowrap">
                    <FormGroup
                        className={`col-md-6 col-xs-6 text-center ${styles['input-group']} ${styles.nmb_b}`}
                    >
                        <div
                            className={`${brand ? styles[brand] : ''} ${
                                styles.icon
                            } ${styles['ccic-cvv']}`}
                        ></div>
                        <fields.verification_value.component
                            {...fields.verification_value.props}
                            maxLength={brand === 'amex' ? 4 : 3}
                            onChange={(e) => handleKeyUpCardCvv(e)}
                        />
                    </FormGroup>
                    <FormGroup
                        className={`col-md-6 col-xs-6 text-center ${styles['input-group']} ${styles.nmb_d}`}
                    >
                        <div
                            className={`${styles.icon} ${styles['ccic-exp']}`}
                        ></div>
                        <fields.expiration.component
                            {...fields.expiration.props}
                            onChange={(e) => handleKeyUpCardExpiry(e)}
                        />
                    </FormGroup>
                </FormRow>
                <FormRow>
                    <FormGroup
                        className={`col-md-12 text-center ${styles['input-group']} ${styles.nmb_c}`}
                    >
                        <div
                            className={`${styles.icon} ${styles['ccic-name']}`}
                        ></div>
                        <fields.full_name.component
                            {...fields.full_name.props}
                        />
                    </FormGroup>
                </FormRow>
                <div className={styles.footerInfo}>
                    <img
                        src="https://bitbucket.org/condorinstprogdev/sleepdiarystaticfiles/raw/5309a12d4897a44ecb397809a6af276524a3d42d/cc-icons.png"
                        alt={imgAltTexts.cardTypes}
                        border="0"
                    />
                    <a className={styles['iugu-btn']} href="http://iugu.com">
                        <img
                            src="https://bitbucket.org/condorinstprogdev/sleepdiarystaticfiles/raw/5309a12d4897a44ecb397809a6af276524a3d42d/payments-by-iugu.png"
                            alt={imgAltTexts.paymentByIugu}
                            border="0"
                        />
                    </a>
                </div>
            </div>
        </>
    );
}

IuguCreditCardForm.propTypes = {
    fields: PropTypes.object.isRequired,
    imgAltTexts: PropTypes.object.isRequired,
    creditCardLabelText: PropTypes.string.isRequired,
    iugu: PropTypes.object.isRequired,
};

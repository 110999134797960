import { all, takeLatest, put } from 'redux-saga/effects';

import { showLoading, hideLoading } from '../actions/loadingActions';
import { LOADING } from '../constants';

export function* setLoading({ payload }) {
    if (payload) {
        yield put(showLoading());
    } else {
        yield put(hideLoading());
    }
}

export default all([takeLatest(LOADING.LOADING_REQUEST, setLoading)]);

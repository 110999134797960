import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import lodash from 'lodash';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { EditPlanForm } from './components/form';
import history from '../../../services/history';

EditPlan.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function EditPlan({ currentUser }) {
    const [plan, setPlan] = useState({});
    const [lumusPricesFields, setLumusPricesFields] = useState([]);

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];
    const schemaData = schemaConfig[lang][currentUser.permissions];

    function handleRemoveClick(index) {
        let tempLumusPricesFields = lodash.cloneDeep(lumusPricesFields);

        tempLumusPricesFields.splice(index, 1);

        setLumusPricesFields(tempLumusPricesFields);
    }

    function handleAddClick() {
        let tempLumusPricesFields = lodash.cloneDeep(
            pageData.lumusPricesFields
        );

        let lumusPriceFieldsUUID = crypto.randomUUID();

        tempLumusPricesFields[0].key = lumusPriceFieldsUUID;
        tempLumusPricesFields[1].key = lumusPriceFieldsUUID;
        tempLumusPricesFields[2].key = lumusPriceFieldsUUID;

        setLumusPricesFields([...lumusPricesFields, tempLumusPricesFields]);
    }

    useEffect(() => {
        if (history.location.state?.entity) {
            let tempPlan = history.location.state?.entity;

            if (tempPlan.type === 'lumus') {
                setLumusPricesFields(
                    tempPlan.prices.map((_) => {
                        let lumusPricesFields = lodash.cloneDeep(
                            pageData.lumusPricesFields
                        );

                        let key = crypto.randomUUID();

                        lumusPricesFields[0].key = key;
                        lumusPricesFields[1].key = key;
                        lumusPricesFields[2].key = key;

                        return lumusPricesFields;
                    })
                );
            }

            setPlan(tempPlan);
        } else {
            history.push('/plans');
        }
    }, []);

    if (Object.entries(plan).length === 0) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <EditPlanForm
                    plan={plan}
                    fields={pageData.fields}
                    schema={schemaData.schema}
                    texts={{
                        editSuccessText: pageData.editSuccessText,
                        editButtonText: pageData.editButtonText,
                        cancelButtonText: pageData.cancelButtonText,
                        intervalTypeOptions: pageData.intervalTypeOptions,
                        payableWithOptions: pageData.payableWithOptions,
                        yesText: pageData.yesText,
                        noText: pageData.noText,
                        planTypes: pageData.planTypes,
                        numberOfDevicesText: pageData.numberOfDevicesText,
                        priceBRLText: pageData.priceBRLText,
                        priceUSDText: pageData.priceUSDText,
                        pricesText: pageData.pricesText,
                        deletePriceText: pageData.deletePriceText,
                        addPriceText: pageData.addPriceText,
                    }}
                    lang={lang}
                    lumusPricesFields={lumusPricesFields}
                    handleRemoveClick={handleRemoveClick}
                    handleAddClick={handleAddClick}
                />
            </CardBody>
        </Card>
    );
}

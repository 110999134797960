import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col,
    Button,
    Spinner,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import lodash from 'lodash';
import PropTypes from 'prop-types';

import '../../../styles/pages/devices/deviceReport/deviceReport.css';

import pageConfig from './page.config';
import history from '../../../services/history';
import {
    fetchDeviceReport,
    fetchLogs,
    fetchHistoryLogs,
} from '../../../services/api/devices';
import { fetchSystemLogDataCsv } from '../../../services/api/files';
import {
    localeDateTimeFormatter,
    localeDateFormatter,
} from '../../../helpers/formatters';
import { setLoading } from '../../../store/actions/loadingActions';
import { DeviceTable } from './components/deviceTable';
import { System } from './components/system';
import { Sensors } from './components/sensors';
import { Memory } from './components/memory';
import { SystemLogs } from './components/systemLogs';

DeviceReport.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function DeviceReport({ currentUser }) {
    const [device, setDevice] = useState(null);
    const [reportData, setReportData] = useState(null);
    const [updatingReportData, setUpdatingReportData] = useState(false);
    const [updatingSystemLogsData, setUpdatingSystemLogsData] = useState(false);
    const [activeTab, setActiveTab] = useState('system');
    const [loadingCsvFile, setLoadingCsvFile] = useState(false);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);
    const isLoading = useSelector((state) => state.loading.isLoading);
    const { dateLocale } = useSelector((state) => state.locale);

    const pageData = pageConfig[lang][currentUser.permissions];

    function toggle(tab) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    async function handleUpdateLogs() {
        setUpdatingReportData(true);

        const data = await fetchLogs(device?.id);

        setReportData({
            system: {
                tableData: {
                    vBat: data?.report?.system?.table_data?.v_bat
                        ? `${data?.report?.system?.table_data?.v_bat} V`
                        : '--',
                    logTime: data?.report?.system?.table_data?.log_time,
                    formattedLogTime:
                        data?.report?.system?.table_data?.log_time !== null
                            ? localeDateTimeFormatter(
                                  data?.report?.system?.table_data?.log_time,
                                  dateLocale
                              )
                                ? localeDateTimeFormatter(
                                      data?.report?.system?.table_data
                                          ?.log_time,
                                      dateLocale
                                  )
                                : '--'
                            : '--',
                    logType: data?.report?.system?.table_data?.log_type,
                    formattedLogType:
                        data?.report?.system?.table_data?.log_type !== null
                            ? pageData.system.logTypeDescriptions[
                                  data?.report?.system?.table_data?.log_type
                              ]
                            : '--',
                },
                chartData: data?.report?.system?.chart_data,
            },
            sensors: {
                actigraphyChart: data?.report?.sensors?.actigraphy_chart,
                temperatureChart: data?.report?.sensors?.temperature_chart,
                lightIntensityChart:
                    data?.report?.sensors?.light_intensity_chart,
                luxChart: data?.report?.sensors?.lux_chart,
                capacitiveChart: data?.report?.sensors?.capacitive_chart,
            },
            memory: {
                sensorsMemoryUsageData:
                    data?.report?.memory?.sensors_memory_usage_data,
                systemMemoryUsageData:
                    data?.report?.memory?.system_memory_usage_data,
            },
            systemLogs: {
                tableData: {
                    lastDeviceConnection: data?.report?.system_logs?.table_data
                        ?.last_device_connection
                        ? localeDateTimeFormatter(
                              data?.report?.system_logs?.table_data
                                  ?.last_device_connection,
                              dateLocale
                          )
                            ? localeDateTimeFormatter(
                                  data?.report?.system_logs?.table_data
                                      ?.last_device_connection,
                                  dateLocale
                              )
                            : '--'
                        : '--',
                    lastServerConnection: data?.report?.system_logs?.table_data
                        ?.last_server_connection
                        ? localeDateTimeFormatter(
                              data?.report?.system_logs?.table_data
                                  ?.last_server_connection,
                              dateLocale
                          )
                            ? localeDateTimeFormatter(
                                  data?.report?.system_logs?.table_data
                                      ?.last_server_connection,
                                  dateLocale
                              )
                            : '--'
                        : '--',
                    payloadKbs:
                        data?.report?.system_logs?.table_data?.payload_kbs ??
                        '--',
                    payloadMsgs:
                        data?.report?.system_logs?.table_data?.payload_msgs ??
                        '--',
                },
                serverConnectionHistoryData:
                    data?.report?.system_logs?.server_connection_history_data?.map(
                        (log) => ({
                            lastServerConnection: log.last_server_connection
                                ? localeDateTimeFormatter(
                                      log?.last_server_connection,
                                      dateLocale
                                  )
                                    ? localeDateTimeFormatter(
                                          log?.last_server_connection,
                                          dateLocale
                                      )
                                    : '--'
                                : '--',
                            // numberOfConnections: log?.number_of_connections ?? '--',
                            totalLogs: log?.total_logs ?? '--',
                        })
                    ),
                eraseDeviceMemoryHistoryData:
                    data?.report?.system_logs?.erase_device_memory_data?.map(
                        (log) => ({
                            id: log.id,
                            userId: log.user_id,
                            deviceId: log.device_id,
                            companyId: log.company_id,
                            type: log.type,
                            logTime: log.log_time
                                ? localeDateTimeFormatter(
                                      log?.log_time,
                                      dateLocale
                                  )
                                    ? localeDateTimeFormatter(
                                          log?.log_time,
                                          dateLocale
                                      )
                                    : '--'
                                : '--',
                        })
                    ),
                fullChargeHistoryData:
                    data?.report?.system_logs?.full_charge_history_data?.map(
                        (log) => ({
                            logTime: localeDateTimeFormatter(log, dateLocale)
                                ? localeDateTimeFormatter(log, dateLocale)
                                : '--',
                        })
                    ),
                batteryUsbStatusHistoryData:
                    data?.report?.system_logs?.battery_usb_status_history_data[0]?.map(
                        (log, index) => ({
                            vBat: data?.report?.system_logs
                                ?.battery_usb_status_history_data[1].vbat[index]
                                ? `${data?.report?.system_logs?.battery_usb_status_history_data[1].vbat[index]} V`
                                : '--',
                            logTime: log,
                            formattedLogTime:
                                log !== null
                                    ? localeDateTimeFormatter(log, dateLocale)
                                        ? localeDateTimeFormatter(
                                              log,
                                              dateLocale
                                          )
                                        : '--'
                                    : '--',
                            logType:
                                data?.report?.system_logs
                                    ?.battery_usb_status_history_data[1]
                                    .log_type[index],
                            formattedLogType:
                                data?.report?.system_logs
                                    ?.battery_usb_status_history_data[1]
                                    .log_type[index] !== null
                                    ? pageData.systemLogs.batteryUsbState
                                          .logTypeDescriptions[
                                          data?.report?.system_logs
                                              ?.battery_usb_status_history_data[1]
                                              .log_type[index]
                                      ]
                                    : '--',
                        })
                    ),
                powerDownHistoryData:
                    data?.report?.system_logs?.power_down_history_data[0]?.map(
                        (log, index) => ({
                            logTime: localeDateTimeFormatter(log, dateLocale)
                                ? localeDateTimeFormatter(log, dateLocale)
                                : '--',
                            vBat: `${data?.report?.system_logs?.power_down_history_data[1][index]} V`,
                        })
                    ),
                bootReasonHistoryData:
                    data?.report?.system_logs?.boot_reason_history_data[0]?.map(
                        (log, index) => ({
                            logTime: localeDateTimeFormatter(log, dateLocale)
                                ? localeDateTimeFormatter(log, dateLocale)
                                : '--',
                            bootCode:
                                data?.report?.system_logs
                                    ?.boot_reason_history_data[1][index],
                            formattedBootCode:
                                pageData.systemLogs.boot.resetReasonsTexts[
                                    data?.report?.system_logs
                                        ?.boot_reason_history_data[1][index]
                                ],
                        })
                    ),
                errorsHistoryData:
                    data?.report?.system_logs?.errors_history_data[0]?.map(
                        (log, index) => ({
                            logTime: localeDateTimeFormatter(log, dateLocale)
                                ? localeDateTimeFormatter(log, dateLocale)
                                : '--',
                            ...pageData.systemLogs.errors.errorsTexts.reduce(
                                (acc, error) => {
                                    return {
                                        ...acc,
                                        [error]:
                                            data?.report?.system_logs?.errors_history_data[1][
                                                index
                                            ].includes(error),
                                    };
                                },
                                {}
                            ),
                        })
                    ),
                associationsHistoryData:
                    data?.report?.system_logs?.associations_history_data?.map(
                        (log) => ({
                            receivedAt: localeDateTimeFormatter(
                                log.received_at,
                                dateLocale
                            )
                                ? localeDateTimeFormatter(
                                      log.received_at,
                                      dateLocale
                                  )
                                : '--',
                            returnedAt: localeDateTimeFormatter(
                                log.returned_at,
                                dateLocale
                            )
                                ? localeDateTimeFormatter(
                                      log.returned_at,
                                      dateLocale
                                  )
                                : '--',
                            lastUserId: log.last_user_id,
                        })
                    ),
            },
        });

        setUpdatingReportData(false);
    }

    const handleUpdateLogType = useCallback(
        async (logType, days) => {
            setUpdatingSystemLogsData(true);

            switch (logType) {
                case 'systemLogs': {
                    const data = await fetchHistoryLogs(device?.id, days);

                    let tempReport = lodash.cloneDeep(reportData);

                    tempReport.systemLogs = {
                        tableData: { ...tempReport.systemLogs.tableData },
                        serverConnectionHistoryData: [
                            ...tempReport.systemLogs
                                .serverConnectionHistoryData,
                        ],
                        eraseDeviceMemoryHistoryData: [
                            ...tempReport.systemLogs
                                .eraseDeviceMemoryHistoryData,
                        ],
                        fullChargeHistoryData: [
                            ...tempReport.systemLogs.fullChargeHistoryData,
                        ],
                        batteryUsbStatusHistoryData: [
                            ...data?.report?.system_logs?.battery_usb_status_history_data[0]?.map(
                                (log, index) => ({
                                    vBat: data?.report?.system_logs
                                        ?.battery_usb_status_history_data[1]
                                        .vbat[index]
                                        ? `${data?.report?.system_logs?.battery_usb_status_history_data[1].vbat[index]} V`
                                        : '--',
                                    logTime: log,
                                    formattedLogTime:
                                        log !== null
                                            ? localeDateTimeFormatter(
                                                  log,
                                                  dateLocale
                                              )
                                                ? localeDateTimeFormatter(
                                                      log,
                                                      dateLocale
                                                  )
                                                : '--'
                                            : '--',
                                    logType:
                                        data?.report?.system_logs
                                            ?.battery_usb_status_history_data[1]
                                            .log_type[index],
                                    formattedLogType:
                                        data?.report?.system_logs
                                            ?.battery_usb_status_history_data[1]
                                            .log_type[index] !== null
                                            ? pageData.systemLogs
                                                  .batteryUsbState
                                                  .logTypeDescriptions[
                                                  data?.report?.system_logs
                                                      ?.battery_usb_status_history_data[1]
                                                      .log_type[index]
                                              ]
                                            : '--',
                                })
                            ),
                        ],
                        powerDownHistoryData: [
                            ...data?.report?.system_logs?.power_down_history_data[0]?.map(
                                (log, index) => ({
                                    logTime: localeDateTimeFormatter(
                                        log,
                                        dateLocale
                                    )
                                        ? localeDateTimeFormatter(
                                              log,
                                              dateLocale
                                          )
                                        : '--',
                                    vBat: `${data?.report?.system_logs?.power_down_history_data[1][index]} V`,
                                })
                            ),
                        ],
                        bootReasonHistoryData: [
                            ...data?.report?.system_logs?.boot_reason_history_data[0]?.map(
                                (log, index) => ({
                                    logTime: localeDateTimeFormatter(
                                        log,
                                        dateLocale
                                    )
                                        ? localeDateTimeFormatter(
                                              log,
                                              dateLocale
                                          )
                                        : '--',
                                    bootCode:
                                        data?.report?.system_logs
                                            ?.boot_reason_history_data[1][
                                            index
                                        ],
                                    formattedBootCode:
                                        pageData.systemLogs.boot
                                            .resetReasonsTexts[
                                            data?.report?.system_logs
                                                ?.boot_reason_history_data[1][
                                                index
                                            ]
                                        ],
                                })
                            ),
                        ],
                        errorsHistoryData: [
                            ...data?.report?.system_logs?.errors_history_data[0]?.map(
                                (log, index) => ({
                                    logTime: localeDateTimeFormatter(
                                        log,
                                        dateLocale
                                    )
                                        ? localeDateTimeFormatter(
                                              log,
                                              dateLocale
                                          )
                                        : '--',
                                    ...pageData.systemLogs.errors.errorsTexts.reduce(
                                        (acc, error) => {
                                            return {
                                                ...acc,
                                                [error]:
                                                    data?.report?.system_logs?.errors_history_data[1][
                                                        index
                                                    ].includes(error),
                                            };
                                        },
                                        {}
                                    ),
                                })
                            ),
                        ],
                        associationsHistoryData: [
                            ...tempReport.systemLogs.associationsHistoryData,
                        ],
                    };

                    setReportData(tempReport);

                    break;
                }
            }

            setUpdatingSystemLogsData(false);
        },
        [device, reportData]
    );

    async function handleExport(name) {
        setLoadingCsvFile(true);

        if (name === 'systemLogData') {
            await fetchSystemLogDataCsv(device.id);
        }

        setLoadingCsvFile(false);
    }

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            if (history.location.state?.entity) {
                let tempDevice = history.location.state.entity;

                const data = await fetchDeviceReport(tempDevice.id);

                setDevice({
                    id: tempDevice.id,
                    serial_no: tempDevice.serial_no,
                    brand: tempDevice.brand,
                    model: tempDevice.model,
                    company_id: tempDevice.company_id,
                    company_name: tempDevice.company_name,
                    purchased_at: tempDevice?.purchased_at
                        ? localeDateFormatter(
                              tempDevice?.purchased_at,
                              dateLocale
                          )
                            ? localeDateFormatter(
                                  tempDevice?.purchased_at,
                                  dateLocale
                              )
                            : '--'
                        : '--',
                    user_id: tempDevice.user_id,
                    user_name:
                        tempDevice?.user_name ?? pageData.notAssociatedText,
                    user_email: tempDevice.user_email,
                    received_at: tempDevice?.received_at
                        ? localeDateFormatter(
                              tempDevice?.received_at,
                              dateLocale
                          )
                            ? localeDateFormatter(
                                  tempDevice?.received_at,
                                  dateLocale
                              )
                            : pageData.notAssociatedText
                        : pageData.notAssociatedText,
                });

                setReportData({
                    system: {
                        tableData: {
                            vBat: data?.report?.system?.table_data?.v_bat
                                ? `${data?.report?.system?.table_data?.v_bat} V`
                                : '--',
                            logTime: data?.report?.system?.table_data?.log_time,
                            formattedLogTime:
                                data?.report?.system?.table_data?.log_time !==
                                null
                                    ? localeDateTimeFormatter(
                                          data?.report?.system?.table_data
                                              ?.log_time,
                                          dateLocale
                                      )
                                        ? localeDateTimeFormatter(
                                              data?.report?.system?.table_data
                                                  ?.log_time,
                                              dateLocale
                                          )
                                        : '--'
                                    : '--',
                            logType: data?.report?.system?.table_data?.log_type,
                            formattedLogType:
                                data?.report?.system?.table_data?.log_type !==
                                null
                                    ? pageData.system.logTypeDescriptions[
                                          data?.report?.system?.table_data
                                              ?.log_type
                                      ]
                                    : '--',
                        },
                        chartData: data?.report?.system?.chart_data,
                    },
                    sensors: {
                        actigraphyChart:
                            data?.report?.sensors?.actigraphy_chart,
                        temperatureChart:
                            data?.report?.sensors?.temperature_chart,
                        lightIntensityChart:
                            data?.report?.sensors?.light_intensity_chart,
                        luxChart: data?.report?.sensors?.lux_chart,
                        capacitiveChart:
                            data?.report?.sensors?.capacitive_chart,
                    },
                    memory: {
                        sensorsMemoryUsageData:
                            data?.report?.memory?.sensors_memory_usage_data,
                        systemMemoryUsageData:
                            data?.report?.memory?.system_memory_usage_data,
                    },
                    systemLogs: {
                        tableData: {
                            lastDeviceConnection: data?.report?.system_logs
                                ?.table_data?.last_device_connection
                                ? localeDateTimeFormatter(
                                      data?.report?.system_logs?.table_data
                                          ?.last_device_connection,
                                      dateLocale
                                  )
                                    ? localeDateTimeFormatter(
                                          data?.report?.system_logs?.table_data
                                              ?.last_device_connection,
                                          dateLocale
                                      )
                                    : '--'
                                : '--',
                            lastServerConnection: data?.report?.system_logs
                                ?.table_data?.last_server_connection
                                ? localeDateTimeFormatter(
                                      data?.report?.system_logs?.table_data
                                          ?.last_server_connection,
                                      dateLocale
                                  )
                                    ? localeDateTimeFormatter(
                                          data?.report?.system_logs?.table_data
                                              ?.last_server_connection,
                                          dateLocale
                                      )
                                    : '--'
                                : '--',
                            payloadKbs:
                                data?.report?.system_logs?.table_data
                                    ?.payload_kbs ?? '--',
                            payloadMsgs:
                                data?.report?.system_logs?.table_data
                                    ?.payload_msgs ?? '--',
                        },
                        serverConnectionHistoryData:
                            data?.report?.system_logs?.server_connection_history_data?.map(
                                (log) => ({
                                    lastServerConnection:
                                        log.last_server_connection
                                            ? localeDateTimeFormatter(
                                                  log?.last_server_connection,
                                                  dateLocale
                                              )
                                                ? localeDateTimeFormatter(
                                                      log?.last_server_connection,
                                                      dateLocale
                                                  )
                                                : '--'
                                            : '--',
                                    // numberOfConnections: log?.number_of_connections ?? '--',
                                    totalLogs: log?.total_logs ?? '--',
                                })
                            ),
                        eraseDeviceMemoryHistoryData:
                            data?.report?.system_logs?.erase_device_memory_data?.map(
                                (log) => ({
                                    id: log.id,
                                    userId: log.user_id,
                                    deviceId: log.device_id,
                                    companyId: log.company_id,
                                    type: log.type,
                                    logTime: log.log_time
                                        ? localeDateTimeFormatter(
                                              log?.log_time,
                                              dateLocale
                                          )
                                            ? localeDateTimeFormatter(
                                                  log?.log_time,
                                                  dateLocale
                                              )
                                            : '--'
                                        : '--',
                                })
                            ),
                        fullChargeHistoryData:
                            data?.report?.system_logs?.full_charge_history_data?.map(
                                (log) => ({
                                    logTime: localeDateTimeFormatter(
                                        log,
                                        dateLocale
                                    )
                                        ? localeDateTimeFormatter(
                                              log,
                                              dateLocale
                                          )
                                        : '--',
                                })
                            ),
                        batteryUsbStatusHistoryData:
                            data?.report?.system_logs?.battery_usb_status_history_data[0]?.map(
                                (log, index) => ({
                                    vBat: data?.report?.system_logs
                                        ?.battery_usb_status_history_data[1]
                                        .vbat[index]
                                        ? `${data?.report?.system_logs?.battery_usb_status_history_data[1].vbat[index]} V`
                                        : '--',
                                    logTime: log,
                                    formattedLogTime:
                                        log !== null
                                            ? localeDateTimeFormatter(
                                                  log,
                                                  dateLocale
                                              )
                                                ? localeDateTimeFormatter(
                                                      log,
                                                      dateLocale
                                                  )
                                                : '--'
                                            : '--',
                                    logType:
                                        data?.report?.system_logs
                                            ?.battery_usb_status_history_data[1]
                                            .log_type[index],
                                    formattedLogType:
                                        data?.report?.system_logs
                                            ?.battery_usb_status_history_data[1]
                                            .log_type[index] !== null
                                            ? pageData.systemLogs
                                                  .batteryUsbState
                                                  .logTypeDescriptions[
                                                  data?.report?.system_logs
                                                      ?.battery_usb_status_history_data[1]
                                                      .log_type[index]
                                              ]
                                            : '--',
                                })
                            ),
                        powerDownHistoryData:
                            data?.report?.system_logs?.power_down_history_data[0]?.map(
                                (log, index) => ({
                                    logTime: localeDateTimeFormatter(
                                        log,
                                        dateLocale
                                    )
                                        ? localeDateTimeFormatter(
                                              log,
                                              dateLocale
                                          )
                                        : '--',
                                    vBat: `${data?.report?.system_logs?.power_down_history_data[1][index]} V`,
                                })
                            ),
                        bootReasonHistoryData:
                            data?.report?.system_logs?.boot_reason_history_data[0]?.map(
                                (log, index) => ({
                                    logTime: localeDateTimeFormatter(
                                        log,
                                        dateLocale
                                    )
                                        ? localeDateTimeFormatter(
                                              log,
                                              dateLocale
                                          )
                                        : '--',
                                    bootCode:
                                        data?.report?.system_logs
                                            ?.boot_reason_history_data[1][
                                            index
                                        ],
                                    formattedBootCode:
                                        pageData.systemLogs.boot
                                            .resetReasonsTexts[
                                            data?.report?.system_logs
                                                ?.boot_reason_history_data[1][
                                                index
                                            ]
                                        ],
                                })
                            ),
                        errorsHistoryData:
                            data?.report?.system_logs?.errors_history_data[0]?.map(
                                (log, index) => ({
                                    logTime: localeDateTimeFormatter(
                                        log,
                                        dateLocale
                                    )
                                        ? localeDateTimeFormatter(
                                              log,
                                              dateLocale
                                          )
                                        : '--',
                                    ...pageData.systemLogs.errors.errorsTexts.reduce(
                                        (acc, error) => {
                                            return {
                                                ...acc,
                                                [error]:
                                                    data?.report?.system_logs?.errors_history_data[1][
                                                        index
                                                    ].includes(error),
                                            };
                                        },
                                        {}
                                    ),
                                })
                            ),
                        associationsHistoryData:
                            data?.report?.system_logs?.associations_history_data?.map(
                                (log) => ({
                                    receivedAt: log?.received_at
                                        ? localeDateTimeFormatter(
                                              log.received_at,
                                              dateLocale
                                          )
                                            ? localeDateTimeFormatter(
                                                  log.received_at,
                                                  dateLocale
                                              )
                                            : '--'
                                        : '--',
                                    returnedAt: log?.returned_at
                                        ? localeDateTimeFormatter(
                                              log.returned_at,
                                              dateLocale
                                          )
                                            ? localeDateTimeFormatter(
                                                  log.returned_at,
                                                  dateLocale
                                              )
                                            : '--'
                                        : '--',
                                    lastUserId: log.last_user_id,
                                })
                            ),
                    },
                });
            } else {
                history.push('/devices');
            }

            dispatch(setLoading(false));
        })();
    }, []);

    useEffect(() => {
        if (reportData !== null) {
            setReportData({
                system: {
                    tableData: {
                        vBat: reportData?.system?.tableData?.vBat,
                        logTime: reportData?.system?.tableData?.logTime,
                        formattedLogTime:
                            reportData?.system?.tableData?.logTime !== null
                                ? localeDateTimeFormatter(
                                      reportData?.system?.tableData?.logTime,
                                      dateLocale
                                  )
                                    ? localeDateTimeFormatter(
                                          reportData?.system?.tableData
                                              ?.logTime,
                                          dateLocale
                                      )
                                    : '--'
                                : '--',
                        logType: reportData?.system?.tableData?.logType,
                        formattedLogType:
                            reportData?.system?.tableData?.logType !== null
                                ? pageData.system.logTypeDescriptions[
                                      reportData?.system?.tableData?.logType
                                  ]
                                : '--',
                    },
                    chartData: reportData?.system?.chartData,
                },
                sensors: {
                    actigraphyChart: reportData?.sensors?.actigraphyChart,
                    temperatureChart: reportData?.sensors?.temperatureChart,
                    lightIntensityChart:
                        reportData?.sensors?.lightIntensityChart,
                    luxChart: reportData?.sensors?.luxChart,
                    capacitiveChart: reportData?.sensors?.capacitiveChart,
                },
                memory: {
                    sensorsMemoryUsageData:
                        reportData?.memory?.sensorsMemoryUsageData,
                    systemMemoryUsageData:
                        reportData?.memory?.systemMemoryUsageData,
                },
                systemLogs: {
                    tableData: {
                        lastDeviceConnection:
                            reportData?.systemLogs?.tableData
                                ?.lastDeviceConnection,
                        lastServerConnection:
                            reportData?.systemLogs?.tableData
                                ?.lastServerConnection,
                        payloadKbs:
                            reportData?.systemLogs?.tableData?.payloadKbs,
                        payloadMsgs:
                            reportData?.systemLogs?.tableData?.payloadMsgs,
                    },
                    serverConnectionHistoryData: [
                        ...reportData?.systemLogs?.serverConnectionHistoryData,
                    ],
                    eraseDeviceMemoryHistoryData: [
                        ...reportData?.systemLogs?.eraseDeviceMemoryHistoryData,
                    ],
                    fullChargeHistoryData: [
                        ...reportData?.systemLogs?.fullChargeHistoryData,
                    ],
                    batteryUsbStatusHistoryData:
                        reportData?.systemLogs?.batteryUsbStatusHistoryData?.map(
                            (log) => ({
                                vBat: log?.vBat,
                                logTime: log?.logTime,
                                formattedLogTime:
                                    log?.logTime !== null
                                        ? localeDateTimeFormatter(
                                              log?.logTime,
                                              dateLocale
                                          )
                                            ? localeDateTimeFormatter(
                                                  log?.logTime,
                                                  dateLocale
                                              )
                                            : '--'
                                        : '--',
                                logType: log?.logType,
                                formattedLogType:
                                    log?.logType !== null
                                        ? pageData.systemLogs.batteryUsbState
                                              .logTypeDescriptions[log?.logType]
                                        : '--',
                            })
                        ),
                    powerDownHistoryData: [
                        ...reportData?.systemLogs?.powerDownHistoryData,
                    ],
                    bootReasonHistoryData:
                        reportData?.systemLogs?.bootReasonHistoryData?.map(
                            (log) => ({
                                logTime: log?.logTime,
                                bootCode: log?.bootCode,
                                formattedBootCode:
                                    pageData.systemLogs.boot.resetReasonsTexts[
                                        log?.bootCode
                                    ],
                            })
                        ),
                    errorsHistoryData: [
                        ...reportData?.systemLogs?.errorsHistoryData,
                    ],
                    associationsHistoryData: [
                        ...reportData?.systemLogs?.associationsHistoryData,
                    ],
                },
            });
        }
    }, [lang]);

    if (isLoading || reportData === null) {
        return <></>;
    }

    return (
        <>
            <Row>
                <Col xs="12">
                    <DeviceTable device={device} textData={pageData} />
                </Col>
            </Row>

            <Row>
                <Col xs="12" className="updateLogContainer">
                    <Button
                        type="button"
                        color="info"
                        onClick={() => handleUpdateLogs()}
                        name="lastUpdate"
                        disabled={updatingReportData}
                    >
                        {pageData.updateText}
                    </Button>

                    <Button
                        type="button"
                        color="info"
                        onClick={(e) => handleExport(e.target.name)}
                        name="systemLogData"
                        disabled={
                            (reportData?.system?.chartData === null &&
                                reportData?.systemLogs
                                    ?.batteryUsbStatusHistoryData?.length ===
                                    0) ||
                            loadingCsvFile
                        }
                    >
                        <FontAwesomeIcon icon={faFileCsv} />{' '}
                        {pageData.systemLogDataText}{' '}
                        {loadingCsvFile && <Spinner size="sm" />}
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col xs="12">
                    <p>{`${pageData.lastUpdateText} ${reportData?.systemLogs?.tableData?.lastServerConnection}`}</p>
                </Col>
            </Row>

            <Row>
                <Col xs="12">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={`${
                                    activeTab === 'system' ? 'active' : ''
                                } systemLink`}
                                onClick={() => toggle('system')}
                            >
                                {pageData.tabsText.systemText}{' '}
                                {updatingReportData && <Spinner size="sm" />}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={`${
                                    activeTab === 'sensors' ? 'active' : ''
                                } sensorsLink`}
                                onClick={() => toggle('sensors')}
                            >
                                {pageData.tabsText.sensorsText}{' '}
                                {updatingReportData && <Spinner size="sm" />}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={`${
                                    activeTab === 'memory' ? 'active' : ''
                                } memoryLink`}
                                onClick={() => toggle('memory')}
                            >
                                {pageData.tabsText.memoryText}{' '}
                                {updatingReportData && <Spinner size="sm" />}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={`${
                                    activeTab === 'systemLogs' ? 'active' : ''
                                } systemLogsLink`}
                                onClick={() => toggle('systemLogs')}
                            >
                                {pageData.tabsText.systemLogsText}{' '}
                                {(updatingReportData ||
                                    updatingSystemLogsData) && (
                                    <Spinner size="sm" />
                                )}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="system" className="systemTabPane">
                            <Row>
                                <System
                                    data={reportData?.system}
                                    textData={pageData.system}
                                    visible={activeTab === 'system'}
                                />
                            </Row>
                        </TabPane>
                        <TabPane tabId="sensors" className="sensorsTabPane">
                            <Row className="sensors">
                                <Sensors
                                    data={reportData.sensors}
                                    textData={pageData.sensors}
                                    visible={activeTab === 'sensors'}
                                />
                            </Row>
                        </TabPane>
                        <TabPane tabId="memory" className="memoryTabPane">
                            <Row className="memory">
                                <Memory
                                    data={reportData?.memory}
                                    textData={pageData.memory}
                                    visible={activeTab === 'memory'}
                                />
                            </Row>
                        </TabPane>
                        <TabPane
                            tabId="systemLogs"
                            className="historicsTabPane"
                        >
                            <Row>
                                <SystemLogs
                                    data={reportData?.systemLogs}
                                    textData={pageData.systemLogs}
                                    handleUpdateLogType={handleUpdateLogType}
                                />
                            </Row>
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </>
    );
}

import React from 'react';
import PropTypes from 'prop-types';

import { localeDateFormatter } from '../../../../../helpers/formatters';
import { TitleSection } from './titleSection';
import { PageNumber } from './pageNumber';

export function UserInfo({ user, data, textData, locale, pageNumbers }) {
    const { weekNumbers, weekReference } = data;

    const lastWeekNumber = String(weekNumbers[weekNumbers.length - 1]);
    const lastWeekReferenceStart = weekReference[weekReference.length - 1][0];
    const lastWeekReferenceEnd = weekReference[weekReference.length - 1][1];

    const {
        sleepDiaryText,
        reportNameText,
        titleSectionText,
        patientText,
        startText,
        currentWeekText,
        weekText,
        weeksText,
    } = textData;

    return (
        <>
            <div className="pagePrint">
                <div className="rowPrint cabecalhoPrint">
                    <div className="contentPrint">
                        <div className="col-2Print">
                            <img
                                src="/assets/logo.png"
                                alt="Logo da Condor"
                                className="logoPrint"
                            />
                        </div>
                        <div
                            className="col-10Print"
                            style={{ paddingLeft: '15px' }}
                        >
                            <h2 className="h2Print">{sleepDiaryText}</h2>
                        </div>
                    </div>
                    <div className="contentPrint">
                        <div
                            className="col-12Print"
                            style={{ textAlign: 'center' }}
                        >
                            <h2 className="h2Print">{reportNameText}</h2>
                        </div>
                    </div>
                </div>

                <div className="rowPrint">
                    <TitleSection title={titleSectionText} />
                    <div
                        className="contentPrint col-12Print"
                        style={{ paddingTop: '10px' }}
                    >
                        <p className="pPrint col-3Print">{patientText}</p>
                        <div className="col-9Print" id="patient">
                            <p className="pPrint">{user.name}</p>
                        </div>
                    </div>
                    <div
                        className="contentPrint col-12Print"
                        style={{ paddingTop: '10px' }}
                    >
                        <p className="pPrint col-3Print">{startText}</p>
                        <div className="col-9Print" id="start">
                            <p className="pPrint">
                                {localeDateFormatter(
                                    user.trials_configurations['trialStart'],
                                    locale
                                )
                                    ? localeDateFormatter(
                                          user.trials_configurations[
                                              'trialStart'
                                          ],
                                          locale
                                      )
                                    : '--'}
                            </p>
                        </div>
                    </div>
                    <div
                        className="contentPrint col-12Print"
                        style={{ paddingTop: '10px' }}
                    >
                        <p className="pPrint col-3Print">{currentWeekText}</p>
                        <div className="col-9Print" id="current-week-number">
                            <p className="pPrint">
                                {`${
                                    lastWeekNumber.includes('-')
                                        ? weeksText
                                        : weekText
                                } ${lastWeekNumber} (${
                                    localeDateFormatter(
                                        lastWeekReferenceStart,
                                        locale
                                    )
                                        ? localeDateFormatter(
                                              lastWeekReferenceStart,
                                              locale
                                          )
                                        : '--'
                                } - ${
                                    localeDateFormatter(
                                        lastWeekReferenceEnd,
                                        locale
                                    )
                                        ? localeDateFormatter(
                                              lastWeekReferenceEnd,
                                              locale
                                          )
                                        : '--'
                                })`}
                            </p>
                        </div>
                    </div>
                </div>

                <PageNumber number={pageNumbers[0]} />
            </div>
        </>
    );
}

UserInfo.propTypes = {
    user: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    textData: PropTypes.object.isRequired,
    locale: PropTypes.object.isRequired,
    pageNumbers: PropTypes.array.isRequired,
};

import Input from '../../../components/inputForm/inputText';
import Radio from '../../../components/inputForm/inputRadio';

export default {
    pt: {
        pageTitle: 'Pronto para começar?',
        pageTitleTrial: 'Pronto para começar o teste grátis?',
        pageSubtitle: 'Preencha o formulário e comece a adicionar pacientes',
        pageSubtitleLumustext:
            'Preencha o formulário e comece a adicionar pacientes e dispositivos',
        formLabel: 'Crie sua conta do Diário de Sono',
        creditCardLabelText: 'Dados do cartão <b style="color: darkred">*</b>',
        fields: {
            companyFields: [
                {
                    component: Input,
                    props: {
                        name: 'company_name',
                        type: 'text',
                        label: 'Nome da empresa <b style="color: darkred">*</b>',
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Seu nome <b style="color: darkred">*</b>',
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        label: 'E-mail <b style="color: darkred">*</b>',
                        type: 'text',
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Radio,
                    props: {
                        name: 'share',
                        label: 'Gostaria de compartilhar pacientes com outros<br/> gestores da empresa? <b style="color: darkred">*</b>',
                        options: [
                            { id: 'yes', value: true, label: 'Sim' },
                            { id: 'no', value: false, label: 'Não' },
                        ],
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'password',
                        label: 'Senha <b style="color: darkred">*</b>',
                        type: 'password',
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'confirm_password',
                        label: 'Confirmação de senha <b style="color: darkred">*</b>',
                        type: 'password',
                    },
                    className: 'col-xs-12 col-md-12',
                },
            ],
            planFields: [
                {
                    component: Input,
                    props: {
                        name: 'plan',
                        type: 'text',
                        label: 'Plano',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'max_patients',
                        type: 'text',
                        label: 'Pacientes ativos',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'number_of_devices',
                        type: 'number',
                        label: 'Número de dispositivos',
                        min: 1,
                        max: 1000000,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'unit_price',
                        type: 'text',
                        label: 'Preço por unidade',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'price',
                        type: 'text',
                        label: 'Preço',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
            ],
            new_card_info: {
                number: {
                    component: Input,
                    props: {
                        name: 'number',
                        type: 'text',
                        'data-iugu': 'number',
                        placeholder: 'Número do cartão',
                        className: 'credit_card_number',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                verification_value: {
                    component: Input,
                    props: {
                        name: 'verification_value',
                        type: 'text',
                        'data-iugu': 'verification_value',
                        placeholder: 'CVV',
                        className: 'credit_card_cvv',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                full_name: {
                    component: Input,
                    props: {
                        name: 'full_name',
                        type: 'text',
                        'data-iugu': 'full_name',
                        placeholder: 'Titular do cartão',
                        className: 'credit_card_name',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                expiration: {
                    component: Input,
                    props: {
                        name: 'expiration',
                        type: 'text',
                        'data-iugu': 'expiration',
                        placeholder: 'MM/AA',
                        className: 'credit_card_expiration',
                    },
                    className: 'col-xs-12 col-md-3',
                },
            },
        },
        imgAltTexts: {
            cardTypes: 'Visa, Master, Diners. Amex',
            paymentByIugu: 'Pagamentos por Iugu',
        },
        createAccount: 'Criar conta',
        creating: 'Aguarde...',
        alreadyHasAnAccount: 'Já tem uma conta? ',
        accessIt: ' Acesse',
        requiredFields: '<b style="color: darkred">*</b> Campos obrigatórios!',
        pageFooter: '© 2024 - SleepDiary',
        successTrialRegister:
            'Conta criada com sucesso! Verifique o e-mail cadastrado para obter as informações de login.',
        successRegister:
            'Estamos processando o seu pagamento! Assim que ele for confirmado, enviaremos informações de login no e-mail cadastrado.',
        paymentFailed: 'Não foi possível processar o pagamento!',
        stripeErrors: {
            expired_card: 'O cartão expirou.',
            incorrect_cvc: 'O código de segurança do cartão está incorreto.',
            processing_error:
                'Ocorreu um erro ao processar o cartão. Tente novamente em instantes.',
            incorrect_number: 'O número do cartão é inválido.',
            card_declined: 'O cartão foi recusado.',
            card_decline_codes: {
                approve_with_id: 'Não é possível autorizar o pagamento.',
                call_issuer:
                    'O cartão foi recusado por um motivo desconhecido.',
                card_not_supported: 'O cartão não aceita este tipo de compra.',
                card_velocity_exceeded:
                    'O cliente excedeu o saldo ou o limite de crédito disponível no cartão.',
                currency_not_supported:
                    'O cartão não aceita a moeda especificada.',
                do_not_honor:
                    'O cartão foi recusado por um motivo desconhecido.',
                duplicate_transaction:
                    'Uma transação com valor e dados de cartão de crédito idênticos foi executada recentemente.',
                expired_card: 'O cartão expirou.',
                insufficient_funds:
                    'O cartão não tem saldo suficiente para concluir a compra.',
                invalid_amount:
                    'O valor do pagamento é inválido ou excede o valor permitido.',
                invalid_cvc: 'O código de segurança do cartão está incorreto.',
                invalid_expiry_month: 'O mês de validade é inválido.',
                invalid_expiry_year: 'O ano de validade é inválido.',
                invalid_number: 'O número do cartão é inválido.',
                not_permitted: 'O pagamento não é permitido.',
                processing_error:
                    'Ocorreu um erro ao processar o cartão. Tente novamente em instantes.',
            },
        },
        failedRegister:
            'Ocorreu um erro ao processar seu pagamento! Verifique os dados do cartão e tente novamente.',
        iuguErrorsText: {
            numberErrorText: 'O número do cartão é inválido.',
            cvvErrorText: 'O código de segurança do cartão está incorreto.',
            fullNameErrorText: 'O nome do titular do cartão é inválido.',
            expirationErrorText: 'A data de validade é inválida.',
            defaultErrorText: 'Erro no cartão.',
        },
        fromText: 'A partir de',
    },
    en: {
        pageTitle: 'Ready to start?',
        pageTitleTrial: 'Ready to start the free trial?',
        pageSubtitle: 'Fill out the form and start adding patients',
        pageSubtitleLumustext:
            'Fill out the form and start adding patients and devices',
        formLabel: 'Create your Sleep Diary account',
        creditCardLabelText: 'Card data <b style="color: darkred">*</b>',
        fields: {
            companyFields: [
                {
                    component: Input,
                    props: {
                        name: 'company_name',
                        type: 'text',
                        label: 'Company name <b style="color: darkred">*</b>',
                        required: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Your name <b style="color: darkred">*</b>',
                        required: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        label: 'E-mail <b style="color: darkred">*</b>',
                        type: 'text',
                        required: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Radio,
                    props: {
                        name: 'share',
                        label: 'Would you like to share patients with other<br/> company managers? <b style="color: darkred">*</b>',
                        options: [
                            { id: 'yes', value: true, label: 'Yes' },
                            { id: 'no', value: false, label: 'No' },
                        ],
                        required: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'password',
                        label: 'Password <b style="color: darkred">*</b>',
                        type: 'password',
                        required: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'confirm_password',
                        label: 'Password confirmation <b style="color: darkred">*</b>',
                        type: 'password',
                        required: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
            ],
            planFields: [
                {
                    component: Input,
                    props: {
                        name: 'plan',
                        type: 'text',
                        label: 'Plan',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'max_patients',
                        type: 'text',
                        label: 'Active patients',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'number_of_devices',
                        type: 'number',
                        label: 'Number of devices',
                        min: 1,
                        max: 1000000,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'unit_price',
                        type: 'text',
                        label: 'Unit price',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
                {
                    component: Input,
                    props: {
                        name: 'price',
                        type: 'text',
                        label: 'Price',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-12',
                },
            ],
            new_card_info: {
                number: {
                    component: Input,
                    props: {
                        name: 'number',
                        type: 'text',
                        'data-iugu': 'number',
                        placeholder: 'Card number',
                        className: 'credit_card_number',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                verification_value: {
                    component: Input,
                    props: {
                        name: 'verification_value',
                        type: 'text',
                        'data-iugu': 'verification_value',
                        placeholder: 'CVV',
                        className: 'credit_card_cvv',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                full_name: {
                    component: Input,
                    props: {
                        name: 'full_name',
                        type: 'text',
                        'data-iugu': 'full_name',
                        placeholder: 'Cardholder',
                        className: 'credit_card_name',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                expiration: {
                    component: Input,
                    props: {
                        name: 'expiration',
                        type: 'text',
                        'data-iugu': 'expiration',
                        placeholder: 'MM/YY',
                        className: 'credit_card_expiration',
                    },
                    className: 'col-xs-12 col-md-3',
                },
            },
        },
        imgAltTexts: {
            cardTypes: 'Visa, Master, Diners. Amex',
            paymentByIugu: 'Payments by Iugu',
        },
        createAccount: 'Create account',
        creating: 'Loading...',
        alreadyHasAnAccount: 'Already has an account? ',
        accessIt: ' Access',
        requiredFields: '<b style="color: darkred">*</b> Required fields!',
        pageFooter: '© 2024 - SleepDiary',
        successTrialRegister:
            'Account succesfully registered! Check the registered email for login information.',
        successRegister:
            'We are processing your payment! As soons as it is confirmed, we will send you login information in the registered e-mail.',
        paymentFailed: 'It was not possible to process your payment!',
        stripeErrors: {
            expired_card: 'The card has expired.',
            incorrect_cvc: 'The card verification code is incorrect.',
            processing_error:
                'An error occurred while processing the card. Please try again in a moment.',
            incorrect_number: 'The card number is invalid.',
            card_declined: 'The card has been declined.',
            card_decline_codes: {
                approve_with_id: 'Cannot approve payment.',
                call_issuer: 'The card was declined for an unknown reason.',
                card_not_supported:
                    'The card does not accept this type of purchase.',
                card_velocity_exceeded:
                    'The customer has exceeded the balance or credit limit available on the card.',
                currency_not_supported:
                    'The card does not accept the specified currency.',
                do_not_honor: 'The card was declined for an unknown reason.',
                duplicate_transaction:
                    'A transaction with identical amount and credit card data was performed recently.',
                expired_card: 'The card has expired.',
                insufficient_funds:
                    'Card does not have sufficient balance to complete purchase.',
                invalid_amount:
                    'The payment amount is invalid or exceeds the allowed amount.',
                invalid_cvc: 'The card verification code is incorrect.',
                invalid_expiry_month: 'The expiration month is invalid.',
                invalid_expiry_year: 'The expiration year is invalid.',
                invalid_number: 'The card number is invalid.',
                not_permitted: 'Payment is not allowed.',
                processing_error:
                    'An error occurred while processing the card. Please try again in a moment.',
            },
        },
        failedRegister:
            'There was an error processing your payment! Please check the card details and try again.',
        iuguErrorsText: {
            numberErrorText: 'Card number is invalid!',
            cvvErrorText: 'Security code is incorrect!',
            fullNameErrorText: 'Cardholder is incorrect!',
            expirationErrorText: 'Invalid expiry date!',
            defaultErrorText: 'Card error!',
        },
        fromText: 'From',
    },
};

import * as Yup from 'yup';

export default {
    pt: {
        schema: Yup.object().shape({
            user_id: Yup.string().required('O ID do usuário é obrigatório'),
            company_id: Yup.string().required('O ID da empresa é obrigatório'),
        }),
    },
    en: {
        schema: Yup.object().shape({
            user_id: Yup.string().required('User ID is required'),
            company_id: Yup.string().required('Company ID is required'),
        }),
    },
};

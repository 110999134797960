import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import styles from './editModal.module.css';
import { CustomForm as Form } from './form';

export function EditModal({
    question,
    texts,
    fields,
    schemas,
    handleEditQuestion,
}) {
    const [modal, setModal] = useState(false);

    function toggle() {
        setModal(!modal);
    }

    return (
        <>
            <Button type="button" onClick={toggle} title={texts.editText}>
                <FontAwesomeIcon icon={faEdit} />
            </Button>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader className={styles.headerContainer}>
                    {texts.editText}
                </ModalHeader>
                <ModalBody className={styles.bodyContainer}>
                    <Form
                        schemas={schemas}
                        fields={fields}
                        texts={texts}
                        toggle={toggle}
                        handleEditQuestion={handleEditQuestion}
                        question={question}
                    />
                </ModalBody>
            </Modal>
        </>
    );
}

EditModal.propTypes = {
    question: PropTypes.object.isRequired,
    texts: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    schemas: PropTypes.object.isRequired,
    handleEditQuestion: PropTypes.func.isRequired,
};

import { createTransform } from 'redux-persist';

import { ptBR, enUS } from 'date-fns/locale';

const locales = {
    [ptBR.code]: ptBR,
    [enUS.code]: enUS,
};

const SetTransform = createTransform(
    // transform state on its way to being serialized and persisted.
    (inboundState, key) => {
        // convert dateLocale object to a String.
        return { ...inboundState, dateLocale: inboundState.dateLocale.code };
    },

    // transform state being rehydrated
    (outboundState, key) => {
        // convert dateLocale back to an Object.
        return {
            ...outboundState,
            dateLocale: locales[outboundState.dateLocale],
        };
    },

    // define which reducers this transform gets called for.
    { whitelist: ['locale'] }
);

export default SetTransform;

import React from 'react';
import PropTypes from 'prop-types';

function TableHeaders({ ths }) {
    return ths.map((th) => {
        return <th key={th}>{th}</th>;
    });
}

TableHeaders.propTypes = {
    ths: PropTypes.array.isRequired,
};

export default TableHeaders;

import React from 'react';
import PropTypes from 'prop-types';

export function PageNumber({ number }) {
    return (
        <>
            <p className="pPrint page-numberPrint">{number}</p>
        </>
    );
}

PageNumber.propTypes = {
    number: PropTypes.number.isRequired,
};

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import history from '../../../services/history';
import { EditCouponForm } from './components/form';

export default function EditCoupon({ currentUser }) {
    const [coupon, setCoupon] = useState({});

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];
    const { percentOffSchema, amountOffSchema } =
        schemaConfig[lang][currentUser.permissions];

    useEffect(() => {
        if (history.location.state?.entity) {
            setCoupon(history.location.state.entity);
        } else {
            history.push('/coupons');
        }
    }, []);

    if (Object.entries(coupon).length === 0) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <EditCouponForm
                    coupon={coupon}
                    schemas={{ percentOffSchema, amountOffSchema }}
                    fields={pageData.fields}
                    texts={{
                        editSuccessText: pageData.editSuccessText,
                        editText: pageData.editText,
                        cancelText: pageData.cancelText,
                        currencyText: pageData.currencyText,
                        percentageText: pageData.percentageText,
                        amountText: pageData.amountText,
                        selectCurrencyErrorText:
                            pageData?.selectCurrencyErrorText,
                    }}
                    lang={lang}
                />
            </CardBody>
        </Card>
    );
}

EditCoupon.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import styles from './tableContainer.module.css';

import Table from '../../../components/table/table';
import { ThWithOrdering } from '../../../components/table/thWithOrdering';
import { TableRows } from './tableRows';

export function TableContainer({
    ths,
    tds,
    texts,
    filteredPlans,
    actions,
    locale,
    currency_pt_BR,
    currency_en_US,
    activeFilter,
    orderByProperty,
}) {
    return (
        <Row>
            <Col xs="12">
                <Table className={styles.plansTable}>
                    <thead>
                        <tr>
                            {ths.map((th) => {
                                if (
                                    [
                                        'name',
                                        'price_brl',
                                        'price_usd',
                                        'max_patients',
                                        'created_at',
                                        'updated_at',
                                    ].includes(th.property)
                                ) {
                                    return (
                                        <ThWithOrdering
                                            key={th.property}
                                            property={th.property}
                                            activeFilterProperty={
                                                activeFilter.property
                                            }
                                            activeOrder={activeFilter.order}
                                            orderByProperty={orderByProperty}
                                            activeClassName={styles.active}
                                            clickableIconClassName={
                                                styles.clickableIcon
                                            }
                                        >
                                            {th.name}
                                        </ThWithOrdering>
                                    );
                                }

                                return <th key={th.property}>{th.name}</th>;
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <TableRows
                            plans={filteredPlans}
                            tds={tds}
                            texts={texts}
                            actions={actions}
                            locale={locale}
                            currency_pt_BR={currency_pt_BR}
                            currency_en_US={currency_en_US}
                        />
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}

TableContainer.propTypes = {
    ths: PropTypes.array.isRequired,
    tds: PropTypes.array.isRequired,
    texts: PropTypes.object.isRequired,
    filteredPlans: PropTypes.array.isRequired,
    actions: PropTypes.array,
    locale: PropTypes.object.isRequired,
    currency_pt_BR: PropTypes.object.isRequired,
    currency_en_US: PropTypes.object.isRequired,
    activeFilter: PropTypes.object.isRequired,
    orderByProperty: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import TableTds from './tableTds';

function TableRows({ companies, tds, actions, shareText, dosentShareText }) {
    return companies.map((company) => {
        return (
            <tr key={company.id}>
                <TableTds
                    tds={tds}
                    company={company}
                    actions={actions}
                    shareText={shareText}
                    dosentShareText={dosentShareText}
                />
            </tr>
        );
    });
}

TableRows.propTypes = {
    companies: PropTypes.array.isRequired,
    tds: PropTypes.array.isRequired,
    actions: PropTypes.array,
    shareText: PropTypes.string.isRequired,
    dosentShareText: PropTypes.string.isRequired,
};

export default TableRows;

import Input from '../../../components/inputForm/inputText';

export default {
    pt: {
        fields: [
            {
                component: Input,
                props: {
                    name: 'name',
                    type: 'text',
                    label: 'Nome',
                },
                className: 'col-xs-12 col-md-5',
            },
            {
                component: Input,
                props: {
                    name: 'secondary_id',
                    type: 'text',
                    label: 'ID Secundário',
                },
                className: 'col-xs-12 col-md-3',
            },
            {
                component: Input,
                props: {
                    name: 'email',
                    type: 'text',
                    label: 'Email',
                },
                className: 'col-xs-12 col-md-3',
            },
        ],
        passwordFields: [
            {
                component: Input,
                props: {
                    name: 'password',
                    type: 'password',
                    label: 'Nova senha',
                },
                className: 'col-xs-12 col-md-3',
            },
            {
                component: Input,
                props: {
                    name: 'confirm_password',
                    type: 'password',
                    label: 'Confirmação de nova senha',
                },
                className: 'col-xs-12 col-md-3',
            },
        ],
        saveText: 'Salvar',
        cancelText: 'Cancelar',
        yesText: 'Sim',
        noText: 'Não',
        successText: 'Perfil editado com sucesso!',
        nameAndEmailText: 'Nome e email',
        editText: 'Editar',
        deleteText: 'Excluir',
        passwordText: 'Senha',
        passwordMustContainSpecialCharacterText:
            'A senha deve conter pelo menos 1 caracter especial.',
    },
    en: {
        fields: [
            {
                component: Input,
                props: {
                    name: 'name',
                    type: 'text',
                    label: 'Name',
                },
                className: 'col-xs-12 col-md-5',
            },
            {
                component: Input,
                props: {
                    name: 'secondary_id',
                    type: 'text',
                    label: 'Secondary ID',
                },
                className: 'col-xs-12 col-md-3',
            },
            {
                component: Input,
                props: {
                    name: 'email',
                    type: 'text',
                    label: 'Email',
                },
                className: 'col-xs-12 col-md-3',
            },
        ],
        passwordFields: [
            {
                component: Input,
                props: {
                    name: 'password',
                    type: 'password',
                    label: 'New Password',
                },
                className: 'col-xs-12 col-md-3',
            },
            {
                component: Input,
                props: {
                    name: 'confirm_password',
                    type: 'password',
                    label: 'Confirmation of new password',
                },
                className: 'col-xs-12 col-md-3',
            },
        ],
        saveText: 'Save',
        cancelText: 'Cancel',
        yesText: 'Yes',
        noText: 'No',
        successText: 'Profile successfully edited!',
        nameAndEmailText: 'Name and email',
        editText: 'Edit',
        deleteText: 'Delete',
        passwordText: 'Password',
        passwordMustContainSpecialCharacterText:
            'Password must contain at least 1 special character.',
    },
};

import React from 'react';
import PropTypes from 'prop-types';

ChartLabel.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    type: PropTypes.string,
};

export function ChartLabel({ name, color, type }) {
    return (
        <div className="d-inline-flex justify-content-between align-items-center">
            <div
                className="chartLabelColorContainer"
                style={{
                    backgroundColor: type === 'state' ? color : 'transparent',
                }}
            >
                {type !== 'state' && (
                    <hr
                        style={{
                            borderColor: color,
                            borderStyle: type === 'data' ? 'solid' : 'dashed',
                        }}
                    />
                )}
            </div>
            <p className="ml-2 mr-3">{name}</p>
        </div>
    );
}

import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardFooter, Button, FormGroup } from 'reactstrap';
import { Form } from '@unform/web';
import {
    CardNumberElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import history from '../../../../services/history';
import {
    createStripeSetupIntent,
    updatePaymentMethod,
    payPendingInvoice,
} from '../../../../services/api/account';
import { setLoading } from '../../../../store/actions/loadingActions';
import useIugu from '../../../../hooks/useIugu';
import {
    localeDateFormatter,
    currencyFormatter,
} from '../../../../helpers/formatters';
import { validateForm } from '../../../../helpers/formValidator';
import Input from '../../../../components/inputForm/inputText';
import { IuguCreditCardForm } from '../../../../components/form/iuguCreditCardForm';
import { StripeForm } from '../../../../components/form/stripeCreditCardForm';

PayPendingInvoicePage.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function PayPendingInvoicePage({ currentUser }) {
    const iugu = useIugu();

    const stripe = useStripe();
    const elements = useElements();

    const formRef = useRef(null);

    const [currency, setCurrency] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState({});
    const [pendingInvoice, setPendingInvoice] = useState(null);

    const [paymentIntentClientSecret, setPaymentIntentClientSecret] =
        useState(null);

    const [cardNumberComplete, setCardNumberComplete] = useState(
        !!paymentMethod
    );
    const [cardExpiryComplete, setCardExpiryComplete] = useState(
        !!paymentMethod
    );
    const [cardCvcComplete, setCardCvcComplete] = useState(!!paymentMethod);
    const [filledInForm, setFilledInForm] = useState(!!paymentMethod);
    const [formReady, setFormReady] = useState(true);
    const [addNewCard, setAddNewCard] = useState(false);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);
    const { dateLocale } = useSelector((state) => state.locale);

    const pageData = pageConfig[lang][currentUser.permissions];
    const { schemaWithOldCard, schemaWithNewCard, schemaWithoutCard } =
        schemaConfig[lang][currentUser.permissions];

    async function handleSubmit(data, { reset }, event) {
        event.preventDefault();

        const { parsedData, isValid, errors } = await validateForm(
            data,
            addNewCard
                ? currentUser.subscription.locale === 'pt-BR'
                    ? schemaWithNewCard
                    : schemaWithOldCard
                : schemaWithoutCard
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(setLoading(true));

            const sendData = {
                invoice_id: null,
                token: null,
                description: null,
            };

            if (currency.locale === 'pt-BR') {
                sendData.description = parsedData?.description;
                sendData.invoice_id = pendingInvoice.id;

                // avoid sending the form if iugu script has not yet been loaded
                if (!iugu.impl()) {
                    dispatch(setLoading(false));
                    return;
                }

                // check if user already has a payment method, if not, create a payment token
                if (addNewCard) {
                    const createTokenResponse = await iugu.createPaymentToken(
                        event.target
                    );

                    if (createTokenResponse?.errors) {
                        dispatch(setLoading(false));

                        if ('incorrect_number' in createTokenResponse.errors) {
                            toast.error(
                                pageData.iuguErrorsText.numberErrorText
                            );
                        } else if (
                            'incorrect_cvv' in createTokenResponse.errors
                        ) {
                            toast.error(pageData.iuguErrorsText.cvvErrorText);
                        } else if (
                            'incorrect_fullName' in createTokenResponse.errors
                        ) {
                            toast.error(
                                pageData.iuguErrorsText.fullNameErrorText
                            );
                        } else if (
                            'incorrect_expiration' in createTokenResponse.errors
                        ) {
                            toast.error(
                                pageData.iuguErrorsText.expirationErrorText
                            );
                        } else {
                            toast.error(
                                pageData.iuguErrorsText.defaultErrorText
                            );
                        }

                        return;
                    }

                    sendData.description = parsedData.new_description;
                    sendData.token = createTokenResponse.id;
                }

                const response = await payPendingInvoice(sendData);

                if (response) {
                    toast.success(pageData.successText);

                    history.push('/account/my_account');
                }
            } else {
                // avoid sending the form if stripe promise has not yet been fulfilled
                if (!stripe || !elements) {
                    dispatch(setLoading(false));
                    return;
                }

                if (!paymentIntentClientSecret) {
                    dispatch(setLoading(false));
                    return;
                }

                let tempStripePaymentMethodId = paymentMethod['id'];

                // check if user already has a payment method, if not,
                // create a setup intent to update the payment method
                if (addNewCard) {
                    const createStripeSetupIntentResponse =
                        await createStripeSetupIntent();

                    if (!createStripeSetupIntentResponse) {
                        toast.error(pageData.unableToUpdateCardText);

                        dispatch(setLoading(false));

                        return;
                    }

                    const setupIntentClientSecret =
                        createStripeSetupIntentResponse?.setup_intent_client_secret;

                    if (!setupIntentClientSecret) {
                        toast.error(pageData.unableToUpdateCardText);

                        dispatch(setLoading(false));

                        return;
                    }

                    // confirm stripe setup intent to attach new payment method to customer

                    const confirmCardSetupResult =
                        await stripe.confirmCardSetup(setupIntentClientSecret, {
                            payment_method: {
                                card: elements.getElement(CardNumberElement),
                                billing_details: {
                                    name: currentUser.company_name,
                                },
                            },
                        });

                    if (confirmCardSetupResult.error) {
                        dispatch(setLoading(false));

                        toast.error(
                            confirmCardSetupResult.error.code in
                                pageData.stripeTexts
                                ? pageData.stripeTexts[
                                      confirmCardSetupResult.error.code
                                  ]
                                : pageData.unableToUpdateCardText
                        );

                        return;
                    }

                    if (
                        confirmCardSetupResult?.setupIntent?.status !==
                        'succeeded'
                    ) {
                        dispatch(setLoading(false));

                        toast.error(pageData.unableToUpdateCardText);

                        return;
                    }

                    tempStripePaymentMethodId =
                        confirmCardSetupResult.setupIntent.payment_method;

                    const updatePaymentMethodResponse =
                        await updatePaymentMethod({
                            payment_method_id: tempStripePaymentMethodId,
                        });

                    if (!updatePaymentMethodResponse) {
                        toast.error(pageData.unableToUpdateCardText);

                        dispatch(setLoading(false));

                        return;
                    }
                }

                const result = await stripe.confirmCardPayment(
                    paymentIntentClientSecret,
                    {
                        payment_method: tempStripePaymentMethodId,
                    }
                );

                if (result.error) {
                    dispatch(setLoading(false));

                    toast.error(
                        result.error.code in pageData.stripeTexts
                            ? pageData.stripeTexts[result.error.code]
                            : pageData.unableToPayInvoiceText
                    );

                    return;
                }

                if (result.paymentIntent.status === 'succeeded') {
                    toast.success(pageData.successText);

                    history.push('/account/my_account');
                }
            }

            dispatch(setLoading(false));
        }
    }

    function handleKeyUpForm() {
        const formData = formRef.current.getData();

        let empty = 0;

        for (let key in formData) {
            if (formData[key] === '') {
                empty += 1;
            }
        }

        setFilledInForm(empty ? false : true);
    }

    function createNewCardField() {
        setAddNewCard(true);

        setCardNumberComplete(false);
        setCardCvcComplete(false);
        setCardExpiryComplete(false);
        setFilledInForm(false);
        setFormReady(false);
    }

    function deleteNewCardField() {
        setAddNewCard(false);

        setCardNumberComplete(true);
        setCardCvcComplete(true);
        setCardExpiryComplete(true);
        setFilledInForm(true);
        setFormReady(true);

        setTimeout(() => {
            if (currency.format === 'BRL') {
                formRef.current.setFieldValue(
                    'description',
                    paymentMethod['description']
                );
                formRef.current.setFieldValue(
                    'display_number',
                    paymentMethod['data']['display_number']
                );
                formRef.current.setFieldValue(
                    'expiration_date',
                    `${paymentMethod['data']['month']}/${paymentMethod['data']['year']}`
                );
            } else {
                formRef.current.setFieldValue(
                    'description',
                    paymentMethod['description']
                );
                formRef.current.setFieldValue(
                    'display_number',
                    '•••• •••• •••• ' + paymentMethod['card']['last4']
                );
                formRef.current.setFieldValue(
                    'expiration_date',
                    `${paymentMethod['card']['exp_month']}/${paymentMethod['card']['exp_year']}`
                );
            }
        }, 50);
    }

    useEffect(() => {
        if (
            history.location.state?.paymentMethod &&
            history.location.state?.pendingInvoice
        ) {
            const tempCurrency = history.location.state.currency;
            const tempPaymentMethod = history.location.state.paymentMethod;
            const tempPendingInvoice = history.location.state.pendingInvoice;

            setCurrency(tempCurrency);
            setPaymentMethod(tempPaymentMethod);
            setPendingInvoice(tempPendingInvoice);

            if (currentUser?.subscription.locale === 'en-US') {
                setPaymentIntentClientSecret(
                    tempPendingInvoice.payment_intent.client_secret
                );
            }

            formRef.current.reset();

            let initialData = {};

            if (currentUser?.subscription.locale === 'pt-BR') {
                initialData = {
                    plan_name:
                        currentUser.subscription.plans.find(
                            (subscriptionPlan) =>
                                subscriptionPlan.iugu_id ===
                                tempPendingInvoice.subscription_id
                        )?.plan.name ?? '--',
                    due_date: localeDateFormatter(
                        tempPendingInvoice['due_date'],
                        dateLocale
                    ),
                    price: currencyFormatter(
                        tempPendingInvoice['total_cents'],
                        tempCurrency.locale,
                        tempCurrency.format
                    ),
                    description: tempPaymentMethod['description'],
                    display_number: tempPaymentMethod['data']['display_number'],
                    expiration_date: `${tempPaymentMethod['data']['month']}/${tempPaymentMethod['data']['year']}`,
                };
            } else {
                initialData = {
                    plan_name:
                        currentUser.subscription.plans.find(
                            (subscriptionPlan) =>
                                subscriptionPlan.stripe_id ===
                                tempPendingInvoice.subscription
                        )?.plan.name ?? '--',
                    due_date: localeDateFormatter(
                        new Date(tempPendingInvoice['created'] * 1000),
                        dateLocale
                    ),
                    price: currencyFormatter(
                        tempPendingInvoice['amount_due'],
                        tempCurrency.locale,
                        tempCurrency.format
                    ),
                    description: tempPaymentMethod['description'],
                    display_number: `•••• •••• •••• ${tempPaymentMethod['card']['last4']}`,
                    expiration_date: `${tempPaymentMethod['card']['exp_month']}/${tempPaymentMethod['card']['exp_year']}`,
                };
            }

            formRef.current.setData(initialData);
        } else {
            history.push('/account/my_account');
        }
    }, []);

    useEffect(() => {
        if (currency !== null) {
            if (currency.format === 'BRL') {
                if (filledInForm) {
                    setFormReady(true);
                } else {
                    setFormReady(false);
                }
            } else {
                if (
                    cardNumberComplete &&
                    cardExpiryComplete &&
                    cardCvcComplete
                ) {
                    setFormReady(true);
                } else {
                    setFormReady(false);
                }
            }
        }
    }, [cardNumberComplete, cardExpiryComplete, cardCvcComplete, filledInForm]);

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    onKeyUp={handleKeyUpForm}
                >
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-12">
                            <span>{pageData.pendingInvoiceText}</span>
                        </FormGroup>
                    </FormGroup>

                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-4">
                            <Input
                                name="plan_name"
                                type="text"
                                label={pageData.planText}
                                readOnly
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-4">
                            <Input
                                name="due_date"
                                type="text"
                                label={pageData.dueDateText}
                                readOnly
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-4">
                            <Input
                                name="price"
                                type="text"
                                label={pageData.amountText}
                                readOnly
                            />
                        </FormGroup>
                    </FormGroup>

                    {!addNewCard && (
                        <>
                            <FormGroup row>
                                <FormGroup className="col-xs-12 col-md-12">
                                    <span>{pageData.cardDataText}</span>
                                </FormGroup>
                            </FormGroup>

                            <FormGroup row>
                                {currentUser.subscription.locale ===
                                    'pt-BR' && (
                                    <FormGroup className="col-xs-12 col-md-3">
                                        <Input
                                            name="description"
                                            type="text"
                                            label={pageData.cardDescriptionText}
                                            readOnly
                                        />
                                    </FormGroup>
                                )}

                                <FormGroup className="col-xs-12 col-md-3">
                                    <Input
                                        name="display_number"
                                        type="text"
                                        label={pageData.cardNumberText}
                                        readOnly
                                    />
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-3">
                                    <Input
                                        name="expiration_date"
                                        type="text"
                                        label={pageData.expirationDateText}
                                        readOnly
                                    />
                                </FormGroup>
                            </FormGroup>

                            <FormGroup row>
                                <div className="col-xs-12 col-md-12 text-left">
                                    <Button
                                        type="button"
                                        color="primary"
                                        onClick={() => createNewCardField()}
                                    >
                                        {pageData.addNewCardText}
                                    </Button>
                                </div>
                            </FormGroup>
                        </>
                    )}

                    {addNewCard ? (
                        currentUser.subscription.locale === 'pt-BR' ? (
                            <FormGroup row>
                                <FormGroup className="col-xs-12 col-md-3">
                                    <Input
                                        name="new_description"
                                        type="text"
                                        label={
                                            pageData.requiredCardDescriptionText
                                        }
                                    />
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-5">
                                    <IuguCreditCardForm
                                        fields={pageData.new_card_info_fields}
                                        imgAltTexts={
                                            pageData.iuguTexts.imgAltTexts
                                        }
                                        creditCardLabelText={pageData.cardText}
                                        iugu={iugu}
                                    />
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-4">
                                    <Button
                                        color="danger"
                                        type="button"
                                        onClick={() => deleteNewCardField()}
                                        title={pageData.deleteCardText}
                                    >
                                        <FaTrash />
                                    </Button>
                                </FormGroup>
                            </FormGroup>
                        ) : (
                            <FormGroup row>
                                <FormGroup className="col-xs-12 col-md-5">
                                    <StripeForm
                                        creditCardLabelText={
                                            pageData.creditCardLabelText
                                        }
                                        setCardNumberComplete={
                                            setCardNumberComplete
                                        }
                                        setCardExpiryComplete={
                                            setCardExpiryComplete
                                        }
                                        setCardCvcComplete={setCardCvcComplete}
                                    />
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-4">
                                    <Button
                                        color="danger"
                                        type="button"
                                        onClick={() => deleteNewCardField()}
                                        title={pageData.deleteCardText}
                                    >
                                        <FaTrash />
                                    </Button>
                                </FormGroup>
                            </FormGroup>
                        )
                    ) : null}

                    <hr />

                    <FormGroup row>
                        <div className="col-xs-12 col-md-12 text-left">
                            <Button
                                color="primary"
                                type="submit"
                                disabled={!formReady}
                                className="mr-2"
                            >
                                {pageData.confirmText}
                            </Button>

                            <Link
                                to="/account/my_account"
                                className="btn btn-secondary"
                            >
                                {pageData.goBackText}
                            </Link>
                        </div>
                    </FormGroup>
                </Form>
            </CardBody>

            <CardFooter>
                <p
                    dangerouslySetInnerHTML={{
                        __html: pageData?.requiredFieldsText,
                    }}
                ></p>
            </CardFooter>
        </Card>
    );
}

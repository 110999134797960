import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import history from '../../../services/history';
import { DeleteCouponForm } from './components/form';

export default function DeleteCoupon({ currentUser }) {
    const [coupon, setCoupon] = useState({});

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];

    useEffect(() => {
        if (history.location.state?.entity) {
            setCoupon(history.location.state.entity);
        } else {
            history.push('/coupons');
        }
    }, []);

    if (Object.entries(coupon).length === 0) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <DeleteCouponForm
                    coupon={coupon}
                    fields={pageData?.fields}
                    texts={{
                        deleteSuccessText: pageData?.deleteSuccessText,
                        deleteText: pageData?.deleteText,
                        cancelText: pageData?.cancelText,
                        currencyText: pageData?.currencyText,
                        percentageText: pageData?.percentageText,
                        amountText: pageData?.amountText,
                    }}
                    lang={lang}
                />
            </CardBody>
        </Card>
    );
}

DeleteCoupon.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

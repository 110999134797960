import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { Button, Card, CardBody, CardFooter, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import FormFields from '../../../components/form/formFields';
import { setLoading } from '../../../store/actions/loadingActions';
import { validateForm } from '../../../helpers/formValidator';
import { addCompany } from '../../../services/api/companies';
import history from '../../../services/history';

AddCompany.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function AddCompany({ currentUser }) {
    const formRef = useRef(null);

    const isFirstRender = useRef(true);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];

    const schemaData = schemaConfig[lang][currentUser.permissions];

    async function handleSubmit(data) {
        const { parsedData, isValid, errors } = await validateForm(
            data,
            schemaData.schema
        );

        if (isValid) {
            formRef.current.setErrors(errors);

            dispatch(setLoading(true));

            const response = await addCompany(parsedData);

            dispatch(setLoading(false));

            if (response) {
                toast.success(pageData.addSuccessText);
                history.push('/companies');
            }
        } else {
            formRef.current.setErrors(errors);
        }
    }

    useEffect(() => {
        const initialData = {
            descriptor: {},
            role_id: {
                label: pageData.roleIdOptions.manager.label,
                value: pageData.roleIdOptions.manager.value,
            },
        };

        if (isFirstRender.current) {
            isFirstRender.current = false;

            initialData['descriptor']['minPasswordLength'] = 8;

            initialData['active'] = {
                label: pageData.yesText,
                value: true,
            };
        } else {
            initialData['active'] = {
                label: formRef.current.getFieldValue('active')
                    ? pageData.yesText
                    : pageData.noText,
                value: formRef.current.getFieldValue('active'),
            };

            initialData['descriptor']['minPasswordLength'] =
                formRef.current.getFieldValue('descriptor.minPasswordLength');
        }

        formRef.current.setData(initialData);
    }, [lang]);

    if (pageData === null && schemaData === null) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-12">
                            <span>{pageData.companySpanText}</span>
                        </FormGroup>
                    </FormGroup>
                    <FormGroup row>
                        <FormFields fields={pageData.fields.company} />
                    </FormGroup>
                    <hr />
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-12">
                            <span>{pageData.userSpanText}</span>
                        </FormGroup>
                    </FormGroup>
                    <FormGroup row>
                        <FormFields fields={pageData.fields.user} />
                    </FormGroup>
                    <hr />
                    <FormGroup row>
                        <div className="col-xs-12 col-md-12 text-left">
                            <Button color="primary mr-2" type="submit">
                                {pageData.addButtonText}
                            </Button>
                            <Link to="/companies" className="btn btn-secondary">
                                {pageData.cancelButtonText}
                            </Link>
                        </div>
                    </FormGroup>
                </Form>
            </CardBody>
            <CardFooter>
                <p
                    dangerouslySetInnerHTML={{
                        __html: pageData.requiredFieldPText,
                    }}
                ></p>
            </CardFooter>
        </Card>
    );
}

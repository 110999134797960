import Input from '../../../components/inputForm/inputText';
import Select from '../../../components/inputForm/inputSelect';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Nome <b style="color: darkred">*</b>',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'max_patients',
                        type: 'number',
                        label: 'Número máximo de pacientes <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'interval',
                        type: 'number',
                        label: 'Intervalo de pagamento <b style="color: darkred">*</b>',
                        min: 1,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'interval_type',
                        label: 'Tipo de intervalo <b style="color: darkred">*</b>',
                        options: [{ value: 'months', label: 'Meses' }],
                    },
                    className: 'col-xs-12 col-md-2',
                },
                {
                    component: Input,
                    props: {
                        name: 'max_cycles',
                        type: 'number',
                        label: 'Máximo de meses para expirar (0 para indeterminado) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Select,
                    props: {
                        name: 'payable_with',
                        label: 'Tipo de pagamento <b style="color: darkred">*</b>',
                        options: [
                            {
                                value: 'credit_card',
                                label: 'Cartão de crédito',
                            },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'active',
                        label: 'Ativo <b style="color: darkred">*</b>',
                        options: [
                            { value: false, label: 'Não' },
                            { value: true, label: 'Sim' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'visible',
                        label: 'Visível <b style="color: darkred">*</b>',
                        options: [
                            { value: false, label: 'Não' },
                            { value: true, label: 'Sim' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'plan_group',
                        label: 'Adicionar grupo de planos? <b style="color: darkred">*</b>',
                        options: [
                            {
                                value: true,
                                label: 'Sim',
                            },
                            {
                                value: false,
                                label: 'Não',
                            },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'type',
                        label: 'Tipo <b style="color: darkred">*</b>',
                        options: [
                            { value: 'sleep_diary', label: 'Diário de Sono' },
                            { value: 'lumus', label: 'Dispositivos Lumus' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'discount',
                        type: 'number',
                        label: 'Desconto (centavos) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'price_brl',
                        type: 'number',
                        label: 'Preço em Reais (centavos) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'price_usd',
                        type: 'number',
                        label: 'Preço em dólar (centavos) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            biannualPlanFields: [
                {
                    component: Input,
                    props: {
                        name: 'biannual_price_brl',
                        type: 'number',
                        label: 'Preço em Reais (centavos) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'biannual_price_usd',
                        type: 'number',
                        label: 'Preço em dólar (centavos) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            annualPlanFields: [
                {
                    component: Input,
                    props: {
                        name: 'annual_price_brl',
                        type: 'number',
                        label: 'Preço em Reais (centavos) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'annual_price_usd',
                        type: 'number',
                        label: 'Preço em dólar (centavos) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            lumusPricesFields: [
                {
                    component: Input,
                    props: {
                        name: 'up_to',
                        type: 'number',
                        label: 'Quantidade de dispositivos <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'price_brl',
                        type: 'number',
                        label: 'Preço em Reais (centavos) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'price_usd',
                        type: 'number',
                        label: 'Preço em dólar (centavos) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            biannualPlanText: 'Plano semestral',
            annualPlanText: 'Plano anual',
            addSuccessText: 'Plano adicionado com sucesso!',
            addButtonText: 'Adicionar',
            cancelButtonText: 'Cancelar',
            requiredFieldPText:
                '<b style="color: darkred">*</b> Campos obrigatórios!',
            yesText: 'Sim',
            planTypes: {
                sleep_diary: 'Diário de Sono',
                lumus: 'Dispositivos Lumus',
            },
            numberOfDevicesText:
                'Quantidade de dispositivos <b style="color: darkred">*</b>',
            priceBRLText:
                'Preço em Reais (centavos) <b style="color: darkred">*</b>',
            priceUSDText:
                'Preço em dólar (centavos) <b style="color: darkred">*</b>',
            pricesText: 'Preços',
            deletePriceText: 'Remover preço',
            addPriceText: 'Adicionar preço',
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Name <b style="color: darkred">*</b>',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'max_patients',
                        type: 'number',
                        label: 'Maximum number of patients <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'interval',
                        type: 'number',
                        label: 'Range of payment <b style="color: darkred">*</b>',
                        min: 1,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'interval_type',
                        label: 'Interval type <b style="color: darkred">*</b>',
                        options: [{ value: 'months', label: 'Months' }],
                    },
                    className: 'col-xs-12 col-md-2',
                },
                {
                    component: Input,
                    props: {
                        name: 'max_cycles',
                        type: 'number',
                        label: 'Maximum months to expire (0 for indefinite) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Select,
                    props: {
                        name: 'payable_with',
                        label: 'Payment type <b style="color: darkred">*</b>',
                        options: [
                            {
                                value: 'credit_card',
                                label: 'Credit card',
                            },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'active',
                        label: 'Active <b style="color: darkred">*</b>',
                        options: [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'visible',
                        label: 'Visible <b style="color: darkred">*</b>',
                        options: [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'plan_group',
                        label: 'Add plan group? <b style="color: darkred">*</b>',
                        options: [
                            {
                                value: true,
                                label: 'Yes',
                            },
                            {
                                value: false,
                                label: 'No',
                            },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'type',
                        label: 'Type <b style="color: darkred">*</b>',
                        options: [
                            { value: 'sleep_diary', label: 'Sleep Diary' },
                            { value: 'lumus', label: 'Lumus Devices' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'discount',
                        type: 'number',
                        label: 'Discount (cents) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'price_brl',
                        type: 'number',
                        label: 'Price in reais (cents) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'price_usd',
                        type: 'number',
                        label: 'Price in dollars (cents) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            biannualPlanFields: [
                {
                    component: Input,
                    props: {
                        name: 'biannual_price_brl',
                        type: 'number',
                        label: 'Price in Reais (cents) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'biannual_price_usd',
                        type: 'number',
                        label: 'Price in dollar (cents) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            annualPlanFields: [
                {
                    component: Input,
                    props: {
                        name: 'annual_price_brl',
                        type: 'number',
                        label: 'Price in Reais (cents) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'annual_price_usd',
                        type: 'number',
                        label: 'Price in dollar (cents) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            lumusPricesFields: [
                {
                    component: Input,
                    props: {
                        name: 'up_to',
                        type: 'number',
                        label: 'Number of devices <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'price_brl',
                        type: 'number',
                        label: 'Price in Reais (cents) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'price_usd',
                        type: 'number',
                        label: 'Price in dollar (cents) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            biannualPlanText: 'Semestral plan',
            annualPlanText: 'Annual plan',
            addSuccessText: 'Plan successfully added!',
            addButtonText: 'Add',
            cancelButtonText: 'Cancel',
            requiredFieldPText:
                '<b style="color: darkred">*</b> Required fields!',
            yesText: 'Yes',
            planTypes: {
                sleep_diary: 'Sleep Diary',
                lumus: 'Lumus Devices',
            },
            numberOfDevicesText:
                'Number of devices <b style="color: darkred">*</b>',
            priceBRLText:
                'Price in Reais (cents) <b style="color: darkred">*</b>',
            priceUSDText:
                'Price in dollar (cents) <b style="color: darkred">*</b>',
            pricesText: 'Prices',
            deletePriceText: 'Remove price',
            addPriceText: 'Add price',
        },
    },
};

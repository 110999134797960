import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import lodash from 'lodash';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import history from '../../../services/history';
import { setLoading } from '../../../store/actions/loadingActions';
import { fetchFreeDevices } from '../../../services/api/devices';
import { fetchDiaries } from '../../../services/api/diaries';
import { MyForm as Form } from './components/form';

export default function EditUser({ currentUser }) {
    const [user, setUser] = useState({});
    const [diaries, setDiaries] = useState([]);
    const [devices, setDevices] = useState([]);
    const [devicesFields, setDevicesFields] = useState([]);
    const [serialNumbersOptions, setSerialNumbersOptions] = useState([]);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];
    const { schema } = schemaConfig[lang][currentUser.permissions];

    const diaryOptions = useMemo(() => {
        return [
            {
                value: 0,
                label: pageData.defaultDiaryText,
            },
            ...diaries.map((diary) => {
                return {
                    value: diary.id,
                    label: diary.name,
                };
            }),
        ];
    }, [lang, diaries]);

    function handleRemoveClick(index) {
        let tempDevices = lodash.cloneDeep(devicesFields);
        tempDevices.splice(index, 1);

        setDevicesFields(tempDevices);
    }

    function handleAddClick() {
        let tempDevicesFields = lodash.cloneDeep(pageData.deviceFields);
        let deviceFieldsUUID = crypto.randomUUID();

        tempDevicesFields[0].props.options = serialNumbersOptions;
        tempDevicesFields[0].key = deviceFieldsUUID;
        tempDevicesFields[1].key = deviceFieldsUUID;
        tempDevicesFields[2].key = deviceFieldsUUID;

        setDevicesFields([...devicesFields, tempDevicesFields]);
    }

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            if (history.location.state?.entity) {
                let tempUser = history.location.state.entity;

                if (currentUser.permissions !== 'condor_admin') {
                    const devicesData = await fetchFreeDevices();

                    if (devicesData?.devices) {
                        setDevicesFields(
                            tempUser?.devices?.length > 0
                                ? tempUser?.devices?.map((_) => {
                                      let tempDevicesFields = lodash.cloneDeep(
                                          pageData.deviceFields
                                      );
                                      let key = crypto.randomUUID();

                                      tempDevicesFields[0].props.isDisabled = true;
                                      tempDevicesFields[0].key = key;
                                      tempDevicesFields[1].key = key;
                                      tempDevicesFields[2].key = key;
                                      return tempDevicesFields;
                                  })
                                : []
                        );
                        setDevices(devicesData?.devices);
                        setSerialNumbersOptions(
                            devicesData?.devices?.map((device) => {
                                return {
                                    value: device.id,
                                    label: device.serial_no,
                                };
                            })
                        );
                    }

                    const diariesData = await fetchDiaries();

                    setDiaries(diariesData?.diaries);
                }

                setUser(tempUser);
            } else {
                history.push('/users');
            }

            dispatch(setLoading(false));
        })();
    }, []);

    useEffect(() => {
        if (devicesFields.length > 0) {
            let tempDevicesFields = lodash.cloneDeep(devicesFields);

            tempDevicesFields = tempDevicesFields.map((deviceFields) => {
                deviceFields[0].props.label = pageData.serialNumberText;
                deviceFields[1].props.label = pageData.bodyPartText;
                deviceFields[1].props.options = pageData.bodyPartOptions;
                deviceFields[2].props.label = pageData.receivedAtText;

                return deviceFields;
            });

            setDevicesFields(tempDevicesFields);
        }
    }, [lang]);

    if (Object.entries(user).length === 0) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form
                    pageData={pageData}
                    schema={schema}
                    user={user}
                    diaries={diaries}
                    diaryOptions={diaryOptions}
                    devices={devices}
                    devicesFields={devicesFields}
                    handleAddClick={handleAddClick}
                    handleRemoveClick={handleRemoveClick}
                />
            </CardBody>
        </Card>
    );
}

EditUser.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

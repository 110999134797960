import history from '../../services/history';

export const stepsOptions = {
    pt: {
        addPatientButton: [
            {
                arrow: true,
                attachTo: {
                    element: 'a[href="users/add"]',
                    on: 'left',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        action() {
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            history.push({
                                pathname: '/users/add',
                                state: { canAddUsers: true },
                            });
                            return this.next();
                        },
                        text: 'Próximo',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'addPatientButton',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Página de adicionar paciente',
                text: [
                    'Clique no botão Adicionar para entrar na página de adicionar pacientes.',
                ],
            },
        ],
        appDownload: [
            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        type: 'next',
                        text: 'Próximo',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'appDownload',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Aplicativo',
                text: [
                    `Agora abra o e-mail no qual você cadastrou o paciente,
                    siga as instruções para fazer o download do app e preencha o primeiro dia do diário!`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.nav-link[title="Dashboard"]',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        action() {
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            history.push('/dashboard');
                            return this.next();
                        },
                        text: 'Próximo',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'dashboard',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Dashboard',
                text: [
                    `Depois que você preencher o primeiro dia do diário,
                    vá ao dashboard para acompanhar seu paciente!`,
                ],
            },
        ],
    },
    en: {
        addPatientButton: [
            {
                arrow: true,
                attachTo: {
                    element: 'a[href="users/add"]',
                    on: 'left',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        action() {
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            history.push({
                                pathname: '/users/add',
                                state: { canAddUsers: true },
                            });
                            return this.next();
                        },
                        text: 'Next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'addPatientButton',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Add patient page',
                text: ['Click the Add button to enter the Add Patients page.'],
            },
        ],
        appDownload: [
            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        type: 'next',
                        text: 'Next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'appDownload',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'App',
                text: [
                    `Now open the email in which you registered the patient,
                    follow the instructions to download the app, and fill in the first day of the diary!`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.nav-link[title="Dashboard"]',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        action() {
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            history.push('/dashboard');
                            return this.next();
                        },
                        text: 'Next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'dashboard',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Dashboard',
                text: [
                    `After you fill out the first day of the diary,
                    go to the dashboard to track your patient!`,
                ],
            },
        ],
    },
};

export const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
            enabled: false,
        },
    },
    useModalOverlay: true,
};

import ptLocale from '@fullcalendar/core/locales/pt-br';
import enLocale from '@fullcalendar/core/locales/en-gb';

export default {
    pt: {
        customer_admin: {
            noDataInputText: 'Paciente não possui dados preenchidos!',
            noActigraphyDataText: 'Paciente não possui dados de actigrafia!',
            userTable: {
                yesText: 'Sim',
                noText: 'Não',
                noDataText: 'Não há dados preenchidos',
                nameText: 'Nome',
                trialStartText: 'Início',
                currentWeekText: 'Semana atual',
                weekText: 'Semana',
                secondaryIdText: 'ID secundário',
                emailText: 'e-Mail',
                activeText: 'Ativo',
            },
            tabsText: {
                calendar: 'Preenchimento',
                weeklyEvolution: 'Evolução semanal',
                currentWeek: 'Semana atual',
                dailyEvolution: 'Evolução diária',
                tables: 'Tabelas',
                editData: 'Editar dados',
                actogram: 'Actograma',
                sleepScoring: 'Pontuação de sono',
            },
            exportButtonsText: {
                print: 'Imprimir',
                rawData: 'Exportar dados',
                tableData: 'Exportar tabelas',
                decimalsTableData: 'Exportar tabelas decimais',
                actigraphyData: 'Exportar actigrafia',
                customQuestionsData: 'Exportar perguntas personalizadas',
                selectedIntervalText: 'Intervalo selecionado',
                applyText: 'Aplicar',
                cancelText: 'Cancelar',
                todayText: 'Hoje',
            },
            calendar: {
                cardTitle: 'Calendário de preenchimento',
                calendarLocale: ptLocale,
                filledText: 'Preenchido',
                notFilledText: 'Não preenchido',
                filledColor: 'rgba(82, 157, 82, 0.6)',
                notFilledColor: 'rgba(204, 34, 34, 0.6)',
            },
            weeklyEvolution: {
                cardTitle: 'Evolução semanal',
            },
            currentWeek: {
                cardTitles: {
                    chart: 'Semana ',
                    table: 'Medicamentos e comentários',
                    napsChart: 'Cochilos',
                },
                ths: ['Dia da semana', 'Medicamentos', 'Comentários'],
            },
            tables: {
                cardTitle: 'Tabelas',
                sleepStatisticsTitle: 'Estatísticas de sono - Médias semanais',
                napStatisticsTitle: 'Estatísticas de cochilo - Médias semanais',
                bedTimeText: 'Hora que deitou',
                trySleepStartText: 'Hora que tentou dormir',
                getUpTimeText: 'Hora que levantou',
                timeInBedText: 'Tempo na cama',
                sleepDurationText: 'Tempo de sono',
                sleepEfficiencyText: 'Eficiência',
                latencyDurationText: 'Latência',
                nOfAwkText: 'Número de despertares',
                wasoText: 'WASO',
                inertiaText: 'Inércia',
                sleepSatisfactionText: 'Satisfação',
                usesMedicineText: 'Uso de medicamento',
                weekText: 'Semana',
                numberOfNapsText: 'Número de cochilos',
                totalNapsTimeText: 'Tempo total de cochilos',
            },
            editData: {
                cardTitle: 'Semana ',
                ths: [
                    'Data',
                    'Hora que deitou',
                    'Hora que tentou dormir',
                    'Hora que acordou',
                    'Hora que levantou',
                    'Latência',
                    'Despertares',
                    'Cochilos',
                    'Duração do sono',
                    '',
                ],
                tds: [
                    {
                        th: 'Data',
                        property: 'date',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Hora que deitou',
                        property: 'bedTime',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Hora que tentou dormir',
                        property: 'trySleepStart',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Hora que acordou',
                        property: 'wakeTime',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Hora que levantou',
                        property: 'getUpTime',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Latência',
                        property: 'latencyDuration',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Despertares',
                        property: 'durationOfAwk',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Despertares',
                        property: 'totalDurationOfAwk',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Cochilos',
                        property: 'durationOfNaps',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Duração do sono',
                        property: 'sleepDuration',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: '',
                        property: 'saveButton',
                        hasLink: false,
                        to: '',
                    },
                ],
                editButtonText: 'Editar',
                saveButtonText: 'Salvar',
                loadingButtonText: 'Aguarde...',
                cancelButtonText: 'Cancelar',
                incorrectValueSpanText:
                    'Horários com fundo <span class="bgPink">rosa</span> representam valores que podem ter sido digitados incorretamente.',
                automaticFixedValueSpanText:
                    'Horários com borda <span class="bgBlue txtWhite">azul</span> representam valores que foram corrigidos automaticamente nos cálculos.',
                editSuccessText: 'Dado editado com sucesso!',
                incorretFormatText: 'Formato incorreto',
            },
            actogram: {
                cardTitle: 'Actograma',
                xLabel: '2 Dias - Horas',
                actigraphyDataText: 'Dados de actigrafia',
                activityText: 'Atividade',
                wristText: 'Pulso',
                necklaceText: 'Colar',
                mixedText: 'Pulso (atividade) e colar (luz)',
                dataVisualizationText: 'Visualização dos dados:',
            },
            sleepScoring: {
                cardTitle: 'Pontuação de sono',
                actigraphyDataText: 'Dados de actigrafia',
                wristText: 'Pulso',
                necklaceText: 'Colar',
                mixedText: 'Pulso (atividade) e colar (luz)',
                activityText: 'Atividade',
                temperatureText: 'Temperatura',
                photopicText: 'Lux photopic',
                melanopicText: 'Lux melanopic',
                photopicNecklaceText: 'Lux photopic (dispositivo do colar)',
                melanopicNecklaceText: 'Lux melanopic (dispositivo do colar)',
                mixedChartsInfoText:
                    'Na visualização conjunta de pulso (atividade) e colar (luz), gráficos com uma borda <span class="bgPurple txtWhite">roxa</span> possuem dados de luz vindos do dispositivo usado como colar.',
                dataVisualizationText: 'Visualização dos dados:',
                changeChartLeftScaleText: 'Escala da esquerda:',
                recalculateStatesText: 'Recalcular estados',
                updateText: 'Atualizar',
                numberOfDaysText: 'Número de dias:',
                restingText: 'Descansando',
                sleepingText: 'Dormindo',
                offwristText: 'OffWrist',
                noDataText: 'Sem dados',
                eventText: 'Evento',
                sleepDiaryText: 'Diário do sono',
                entirePeriodText: 'Todo o período:',
                sleepingStatesText: 'Estados de sono:',
                noActigraphyDataText: 'Não há dados de actigrafia',
                calculatingStatesText: 'Calculando estados',
                thereAreStatesToCalculateText: 'Há estados a serem calculados',
                upToDateStatesText: 'Estados atualizados',
                calculateStateText: 'Calcular estados',
            },
            downloadAsPngText: 'Exportar como png',
            exportText: 'Exportar',
            formatText: 'Formato',
            pngText: 'PNG',
            pdfText: 'PDF',
            periodText: 'Período de visualização',
            sevenDaysText: '7 dias',
            fourteenDaysText: '14 dias',
            twentyOneDaysText: '21 dias',
            twentyEightDaysText: '28 dias',
        },
        manager: {
            noDataInputText: 'Paciente não possui dados preenchidos!',
            noActigraphyDataText: 'Paciente não possui dados de actigrafia!',
            userTable: {
                yesText: 'Sim',
                noText: 'Não',
                noDataText: 'Não há dados preenchidos',
                nameText: 'Nome',
                trialStartText: 'Início',
                currentWeekText: 'Semana atual',
                weekText: 'Semana',
                secondaryIdText: 'ID secundário',
                emailText: 'e-Mail',
                activeText: 'Ativo',
            },
            tabsText: {
                calendar: 'Preenchimento',
                weeklyEvolution: 'Evolução semanal',
                currentWeek: 'Semana atual',
                dailyEvolution: 'Evolução diária',
                tables: 'Tabelas',
                editData: 'Editar dados',
                actogram: 'Actograma',
                sleepScoring: 'Pontuação de sono',
            },
            exportButtonsText: {
                print: 'Imprimir',
                rawData: 'Exportar dados',
                tableData: 'Exportar tabelas',
                decimalsTableData: 'Exportar tabelas decimais',
                actigraphyData: 'Exportar actigrafia',
                customQuestionsData: 'Exportar perguntas personalizadas',
                selectedIntervalText: 'Intervalo selecionado',
                applyText: 'Aplicar',
                cancelText: 'Cancelar',
                todayText: 'Hoje',
            },
            calendar: {
                cardTitle: 'Calendário de preenchimento',
                calendarLocale: ptLocale,
                filledText: 'Preenchido',
                notFilledText: 'Não preenchido',
                filledColor: 'rgba(82, 157, 82, 0.6)',
                notFilledColor: 'rgba(204, 34, 34, 0.6)',
            },
            weeklyEvolution: {
                cardTitle: 'Evolução semanal',
            },
            currentWeek: {
                cardTitles: {
                    chart: 'Semana ',
                    table: 'Medicamentos e comentários',
                    napsChart: 'Cochilos',
                },
                ths: ['Dia da semana', 'Medicamentos', 'Comentários'],
            },
            tables: {
                cardTitle: 'Tabelas',
                sleepStatisticsTitle: 'Estatísticas de sono - Médias semanais',
                napStatisticsTitle: 'Estatísticas de cochilo - Médias semanais',
                bedTimeText: 'Hora que deitou',
                trySleepStartText: 'Hora que tentou dormir',
                getUpTimeText: 'Hora que levantou',
                timeInBedText: 'Tempo na cama',
                sleepDurationText: 'Tempo de sono',
                sleepEfficiencyText: 'Eficiência',
                latencyDurationText: 'Latência',
                nOfAwkText: 'Número de despertares',
                wasoText: 'WASO',
                inertiaText: 'Inércia',
                sleepSatisfactionText: 'Satisfação',
                usesMedicineText: 'Uso de medicamento',
                weekText: 'Semana',
                numberOfNapsText: 'Número de cochilos',
                totalNapsTimeText: 'Tempo total de cochilos',
            },
            editData: {
                cardTitle: 'Semana ',
                ths: [
                    'Data',
                    'Hora que deitou',
                    'Hora que tentou dormir',
                    'Hora que acordou',
                    'Hora que levantou',
                    'Latência',
                    'Despertares',
                    'Cochilos',
                    'Duração do sono',
                    '',
                ],
                tds: [
                    {
                        th: 'Data',
                        property: 'date',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Hora que deitou',
                        property: 'bedTime',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Hora que tentou dormir',
                        property: 'trySleepStart',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Hora que acordou',
                        property: 'wakeTime',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Hora que levantou',
                        property: 'getUpTime',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Latência',
                        property: 'latencyDuration',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Despertares',
                        property: 'durationOfAwk',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Despertares',
                        property: 'totalDurationOfAwk',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Cochilos',
                        property: 'durationOfNaps',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Duração do sono',
                        property: 'sleepDuration',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: '',
                        property: 'saveButton',
                        hasLink: false,
                        to: '',
                    },
                ],
                editButtonText: 'Editar',
                saveButtonText: 'Salvar',
                loadingButtonText: 'Aguarde...',
                cancelButtonText: 'Cancelar',
                incorrectValueSpanText:
                    'Horários com fundo <span class="bgPink">rosa</span> representam valores que podem ter sido digitados incorretamente.',
                automaticFixedValueSpanText:
                    'Horários com borda <span class="bgBlue txtWhite">azul</span> representam valores que foram corrigidos automaticamente nos cálculos.',
                editSuccessText: 'Dado editado com sucesso!',
                incorretFormatText: 'Formato incorreto',
            },
            actogram: {
                cardTitle: 'Actograma',
                xLabel: '2 Dias - Horas',
                actigraphyDataText: 'Dados de actigrafia',
                activityText: 'Atividade',
                wristText: 'Pulso',
                necklaceText: 'Colar',
                mixedText: 'Pulso (atividade) e colar (luz)',
                dataVisualizationText: 'Visualização dos dados:',
            },
            sleepScoring: {
                cardTitle: 'Pontuação de sono',
                actigraphyDataText: 'Dados de actigrafia',
                wristText: 'Pulso',
                necklaceText: 'Colar',
                mixedText: 'Pulso (atividade) e colar (luz)',
                activityText: 'Atividade',
                temperatureText: 'Temperatura',
                photopicText: 'Lux photopic',
                melanopicText: 'Lux melanopic',
                photopicNecklaceText: 'Lux photopic (dispositivo do colar)',
                melanopicNecklaceText: 'Lux melanopic (dispositivo do colar)',
                mixedChartsInfoText:
                    'Na visualização conjunta de pulso (atividade) e colar (luz), gráficos com uma borda <span class="bgPurple txtWhite">roxa</span> possuem dados de luz vindos do dispositivo usado como colar.',
                dataVisualizationText: 'Visualização dos dados:',
                changeChartLeftScaleText: 'Escala da esquerda:',
                recalculateStatesText: 'Recalcular estados',
                updateText: 'Atualizar',
                numberOfDaysText: 'Número de dias:',
                restingText: 'Descansando',
                sleepingText: 'Dormindo',
                offwristText: 'OffWrist',
                noDataText: 'Sem dados',
                eventText: 'Evento',
                sleepDiaryText: 'Diário do sono',
                entirePeriodText: 'Todo o período:',
                sleepingStatesText: 'Estados de sono:',
                noActigraphyDataText: 'Não há dados de actigrafia',
                calculatingStatesText: 'Calculando estados',
                thereAreStatesToCalculateText: 'Há estados a serem calculados',
                upToDateStatesText: 'Estados atualizados',
                calculateStateText: 'Calcular estados',
            },
            next: 'Próximo',
            back: 'Voltar',
            downloadAsPngText: 'Exportar como png',
            exportText: 'Exportar',
            formatText: 'Formato',
            pngText: 'PNG',
            pdfText: 'PDF',
            periodText: 'Período de visualização',
            sevenDaysText: '7 dias',
            fourteenDaysText: '14 dias',
            twentyOneDaysText: '21 dias',
            twentyEightDaysText: '28 dias',
        },
        condor_admin: null,
    },
    en: {
        customer_admin: {
            noDataInputText: 'Patient does not have data filled in!',
            noActigraphyDataText: 'Patient does not have actigraphy data!',
            userTable: {
                yesText: 'Yes',
                noText: 'No',
                noDataText: 'No data filled',
                nameText: 'Name',
                trialStartText: 'Trial start',
                currentWeekText: 'Current week',
                weekText: 'Week',
                secondaryIdText: 'Secondary ID',
                emailText: 'e-Mail',
                activeText: 'Active',
            },
            tabsText: {
                calendar: 'Calendar',
                weeklyEvolution: 'Weekly Evolution',
                currentWeek: 'Current week',
                dailyEvolution: 'Daily evolution',
                tables: 'Tables',
                editData: 'Edit data',
                actogram: 'Actogram',
                sleepScoring: 'Sleep Scoring',
            },
            exportButtonsText: {
                print: 'Print',
                rawData: 'Export raw data',
                tableData: 'Export tables',
                decimalsTableData: 'Export decimal tables',
                actigraphyData: 'Export actigraphy',
                customQuestionsData: 'Export custom questions',
                selectedIntervalText: 'Selected interval',
                applyText: 'Apply',
                cancelText: 'Cancel',
                todayText: 'Today',
            },
            calendar: {
                cardTitle: 'Calendar',
                calendarLocale: enLocale,
                filledText: 'Filled',
                notFilledText: 'Not filled',
                filledColor: 'rgba(82, 157, 82, 0.6)',
                notFilledColor: 'rgba(204, 34, 34, 0.6)',
            },
            weeklyEvolution: {
                cardTitle: 'Weekly Evolution',
            },
            currentWeek: {
                cardTitles: {
                    chart: 'Week ',
                    table: 'Medications and comments',
                    napsChart: 'Naps',
                },
                ths: ['Day of the week', 'Medications', 'Comments'],
            },
            tables: {
                cardTitle: 'Tables',
                sleepStatisticsTitle: 'Sleep statistics - Weekly averages',
                napStatisticsTitle: 'Nap statistics - Weekly averages',
                bedTimeText: 'Bed time',
                trySleepStartText: 'Time patient tried to sleep',
                getUpTimeText: 'Get up time',
                timeInBedText: 'Time in bed',
                sleepDurationText: 'Sleep time',
                sleepEfficiencyText: 'Sleep Efficiency',
                latencyDurationText: 'Onset Latency',
                nOfAwkText: 'Number of awakenings',
                wasoText: 'WASO',
                inertiaText: 'Inertia',
                sleepSatisfactionText: 'Sleep satisfaction',
                usesMedicineText: 'Drug use',
                weekText: 'Week',
                numberOfNapsText: 'Number of naps',
                totalNapsTimeText: 'Total naps time',
            },
            editData: {
                cardTitle: 'Week ',
                ths: [
                    'Date',
                    'Bed time',
                    'Time patient tried to sleep',
                    'Wake up time',
                    'Get up time',
                    'Onset Latency',
                    'Awakenings',
                    'Naps',
                    'Sleep duration',
                    '',
                ],
                tds: [
                    {
                        th: 'Date',
                        property: 'date',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Bed time',
                        property: 'bedTime',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Time patient tried to sleep',
                        property: 'trySleepStart',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Wake up time',
                        property: 'wakeTime',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Get up time',
                        property: 'getUpTime',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Onset Latency',
                        property: 'latencyDuration',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Awakenings',
                        property: 'durationOfAwk',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Awakenings',
                        property: 'totalDurationOfAwk',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Naps',
                        property: 'durationOfNaps',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Sleep duration',
                        property: 'sleepDuration',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: '',
                        property: 'saveButton',
                        hasLink: false,
                        to: '',
                    },
                ],
                editButtonText: 'Edit',
                saveButtonText: 'Save',
                loadingButtonText: 'Loading...',
                cancelButtonText: 'Cancel',
                incorrectValueSpanText:
                    'Times with a <span class="bgPink">pink</span> background represent values ​​that may have been entered incorrectly.',
                automaticFixedValueSpanText:
                    '<span class="bgBlue txtWhite">Blue</span>-bordered times represent values ​​that have been automatically corrected in calculations.',
                editSuccessText: 'Data successfully edited!',
                incorretFormatText: 'Incorrect format',
            },
            actogram: {
                cardTitle: 'Actogram',
                xLabel: '2 Days - Hours',
                actigraphyDataText: 'Actigraphy data',
                activityText: 'Activity',
                wristText: 'Wrist',
                necklaceText: 'Necklace',
                mixedText: 'Wrist (activity) and necklace (light)',
                dataVisualizationText: 'Data visualization:',
            },
            sleepScoring: {
                cardTitle: 'Sleep Scoring',
                actigraphyDataText: 'Actigraphy data',
                wristText: 'Wrist',
                necklaceText: 'Necklace',
                mixedText: 'Wrist (activity) and necklace (light)',
                activityText: 'Activity',
                temperatureText: 'Temperature',
                photopicText: 'Lux photopic',
                melanopicText: 'Lux melanopic',
                photopicNecklaceText: 'Lux photopic (necklace device)',
                melanopicNecklaceText: 'Lux melanopic (necklace device)',
                mixedChartsInfoText:
                    'In the wrist (activity) and necklace (light) co-view, charts with a <span class="bgPurple txtWhite">purple</span> border have light data coming from the device used as a necklace.',
                dataVisualizationText: 'Data visualization:',
                changeChartLeftScaleText: 'Left scale:',
                recalculateStatesText: 'Recalculate states',
                updateText: 'Update',
                numberOfDaysText: 'Number of days:',
                restingText: 'Resting',
                sleepingText: 'Sleeping',
                offwristText: 'OffWrist',
                noDataText: 'No data',
                eventText: 'Event',
                sleepDiaryText: 'Sleep diary',
                entirePeriodText: 'Entire period:',
                sleepingStatesText: 'Sleeping states:',
                noActigraphyDataText: 'There is no actigraphy data',
                calculatingStatesText: 'Calculating states',
                thereAreStatesToCalculateText:
                    'There are states to be calculated',
                upToDateStatesText: 'Up to date',
                calculateStateText: 'Calculate states',
            },
            downloadAsPngText: 'Export as png',
            exportText: 'Export',
            formatText: 'Format',
            pngText: 'PNG',
            pdfText: 'PDF',
            periodText: 'Viewing period',
            sevenDaysText: '7 days',
            fourteenDaysText: '14 days',
            twentyOneDaysText: '21 days',
            twentyEightDaysText: '28 days',
        },
        manager: {
            noDataInputText: 'Patient does not have data filled in!',
            noActigraphyDataText: 'Patient does not have actigraphy data!',
            userTable: {
                yesText: 'Yes',
                noText: 'No',
                noDataText: 'No data filled',
                nameText: 'Name',
                trialStartText: 'Trial start',
                currentWeekText: 'Current week',
                weekText: 'Week',
                secondaryIdText: 'Secondary ID',
                emailText: 'e-Mail',
                activeText: 'Active',
            },
            tabsText: {
                calendar: 'Calendar',
                weeklyEvolution: 'Weekly Evolution',
                currentWeek: 'Current week',
                dailyEvolution: 'Daily evolution',
                tables: 'Tables',
                editData: 'Edit data',
                actogram: 'Actogram',
                sleepScoring: 'Sleep Scoring',
            },
            exportButtonsText: {
                print: 'Print',
                rawData: 'Export raw data',
                tableData: 'Export tables',
                decimalsTableData: 'Export decimal tables',
                actigraphyData: 'Export actigraphy',
                customQuestionsData: 'Export custom questions',
                selectedIntervalText: 'Selected interval',
                applyText: 'Apply',
                cancelText: 'Cancel',
                todayText: 'Today',
            },
            calendar: {
                cardTitle: 'Calendar',
                calendarLocale: enLocale,
                filledText: 'Filled',
                notFilledText: 'Not filled',
                filledColor: 'rgba(82, 157, 82, 0.6)',
                notFilledColor: 'rgba(204, 34, 34, 0.6)',
            },
            weeklyEvolution: {
                cardTitle: 'Weekly Evolution',
            },
            currentWeek: {
                cardTitles: {
                    chart: 'Week ',
                    table: 'Medications and comments',
                    napsChart: 'Naps',
                },
                ths: ['Day of the week', 'Medications', 'Comments'],
            },
            tables: {
                cardTitle: 'Tables',
                sleepStatisticsTitle: 'Sleep statistics - Weekly averages',
                napStatisticsTitle: 'Nap statistics - Weekly averages',
                bedTimeText: 'Bed time',
                trySleepStartText: 'Time patient tried to sleep',
                getUpTimeText: 'Get up time',
                timeInBedText: 'Time in bed',
                sleepDurationText: 'Sleep time',
                sleepEfficiencyText: 'Sleep Efficiency',
                latencyDurationText: 'Onset Latency',
                nOfAwkText: 'Number of awakenings',
                wasoText: 'WASO',
                inertiaText: 'Inertia',
                sleepSatisfactionText: 'Sleep satisfaction',
                usesMedicineText: 'Drug use',
                weekText: 'Week',
                numberOfNapsText: 'Number of naps',
                totalNapsTimeText: 'Total naps time',
            },
            editData: {
                cardTitle: 'Week ',
                ths: [
                    'Date',
                    'Bed time',
                    'Time patient tried to sleep',
                    'Wake up time',
                    'Get up time',
                    'Onset Latency',
                    'Awakenings',
                    'Naps',
                    'Sleep duration',
                    '',
                ],
                tds: [
                    {
                        th: 'Date',
                        property: 'date',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Bed time',
                        property: 'bedTime',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Time patient tried to sleep',
                        property: 'trySleepStart',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Wake up time',
                        property: 'wakeTime',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Get up time',
                        property: 'getUpTime',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Onset Latency',
                        property: 'latencyDuration',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Awakenings',
                        property: 'durationOfAwk',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Awakenings',
                        property: 'totalDurationOfAwk',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Naps',
                        property: 'durationOfNaps',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Sleep duration',
                        property: 'sleepDuration',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: '',
                        property: 'saveButton',
                        hasLink: false,
                        to: '',
                    },
                ],
                editButtonText: 'Edit',
                saveButtonText: 'Save',
                loadingButtonText: 'Loading...',
                cancelButtonText: 'Cancel',
                incorrectValueSpanText:
                    'Times with a <span class="bgPink">pink</span> background represent values ​​that may have been entered incorrectly.',
                automaticFixedValueSpanText:
                    '<span class="bgBlue txtWhite">Blue</span>-bordered times represent values ​​that have been automatically corrected in calculations.',
                editSuccessText: 'Data successfully edited!',
                incorretFormatText: 'Incorrect format',
            },
            actogram: {
                cardTitle: 'Actogram',
                xLabel: '2 Days - Hours',
                actigraphyDataText: 'Actigraphy data',
                activityText: 'Activity',
                wristText: 'Wrist',
                necklaceText: 'Necklace',
                mixedText: 'Wrist (activity) and necklace (light)',
                dataVisualizationText: 'Data visualization:',
            },
            sleepScoring: {
                cardTitle: 'Sleep Scoring',
                actigraphyDataText: 'Actigraphy data',
                wristText: 'Wrist',
                necklaceText: 'Necklace',
                mixedText: 'Wrist (activity) and necklace (light)',
                activityText: 'Activity',
                temperatureText: 'Temperature',
                photopicText: 'Lux photopic',
                melanopicText: 'Lux melanopic',
                photopicNecklaceText: 'Lux photopic (necklace device)',
                melanopicNecklaceText: 'Lux melanopic (necklace device)',
                mixedChartsInfoText:
                    'In the wrist (activity) and necklace (light) co-view, charts with a <span class="bgPurple txtWhite">purple</span> border have light data coming from the device used as a necklace.',
                dataVisualizationText: 'Data visualization:',
                changeChartLeftScaleText: 'Left scale:',
                recalculateStatesText: 'Recalculate states',
                updateText: 'Update',
                numberOfDaysText: 'Number of days:',
                restingText: 'Resting',
                sleepingText: 'Sleeping',
                offwristText: 'OffWrist',
                noDataText: 'No data',
                eventText: 'Event',
                sleepDiaryText: 'Sleep diary',
                entirePeriodText: 'Entire period:',
                sleepingStatesText: 'Sleeping states:',
                noActigraphyDataText: 'There is no actigraphy data',
                calculatingStatesText: 'Calculating states',
                thereAreStatesToCalculateText:
                    'There are states to be calculated',
                upToDateStatesText: 'Up to date',
                calculateStateText: 'Calculate states',
            },
            next: 'Next',
            back: 'Back',
            downloadAsPngText: 'Export as png',
            exportText: 'Export',
            formatText: 'Format',
            pngText: 'PNG',
            pdfText: 'PDF',
            periodText: 'Viewing period',
            sevenDaysText: '7 days',
            fourteenDaysText: '14 days',
            twentyOneDaysText: '21 days',
            twentyEightDaysText: '28 days',
        },
        condor_admin: null,
    },
};

import Input from '../../../components/inputForm/inputText';

export default {
    pt: {
        fields: [
            {
                component: Input,
                props: {
                    name: 'user_id',
                    type: 'text',
                    label: 'ID do usuário',
                },
                className: 'col-xs-12 col-md-12',
            },
            {
                component: Input,
                props: {
                    name: 'company_id',
                    type: 'text',
                    label: 'ID da empresa',
                },
                className: 'col-xs-12 col-md-12',
            },
        ],
        pageTitle: 'Solicitar redefinição de senha',
        userIdLabel: 'ID do usuário',
        CompanyIdLabel: 'ID da empresa',
        requestButtonText: 'Solicitar',
        requestButtonLoadingText: 'Aguarde...',
        goBackButtonText: 'Voltar',
        verifyEmailText:
            'Verifique seu e-Mail para seguir as instruções de redefinição de senha.',
        doesNotRememberCredentials: `Se você não se lembra das suas credenciais de acesso,
        envie um e-mail para suporte_diario@condorinst.com.br.`,
    },
    en: {
        fields: [
            {
                component: Input,
                props: {
                    name: 'user_id',
                    type: 'text',
                    label: 'User ID',
                },
                className: 'col-xs-12 col-md-12',
            },
            {
                component: Input,
                props: {
                    name: 'company_id',
                    type: 'text',
                    label: 'Company ID',
                },
                className: 'col-xs-12 col-md-12',
            },
        ],
        pageTitle: 'Request password reset',
        userIdLabel: 'User ID',
        CompanyIdLabel: 'Company ID',
        requestButtonText: 'Request',
        requestButtonLoadingText: 'Loading...',
        goBackButtonText: 'Return',
        verifyEmailText: 'Check your email for password reset instructions.',
        doesNotRememberCredentials: `If you do not remember your login credentials, please contact us at suporte_diario@condorinst.com.br.`,
    },
};

import { all, takeLatest, put } from 'redux-saga/effects';

import { changeLang } from '../actions/langActions';
import { LANG } from '../constants';
import api from '../../services/api';
import csvApi from '../../services/api/filesApi';

export function* setLang({ payload }) {
    api.defaults.headers.Lang = payload;
    csvApi.defaults.headers.Lang = payload;

    yield put(changeLang(payload));
}

export function setLangHeader({ payload }) {
    if (!payload) return;

    const { lang } = payload.lang;

    if (lang) {
        api.defaults.headers.Lang = lang;
        csvApi.defaults.headers.Lang = lang;
    }
}

export default all([
    takeLatest('persist/REHYDRATE', setLangHeader),
    takeLatest(LANG.CHANGE_LANG_REQUEST, setLang),
]);

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { formatISO9075 } from 'date-fns';
import {
    Row,
    Col,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';

import Card from '../../../../components/card/card';
import Plot from '../../../../components/plot';
import { ChartLabel } from '../../../../components/chartLabel';
import { addDays } from '../../../../helpers/date';
import { localeDateFormatter } from '../../../../helpers/formatters';
import {
    creaActivityteTraces,
    createDivisionShape,
    createOffwristShapes,
    createSleepingShapes,
    createRestingShapes,
} from '../../../../helpers/actogramHelpers';
import useWindowSize from '../../../../hooks/useWindowSize';

Actogram.propTypes = {
    data: PropTypes.shape({
        body_parts: PropTypes.arrayOf(PropTypes.string),
        charts_data: PropTypes.arrayOf(
            PropTypes.shape({
                data: PropTypes.array.isRequired,
                date_ref: PropTypes.array.isRequired,
                states: PropTypes.shape({
                    offwrist: PropTypes.array.isRequired,
                    sleeping: PropTypes.array.isRequired,
                    resting: PropTypes.array.isRequired,
                }).isRequired,
            })
        ).isRequired,
    }).isRequired,
    textData: PropTypes.shape({
        cardTitle: PropTypes.string.isRequired,
        xLabel: PropTypes.string.isRequired,
        actigraphyDataText: PropTypes.string.isRequired,
        activityText: PropTypes.string.isRequired,
        wristText: PropTypes.string.isRequired,
        necklaceText: PropTypes.string.isRequired,
        mixedText: PropTypes.string.isRequired,
        dataVisualizationText: PropTypes.string.isRequired,
    }).isRequired,
};

export function Actogram({ data, textData }) {
    const { body_parts: bodyParts, charts_data: chartsData } = data;

    const { width } = useWindowSize();

    const lang = useSelector((state) => state.lang.lang);
    const { dateLocale } = useSelector((state) => state.locale);

    const [chartsMode, setChartsMode] = useState('wrist');
    const [chartsModesOptions, setChartsModesOptions] = useState([
        { text: textData.wristText, active: true, type: 'wrist' },
        { text: textData.necklaceText, active: false, type: 'necklace' },
    ]);
    const [chart, setChart] = useState(null);
    const [layout, setLayout] = useState(null);
    const [loadingCharts, setLoadingCharts] = useState(true);

    const actogramColors = {
        activity: '#1F96C8',
        offwrist: '#663399',
        sleeping: '#46bece',
        resting: '#64ff64',
    };

    const dataLabels = [
        {
            name: textData.activityText,
            color: actogramColors.activity,
        },
    ];

    const config = {
        responsive: true,
        scrollZoom: false,
        showAxisDragHandles: false,
        displayModeBar: false,
        staticPlot: true,
        edits: {
            axisTitleText: false,
            shapePosition: false,
            titleText: false,
        },
    };

    useEffect(() => {
        if (chart !== null) {
            let tempLayout = {};

            if (layout !== null) {
                tempLayout = {
                    ...layout,
                    width: width - (20 * width) / 100,
                };
            } else {
                let wristIndex = -1;
                let necklaceIndex = -1;

                if (chartsMode === 'wrist') {
                    wristIndex = bodyParts.findIndex(
                        (part) => part === 'wrist'
                    );
                } else if (chartsMode === 'necklace') {
                    necklaceIndex = bodyParts.findIndex(
                        (part) => part === 'necklace'
                    );
                } else {
                    wristIndex = bodyParts.findIndex(
                        (part) => part === 'wrist'
                    );
                    necklaceIndex = bodyParts.findIndex(
                        (part) => part === 'necklace'
                    );
                }

                let indexToUse =
                    wristIndex !== -1
                        ? wristIndex
                        : necklaceIndex !== -1
                        ? necklaceIndex
                        : -1;

                if (indexToUse !== -1) {
                    tempLayout = {
                        ...layout,
                        height:
                            chart.length === 1
                                ? chart.length * 200
                                : chart.length * 100,
                        width: width - (20 * width) / 100,
                    };
                }
            }

            setLayout(tempLayout);
        } else {
            if (bodyParts.length === 2) {
                setChartsMode('wrist');

                setChartsModesOptions([
                    { text: textData.wristText, active: true, type: 'wrist' },
                    {
                        text: textData.necklaceText,
                        active: false,
                        type: 'necklace',
                    },
                ]);

                createCharts('wrist');
            } else {
                if (bodyParts.find((part) => part === 'wrist')) {
                    setChartsMode('wrist');

                    setChartsModesOptions([
                        {
                            text: textData.wristText,
                            active: true,
                            type: 'wrist',
                        },
                    ]);

                    createCharts('wrist');
                } else if (bodyParts.find((part) => part === 'necklace')) {
                    setChartsMode('necklace');

                    setChartsModesOptions([
                        {
                            text: textData.necklaceText,
                            active: true,
                            type: 'necklace',
                        },
                    ]);

                    createCharts('necklace');
                } else {
                    setChartsModesOptions([]);
                }
            }
        }
    }, [chart, width]);

    useEffect(() => {
        setChartsModesOptions((prevState) => {
            const newState = [...prevState];

            return newState.map((option) => {
                if (option.type === 'wrist') {
                    return {
                        ...option,
                        text: textData.wristText,
                    };
                } else if (option.type === 'necklace') {
                    return {
                        ...option,
                        text: textData.necklaceText,
                    };
                } else {
                    return {
                        ...option,
                        text: textData.mixedText,
                    };
                }
            });
        });

        setLayout((prevState) => {
            const newState = { ...prevState };

            const xaxisToUpdate = Object.keys(newState).filter((key) => {
                if (key.includes('xaxis') && 'title' in newState[key]) {
                    return true;
                }

                return false;
            });

            if (xaxisToUpdate.length > 0) {
                newState[xaxisToUpdate].title = {
                    text: textData.xLabel,
                };
            }

            return newState;
        });
    }, [lang]);

    function handleClickActigraphyData(type) {
        createCharts(type);
    }

    function createCharts(mode) {
        setLoadingCharts(true);

        if (mode === 'wrist') {
            setChartsMode('wrist');

            setChartsModesOptions((prevState) => {
                const newChartsModes = [...prevState];

                return newChartsModes.map((chartMode) => {
                    if (chartMode.type === 'wrist') {
                        return {
                            ...chartMode,
                            active: true,
                        };
                    }

                    return {
                        ...chartMode,
                        active: false,
                    };
                });
            });

            createSingleBodyPartCharts('wrist');
        } else if (mode === 'necklace') {
            setChartsMode('necklace');

            setChartsModesOptions((prevState) => {
                const newChartsModes = [...prevState];

                return newChartsModes.map((chartMode) => {
                    if (chartMode.type === 'necklace') {
                        return {
                            ...chartMode,
                            active: true,
                        };
                    }

                    return {
                        ...chartMode,
                        active: false,
                    };
                });
            });

            createSingleBodyPartCharts('necklace');
        }

        setLoadingCharts(false);
    }

    function createSingleBodyPartCharts(part) {
        const index = bodyParts.findIndex((bodyPart) => bodyPart === part);

        if (index !== -1) {
            let activityTraces = creaActivityteTraces(
                chartsData[index].data,
                actogramColors.activity
            );

            let tempLayout = {
                hovermode: false,
                margin: {
                    l: 100,
                    t: 30,
                },
                font: {
                    family: 'Georgia, serif',
                },
                showlegend: false,
                height:
                    chartsData[index].data.length === 1
                        ? chartsData[index].data.length * 200
                        : chartsData[index].data.length * 100,
                width: width - (20 * width) / 100,
            };

            let step = 1 / chartsData[index].data.length;

            let domain = [1.0 - step, 1.0];

            for (let i = 1; i < chartsData[index].data.length + 1; i++) {
                let start = chartsData[index].date_ref[i - 1];

                let startDate = new Date(chartsData[index].date_ref[i - 1]);

                let endDate = addDays(startDate, 2);

                let end = formatISO9075(endDate);

                if (i === chartsData[index].data.length) {
                    tempLayout[`xaxis${i}`] = {
                        title: {
                            text: textData.xLabel,
                        },
                        ticks: '',
                        anchor: `y${i}`,
                        mirror: true,
                        showgrid: true,
                        showline: true,
                        zeroline: true,
                        linecolor: '#CECECE',
                        showticklabels: true,
                        range: [start, end],
                        gridcolor: '#CECECE',
                        gridwidth: 1,
                        tickformat: '%H',
                        tickmode: 'auto',
                        nticks: 26,
                        fixedrange: true,
                    };

                    tempLayout[`yaxis${i}`] = {
                        title: {
                            text: localeDateFormatter(
                                chartsData[index].date_ref[i - 1],
                                dateLocale
                            )
                                ? localeDateFormatter(
                                      chartsData[index].date_ref[i - 1],
                                      dateLocale
                                  )
                                : '--',
                        },
                        ticks: '',
                        anchor: `x${i}`,
                        domain: domain,
                        mirror: true,
                        showgrid: false,
                        showline: true,
                        zeroline: true,
                        linecolor: '#CECECE',
                        showticklabels: false,
                        range: [0, 1.1],
                        fixedrange: true,
                    };
                } else {
                    tempLayout[`xaxis${i}`] = {
                        ticks: '',
                        anchor: `y${i}`,
                        mirror: true,
                        showgrid: true,
                        showline: true,
                        zeroline: true,
                        linecolor: '#CECECE',
                        showticklabels: false,
                        range: [start, end],
                        gridcolor: '#CECECE',
                        gridwidth: 1,
                        tickmode: 'auto',
                        nticks: 26,
                        fixedrange: true,
                    };

                    tempLayout[`yaxis${i}`] = {
                        title: {
                            text: localeDateFormatter(
                                chartsData[index].date_ref[i - 1],
                                dateLocale
                            )
                                ? localeDateFormatter(
                                      chartsData[index].date_ref[i - 1],
                                      dateLocale
                                  )
                                : '--',
                        },
                        ticks: '',
                        anchor: `x${i}`,
                        domain: domain,
                        mirror: true,
                        showgrid: false,
                        showline: true,
                        zeroline: true,
                        linecolor: '#CECECE',
                        showticklabels: false,
                        range: [0, 1.1],
                        fixedrange: true,
                    };
                }

                domain = [domain[0] - step, domain[1] - step];
            }

            let divisionShape = createDivisionShape(chartsData[index].date_ref);

            let offwristShapes = createOffwristShapes(
                chartsData[index].data,
                chartsData[index].states.offwrist,
                actogramColors.offwrist
            );

            let sleepingShapes = createSleepingShapes(
                chartsData[index].data,
                chartsData[index].states.sleeping,
                actogramColors.sleeping
            );

            let restingShapes = createRestingShapes(
                chartsData[index].data,
                chartsData[index].states.resting,
                actogramColors.resting
            );

            tempLayout.shapes = [
                divisionShape,
                ...offwristShapes,
                ...sleepingShapes,
                ...restingShapes,
            ];

            const newChart = {
                data: activityTraces,
                layout: tempLayout,
                config,
            };

            setLayout(newChart.layout);

            setChart(newChart);
        }
    }

    return (
        <>
            <Col xs="12">
                <Card
                    title={textData.cardTitle}
                    isTable={true}
                    textSize="lg"
                    textCenter
                >
                    <Row className="pb-3">
                        <Col
                            xs="12"
                            className="d-inline-flex justify-content-center align-items-center mt-3"
                        >
                            {dataLabels.map((actigraphyText) => (
                                <ChartLabel
                                    key={actigraphyText.name}
                                    name={actigraphyText.name}
                                    color={actigraphyText.color}
                                />
                            ))}
                        </Col>
                    </Row>
                    <Col xs="12" className="mt-3 d-flex">
                        <p className="mr-2">{textData.dataVisualizationText}</p>
                        <UncontrolledButtonDropdown
                            nav
                            inNavbar
                            disabled={loadingCharts}
                        >
                            <DropdownToggle caret>
                                {
                                    chartsModesOptions.filter(
                                        (mode) => mode.active
                                    )[0].text
                                }
                            </DropdownToggle>
                            <DropdownMenu>
                                {chartsModesOptions.map((mode) => {
                                    return (
                                        <DropdownItem
                                            key={mode.text}
                                            active={mode.active}
                                            onClick={() =>
                                                handleClickActigraphyData(
                                                    mode.type
                                                )
                                            }
                                        >
                                            {mode.text}
                                        </DropdownItem>
                                    );
                                })}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </Col>
                    {loadingCharts ? (
                        <Spinner />
                    ) : (
                        <Col xs="12" className="text-center chartContainer">
                            <Plot
                                data={chart.data}
                                layout={layout}
                                config={config}
                            />
                        </Col>
                    )}
                </Card>
            </Col>
        </>
    );
}

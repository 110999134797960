import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { setLoading } from '../../../../store/actions/loadingActions';
import { validateForm } from '../../../../helpers/formValidator';
import { editUser } from '../../../../services/api/users';
import history from '../../../../services/history';
import { DeviceFormFields } from './deviceFormFields';
import Input from '../../../../components/inputForm/inputText';
import Select from '../../../../components/inputForm/inputSelect';

MyForm.propTypes = {
    pageData: PropTypes.object.isRequired,
    schema: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    diaries: PropTypes.array,
    diaryOptions: PropTypes.array,
    devices: PropTypes.array.isRequired,
    devicesFields: PropTypes.array.isRequired,
    handleAddClick: PropTypes.func.isRequired,
    handleRemoveClick: PropTypes.func.isRequired,
};

export function MyForm({
    pageData,
    schema,
    user,
    diaries,
    diaryOptions,
    devices,
    devicesFields,
    handleAddClick,
    handleRemoveClick,
}) {
    const formRef = useRef(null);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);
    const currentUser = useSelector((state) => state.auth.currentUser);

    const sleepDiarySubscription = currentUser.subscription.plans.filter(
        (subscriptionPlan) => subscriptionPlan.type === 'sleep_diary'
    );

    const lumusSubscription = currentUser.subscription.plans.filter(
        (subscriptionPlan) => subscriptionPlan.type === 'lumus'
    );

    const handleSubmit = async (data) => {
        const { isValid, errors } = await validateForm(data, schema);

        if (isValid) {
            formRef.current.setErrors(errors);

            dispatch(setLoading(true));

            const response = await editUser(user.id, data);

            dispatch(setLoading(false));

            if (response) {
                toast.success(pageData.successText);
                history.push('/users');
            }
        } else {
            formRef.current.setErrors(errors);
        }
    };

    useEffect(() => {
        formRef.current.reset();

        const initialData = {
            name: user['name'],
            secondary_id: user['secondary_id'],
            email: user['email'],
            trialStart: user['trials_configurations']['trialStart'],
            trialEnd: user['trials_configurations']['trialEnd'],
        };

        formRef.current.setData(initialData);

        formRef.current.setFieldValue('active', {
            label: user.active ? pageData.yesText : pageData.noText,
            value: user.active,
        });

        if (user?.devices?.length > 0) {
            for (let i = 0; i < user.devices.length; i++) {
                formRef.current.setFieldValue(`devices[${i}].device_id`, {
                    label: user.devices[i].device.serial_no,
                    value: user.devices[i].device.device_id,
                });
                formRef.current.setFieldValue(`devices[${i}].body_part`, {
                    label: pageData.bodyPartOptions.find(
                        (option) => option.value === user.devices[i].body_part
                    )?.label,
                    value: user.devices[i].body_part,
                });
                formRef.current.setFieldValue(
                    `devices[${i}].received_at`,
                    user.devices[i].received_at
                );
            }
        }

        if (user?.trials_configurations?.diaryId) {
            let foundDiary = diaries.find(
                (diary) => diary.id === user['trials_configurations']['diaryId']
            );

            formRef.current.setFieldValue('diary_id', {
                label: foundDiary ? foundDiary.name : '',
                value: foundDiary ? foundDiary.id : -1,
            });
        } else {
            formRef.current.setFieldValue('diary_id', {
                label: pageData.defaultDiaryText,
                value: 0,
            });
        }
    }, []);

    useEffect(() => {
        if (user?.devices?.length > 0) {
            for (let i = 0; i < user.devices.length; i++) {
                formRef.current.setFieldValue(`devices[${i}].body_part`, {
                    label: pageData.bodyPartOptions.find(
                        (option) => option.value === user.devices[i].body_part
                    )?.label,
                    value: user.devices[i].body_part,
                });
            }
        }

        if (
            formRef.current.getFieldValue('active') !== undefined &&
            formRef.current.getFieldValue('active') !== ''
        ) {
            formRef.current.setFieldValue('active', {
                label: formRef.current.getFieldValue('active')
                    ? pageData.yesText
                    : pageData.noText,
                value: formRef.current.getFieldValue('active'),
            });
        }

        let diary_id = formRef.current.getFieldValue('diary_id');

        if (diary_id !== undefined && diary_id !== '') {
            let findDiary = diaryOptions.find(
                (diary) => diary.value === diary_id
            );
            formRef.current.setFieldValue('diary_id', {
                label:
                    diary_id === 0
                        ? pageData.defaultDiaryText
                        : findDiary?.label,
                value: diary_id,
            });
        }
    }, [lang]);

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-5">
                    <Input
                        name="name"
                        type="text"
                        label={pageData.nameText}
                        data-cy="edit_user_name_input"
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-3">
                    <Input
                        name="secondary_id"
                        type="text"
                        label={pageData.secondaryIdText}
                        data-cy="edit_user_secondary_id_input"
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-3">
                    <Input
                        name="email"
                        type="text"
                        label={pageData.emailText}
                        data-cy="edit_user_email_input"
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-3">
                    <Select
                        name="active"
                        label={pageData.activeText}
                        options={[
                            { value: false, label: pageData.noText },
                            { value: true, label: pageData.yesText },
                        ]}
                        isDisabled={
                            !(
                                sleepDiarySubscription.length > 0 &&
                                sleepDiarySubscription[0].active
                            )
                        }
                        data-cy="edit_user_active_input"
                        className="edit_user_active_input"
                    />
                </FormGroup>

                {currentUser.permissions !== 'condor_admin' && (
                    <>
                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="trialStart"
                                type="date"
                                label={pageData.trialStartText}
                                disabled={
                                    !(
                                        sleepDiarySubscription.length > 0 &&
                                        sleepDiarySubscription[0].active
                                    )
                                }
                                data-cy="edit_user_trialStart_input"
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="trialEnd"
                                type="date"
                                label={pageData.trialEndText}
                                disabled={
                                    !(
                                        sleepDiarySubscription.length > 0 &&
                                        sleepDiarySubscription[0].active
                                    )
                                }
                                data-cy="edit_user_trialEnd_input"
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-4">
                            <Select
                                name="diary_id"
                                label={pageData.diaryText}
                                options={diaryOptions}
                                isDisabled={
                                    !(
                                        sleepDiarySubscription.length > 0 &&
                                        sleepDiarySubscription[0].active
                                    )
                                }
                                data-cy="edit_user_diary_id_input"
                                className="edit_user_diary_id_input"
                            />
                        </FormGroup>
                    </>
                )}
            </FormGroup>

            {currentUser.permissions !== 'condor_admin' &&
                lumusSubscription.length > 0 &&
                lumusSubscription[0].active && (
                    <DeviceFormFields
                        pageData={pageData}
                        devicesFields={devicesFields}
                        handleAddClick={handleAddClick}
                        handleRemoveClick={handleRemoveClick}
                    />
                )}

            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button
                        color="primary"
                        className="mr-2"
                        type="submit"
                        data-cy="edit_user_save_button"
                    >
                        {pageData.saveText}
                    </Button>
                    <Link
                        to={'/users'}
                        className="btn btn-secondary"
                        data-cy="edit_user_cancel_link"
                    >
                        {pageData.cancelText}
                    </Link>
                </div>
            </FormGroup>
        </Form>
    );
}

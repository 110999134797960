import publicIp from 'public-ip';

export const getPublicIp = async () => {
    try {
        const ip = await publicIp.v4();
        return ip;
    } catch (e) {
        console.log('### ERROR - getPublicIp', e);
    }
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export function Search({
    searchByProperty,
    searchByText,
    entities,
    setFilteredEntities,
}) {
    const [searchText, setSearchText] = useState('');

    function handleNameInputChange(e) {
        setSearchText(e.target.value);
    }

    useEffect(() => {
        setFilteredEntities(
            entities.filter((entity) =>
                entity[searchByProperty]
                    .toUpperCase()
                    .includes(searchText.toUpperCase())
            )
        );
    }, [searchText]);

    return (
        <input
            name={`search-${searchByProperty}`}
            placeholder={searchByText}
            title={searchByText}
            value={searchText}
            onChange={handleNameInputChange}
        />
    );
}

Search.propTypes = {
    searchByProperty: PropTypes.string.isRequired,
    searchByText: PropTypes.string.isRequired,
    entities: PropTypes.array.isRequired,
    setFilteredEntities: PropTypes.func.isRequired,
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import { setLoading } from '../../store/actions/loadingActions';
import { fetchCompanies } from '../../services/api/companies';
import { useOrderByProperty } from '../../hooks/useOrderByProperty';
import SearchAndAddContainer from './components/searchAndAddContainer';
import TableContainer from './components/tableContainer';

export default function Companies({ currentUser }) {
    const [companies, setCompanies] = useState([]);
    const { activeFilter, filteredData, setFilteredData, orderByProperty } =
        useOrderByProperty(companies, 'name', 'asc');

    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.loading.isLoading);
    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            const data = await fetchCompanies();

            setCompanies(data?.companies);

            dispatch(setLoading(false));
        })();
    }, []);

    if (isLoading || pageData === null) {
        return <></>;
    }

    return (
        <>
            <SearchAndAddContainer
                companies={companies}
                setFilteredCompanies={setFilteredData}
                searchByNameInput={pageData.searchByNameInput}
                addButtonLabel={pageData.addButtonLabel}
                addButtonTitle={pageData.addButtonTitle}
            />
            <TableContainer
                ths={pageData.ths}
                tds={pageData.tds}
                filteredCompanies={filteredData}
                actions={pageData.actions}
                shareText={pageData.shareText}
                dosentShareText={pageData.dosentShareText}
                activeFilter={activeFilter}
                orderByProperty={orderByProperty}
            />
        </>
    );
}

Companies.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            schema: Yup.object().shape({
                serial_no: Yup.number().required(
                    'O número de série é obrigatório'
                ),
                brand: Yup.string().required('A marca é obrigatória'),
                model: Yup.string().required('O modelo é obrigatório'),
                company_id: Yup.number()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                purchased_at: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
            }),
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            schema: Yup.object().shape({
                serial_no: Yup.number().required('Serial number is required'),
                brand: Yup.string().required('Brand is required'),
                model: Yup.string().required('Model is required'),
                company_id: Yup.number()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                purchased_at: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
            }),
        },
    },
};

import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import styles from './deleteModal.module.css';

export function DeleteModal({ question, texts, handleDeleteQuestion }) {
    const [modal, setModal] = useState(false);

    function toggle() {
        setModal(!modal);
    }

    function handleDelete() {
        handleDeleteQuestion(question);
    }

    return (
        <>
            <Button
                type="button"
                color="danger"
                className="ml-2"
                onClick={toggle}
                title={texts.deleteText}
            >
                <FontAwesomeIcon icon={faTrash} />
            </Button>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader className={styles.headerContainer}>
                    {texts.deleteText}
                </ModalHeader>
                <ModalBody className={styles.bodyContainer}>
                    <>
                        <div className="col-xs-12 col-md-12 mb-3">
                            <p>
                                {texts.deleteQuestionText}{' '}
                                <strong>{question.descriptor.fieldtext}</strong>{' '}
                                ?
                            </p>
                        </div>

                        <div className="col-xs-12 col-md-12 text-left">
                            <Button
                                color="primary"
                                className="mr-2"
                                type="button"
                                onClick={handleDelete}
                            >
                                {texts.deleteText}
                            </Button>
                            <Button
                                color="secondary"
                                className="mr-2"
                                type="button"
                                onClick={toggle}
                            >
                                {texts.cancelText}
                            </Button>
                        </div>
                    </>
                </ModalBody>
            </Modal>
        </>
    );
}

DeleteModal.propTypes = {
    question: PropTypes.object.isRequired,
    texts: PropTypes.object.isRequired,
    handleDeleteQuestion: PropTypes.func.isRequired,
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Switch } from '@headlessui/react';
import {
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Row,
    Col,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { BsSmartwatch } from 'react-icons/bs';
import { FaInfinity, FaBook, FaEdit, FaTrash } from 'react-icons/fa';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import pageConfig from './page.config';
import { setLoading } from '../../store/actions/loadingActions';
import history from '../../services/history';
import { fetchDevices, activateDevice } from '../../services/api/devices';
import { useOrderByProperty } from '../../hooks/useOrderByProperty';
import {
    localeDateFormatter,
    localeDateTimeFormatter,
} from '../../helpers/formatters';
import { InfoCard } from '../../components/infoCard';
import Table from '../../components/table/table';
import Td from '../../components/table/td';
import { ThWithOrdering } from '../../components/table/thWithOrdering';

Devices.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function Devices({ currentUser }) {
    const [devices, setDevices] = useState([]);
    const [serialNumberFilter, setSerialNumberFilter] = useState('');
    const [loadingActivateDevice, setLoadingActivateDevice] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const { activeFilter, filteredData, setFilteredData, orderByProperty } =
        useOrderByProperty(devices, 'serial_no', 'asc');

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);
    const { dateLocale } = useSelector((state) => state.locale);
    const isLoading = useSelector((state) => state.loading.isLoading);

    const pageData = pageConfig[lang][currentUser.permissions];

    const active_devices = devices.reduce((acc, device) => {
        if (device.active) {
            return acc + 1;
        }

        return acc;
    }, 0);

    const lumusSubscription = currentUser.subscription.plans.find(
        (subscriptionPlan) => subscriptionPlan.type === 'lumus'
    );

    function updateDevice(deviceId, active) {
        setDevices((prevState) => {
            const newDevices = [...prevState];

            return newDevices.map((device) => ({
                ...device,
                active: device.id === deviceId ? active : device.active,
                body_part: device.id === deviceId ? null : device.body_part,
                last_server_connection:
                    device.id === deviceId
                        ? null
                        : device.last_server_connection,
                received_at: device.id === deviceId ? null : device.received_at,
                user_email: device.id === deviceId ? null : device.user_email,
                user_id: device.id === deviceId ? null : device.user_id,
                user_name: device.id === deviceId ? null : device.user_name,
            }));
        });

        setFilteredData((prevState) => {
            const newDevices = [...prevState];

            return newDevices.map((device) => ({
                ...device,
                active: device.id === deviceId ? active : device.active,
                body_part: device.id === deviceId ? null : device.body_part,
                last_server_connection:
                    device.id === deviceId
                        ? null
                        : device.last_server_connection,
                received_at: device.id === deviceId ? null : device.received_at,
                user_email: device.id === deviceId ? null : device.user_email,
                user_id: device.id === deviceId ? null : device.user_id,
                user_name: device.id === deviceId ? null : device.user_name,
            }));
        });
    }

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    async function handleChangeToogle(device, newToogleState) {
        setSelectedDevice(device);
        setLoadingActivateDevice(true);

        const response = await activateDevice(device.id, newToogleState);

        setSelectedDevice(null);
        setLoadingActivateDevice(false);

        if (response) {
            toast.success(pageData.successText);

            updateDevice(device.id, newToogleState);
        }
    }

    function handleSwitchChange(device, newValue) {
        if (device?.active && device.user_id) {
            toggleModal();
        } else {
            handleChangeToogle(device, newValue);
        }
    }

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            const data = await fetchDevices();

            if (data?.devices) {
                if (data.devices.length === 0) {
                    toast.info(pageData.noDevicesText);
                }

                setDevices(data?.devices);
                setFilteredData(data?.devices);
            }

            dispatch(setLoading(false));
        })();
    }, []);

    useEffect(() => {
        let tempFilteredDevices = [];

        if (serialNumberFilter) {
            tempFilteredDevices = devices.filter((device) =>
                String(device.serial_no)
                    .toUpperCase()
                    .includes(serialNumberFilter.toUpperCase())
            );
        } else {
            tempFilteredDevices = devices;
        }

        setFilteredData(tempFilteredDevices);
    }, [serialNumberFilter]);

    if (isLoading || pageData === null) {
        return <></>;
    }

    return (
        <>
            <Row>
                <Col xs="12" md="3" lg="3" xl="3">
                    <InfoCard
                        value={active_devices}
                        title={pageData.activeDevicesText}
                        icon={BsSmartwatch}
                        className="blueBackground"
                        data-cy="devices_active_devices_card"
                    />
                </Col>
                <Col xs="12" md="3" lg="3" xl="3">
                    <InfoCard
                        value={
                            lumusSubscription
                                ? lumusSubscription?.plan?.name !==
                                  'Lumus Unlimited'
                                    ? lumusSubscription?.max_devices -
                                      active_devices
                                    : FaInfinity
                                : 0
                        }
                        title={pageData.remainingDevicesQuotaText}
                        icon={BsSmartwatch}
                        className="greenBackground"
                        data-cy="devices_remaining_active_devices_card"
                    />
                </Col>
            </Row>

            <Row>
                <Col xs="12">
                    <div className={styles.searchAndAddContainer}>
                        <input
                            name="searchSerialNumber"
                            placeholder={pageData.searchBySerialNumberText}
                            title={pageData.searchBySerialNumberText}
                            value={serialNumberFilter}
                            onChange={(e) =>
                                setSerialNumberFilter(e.target.value)
                            }
                            data-cy="devices_search_serial_number_input"
                        />

                        {currentUser.permissions === 'condor_admin' && (
                            <>
                                <Link
                                    to={{
                                        pathname: '/devices/add',
                                    }}
                                    className="btn btn-primary ml-2"
                                    title={pageData.addText}
                                    data-cy="devices_add_device_link"
                                >
                                    {pageData.addText}
                                </Link>

                                <Link
                                    to={{
                                        pathname: 'devices/import',
                                    }}
                                    className="btn btn-primary ml-2"
                                    title={pageData.importDevicesText}
                                    data-cy="devices_import_devices_link"
                                >
                                    {pageData.importDevicesText}
                                </Link>
                            </>
                        )}

                        <Link
                            to={{
                                pathname: 'devices/battery_discharge',
                                state: {
                                    devices,
                                },
                            }}
                            className="btn btn-primary ml-2"
                            title={pageData.batteryDischargeCurveText}
                            data-cy="devices_battery_discharge_link"
                        >
                            {pageData.batteryDischargeCurveText}
                        </Link>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs="12">
                    <Table className={styles.devicesTable}>
                        <thead data-cy="devices_table_head">
                            <tr>
                                {currentUser.permissions === 'condor_admin' && (
                                    <th data-cy="devices_company_th">
                                        {pageData.companyText}
                                    </th>
                                )}
                                <ThWithOrdering
                                    property="serial_no"
                                    activeFilterProperty={activeFilter.property}
                                    activeOrder={activeFilter.order}
                                    orderByProperty={orderByProperty}
                                    activeClassName={styles.active}
                                    clickableIconClassName={
                                        styles.clickableIcon
                                    }
                                    data-cy="devices_serial_no_th"
                                >
                                    {pageData.serialNumberText}
                                </ThWithOrdering>
                                <th data-cy="devices_model_th">
                                    {pageData.modelText}
                                </th>
                                <th data-cy="devices_user_th">
                                    {pageData.userText}
                                </th>
                                <ThWithOrdering
                                    property="received_at"
                                    activeFilterProperty={activeFilter.property}
                                    activeOrder={activeFilter.order}
                                    orderByProperty={orderByProperty}
                                    activeClassName={styles.active}
                                    clickableIconClassName={
                                        styles.clickableIcon
                                    }
                                    data-cy="devices_received_at_th"
                                >
                                    {pageData.receivedAtText}
                                </ThWithOrdering>
                                <ThWithOrdering
                                    property="last_server_connection"
                                    activeFilterProperty={activeFilter.property}
                                    activeOrder={activeFilter.order}
                                    orderByProperty={orderByProperty}
                                    activeClassName={styles.active}
                                    clickableIconClassName={
                                        styles.clickableIcon
                                    }
                                    data-cy="devices_last_server_connection_th"
                                >
                                    {pageData.lastUpdateText}
                                </ThWithOrdering>
                                <th data-cy="devices_active_th">
                                    {pageData.activeText}
                                </th>
                                <th data-cy="devices_actions_th">
                                    {pageData.actionsText}
                                </th>
                            </tr>
                        </thead>
                        <tbody data-cy="devices_table_body">
                            {filteredData.map((device) => (
                                <tr key={device.id}>
                                    {currentUser.permissions ===
                                        'condor_admin' && (
                                        <Td
                                            title={pageData.companyText}
                                            data-cy={`devices_table_tr_company_${device.id}`}
                                        >
                                            {device.company_name || '--'}
                                        </Td>
                                    )}
                                    <Td
                                        title={pageData.serialNumberText}
                                        data-cy={`devices_table_tr_serial_no_${device.id}`}
                                    >
                                        {device.serial_no}
                                    </Td>
                                    <Td
                                        title={pageData.modelText}
                                        data-cy={`devices_table_tr_model_${device.id}`}
                                    >
                                        {device.model}
                                    </Td>
                                    <Td
                                        title={pageData.userText}
                                        data-cy={`devices_table_tr_user_${device.id}`}
                                    >
                                        {device.user_name || '--'}
                                    </Td>
                                    <Td
                                        title={pageData.receivedAtText}
                                        data-cy={`devices_table_tr_received_at_${device.id}`}
                                    >
                                        {device.received_at
                                            ? localeDateFormatter(
                                                  device.received_at,
                                                  dateLocale
                                              )
                                                ? localeDateFormatter(
                                                      device.received_at,
                                                      dateLocale
                                                  )
                                                : '--'
                                            : '--'}
                                    </Td>
                                    <Td
                                        title={pageData.lastUpdateText}
                                        data-cy={`devices_table_tr_last_server_connection_${device.id}`}
                                    >
                                        {device.last_server_connection
                                            ? localeDateTimeFormatter(
                                                  device.last_server_connection,
                                                  dateLocale
                                              )
                                                ? localeDateTimeFormatter(
                                                      device.last_server_connection,
                                                      dateLocale
                                                  )
                                                : '--'
                                            : '--'}
                                    </Td>
                                    <Td
                                        title={pageData.activeText}
                                        data-cy={`devices_table_tr_active_${device.id}`}
                                    >
                                        <div
                                            className={
                                                styles.activeSwitchContainer
                                            }
                                        >
                                            {loadingActivateDevice ? (
                                                selectedDevice?.id ===
                                                device.id ? (
                                                    <Spinner
                                                        size="sm"
                                                        className="mr-1"
                                                    />
                                                ) : (
                                                    <Spinner
                                                        size="sm"
                                                        className={`mr-1 ${styles.visibilityHidden}`}
                                                    />
                                                )
                                            ) : (
                                                <></>
                                            )}

                                            <Switch
                                                checked={device?.active}
                                                onChange={(newValue) =>
                                                    handleSwitchChange(
                                                        device,
                                                        newValue
                                                    )
                                                }
                                                className={`${
                                                    styles.toggleButton
                                                } ${
                                                    device?.active
                                                        ? styles.enabled
                                                        : ''
                                                }`}
                                                title={
                                                    device?.active
                                                        ? pageData.deactivateDeviceText
                                                        : pageData.activateDeviceText
                                                }
                                                disabled={loadingActivateDevice}
                                                data-cy={`devices_table_tr_active_switch_${device.id}`}
                                            >
                                                <span
                                                    className={`${
                                                        styles.toggleIcon
                                                    } ${
                                                        device?.active
                                                            ? styles.enabled
                                                            : ''
                                                    }`}
                                                />
                                            </Switch>
                                        </div>
                                    </Td>
                                    <Td
                                        title={pageData.actionsText}
                                        data-cy={`devices_table_tr_actions_${device.id}`}
                                    >
                                        <Link
                                            to={{
                                                pathname: 'devices/report',
                                                state: {
                                                    entity: device,
                                                    to: history.location
                                                        .pathname,
                                                },
                                            }}
                                            className="btn btn-success"
                                            title={pageData.reportText}
                                            data-cy={`devices_table_tr_report_action_link_${device.id}`}
                                        >
                                            <FaBook />
                                        </Link>
                                        <Link
                                            to={{
                                                pathname: 'devices/edit',
                                                state: {
                                                    entity: device,
                                                    to: history.location
                                                        .pathname,
                                                },
                                            }}
                                            className="btn btn-secondary ml-2"
                                            title={pageData.editText}
                                            data-cy={`devices_table_tr_edit_action_link_${device.id}`}
                                        >
                                            <FaEdit />
                                        </Link>
                                        {currentUser.permissions ===
                                            'condor_admin' && (
                                            <Link
                                                to={{
                                                    pathname: 'devices/delete',
                                                    state: {
                                                        entity: device,
                                                        to: history.location
                                                            .pathname,
                                                    },
                                                }}
                                                className="btn btn-danger ml-2"
                                                title={pageData.deleteText}
                                                data-cy={`devices_table_tr_delete_action_link_${device.id}`}
                                            >
                                                <FaTrash />
                                            </Link>
                                        )}
                                    </Td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Modal isOpen={isOpen} toggle={toggleModal}>
                <ModalHeader
                    toggle={toggleModal}
                    data-cy="devices_modal_header_text"
                >
                    {pageData.attentionText}
                </ModalHeader>
                <ModalBody data-cy="devices_modal_body_text">
                    {pageData.warningDeactivateDeviceText}
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="button"
                        color="primary"
                        onClick={() => {
                            toggleModal();
                            handleChangeToogle(selectedDevice, false);
                        }}
                        data-cy="devices_modal_deactivate_button"
                    >
                        {pageData.deactivateText}
                    </Button>

                    <Button
                        type="button"
                        color="secondary"
                        onClick={toggleModal}
                        data-cy="devices_modal_cancel_button"
                    >
                        {pageData.cancelText}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import '../../../../styles/pages/account/changePlan/changePlan.css';

import pageConfig from './page.config';
import { setLoading } from '../../../../store/actions/loadingActions';
import { fetchPlans } from '../../../../services/api/account';
import history from '../../../../services/history';
import { CardItem } from './components/card';
import { LumusCardPlan } from '../../../../components/lumusPlanCard';

ChangePlan.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function ChangePlan({ currentUser }) {
    const [plansGroups, setPlansGroups] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);

    const [planType, setPlanType] = useState(null);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);
    const isLoading = useSelector((state) => state.loading.isLoading);

    const pageData = pageConfig[lang][currentUser.permissions];

    useEffect(() => {
        (async function () {
            if (!history.location.state?.currency) {
                history.push('/account/my_account');
                return;
            }

            setPlanType(history.location.state.type);

            setCurrency(history.location.state.currency);

            setPaymentMethod(history.location.state.paymentMethod);

            dispatch(setLoading(true));

            const data = await fetchPlans(history.location.state.type);

            setPlansGroups(data?.planGroups);

            dispatch(setLoading(false));
        })();
    }, []);

    if (isLoading || plansGroups === null) {
        return <></>;
    }

    return (
        <Row className="plansContainer">
            {planType === 'sleep_diary' &&
                plansGroups.map((planGroup) => (
                    <CardItem
                        key={planGroup.planName}
                        plans={planGroup.plans}
                        planName={planGroup.planName}
                        planPrices={planGroup.planPrices}
                        maxPatients={planGroup.maxPatients}
                        currency={currency}
                        paymentMethod={paymentMethod}
                        texts={{
                            planPaymentTypeText:
                                pageData.planPaymentTypeText[
                                    planGroup.intervalType
                                ],
                            fidelityText: pageData.fidelityText,
                            maxPatientsText: pageData.maxPatientsText,
                            currentPlanButtonText:
                                pageData.currentPlanButtonText,
                            chooseText: pageData.chooseText,
                        }}
                        currentUser={currentUser}
                    />
                ))}

            {planType === 'lumus' && (
                <Row className="d-flex flex-column">
                    {plansGroups.map((planGroup) => (
                        <LumusCardPlan
                            key={planGroup.name}
                            name={planGroup.name}
                            plans={planGroup.plans}
                            prices={planGroup.prices}
                            currency={currency}
                            paymentMethod={paymentMethod}
                            texts={{
                                numberOfDevicesText:
                                    pageData.numberOfDevicesText,
                                monthlyText: pageData.fidelityText.monthly,
                                biannualText: pageData.fidelityText.biannual,
                                annualText: pageData.fidelityText.annual,
                                devicesText: pageData.devicesText,
                                chooseText: pageData.chooseText,
                                upToText: pageData.upToText,
                                fromText: pageData.fromText,
                            }}
                            currentUser={currentUser}
                            planType={planType}
                        />
                    ))}

                    <Row className="mt-4">
                        <Col className="d-flex flex-column">
                            <Link
                                className="btn btn-primary"
                                to={{
                                    pathname:
                                        '/account/my_account/change_plan/choose_subscription',
                                    state: {
                                        plans: plansGroups[0].plans,
                                        currency,
                                        paymentMethod,
                                        plansGroups,
                                        planType: 'lumus',
                                    },
                                }}
                            >
                                {pageData.chooseSubscriptionText}
                            </Link>
                        </Col>
                    </Row>
                </Row>
            )}
        </Row>
    );
}

import { REPORT } from '../constants';

export function updateReportRequest(data) {
    return {
        type: REPORT.UPDATE_REPORT_REQUEST,
        payload: data,
    };
}

export function updateReport(reportData, user, minDate, maxDate, daysInWeek) {
    return {
        type: REPORT.UPDATE_REPORT,
        payload: { reportData, user, minDate, maxDate, daysInWeek },
    };
}

export function updateUserRequest({ user }) {
    return {
        type: REPORT.UPDATE_USER_REQUEST,
        payload: { user },
    };
}

export function updateUser(data) {
    return {
        type: REPORT.UPDATE_USER,
        payload: { data },
    };
}

export function updateDatesRequest({ minDate, maxDate }) {
    return {
        type: REPORT.UPDATE_DATES_REQUEST,
        payload: { minDate, maxDate },
    };
}

export function updateDates(data) {
    return {
        type: REPORT.UPDATE_DATES,
        payload: { data },
    };
}

export function updateDaysInWeekRequest({ daysInWeek }) {
    return {
        type: REPORT.UPDATE_DAYS_IN_WEEK_REQUEST,
        payload: { daysInWeek },
    };
}

export function updateDaysInWeek(data) {
    return {
        type: REPORT.UPDATE_DAYS_IN_WEEK,
        payload: { data },
    };
}

export function updateWeekReferenceRequest({
    currentWeekReference,
    // userId,
    // date = null,
    // minDate = null,
    // maxDate = null,
    // daysInWeek = null,
}) {
    return {
        type: REPORT.UPDATE_WEEK_REFERENCE_REQUEST,
        payload: { currentWeekReference },
        // payload: { userId, date, minDate, maxDate, daysInWeek },
    };
}

export function updateWeekReferenceSuccess(data, status = 'done') {
    return {
        type: REPORT.UPDATE_WEEK_REFERENCE_SUCCESS,
        payload: { data },
        // payload: { data, status },
    };
}

export function updateWeekReferenceFailure(status = 'done') {
    return {
        type: REPORT.UPDATE_WEEK_REFERENCE_FAILURE,
        payload: { status },
    };
}

export function updateCalendarRequest({
    calendar,
    // userId,
    // date = null,
    // minDate = null,
    // maxDate = null,
    // daysInWeek = null,
}) {
    return {
        type: REPORT.UPDATE_CALENDAR_REQUEST,
        payload: { calendar },
        // payload: { userId, date, minDate, maxDate, daysInWeek },
    };
}

export function updateCalendarSuccess(data, status = 'done') {
    return {
        type: REPORT.UPDATE_CALENDAR_SUCCESS,
        payload: { data },
        // payload: { data, status },
    };
}

export function updateCalendarFailure(status = 'done') {
    return {
        type: REPORT.UPDATE_CALENDAR_FAILURE,
        payload: { status },
    };
}

export function updateWeeklyEvolutionRequest({
    weeklyEvolution,
    // userId,
    // date = null,
    // minDate = null,
    // maxDate = null,
    // daysInWeek = null,
}) {
    return {
        type: REPORT.UPDATE_WEEKLY_EVOLUTION_REQUEST,
        payload: { weeklyEvolution },
        // payload: { userId, date, minDate, maxDate, daysInWeek },
    };
}

export function updateWeeklyEvolutionSuccess(data, status = 'done') {
    return {
        type: REPORT.UPDATE_WEEKLY_EVOLUTION_SUCCESS,
        payload: { data },
        // payload: { data, status },
    };
}

export function updateWeeklyEvolutionFailure(status = 'done') {
    return {
        type: REPORT.UPDATE_WEEKLY_EVOLUTION_FAILURE,
        payload: { status },
    };
}

export function updateDailyEvolutionRequest({
    dailyEvolution,
    // userId,
    // date = null,
    // minDate = null,
    // maxDate = null,
    // daysInWeek = null,
}) {
    return {
        type: REPORT.UPDATE_DAILY_EVOLUTION_REQUEST,
        payload: { dailyEvolution },
        // payload: { userId, date, minDate, maxDate, daysInWeek },
    };
}

export function updateDailyEvolutionSuccess(data, status = 'done') {
    return {
        type: REPORT.UPDATE_DAILY_EVOLUTION_SUCCESS,
        payload: { data },
        // payload: { data, status },
    };
}

export function updateDailyEvolutionFailure(status = 'done') {
    return {
        type: REPORT.UPDATE_DAILY_EVOLUTION_FAILURE,
        payload: { status },
    };
}

export function updateAverageTableRequest({
    data,
    // userId,
    // date = null,
    // minDate = null,
    // maxDate = null,
    // daysInWeek = null,
}) {
    return {
        type: REPORT.UPDATE_AVERAGE_TABLE_REQUEST,
        payload: { data },
        // payload: { userId, date, minDate, maxDate, daysInWeek },
    };
}

export function updateAverageTableSuccess(data, status = 'done') {
    return {
        type: REPORT.UPDATE_AVERAGE_TABLE_SUCCESS,
        payload: { data },
        // payload: { data, status },
    };
}

export function updateAverageTableFailure(status = 'done') {
    return {
        type: REPORT.UPDATE_AVERAGE_TABLE_FAILURE,
        payload: { status },
    };
}

export function updateEditableTableRequest({
    data,
    // userId,
    // date = null,
    // minDate = null,
    // maxDate = null,
    // daysInWeek = null,
}) {
    return {
        type: REPORT.UPDATE_EDITABLE_TABLE_REQUEST,
        payload: { data },
    };
}

export function updateEditableTableSuccess(data, status = 'done') {
    return {
        type: REPORT.UPDATE_EDITABLE_TABLE_SUCCESS,
        payload: { data },
        // payload: { data, status },
    };
}

export function updateEditableTableFailure(status = 'done') {
    return {
        type: REPORT.UPDATE_EDITABLE_TABLE_FAILURE,
        payload: { status },
    };
}

export function updateEditableTableWeeksRequest({
    data,
    // userId,
    // date = null,
    // minDate = null,
    // maxDate = null,
    // daysInWeek = null,
}) {
    return {
        type: REPORT.UPDATE_EDITABLE_TABLE_WEEKS_REQUEST,
        payload: { data },
        // payload: { userId, date, minDate, maxDate, daysInWeek },
    };
}

export function updateEditableTableWeeksSuccess(data, status = 'done') {
    return {
        type: REPORT.UPDATE_EDITABLE_TABLE_WEEKS_SUCCESS,
        payload: { data },
        // payload: { data, status },
    };
}

export function updateEditableTableWeeksFailure(status = 'done') {
    return {
        type: REPORT.UPDATE_EDITABLE_TABLE_WEEKS_FAILURE,
        payload: { status },
    };
}

export function updateActogramRequest({
    data,
    // userId,
    // date = null,
    // minDate = null,
    // maxDate = null,
    // daysInWeek = null,
}) {
    return {
        type: REPORT.UPDATE_ACTOGRAM_REQUEST,
        payload: { data },
    };
}

export function updateActogramSuccess(data, status = 'done') {
    return {
        type: REPORT.UPDATE_ACTOGRAM_SUCCESS,
        payload: { data },
        // payload: { data, status },
    };
}

export function updateActogramFailure(status = 'done') {
    return {
        type: REPORT.UPDATE_ACTOGRAM_FAILURE,
        payload: { status },
    };
}

export function updateSleepScoringRequest({
    data,
    // userId,
    // date = null,
    // minDate = null,
    // maxDate = null,
    // daysInWeek = null,
}) {
    return {
        type: REPORT.UPDATE_SLEEP_SCORING_REQUEST,
        payload: { data },
    };
}

export function updateSleepScoringSuccess(data, status = 'done') {
    return {
        type: REPORT.UPDATE_SLEEP_SCORING_SUCCESS,
        payload: { data },
        // payload: { data, status },
    };
}

export function updateSleepScoringFailure(status = 'done') {
    return {
        type: REPORT.UPDATE_SLEEP_SCORING_FAILURE,
        payload: { status },
    };
}

export function resetReportState() {
    return {
        type: REPORT.RESET_STATE,
    };
}

import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, Button, FormGroup } from 'reactstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import history from '../../../services/history';
import { sendUserCredentials } from '../../../services/api/users';
import { validateForm } from '../../../helpers/formValidator';
import { setLoading } from '../../../store/actions/loadingActions';
import Input from '../../../components/inputForm/inputText';
import Select from '../../../components/inputForm/inputSelect';

ManagerCredentials.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function ManagerCredentials({ currentUser }) {
    const formRef = useRef(null);

    const [manager, setManager] = useState({});

    const lang = useSelector((state) => state.lang.lang);

    const dispatch = useDispatch();

    const pageData = pageConfig[lang][currentUser.permissions];

    const { schema } = schemaConfig[lang][currentUser.permissions];

    const languageOptions = [
        { value: 'pt', label: pageData.portugueseText },
        { value: 'en', label: pageData.englishText },
        { value: 'ge', label: pageData.germanyText },
    ];

    const handleSubmit = async (data) => {
        const { parsedData, isValid, errors } = await validateForm(
            data,
            schema
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(setLoading(true));

            const response = await sendUserCredentials(manager.id, parsedData);

            dispatch(setLoading(false));

            if (response) {
                toast.success(pageData.successText);

                history.push('/managers');
            }
        }
    };

    useEffect(() => {
        if (history.location.state?.user) {
            let tempUser = history.location.state.user;

            formRef.current.setData({
                name: tempUser.name,
                email: tempUser.email,
            });

            setManager(tempUser);
        } else {
            history.push('/managers');
        }
    }, []);

    useEffect(() => {
        if (formRef.current.getFieldValue('lang')) {
            let selectedLanguage = languageOptions.find(
                (lang) => lang.value === formRef.current.getFieldValue('lang')
            );

            formRef.current.setFieldValue('lang', {
                label: selectedLanguage?.label,
                value: selectedLanguage?.value,
            });
        }
    }, [lang]);

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-5">
                            <Input
                                name="name"
                                type="text"
                                label={pageData.nameText}
                                readOnly
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="email"
                                type="text"
                                label={pageData.emailText}
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-3">
                            <Select
                                name="lang"
                                label={pageData.languageText}
                                options={languageOptions}
                                required
                            />
                        </FormGroup>
                    </FormGroup>

                    <FormGroup row>
                        <div className="col-xs-12 col-md-12 text-left">
                            <Button
                                color="primary"
                                className="mr-2"
                                type="submit"
                            >
                                {pageData.sendText}
                            </Button>
                            <Link
                                to={{
                                    pathname: '/managers/edit',
                                    state: { user: manager },
                                }}
                                className="btn btn-secondary mr-2"
                            >
                                {pageData.changeEmailText}
                            </Link>
                            <Link to="/managers" className="btn btn-secondary">
                                {pageData.cancelText}
                            </Link>
                        </div>
                    </FormGroup>
                </Form>
            </CardBody>
        </Card>
    );
}

export default {
    pt: {
        customer_admin: {
            updateText: 'Atualizar',
            notAssociatedText: 'Não associado',
            noDataText: 'Não há dados!',
            tabsText: {
                systemText: 'Sistema',
                sensorsText: 'Sensores',
                memoryText: 'Memória',
                systemLogsText: 'Históricos',
            },
            lastUpdateText: 'Última atualização: ',
            ths: [
                'Número de série',
                'Marca',
                'Modelo',
                'Data de aquisição',
                'Usuário associado',
                'Recebido em',
            ],
            tds: [
                {
                    th: 'Número de série',
                    property: 'serial_no',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Marca',
                    property: 'brand',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Modelo',
                    property: 'model',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Data de aquisição',
                    property: 'purchased_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Usuário associado',
                    property: 'user_name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Recebido em',
                    property: 'received_at',
                    hasLink: false,
                    to: '',
                },
            ],
            system: {
                batteryVoltageText: 'Tensão da bateria (V)',
                batteryVoltageChartText:
                    'O gráfico indica a tensão da bateria nos últimos 2 meses.',
                logTypeDescriptions: {
                    64: 'Bateria',
                    96: 'Conexão',
                    112: 'Desconexão',
                },
                hoursText: 'Horas',
                voltageText: 'Tensão (V)',
                connectionText: 'Conexão',
                disconnectionText: 'Desconexão',
                ths: ['Data', 'Tensão da bateria', 'Evento'],
                tds: [
                    {
                        th: 'Data',
                        property: 'formattedLogTime',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Tensão da bateria',
                        property: 'vBat',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Evento',
                        property: 'formattedLogType',
                        hasLink: false,
                        to: '',
                    },
                ],
                noDataText: 'Não há dados!',
                oneWeekText: '1 semana',
                oneMonthText: '1 mês',
                onDayText: '1 dia',
            },
            sensors: {
                actigraphyDataText: 'Dados de actigrafia',
                temperatureDataText: 'Dados de temperatura',
                lightIntensityDataText: 'Dados de intensidade luminosa',
                luxDataText: 'Dados de sensores de lux',
                capacitiveDataText: 'Dados de sensores capacitivos',
                actigraphyChartText:
                    'O gráfico indica os dados de actigrafia obtidos na última semana.',
                temperatureChartText:
                    'O gráfico indica a temperatura do dispositivo na última semana.',
                lightIntensityChartText:
                    'O gráfico indica os dados de intensidade luminosa obtidos na última semana.',
                luxChartText:
                    'O gráfico indica os dados de sensores de lux obtidos na última semana.',
                capacitiveChartText:
                    'O gráfico indica os dados de sensores capacitivos obtidos na última semana.',
                hoursText: 'Horas',
                temperatureText: 'Temperatura (°C)',
                actigraphyTexts: [
                    {
                        name: 'PIM',
                        color: '#7F3FBF',
                    },
                    {
                        name: 'TAT',
                        color: '#D00F0F',
                    },
                    {
                        name: 'ZCM',
                        color: '#23D00F',
                    },
                ],
                temperatureTexts: [
                    {
                        name: 'Temperatura',
                        color: '#1A33F4',
                    },
                ],
                lightsTexts: [
                    {
                        name: 'F1',
                        color: '#6A00FF',
                    },
                    {
                        name: 'F2',
                        color: '#003BFF',
                    },
                    {
                        name: 'F3',
                        color: '#00CDFF',
                    },
                    {
                        name: 'F4',
                        color: '#2DFF00',
                    },
                    {
                        name: 'F5',
                        color: '#B6FF00',
                    },
                    {
                        name: 'F6',
                        color: '#FFE600',
                    },
                    {
                        name: 'F7',
                        color: '#FF5F00',
                    },
                    {
                        name: 'F8',
                        color: '#FF0000',
                    },
                    {
                        name: 'Clear',
                        color: '#3F7FBF',
                    },
                    {
                        name: 'Nir',
                        color: '#BF3F3F',
                    },
                ],
                luxTexts: [
                    {
                        name: 'Lux photopic',
                        color: '#ffff00',
                    },
                    {
                        name: 'Lux melanopic',
                        color: '#00ffff',
                    },
                ],
                capacitiveTexts: [
                    {
                        name: 'Sensor capacitivo 1',
                        color: '#3F7FBF',
                    },
                    {
                        name: 'Sensor capacitivo 2',
                        color: '#7F3FBF',
                    },
                ],
                noDataText: 'Não há dados!',
            },
            memory: {
                systemMemoryUsageText: 'Uso de memória do sistema',
                sensorsMemoryUsageText: 'Uso de memória dos sensores',
                systemMemoryUsageChartText:
                    'O gráfico indica a porcentagem do uso da memória destinada ao sistema.',
                sensorsMemoryUsageChartText:
                    'O gráfico indica a porcentagem do uso da memória destinada aos sensores.',
                availableText: 'Disponível',
                usedText: 'Ocupada',
                noDataText: 'Não há dados!',
            },
            systemLogs: {
                selectLogTimeText: 'Selecione o tempo do histórico',
                oneWeekText: '1 semana',
                oneMonthText: '1 mês',
                twoMonthsText: '2 meses',
                threeMonthsText: '3 meses',
                fourMonthText: '4 meses',
                serverConnectionHistoryText:
                    'Histórico de conexões com o servidor',
                eraseDeviceMemoryHistoryText: 'Histórico de limpeza de memória',
                fullChargeLogText: 'Histórico de cargas completas',
                batteryUsbStateLogText: 'Histórico de conexões e desconexões',
                powerDownLogText: 'Histórico de power down',
                bootLogText: 'Histórico de boot',
                errorsLogText: 'Histórico de erros',
                associationLogText: 'Histórico de associações',
                commandTypeText: 'Tipo de comando',
                dateTimeCommandSentText:
                    'Data e hora em que o comando foi enviado',
                requestCommandText: 'Requisição de comando',
                executionCommandText: 'Execução de comando',
                ths: [
                    'Data e horário da última conexão com o servidor',
                    'Data e horário da última conexão com o dispositivo',
                    'Quantidade de mensagens trocadas',
                    'Quantidade de mensagens trocadas em Kb',
                ],
                tds: [
                    {
                        th: 'Data e horário da última conexão com o servidor',
                        property: 'lastServerConnection',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Data e horário da última conexão com o dispositivo',
                        property: 'lastDeviceConnection',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Quantidade de mensagens trocadas',
                        property: 'payloadMsgs',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Quantidade de mensagens trocadas em Kb',
                        property: 'payloadKbs',
                        hasLink: false,
                        to: '',
                    },
                ],
                serverConnection: {
                    ths: [
                        'Data e horário da conexão com o servidor',
                        // 'Número de conexões',
                        'Quantidade de dados inseridos',
                    ],
                    tds: [
                        {
                            th: 'Data e horário da conexão com o servidor',
                            property: 'lastServerConnection',
                            hasLink: false,
                            to: '',
                        },
                        // {
                        //     th: 'Número de conexões',
                        //     property: 'numberOfConnections',
                        //     hasLink: false,
                        //     to: '',
                        // },
                        {
                            th: 'Quantidade de dados inseridos',
                            property: 'totalLogs',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                eraseDeviceMemory: {
                    ths: [
                        'Tipo de comando',
                        'Data e hora em que o comando foi enviado',
                    ],
                    tds: [
                        {
                            th: 'Tipo de comando',
                            property: 'type',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Data e hora em que o comando foi enviado',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                fullCharge: {
                    ths: ['Data e horário da carga completa'],
                    tds: [
                        {
                            th: 'Data e horário da carga completa',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                batteryUsbState: {
                    logTypeDescriptions: {
                        64: 'Bateria',
                        96: 'Conexão',
                        112: 'Desconexão',
                    },
                    ths: ['Data', 'Tensão da bateria', 'Evento'],
                    tds: [
                        {
                            th: 'Data',
                            property: 'formattedLogTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Tensão da bateria',
                            property: 'vBat',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Evento',
                            property: 'formattedLogType',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                powerDown: {
                    ths: [
                        'Data e horário de power down',
                        'Tensão da Bateria (V)',
                    ],
                    tds: [
                        {
                            th: 'Data e horário de power down',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Tensão da Bateria (V)',
                            property: 'vBat',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                boot: {
                    resetReasonsTexts: {
                        0: '',
                        1: 'pelo pino de reset',
                        2: 'pelo watchdog',
                        4: 'por software',
                        8: 'por travamento da CPU',
                        16: 'por sinal de wake detectado no GPIO',
                        32: 'pelo LPCOMP',
                        64: 'pelo dispositivo ter entrado em modo debug',
                        128: 'pelo NFC',
                    },
                    ths: ['Data e horário de boot', 'Razão de boot'],
                    tds: [
                        {
                            th: 'Data e horário de boot',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Razão de boot',
                            property: 'formattedBootCode',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                errors: {
                    errorsTexts: [
                        'POWER_MANAGEMENT',
                        'LIGHT_SENSOR_1',
                        'ACCEL',
                        'TEMP',
                        'VBAT',
                        'FLASH',
                        'BLE',
                        'GPIO',
                        'SDK_NORDIC',
                    ],
                    ths: [
                        'Data e horário do erro',
                        'Gerenciador de energia',
                        'Sensor de luz 1',
                        'Acelerômetro',
                        'Sensor de temperatura',
                        'Módulo de medição de tensão da bateria',
                        'Memória flash',
                        'BLE',
                        'GPIO',
                        'SDK',
                    ],
                    tds: [
                        {
                            th: 'Data e horário do erro',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Gerenciador de energia',
                            property: 'POWER_MANAGEMENT',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Sensor de luz 1',
                            property: 'LIGHT_SENSOR_1',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Acelerômetro',
                            property: 'ACCEL',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Sensor de temperatura',
                            property: 'TEMP',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Módulo de medição de tensão da bateria',
                            property: 'VBAT',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Memória flash',
                            property: 'FLASH',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'BLE',
                            property: 'BLE',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'GPIO',
                            property: 'GPIO',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'SDK',
                            property: 'SDK',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                association: {
                    ths: [
                        'Data de recebimento',
                        'Data de devolução',
                        'Usuário associado',
                    ],
                    tds: [
                        {
                            th: 'Data recebimento',
                            property: 'receivedAt',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Data de devolução',
                            property: 'returnedAt',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Usuário associado ',
                            property: 'lastUserId',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                noDataText: 'Não há dados!',
            },
            systemLogDataText: 'Log de sistema',
        },
        manager: {
            updateText: 'Atualizar',
            notAssociatedText: 'Não associado',
            noDataText: 'Não há dados!',
            tabsText: {
                systemText: 'Sistema',
                sensorsText: 'Sensores',
                memoryText: 'Memória',
                systemLogsText: 'Históricos',
            },
            lastUpdateText: 'Última atualização: ',
            ths: [
                'Número de série',
                'Marca',
                'Modelo',
                'Data de aquisição',
                'Usuário associado',
                'Recebido em',
            ],
            tds: [
                {
                    th: 'Número de série',
                    property: 'serial_no',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Marca',
                    property: 'brand',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Modelo',
                    property: 'model',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Data de aquisição',
                    property: 'purchased_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Usuário associado',
                    property: 'user_name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Recebido em',
                    property: 'received_at',
                    hasLink: false,
                    to: '',
                },
            ],
            system: {
                batteryVoltageText: 'Tensão da bateria (V)',
                batteryVoltageChartText:
                    'O gráfico indica a tensão da bateria nos últimos 2 meses.',
                logTypeDescriptions: {
                    64: 'Bateria',
                    96: 'Conexão',
                    112: 'Desconexão',
                },
                hoursText: 'Horas',
                voltageText: 'Tensão (V)',
                connectionText: 'Conexão',
                disconnectionText: 'Desconexão',
                ths: ['Data', 'Tensão da bateria', 'Evento'],
                tds: [
                    {
                        th: 'Data',
                        property: 'formattedLogTime',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Tensão da bateria',
                        property: 'vBat',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Evento',
                        property: 'formattedLogType',
                        hasLink: false,
                        to: '',
                    },
                ],
                noDataText: 'Não há dados!',
                oneWeekText: '1 semana',
                oneMonthText: '1 mês',
                onDayText: '1 dia',
            },
            sensors: {
                actigraphyDataText: 'Dados de actigrafia',
                temperatureDataText: 'Dados de temperatura',
                lightIntensityDataText: 'Dados de intensidade luminosa',
                luxDataText: 'Dados de sensores de lux',
                capacitiveDataText: 'Dados de sensores capacitivos',
                actigraphyChartText:
                    'O gráfico indica os dados de actigrafia obtidos na última semana.',
                temperatureChartText:
                    'O gráfico indica a temperatura do dispositivo na última semana.',
                lightIntensityChartText:
                    'O gráfico indica os dados de intensidade luminosa obtidos na última semana.',
                luxChartText:
                    'O gráfico indica os dados de sensores de lux obtidos na última semana.',
                capacitiveChartText:
                    'O gráfico indica os dados de sensores capacitivos obtidos na última semana.',
                hoursText: 'Horas',
                temperatureText: 'Temperatura (°C)',
                actigraphyTexts: [
                    {
                        name: 'PIM',
                        color: '#7F3FBF',
                    },
                    {
                        name: 'TAT',
                        color: '#D00F0F',
                    },
                    {
                        name: 'ZCM',
                        color: '#23D00F',
                    },
                ],
                temperatureTexts: [
                    {
                        name: 'Temperatura',
                        color: '#1A33F4',
                    },
                ],
                lightsTexts: [
                    {
                        name: 'F1',
                        color: '#6A00FF',
                    },
                    {
                        name: 'F2',
                        color: '#003BFF',
                    },
                    {
                        name: 'F3',
                        color: '#00CDFF',
                    },
                    {
                        name: 'F4',
                        color: '#2DFF00',
                    },
                    {
                        name: 'F5',
                        color: '#B6FF00',
                    },
                    {
                        name: 'F6',
                        color: '#FFE600',
                    },
                    {
                        name: 'F7',
                        color: '#FF5F00',
                    },
                    {
                        name: 'F8',
                        color: '#FF0000',
                    },
                    {
                        name: 'Clear',
                        color: '#3F7FBF',
                    },
                    {
                        name: 'Nir',
                        color: '#BF3F3F',
                    },
                ],
                luxTexts: [
                    {
                        name: 'Lux photopic',
                        color: '#ffff00',
                    },
                    {
                        name: 'Lux melanopic',
                        color: '#00ffff',
                    },
                ],
                capacitiveTexts: [
                    {
                        name: 'Sensor capacitivo 1',
                        color: '#3F7FBF',
                    },
                    {
                        name: 'Sensor capacitivo 2',
                        color: '#7F3FBF',
                    },
                ],
                noDataText: 'Não há dados!',
            },
            memory: {
                systemMemoryUsageText: 'Uso de memória do sistema',
                sensorsMemoryUsageText: 'Uso de memória dos sensores',
                systemMemoryUsageChartText:
                    'O gráfico indica a porcentagem do uso da memória destinada ao sistema.',
                sensorsMemoryUsageChartText:
                    'O gráfico indica a porcentagem do uso da memória destinada aos sensores.',
                availableText: 'Disponível',
                usedText: 'Ocupada',
                noDataText: 'Não há dados!',
            },
            systemLogs: {
                selectLogTimeText: 'Selecione o tempo do histórico',
                oneWeekText: '1 semana',
                oneMonthText: '1 mês',
                twoMonthsText: '2 meses',
                threeMonthsText: '3 meses',
                fourMonthText: '4 meses',
                serverConnectionHistoryText:
                    'Histórico de conexões com o servidor',
                eraseDeviceMemoryHistoryText: 'Histórico de limpeza de memória',
                fullChargeLogText: 'Histórico de cargas completas',
                batteryUsbStateLogText: 'Histórico de conexões e desconexões',
                powerDownLogText: 'Histórico de power down',
                bootLogText: 'Histórico de boot',
                errorsLogText: 'Histórico de erros',
                associationLogText: 'Histórico de associações',
                commandTypeText: 'Tipo de comando',
                dateTimeCommandSentText:
                    'Data e hora em que o comando foi enviado',
                requestCommandText: 'Requisição de comando',
                executionCommandText: 'Execução de comando',
                ths: [
                    'Data e horário da última conexão com o servidor',
                    'Data e horário da última conexão com o dispositivo',
                    'Quantidade de mensagens trocadas',
                    'Quantidade de mensagens trocadas em Kb',
                ],
                tds: [
                    {
                        th: 'Data e horário da última conexão com o servidor',
                        property: 'lastServerConnection',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Data e horário da última conexão com o dispositivo',
                        property: 'lastDeviceConnection',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Quantidade de mensagens trocadas',
                        property: 'payloadMsgs',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Quantidade de mensagens trocadas em Kb',
                        property: 'payloadKbs',
                        hasLink: false,
                        to: '',
                    },
                ],
                serverConnection: {
                    ths: [
                        'Data e horário da conexão com o servidor',
                        // 'Número de conexões',
                        'Quantidade de dados inseridos',
                    ],
                    tds: [
                        {
                            th: 'Data e horário da conexão com o servidor',
                            property: 'lastServerConnection',
                            hasLink: false,
                            to: '',
                        },
                        // {
                        //     th: 'Número de conexões',
                        //     property: 'numberOfConnections',
                        //     hasLink: false,
                        //     to: '',
                        // },
                        {
                            th: 'Quantidade de dados inseridos',
                            property: 'totalLogs',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                eraseDeviceMemory: {
                    ths: [
                        'Tipo de comando',
                        'Data e hora em que o comando foi enviado',
                    ],
                    tds: [
                        {
                            th: 'Tipo de comando',
                            property: 'type',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Data e hora em que o comando foi enviado',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                fullCharge: {
                    ths: ['Data e horário da carga completa'],
                    tds: [
                        {
                            th: 'Data e horário da carga completa',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                batteryUsbState: {
                    logTypeDescriptions: {
                        64: 'Bateria',
                        96: 'Conexão',
                        112: 'Desconexão',
                    },
                    ths: ['Data', 'Tensão da bateria', 'Evento'],
                    tds: [
                        {
                            th: 'Data',
                            property: 'formattedLogTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Tensão da bateria',
                            property: 'vBat',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Evento',
                            property: 'formattedLogType',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                powerDown: {
                    ths: [
                        'Data e horário de power down',
                        'Tensão da Bateria (V)',
                    ],
                    tds: [
                        {
                            th: 'Data e horário de power down',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Tensão da Bateria (V)',
                            property: 'vBat',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                boot: {
                    resetReasonsTexts: {
                        0: '',
                        1: 'pelo pino de reset',
                        2: 'pelo watchdog',
                        4: 'por software',
                        8: 'por travamento da CPU',
                        16: 'por sinal de wake detectado no GPIO',
                        32: 'pelo LPCOMP',
                        64: 'pelo dispositivo ter entrado em modo debug',
                        128: 'pelo NFC',
                    },
                    ths: ['Data e horário de boot', 'Razão de boot'],
                    tds: [
                        {
                            th: 'Data e horário de boot',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Razão de boot',
                            property: 'formattedBootCode',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                errors: {
                    errorsTexts: [
                        'POWER_MANAGEMENT',
                        'LIGHT_SENSOR_1',
                        'ACCEL',
                        'TEMP',
                        'VBAT',
                        'FLASH',
                        'BLE',
                        'GPIO',
                        'SDK_NORDIC',
                    ],
                    ths: [
                        'Data e horário do erro',
                        'Gerenciador de energia',
                        'Sensor de luz 1',
                        'Acelerômetro',
                        'Sensor de temperatura',
                        'Módulo de medição de tensão da bateria',
                        'Memória flash',
                        'BLE',
                        'GPIO',
                        'SDK',
                    ],
                    tds: [
                        {
                            th: 'Data e horário do erro',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Gerenciador de energia',
                            property: 'POWER_MANAGEMENT',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Sensor de luz 1',
                            property: 'LIGHT_SENSOR_1',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Acelerômetro',
                            property: 'ACCEL',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Sensor de temperatura',
                            property: 'TEMP',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Módulo de medição de tensão da bateria',
                            property: 'VBAT',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Memória flash',
                            property: 'FLASH',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'BLE',
                            property: 'BLE',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'GPIO',
                            property: 'GPIO',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'SDK',
                            property: 'SDK',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                association: {
                    ths: [
                        'Data de recebimento',
                        'Data de devolução',
                        'Usuário associado',
                    ],
                    tds: [
                        {
                            th: 'Data recebimento',
                            property: 'receivedAt',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Data de devolução',
                            property: 'returnedAt',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Usuário associado ',
                            property: 'lastUserId',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                noDataText: 'Não há dados!',
            },
            systemLogDataText: 'Log de sistema',
        },
        condor_admin: {
            updateText: 'Atualizar',
            notAssociatedText: 'Não associado',
            noDataText: 'Não há dados!',
            tabsText: {
                systemText: 'Sistema',
                sensorsText: 'Sensores',
                memoryText: 'Memória',
                systemLogsText: 'Históricos',
            },
            lastUpdateText: 'Última atualização: ',
            ths: [
                'Número de série',
                'Marca',
                'Modelo',
                'Empresa',
                'Data de aquisição',
                'Usuário associado',
                'Recebido em',
            ],
            tds: [
                {
                    th: 'Número de série',
                    property: 'serial_no',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Marca',
                    property: 'brand',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Modelo',
                    property: 'model',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Empresa',
                    property: 'company_name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Data de aquisição',
                    property: 'purchased_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Usuário associado',
                    property: 'user_name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Recebido em',
                    property: 'received_at',
                    hasLink: false,
                    to: '',
                },
            ],
            system: {
                batteryVoltageText: 'Tensão da bateria (V)',
                batteryVoltageChartText:
                    'O gráfico indica a tensão da bateria nos últimos 2 meses.',
                logTypeDescriptions: {
                    64: 'Bateria',
                    96: 'Conexão',
                    112: 'Desconexão',
                },
                hoursText: 'Horas',
                voltageText: 'Tensão (V)',
                connectionText: 'Conexão',
                disconnectionText: 'Desconexão',
                ths: ['Data', 'Tensão da bateria', 'Evento'],
                tds: [
                    {
                        th: 'Data',
                        property: 'formattedLogTime',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Tensão da bateria',
                        property: 'vBat',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Evento',
                        property: 'formattedLogType',
                        hasLink: false,
                        to: '',
                    },
                ],
                noDataText: 'Não há dados!',
                oneWeekText: '1 semana',
                oneMonthText: '1 mês',
                onDayText: '1 dia',
            },
            sensors: {
                actigraphyDataText: 'Dados de actigrafia',
                temperatureDataText: 'Dados de temperatura',
                lightIntensityDataText: 'Dados de intensidade luminosa',
                luxDataText: 'Dados de sensores de lux',
                capacitiveDataText: 'Dados de sensores capacitivos',
                actigraphyChartText:
                    'O gráfico indica os dados de actigrafia obtidos na última semana.',
                temperatureChartText:
                    'O gráfico indica a temperatura do dispositivo na última semana.',
                lightIntensityChartText:
                    'O gráfico indica os dados de intensidade luminosa obtidos na última semana.',
                luxChartText:
                    'O gráfico indica os dados de sensores de lux obtidos na última semana.',
                capacitiveChartText:
                    'O gráfico indica os dados de sensores capacitivos obtidos na última semana.',
                hoursText: 'Horas',
                temperatureText: 'Temperatura (°C)',
                actigraphyTexts: [
                    {
                        name: 'PIM',
                        color: '#7F3FBF',
                    },
                    {
                        name: 'TAT',
                        color: '#D00F0F',
                    },
                    {
                        name: 'ZCM',
                        color: '#23D00F',
                    },
                ],
                temperatureTexts: [
                    {
                        name: 'Temperatura',
                        color: '#1A33F4',
                    },
                ],
                lightsTexts: [
                    {
                        name: 'F1',
                        color: '#6A00FF',
                    },
                    {
                        name: 'F2',
                        color: '#003BFF',
                    },
                    {
                        name: 'F3',
                        color: '#00CDFF',
                    },
                    {
                        name: 'F4',
                        color: '#2DFF00',
                    },
                    {
                        name: 'F5',
                        color: '#B6FF00',
                    },
                    {
                        name: 'F6',
                        color: '#FFE600',
                    },
                    {
                        name: 'F7',
                        color: '#FF5F00',
                    },
                    {
                        name: 'F8',
                        color: '#FF0000',
                    },
                    {
                        name: 'Clear',
                        color: '#3F7FBF',
                    },
                    {
                        name: 'Nir',
                        color: '#BF3F3F',
                    },
                ],
                luxTexts: [
                    {
                        name: 'Lux photopic',
                        color: '#ffff00',
                    },
                    {
                        name: 'Lux melanopic',
                        color: '#00ffff',
                    },
                ],
                capacitiveTexts: [
                    {
                        name: 'Sensor capacitivo 1',
                        color: '#3F7FBF',
                    },
                    {
                        name: 'Sensor capacitivo 2',
                        color: '#7F3FBF',
                    },
                ],
                noDataText: 'Não há dados!',
            },
            memory: {
                systemMemoryUsageText: 'Uso de memória do sistema',
                sensorsMemoryUsageText: 'Uso de memória dos sensores',
                systemMemoryUsageChartText:
                    'O gráfico indica a porcentagem do uso da memória destinada ao sistema.',
                sensorsMemoryUsageChartText:
                    'O gráfico indica a porcentagem do uso da memória destinada aos sensores.',
                availableText: 'Disponível',
                usedText: 'Ocupada',
                noDataText: 'Não há dados!',
            },
            systemLogs: {
                selectLogTimeText: 'Selecione o tempo do histórico',
                oneWeekText: '1 semana',
                oneMonthText: '1 mês',
                twoMonthsText: '2 meses',
                threeMonthsText: '3 meses',
                fourMonthText: '4 meses',
                serverConnectionHistoryText:
                    'Histórico de conexões com o servidor',
                eraseDeviceMemoryHistoryText: 'Histórico de limpeza de memória',
                fullChargeLogText: 'Histórico de cargas completas',
                batteryUsbStateLogText: 'Histórico de conexões e desconexões',
                powerDownLogText: 'Histórico de power down',
                bootLogText: 'Histórico de boot',
                errorsLogText: 'Histórico de erros',
                associationLogText: 'Histórico de associações',
                commandTypeText: 'Tipo de comando',
                dateTimeCommandSentText:
                    'Data e hora em que o comando foi enviado',
                requestCommandText: 'Requisição de comando',
                executionCommandText: 'Execução de comando',
                ths: [
                    'Data e horário da última conexão com o servidor',
                    'Data e horário da última conexão com o dispositivo',
                    'Quantidade de mensagens trocadas',
                    'Quantidade de mensagens trocadas em Kb',
                ],
                tds: [
                    {
                        th: 'Data e horário da última conexão com o servidor',
                        property: 'lastServerConnection',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Data e horário da última conexão com o dispositivo',
                        property: 'lastDeviceConnection',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Quantidade de mensagens trocadas',
                        property: 'payloadMsgs',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Quantidade de mensagens trocadas em Kb',
                        property: 'payloadKbs',
                        hasLink: false,
                        to: '',
                    },
                ],
                serverConnection: {
                    ths: [
                        'Data e horário da conexão com o servidor',
                        // 'Número de conexões',
                        'Quantidade de dados inseridos',
                    ],
                    tds: [
                        {
                            th: 'Data e horário da conexão com o servidor',
                            property: 'lastServerConnection',
                            hasLink: false,
                            to: '',
                        },
                        // {
                        //     th: 'Número de conexões',
                        //     property: 'numberOfConnections',
                        //     hasLink: false,
                        //     to: '',
                        // },
                        {
                            th: 'Quantidade de dados inseridos',
                            property: 'totalLogs',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                eraseDeviceMemory: {
                    ths: [
                        'Tipo de comando',
                        'Data e hora em que o comando foi enviado',
                    ],
                    tds: [
                        {
                            th: 'Tipo de comando',
                            property: 'type',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Data e hora em que o comando foi enviado',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                fullCharge: {
                    ths: ['Data e horário da carga completa'],
                    tds: [
                        {
                            th: 'Data e horário da carga completa',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                batteryUsbState: {
                    logTypeDescriptions: {
                        64: 'Bateria',
                        96: 'Conexão',
                        112: 'Desconexão',
                    },
                    ths: ['Data', 'Tensão da bateria', 'Evento'],
                    tds: [
                        {
                            th: 'Data',
                            property: 'formattedLogTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Tensão da bateria',
                            property: 'vBat',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Evento',
                            property: 'formattedLogType',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                powerDown: {
                    ths: [
                        'Data e horário de power down',
                        'Tensão da Bateria (V)',
                    ],
                    tds: [
                        {
                            th: 'Data e horário de power down',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Tensão da Bateria (V)',
                            property: 'vBat',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                boot: {
                    resetReasonsTexts: {
                        0: '',
                        1: 'pelo pino de reset',
                        2: 'pelo watchdog',
                        4: 'por software',
                        8: 'por travamento da CPU',
                        16: 'por sinal de wake detectado no GPIO',
                        32: 'pelo LPCOMP',
                        64: 'pelo dispositivo ter entrado em modo debug',
                        128: 'pelo NFC',
                    },
                    ths: ['Data e horário de boot', 'Razão de boot'],
                    tds: [
                        {
                            th: 'Data e horário de boot',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Razão de boot',
                            property: 'formattedBootCode',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                errors: {
                    errorsTexts: [
                        'POWER_MANAGEMENT',
                        'LIGHT_SENSOR_1',
                        'ACCEL',
                        'TEMP',
                        'VBAT',
                        'FLASH',
                        'BLE',
                        'GPIO',
                        'SDK_NORDIC',
                    ],
                    ths: [
                        'Data e horário do erro',
                        'Gerenciador de energia',
                        'Sensor de luz 1',
                        'Acelerômetro',
                        'Sensor de temperatura',
                        'Módulo de medição de tensão da bateria',
                        'Memória flash',
                        'BLE',
                        'GPIO',
                        'SDK',
                    ],
                    tds: [
                        {
                            th: 'Data e horário do erro',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Gerenciador de energia',
                            property: 'POWER_MANAGEMENT',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Sensor de luz 1',
                            property: 'LIGHT_SENSOR_1',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Acelerômetro',
                            property: 'ACCEL',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Sensor de temperatura',
                            property: 'TEMP',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Módulo de medição de tensão da bateria',
                            property: 'VBAT',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Memória flash',
                            property: 'FLASH',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'BLE',
                            property: 'BLE',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'GPIO',
                            property: 'GPIO',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'SDK',
                            property: 'SDK',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                association: {
                    ths: [
                        'Data de recebimento',
                        'Data de devolução',
                        'Usuário associado',
                    ],
                    tds: [
                        {
                            th: 'Data recebimento',
                            property: 'receivedAt',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Data de devolução',
                            property: 'returnedAt',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Usuário associado ',
                            property: 'lastUserId',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                noDataText: 'Não há dados!',
            },
            systemLogDataText: 'Log de sistema',
        },
    },
    en: {
        customer_admin: {
            updateText: 'Update',
            notAssociatedText: 'Not associated',
            noDataText: 'There is no data!',
            tabsText: {
                systemText: 'System',
                sensorsText: 'Sensors',
                memoryText: 'Memory',
                systemLogsText: 'System logs',
            },
            lastUpdateText: 'Last update: ',
            ths: [
                'Serial number',
                'Brand',
                'Model',
                'Purchased at',
                'Associated user',
                'Received at',
            ],
            tds: [
                {
                    th: 'Serial number',
                    property: 'serial_no',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Brand',
                    property: 'brand',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Model',
                    property: 'model',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Purchased at',
                    property: 'purchased_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Associated user',
                    property: 'user_name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Received at',
                    property: 'received_at',
                    hasLink: false,
                    to: '',
                },
            ],
            system: {
                batteryVoltageText: 'Battery voltage (V)',
                batteryVoltageChartText:
                    'The graph indicates the battery voltage in the last 2 months.',
                logTypeDescriptions: {
                    64: 'Battery',
                    96: 'Connection',
                    112: 'Disconnection',
                },
                hoursText: 'Hours',
                voltageText: 'Voltage (V)',
                connectionText: 'Connection',
                disconnectionText: 'Disconnection',
                ths: ['Date', 'Battery voltage', 'Event'],
                tds: [
                    {
                        th: 'Date',
                        property: 'formattedLogTime',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Battery voltage',
                        property: 'vBat',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Event',
                        property: 'formattedLogType',
                        hasLink: false,
                        to: '',
                    },
                ],
                noDataText: 'There is no data!',
                oneWeekText: '1 week',
                oneMonthText: '1 month',
                onDayText: '1 day',
            },
            sensors: {
                actigraphyDataText: 'Actigraphy data',
                temperatureDataText: 'Temperature data',
                lightIntensityDataText: 'Light intensity data',
                luxDataText: 'Lux sensors data',
                capacitiveDataText: 'Capacitive sensors data',
                actigraphyChartText:
                    'The graph indicates the actigraphy data obtained in the last week.',
                temperatureChartText:
                    'The graph indicates the device temperature in the last week.',
                lightIntensityChartText:
                    'The graph indicates the light intensity data obtained in the last week.',
                luxChartText:
                    'The graph indicates the lux sensors data obtained in the last week.',
                capacitiveChartText:
                    'The graph indicates the capacitive sensors data obtained in the last week.',
                hoursText: 'Hours',
                temperatureText: 'Temperature (°C)',
                actigraphyTexts: [
                    {
                        name: 'PIM',
                        color: '#7F3FBF',
                    },
                    {
                        name: 'TAT',
                        color: '#D00F0F',
                    },
                    {
                        name: 'ZCM',
                        color: '#23D00F',
                    },
                ],
                temperatureTexts: [
                    {
                        name: 'Temperature',
                        color: '#1A33F4',
                    },
                ],
                lightsTexts: [
                    {
                        name: 'F1',
                        color: '#6A00FF',
                    },
                    {
                        name: 'F2',
                        color: '#003BFF',
                    },
                    {
                        name: 'F3',
                        color: '#00CDFF',
                    },
                    {
                        name: 'F4',
                        color: '#2DFF00',
                    },
                    {
                        name: 'F5',
                        color: '#B6FF00',
                    },
                    {
                        name: 'F6',
                        color: '#FFE600',
                    },
                    {
                        name: 'F7',
                        color: '#FF5F00',
                    },
                    {
                        name: 'F8',
                        color: '#FF0000',
                    },
                    {
                        name: 'Clear',
                        color: '#3F7FBF',
                    },
                    {
                        name: 'Nir',
                        color: '#BF3F3F',
                    },
                ],
                luxTexts: [
                    {
                        name: 'Lux photopic',
                        color: '#ffff00',
                    },
                    {
                        name: 'Lux melanopic',
                        color: '#00ffff',
                    },
                ],
                capacitiveTexts: [
                    {
                        name: 'Capacitive sensor 1',
                        color: '#3F7FBF',
                    },
                    {
                        name: 'Capacitive sensor 2',
                        color: '#7F3FBF',
                    },
                ],
                noDataText: 'There is no data!',
            },
            memory: {
                systemMemoryUsageText: 'System memory usage',
                sensorsMemoryUsageText: 'Sensors memory usage',
                systemMemoryUsageChartText:
                    'The graph indicates the percentage of memory usage allocated to the system.',
                sensorsMemoryUsageChartText:
                    'The graph indicates the percentage of memory usage allocated to the sensors.',
                availableText: 'Available',
                usedText: 'Used',
                noDataText: 'There is no data!',
            },
            systemLogs: {
                selectLogTimeText: 'Select history time',
                oneWeekText: '1 week',
                oneMonthText: '1 month',
                twoMonthsText: '2 months',
                threeMonthsText: '3 months',
                fourMonthText: '4 months',
                serverConnectionHistoryText: 'Server connection history',
                eraseDeviceMemoryHistoryText: 'Memory erase history',
                fullChargeLogText: 'Full charge history',
                batteryUsbStateLogText: 'Connection and disconnection history',
                powerDownLogText: 'Power down history',
                bootLogText: 'Boot history',
                errorsLogText: 'Errors history',
                associationLogText: 'Association history',
                commandTypeText: 'Type of command',
                dateTimeCommandSentText: 'Date and time command was sent',
                requestCommandText: 'Command request',
                executionCommandText: 'Command execution',
                ths: [
                    'Date and time of last connection to the server',
                    'Date and time of last connection to the device',
                    'Number of messages exchanged',
                    'Number of messages exchanged in Kb',
                ],
                tds: [
                    {
                        th: 'Date and time of last connection to the server',
                        property: 'lastServerConnection',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Date and time of last connection to the device',
                        property: 'lastDeviceConnection',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Number of messages exchanged',
                        property: 'payloadMsgs',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Number of messages exchanged in Kb',
                        property: 'payloadKbs',
                        hasLink: false,
                        to: '',
                    },
                ],
                serverConnection: {
                    ths: [
                        'Date and time of last connection to the server',
                        // 'Number of connections',
                        'Number of data inserted',
                    ],
                    tds: [
                        {
                            th: 'Date and time of last connection to the server',
                            property: 'lastServerConnection',
                            hasLink: false,
                            to: '',
                        },
                        // {
                        //     th: 'Number of connections',
                        //     property: 'numberOfConnections',
                        //     hasLink: false,
                        //     to: '',
                        // },
                        {
                            th: 'Number of data inserted',
                            property: 'totalLogs',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                eraseDeviceMemory: {
                    ths: ['Type of command', 'Date and time command was sent'],
                    tds: [
                        {
                            th: 'Type of command',
                            property: 'type',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Date and time command was sent',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                fullCharge: {
                    ths: ['Full charge date and time'],
                    tds: [
                        {
                            th: 'Full charge date and time',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                batteryUsbState: {
                    logTypeDescriptions: {
                        64: 'Battery',
                        96: 'Connection',
                        112: 'Disconnection',
                    },
                    ths: ['Date', 'Battery voltage', 'Event'],
                    tds: [
                        {
                            th: 'Date',
                            property: 'formattedLogTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Battery voltage',
                            property: 'vBat',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Event',
                            property: 'formattedLogType',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                powerDown: {
                    ths: ['Power down date and time', 'Battery voltage (V)'],
                    tds: [
                        {
                            th: 'Power down date and time',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Battery voltage (V)',
                            property: 'vBat',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                boot: {
                    resetReasonsTexts: {
                        0: '',
                        1: 'by the reset pin',
                        2: 'by watchdog',
                        4: 'by software',
                        8: 'due to cpu crash',
                        16: 'by wake signal detected on GPIO',
                        32: 'by LPCOMP',
                        64: 'by device having entered debug mode',
                        128: 'by NFC',
                    },
                    ths: ['Boot date and time', 'Boot reason'],
                    tds: [
                        {
                            th: 'Boot date and time',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Boot reason',
                            property: 'formattedBootCode',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                errors: {
                    errorsTexts: [
                        'POWER_MANAGEMENT',
                        'LIGHT_SENSOR_1',
                        'ACCEL',
                        'TEMP',
                        'VBAT',
                        'FLASH',
                        'BLE',
                        'GPIO',
                        'SDK_NORDIC',
                    ],
                    ths: [
                        'Error date and time',
                        'Energy manager',
                        'Light sensor 1',
                        'Accelerometer',
                        'Temperature sensor',
                        'Battery voltage measurement module',
                        'Flash memory',
                        'BLE',
                        'GPIO',
                        'SDK',
                    ],
                    tds: [
                        {
                            th: 'Error date and time',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Energy manager',
                            property: 'POWER_MANAGEMENT',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Light sensor 1',
                            property: 'LIGHT_SENSOR_1',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Accelerometer',
                            property: 'ACCEL',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Temperature sensor',
                            property: 'TEMP',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Battery voltage measurement module',
                            property: 'VBAT',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Flash memory',
                            property: 'FLASH',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'BLE',
                            property: 'BLE',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'GPIO',
                            property: 'GPIO',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'SDK',
                            property: 'SDK',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                association: {
                    ths: ['Received at', 'Returned at', 'Associated user'],
                    tds: [
                        {
                            th: 'Received at',
                            property: 'receivedAt',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Returned at',
                            property: 'returnedAt',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Associated user',
                            property: 'lastUserId',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                noDataText: 'There is no data!',
            },
            systemLogDataText: 'System log',
        },
        manager: {
            updateText: 'Update',
            notAssociatedText: 'Not associated',
            noDataText: 'There is no data!',
            tabsText: {
                systemText: 'System',
                sensorsText: 'Sensors',
                memoryText: 'Memory',
                systemLogsText: 'System logs',
            },
            lastUpdateText: 'Last update: ',
            ths: [
                'Serial number',
                'Brand',
                'Model',
                'Purchased at',
                'Associated user',
                'Received at',
            ],
            tds: [
                {
                    th: 'Serial number',
                    property: 'serial_no',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Brand',
                    property: 'brand',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Model',
                    property: 'model',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Purchased at',
                    property: 'purchased_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Associated user',
                    property: 'user_name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Received at',
                    property: 'received_at',
                    hasLink: false,
                    to: '',
                },
            ],
            system: {
                batteryVoltageText: 'Battery voltage (V)',
                batteryVoltageChartText:
                    'The graph indicates the battery voltage in the last 2 months.',
                logTypeDescriptions: {
                    64: 'Battery',
                    96: 'Connection',
                    112: 'Disconnection',
                },
                hoursText: 'Hours',
                voltageText: 'Voltage (V)',
                connectionText: 'Connection',
                disconnectionText: 'Disconnection',
                ths: ['Date', 'Battery voltage', 'Event'],
                tds: [
                    {
                        th: 'Date',
                        property: 'formattedLogTime',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Battery voltage',
                        property: 'vBat',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Event',
                        property: 'formattedLogType',
                        hasLink: false,
                        to: '',
                    },
                ],
                noDataText: 'There is no data!',
                oneWeekText: '1 week',
                oneMonthText: '1 month',
                onDayText: '1 day',
            },
            sensors: {
                actigraphyDataText: 'Actigraphy data',
                temperatureDataText: 'Temperature data',
                lightIntensityDataText: 'Light intensity data',
                luxDataText: 'Lux sensors data',
                capacitiveDataText: 'Capacitive sensors data',
                actigraphyChartText:
                    'The graph indicates the actigraphy data obtained in the last week.',
                temperatureChartText:
                    'The graph indicates the device temperature in the last week.',
                lightIntensityChartText:
                    'The graph indicates the light intensity data obtained in the last week.',
                luxChartText:
                    'The graph indicates the lux sensors data obtained in the last week.',
                capacitiveChartText:
                    'The graph indicates the capacitive sensors data obtained in the last week.',
                hoursText: 'Hours',
                temperatureText: 'Temperature (°C)',
                actigraphyTexts: [
                    {
                        name: 'PIM',
                        color: '#7F3FBF',
                    },
                    {
                        name: 'TAT',
                        color: '#D00F0F',
                    },
                    {
                        name: 'ZCM',
                        color: '#23D00F',
                    },
                ],
                temperatureTexts: [
                    {
                        name: 'Temperature',
                        color: '#1A33F4',
                    },
                ],
                lightsTexts: [
                    {
                        name: 'F1',
                        color: '#6A00FF',
                    },
                    {
                        name: 'F2',
                        color: '#003BFF',
                    },
                    {
                        name: 'F3',
                        color: '#00CDFF',
                    },
                    {
                        name: 'F4',
                        color: '#2DFF00',
                    },
                    {
                        name: 'F5',
                        color: '#B6FF00',
                    },
                    {
                        name: 'F6',
                        color: '#FFE600',
                    },
                    {
                        name: 'F7',
                        color: '#FF5F00',
                    },
                    {
                        name: 'F8',
                        color: '#FF0000',
                    },
                    {
                        name: 'Clear',
                        color: '#3F7FBF',
                    },
                    {
                        name: 'Nir',
                        color: '#BF3F3F',
                    },
                ],
                luxTexts: [
                    {
                        name: 'Lux photopic',
                        color: '#ffff00',
                    },
                    {
                        name: 'Lux melanopic',
                        color: '#00ffff',
                    },
                ],
                capacitiveTexts: [
                    {
                        name: 'Capacitive sensor 1',
                        color: '#3F7FBF',
                    },
                    {
                        name: 'Capacitive sensor 2',
                        color: '#7F3FBF',
                    },
                ],
                noDataText: 'There is no data!',
            },
            memory: {
                systemMemoryUsageText: 'System memory usage',
                sensorsMemoryUsageText: 'Sensors memory usage',
                systemMemoryUsageChartText:
                    'The graph indicates the percentage of memory usage allocated to the system.',
                sensorsMemoryUsageChartText:
                    'The graph indicates the percentage of memory usage allocated to the sensors.',
                availableText: 'Available',
                usedText: 'Used',
                noDataText: 'There is no data!',
            },
            systemLogs: {
                selectLogTimeText: 'Select history time',
                oneWeekText: '1 week',
                oneMonthText: '1 month',
                twoMonthsText: '2 months',
                threeMonthsText: '3 months',
                fourMonthText: '4 months',
                serverConnectionHistoryText: 'Server connection history',
                eraseDeviceMemoryHistoryText: 'Memory erase history',
                fullChargeLogText: 'Full charge history',
                batteryUsbStateLogText: 'Connection and disconnection history',
                powerDownLogText: 'Power down history',
                bootLogText: 'Boot history',
                errorsLogText: 'Errors history',
                associationLogText: 'Association history',
                commandTypeText: 'Type of command',
                dateTimeCommandSentText: 'Date and time command was sent',
                requestCommandText: 'Command request',
                executionCommandText: 'Command execution',
                ths: [
                    'Date and time of last connection to the server',
                    'Date and time of last connection to the device',
                    'Number of messages exchanged',
                    'Number of messages exchanged in Kb',
                ],
                tds: [
                    {
                        th: 'Date and time of last connection to the server',
                        property: 'lastServerConnection',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Date and time of last connection to the device',
                        property: 'lastDeviceConnection',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Number of messages exchanged',
                        property: 'payloadMsgs',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Number of messages exchanged in Kb',
                        property: 'payloadKbs',
                        hasLink: false,
                        to: '',
                    },
                ],
                serverConnection: {
                    ths: [
                        'Date and time of last connection to the server',
                        // 'Number of connections',
                        'Number of data inserted',
                    ],
                    tds: [
                        {
                            th: 'Date and time of last connection to the server',
                            property: 'lastServerConnection',
                            hasLink: false,
                            to: '',
                        },
                        // {
                        //     th: 'Number of connections',
                        //     property: 'numberOfConnections',
                        //     hasLink: false,
                        //     to: '',
                        // },
                        {
                            th: 'Number of data inserted',
                            property: 'totalLogs',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                eraseDeviceMemory: {
                    ths: ['Type of command', 'Date and time command was sent'],
                    tds: [
                        {
                            th: 'Type of command',
                            property: 'type',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Date and time command was sent',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                fullCharge: {
                    ths: ['Full charge date and time'],
                    tds: [
                        {
                            th: 'Full charge date and time',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                batteryUsbState: {
                    logTypeDescriptions: {
                        64: 'Battery',
                        96: 'Connection',
                        112: 'Disconnection',
                    },
                    ths: ['Date', 'Battery voltage', 'Event'],
                    tds: [
                        {
                            th: 'Date',
                            property: 'formattedLogTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Battery voltage',
                            property: 'vBat',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Event',
                            property: 'formattedLogType',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                powerDown: {
                    ths: ['Power down date and time', 'Battery voltage (V)'],
                    tds: [
                        {
                            th: 'Power down date and time',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Battery voltage (V)',
                            property: 'vBat',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                boot: {
                    resetReasonsTexts: {
                        0: '',
                        1: 'by the reset pin',
                        2: 'by watchdog',
                        4: 'by software',
                        8: 'due to cpu crash',
                        16: 'by wake signal detected on GPIO',
                        32: 'by LPCOMP',
                        64: 'by device having entered debug mode',
                        128: 'by NFC',
                    },
                    ths: ['Boot date and time', 'Boot reason'],
                    tds: [
                        {
                            th: 'Boot date and time',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Boot reason',
                            property: 'formattedBootCode',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                errors: {
                    errorsTexts: [
                        'POWER_MANAGEMENT',
                        'LIGHT_SENSOR_1',
                        'ACCEL',
                        'TEMP',
                        'VBAT',
                        'FLASH',
                        'BLE',
                        'GPIO',
                        'SDK_NORDIC',
                    ],
                    ths: [
                        'Error date and time',
                        'Energy manager',
                        'Light sensor 1',
                        'Accelerometer',
                        'Temperature sensor',
                        'Battery voltage measurement module',
                        'Flash memory',
                        'BLE',
                        'GPIO',
                        'SDK',
                    ],
                    tds: [
                        {
                            th: 'Error date and time',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Energy manager',
                            property: 'POWER_MANAGEMENT',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Light sensor 1',
                            property: 'LIGHT_SENSOR_1',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Accelerometer',
                            property: 'ACCEL',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Temperature sensor',
                            property: 'TEMP',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Battery voltage measurement module',
                            property: 'VBAT',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Flash memory',
                            property: 'FLASH',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'BLE',
                            property: 'BLE',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'GPIO',
                            property: 'GPIO',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'SDK',
                            property: 'SDK',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                association: {
                    ths: ['Received at', 'Returned at', 'Associated user'],
                    tds: [
                        {
                            th: 'Received at',
                            property: 'receivedAt',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Returned at',
                            property: 'returnedAt',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Associated user',
                            property: 'lastUserId',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                noDataText: 'There is no data!',
            },
            systemLogDataText: 'System log',
        },
        condor_admin: {
            updateText: 'Update',
            notAssociatedText: 'Not associated',
            noDataText: 'There is no data!',
            tabsText: {
                systemText: 'System',
                sensorsText: 'Sensors',
                memoryText: 'Memory',
                systemLogsText: 'System logs',
            },
            lastUpdateText: 'Last update: ',
            ths: [
                'Serial number',
                'Brand',
                'Model',
                'Company',
                'Purchased at',
                'Associated user',
                'Received at',
            ],
            tds: [
                {
                    th: 'Serial number',
                    property: 'serial_no',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Brand',
                    property: 'brand',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Model',
                    property: 'model',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Company',
                    property: 'company_name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Purchased at',
                    property: 'purchased_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Associated user',
                    property: 'user_name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Received at',
                    property: 'received_at',
                    hasLink: false,
                    to: '',
                },
            ],
            system: {
                batteryVoltageText: 'Battery voltage (V)',
                batteryVoltageChartText:
                    'The graph indicates the battery voltage in the last 2 months.',
                logTypeDescriptions: {
                    64: 'Battery',
                    96: 'Connection',
                    112: 'Disconnection',
                },
                hoursText: 'Hours',
                voltageText: 'Voltage (V)',
                connectionText: 'Connection',
                disconnectionText: 'Disconnection',
                ths: ['Date', 'Battery voltage', 'Event'],
                tds: [
                    {
                        th: 'Date',
                        property: 'formattedLogTime',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Battery voltage',
                        property: 'vBat',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Event',
                        property: 'formattedLogType',
                        hasLink: false,
                        to: '',
                    },
                ],
                noDataText: 'There is no data!',
                oneWeekText: '1 week',
                oneMonthText: '1 month',
                onDayText: '1 day',
            },
            sensors: {
                actigraphyDataText: 'Actigraphy data',
                temperatureDataText: 'Temperature data',
                lightIntensityDataText: 'Light intensity data',
                luxDataText: 'Lux sensors data',
                capacitiveDataText: 'Capacitive sensors data',
                actigraphyChartText:
                    'The graph indicates the actigraphy data obtained in the last week.',
                temperatureChartText:
                    'The graph indicates the device temperature in the last week.',
                lightIntensityChartText:
                    'The graph indicates the light intensity data obtained in the last week.',
                luxChartText:
                    'The graph indicates the lux sensors data obtained in the last week.',
                capacitiveChartText:
                    'The graph indicates the capacitive sensors data obtained in the last week.',
                hoursText: 'Hours',
                temperatureText: 'Temperature (°C)',
                actigraphyTexts: [
                    {
                        name: 'PIM',
                        color: '#7F3FBF',
                    },
                    {
                        name: 'TAT',
                        color: '#D00F0F',
                    },
                    {
                        name: 'ZCM',
                        color: '#23D00F',
                    },
                ],
                temperatureTexts: [
                    {
                        name: 'Temperature',
                        color: '#1A33F4',
                    },
                ],
                lightsTexts: [
                    {
                        name: 'F1',
                        color: '#6A00FF',
                    },
                    {
                        name: 'F2',
                        color: '#003BFF',
                    },
                    {
                        name: 'F3',
                        color: '#00CDFF',
                    },
                    {
                        name: 'F4',
                        color: '#2DFF00',
                    },
                    {
                        name: 'F5',
                        color: '#B6FF00',
                    },
                    {
                        name: 'F6',
                        color: '#FFE600',
                    },
                    {
                        name: 'F7',
                        color: '#FF5F00',
                    },
                    {
                        name: 'F8',
                        color: '#FF0000',
                    },
                    {
                        name: 'Clear',
                        color: '#3F7FBF',
                    },
                    {
                        name: 'Nir',
                        color: '#BF3F3F',
                    },
                ],
                luxTexts: [
                    {
                        name: 'Lux photopic',
                        color: '#ffff00',
                    },
                    {
                        name: 'Lux melanopic',
                        color: '#00ffff',
                    },
                ],
                capacitiveTexts: [
                    {
                        name: 'Capacitive sensor 1',
                        color: '#3F7FBF',
                    },
                    {
                        name: 'Capacitive sensor 2',
                        color: '#7F3FBF',
                    },
                ],
                noDataText: 'There is no data!',
            },
            memory: {
                systemMemoryUsageText: 'System memory usage',
                sensorsMemoryUsageText: 'Sensors memory usage',
                systemMemoryUsageChartText:
                    'The graph indicates the percentage of memory usage allocated to the system.',
                sensorsMemoryUsageChartText:
                    'The graph indicates the percentage of memory usage allocated to the sensors.',
                availableText: 'Available',
                usedText: 'Used',
                noDataText: 'There is no data!',
            },
            systemLogs: {
                selectLogTimeText: 'Select history time',
                oneWeekText: '1 week',
                oneMonthText: '1 month',
                twoMonthsText: '2 months',
                threeMonthsText: '3 months',
                fourMonthText: '4 months',
                serverConnectionHistoryText: 'Server connection history',
                eraseDeviceMemoryHistoryText: 'Memory erase history',
                fullChargeLogText: 'Full charge history',
                batteryUsbStateLogText: 'Connection and disconnection history',
                powerDownLogText: 'Power down history',
                bootLogText: 'Boot history',
                errorsLogText: 'Errors history',
                associationLogText: 'Association history',
                commandTypeText: 'Type of command',
                dateTimeCommandSentText: 'Date and time command was sent',
                requestCommandText: 'Command request',
                executionCommandText: 'Command execution',
                ths: [
                    'Date and time of last connection to the server',
                    'Date and time of last connection to the device',
                    'Number of messages exchanged',
                    'Number of messages exchanged in Kb',
                ],
                tds: [
                    {
                        th: 'Date and time of last connection to the server',
                        property: 'lastServerConnection',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Date and time of last connection to the device',
                        property: 'lastDeviceConnection',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Number of messages exchanged',
                        property: 'payloadMsgs',
                        hasLink: false,
                        to: '',
                    },
                    {
                        th: 'Number of messages exchanged in Kb',
                        property: 'payloadKbs',
                        hasLink: false,
                        to: '',
                    },
                ],
                serverConnection: {
                    ths: [
                        'Date and time of last connection to the server',
                        // 'Number of connections',
                        'Number of data inserted',
                    ],
                    tds: [
                        {
                            th: 'Date and time of last connection to the server',
                            property: 'lastServerConnection',
                            hasLink: false,
                            to: '',
                        },
                        // {
                        //     th: 'Number of connections',
                        //     property: 'numberOfConnections',
                        //     hasLink: false,
                        //     to: '',
                        // },
                        {
                            th: 'Number of data inserted',
                            property: 'totalLogs',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                eraseDeviceMemory: {
                    ths: ['Type of command', 'Date and time command was sent'],
                    tds: [
                        {
                            th: 'Type of command',
                            property: 'type',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Date and time command was sent',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                fullCharge: {
                    ths: ['Full charge date and time'],
                    tds: [
                        {
                            th: 'Full charge date and time',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                batteryUsbState: {
                    logTypeDescriptions: {
                        64: 'Battery',
                        96: 'Connection',
                        112: 'Disconnection',
                    },
                    ths: ['Date', 'Battery voltage', 'Event'],
                    tds: [
                        {
                            th: 'Date',
                            property: 'formattedLogTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Battery voltage',
                            property: 'vBat',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Event',
                            property: 'formattedLogType',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                powerDown: {
                    ths: ['Power down date and time', 'Battery voltage (V)'],
                    tds: [
                        {
                            th: 'Power down date and time',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Battery voltage (V)',
                            property: 'vBat',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                boot: {
                    resetReasonsTexts: {
                        0: '',
                        1: 'by the reset pin',
                        2: 'by watchdog',
                        4: 'by software',
                        8: 'due to cpu crash',
                        16: 'by wake signal detected on GPIO',
                        32: 'by LPCOMP',
                        64: 'by device having entered debug mode',
                        128: 'by NFC',
                    },
                    ths: ['Boot date and time', 'Boot reason'],
                    tds: [
                        {
                            th: 'Boot date and time',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Boot reason',
                            property: 'formattedBootCode',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                errors: {
                    errorsTexts: [
                        'POWER_MANAGEMENT',
                        'LIGHT_SENSOR_1',
                        'ACCEL',
                        'TEMP',
                        'VBAT',
                        'FLASH',
                        'BLE',
                        'GPIO',
                        'SDK_NORDIC',
                    ],
                    ths: [
                        'Error date and time',
                        'Energy manager',
                        'Light sensor 1',
                        'Accelerometer',
                        'Temperature sensor',
                        'Battery voltage measurement module',
                        'Flash memory',
                        'BLE',
                        'GPIO',
                        'SDK',
                    ],
                    tds: [
                        {
                            th: 'Error date and time',
                            property: 'logTime',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Energy manager',
                            property: 'POWER_MANAGEMENT',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Light sensor 1',
                            property: 'LIGHT_SENSOR_1',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Accelerometer',
                            property: 'ACCEL',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Temperature sensor',
                            property: 'TEMP',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Battery voltage measurement module',
                            property: 'VBAT',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Flash memory',
                            property: 'FLASH',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'BLE',
                            property: 'BLE',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'GPIO',
                            property: 'GPIO',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'SDK',
                            property: 'SDK',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                association: {
                    ths: ['Received at', 'Returned at', 'Associated user'],
                    tds: [
                        {
                            th: 'Received at',
                            property: 'receivedAt',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Returned at',
                            property: 'returnedAt',
                            hasLink: false,
                            to: '',
                        },
                        {
                            th: 'Associated user',
                            property: 'lastUserId',
                            hasLink: false,
                            to: '',
                        },
                    ],
                },
                noDataText: 'There is no data!',
            },
            systemLogDataText: 'System log',
        },
    },
};

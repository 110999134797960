import React, { useState, useEffect, useRef } from 'react';
import { Col } from 'reactstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import PropTypes from 'prop-types';

Calendar.propTypes = {
    data: PropTypes.object.isRequired,
    textData: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
};

export function Calendar({ data, textData, lang }) {
    const calendarRef = useRef();

    const [events, setEvents] = useState([]);

    const { filledDays, totalDays } = data;

    const {
        cardTitle,
        calendarLocale,
        filledText,
        notFilledText,
        filledColor,
        notFilledColor,
    } = textData;

    useEffect(() => {
        (function () {
            let e = [];

            for (let day of totalDays) {
                if (filledDays.includes(day)) {
                    e.push({
                        title: filledText,
                        start: day,
                        display: 'background',
                        backgroundColor: filledColor,
                    });
                } else {
                    e.push({
                        title: notFilledText,
                        start: day,
                        display: 'background',
                        backgroundColor: notFilledColor,
                    });
                }
            }

            setEvents(e);
        })();
    }, [lang]);

    if (filledDays.length === 0 && totalDays.length === 0) {
        return <></>;
    }

    return (
        <Col xs="12">
            <Card className="cardShadow">
                <CardHeader className="cardHeader text-center">
                    <CardTitle className="cardTitle">
                        <h6
                            className="cardHeaderSizeLarge"
                            data-cy="user_report_calendar_card_title"
                        >
                            {cardTitle}
                        </h6>
                    </CardTitle>
                </CardHeader>
                <CardBody data-cy="user_report_calendar_card_body">
                    <FullCalendar
                        ref={calendarRef}
                        plugins={[dayGridPlugin]}
                        initialView="dayGridMonth"
                        events={events}
                        headerToolbar={{
                            left: 'prev next today',
                            center: 'title',
                            right: 'dayGridMonth',
                        }}
                        locale={calendarLocale}
                    />
                </CardBody>
            </Card>
        </Col>
    );
}

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Collapse from './collapse';
import { AccordionContext } from '../../contexts/accordionContext';

export function AccordionBody({
    className,
    innerRef,
    children,
    accordionId,
    ...attributes
}) {
    const { open } = useContext(AccordionContext);

    return (
        <Collapse
            {...attributes}
            className={`${className} accordion-collapse`}
            ref={innerRef}
            isOpen={
                Array.isArray(open)
                    ? open.includes(accordionId)
                    : open === accordionId
            }
        >
            <div className="accordion-body">{children}</div>
        </Collapse>
    );
}

AccordionBody.propTypes = {
    className: PropTypes.string,
    innerRef: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.func,
    ]),
    children: PropTypes.node,
    accordionId: PropTypes.string.isRequired,
};

import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import Input from '../../../../components/inputForm/inputText';
import Select from '../../../../components/inputForm/inputSelect';
import { setLoading } from '../../../../store/actions/loadingActions';
import { validateForm } from '../../../../helpers/formValidator';
import { sendUserCredentials } from '../../../../services/api/users';
import history from '../../../../services/history';

MyForm.propTypes = {
    user: PropTypes.object.isRequired,
    schema: PropTypes.object.isRequired,
    texts: PropTypes.object.isRequired,
};

export function MyForm({ user, schema, texts }) {
    const formRef = useRef(null);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const languageOptions = [
        { value: 'pt', label: texts.portugueseText },
        { value: 'en', label: texts.englishText },
        { value: 'ge', label: texts.germanyText },
    ];

    const handleSubmit = async (data) => {
        const { parsedData, isValid, errors } = await validateForm(
            data,
            schema
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(setLoading(true));

            const response = await sendUserCredentials(user.id, parsedData);

            dispatch(setLoading(false));

            if (response) {
                toast.success(texts.successText);

                history.push('/users');
            }
        }
    };

    useEffect(() => {
        formRef.current.reset();

        formRef.current.setData({
            name: user.name,
            email: user.email,
        });
    }, []);

    useEffect(() => {
        if (formRef.current.getFieldValue('lang')) {
            let selectedLanguage = languageOptions.find(
                (lang) => lang.value === formRef.current.getFieldValue('lang')
            );

            formRef.current.setFieldValue('lang', {
                label: selectedLanguage?.label,
                value: selectedLanguage?.value,
            });
        }
    }, [lang]);

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-5">
                    <Input
                        name="name"
                        type="text"
                        label={texts.nameText}
                        data-cy="user_credentials_name_input"
                        readOnly
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-3">
                    <Input
                        name="email"
                        type="text"
                        label={texts.emailText}
                        data-cy="user_credentials_email_input"
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-3">
                    <Select
                        name="lang"
                        label={texts.langText}
                        data-cy="user_credentials_lang_input"
                        className="lang-sheperd user_credentials_lang_input"
                        options={languageOptions}
                    />
                </FormGroup>
            </FormGroup>

            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button
                        color="primary"
                        className="mr-2"
                        type="submit"
                        data-cy="user_credentials_submit_button"
                    >
                        {texts.sendText}
                    </Button>

                    <Link
                        to={{
                            pathname: '/users/edit',
                            state: { entity: user },
                        }}
                        className="btn btn-secondary mr-2"
                        data-cy="user_credentials_change_email_link"
                    >
                        {texts.changeEmailText}
                    </Link>

                    <Link
                        to={'/users'}
                        className="btn btn-secondary"
                        data-cy="user_credentials_cancel_link"
                    >
                        {texts.cancelText}
                    </Link>
                </div>
            </FormGroup>
        </Form>
    );
}

import React, { useRef, useEffect } from 'react';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

export default function Select({ name, label, required = false, ...rest }) {
    const selectRef = useRef(null);

    const { fieldName, defaultValue, registerField, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            getValue: (ref) => {
                if (rest.isMulti) {
                    if (!ref.state.value) {
                        return [];
                    }
                    return ref.state.value.map((option) => option.value);
                }
                if (!ref.state.value) {
                    return '';
                }
                return ref.state.value.value;
            },
            setValue: (ref, value) => {
                ref.select.setValue(value);
            },
            clearValue: (ref) => {
                ref.select.clearValue();
            },
        });
    }, [fieldName, registerField, rest.isMulti]);

    return (
        <>
            {label && (
                <label
                    htmlFor={fieldName}
                    dangerouslySetInnerHTML={{
                        __html: required
                            ? `${label} <b style="color: darkred">*</b>`
                            : label,
                    }}
                    data-cy={`${rest['data-cy']}_label`}
                ></label>
            )}

            <ReactSelect
                defaultValue={defaultValue}
                ref={selectRef}
                classNamePrefix="react-select"
                {...rest}
            />
            {error && (
                <span
                    className="text-danger"
                    data-cy={`${rest['data-cy']}-error`}
                >
                    {error}
                </span>
            )}
        </>
    );
}

Select.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
};

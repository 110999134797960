import { differenceInDays } from 'date-fns';

export function getMonthToRemoveDiscount(discountMonths, billingDateString) {
    let monthToRemove;
    let yearToRemove;

    let currentDate = new Date();
    let currentMonth = currentDate.getMonth() + 1;
    let currentYear = currentDate.getFullYear();

    let billingDate = new Date(billingDateString);
    let billingDateMonth = billingDate.getMonth() + 1;

    if (billingDateMonth == currentMonth) {
        monthToRemove = currentMonth + discountMonths - 1;
    } else {
        monthToRemove = currentMonth + discountMonths;
    }

    yearToRemove = currentYear;

    while (monthToRemove > 12) {
        yearToRemove += 1;
        monthToRemove -= 12;
    }

    return { monthToRemove, yearToRemove };
}

export function getNewSubscriptionProRataValue(
    fullPrice,
    nextBillingDateStr,
    lastBillingDateStr = null
) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const lastBillingDateDay = Number(lastBillingDateStr.slice(8, 10));

    const lastBillingDate = new Date(lastBillingDateStr);
    lastBillingDate.setHours(0, 0, 0, 0);

    if (lastBillingDateDay !== lastBillingDate.getDate()) {
        lastBillingDate.setDate(
            lastBillingDate.getDate() +
                (lastBillingDateDay - lastBillingDate.getDate())
        );
    }

    const nextBillingDateDay = Number(nextBillingDateStr.slice(8, 10));

    const nextBillingDate = new Date(nextBillingDateStr);
    nextBillingDate.setHours(0, 0, 0, 0);

    if (nextBillingDateDay !== nextBillingDate.getDate()) {
        nextBillingDate.setDate(
            nextBillingDate.getDate() +
                (nextBillingDateDay - nextBillingDate.getDate())
        );
    }

    const remainingDays = differenceInDays(nextBillingDate, today);

    const subscriptionIntervalDays = differenceInDays(
        nextBillingDate,
        lastBillingDate
    );

    return (fullPrice * remainingDays) / subscriptionIntervalDays;
}

export function getPlanUpgradeProRataValue(
    newPlanPrice,
    oldPlanPrice,
    nextBillingDateStr,
    lastBillingDateStr = null
) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const lastBillingDateDay = Number(lastBillingDateStr.slice(8, 10));
    const nextBillingDateDay = Number(nextBillingDateStr.slice(8, 10));

    const lastBillingDate = new Date(lastBillingDateStr);
    lastBillingDate.setHours(0, 0, 0, 0);

    const nextBillingDate = new Date(nextBillingDateStr);
    nextBillingDate.setHours(0, 0, 0, 0);

    if (lastBillingDateDay !== lastBillingDate.getDate()) {
        lastBillingDate.setDate(
            lastBillingDate.getDate() +
                (lastBillingDateDay - lastBillingDate.getDate())
        );
    }

    if (nextBillingDateDay !== nextBillingDate.getDate()) {
        nextBillingDate.setDate(
            nextBillingDate.getDate() +
                (nextBillingDateDay - nextBillingDate.getDate())
        );
    }

    const subscriptionCycleDays = differenceInDays(
        nextBillingDate,
        lastBillingDate
    );

    const subscriptionUsedDays = differenceInDays(today, lastBillingDate);

    const unusedOldPrice =
        oldPlanPrice * (1 - subscriptionUsedDays / subscriptionCycleDays);

    const unusedNewSubscriptionPrice =
        newPlanPrice * (1 - subscriptionUsedDays / subscriptionCycleDays);

    return Math.round(unusedNewSubscriptionPrice - unusedOldPrice);
}

export function getLastBillingDate(nextBillingDateStr) {
    let billingDate = new Date(nextBillingDateStr);
    billingDate.setHours(0, 0, 0, 0);

    let billingDateDay = Number(nextBillingDateStr.slice(8, 10));

    if (billingDateDay !== billingDate.getDate()) {
        billingDate.setDate(
            billingDate.getDate() + (billingDateDay - billingDate.getDate())
        );
    }

    let lastBillingDate = null;

    if (billingDate.getMonth() === 0) {
        lastBillingDate = new Date(
            billingDate.getFullYear(),
            11,
            billingDate.getDate()
        );
    } else {
        lastBillingDate = new Date(
            billingDate.getFullYear(),
            billingDate.getMonth() - 1,
            billingDate.getDate()
        );
    }

    return lastBillingDate;
}

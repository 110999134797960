export default {
    pt: {
        twoFactorAuthenticationText: 'Autenticação de 2 fatores',
        successText: 'Autenticação de 2 fatores habilitada com sucesso!',
        infoText: `Ao habilitar a autenticação de 2 fatores os usuários
            receberão, por email, um código de 6 dígitos, ao fazer
            login.`,
        twoFactorAuthenticationRecoveryCodeText:
            'Códigos de recuperação de autenticação de 2 fatores',
        info2Text: `Os códigos abaixo devem ser utilizados em situações de
            emergência (por exemplo, se você perdeu acesso ao seu
            email que recebe os códigos de autenticação). Cada
            código pode ser utilizado apenas uma vez.`,
        info3Text:
            'Após utilizar todos os códigos, entre em contato com o suporte para receber novos códigos.',
        saveCodeText: 'Salve-os em um local seguro!',
        downloadCodeText: 'Baixar códigos',
        iSavedCodeText: 'Eu salvei os códigos de recuperação',
        enableText: 'Habilitar',
        cancelText: 'Cancelar',
    },
    en: {
        twoFactorAuthenticationText: 'Two-Factor authentication',
        successText: 'Two-factor authentication successfully enabled!',
        infoText: `By enabling 2-factor authentication, users
            will receive, by email, a 6-digit code, when making
            Login.`,
        twoFactorAuthenticationRecoveryCodeText:
            'Two-Factor authentication recovery codes',
        info2Text: `The codes below must be used in situations of
            emergency (for example, if you lost access to your
            email that receives the authentication codes). Each
            code can only be used once.`,
        info3Text:
            'After using all the codes, contact support to receive new codes.',
        saveCodeText: 'Save them in a safe place!',
        downloadCodeText: 'Download codes',
        iSavedCodeText: 'I saved the recovery codes',
        enableText: 'Enable',
        cancelText: 'Cancel',
    },
};

import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import PropTypes from 'prop-types';

import { currencyFormatter } from '../../../../../helpers/formatters';
import { CardBodyPrice } from './cardBodyPrice';

CardItem.propTypes = {
    plans: PropTypes.array.isRequired,
    planName: PropTypes.string.isRequired,
    planPrices: PropTypes.object.isRequired,
    maxPatients: PropTypes.number.isRequired,
    currency: PropTypes.object.isRequired,
    paymentMethod: PropTypes.object,
    texts: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
};

export function CardItem({
    plans,
    planName,
    planPrices,
    maxPatients,
    currency,
    paymentMethod,
    texts,
    currentUser,
}) {
    return (
        <>
            <div className="planItem">
                <Card className="planItemCard">
                    <CardHeader className="planItemCardHeader">
                        <div className="planItemCardHeaderContent">
                            <span>{planName}</span>
                        </div>
                    </CardHeader>
                    <CardBody className="planItemCardBody">
                        {planPrices?.monthly !== undefined ? (
                            <CardBodyPrice
                                planPrice={currencyFormatter(
                                    planPrices.monthly,
                                    currency.locale,
                                    currency.format
                                )}
                                planPaymentTypeText={texts.planPaymentTypeText}
                                fidelityText={texts.fidelityText.monthly}
                            />
                        ) : (
                            <CardBodyPrice
                                planPrice={'--'}
                                planPaymentTypeText={''}
                                fidelityText={'--'}
                            />
                        )}
                        {planPrices?.biannual !== undefined ? (
                            <CardBodyPrice
                                planPrice={currencyFormatter(
                                    planPrices.biannual,
                                    currency.locale,
                                    currency.format
                                )}
                                planPaymentTypeText={texts.planPaymentTypeText}
                                fidelityText={texts.fidelityText.biannual}
                            />
                        ) : (
                            <CardBodyPrice
                                planPrice={'--'}
                                planPaymentTypeText={''}
                                fidelityText={'--'}
                            />
                        )}
                        {planPrices?.annual !== undefined ? (
                            <CardBodyPrice
                                planPrice={currencyFormatter(
                                    planPrices.annual,
                                    currency.locale,
                                    currency.format
                                )}
                                planPaymentTypeText={texts.planPaymentTypeText}
                                fidelityText={texts.fidelityText.annual}
                            />
                        ) : (
                            <CardBodyPrice
                                planPrice={'--'}
                                planPaymentTypeText={''}
                                fidelityText={'--'}
                            />
                        )}

                        <ul className="planItemCardInfo">
                            <li>{`${maxPatients} ${texts.maxPatientsText}`}</li>
                        </ul>
                    </CardBody>
                    <div className="planItemCardAction">
                        {currentUser?.subscription?.plans?.find(
                            (subscriptionPlan) =>
                                subscriptionPlan.plan.name.includes(planName) &&
                                subscriptionPlan.active
                        ) ? (
                            <Button color="primary" disabled>
                                {texts.currentPlanButtonText}
                            </Button>
                        ) : (
                            <Link
                                className="btn btn-primary"
                                to={{
                                    pathname:
                                        '/account/my_account/change_plan/choose_subscription',
                                    state: {
                                        plans,
                                        currency,
                                        paymentMethod,
                                        planType: 'sleep_diary',
                                    },
                                }}
                            >
                                {texts.chooseText}
                            </Link>
                        )}
                    </div>
                </Card>
            </div>
        </>
    );
}

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import history from '../../../services/history';
import { DeletePlanForm } from './components/form';

export default function DeletePlan({ currentUser }) {
    const [plan, setPlan] = useState({});

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];
    const schemaData = schemaConfig[lang][currentUser.permissions];

    useEffect(() => {
        if (history.location.state?.entity) {
            setPlan(history.location.state.entity);
        } else {
            history.push('/plans');
        }
    }, []);

    if (Object.entries(plan).length === 0) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <DeletePlanForm
                    plan={plan}
                    fields={pageData?.fields}
                    schema={schemaData.schema}
                    texts={{
                        deleteSuccessText: pageData?.deleteSuccessText,
                        deleteButtonText: pageData?.deleteButtonText,
                        cancelButtonText: pageData?.cancelButtonText,
                        intervalTypeOptions: pageData?.intervalTypeOptions,
                        payableWithOptions: pageData?.payableWithOptions,
                        yesText: pageData?.yesText,
                        noText: pageData?.noText,
                        planTypes: pageData?.planTypes,
                    }}
                    lang={lang}
                />
            </CardBody>
        </Card>
    );
}

DeletePlan.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

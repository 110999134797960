import Input from '../../../components/inputForm/inputText';

export default {
    pt: {
        customer_admin: {
            deviceFields: [
                {
                    component: Input,
                    props: {
                        name: 'device_id',
                        label: 'Número de série',
                        type: 'text',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'body_part',
                        type: 'text',
                        label: 'Parte do corpo',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'received_at',
                        type: 'date',
                        label: 'Data de recebimento',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
            ],
            bodyPartTexts: {
                wrist: 'Pulso',
                necklace: 'Colar',
            },
            devicesText: 'Dispositivos',
            serialNumberText: 'Numéro de série',
            receivedAtText: 'Data de recebimento',
            bodyPartText: 'Parte do corpo',
            successText: 'Paciente excluído com sucesso!',
            deleteText: 'Excluir',
            cancelText: 'Cancelar',
            yesText: 'Sim',
            noText: 'Não',
            defaultDiaryText: 'Diário padrão',
            nameText: 'Nome',
            secondaryIdText: 'ID Secundário',
            emailText: 'e-Mail',
            userTypeText: 'Tipo de usuário',
            activeText: 'Ativo',
            trialStartText: 'Início',
            trialEndText: 'Fim',
            diaryText: 'Diário',
            patientText: 'Paciente',
            managerText: 'Gestor',
            condorAdminText: 'Administrador Condor',
            managerAdminText: 'Gestor Administrador',
        },
        manager: {
            deviceFields: [
                {
                    component: Input,
                    props: {
                        name: 'device_id',
                        label: 'Número de série',
                        type: 'text',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'body_part',
                        type: 'text',
                        label: 'Parte do corpo',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'received_at',
                        type: 'date',
                        label: 'Data de recebimento',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
            ],
            bodyPartTexts: {
                wrist: 'Pulso',
                necklace: 'Colar',
            },
            devicesText: 'Dispositivos',
            serialNumberText: 'Numéro de série',
            receivedAtText: 'Data de recebimento',
            bodyPartText: 'Parte do corpo',
            successText: 'Usuário excluído com sucesso!',
            deleteText: 'Excluir',
            cancelText: 'Cancelar',
            yesText: 'Sim',
            noText: 'Não',
            defaultDiaryText: 'Diário padrão',
            nameText: 'Nome',
            secondaryIdText: 'ID Secundário',
            emailText: 'e-Mail',
            userTypeText: 'Tipo de usuário',
            activeText: 'Ativo',
            trialStartText: 'Início',
            trialEndText: 'Fim',
            diaryText: 'Diário',
            patientText: 'Paciente',
            managerText: 'Gestor',
            condorAdminText: 'Administrador Condor',
            managerAdminText: 'Gestor Administrador',
        },
        condor_admin: {
            successText: 'Usuário excluído com sucesso!',
            deleteText: 'Excluir',
            cancelText: 'Cancelar',
            yesText: 'Sim',
            noText: 'Não',
            nameText: 'Nome',
            secondaryIdText: 'ID Secundário',
            emailText: 'e-Mail',
            userTypeText: 'Tipo de usuário',
            activeText: 'Ativo',
            trialStartText: 'Início',
            trialEndText: 'Fim',
            diaryText: 'Diário',
            patientText: 'Paciente',
            managerText: 'Gestor',
            condorAdminText: 'Administrador Condor',
            managerAdminText: 'Gestor Administrador',
        },
    },
    en: {
        customer_admin: {
            deviceFields: [
                {
                    component: Input,
                    props: {
                        name: 'device_id',
                        label: 'Serial number',
                        type: 'text',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'body_part',
                        type: 'text',
                        label: 'Body part',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'received_at',
                        type: 'date',
                        label: 'Received at',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
            ],
            bodyPartTexts: {
                wrist: 'Wrist',
                necklace: 'Necklace',
            },
            devicesText: 'Devices',
            serialNumberText: 'Serial number',
            receivedAtText: 'Received at',
            bodyPartText: 'Body part',
            successText: 'Patient successfully deleted!',
            deleteText: 'Delete',
            cancelText: 'Cancel',
            yesText: 'Yes',
            noText: 'No',
            defaultDiaryText: 'Default diary',
            nameText: 'Name',
            secondaryIdText: 'Secondary ID',
            emailText: 'e-Mail',
            userTypeText: 'User type',
            activeText: 'Active',
            trialStartText: 'Start',
            trialEndText: 'End',
            diaryText: 'Diary',
            patientText: 'Patient',
            managerText: 'Manager',
            condorAdminText: 'Condor Admin',
            managerAdminText: 'Manager Admin',
        },
        manager: {
            deviceFields: [
                {
                    component: Input,
                    props: {
                        name: 'device_id',
                        label: 'Serial number',
                        type: 'text',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'body_part',
                        type: 'text',
                        label: 'Body part',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'received_at',
                        type: 'date',
                        label: 'Received at',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
            ],
            bodyPartTexts: {
                wrist: 'Wrist',
                necklace: 'Necklace',
            },
            devicesText: 'Devices',
            serialNumberText: 'Serial number',
            receivedAtText: 'Received at',
            bodyPartText: 'Body part',
            successText: 'User successfully deleted!',
            deleteText: 'Delete',
            cancelText: 'Cancel',
            yesText: 'Yes',
            noText: 'No',
            defaultDiaryText: 'Default diary',
            nameText: 'Name',
            secondaryIdText: 'Secondary ID',
            emailText: 'e-Mail',
            userTypeText: 'User type',
            activeText: 'Active',
            trialStartText: 'Start',
            trialEndText: 'End',
            diaryText: 'Diary',
            patientText: 'Patient',
            managerText: 'Manager',
            condorAdminText: 'Condor Admin',
            managerAdminText: 'Manager Admin',
        },
        condor_admin: {
            successText: 'User successfully deleted!',
            deleteText: 'Delete',
            cancelText: 'Cancel',
            yesText: 'Yes',
            noText: 'No',
            nameText: 'Name',
            secondaryIdText: 'Secondary ID',
            emailText: 'e-Mail',
            userTypeText: 'User type',
            activeText: 'Active',
            trialStartText: 'Start',
            trialEndText: 'End',
            diaryText: 'Diary',
            patientText: 'Patient',
            managerText: 'Manager',
            condorAdminText: 'Condor Admin',
            managerAdminText: 'Manager Admin',
        },
    },
};

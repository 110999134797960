import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { differenceInDays } from 'date-fns';
import PropTypes from 'prop-types';

import '../../styles/components/layout/header.css';

export function Header({
    currentUser,
    pageData,
    handleLogout,
    toggleSidebar,
    buttons,
    handleChangeLang,
}) {
    const { dropDownMenu } = pageData;

    let expiresIn;

    let activeTrialSubscription = currentUser?.subscription?.plans?.find(
        (subscriptionPlan) =>
            subscriptionPlan.plan.name.toLowerCase().includes('trial') &&
            subscriptionPlan.active
    );

    if (activeTrialSubscription) {
        let now = new Date();
        now.setHours(0, 0, 0, 0);

        let expiresAt = new Date(activeTrialSubscription.expires_at);
        expiresAt.setHours(0, 0, 0, 0);

        let day = Number(activeTrialSubscription.expires_at.slice(8, 10));

        if (day !== expiresAt.getDate()) {
            expiresAt.setDate(
                expiresAt.getDate() + (day - expiresAt.getDate())
            );
        }

        expiresIn = differenceInDays(expiresAt, now);
    }

    return (
        <header className="header">
            <div className="logo">
                <img src="/assets/logo.svg" alt="Logo Condor" />
            </div>
            <div className="headerContent ">
                <div>
                    <a onClick={toggleSidebar}>
                        <FontAwesomeIcon icon={faBars} />
                    </a>
                </div>
                <div className="ml-auto">
                    {activeTrialSubscription && (
                        <div className="subscriptionExpiresIn">
                            <p>
                                {`${
                                    activeTrialSubscription.type ===
                                    'sleep_diary'
                                        ? pageData.sleepDiaryTrialsubscriptionExpiresInText
                                        : pageData.lumusTrialSubscriptionExpiresInText
                                } ${expiresIn} ${pageData.daysText}!`}
                            </p>
                        </div>
                    )}
                    <ul className="centerItems">
                        {buttons.map((button) => (
                            <li key={button.name}>
                                <button
                                    type="button"
                                    className="langButton"
                                    disabled={button.disabled}
                                    name={button.name}
                                    onClick={(e) =>
                                        handleChangeLang(e.target.name)
                                    }
                                >
                                    {button.text}
                                </button>
                            </li>
                        ))}
                    </ul>
                    <div>
                        <ul>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle
                                    nav
                                    caret
                                    className="centerItems"
                                >
                                    <FontAwesomeIcon
                                        icon={faUserCircle}
                                        className="userIcon"
                                    />
                                    <span className="ml-2">
                                        {currentUser.name.split(' ')[0]}
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {dropDownMenu.map((item) => {
                                        if (!item.link) {
                                            return (
                                                <React.Fragment
                                                    key={item.label}
                                                >
                                                    <DropdownItem divider />
                                                    <DropdownItem
                                                        onClick={handleLogout}
                                                    >
                                                        {item.label}
                                                    </DropdownItem>
                                                </React.Fragment>
                                            );
                                        }

                                        return (
                                            <Link
                                                key={item.label}
                                                to={item.link}
                                            >
                                                <DropdownItem>
                                                    {item.label}
                                                </DropdownItem>
                                            </Link>
                                        );
                                    })}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
}

Header.propTypes = {
    currentUser: PropTypes.object.isRequired,
    pageData: PropTypes.object.isRequired,
    handleLogout: PropTypes.func.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    buttons: PropTypes.array.isRequired,
    handleChangeLang: PropTypes.func.isRequired,
};

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardTitle,
    CardSubtitle,
    CardBody,
    CardFooter,
} from 'reactstrap';
import { FaCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';

import Table from '../../../../components/table/table';
import TableHeaders from '../../../../components/table/tableHeaders';
import Td from '../../../../components/table/td';
import useWindowSize from '../../../../hooks/useWindowSize';
import Plot from '../../../../components/plot';
import { getMinValue } from '../../../../helpers/getMinValue';
import { getMaxValue } from '../../../../helpers/getMaxValue';

System.propTypes = {
    data: PropTypes.shape({
        tableData: PropTypes.shape({
            vBat: PropTypes.string,
            logType: PropTypes.number,
            formattedLogType: PropTypes.string,
            logTime: PropTypes.string,
            formattedLogTime: PropTypes.string,
        }).isRequired,
        chartData: PropTypes.shape({
            log_time: PropTypes.arrayOf(PropTypes.string),
            voltage: PropTypes.arrayOf(PropTypes.number),
            connection: PropTypes.shape({
                x: PropTypes.arrayOf(PropTypes.string),
                y: PropTypes.arrayOf(PropTypes.number),
            }),
            disconnection: PropTypes.shape({
                x: PropTypes.arrayOf(PropTypes.string),
                y: PropTypes.arrayOf(PropTypes.number),
            }),
        }),
    }).isRequired,
    textData: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
};

export function System({ data, textData, visible }) {
    const { width } = useWindowSize();

    const lang = useSelector((state) => state.lang.lang);

    const { tableData, chartData } = data;

    const minRange = chartData?.log_time
        ? getMinValue([
              getMinValue(chartData?.log_time),
              getMinValue(chartData?.connection.x),
              getMinValue(chartData?.disconnection.x),
          ])
        : null;

    const maxRange = chartData?.log_time
        ? getMaxValue([
              getMaxValue(chartData?.log_time),
              getMaxValue(chartData?.connection.x),
              getMaxValue(chartData?.disconnection.x),
          ])
        : null;

    const plotData = [
        {
            x: chartData?.log_time,
            y: chartData?.voltage,
            type: 'scatter',
            mode: 'lines',
            name: textData.voltageText,
            line: { shape: 'linear', color: '#1A33F4', width: 2 },
        },
        {
            x: chartData?.connection.x,
            y: chartData?.connection.y,
            type: 'scatter',
            mode: 'markers',
            name: textData.connectionText,
            marker: {
                color: '#5fbf3f',
            },
        },
        {
            x: chartData?.disconnection.x,
            y: chartData?.disconnection.y,
            type: 'scatter',
            mode: 'markers',
            name: textData.disconnectionText,
            marker: {
                color: '#bf3f3f',
            },
        },
    ];

    const layout = useMemo(() => {
        return {
            margin: {
                t: 0,
                b: 0,
                l: 50,
                r: 10,
            },
            font: { family: 'Raleway, sans-serif' },
            xaxis: {
                type: 'date',
                autorange: true,
                range: [minRange, maxRange],
                fixedrange: true,
                rangeselector: {
                    buttons: [
                        {
                            count: 7,
                            label: textData.oneWeekText,
                            step: 'day',
                            stepmode: 'backward',
                        },
                        {
                            count: 1,
                            label: textData.oneMonthText,
                            step: 'month',
                            stepmode: 'backward',
                        },
                        { step: 'all' },
                    ],
                },
                rangeslider: { range: [minRange, maxRange] },
                visible: true,
                showgrid: false,
                title: {
                    text: textData.hoursText,
                },
            },
            yaxis: {
                tickmode: 'auto',
                type: 'linear',
                autorange: true,
                fixedrange: true,
                visible: true,
                showgrid: true,
                gridcolor: '#cccccc',
                gridwidth: 1,
                zerolinecolor: '#cccccc',
                title: {
                    text: textData.voltageText,
                },
            },
            showlegend: false,
            height: 200,
            width: width - (20 * width) / 100,
            hoverinfo: 'y unified',
        };
    }, [width, lang]);

    const config = {
        responsive: true,
        editable: false,
        scrollzoom: false,
        showAxisDragHandles: false,
        displayModeBar: false,
    };

    return (
        <>
            <Col xs="12">
                <Table>
                    <thead>
                        <tr>
                            <TableHeaders ths={textData.ths} />
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <Td
                                key={textData.tds[0].th}
                                title={textData.tds[0].th}
                            >
                                {tableData[textData.tds[0].property]}
                            </Td>
                            <Td
                                key={textData.tds[1].th}
                                title={textData.tds[1].th}
                            >
                                {tableData[textData.tds[1].property]}
                            </Td>
                            <Td
                                key={textData.tds[2].th}
                                title={textData.tds[2].th}
                                className={
                                    tableData.logType === 96
                                        ? 'textGreen'
                                        : 'textRed'
                                }
                            >
                                {tableData[textData.tds[2].property]}
                            </Td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
            <Col xs="12" className="mt-2">
                <Card className="cardShadow">
                    <CardHeader>
                        <CardTitle>
                            <h5>{textData.batteryVoltageText}</h5>
                        </CardTitle>
                        <CardSubtitle>
                            {textData.batteryVoltageChartText}
                        </CardSubtitle>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col
                                xs="12"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {chartData && visible ? (
                                    <Plot
                                        data={plotData}
                                        layout={layout}
                                        config={config}
                                    />
                                ) : (
                                    textData.noDataText
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <FaCircle color="#1A33F4" />{' '}
                        {textData.batteryVoltageText}
                    </CardFooter>
                </Card>
            </Col>
        </>
    );
}

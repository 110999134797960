export default [
    'jpeg',
    'jpg',
    'gif',
    'png',
    'bmp',
    'tiff',
    'psd',
    'exif',
    'pdf',
    'eps',
    'svg',
    'webp',
];

import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            schema: Yup.object().shape({
                file: Yup.mixed()
                    .test('fileSize', 'O arquivo é muito grande!', (value) => {
                        if (!value) return true;
                        return value.size <= 2000000;
                    })
                    .test(
                        'fileExtension',
                        `A extensão do arquivo não é permitida. Insira um arquivo .csv.`,
                        (value) => {
                            if (!value) return true;
                            return value.name.split('.')[1] === 'csv';
                        }
                    )
                    .required('O arquivo é obrigatório.'),
            }),
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            schema: Yup.object().shape({
                file: Yup.mixed()
                    .test('fileSize', 'File is too large.', (value) => {
                        if (!value) return true;
                        return value.size <= 2000000;
                    })
                    .test(
                        'fileExtension',
                        `The file extension is not allowed. Provide a .csv file.`,
                        (value) => {
                            if (!value) return true;
                            return value.name.split('.')[1] === 'csv';
                        }
                    )
                    .required('File is required.'),
            }),
        },
    },
};

import Input from '../../../components/inputForm/inputText';
import Select from '../../../components/inputForm/inputSelect';

export default {
    pt: {
        customer_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Nome',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'ID Secundário',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'active',
                        label: 'Ativo',
                        options: [
                            { value: false, label: 'Não' },
                            { value: true, label: 'Sim' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialStart',
                        label: 'Início',
                        type: 'date',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialEnd',
                        label: 'Fim',
                        type: 'date',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'diary_id',
                        label: 'Diário',
                        options: [{}],
                    },
                    className: 'col-xs-12 col-md-4',
                },
            ],
            deviceFields: [
                {
                    component: Select,
                    props: {
                        name: 'device_id',
                        label: 'Número de série',
                        className: 'edit_user_device_id_input',
                        'data-cy': 'edit_user_device_id_input',
                        options: [],
                    },
                    className: 'col-xs-12 col-md-2',
                },
                {
                    component: Select,
                    props: {
                        name: 'body_part',
                        label: 'Parte do corpo',
                        className: 'edit_user_body_part_input',
                        'data-cy': 'edit_user_body_part_input',
                        options: [
                            {
                                value: 'wrist',
                                label: 'Pulso',
                            },
                            {
                                value: 'necklace',
                                label: 'Colar',
                            },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'received_at',
                        type: 'date',
                        label: 'Data de recebimento',
                        'data-cy': 'edit_user_received_at_input',
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            bodyPartOptions: [
                {
                    value: 'wrist',
                    label: 'Pulso',
                },
                {
                    value: 'necklace',
                    label: 'Colar',
                },
            ],
            devicesText: 'Dispositivos',
            serialNumberText: 'Numéro de série',
            receivedAtText: 'Data de recebimento',
            bodyPartText: 'Parte do corpo',
            addDeviceText: 'Adicionar dispositivo',
            deleteDeviceText: 'Excluir dispositivo',
            successText: 'Paciente editado com sucesso!',
            saveText: 'Salvar',
            cancelText: 'Cancelar',
            yesText: 'Sim',
            noText: 'Não',
            defaultDiaryText: 'Diário padrão',
            nameText: 'Nome',
            secondaryIdText: 'ID Secundário',
            emailText: 'e-Mail',
            activeText: 'Ativo',
            trialStartText: 'Início',
            trialEndText: 'Fim',
            diaryText: 'Diário',
        },
        manager: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Nome',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'ID Secundário',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'active',
                        label: 'Ativo',
                        options: [
                            { value: false, label: 'Não' },
                            { value: true, label: 'Sim' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialStart',
                        label: 'Início',
                        type: 'date',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialEnd',
                        label: 'Fim',
                        type: 'date',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'diary_id',
                        label: 'Diário',
                        options: [{}],
                    },
                    className: 'col-xs-12 col-md-4',
                },
            ],
            deviceFields: [
                {
                    component: Select,
                    props: {
                        name: 'device_id',
                        label: 'Número de série',
                        className: 'edit_user_device_id_input',
                        'data-cy': 'edit_user_device_id_input',
                        options: [],
                    },
                    className: 'col-xs-12 col-md-2',
                },
                {
                    component: Select,
                    props: {
                        name: 'body_part',
                        label: 'Parte do corpo',
                        className: 'edit_user_body_part_input',
                        'data-cy': 'edit_user_body_part_input',
                        options: [
                            {
                                value: 'wrist',
                                label: 'Pulso',
                            },
                            {
                                value: 'necklace',
                                label: 'Colar',
                            },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'received_at',
                        type: 'date',
                        label: 'Data de recebimento',
                        'data-cy': 'edit_user_received_at_input',
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            bodyPartOptions: [
                {
                    value: 'wrist',
                    label: 'Pulso',
                },
                {
                    value: 'necklace',
                    label: 'Colar',
                },
            ],
            devicesText: 'Dispositivos',
            serialNumberText: 'Numéro de série',
            receivedAtText: 'Data de recebimento',
            bodyPartText: 'Parte do corpo',
            addDeviceText: 'Adicionar dispositivo',
            deleteDeviceText: 'Excluir dispositivo',
            successText: 'Usuário editado com sucesso!',
            saveText: 'Salvar',
            cancelText: 'Cancelar',
            yesText: 'Sim',
            noText: 'Não',
            defaultDiaryText: 'Diário padrão',
            nameText: 'Nome',
            secondaryIdText: 'ID Secundário',
            emailText: 'e-Mail',
            activeText: 'Ativo',
            trialStartText: 'Início',
            trialEndText: 'Fim',
            diaryText: 'Diário',
        },
        condor_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Nome',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'ID Secundário',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'active',
                        label: 'Ativo',
                        options: [
                            { value: false, label: 'Não' },
                            { value: true, label: 'Sim' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            successText: 'Usuário editado com sucesso!',
            saveText: 'Salvar',
            cancelText: 'Cancelar',
            yesText: 'Sim',
            noText: 'Não',
            sharePatientsText: 'Compartilhar pacientes?',
            nameText: 'Nome',
            secondaryIdText: 'ID Secundário',
            emailText: 'e-Mail',
            activeText: 'Ativo',
        },
    },
    en: {
        customer_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Name',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'Secondary ID',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'active',
                        label: 'Active',
                        options: [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialStart',
                        label: 'Start',
                        type: 'date',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialEnd',
                        label: 'End',
                        type: 'date',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'diary_id',
                        label: 'Diary',
                        options: [{}],
                    },
                    className: 'col-xs-12 col-md-4',
                },
            ],
            deviceFields: [
                {
                    component: Select,
                    props: {
                        name: 'device_id',
                        label: 'Serial number',
                        className: 'edit_user_device_id_input',
                        'data-cy': 'edit_user_device_id_input',
                        options: [],
                    },
                    className: 'col-xs-12 col-md-2',
                },
                {
                    component: Select,
                    props: {
                        name: 'body_part',
                        label: 'Body part',
                        className: 'edit_user_body_part_input',
                        'data-cy': 'edit_user_body_part_input',
                        options: [
                            {
                                value: 'wrist',
                                label: 'Wrist',
                            },
                            {
                                value: 'necklace',
                                label: 'Necklace',
                            },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'received_at',
                        type: 'date',
                        label: 'Received at',
                        'data-cy': 'edit_user_received_at_input',
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            bodyPartOptions: [
                {
                    value: 'wrist',
                    label: 'Wrist',
                },
                {
                    value: 'necklace',
                    label: 'Necklace',
                },
            ],
            devicesText: 'Devices',
            serialNumberText: 'Serial number',
            receivedAtText: 'Received at',
            bodyPartText: 'Body part',
            addDeviceText: 'Add device',
            deleteDeviceText: 'Delete device',
            successText: 'Patient successfully edited!',
            saveText: 'Save',
            cancelText: 'Cancel',
            yesText: 'Yes',
            noText: 'No',
            defaultDiaryText: 'Default diary',
            nameText: 'Name',
            secondaryIdText: 'Secondary ID',
            emailText: 'e-Mail',
            activeText: 'Active',
            trialStartText: 'Start',
            trialEndText: 'End',
            diaryText: 'Diary',
        },
        manager: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Name',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'Secondary ID',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'active',
                        label: 'Active',
                        options: [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialStart',
                        label: 'Start',
                        type: 'date',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'trialEnd',
                        label: 'End',
                        type: 'date',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'diary_id',
                        label: 'Diary',
                        options: [{}],
                    },
                    className: 'col-xs-12 col-md-4',
                },
            ],
            deviceFields: [
                {
                    component: Select,
                    props: {
                        name: 'device_id',
                        label: 'Serial number',
                        className: 'edit_user_device_id_input',
                        'data-cy': 'edit_user_device_id_input',
                        options: [],
                    },
                    className: 'col-xs-12 col-md-2',
                },
                {
                    component: Select,
                    props: {
                        name: 'body_part',
                        label: 'Body part',
                        className: 'edit_user_body_part_input',
                        'data-cy': 'edit_user_body_part_input',
                        options: [
                            {
                                value: 'wrist',
                                label: 'Wrist',
                            },
                            {
                                value: 'necklace',
                                label: 'Necklace',
                            },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'received_at',
                        type: 'date',
                        label: 'Received at',
                        'data-cy': 'edit_user_received_at_input',
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            bodyPartOptions: [
                {
                    value: 'wrist',
                    label: 'Wrist',
                },
                {
                    value: 'necklace',
                    label: 'Necklace',
                },
            ],
            devicesText: 'Devices',
            serialNumberText: 'Serial number',
            receivedAtText: 'Received at',
            bodyPartText: 'Body part',
            addDeviceText: 'Add device',
            deleteDeviceText: 'Delete device',
            successText: 'User successfully edited!',
            saveText: 'Save',
            cancelText: 'Cancel',
            yesText: 'Yes',
            noText: 'No',
            defaultDiaryText: 'Default diary',
            nameText: 'Name',
            secondaryIdText: 'Secondary ID',
            emailText: 'e-Mail',
            activeText: 'Active',
            trialStartText: 'Start',
            trialEndText: 'End',
            diaryText: 'Diary',
        },
        condor_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Name',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'secondary_id',
                        type: 'text',
                        label: 'Secondary ID',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'email',
                        type: 'text',
                        label: 'e-Mail',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'active',
                        label: 'Active',
                        options: [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            successText: 'User successfully edited!',
            saveText: 'Save',
            cancelText: 'Cancel',
            yesText: 'Yes',
            noText: 'No',
            sharePatientsText: 'Share patients?',
            nameText: 'Name',
            secondaryIdText: 'Secondary ID',
            emailText: 'e-Mail',
            activeText: 'Active',
        },
    },
};

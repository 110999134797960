import React from 'react';

import '../../styles/components/layout/footer.css';

export function Footer() {
    return (
        <footer className="footer">
            <div>
                <p>
                    <img src="/assets/logo.svg" alt="Logo Condor" /> &copy; 2024
                    - SleepDiary
                </p>
            </div>
        </footer>
    );
}

import storage from 'redux-persist/lib/storage';
import { persistReducer, createMigrate } from 'redux-persist';

import SetTransform from './transforms';

const migrations = {
    0: (state) => {
        // migration: add minDate and maxDate to report state
        console.log('[0]: Running migrations...');
        return {
            ...state,
            report: {
                ...state.report,
                minDate: null,
                maxDate: null,
            },
        };
    },
    1: (state) => {
        // migration: add daysInWeek to report state
        console.log('[1]: Running migrations...');
        return {
            ...state,
            report: {
                ...state.report,
                daysInWeek: 7,
            },
        };
    },
    2: (state) => {
        // migration: change reportData type
        console.log('[2]: Running migrations...');
        return {
            ...state,
            report: {
                ...state.report,
                reportData: {
                    weekReference: {
                        data: null,
                        // loading: true,
                        // status: 'pending',
                    },
                    calendar: {
                        data: null,
                        // loading: true,
                        // status: 'pending',
                    },
                    weeklyEvolution: {
                        data: null,
                        // loading: true,
                        // status: 'pending',
                    },
                    dailyEvolution: {
                        data: null,
                        // loading: true,
                        // status: 'pending',
                    },
                    averageTable: {
                        data: null,
                        // loading: true,
                        // status: 'pending',
                    },
                    editableTable: {
                        data: null,
                        // loading: true,
                        // status: 'pending',
                    },
                    actogram: {
                        data: null,
                        // loading: true,
                        // status: 'pending',
                    },
                    sleepScoring: {
                        data: null,
                        // loading: true,
                        // status: 'pending',
                    },
                },
            },
        };
    },
    3: (state) => {
        // migration: remove token from auth state
        console.log('[3]: Running migrations...');
        return {
            ...state,
            auth: {
                currentUser: state.auth.currentUser,
                signed: state.auth.signed,
                loading: state.auth.loading,
            },
        };
    },
    4: (state) => {
        // migration: add completedLoginFlow to auth state
        console.log('[4]: Running migrations...');
        return {
            ...state,
            auth: {
                ...state.auth,
                completedLoginFlow: false,
            },
        };
    },
    5: (state) => {
        // migration: add maxDateToCompleteLoginFlow to auth state
        console.log('[5]: Running migrations...');
        return {
            ...state,
            auth: {
                ...state.auth,
                maxDateToCompleteLoginFlow: null,
            },
        };
    },
};

export default (reducers) => {
    const persistedReducer = persistReducer(
        {
            key: 'sleepdiary',
            storage,
            version: 5,
            whitelist: ['auth', 'lang', 'locale', 'report'],
            blacklist: ['loading'],
            transforms: [SetTransform],
            migrate: createMigrate(migrations, {
                debug: process.env.NODE_ENV === 'development',
            }),
            debug: process.env.NODE_ENV === 'development',
        },
        reducers
    );
    return persistedReducer;
};

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

function Breadcrumbs({ crumbs }) {
    return (
        <Breadcrumb data-cy="breadcrumb_container">
            {crumbs.map(({ name, link }, key) => {
                return key + 1 === crumbs.length ? (
                    <BreadcrumbItem active key={key}>
                        {name}
                    </BreadcrumbItem>
                ) : (
                    <BreadcrumbItem key={key}>
                        <Link to={link}>{name}</Link>
                    </BreadcrumbItem>
                );
            })}
        </Breadcrumb>
    );
}

Breadcrumbs.propTypes = {
    crumbs: PropTypes.array,
};

export default Breadcrumbs;

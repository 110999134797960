export function time(e) {
    e.currentTarget.maxLength = 5;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, '');
    value = value.replace(/^[3-9]/, '');
    value = value.replace(/^(2)([4-9])/, '$1');
    value = value.replace(/^(\d{2})(\d)/, '$1:$2');
    value = value.replace(/^(\d{2}):([6-9])$/, '$1:');
    e.currentTarget.value = value;
    return e;
}

export function duration(e) {
    e.currentTarget.maxLength = 5;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{2})(\d)/, '$1:$2');
    value = value.replace(/^(\d{2}):([6-9])$/, '$1:');
    e.currentTarget.value = value;
    return e;
}

export function cardNumber(e, brand) {
    let maxLength;

    let value = e.currentTarget.value;

    switch (brand) {
        case 'amex': {
            // pattern = '{{9999}} {{999999}} {{99999}}';
            maxLength = 17;
            value = value.replace(/\D/g, '');
            value = value.replace(/^(\d{4})(\d)/, '$1 $2');
            value = value.replace(/^(\d{4})\s(\d{6})(\d)/, '$1 $2 $3');
            break;
        }
        case 'diners': {
            // pattern = '{{9999}} {{999999}} {{9999}}';
            maxLength = 16;
            value = value.replace(/\D/g, '');
            value = value.replace(/^(\d{4})(\d)/, '$1 $2');
            value = value.replace(/^(\d{4})\s(\d{6})(\d)/, '$1 $2 $3');
            break;
        }
        default: {
            // pattern = '{{9999}} {{9999}} {{9999}} {{9999}}';
            maxLength = 19;
            value = value.replace(/\D/g, '');
            value = value.replace(/^(\d{4})(\d)/, '$1 $2');
            value = value.replace(/^(\d{4})\s(\d{4})(\d)/, '$1 $2 $3');
            value = value.replace(
                /^(\d{4})\s(\d{4})\s(\d{4})(\d)/,
                '$1 $2 $3 $4'
            );
            break;
        }
    }

    e.currentTarget.maxLength = maxLength;
    e.currentTarget.value = value;

    return e;
}

export function cardEpirationDate(e) {
    e.currentTarget.maxLength = 5;

    let value = e.currentTarget.value;

    value = value.replace(/\D/g, '');
    value = value.replace(/^[2-9]/, '');
    value = value.replace(/^(1)([3-9])/, '$1');
    value = value.replace(/^(\d{2})(\d)/, '$1/$2');

    e.currentTarget.value = value;
    return e;
}

export function cardCvv(e) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, '');
    e.currentTarget.value = value;
    return e;
}

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import pageConfig from './page.config';
import {
    get2faRecoveryCodes,
    setup2fa,
} from '../../../../services/api/account';
import { fetch2faRecoveryCodes } from '../../../../services/api/files';
import history from '../../../../services/history';
import { setLoading } from '../../../../store/actions/loadingActions';

TwoFactorAuthenticationSetupPage.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function TwoFactorAuthenticationSetupPage({ currentUser }) {
    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const [recoveryCodes, setRecoveryCodes] = useState([]);
    const [savedRecoveryCodes, setSavedRecoveryCodes] = useState(false);

    const pageData = pageConfig[lang];

    async function handleEnable2fa() {
        dispatch(setLoading(true));

        const data = await setup2fa(recoveryCodes);

        dispatch(setLoading(false));

        if (data) {
            toast.success(pageData.successText);

            history.push('/account/settings');
        }
    }

    async function handleDownloadFile() {
        dispatch(setLoading(true));

        const [code1, code2, code3, code4] = recoveryCodes;

        await fetch2faRecoveryCodes(code1, code2, code3, code4);

        dispatch(setLoading(false));
    }

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            const data = await get2faRecoveryCodes();

            dispatch(setLoading(false));

            if (data) {
                setRecoveryCodes(data.otp_codes);
            }
        })();
    }, []);

    return (
        <Card>
            <CardHeader>{pageData.twoFactorAuthenticationText}</CardHeader>

            <CardBody>
                <p>{pageData.infoText}</p>

                <hr />

                <div className="mt-4">
                    <p>
                        <b>
                            {pageData.twoFactorAuthenticationRecoveryCodeText}
                        </b>
                    </p>

                    <p className="mt-4">{pageData.info2Text}</p>

                    <p className="mt-2">{pageData.info3Text}</p>

                    <p className="mt-2">{pageData.saveCodeText}</p>

                    {recoveryCodes.length > 0 && (
                        <>
                            <div className={styles.recoveryCodesContainer}>
                                {recoveryCodes.map((otpCode) => (
                                    <p key={otpCode}>
                                        <b>{otpCode}</b>
                                    </p>
                                ))}
                            </div>

                            <div className="mt-4">
                                <Button
                                    color="primary"
                                    className="mr-2"
                                    onClick={() => handleDownloadFile()}
                                >
                                    {pageData.downloadCodeText}
                                </Button>
                            </div>

                            <div className="mt-4">
                                <input
                                    id="savedRecoveryCodes"
                                    type="checkbox"
                                    className="mr-2"
                                    checked={savedRecoveryCodes}
                                    onChange={() =>
                                        setSavedRecoveryCodes(
                                            !savedRecoveryCodes
                                        )
                                    }
                                />

                                <label htmlFor="savedRecoveryCodes">
                                    {pageData.iSavedCodeText}
                                </label>
                            </div>
                        </>
                    )}
                </div>

                <hr />

                <div>
                    <Button
                        color="primary"
                        className="mr-2"
                        disabled={!savedRecoveryCodes}
                        onClick={() => handleEnable2fa()}
                    >
                        {pageData.enableText}
                    </Button>

                    <Link to="/account/settings" className="btn btn-secondary">
                        {pageData.cancelText}
                    </Link>
                </div>
            </CardBody>
        </Card>
    );
}

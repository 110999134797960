import React from 'react';
import { useSelector } from 'react-redux';
import { ptBR, enUS } from 'date-fns/locale';
import PropTypes from 'prop-types';

import {
    localeWeekDayFormatter,
    localeDateFormatter,
} from '../../../../../helpers/formatters';

export function CurrentWeekTable({
    data,
    titleText,
    ths,
    isCommentsTable,
    completeWeekDays,
    locale,
}) {
    const lang = useSelector((state) => state.lang.lang);

    const dateLocale = lang === 'pt' ? ptBR : enUS;

    return (
        <>
            <div className="contentPrint">
                <div className="col-12Print">
                    <div className="cardPrint">
                        <div className="card-headerPrint card-tablePrint">
                            <div className="card-titlePrint">
                                <p className="pCardTitlePrint">{titleText}</p>
                            </div>
                        </div>
                        <div className="card-bodyPrint">
                            <div className="rowPrint">
                                <div className="col-12Print">
                                    <table className="col-12Print tablePrint currentWeektablePrint">
                                        <thead>
                                            <tr>
                                                {ths.map((th) => {
                                                    return (
                                                        <th
                                                            key={th}
                                                            className="thPrint text-center capitalize"
                                                            style={{
                                                                verticalAlign:
                                                                    'middle',
                                                            }}
                                                        >
                                                            {th}
                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {completeWeekDays.map(
                                                (day, index) => {
                                                    const weekDay = day
                                                        ? localeWeekDayFormatter(
                                                              day,
                                                              dateLocale
                                                          )
                                                            ? localeWeekDayFormatter(
                                                                  day,
                                                                  dateLocale
                                                              )
                                                            : ''
                                                        : '';

                                                    const date = day
                                                        ? localeDateFormatter(
                                                              day,
                                                              locale
                                                          )
                                                            ? localeDateFormatter(
                                                                  day,
                                                                  locale
                                                              )
                                                            : ''
                                                        : '';

                                                    return (
                                                        <tr key={day}>
                                                            <td
                                                                className="text-center tdPrint"
                                                                style={{
                                                                    verticalAlign:
                                                                        'middle',
                                                                }}
                                                            >
                                                                {`${weekDay} - ${date}`}
                                                            </td>
                                                            <td
                                                                className="text-center tdPrint"
                                                                style={{
                                                                    verticalAlign:
                                                                        'middle',
                                                                }}
                                                            >
                                                                {isCommentsTable
                                                                    ? data
                                                                          .comments[
                                                                          index
                                                                      ]
                                                                    : data
                                                                          .medicineDescription[
                                                                          index
                                                                      ]}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

CurrentWeekTable.propTypes = {
    data: PropTypes.object.isRequired,
    titleText: PropTypes.string.isRequired,
    ths: PropTypes.array.isRequired,
    isCommentsTable: PropTypes.bool,
    completeWeekDays: PropTypes.array.isRequired,
    locale: PropTypes.object.isRequired,
};

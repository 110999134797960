import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import FormFields from '../../../../components/form/formFields';
import { setLoading } from '../../../../store/actions/loadingActions';
import { deleteDevice } from '../../../../services/api/devices';
import history from '../../../../services/history';

MyForm.propTypes = {
    device: PropTypes.object.isRequired,
    fields: PropTypes.array.isRequired,
    texts: PropTypes.shape({
        successText: PropTypes.string.isRequired,
        deleteText: PropTypes.string.isRequired,
        cancelText: PropTypes.string.isRequired,
    }).isRequired,
};

export function MyForm({ device, fields, texts }) {
    const formRef = useRef(null);
    const dispatch = useDispatch();

    const handleSubmit = async (data) => {
        dispatch(setLoading(true));

        const response = await deleteDevice(device.id);

        dispatch(setLoading(false));

        if (response) {
            toast.success(texts.successText);
            history.push('/devices');
        }
    };

    useEffect(() => {
        formRef.current.reset();

        const initialData = {};

        fields.map((field) => {
            switch (field.props.name) {
                case 'purchased_at':
                    initialData[field.props.name] = device[
                        field.props.name
                    ]?.slice(0, 10);
                    break;
                case 'company_id':
                    initialData[field.props.name] = device.company_name;
                    break;
                default:
                    initialData[field.props.name] = device[field.props.name];
                    break;
            }
        });

        formRef.current.setData(initialData);
    }, []);

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <FormGroup row>
                <FormFields fields={fields} />
            </FormGroup>
            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button color="primary mr-2" type="submit">
                        {texts.deleteText}
                    </Button>
                    <Link to="/devices" className="btn btn-secondary">
                        {texts.cancelText}
                    </Link>
                </div>
            </FormGroup>
        </Form>
    );
}

import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import '../../styles/components/layout/content.css';

import Breadcrumbs from './breadcrumbs';

export function Content({ children, pageData, pageName }) {
    return (
        <main className="content">
            <Container fluid={true}>
                <Breadcrumbs crumbs={pageData.crumbs[pageName]} />
                {children}
            </Container>
        </main>
    );
}

Content.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
        .isRequired,
    pageData: PropTypes.object,
    pageName: PropTypes.string,
};

import React from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';

import '../../styles/components/table/table.css';

import TableHeaders from './tableHeaders';
import Td from './td';

export default function ResponsiveTable({ children, ...rest }) {
    const { className } = rest;

    return (
        <Table hover striped className={`responsiveTable ${className ?? ''}`}>
            {children}
        </Table>
    );
}

export { ResponsiveTable as Table, TableHeaders, Td };

ResponsiveTable.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node.isRequired]),
};

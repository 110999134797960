// import Input from '../../../components/inputForm/inputText';

export default {
    pt: {
        customer_admin: null,
        manager: {
            monthlyPaymentFrequencyText: '/mês',
            planSubscriptionDurationText: 'Duração de 30 dias',
            amountOfDevicesText: '5 dispositivos ativos',
            amountOfPatientsText: '5 pacientes ativos',
            lumusText: 'Lumus',
            userAlreadyHasSubscriptionText:
                'Sua empresa já possui uma assinatura desse tipo.',
            signSubscriptionText: 'Assinar plano',
            successText: 'Plano assinado com sucesso!',
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            monthlyPaymentFrequencyText: '/mo',
            planSubscriptionDurationText: 'Duration of 30 days',
            amountOfDevicesText: '5 active devices',
            amountOfPatientsText: '5 active patients',
            lumusText: 'Lumus',
            userAlreadyHasSubscriptionText:
                'Your company already has a subscription of this type.',
            signSubscriptionText: 'Sign plan',
            successText: 'Plan successfully signed!',
        },
        condor_admin: null,
    },
};

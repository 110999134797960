import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './searchAndAddContainer.module.css';

export function SearchAndAddContainer({
    plans,
    setFilteredPlans,
    searchByNameInput,
    addButtonLabel,
    addButtonTitle,
}) {
    const [nameFilter, setNameFilter] = useState('');

    function handleNameInputChange(e) {
        setNameFilter(e.target.value);
    }

    useEffect(() => {
        let tempFilteredPlans = [];

        if (nameFilter) {
            tempFilteredPlans = plans.filter((user) =>
                user.name.toUpperCase().includes(nameFilter.toUpperCase())
            );
        } else {
            tempFilteredPlans = plans;
        }

        setFilteredPlans(tempFilteredPlans);
    }, [nameFilter]);

    return (
        <Row>
            <Col xs="12">
                <div className={styles.searchAndAddContainer}>
                    <input
                        name="searchName"
                        placeholder={searchByNameInput}
                        title={searchByNameInput}
                        value={nameFilter}
                        onChange={handleNameInputChange}
                    />
                    <Link
                        to={{
                            pathname: '/plans/add',
                        }}
                        className="btn btn-primary ml-2"
                        title={addButtonTitle}
                    >
                        {addButtonLabel}
                    </Link>
                </div>
            </Col>
        </Row>
    );
}

SearchAndAddContainer.propTypes = {
    plans: PropTypes.array.isRequired,
    setFilteredPlans: PropTypes.func.isRequired,
    searchByNameInput: PropTypes.string.isRequired,
    addButtonLabel: PropTypes.string.isRequired,
    addButtonTitle: PropTypes.string.isRequired,
};

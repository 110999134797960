import Input from '../../../components/inputForm/inputText';
import Select from '../../../components/inputForm/inputSelect';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: {
                company: [
                    {
                        component: Input,
                        props: {
                            name: 'name',
                            type: 'text',
                            label: 'Nome',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-4',
                        hasLink: false,
                    },
                    {
                        component: Input,
                        props: {
                            name: 'user_name',
                            type: 'text',
                            label: 'Responsável',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-4',
                        hasLink: false,
                    },
                    {
                        component: Input,
                        props: {
                            name: 'email',
                            type: 'text',
                            label: 'e-Mail',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-4',
                        hasLink: false,
                    },
                ],
                subscription: [
                    {
                        component: Input,
                        props: {
                            name: 'price',
                            type: 'text',
                            label: 'Preço total',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'billing_date',
                            type: 'text',
                            label: 'Próxima data de cobrança',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'suspended_reason',
                            type: 'text',
                            label: 'Motivo da suspensão',
                            readOnly: true,
                        },
                        className: 'col-xs-12',
                    },
                ],
                subscriptionPlans: [
                    {
                        component: Select,
                        props: {
                            name: 'plan_id',
                            type: 'text',
                            label: 'Plano',
                            isDisabled: true,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'type',
                            type: 'text',
                            label: 'Tipo',
                            isDisabled: true,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'price',
                            type: 'text',
                            label: 'Preço',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'active',
                            type: 'text',
                            label: 'Ativa',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'suspended',
                            type: 'text',
                            label: 'Suspensa',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'expires_at',
                            type: 'date',
                            label: 'Data de expiração',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'action',
                            type: 'text',
                            label: 'Ação',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'days_to_extend',
                            type: 'number',
                            label: 'Dias para estender',
                            min: 0,
                            defaultValue: 0,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'new_expires_at',
                            type: 'date',
                            label: 'Nova data de expiração',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                coupon: [
                    {
                        component: Select,
                        props: {
                            name: 'coupon_id',
                            label: 'Nome do cupom',
                            options: [],
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'month_to_remove',
                            type: 'number',
                            label: 'Mês em que expira',
                            min: 1,
                            max: 12,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'year_to_remove',
                            type: 'number',
                            label: 'Ano em que expira',
                            max: 2999,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                ],
            },
            fidelityTexts: {
                monthly: 'Mensal',
                biannual: 'Semestral',
                annual: 'Anual',
            },
            companyText: 'Empresa',
            subscriptionText: 'Assinatura e cobrança',
            subscriptionPlansText: 'Planos na assinatura',
            couponsText: 'Cupons aplicados',
            couponNameText: 'Nome do cupom',
            expiryMonthText: 'Mês em que expira',
            expiryYearText: 'Ano em que expira',
            addCouponText: 'Adicionar cupom',
            deleteCouponText: 'Excluir cupom',
            applyCouponSuccessText: 'Cupom aplicado com sucesso!',
            updateCouponSuccessText: 'Cupom atualizado com sucesso!',
            removeCouponSuccessText: 'Cupom removido com sucesso!',
            editText: 'Salvar',
            cancelText: 'Cancelar',
            yesText: 'Sim',
            noText: 'Não',
            successText: 'Mudanças aplicadas com sucesso!',
            monthlyPaymentFrequencyText: '/mês',
            unlimitedActivePatientsText: 'Pacientes ativos ilimitados',
            activePatientsText: 'pacientes ativos',
            unlimitedActiveDevicesText: 'Dispositivos ativos ilimitados',
            activeDevicesText: 'dispositivos ativos',
            expiresAtText: 'Expira em',
            activeText: 'Ativa',
            suspendedText: 'Suspensa',
            expiredText: 'Expirada',
            attentionText: 'Atenção',
            closeText: 'Fechar',
            unsubscribeText: 'Cancelar assinatura',
            subscriptionTypeText: {
                sleep_diary: 'Diário de sono',
                lumus: 'Lumus',
            },
            actionOptionsTexts: {
                extend_trial: 'Estender trial',
                activate_trial: 'Ativar trial',
                activate: 'Ativar',
                suspend: 'Suspender',
            },
            goBackText: 'Voltar',
            suspendedReasonsTexts: {
                trial_expired: 'Assinatura trial finalizada.',
                invalid_payment_method: 'Falha com o método de pagamento.',
                invoice_expired: 'Fatura expirada.',
            },
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: {
                company: [
                    {
                        component: Input,
                        props: {
                            name: 'name',
                            type: 'text',
                            label: 'Name',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-4',
                        hasLink: false,
                    },
                    {
                        component: Input,
                        props: {
                            name: 'user_name',
                            type: 'text',
                            label: 'Manager',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-4',
                        hasLink: false,
                    },
                    {
                        component: Input,
                        props: {
                            name: 'email',
                            type: 'text',
                            label: 'e-Mail',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-4',
                        hasLink: false,
                    },
                ],
                subscription: [
                    {
                        component: Input,
                        props: {
                            name: 'price',
                            type: 'text',
                            label: 'Total price',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'billing_date',
                            type: 'text',
                            label: 'Next billing date',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'suspended_reason',
                            type: 'text',
                            label: 'Suspended reason',
                            readOnly: true,
                        },
                        className: 'col-xs-12',
                    },
                ],
                subscriptionPlans: [
                    {
                        component: Select,
                        props: {
                            name: 'plan_id',
                            type: 'text',
                            label: 'Plan',
                            isDisabled: true,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'type',
                            type: 'text',
                            label: 'Type',
                            isDisabled: true,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'price',
                            type: 'text',
                            label: 'Price',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'active',
                            type: 'text',
                            label: 'Active',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'suspended',
                            type: 'text',
                            label: 'Suspended',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'expires_at',
                            type: 'date',
                            label: 'Expiry date',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'action',
                            type: 'text',
                            label: 'Action',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'days_to_extend',
                            type: 'number',
                            label: 'Days to extend',
                            min: 0,
                            defaultValue: 0,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'new_expires_at',
                            type: 'date',
                            label: 'New expiration date',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                coupon: [
                    {
                        component: Select,
                        props: {
                            name: 'coupon_id',
                            label: 'Coupon name',
                            options: [],
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'month_to_remove',
                            type: 'number',
                            label: 'Month in which it expires',
                            min: 1,
                            max: 12,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'year_to_remove',
                            type: 'number',
                            label: 'Year in which it expires',
                            max: 2999,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                ],
            },
            fidelityTexts: {
                monthly: 'Monthly',
                biannual: 'Semestral',
                annual: 'Annual',
            },
            companyText: 'Comapny',
            subscriptionText: 'Subscription and billing',
            subscriptionPlansText: 'Plans in subscription',
            couponsText: 'Applied coupons',
            couponNameText: 'Coupon name',
            expiryMonthText: 'Month in which it expires',
            expiryYearText: 'Year in which it expires',
            addCouponText: 'Add coupon',
            deleteCouponText: 'Remove coupon',
            applyCouponSuccessText: 'Coupon succesfully applied!',
            updateCouponSuccessText: 'Coupon succesfully updated!',
            removeCouponSuccessText: 'Coupon succesfully removed!',
            editText: 'Save',
            cancelText: 'Cancel',
            yesText: 'Yes',
            noText: 'No',
            successText: 'Changes applied successfully!',
            monthlyPaymentFrequencyText: '/mo',
            unlimitedActivePatientsText: 'Unlimited active patients',
            activePatientsText: 'active patients',
            unlimitedActiveDevicesText: 'Unlimited active devices',
            activeDevicesText: 'active devices',
            expiresAtText: 'Expires at',
            activeText: 'Active',
            suspendedText: 'Suspended',
            expiredText: 'Expired',
            attentionText: 'Attention',
            closeText: 'Close',
            unsubscribeText: 'Unsubscribe',
            subscriptionTypeText: {
                sleep_diary: 'Sleep diary',
                lumus: 'Lumus',
            },
            actionOptionsTexts: {
                extend_trial: 'Extend trial',
                activate_trial: 'Activate trial',
                activate: 'Activate',
                suspend: 'Suspend',
            },
            goBackText: 'Go back',
            suspendedReasonsTexts: {
                trial_expired: 'Trial subscription expired.',
                invalid_payment_method: 'Payment method failed.',
                invoice_expired: 'Expired invoice.',
            },
        },
    },
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import lodash from 'lodash';

import '../../../../styles/pages/users/userReport/userPrintReport/userPrintReport.css';

import pageConfig from './page.config';
import history from '../../../../services/history';
import { setLoading } from '../../../../store/actions/loadingActions';
import { fetchUserPrintReport } from '../../../../services/api/users';
import { getPageNumbers } from '../../../../helpers/pageNumbers';
import { UserInfo } from './components/userInfo';
import { WeeklyEvolution } from './components/weeklyEvolution';
import { CurrentWeek } from './components/currentWeek';
import { Tables } from './components/tables';

export default function UserPrintReport() {
    const [user, setUser] = useState(null);
    const [reportData, setReportData] = useState(null);
    const [pageNumbers, setPageNumbers] = useState(null);
    const [weeksByChart, setWeeksByChart] = useState(null);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);
    const reportState = useSelector((state) => state.report);
    const { dateLocale } = useSelector((state) => state.locale);
    const isLoading = useSelector((state) => state.loading.isLoading);
    const currentUser = useSelector((state) => state.auth.currentUser);

    const pageData = pageConfig[lang][currentUser.permissions];

    const handleClick = () => {
        window.print();
    };

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            let cloneUser = lodash.cloneDeep(reportState.user);
            let cloneData = lodash.cloneDeep(reportState.reportData);

            if (cloneData?.weekReference?.data?.weekNumber !== null) {
                let data;

                if (reportState.minDate === reportState.maxDate) {
                    data = await fetchUserPrintReport(
                        cloneUser.id,
                        reportState.minDate,
                        null,
                        null,
                        reportState.daysInWeek
                    );
                } else {
                    data = await fetchUserPrintReport(
                        cloneUser.id,
                        null,
                        reportState.minDate,
                        reportState.maxDate,
                        reportState.daysInWeek
                    );
                }

                setWeeksByChart(data.weeklyEvolution.weeksByChart);

                setReportData({
                    ...cloneData,
                    weeklyEvolution: {
                        data: data.weeklyEvolution,
                    },
                    dailyEvolution: {
                        data: {
                            ...cloneData.dailyEvolution.data,
                            currentWeek: {
                                ...cloneData.dailyEvolution.data.currentWeek,
                                charts: data.dailyEvolution.charts,
                                commentsTable:
                                    data.dailyEvolution.commentsTable,
                                weekNumbers: data.dailyEvolution.weekNumbers,
                                weekReference:
                                    data.dailyEvolution.weekReference,
                            },
                        },
                    },
                });

                setUser(cloneUser);

                setPageNumbers(
                    getPageNumbers(
                        cloneData.weekReference.data.weekNumber,
                        reportState.daysInWeek
                    )
                );
            } else {
                let cloneUser = lodash.cloneDeep(reportState.user);
                let cloneData = lodash.cloneDeep(reportState.reportData);

                if (cloneData.weeklyEvolution.data !== null) {
                    const data = await fetchUserPrintReport(cloneUser.id);

                    setWeeksByChart(data?.weekEvolution?.weeksByChart);

                    setReportData({
                        ...cloneData,
                        weeklyEvolution: {
                            data: data?.weeklyEvolution,
                        },
                    });

                    setUser(cloneUser);

                    setPageNumbers(
                        getPageNumbers(cloneData.weekReference.data.weekNumber)
                    );
                } else {
                    history.push('/users');
                }
            }

            dispatch(setLoading(false));
        })();
    }, []);

    if (isLoading || user === null) {
        return <></>;
    }

    return (
        <>
            <div className="rowPrint no-print-divPrint">
                <div className="col-12Print text-center">
                    <h1 className="h1Print">{pageData.pageNameText}</h1>
                </div>
                <div className="col-12Print text-center">
                    <Button color="primary" onClick={handleClick}>
                        {pageData.printButtonText}
                    </Button>
                </div>
            </div>

            <UserInfo
                user={user}
                data={reportData.dailyEvolution.data.currentWeek}
                textData={pageData.userInfo}
                locale={dateLocale}
                pageNumbers={pageNumbers.userInfo}
            />

            <WeeklyEvolution
                data={reportData.weeklyEvolution.data}
                textData={pageData.weeklyEvolution}
                pageNumbers={pageNumbers.weeklyEvolution}
                weeksByChart={weeksByChart}
            />

            <CurrentWeek
                data={reportData.dailyEvolution.data.currentWeek}
                textData={pageData.currentWeek}
                pageNumbers={pageNumbers.currentWeek}
                locale={dateLocale}
                completeWeekDays={
                    reportData.dailyEvolution.data.completeWeekDays[
                        reportData.dailyEvolution.data.completeWeekDays.length -
                            1
                    ]
                }
            />

            <Tables
                data={reportData.averageTable.data}
                textData={pageData.tables}
                pageNumbers={pageNumbers.tables}
            />
        </>
    );
}

UserPrintReport.propTypes = {};

import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import FormFields from '../../../../components/form/formFields';
import { setLoading } from '../../../../store/actions/loadingActions';
import { validateForm } from '../../../../helpers/formValidator';
import { deletePlan } from '../../../../services/api/plans';
import history from '../../../../services/history';

export function DeletePlanForm({ plan, fields, schema, texts, lang }) {
    const formRef = useRef(null);
    const dispatch = useDispatch();

    const {
        deleteSuccessText,
        deleteButtonText,
        cancelButtonText,
        intervalTypeOptions,
        payableWithOptions,
        yesText,
        noText,
        planTypes,
    } = texts;

    const handleSubmit = async (data) => {
        const { isValid, errors } = await validateForm(data, schema);

        if (isValid) {
            formRef.current.setErrors(errors);

            dispatch(setLoading(true));

            const response = await deletePlan(plan.id);

            dispatch(setLoading(false));

            if (response) {
                toast.success(deleteSuccessText);
                history.push('/plans');
            }
        } else {
            formRef.current.setErrors(errors);
        }
    };

    useEffect(() => {
        formRef.current.reset();

        const initialData = {};

        fields.map((field) => {
            switch (field.props.name) {
                case 'interval_type':
                    initialData[field.props.name] =
                        intervalTypeOptions[plan.interval_type];
                    break;
                case 'payable_with':
                    initialData[field.props.name] =
                        payableWithOptions[plan.payable_with];
                    break;
                case 'active':
                case 'visible':
                    initialData[field.props.name] = plan[field.props.name]
                        ? yesText
                        : noText;
                    break;
                case 'type':
                    initialData[field.props.name] = planTypes[plan?.type];
                    break;
                default:
                    initialData[field.props.name] = plan[field.props.name];
                    break;
            }
        });

        formRef.current.setData(initialData);
    }, [lang]);

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <FormGroup row>
                <FormFields fields={fields} />
            </FormGroup>
            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button color="primary mr-2" type="submit">
                        {deleteButtonText}
                    </Button>
                    <Link to="/plans" className="btn btn-secondary">
                        {cancelButtonText}
                    </Link>
                </div>
            </FormGroup>
        </Form>
    );
}

DeletePlanForm.propTypes = {
    plan: PropTypes.object.isRequired,
    fields: PropTypes.array.isRequired,
    schema: PropTypes.object.isRequired,
    texts: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
};

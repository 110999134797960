import * as Yup from 'yup';

export default {
    pt: {
        schema: Yup.object().shape({
            company_id: Yup.number()
                .transform((curr, orig) => (orig === '' ? undefined : curr))
                .required('Campo obrigatório'),
            user_id: Yup.number()
                .transform((curr, orig) => (orig === '' ? undefined : curr))
                .required('Campo obrigatório'),
            password: Yup.string().required('Campo obrigatório'),
        }),
    },
    en: {
        schema: Yup.object().shape({
            company_id: Yup.number()
                .transform((curr, orig) => (orig === '' ? undefined : curr))
                .required('Required field'),
            user_id: Yup.number()
                .transform((curr, orig) => (orig === '' ? undefined : curr))
                .required('Required fieldd'),
            password: Yup.string().required('Required field'),
        }),
    },
};

export const stepsOptions = {
    pt: {
        report: [
            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'reportPage',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Página do relatório do paciente!',
                text: [
                    `Está é a pagina do relatório do paciente.
                Nela você encontrará análises e informações detalhadas dos dados coletados deste paciente.`,
                ],
            },

            {
                arrow: true,
                attachTo: {
                    element: '.tab-pane.calendarTabPane',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'calendar',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Calendário',
                text: [
                    `Aqui é possível acompanhar o preenchimento do diário do seu paciente!`,
                ],
            },

            {
                arrow: true,
                attachTo: {
                    element: '.weeklyEvolutionLink',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        action() {
                            let link =
                                document.getElementsByClassName(
                                    'calendarLink'
                                )[0];
                            link.click();
                            return this.back();
                        },
                        secondary: true,
                        text: 'Voltar',
                    },
                    {
                        action() {
                            let link = document.getElementsByClassName(
                                'weeklyEvolutionLink'
                            )[0];
                            link.click();
                            return this.next();
                        },
                        text: 'Próximo',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'weeklyEvolutionLink',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Aba de Evolução Semanal',
                text: [
                    `Clicando aqui, é possível acessar a aba de evolução semanal.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.tab-pane.weeklyEvolutionTabPane',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'weeklyEvolution',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Evolução Semanal',
                text: [
                    `Aqui você encontra um gráfico com média semanal dos principais parâmetros do sono.`,
                ],
            },

            {
                arrow: true,
                attachTo: {
                    element: '.currentWeekLink',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        action() {
                            let link = document.getElementsByClassName(
                                'weeklyEvolutionLink'
                            )[0];
                            link.click();
                            return this.back();
                        },
                        secondary: true,
                        text: 'Voltar',
                    },
                    {
                        action() {
                            let link =
                                document.getElementsByClassName(
                                    'currentWeekLink'
                                )[0];
                            link.click();
                            return this.next();
                        },
                        text: 'Próximo',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'currentWeekLink',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Aba de Evolução Diária',
                text: [
                    `Clicando aqui, é possível acessar a aba de Evolução Diária.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.tab-pane.currentWeekTabPane',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'currentWeek',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Evolução Diária',
                text: [
                    `Aqui você encontra gráficos de cada semana, com os principais parâmetros do sono.`,
                ],
            },

            {
                arrow: true,
                attachTo: {
                    element: '.tablesLink',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        action() {
                            let link =
                                document.getElementsByClassName(
                                    'currentWeekLink'
                                )[0];
                            link.click();
                            return this.back();
                        },
                        secondary: true,
                        text: 'Voltar',
                    },
                    {
                        action() {
                            let link =
                                document.getElementsByClassName(
                                    'tablesLink'
                                )[0];
                            link.click();
                            return this.next();
                        },
                        text: 'Próximo',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'tablesLink',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Aba de Tabelas',
                text: [`Clicando aqui, é possível acessar a aba de Tabelas.`],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.tab-pane.tablesTabPane',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'tables',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Tabelas',
                text: [
                    `Aqui você encontra uma tabela com média semanal dos principais parâmetros do sono.`,
                ],
            },

            {
                arrow: true,
                attachTo: {
                    element: '.editDataLink',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        action() {
                            let link =
                                document.getElementsByClassName(
                                    'tablesLink'
                                )[0];
                            link.click();
                            return this.back();
                        },
                        secondary: true,
                        text: 'Voltar',
                    },
                    {
                        action() {
                            let link =
                                document.getElementsByClassName(
                                    'editDataLink'
                                )[0];
                            link.click();
                            return this.next();
                        },
                        text: 'Próximo',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'editDataLink',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Aba de Editar Dados',
                text: [
                    `Clicando aqui, é possível acessar a aba de Editar Dados.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.tab-pane.editDataTabPane',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'editTable',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Editar Dados',
                text: [
                    `Aqui é possível visualizar e editar dados preenchidos pelo paciente.`,
                ],
            },

            {
                arrow: true,
                attachTo: {
                    element: '.btn[name="periodSelection"]',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        action() {
                            let link =
                                document.getElementsByClassName(
                                    'editDataLink'
                                )[0];
                            link.click();
                            return this.back();
                        },
                        secondary: true,
                        text: 'Voltar',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'periodSelectionButton',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Seleção de período',
                text: [
                    `Clicando nesse botão, você seleciona o período de dados do relatório do paciente.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.btn[name="print"]',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'printButton',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Página de Impressão',
                text: [
                    `Clicando nesse botão, você irá para a página de impressão do relatório do paciente.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.btn[name="rawData"]',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'exportRawDataButton',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Exportar Dados Brutos',
                text: [
                    `Clicando nesse botão, você irá exportar os dados brutos preenchidos pelo paciente, no formato .csv.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.btn[name="tablesData"]',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'exportTablesDataButton',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Exportar Tabelas',
                text: [
                    `Clicando nesse botão, você irá exportar os dados da aba de Tabelas, no formato .csv.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.btn[name="decimalsTablesData"]',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'exportDecimalsTablesDataButton',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Exportar Tabelas Decimais',
                text: [
                    `Clicando nesse botão, você irá exportar os dados da aba de Tabelas,
                    convertidos para número decimais, no formato .csv.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.btn[name="customQuestionsData"]',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'exportCustomQuestionsDataButton',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Export Custom Questions',
                text: [
                    `Clicando nesse botão, você irá exportar os dados preenchidos pelo paciente nas questões criadas
                    em um diário personalizado, no formato .csv.`,
                ],
            },

            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        text: 'Finalizar tutorial',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'finishTutorial',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Agora é a sua vez!',
                text: [
                    `Pronto. Agora é sua vez.
                Adicione seus pacientes e veja o poder do nosso sistema de diário de sono!`,
                ],
            },
        ],
    },
    en: {
        report: [
            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'reportPage',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Patient report Page!',
                text: [
                    `This is the patient report page.
                    In it, you will find analysis and detailed information on the data collected from this patient.`,
                ],
            },

            {
                arrow: true,
                attachTo: {
                    element: '.tab-pane.calendarTabPane',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'calendar',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Calendar',
                text: [
                    `Here you can follow the completion of your patient's diary!`,
                ],
            },

            {
                arrow: true,
                attachTo: {
                    element: '.weeklyEvolutionLink',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        action() {
                            let link =
                                document.getElementsByClassName(
                                    'calendarLink'
                                )[0];
                            link.click();
                            return this.back();
                        },
                        secondary: true,
                        text: 'Back',
                    },
                    {
                        action() {
                            let link = document.getElementsByClassName(
                                'weeklyEvolutionLink'
                            )[0];
                            link.click();
                            return this.next();
                        },
                        text: 'Next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'weeklyEvolutionLink',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Weekly Evolution Tab',
                text: [`Click here to access the weekly evolution tab.`],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.tab-pane.weeklyEvolutionTabPane',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'weeklyEvolution',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Weekly Evolution',
                text: [
                    `Find a graph with a weekly average of the main sleep parameters here.`,
                ],
            },

            {
                arrow: true,
                attachTo: {
                    element: '.currentWeekLink',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        action() {
                            let link = document.getElementsByClassName(
                                'weeklyEvolutionLink'
                            )[0];
                            link.click();
                            return this.back();
                        },
                        secondary: true,
                        text: 'Back',
                    },
                    {
                        action() {
                            let link =
                                document.getElementsByClassName(
                                    'currentWeekLink'
                                )[0];
                            link.click();
                            return this.next();
                        },
                        text: 'Next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'currentWeekLink',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Daily Evolution Tab',
                text: [`Click here to access the Daily Evolution tab.`],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.tab-pane.currentWeekTabPane',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'currentWeek',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Daily Evolution',
                text: [
                    `Here you can find graphs for each week, with the main sleep parameters.`,
                ],
            },

            {
                arrow: true,
                attachTo: {
                    element: '.tablesLink',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        action() {
                            let link =
                                document.getElementsByClassName(
                                    'currentWeekLink'
                                )[0];
                            link.click();
                            return this.back();
                        },
                        secondary: true,
                        text: 'Back',
                    },
                    {
                        action() {
                            let link =
                                document.getElementsByClassName(
                                    'tablesLink'
                                )[0];
                            link.click();
                            return this.next();
                        },
                        text: 'Next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'tablesLink',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Tables Tab',
                text: [`Click here to access the Tables tab.`],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.tab-pane.tablesTabPane',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'tables',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Tables',
                text: [
                    `Find a table with a weekly average of the main sleep parameters here.`,
                ],
            },

            {
                arrow: true,
                attachTo: {
                    element: '.editDataLink',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        action() {
                            let link =
                                document.getElementsByClassName(
                                    'tablesLink'
                                )[0];
                            link.click();
                            return this.back();
                        },
                        secondary: true,
                        text: 'Back',
                    },
                    {
                        action() {
                            let link =
                                document.getElementsByClassName(
                                    'editDataLink'
                                )[0];
                            link.click();
                            return this.next();
                        },
                        text: 'Next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'editDataLink',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Edit Data Tab',
                text: [`Click here to access the Edit Data tab.`],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.tab-pane.editDataTabPane',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'editTable',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Edit Data',
                text: [`View and edit data filled in by the patient here.`],
            },

            {
                arrow: true,
                attachTo: {
                    element: '.btn[name="periodSelection"]',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        action() {
                            let link =
                                document.getElementsByClassName(
                                    'editDataLink'
                                )[0];
                            link.click();
                            return this.back();
                        },
                        secondary: true,
                        text: 'Back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'periodSelectionButton',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Period selection',
                text: [
                    `Click on this button to select the period of the patient's report.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.btn[name="print"]',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'printButton',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Print Page',
                text: [
                    `Click on this button to go to the patient report print page.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.btn[name="rawData"]',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'exportRawDataButton',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Export Raw Data',
                text: [
                    `Click on this button to export the raw data filled in by the patient in .csv format.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.btn[name="tablesData"]',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'exportTablesDataButton',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Export Tables',
                text: [
                    `Click on this button to export the data from the Tables tab, in .csv format.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.btn[name="decimalsTablesData"]',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'exportDecimalsTablesDataButton',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Export Decimal Tables',
                text: [
                    `Click on this button to export the data from the Tables tab,
                    converted to decimal numbers, in .csv format.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.btn[name="customQuestionsData"]',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'exportCustomQuestionsDataButton',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Export Custom Questions',
                text: [
                    `Click this button to export the data filled in by the patient in questions created
                    in a custom diary, in .csv format.`,
                ],
            },

            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 150);
                    });
                },
                buttons: [
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        text: 'Finish tutorial',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'exportDecimalsTablesDataButton',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: `Now it's your turn!`,
                text: [
                    `Now it is your turn. Add your patients and see the power of our sleep diary system!`,
                ],
            },
        ],
    },
};

export const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
            enabled: false,
        },
    },
    useModalOverlay: true,
};

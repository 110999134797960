export default {
    pt: {
        default: {
            label: 'Erro!',
            text: 'Algum erro inesperado ocorreu!',
        },
        unauthorized: {
            label: 'Não autorizado!',
            text: 'Você não possui permissões necessárias para visualizar a página!',
        },
        subscriptionExpired: {
            label: 'Assinatura expirada!',
            text: `Você não possui uma assinatura ativa! Para continuar utilizando o sistema`,
            linkText: `assine um plano`,
            link: '/account/my_account',
            reasonText: 'Motivo',
            suspendedReasonsTexts: {
                trial_expired: 'Assinatura trial finalizada.',
                invalid_payment_method: 'Falha com o método de pagamento.',
                invoice_expired: 'Fatura expirada.',
            },
        },
    },
    en: {
        default: {
            label: 'Error!',
            text: 'Some unexpected error has occurred!',
        },
        unauthorized: {
            label: 'Not authorized!',
            text: `You don't have the necessary permissions to view the page!`,
        },
        subscriptionExpired: {
            label: 'Subscription expired!',
            text: `You do not have an active subscription! To continue using the system`,
            linkText: `sign a new plan`,
            link: '/account/my_account',
            reasonText: 'Reason',
            suspendedReasonsTexts: {
                trial_expired: 'Trial subscription expired.',
                invalid_payment_method: 'Payment method failed.',
                invoice_expired: 'Expired invoice.',
            },
        },
    },
};

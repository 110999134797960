import React from 'react';
import { useSelector } from 'react-redux';
import { parseISO, format } from 'date-fns';
import { ptBR, enUS } from 'date-fns/locale';
import PropTypes from 'prop-types';

function WeekDays({ lastNDays, limit, locale }) {
    const lang = useSelector((state) => state.lang.lang);

    return lastNDays.date.map((day, index) => {
        if (limit) {
            if (limit == 'smaller') {
                return (
                    index < 5 && (
                        <span key={crypto.randomUUID()} className="weekDays">
                            {format(parseISO(day), 'eeeeee', {
                                locale: lang === 'pt' ? ptBR : enUS,
                            })}
                        </span>
                    )
                );
            } else {
                return (
                    index >= 5 && (
                        <span key={crypto.randomUUID()} className="weekDays">
                            {format(parseISO(day), 'eeeeee', {
                                locale: lang === 'pt' ? ptBR : enUS,
                            })}
                        </span>
                    )
                );
            }
        } else {
            return (
                <span key={crypto.randomUUID()} className="weekDays">
                    {format(parseISO(day), 'eeeeee', {
                        locale: lang === 'pt' ? ptBR : enUS,
                    })}
                </span>
            );
        }
    });
}

WeekDays.propTypes = {
    lastNDays: PropTypes.object.isRequired,
    limit: PropTypes.string,
    locale: PropTypes.object,
};

export default WeekDays;

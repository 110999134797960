import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';

CancelSubscriptionModal.propTypes = {
    cancelText: PropTypes.string.isRequired,
    attentionText: PropTypes.string.isRequired,
    warningCancelText: PropTypes.string.isRequired,
    closeText: PropTypes.string.isRequired,
    unsubscribeText: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
};

export function CancelSubscriptionModal({
    cancelText,
    attentionText,
    warningCancelText,
    closeText,
    unsubscribeText,
    onCancel,
    disabled,
}) {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    function handleClick() {
        onCancel();

        toggle();
    }

    return (
        <>
            <Button
                type="button"
                color="danger"
                onClick={toggle}
                title={cancelText}
                disabled={disabled}
            >
                {cancelText}
            </Button>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>{attentionText}</ModalHeader>
                <ModalBody>{warningCancelText}</ModalBody>
                <ModalFooter>
                    <Button type="button" color="primary" onClick={handleClick}>
                        {unsubscribeText}
                    </Button>

                    <Button type="button" color="secondary" onClick={toggle}>
                        {closeText}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

import Input from '../../../components/inputForm/inputText';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Nome',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'share',
                        type: 'text',
                        label: 'Compartilha pacientes?',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'descriptor.saveEmail',
                        type: 'text',
                        label: 'Salva e-Mail de pacientes?',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'descriptor.defaultLanguage',
                        type: 'text',
                        label: 'Idioma padrão',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-2',
                },
                {
                    component: Input,
                    props: {
                        name: 'descriptor.minPasswordLength',
                        type: 'number',
                        label: 'Tamanho mínimo de senha',
                        min: 8,
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            successText: 'Empresa excluída com sucesso!',
            deleteText: 'Excluir',
            cancelText: 'Cancelar',
            yesText: 'Sim',
            noText: 'Não',
            notDefinedText: 'Não definido',
            defaultLanguageOptions: {
                pt: { label: 'Português', value: 'pt' },
                en: { label: 'Inglês', value: 'en' },
            },
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Name',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'share',
                        label: 'Share patients?',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-4',
                },
                {
                    component: Input,
                    props: {
                        name: 'descriptor.saveEmail',
                        type: 'text',
                        label: 'Save patients e-Mails?',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'descriptor.defaultLanguage',
                        type: 'text',
                        label: 'Default language',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-2 settingsRadioContainer',
                },
                {
                    component: Input,
                    props: {
                        name: 'descriptor.minPasswordLength',
                        type: 'number',
                        label: 'Minimum password length',
                        min: 8,
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            successText: 'Company successfully deleted!',
            deleteText: 'Delete',
            cancelText: 'Cancel',
            yesText: 'Yes',
            noText: 'No',
            notDefinedText: 'Not defined',
            defaultLanguageOptions: {
                pt: { label: 'Portuguese', value: 'pt' },
                en: { label: 'English', value: 'en' },
            },
        },
    },
};

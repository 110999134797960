import axios from 'axios';

import { successResponse, failureResponse } from './validations';

const api = axios.create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.response.use(successResponse, failureResponse);

export default api;

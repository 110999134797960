import React, { Component } from 'react';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';

import {
    mapToCssModules,
    omit,
    pick,
    TransitionTimeouts,
    TransitionPropTypeKeys,
    TransitionStatuses,
} from './utils';

const propTypes = {
    ...Transition.propTypes,
    horizontal: PropTypes.bool,
    isOpen: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.node,
    navbar: PropTypes.bool,
    cssModule: PropTypes.object,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
        PropTypes.object,
    ]),
};

const defaultProps = {
    ...Transition.defaultProps,
    horizontal: false,
    isOpen: false,
    appear: false,
    enter: true,
    exit: true,
    timeout: TransitionTimeouts.Collapse,
};

const transitionStatusToClassHash = {
    [TransitionStatuses.ENTERING]: 'collapsing',
    [TransitionStatuses.ENTERED]: 'collapse show',
    [TransitionStatuses.EXITING]: 'collapsing',
    [TransitionStatuses.EXITED]: 'collapse',
};

function getTransitionClass(status) {
    return transitionStatusToClassHash[status] || 'collapse';
}

export default class Collapse extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dimension: null,
        };

        ['onEntering', 'onEntered', 'onExit', 'onExiting', 'onExited'].forEach(
            (name) => {
                this[name] = this[name].bind(this);
            }
        );
    }

    getDimension(node) {
        return this.props.horizontal ? node.scrollWidth : node.scrollHeight;
    }

    onEntering(node, isAppearing) {
        this.setState({ dimension: this.getDimension(node) });
        this.props.onEntering(node, isAppearing);
    }

    onEntered(node, isAppearing) {
        this.setState({ dimension: null });
        this.props.onEntered(node, isAppearing);
    }

    onExit(node) {
        this.setState({ dimension: this.getDimension(node) });
        this.props.onExit(node);
    }

    onExiting(node) {
        // getting this variable triggers a reflow
        const _unused = this.getDimension(node); // eslint-disable-line no-unused-vars
        this.setState({ dimension: 0 });
        this.props.onExiting(node);
    }

    onExited(node) {
        this.setState({ dimension: null });
        this.props.onExited(node);
    }

    render() {
        const {
            horizontal,
            isOpen,
            className,
            navbar,
            cssModule,
            children,
            innerRef,
            ...otherProps
        } = this.props;

        const { dimension } = this.state;

        const transitionProps = pick(otherProps, TransitionPropTypeKeys);
        const childProps = omit(otherProps, TransitionPropTypeKeys);
        return (
            <Transition
                {...transitionProps}
                in={isOpen}
                onEntering={this.onEntering}
                onEntered={this.onEntered}
                onExit={this.onExit}
                onExiting={this.onExiting}
                onExited={this.onExited}
            >
                {(status) => {
                    let collapseClass = getTransitionClass(status);
                    const classes = mapToCssModules(
                        `${className}
                        ${horizontal ? 'collapse-horizontal' : ''}
                        ${collapseClass}
                        ${navbar ? 'navbar-collapse' : ''}
                        `,
                        cssModule
                    );
                    const style =
                        dimension === null
                            ? null
                            : { [horizontal ? 'width' : 'height']: dimension };
                    return (
                        <div
                            {...childProps}
                            style={{ ...childProps.style, ...style }}
                            className={classes}
                            ref={this.props.innerRef}
                        >
                            {children}
                        </div>
                    );
                }}
            </Transition>
        );
    }
}

Collapse.propTypes = propTypes;
Collapse.defaultProps = defaultProps;

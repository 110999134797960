import React from 'react';
import PropTypes from 'prop-types';

export function TitleSection({ title }) {
    return (
        <>
            <div className="contentPrint" style={{ paddingBottom: '15px' }}>
                <div className="subtitlePrint col-12Print">
                    <h3 className="h3Print">{title}</h3>
                </div>
            </div>
        </>
    );
}

TitleSection.propTypes = {
    title: PropTypes.string.isRequired,
};

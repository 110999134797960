import FileInput from '../../../components/inputForm/inputFile';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: [
                {
                    component: FileInput,
                    props: {
                        name: 'file',
                        label: 'Arquivo <b style="color: darkred">*</b>',
                        label2: 'Escolher arquivo...',
                        noselectedfiletext: 'Nenhum ficheiro selecionado',
                        accept: '.csv',
                        placeholder: 'Arquivo',
                    },
                    className: 'col-xs-12 col-md-5',
                },
            ],
            successText: 'Dispositivo adicionado com sucesso!',
            addText: 'Adicionar',
            cancelText: 'Cancelar',
            requiredFieldText:
                '<b style="color: darkred">*</b> Campos obrigatórios!',
            downloadText: 'Download',
            downloadTemplateText:
                'Faça o download do arquivo com o formato para adicionar dispositivos!',
            requiredFieldInfoText: `Dentro do arquivo há colunas <b>obrigatórias (*)</b> e não obrigatórias a serem preenchidas.
                As colunas não obrigatórias podem ser deixadas em branco.`,
            dateFormatInfoText: `Para as datas, utilizar o formato <b>aaaa-mm-dd</b>.`,
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: [
                {
                    component: FileInput,
                    props: {
                        name: 'file',
                        label: 'File <b style="color: darkred">*</b>',
                        label2: 'Choose file...',
                        noselectedfiletext:
                            'No files currently selected for upload',
                        accept: '.csv',
                        placeholder: 'File',
                    },
                    className: 'col-xs-12 col-md-5',
                },
            ],
            successText: 'Device successfully added!',
            addText: 'Add',
            cancelText: 'Cancel',
            requiredFieldText:
                '<b style="color: darkred">*</b> Required fields!',
            downloadText: 'Download',
            downloadTemplateText:
                'Download the file with the format to add devices!',
            requiredFieldInfoText: `Inside the file there are <b>required (*)</b> and not required columns to be filled.
                        The not required columns can be left blank.`,
            dateFormatInfoText: `For dates, use the format <b>yyyy-mm-dd</b>.`,
        },
    },
};

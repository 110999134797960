import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import FormFields from '../../../../components/form/formFields';
import { setLoading } from '../../../../store/actions/loadingActions';
import { validateForm } from '../../../../helpers/formValidator';
import { editCoupon } from '../../../../services/api/coupons';
import history from '../../../../services/history';

const allowedCurrencies = ['BRL', 'USD'];

export function EditCouponForm({ coupon, fields, schemas, texts, lang }) {
    const formRef = useRef(null);

    const dispatch = useDispatch();

    const { percentOffSchema, amountOffSchema } = schemas;

    async function handleSubmit(data) {
        const { isValid, errors } = await validateForm(
            data,
            coupon?.percentOff ? percentOffSchema : amountOffSchema
        );

        if (
            !formRef.current.getFieldValue('currency[0]') &&
            !formRef.current.getFieldValue('currency[1]')
        ) {
            formRef.current.setErrors(errors);
            formRef.current.setFieldError(
                'currency[1]',
                texts.selectCurrencyErrorText
            );
            return;
        }

        if (isValid) {
            formRef.current.setErrors(errors);

            dispatch(setLoading(true));

            const sendData = {
                name: data.name,
                percent_off: data?.percentOff ? Number(data.percentOff) : null,
                amount_off: data?.amountOff ? Number(data.amountOff) : null,
                duration: Number(data.duration),
                currency: allowedCurrencies.filter(
                    (_, index) => data.currency[index]
                ),
            };

            const response = await editCoupon(coupon.id, sendData);

            dispatch(setLoading(false));

            if (response) {
                toast.success(texts.editSuccessText);
                history.push('/coupons');
            }
        } else {
            formRef.current.setErrors(errors);
        }
    }

    useEffect(() => {
        formRef.current.reset();

        let initialData = fields.commonFields.reduce(
            (acc, { props: { name } }) => ({
                ...acc,
                [name]: coupon[name],
            }),
            {}
        );

        initialData = fields.percentFields.reduce(
            (acc, { props: { name } }) => ({
                ...acc,
                [name]: coupon[name],
            }),
            initialData
        );

        initialData = fields.amountFields.reduce(
            (acc, { props: { name } }) => ({
                ...acc,
                [name]: coupon[name],
            }),
            initialData
        );

        formRef.current.setData(initialData);
        formRef.current.setFieldValue(
            'currency[0]',
            coupon.currency.find((element) => element === 'BRL')
        );
        formRef.current.setFieldValue(
            'currency[1]',
            coupon.currency.find((element) => element === 'USD')
        );
        formRef.current.setFieldValue(
            'is_percent',
            coupon.percentOff ? texts.percentageText : texts.amountText
        );
    }, [lang]);

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <FormGroup row>
                <FormFields fields={fields.commonFields} />
            </FormGroup>
            {coupon?.percentOff ? (
                <FormGroup row>
                    <FormFields fields={fields.percentFields} />
                </FormGroup>
            ) : (
                <FormGroup row>
                    <FormFields fields={fields.amountFields} />
                </FormGroup>
            )}
            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-12">
                    <span>{texts.currencyText}</span>
                </FormGroup>
            </FormGroup>
            <FormGroup row>
                <FormFields fields={fields.currencyFields} />
            </FormGroup>
            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button color="primary mr-2" type="submit">
                        {texts.editText}
                    </Button>
                    <Link to="/coupons" className="btn btn-secondary">
                        {texts.cancelText}
                    </Link>
                </div>
            </FormGroup>
        </Form>
    );
}

EditCouponForm.propTypes = {
    coupon: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    schemas: PropTypes.object.isRequired,
    texts: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
};

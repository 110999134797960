import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import lodash from 'lodash';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import { setLoading } from '../../../store/actions/loadingActions';
import { setLocale } from '../../../store/actions/localeActions';
import { updateCurrentUserSubscriptionRequest } from '../../../store/actions/authActions';
import { fetchSubscriptionData } from '../../../services/api/account';
import { MyAccountForm } from './components/form';
import history from '../../../services/history';

MyAccount.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function MyAccount({ currentUser }) {
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [pendingInvoice, setPendingInvoice] = useState(null);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);
    const isLoading = useSelector((state) => state.loading.isLoading);
    const { dateLocale, currencyLocale, currencyFormat } = useSelector(
        (state) => state.locale
    );

    const pageData = pageConfig[lang][currentUser.permissions];

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            const data = await fetchSubscriptionData();

            let tempCurrentUser = data?.current_user ?? currentUser;

            if (!lodash.isEqual(tempCurrentUser, currentUser)) {
                dispatch(
                    updateCurrentUserSubscriptionRequest({
                        currentUser: tempCurrentUser,
                    })
                );
            }

            // if user is in trial, show currency formats from global state
            // if user already has a subscription, show currency formats from the subscription
            let iuguId = null;
            let stripeId = null;

            tempCurrentUser?.subscription?.plans?.forEach(
                (subscriptionPlan) => {
                    if (subscriptionPlan.iugu_id !== null) {
                        iuguId = subscriptionPlan.iugu_id;
                    }
                    if (subscriptionPlan.stripe_id !== null) {
                        stripeId = subscriptionPlan.stripe_id;
                    }
                }
            );

            if (iuguId === null && stripeId === null) {
                setCurrency({
                    locale: currencyLocale,
                    format: currencyFormat,
                });
            } else {
                setCurrency({
                    locale: tempCurrentUser?.subscription?.locale,
                    format:
                        tempCurrentUser?.subscription?.locale === 'pt-BR'
                            ? 'BRL'
                            : 'USD',
                });

                dispatch(
                    setLocale({
                        dateLocale: dateLocale,
                        currencyLocale: tempCurrentUser?.subscription?.locale,
                        currencyFormat:
                            tempCurrentUser?.subscription?.locale === 'pt-BR'
                                ? 'BRL'
                                : 'USD',
                    })
                );
            }

            setPaymentMethod(data?.payment_method);
            setPendingInvoice(data?.pending_invoice);

            dispatch(setLoading(false));
        })();
    }, [history?.location?.key]);

    if (isLoading || pageData === null || currency === null) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <MyAccountForm
                    currentUser={currentUser}
                    paymentMethod={paymentMethod}
                    currency={currency}
                    pendingInvoice={pendingInvoice}
                    texts={{
                        subscriptionAndBillingText:
                            pageData.subscriptionAndBillingText,
                        emailText: pageData.emailText,
                        changeEmailText: pageData.changeEmailText,
                        cardText: pageData.cardText,
                        managePaymentInformationText:
                            pageData.managePaymentInformationText,
                        priceText: pageData.priceText,
                        subscriptionExpiresOnText:
                            pageData.subscriptionExpiresOnText,
                        nextBillingDateText: pageData.nextBillingDateText,
                        plansText: pageData.plansText,
                        monthlyPaymentFrequencyText:
                            pageData.monthlyPaymentFrequencyText,
                        fidelityInputText: pageData.fidelityInputText,
                        unlimitedActivePatientsText:
                            pageData.unlimitedActivePatientsText,
                        activePatientsText: pageData.activePatientsText,
                        unlimitedActiveDevicesText:
                            pageData.unlimitedActiveDevicesText,
                        activeDevicesText: pageData.activeDevicesText,
                        expiresAtText: pageData.expiresAtText,
                        activeText: pageData.activeText,
                        suspendedText: pageData.suspendedText,
                        expiredText: pageData.expiredText,
                        cancelText: pageData.cancelText,
                        attentionText: pageData.attentionText,
                        closeText: pageData.closeText,
                        unsubscribeText: pageData.unsubscribeText,
                        subscriptionTypeText: pageData.subscriptionTypeText,
                        cancelSuccessText: pageData.cancelSuccessText,
                        wrongFormatText: pageData.wrongFormatText,
                        testTrialDiaryPlanText: pageData.testTrialDiaryPlanText,
                        testTrialLumusPlanText: pageData.testTrialLumusPlanText,
                        signDiaryPlanText: pageData.signDiaryPlanText,
                        changeDiaryPlanText: pageData.changeDiaryPlanText,
                        signLumusPlanText: pageData.signLumusPlanText,
                        changeLumusPlanText: pageData.changeLumusPlanText,
                        payPendingInvoiceText: pageData.payPendingInvoiceText,
                    }}
                />
            </CardBody>
        </Card>
    );
}

import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, Card, CardBody, FormGroup } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ptBR, enUS } from 'date-fns/locale';

import styles from './styles.module.scss';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { validateForm } from '../../helpers/formValidator';
import { getPublicIp } from '../../helpers/getPublicIp';
import { fetchCountry } from '../../services/api/ip';
import { loginRequest } from '../../store/actions/authActions';
import { setLang } from '../../store/actions/langActions';
import { setLocale } from '../../store/actions/localeActions';
import FormRow from '../../components/form/formRow';
import Input from '../../components/inputForm/inputText';

export default function Login() {
    const formRef = useRef(null);

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.auth.loading);

    const lang = useSelector((state) => state.lang.lang);

    const [activeLanguage, setActiveLanguage] = useState('pt');

    const pageData = pageConfig[lang];

    const { schema } = schemaConfig[lang];

    async function handleSubmit(data) {
        const { parsedData, isValid, errors } = await validateForm(
            data,
            schema
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(loginRequest(parsedData));
        }
    }

    function handleChangeLang(lang) {
        setActiveLanguage(lang);

        dispatch(setLang(lang));
    }

    useEffect(() => {
        (async function () {
            dispatch(setLang(lang));

            const ip = await getPublicIp();

            if (ip) {
                const response = await fetchCountry(ip);

                if (response) {
                    if (response.country === 'Brazil') {
                        dispatch(
                            setLocale({
                                dateLocale: ptBR,
                                currencyLocale: 'pt-BR',
                                currencyFormat: 'BRL',
                            })
                        );
                    } else if (response.country === 'United States') {
                        dispatch(
                            setLocale({
                                dateLocale: enUS,
                                currencyLocale: 'en-US',
                                currencyFormat: 'USD',
                            })
                        );
                    } else {
                        dispatch(
                            setLocale({
                                dateLocale: ptBR,
                                currencyLocale: 'en-US',
                                currencyFormat: 'USD',
                            })
                        );
                    }
                }
            }
        })();
    }, []);

    return (
        <div className={styles.loginContainer}>
            <div className={`shadow rounded ${styles.loginBox}`}>
                <Card>
                    <CardBody>
                        <div className="text-center mb-4">
                            <h2>
                                <b>{pageData.pageTitle}</b>
                            </h2>
                        </div>

                        <div className="text-center mb-4">
                            <h4>
                                <b>{pageData.pageSubtitle}</b>
                            </h4>
                        </div>

                        <Form ref={formRef} onSubmit={handleSubmit}>
                            <FormRow>
                                <FormGroup className="col-md-4">
                                    <Input
                                        name="company_id"
                                        type="text"
                                        label={pageData.loginLabel}
                                        data-cy={'login_company_id'}
                                    />
                                </FormGroup>
                                <FormGroup className="col-md-8">
                                    <Input
                                        name="user_id"
                                        type="text"
                                        label={pageData.loginLabel2}
                                        data-cy={'login_user_id'}
                                    />
                                </FormGroup>
                            </FormRow>

                            <FormRow>
                                <FormGroup className="col-md-12">
                                    <Input
                                        name="password"
                                        type="password"
                                        label={pageData.passwordLabel}
                                        data-cy={'login_password'}
                                    />
                                </FormGroup>
                            </FormRow>

                            <FormRow>
                                <FormGroup className="col-md-12">
                                    <span>
                                        {pageData.forgotPasswordText}
                                        <Link
                                            to="/password_reset_request"
                                            data-cy="password_reset_request_link"
                                        >
                                            {pageData.forgotPasswordText2}
                                        </Link>
                                    </span>
                                </FormGroup>
                            </FormRow>

                            <FormRow>
                                <div className="col-md-12 text-center">
                                    <Button
                                        color="primary"
                                        type="submit"
                                        disabled={loading}
                                        data-cy="login_submit"
                                    >
                                        {loading
                                            ? pageData.loginButtonLoadingText
                                            : pageData.loginButtonText}
                                    </Button>
                                </div>
                            </FormRow>

                            <hr />

                            <FormRow>
                                <div
                                    className={`col-md-12 ${styles.langContainer}`}
                                >
                                    <button
                                        type="button"
                                        className={styles.langButton}
                                        disabled={activeLanguage === 'pt'}
                                        name="pt"
                                        onClick={(e) =>
                                            handleChangeLang(e.target.name)
                                        }
                                    >
                                        Português
                                    </button>

                                    <button
                                        type="button"
                                        className={styles.langButton}
                                        disabled={activeLanguage === 'en'}
                                        name="en"
                                        onClick={(e) =>
                                            handleChangeLang(e.target.name)
                                        }
                                    >
                                        English
                                    </button>
                                </div>
                            </FormRow>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

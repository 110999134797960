import { useEffect } from 'react';

const accountId = process.env.REACT_APP_IUGU_ACCOUNT_ID;
const testMode =
    Number(process.env.REACT_APP_IUGU_TEST_MODE) === 1 ? true : false;

const Iugu = {
    impl() {
        if (!window.Iugu) {
            console.log('Iugu script has not been loaded yet.');
            return;
        }

        return window.Iugu;
    },
    setup() {
        this.impl().setup();
    },
    setAccountID(token) {
        this.impl().setAccountID(token);
    },
    setTestMode(mode) {
        this.impl().setTestMode(mode);
    },
    validateCreditCardNumber(number) {
        return this.impl().utils.validateCreditCardNumber(number);
    },
    validateCVV(verification_value, brand) {
        return this.impl().utils.validateCVV(verification_value, brand);
    },
    validateExpirationString(expiration) {
        return this.impl().utils.validateExpirationString(expiration);
    },
    getBrandByCreditCardNumber(number) {
        return this.impl().utils.getBrandByCreditCardNumber(number);
    },
    createPaymentToken(form, throwOnError = false) {
        let number = document.getElementById('number').value;
        let verification_value =
            document.getElementById('verification_value').value;
        let full_name = document.getElementById('full_name').value;
        let expiration = document.getElementById('expiration').value;

        let brand = this.getBrandByCreditCardNumber(number);

        if (!this.validateCreditCardNumber(number)) {
            return {
                errors: {
                    incorrect_number: 'Validation failed: Number is invalid',
                },
            };
        }

        if (!this.validateCVV(verification_value, brand)) {
            return {
                errors: { incorrect_cvv: 'Validation failed: CVV is invalid' },
            };
        }

        if (full_name.split(' ').length < 2) {
            return {
                errors: {
                    incorrect_full_name:
                        'Validation failed: Card holder is invalid',
                },
            };
        }

        if (!this.validateExpirationString(expiration)) {
            return {
                errors: {
                    incorrect_expiration:
                        'Validation failed: Expiration date is invalid',
                },
            };
        }

        return new Promise((resolve, reject) => {
            this.impl().createPaymentToken(form, (response) => {
                if (response.errors && throwOnError) {
                    return reject(response);
                }

                resolve(response);
            });
        });
    },
};

export default function useIugu() {
    useEffect(() => {
        if (document.getElementById('iugu-script')) {
            Iugu.setAccountID(accountId);
            Iugu.setTestMode(testMode);
            return;
        }

        const script = document.createElement('script');
        script.src = 'https://js.iugu.com/v2';
        script.id = 'iugu-script';

        script.onload = () => {
            Iugu.setAccountID(accountId);
            Iugu.setTestMode(testMode);
        };

        document.head.appendChild(script);

        return () => document.getElementById('iugu-script').remove();
    }, []);

    return Iugu;
}

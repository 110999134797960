import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import { setLoading } from '../../../store/actions/loadingActions';
import history from '../../../services/history';
import { MyForm as Form } from './components/form';

export default function DeleteDevice({ currentUser }) {
    const [device, setDevice] = useState(null);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];

    useEffect(() => {
        dispatch(setLoading(true));

        if (history.location.state?.entity) {
            setDevice(history.location.state.entity);
        } else {
            history.push('/devices');
        }

        dispatch(setLoading(false));
    }, []);

    if (!device) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form
                    device={device}
                    fields={pageData?.fields}
                    texts={{
                        successText: pageData?.successText,
                        deleteText: pageData?.deleteText,
                        cancelText: pageData?.cancelText,
                    }}
                />
            </CardBody>
        </Card>
    );
}

DeleteDevice.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

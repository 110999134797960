import React from 'react';
import PropTypes from 'prop-types';
import { parseISO, format } from 'date-fns';

import { handleCircle } from '../../../helpers/handleTableDays';

function MonthDays({ lastNDays, sub, limit, locale }) {
    return lastNDays.date.map((day, index) => {
        const className = handleCircle(day, sub, lastNDays, index);

        if (limit) {
            if (limit == 'smaller') {
                return (
                    index < 5 && (
                        <span key={day} className={className}>
                            {format(parseISO(day), 'P', {
                                locale: locale,
                            }).substr(0, 5)}
                        </span>
                    )
                );
            } else {
                return (
                    index >= 5 && (
                        <span key={day} className={className}>
                            {format(parseISO(day), 'P', {
                                locale: locale,
                            }).substr(0, 5)}
                        </span>
                    )
                );
            }
        } else {
            return (
                <span key={day} className={className}>
                    {format(parseISO(day), 'P', { locale: locale }).substr(
                        0,
                        5
                    )}
                </span>
            );
        }
    });
}

MonthDays.propTypes = {
    lastNDays: PropTypes.object.isRequired,
    sub: PropTypes.object.isRequired,
    limit: PropTypes.string,
    locale: PropTypes.object,
};

export default MonthDays;

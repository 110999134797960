import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import '../../../../../styles/pages/account/changePlan/chooseSubscription/chooseSubscription.css';

import pageConfig from './page.config';
import { currencyFormatter } from '../../../../../helpers/formatters';
import { saveMoney } from '../../../../../helpers/handleSaveMoney';
import { getLumusPriceByFidelity } from '../../../../../helpers/getLumusPrice';
import history from '../../../../../services/history';
import Table from '../../../../../components/table/table';
import TableHeaders from '../../../../../components/table/tableHeaders';
import { CardItem } from './components/card';
import { TableTds } from './components/tableTds';
import { LumusCardPlan } from '../../../../../components/lumusPlanCard';

ChooseSubscription.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function ChooseSubscription({ currentUser }) {
    const [plans, setPlans] = useState(null);
    const [basePlan, setBasePlan] = useState({});
    const [basePrice, setBasePrice] = useState(0);
    const [currency, setCurrency] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);

    const [plansGroups, setPlansGroups] = useState(null);
    const [planType, setPlanType] = useState(0);
    const [numberOfDevices, setNumberOfDevices] = useState(1);

    const lumusPrices = useMemo(() => {
        if (plansGroups) {
            return {
                monthly: getLumusPriceByFidelity(
                    plansGroups[0].prices,
                    numberOfDevices,
                    'monthly'
                ),
                biannual: getLumusPriceByFidelity(
                    plansGroups[0].prices,
                    numberOfDevices,
                    'biannual'
                ),
                annual: getLumusPriceByFidelity(
                    plansGroups[0].prices,
                    numberOfDevices,
                    'annual'
                ),
            };
        }

        return {
            monthly: 0,
            biannual: 0,
            annual: 0,
        };
    }, [numberOfDevices, plansGroups]);

    const saveLumusPrices = useMemo(() => {
        if (plansGroups) {
            return {
                monthly: saveMoney(
                    lumusPrices.monthly,
                    lumusPrices.monthly,
                    'monthly'
                ),
                biannual: saveMoney(
                    lumusPrices.monthly,
                    lumusPrices.biannual,
                    'biannual'
                ),
                annual: saveMoney(
                    lumusPrices.monthly,
                    lumusPrices.annual,
                    'annual'
                ),
            };
        }

        return {
            monthly: 0,
            biannual: 0,
            annual: 0,
        };
    }, [lumusPrices]);

    const lang = useSelector((state) => state.lang.lang);
    const isLoading = useSelector((state) => state.loading.isLoading);

    const pageData = pageConfig[lang][currentUser.permissions];

    const sleepDiarySubscription = currentUser.subscription.plans.find(
        (subscriptionPlan) => subscriptionPlan.type === 'sleep_diary'
    );

    const lumusSubscription = currentUser.subscription.plans.find(
        (subscriptionPlan) => subscriptionPlan.type === 'lumus'
    );

    useEffect(() => {
        if (history.location.state?.plans) {
            let tempCurrency = history.location.state.currency;

            let priceProperty =
                tempCurrency.format === 'BRL' ? 'price_brl' : 'price_usd';

            const basePlan = history.location.state?.plans.reduce((acc, curr) =>
                curr.price_brl > acc.price_brl ? curr : acc
            );

            let plansFormatted = history.location.state.plans.map((plan) => ({
                ...plan,
                priceFormatted: currencyFormatter(
                    plan[priceProperty],
                    tempCurrency.locale,
                    tempCurrency.format
                ),
                saveMoneyFormatted: currencyFormatter(
                    saveMoney(
                        basePlan[priceProperty],
                        plan[priceProperty],
                        plan.fidelity
                    ),
                    tempCurrency.locale,
                    tempCurrency.format
                ),
            }));

            setPlansGroups(history.location.state.plansGroups);
            setPlanType(history.location.state.planType);
            setCurrency(tempCurrency);
            setPaymentMethod(history.location.state.paymentMethod);
            setBasePrice(basePlan[priceProperty]);
            setBasePlan({ ...basePlan });
            setPlans(plansFormatted);
            setNumberOfDevices(
                lumusSubscription &&
                    lumusSubscription.active &&
                    lumusSubscription?.price !== 0
                    ? lumusSubscription.max_devices
                    : 1
            );
        } else {
            history.push('/account/my_account/change_plan');
        }
    }, []);

    if (isLoading || plans === null) {
        return <></>;
    }

    return (
        <>
            {planType === 'sleep_diary' && (
                <>
                    <Row>
                        <Col xs="12">
                            <Table>
                                <thead>
                                    <tr>
                                        <TableHeaders ths={pageData.ths} />
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <TableTds
                                            tds={pageData.tds}
                                            plan={basePlan}
                                            price={basePrice}
                                            currency={currency}
                                        />
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12">
                            <div className="subscriptionsContainer">
                                {plans.map((plan) => (
                                    <CardItem
                                        key={plan.name}
                                        price={plan.priceFormatted}
                                        save={plan.saveMoneyFormatted}
                                        fidelity={plan.fidelity}
                                        state={{
                                            plan,
                                            plans,
                                            currency,
                                            paymentMethod,
                                            plansGroups,
                                            numberOfDevices,
                                        }}
                                        texts={{
                                            fidelityText:
                                                pageData
                                                    .subscriptionFidelityText[
                                                    plan.fidelity
                                                ],
                                            payableWithText:
                                                pageData.payableWith[
                                                    plan.payable_with
                                                ],
                                            planPaymentTypeText:
                                                pageData.planPaymentTypeText[
                                                    plan.interval_type
                                                ],
                                            saveMoneyText:
                                                pageData.saveMoneyText,
                                            chooseButtonText:
                                                pageData.chooseText,
                                            fineCancellationText:
                                                pageData.fineCancellationText[
                                                    plan.fidelity
                                                ],
                                        }}
                                    />
                                ))}
                            </div>
                        </Col>
                    </Row>
                </>
            )}

            {planType === 'lumus' && (
                <Row className="plansContainer">
                    <Row className="d-flex flex-column">
                        {plansGroups.map((planGroup) => (
                            <LumusCardPlan
                                key={planGroup.name}
                                name={planGroup.name}
                                prices={planGroup.prices}
                                currency={currency}
                                texts={{
                                    numberOfDevicesText:
                                        pageData.numberOfDevicesText,
                                    monthlyText: pageData.fidelityText.monthly,
                                    biannualText:
                                        pageData.fidelityText.biannual,
                                    annualText: pageData.fidelityText.annual,
                                    devicesText: pageData.devicesText,
                                    chooseText: pageData.chooseText,
                                    upToText: pageData.upToText,
                                    fromText: pageData.fromText,
                                }}
                            />
                        ))}

                        <Row className="mt-4">
                            <Col xs="12">
                                <label htmlFor="numberOfDevices">
                                    {pageData.numberOfDevicesText}
                                </label>

                                <input
                                    id="numberOfDevices"
                                    type="number"
                                    min="1"
                                    max="1000000"
                                    value={numberOfDevices}
                                    onChange={(e) =>
                                        setNumberOfDevices(
                                            Number(e.target.value)
                                        )
                                    }
                                    className="form-control"
                                />
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col xs="12">
                                <div className="subscriptionsContainer">
                                    {['monthly', 'biannual', 'annual'].map(
                                        (fidelity, index) => (
                                            <CardItem
                                                key={fidelity}
                                                price={currencyFormatter(
                                                    lumusPrices[fidelity],
                                                    currency.locale,
                                                    currency.format
                                                )}
                                                save={currencyFormatter(
                                                    saveLumusPrices[fidelity],
                                                    currency.locale,
                                                    currency.format
                                                )}
                                                fidelity={fidelity}
                                                texts={{
                                                    fidelityText:
                                                        pageData
                                                            .subscriptionFidelityText[
                                                            fidelity
                                                        ],
                                                    payableWithText:
                                                        pageData.creditCardText,
                                                    planPaymentTypeText:
                                                        pageData
                                                            .planPaymentTypeText
                                                            .months,
                                                    saveMoneyText:
                                                        pageData.saveMoneyText,
                                                    chooseButtonText:
                                                        pageData.chooseText,
                                                    fineCancellationText:
                                                        pageData
                                                            .fineCancellationText[
                                                            fidelity
                                                        ],
                                                }}
                                                state={{
                                                    plan: plansGroups[0].plans[
                                                        index
                                                    ],
                                                    plans,
                                                    currency,
                                                    paymentMethod,
                                                    plansGroups,
                                                    numberOfDevices,
                                                }}
                                                disabled={
                                                    lumusSubscription.active
                                                        ? numberOfDevices ===
                                                              lumusSubscription?.max_devices &&
                                                          fidelity ===
                                                              lumusSubscription?.fidelity &&
                                                          !lumusSubscription.plan.name.includes(
                                                              'Trial'
                                                          )
                                                        : false
                                                }
                                            />
                                        )
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Row>
                </Row>
            )}
        </>
    );
}

import React from 'react';
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';
import classnames from 'classnames';
import PropTypes from 'prop-types';

export function ThWithOrdering({
    children,
    property,
    activeFilterProperty,
    activeOrder,
    orderByProperty,
    activeClassName,
    clickableIconClassName,
    ...rest
}) {
    return (
        <th data-cy={`${rest['data-cy']}`}>
            <div className="d-flex justify-content-center align-items-center">
                {children}
                <div className="ml-2 d-flex flex-column">
                    <FaCaretUp
                        className={classnames({
                            [activeClassName]:
                                activeFilterProperty === property &&
                                activeOrder === 'asc',
                            [clickableIconClassName]: true,
                        })}
                        onClick={() => orderByProperty(property, 'asc')}
                        data-cy={`${property}_asc_ordering`}
                    />
                    <FaCaretDown
                        className={classnames({
                            [activeClassName]:
                                activeFilterProperty === property &&
                                activeOrder === 'desc',
                            [clickableIconClassName]: true,
                        })}
                        onClick={() => orderByProperty(property, 'desc')}
                        data-cy={`${property}_desc_ordering`}
                    />
                </div>
            </div>
        </th>
    );
}

ThWithOrdering.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        .isRequired,
    property: PropTypes.string.isRequired,
    activeFilterProperty: PropTypes.string.isRequired,
    activeOrder: PropTypes.string.isRequired,
    orderByProperty: PropTypes.func.isRequired,
    activeClassName: PropTypes.string.isRequired,
    clickableIconClassName: PropTypes.string.isRequired,
};

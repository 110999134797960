import { all } from 'redux-saga/effects';

import auth from '../sagas/authSaga';
import loading from '../sagas/loadingSaga';
import lang from '../sagas/langSaga';
import locale from '../sagas/localeSaga';
import report from '../sagas/reportSaga';

export default function* rootSaga() {
    yield all([auth, loading, lang, locale, report]);
}

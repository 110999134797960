import Input from '../../../components/inputForm/inputText';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Nome',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'price_brl',
                        type: 'number',
                        label: 'Preço em Reais (centavos)',
                        min: 0,
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'price_usd',
                        type: 'number',
                        label: 'Preço em dólar (centavos)',
                        min: 0,
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'discount',
                        type: 'number',
                        label: 'Desconto (centavos)',
                        min: 0,
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'max_patients',
                        type: 'number',
                        label: 'Número máximo de pacientes',
                        min: 1,
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'interval',
                        type: 'number',
                        label: 'Intervalo de pagamento',
                        min: 1,
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'interval_type',
                        label: 'Tipo de intervalo',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-2',
                },
                {
                    component: Input,
                    props: {
                        name: 'max_cycles',
                        type: 'number',
                        label: 'Máximo de meses para expirar (0 para indeterminado)',
                        min: 0,
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'payable_with',
                        label: 'Tipo de pagamento',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'active',
                        label: 'Ativo',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'visible',
                        label: 'Visível',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'type',
                        label: 'Tipe',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            deleteSuccessText: 'Plano excluído com sucesso!',
            deleteButtonText: 'Excluir',
            cancelButtonText: 'Cancelar',
            intervalTypeOptions: {
                months: 'Meses',
            },
            payableWithOptions: {
                credit_card: 'Cartão de crédito',
            },
            yesText: 'Sim',
            noText: 'Não',
            planTypes: {
                sleep_diary: 'Diário de Sono',
                lumus: 'Dispositivos Lumus',
            },
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Name',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'price_brl',
                        type: 'number',
                        label: 'Price in Reais (cents)',
                        min: 0,
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'price_usd',
                        type: 'number',
                        label: 'Price in dollar (cents)',
                        min: 0,
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'discount',
                        type: 'number',
                        label: 'Discount (cents)',
                        min: 0,
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'max_patients',
                        type: 'number',
                        label: 'Maximum number of patients',
                        min: 1,
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'interval',
                        type: 'number',
                        label: 'Range of payment',
                        min: 1,
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'interval_type',
                        label: 'Interval type',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-2',
                },
                {
                    component: Input,
                    props: {
                        name: 'max_cycles',
                        type: 'number',
                        label: 'Maximum months to expire (0 for indefinite)',
                        min: 0,
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'payable_with',
                        label: 'Payment method',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'active',
                        label: 'Active',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'visible',
                        label: 'Visible',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'type',
                        label: 'Type',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            deleteSuccessText: 'Plan successfully deleted!',
            deleteButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            intervalTypeOptions: {
                months: 'Months',
            },
            payableWithOptions: {
                credit_card: 'Credit card',
            },
            yesText: 'Yes',
            noText: 'No',
            planTypes: {
                sleep_diary: 'Sleep Diary',
                lumus: 'Lumus Devices',
            },
        },
    },
};

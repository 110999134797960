import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardFooter } from 'reactstrap';
import { ptBR, enUS } from 'date-fns/locale';

import styles from './styles.module.scss';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { currencyFormatter } from '../../helpers/formatters';
import { getPublicIp } from '../../helpers/getPublicIp';
import { setLoading } from '../../store/actions/loadingActions';
import { setLocale as updateLocale } from '../../store/actions/localeActions';
import { verifyRecaptchaToken, fetchPlans } from '../../services/api/register';
import { fetchCountry } from '../../services/api/ip';
import { RegisterForm } from './components/form';

export default function Register() {
    const { lang } = useParams();

    const [plan, setPlan] = useState(null);
    const [plansGroupsByType, setPlansGroupsByType] = useState(null);
    const [typeOptions, setTypeOptions] = useState(null);
    const [planOptions, setPlanOptions] = useState(null);
    const [fidelityOptions, setFidelityOptions] = useState(null);
    const [currentType, setCurrentType] = useState(null);
    const [locale, setLocale] = useState(null);
    const [cardNumberComplete, setCardNumberComplete] = useState(false);
    const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
    const [cardCvcComplete, setCardCvcComplete] = useState(false);
    const [filledInForm, setFilledInForm] = useState(false);
    const [formReady, setFormReady] = useState(false);
    const [notARobot, setNotARobot] = useState(false);
    const [loading, updateLoading] = useState(false);

    const dispatch = useDispatch();

    const pageData = pageConfig[lang];
    const schemaData = schemaConfig[lang];

    async function handleRecaptchaChange(value) {
        if (value !== null) {
            const response = await verifyRecaptchaToken({
                response: value,
            });

            if (response) {
                setNotARobot(true);
            }
        } else {
            setNotARobot(false);
        }
    }

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            updateLoading(true);

            let tempLocale;

            const ip = await getPublicIp();

            const response = await fetchCountry(ip);

            if (response) {
                if (response?.country) {
                    if (response.country === 'Brazil') {
                        tempLocale = {
                            dateLocale: ptBR,
                            currencyLocale: 'pt-BR',
                            currencyFormat: 'BRL',
                        };
                    } else if (response.country === 'United States') {
                        tempLocale = {
                            dateLocale: enUS,
                            currencyLocale: 'en-US',
                            currencyFormat: 'USD',
                        };
                    } else {
                        tempLocale = {
                            dateLocale: ptBR,
                            currencyLocale: 'en-US',
                            currencyFormat: 'USD',
                        };
                    }
                } else {
                    tempLocale = {
                        dateLocale: ptBR,
                        currencyLocale: 'pt-BR',
                        currencyFormat: 'BRL',
                    };
                }
            } else {
                tempLocale = {
                    dateLocale: ptBR,
                    currencyLocale: 'pt-BR',
                    currencyFormat: 'BRL',
                };
            }

            dispatch(updateLocale(tempLocale));

            const data = await fetchPlans();

            if (data) {
                setPlansGroupsByType(data?.plans_groups_by_type);

                let tempPlanGroup = data?.plans_groups_by_type[
                    'sleep_diary'
                ]?.find((planGroup) => planGroup.plan_name === 'Standard');

                let tempPlan = tempPlanGroup.plans.find(
                    (plan) => plan.name === 'Standard'
                );

                setPlan(tempPlan);

                tempPlanGroup.fidelities = tempPlanGroup.fidelities
                    .sort()
                    .reverse();

                let tempTypes = Object.keys(data?.plans_groups_by_type);

                setCurrentType(tempTypes[0]);

                setTypeOptions(
                    tempTypes.map((type) => ({
                        id: type,
                        label: pageData.typeTexts[type],
                        value: type,
                    }))
                );

                setPlanOptions(
                    data?.plans_groups_by_type['sleep_diary'].map(
                        (planGroup) => ({
                            id: planGroup.plan_name,
                            label: `${planGroup.plan_name} - ${planGroup.max_patients} ${pageData.patientsText}`,
                            value: planGroup.plan_name,
                        })
                    )
                );

                setFidelityOptions(
                    tempPlanGroup.fidelities.map((fidelity) => ({
                        id: fidelity,
                        label: `${
                            pageData.fidelityTexts[fidelity]
                        } - ${currencyFormatter(
                            tempPlanGroup.plan_prices[fidelity],
                            tempLocale.currencyLocale,
                            tempLocale.currencyFormat
                        )}`,
                        value: fidelity,
                    }))
                );
            }

            setLocale(tempLocale);

            dispatch(setLoading(false));

            updateLoading(false);
        })();
    }, []);

    useEffect(() => {
        if (
            (cardNumberComplete &&
                cardExpiryComplete &&
                cardCvcComplete &&
                filledInForm) ||
            ((locale?.currencyLocale === 'pt-BR' ||
                plan?.name === 'Trial 30') &&
                filledInForm)
        ) {
            setCardNumberComplete(true);
            setCardCvcComplete(true);
            setCardExpiryComplete(true);
            setFormReady(true);
        } else {
            setFormReady(false);
            setNotARobot(false);
        }
    }, [cardNumberComplete, cardExpiryComplete, cardCvcComplete, filledInForm]);

    if (loading || plan === null) {
        return <></>;
    }

    return (
        <div className={styles.registerContainer}>
            <header className={styles.registerHeaderContainer}>
                <h1 className={styles.registerTitle}>{pageData.pageTitle}</h1>
                <h4 className={styles.registerSubTitle}>
                    {pageData.pageSubtitle}
                </h4>
            </header>
            <main className={styles.registerMainContainer}>
                <section className="shadow">
                    <Card>
                        <CardBody>
                            <RegisterForm
                                pageData={pageData}
                                schema={
                                    locale.currencyLocale === 'pt-BR'
                                        ? {
                                              sleep_diary:
                                                  schemaData.iuguSchema,
                                              lumus: schemaData.iuguLumusSchema,
                                          }
                                        : {
                                              sleep_diary:
                                                  schemaData.stripeSchema,
                                              lumus: schemaData.stripeLumusSchema,
                                          }
                                }
                                currentType={currentType}
                                setCurrentType={setCurrentType}
                                typeOptions={typeOptions}
                                planOptions={planOptions}
                                setPlanOptions={setPlanOptions}
                                fidelityOptions={fidelityOptions}
                                setFidelityOptions={setFidelityOptions}
                                plan={plan}
                                setPlan={setPlan}
                                plansGroupsByType={plansGroupsByType}
                                setCardNumberComplete={setCardNumberComplete}
                                setCardExpiryComplete={setCardExpiryComplete}
                                setCardCvcComplete={setCardCvcComplete}
                                setFilledInForm={setFilledInForm}
                                formReady={formReady}
                                handleRecaptchaChange={handleRecaptchaChange}
                                notARobot={notARobot}
                                lang={lang}
                                locale={locale}
                            />
                        </CardBody>
                        <CardFooter className="text-center">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: pageData.requiredFields,
                                }}
                            ></p>
                        </CardFooter>
                    </Card>
                </section>
            </main>
            <footer className={styles.registerFooter}>
                <div>
                    <p>
                        <img src="/assets/logo.png" alt="Logo Condor" />{' '}
                        {pageData.pageFooter}
                    </p>
                </div>
            </footer>
        </div>
    );
}

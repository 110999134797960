import React from 'react';
import { Scope } from '@unform/core';
import { FormGroup, Button } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';
import PropTypes from 'prop-types';

LumusPricesFormFields.propTypes = {
    pageData: PropTypes.object.isRequired,
    lumusPricesFields: PropTypes.array.isRequired,
    handleAddClick: PropTypes.func.isRequired,
    handleRemoveClick: PropTypes.func.isRequired,
    scope: PropTypes.string.isRequired,
};

export function LumusPricesFormFields({
    pageData,
    lumusPricesFields,
    handleAddClick,
    handleRemoveClick,
    scope = 'prices',
}) {
    return (
        <>
            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-12">
                    <span>{pageData.pricesText}</span>
                </FormGroup>
            </FormGroup>
            {lumusPricesFields.map((lumusPriceFields, index) => (
                <React.Fragment key={`${lumusPriceFields[0].key}`}>
                    <FormGroup row>
                        <Scope path={`${scope}[${index}]`}>
                            {lumusPriceFields.map((field) => (
                                <FormGroup
                                    key={`${field.props.name}${field.key}`}
                                    className={field.className}
                                >
                                    <field.component {...field.props} />
                                </FormGroup>
                            ))}
                            <FormGroup>
                                <div className="col-xs-12 col-md-12 text-left d-flex align-items-end h-100">
                                    <Button
                                        color="danger"
                                        type="button"
                                        onClick={() =>
                                            handleRemoveClick(
                                                index,
                                                scope === 'prices'
                                                    ? 'monthly'
                                                    : scope ===
                                                      'biannual_prices'
                                                    ? 'biannual'
                                                    : 'annual'
                                            )
                                        }
                                        title={pageData.deletePriceText}
                                    >
                                        <FaTrash />
                                    </Button>
                                </div>
                            </FormGroup>
                        </Scope>
                    </FormGroup>
                </React.Fragment>
            ))}
            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button
                        color="primary"
                        type="button"
                        onClick={() =>
                            handleAddClick(
                                scope === 'prices'
                                    ? 'monthly'
                                    : scope === 'biannual_prices'
                                    ? 'biannual'
                                    : 'annual'
                            )
                        }
                        title={pageData.addPriceText}
                    >
                        {pageData.addPriceText}
                    </Button>
                </div>
            </FormGroup>
        </>
    );
}

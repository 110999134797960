import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardFooter } from 'reactstrap';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import history from '../../../../services/history';
import { UpdatePaymentMethodForm } from './components/form';

export default function UpdatePaymentMethod({ currentUser }) {
    const [paymentMethod, setPaymentMethod] = useState({});

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];
    const schemaData = schemaConfig[lang][currentUser.permissions];

    useEffect(() => {
        if (history.location.state?.paymentMethod) {
            setPaymentMethod(history.location.state.paymentMethod);
        } else {
            history.push('/account/my_account');
        }
    }, []);

    if (Object.entries(paymentMethod).length === 0) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <UpdatePaymentMethodForm
                    schema={
                        currentUser?.subscription?.locale === 'pt-BR'
                            ? schemaData?.schemaWithCard
                            : schemaData?.schema
                    }
                    fields={pageData?.fields}
                    texts={{
                        iuguTexts: pageData?.iuguTexts,
                        stripeTexts: pageData?.stripeTexts,
                        currentCreditCardSpanText:
                            pageData?.currentCreditCardSpanText,
                        newCreditCardSpanText: pageData?.newCreditCardSpanText,
                        newCreditCardLabelText:
                            pageData?.newCreditCardLabelText,
                        editSuccessText: pageData?.editSuccessText,
                        editFailedText: pageData?.editFailedText,
                        editButtonText: pageData?.editButtonText,
                        cancelButtonText: pageData?.cancelButtonText,
                    }}
                    paymentMethod={paymentMethod}
                    currentUser={currentUser}
                />
            </CardBody>
            <CardFooter>
                <p
                    dangerouslySetInnerHTML={{
                        __html: pageData?.requiredFieldPText,
                    }}
                ></p>
            </CardFooter>
        </Card>
    );
}

UpdatePaymentMethod.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

import Input from '../../../../components/inputForm/inputText';

export default {
    pt: {
        customer_admin: null,
        manager: {
            fields: {
                card: [
                    {
                        component: Input,
                        props: {
                            name: 'description',
                            type: 'text',
                            label: 'Descrição do cartão',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'display_number',
                            type: 'text',
                            label: 'Número do cartão',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'expiration_date',
                            label: 'Data de validade',
                            type: 'text',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                new_card_description: [
                    {
                        component: Input,
                        props: {
                            name: 'new_description',
                            type: 'text',
                            label: 'Descrição do cartão <b style="color: darkred">*</b>',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                new_card_info: {
                    number: {
                        component: Input,
                        props: {
                            name: 'number',
                            type: 'text',
                            'data-iugu': 'number',
                            placeholder: 'Número do cartão',
                            className: 'credit_card_number',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    verification_value: {
                        component: Input,
                        props: {
                            name: 'verification_value',
                            type: 'text',
                            'data-iugu': 'verification_value',
                            placeholder: 'CVV',
                            className: 'credit_card_cvv',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    full_name: {
                        component: Input,
                        props: {
                            name: 'full_name',
                            type: 'text',
                            'data-iugu': 'full_name',
                            placeholder: 'Titular do cartão',
                            className: 'credit_card_name',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    expiration: {
                        component: Input,
                        props: {
                            name: 'expiration',
                            type: 'text',
                            'data-iugu': 'expiration',
                            placeholder: 'MM/AA',
                            className: 'credit_card_expiration',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                },
            },
            iuguTexts: {
                imgAltTexts: {
                    cardTypes: 'Visa, Master, Diners. Amex',
                    paymentByIugu: 'Pagamentos por Iugu',
                },
                numberErrorText: 'O número do cartão é inválido.',
                cvvErrorText: 'O código de segurança do cartão está incorreto.',
                fullNameErrorText: 'O nome do titular do cartão é inválido.',
                expirationErrorText: 'A data de validade é inválida.',
                defaultErrorText: 'Erro no cartão.',
            },
            stripeTexts: {
                expired_card: 'Seu cartão expirou!',
                incorrect_cvc:
                    'O código de segurança do seu cartão está incorreto!',
                processing_error:
                    'Ocorreu um erro ao processar seu cartão. Tente novamente em instantes.',
                incorrect_number: 'O número do seu cartão é inválido.',
                card_declined: 'Seu cartão foi recusado.',
                setup_intent_authentication_failure: `Não conseguimos autenticar o seu método de pagamento.
                Escolha um método de pagamento diferente e tente novamente.`,
            },
            currentCreditCardSpanText: 'Cartão atual',
            newCreditCardSpanText: 'Novo cartão',
            newCreditCardLabelText: 'Cartão <b style="color: darkred">*</b>',
            editSuccessText: 'Cartão atualizado com sucesso!',
            editFailedText: 'Não foi possível atualizar o cartão!',
            editButtonText: 'Salvar',
            cancelButtonText: 'Cancelar',
            requiredFieldPText:
                '<b style="color: darkred">*</b> Campos obrigatórios!',
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            fields: {
                card: [
                    {
                        component: Input,
                        props: {
                            name: 'description',
                            type: 'text',
                            label: 'Card description',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'display_number',
                            type: 'text',
                            label: 'Card number',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'expiration_date',
                            label: 'Expiration date',
                            type: 'text',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                new_card_description: [
                    {
                        component: Input,
                        props: {
                            name: 'new_description',
                            type: 'text',
                            label: 'Card description <b style="color: darkred">*</b>',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                new_card_info: {
                    number: {
                        component: Input,
                        props: {
                            name: 'number',
                            type: 'text',
                            'data-iugu': 'number',
                            placeholder: 'Card number',
                            className: 'credit_card_number',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    verification_value: {
                        component: Input,
                        props: {
                            name: 'verification_value',
                            type: 'text',
                            'data-iugu': 'verification_value',
                            placeholder: 'CVV',
                            className: 'credit_card_cvv',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    full_name: {
                        component: Input,
                        props: {
                            name: 'full_name',
                            type: 'text',
                            'data-iugu': 'full_name',
                            placeholder: 'Cardholder',
                            className: 'credit_card_name',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    expiration: {
                        component: Input,
                        props: {
                            name: 'expiration',
                            type: 'text',
                            'data-iugu': 'expiration',
                            placeholder: 'MM/YY',
                            className: 'credit_card_expiration',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                },
            },
            iuguTexts: {
                imgAltTexts: {
                    cardTypes: 'Visa, Master, Diners. Amex',
                    paymentByIugu: 'Payments by Iugu',
                },
                numberErrorText: 'Card number is invalid!',
                cvvErrorText: 'Security code is incorrect!',
                fullNameErrorText: 'Cardholder is incorrect!',
                expirationErrorText: 'Invalid expiry date!',
                defaultErrorText: 'Card error!',
            },
            stripeTexts: {
                expired_card: 'Your card has expired!',
                incorrect_cvc: "Your card's security code is incorrect!",
                processing_error:
                    'There was an error processing your card. Please try again in a moment.',
                incorrect_number: 'Your card number is invalid.',
                card_declined: 'Your card has been declined.',
                setup_intent_authentication_failure: `We are unable to authenticate your payment method.
                Please choose a different payment method and try again.`,
            },
            currentCreditCardSpanText: 'Current credit card',
            newCreditCardSpanText: 'New credit card',
            newCreditCardLabelText: 'Card <b style="color: darkred">*</b>',
            editSuccessText: 'Credit card successfully updated!',
            editFailedText: 'It was not possible to update the card!',
            editButtonText: 'Save',
            cancelButtonText: 'Cancel',
            requiredFieldPText:
                '<b style="color: darkred">*</b> Required fields!',
        },
        condor_admin: null,
    },
};

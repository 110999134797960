import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardFooter } from 'reactstrap';
import { ptBR, enUS } from 'date-fns/locale';

import styles from './styles.module.scss';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { setLoading } from '../../../store/actions/loadingActions';
import { setLocale as updateLocale } from '../../../store/actions/localeActions';
import { getPublicIp } from '../../../helpers/getPublicIp';
import {
    verifyRecaptchaToken,
    fetchPlan,
} from '../../../services/api/register';
import { fetchCountry } from '../../../services/api/ip';
import { RegisterForm } from './components/form';

export default function RegisterByPlan() {
    const { planName, lang } = useParams();

    if (
        lang === 'event' &&
        planName === 'dee495f5-b373-330c-9a65-fbe0221e37e2'
    ) {
        return <Redirect to={`/register/en/Trial 30`} />;
    }

    const [plan, setPlan] = useState(null);
    const [price, setPrice] = useState(null);
    const [locale, setLocale] = useState({});
    const [showCreditCardFields, setShowCreditCardFields] = useState(false);
    const [cardNumberComplete, setCardNumberComplete] = useState(false);
    const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
    const [cardCvcComplete, setCardCvcComplete] = useState(false);
    const [filledInForm, setFilledInForm] = useState(false);
    const [formReady, setFormReady] = useState(false);
    const [notARobot, setNotARobot] = useState(false);
    const [loading, updateLoading] = useState(false);
    const [companyFields, setCompanyFields] = useState([]);
    const [planFields, setPlanFields] = useState([]);

    const dispatch = useDispatch();

    const pageData = pageConfig[lang];

    const {
        iuguSchema,
        stripeSchema,
        trialSchema,
        iuguLumusSchema,
        stripeLumusSchema,
    } = schemaConfig[lang];

    async function handleRecaptchaChange(value) {
        if (value !== null) {
            const response = await verifyRecaptchaToken({
                response: value,
            });
            if (response) {
                setNotARobot(true);
            }
        } else {
            setNotARobot(false);
        }
    }

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            updateLoading(true);

            let tempLocale;

            const ip = await getPublicIp();

            const response = await fetchCountry(ip);

            if (response) {
                if (response?.country) {
                    if (response.country === 'Brazil') {
                        tempLocale = {
                            dateLocale: ptBR,
                            currencyLocale: 'pt-BR',
                            currencyFormat: 'BRL',
                        };
                    } else if (response.country === 'United States') {
                        tempLocale = {
                            dateLocale: enUS,
                            currencyLocale: 'en-US',
                            currencyFormat: 'USD',
                        };
                    } else {
                        tempLocale = {
                            dateLocale: ptBR,
                            currencyLocale: 'en-US',
                            currencyFormat: 'USD',
                        };
                    }
                } else {
                    tempLocale = {
                        dateLocale: ptBR,
                        currencyLocale: 'pt-BR',
                        currencyFormat: 'BRL',
                    };
                }
            } else {
                tempLocale = {
                    dateLocale: ptBR,
                    currencyLocale: 'pt-BR',
                    currencyFormat: 'BRL',
                };
            }

            dispatch(updateLocale(tempLocale));

            const data = await fetchPlan(planName);

            if (data) {
                if (Object.keys(data?.plan).length > 0) {
                    const tempCompanyFields = [
                        ...pageData.fields.companyFields,
                    ];
                    let tempPlanFields = [...pageData.fields.planFields];

                    let tempPrice = 0;

                    if (data?.plan.type === 'lumus') {
                        if (data?.plan?.name?.includes('Trial')) {
                            tempPrice = 0;
                        } else {
                            tempPrice = 0;
                        }

                        tempPlanFields = tempPlanFields.filter(
                            (field) => field.props.name !== 'max_patients'
                        );
                    } else {
                        tempPrice =
                            tempLocale.currencyLocale === 'pt-BR'
                                ? data?.plan.price_brl
                                : data?.plan.price_usd;

                        tempPlanFields = tempPlanFields.filter(
                            (field) => field.props.name !== 'max_devices'
                        );
                    }

                    if (!data.plan.name.includes('Trial')) {
                        setShowCreditCardFields(true);
                    }

                    setCompanyFields(tempCompanyFields);

                    setPlanFields(tempPlanFields);

                    setPrice(tempPrice);

                    setPlan(data?.plan);
                } else {
                    setPlan({
                        name: 'Trial 30',
                        price_brl: 0,
                        price_usd: 0,
                    });

                    setPrice(0);
                }
            }

            setLocale(tempLocale);

            dispatch(setLoading(false));

            updateLoading(false);
        })();
    }, []);

    useEffect(() => {
        if (
            (cardNumberComplete &&
                cardExpiryComplete &&
                cardCvcComplete &&
                filledInForm) ||
            ((locale?.currencyLocale === 'pt-BR' ||
                plan?.name.toLowerCase().includes('trial')) &&
                filledInForm)
        ) {
            setCardNumberComplete(true);
            setCardCvcComplete(true);
            setCardExpiryComplete(true);
            setFormReady(true);
        } else {
            setFormReady(false);
            setNotARobot(false);
        }
    }, [cardNumberComplete, cardExpiryComplete, cardCvcComplete, filledInForm]);

    if (loading || plan === null) {
        return <></>;
    }

    return (
        <div className={styles.registerContainer}>
            <header className={styles.registerHeaderContainer}>
                <h1 className={styles.registerTitle}>
                    {plan?.name?.toLowerCase().includes('trial')
                        ? pageData.pageTitleTrial
                        : pageData.pageTitle}
                </h1>
                <h4 className={styles.registerSubTitle}>
                    {plan?.name?.toLowerCase().includes('lumus')
                        ? pageData.pageSubtitleLumustext
                        : pageData.pageSubtitle}
                </h4>
            </header>
            <main className={styles.registerMainContainer}>
                <section className="shadow">
                    <Card>
                        <CardBody>
                            <RegisterForm
                                pageData={pageData}
                                schema={
                                    locale.currencyLocale === 'pt-BR'
                                        ? {
                                              trial: trialSchema,
                                              sleep_diary: iuguSchema,
                                              lumus: iuguLumusSchema,
                                          }
                                        : {
                                              trial: trialSchema,
                                              sleep_diary: stripeSchema,
                                              lumus: stripeLumusSchema,
                                          }
                                }
                                plan={plan}
                                price={price}
                                showCreditCardFields={showCreditCardFields}
                                setCardNumberComplete={setCardNumberComplete}
                                setCardExpiryComplete={setCardExpiryComplete}
                                setCardCvcComplete={setCardCvcComplete}
                                setFilledInForm={setFilledInForm}
                                formReady={formReady}
                                handleRecaptchaChange={handleRecaptchaChange}
                                notARobot={notARobot}
                                lang={lang}
                                locale={locale}
                                planFields={planFields}
                                companyFields={companyFields}
                            />
                        </CardBody>
                        <CardFooter className="text-center">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: pageData.requiredFields,
                                }}
                            ></p>
                        </CardFooter>
                    </Card>
                </section>
            </main>
            <footer className={styles.registerFooter}>
                <div>
                    <p>
                        <img src="/assets/logo.png" alt="Logo Condor" />{' '}
                        {pageData.pageFooter}
                    </p>
                </div>
            </footer>
        </div>
    );
}

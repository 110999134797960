import React from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import '../../styles/components/loading/loading.css';

import pageConfig from './loading.config';

function Loading() {
    const isLoading = useSelector((state) => state.loading.isLoading);
    const lang = useSelector((state) => state.lang.lang);

    const { loadingText } = pageConfig[lang];

    return (
        <>
            {(isLoading && (
                <>
                    <div className="overlayLoading">
                        <div className="contentLoading">
                            <div className="spinnerLoading">
                                <Spinner className="fa-spinner" />
                            </div>
                            <h4>{loadingText}</h4>
                        </div>
                    </div>
                </>
            )) ||
                null}
        </>
    );
}

export default Loading;

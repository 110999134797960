import React from 'react';
import PropTypes from 'prop-types';

const FormRow = ({ children, className }) => {
    return (
        <div className={`form-row ${className ? className : ''}`}>
            {children}
        </div>
    );
};

FormRow.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default FormRow;

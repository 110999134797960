import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, FormGroup } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import {
    CardNumberElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import { setLoading } from '../../../../../../../store/actions/loadingActions';
import {
    getNewSubscriptionProRataValue,
    getPlanUpgradeProRataValue,
} from '../../../../../../../helpers/subscriptionHelpers';
import { validateForm } from '../../../../../../../helpers/formValidator';
import { getLumusPrice } from '../../../../../../../helpers/getLumusPrice';
import { currencyFormatter } from '../../../../../../../helpers/formatters';
import history from '../../../../../../../services/history';
import {
    createInternationalSubscription,
    updateInternationalSubscription,
    createNationalSubscription,
} from '../../../../../../../services/api/account';
import useIugu from '../../../../../../../hooks/useIugu';
import { IuguCreditCardForm } from '../../../../../../../components/form/iuguCreditCardForm';
import { StripeForm } from './stripeForm';
import Input from '../../../../../../../components/inputForm/inputText';

ReviewAndPaymentForm.propTypes = {
    pageData: PropTypes.object.isRequired,
    schema: PropTypes.object.isRequired,
    plan: PropTypes.object.isRequired,
    plans: PropTypes.array.isRequired,
    paymentMethod: PropTypes.object,
    currency: PropTypes.object.isRequired,
    plansGroups: PropTypes.array,
    numberOfDevices: PropTypes.number,
    prorationDate: PropTypes.number,
    amountDue: PropTypes.number,
    texts: PropTypes.shape({
        imgAltTexts: PropTypes.object.isRequired,
        payableWithTexts: PropTypes.object.isRequired,
        fidelityTexts: PropTypes.object.isRequired,
        plantext: PropTypes.string.isRequired,
        subscriptionText: PropTypes.string.isRequired,
        cardDataText: PropTypes.string.isRequired,
        cardText: PropTypes.string.isRequired,
        confirmText: PropTypes.string.isRequired,
        goBackText: PropTypes.string.isRequired,
        requiredFieldText: PropTypes.string.isRequired,
        successText: PropTypes.string.isRequired,
        paymentFailedText: PropTypes.string.isRequired,
        iuguErrorsText: PropTypes.object.isRequired,
        stripeErrorsTexts: PropTypes.object.isRequired,
        nameText: PropTypes.string.isRequired,
        maxPatientsText: PropTypes.string.isRequired,
        numberOfDevicesText: PropTypes.string.isRequired,
        fidelityText: PropTypes.string.isRequired,
        payableWithText: PropTypes.string.isRequired,
        priceText: PropTypes.string.isRequired,
        proRataPriceText: PropTypes.string.isRequired,
        cardDescriptionText: PropTypes.string.isRequired,
        cardNumberText: PropTypes.string.isRequired,
        expirationDateText: PropTypes.string.isRequired,
        requiredCardDescriptionText: PropTypes.string.isRequired,
        addNewCardText: PropTypes.string.isRequired,
        deleteCardText: PropTypes.string.isRequired,
    }).isRequired,
};

export function ReviewAndPaymentForm({
    pageData,
    schema,
    plan,
    plans,
    paymentMethod,
    currency,
    plansGroups,
    numberOfDevices,
    prorationDate,
    amountDue,
    texts,
}) {
    const iugu = useIugu();
    const stripe = useStripe();
    const elements = useElements();

    const [clientSecret, setClientSecret] = useState(null);

    const [cardNumberComplete, setCardNumberComplete] = useState(
        !!paymentMethod
    );
    const [cardExpiryComplete, setCardExpiryComplete] = useState(
        !!paymentMethod
    );
    const [cardCvcComplete, setCardCvcComplete] = useState(!!paymentMethod);
    const [filledInForm, setFilledInForm] = useState(!!paymentMethod);
    const [formReady, setFormReady] = useState(false);
    const [addNewCard, setAddNewCard] = useState(false);

    const formRef = useRef(null);

    const dispatch = useDispatch();

    const currentUser = useSelector((state) => state.auth.currentUser);
    const lang = useSelector((state) => state.lang.lang);

    const sleepDiarySubscription = currentUser.subscription.plans.find(
        (subscriptionPlan) => subscriptionPlan.type === 'sleep_diary'
    );

    const lumusSubscription = currentUser.subscription.plans.find(
        (subscriptionPlan) => subscriptionPlan.type === 'lumus'
    );

    function handleKeyUpForm() {
        const formData = formRef.current.getData();

        let empty = 0;

        for (let key in formData) {
            if (formData[key] === '') {
                empty += 1;
            }
        }

        setFilledInForm(empty ? false : true);
    }

    async function handleSubmit(data, { reset }, event) {
        event.preventDefault();

        const { parsedData, isValid, errors } = await validateForm(
            data,
            schema
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(setLoading(true));

            const sendData = {
                plan_id: plan.id,
                token: null,
                description: null,
                number_of_devices:
                    plan.type === 'lumus' ? numberOfDevices : null,
            };

            if (currency.locale === 'pt-BR') {
                // avoid sending the form if iugu script has not yet been loaded
                if (!iugu.impl()) {
                    dispatch(setLoading(false));
                    return;
                }

                // check if user already has a payment method, if not, create a payment token
                if (!paymentMethod || addNewCard) {
                    const createTokenResponse = await iugu.createPaymentToken(
                        event.target
                    );

                    if (createTokenResponse?.errors) {
                        dispatch(setLoading(false));

                        if ('incorrect_number' in createTokenResponse.errors) {
                            toast.error(texts.iuguErrorsText.numberErrorText);
                        } else if (
                            'incorrect_cvv' in createTokenResponse.errors
                        ) {
                            toast.error(texts.iuguErrorsText.cvvErrorText);
                        } else if (
                            'incorrect_fullName' in createTokenResponse.errors
                        ) {
                            toast.error(texts.iuguErrorsText.fullNameErrorText);
                        } else if (
                            'incorrect_expiration' in createTokenResponse.errors
                        ) {
                            toast.error(
                                texts.iuguErrorsText.expirationErrorText
                            );
                        } else {
                            toast.error(texts.iuguErrorsText.defaultErrorText);
                        }

                        return;
                    }

                    sendData.description = data.description;
                    sendData.token = createTokenResponse.id;
                }

                const response = await createNationalSubscription(sendData);

                if (response) {
                    toast.success(texts.successText);

                    history.push('/account/my_account');
                }
            } else {
                // avoid sending the form if stripe promise has not yet been fulfilled

                if (!stripe || !elements) {
                    dispatch(setLoading(false));
                    return;
                }

                // if user has no paid subscription of the same type as the new plan
                // that means, if user does not have a stripe_id in the subscription of the same type of the plan
                // request to create subscription endpoint
                if (
                    (!sleepDiarySubscription?.stripe_id &&
                        plan.type === 'sleep_diary') ||
                    (!lumusSubscription?.stripe_id && plan.type === 'lumus')
                ) {
                    let tempClientSecret;

                    if (!clientSecret) {
                        const response = await createInternationalSubscription({
                            ...sendData,
                            proration_date: prorationDate,
                        });

                        if (response) {
                            tempClientSecret = response?.clientSecret;
                            setClientSecret(response?.clientSecret);
                        }
                    }

                    if (!(tempClientSecret ?? clientSecret)) {
                        dispatch(setLoading(false));
                        return;
                    }

                    let result;

                    if (paymentMethod?.id) {
                        result = await stripe.confirmCardPayment(
                            tempClientSecret,
                            {
                                payment_method: paymentMethod?.id,
                            }
                        );
                    } else {
                        result = await stripe.confirmCardPayment(
                            tempClientSecret ?? clientSecret,
                            {
                                payment_method: {
                                    card: elements.getElement(
                                        CardNumberElement
                                    ),
                                    billing_details: {
                                        name: currentUser.company_name,
                                    },
                                },
                            }
                        );
                    }

                    dispatch(setLoading(false));

                    if (result.error) {
                        if (result.error.code in texts?.stripeErrorsTexts) {
                            if (
                                result.error.code === 'card_declined' &&
                                result.error.decline_code in
                                    texts?.stripeErrorsTexts.card_decline_codes
                            ) {
                                toast.error(
                                    texts?.stripeErrorsTexts
                                        ?.card_decline_codes[
                                        result.error.decline_code
                                    ]
                                );
                            } else {
                                toast.error(
                                    texts?.stripeErrorsTexts[result.error.code]
                                );
                            }
                        } else {
                            toast.error(texts?.paymentFailedText);
                        }

                        return;
                    }

                    if (result.paymentIntent.status === 'succeeded') {
                        toast.success(texts.successText);

                        history.push('/account/my_account');

                        return;
                    }
                }

                // if user has at least one paid subscription
                // that means, if user has at least one stripe_id in either subscription
                // request to update subscription endpoint
                if (
                    (sleepDiarySubscription?.active &&
                        sleepDiarySubscription?.stripe_id !== null) ||
                    (lumusSubscription?.active &&
                        lumusSubscription?.stripe_id !== null)
                ) {
                    let tempClientSecret;

                    if (!clientSecret) {
                        const response = await updateInternationalSubscription({
                            ...sendData,
                            proration_date: prorationDate,
                        });

                        if (response) {
                            if (!response?.clientSecret) {
                                dispatch(setLoading(false));

                                toast.success(texts.successText);

                                history.push('/account/my_account');

                                return;
                            } else {
                                tempClientSecret = response?.clientSecret;
                                setClientSecret(response?.clientSecret);
                            }
                        }
                    }

                    if (!(tempClientSecret ?? clientSecret)) {
                        dispatch(setLoading(false));
                        return;
                    }

                    const result = await stripe.confirmCardPayment(
                        tempClientSecret,
                        {
                            payment_method: paymentMethod?.id,
                        }
                    );

                    dispatch(setLoading(false));

                    if (result.error) {
                        if (result.error.code in texts?.stripeErrorsTexts) {
                            if (
                                result.error.code === 'card_declined' &&
                                result.error.decline_code in
                                    texts?.stripeErrorsTexts.card_decline_codes
                            ) {
                                toast.error(
                                    texts?.stripeErrorsTexts
                                        ?.card_decline_codes[
                                        result.error.decline_code
                                    ]
                                );
                            } else {
                                toast.error(
                                    texts?.stripeErrorsTexts[result.error.code]
                                );
                            }
                        } else {
                            toast.error(texts?.paymentFailedText);
                        }

                        return;
                    } else {
                        if (result.paymentIntent.status === 'succeeded') {
                            toast.success(texts.successText);

                            history.push('/account/my_account');

                            return;
                        }
                    }
                }
            }

            dispatch(setLoading(false));
        }
    }

    function createNewCardField() {
        setAddNewCard(true);

        setCardNumberComplete(false);
        setCardCvcComplete(false);
        setCardExpiryComplete(false);
        setFilledInForm(false);
        setFormReady(false);
    }

    function deleteNewCardField() {
        setAddNewCard(false);

        setCardNumberComplete(true);
        setCardCvcComplete(true);
        setCardExpiryComplete(true);
        setFilledInForm(true);
        setFormReady(true);

        setTimeout(() => {
            if (currency.format === 'BRL') {
                formRef.current.setFieldValue(
                    'description',
                    paymentMethod['description']
                );
                formRef.current.setFieldValue(
                    'display_number',
                    paymentMethod['data']['display_number']
                );
                formRef.current.setFieldValue(
                    'expiration_date',
                    `${paymentMethod['data']['month']}/${paymentMethod['data']['year']}`
                );
            } else {
                formRef.current.setFieldValue(
                    'description',
                    paymentMethod['description']
                );
                formRef.current.setFieldValue(
                    'display_number',
                    '•••• •••• •••• ' + paymentMethod['card']['last4']
                );
                formRef.current.setFieldValue(
                    'expiration_date',
                    `${paymentMethod['card']['exp_month']}/${paymentMethod['card']['exp_year']}`
                );
            }
            console.log('formData', formRef.current.getData());
        }, 50);
    }

    useEffect(() => {
        formRef.current.reset();

        let price = 0;

        let pro_rata_price = undefined;

        if (plan.type === 'sleep_diary') {
            price = currency.format === 'BRL' ? plan.price_brl : plan.price_usd;

            if (sleepDiarySubscription) {
                if (sleepDiarySubscription.active) {
                    if (sleepDiarySubscription.plan.name.includes('Trial')) {
                        // user wants to create a new paid sleep diary subscription

                        const hasActivePaidLumusSubscription =
                            lumusSubscription &&
                            lumusSubscription.active &&
                            !lumusSubscription.plan.name.includes('Trial') &&
                            !lumusSubscription.plan.name.includes('Unlimited');

                        if (hasActivePaidLumusSubscription) {
                            if (currency.format === 'BRL') {
                                pro_rata_price = currentUser?.subscription
                                    ?.last_billing_date
                                    ? getNewSubscriptionProRataValue(
                                          price,
                                          currentUser?.subscription
                                              ?.billing_date,
                                          currentUser?.subscription
                                              ?.last_billing_date
                                      )
                                    : undefined;
                            } else {
                                pro_rata_price = amountDue;
                            }
                        }
                    } else {
                        // user wants to upgrade a paid subscription
                        if (currency.format === 'BRL') {
                            pro_rata_price = currentUser?.subscription
                                ?.last_billing_date
                                ? getPlanUpgradeProRataValue(
                                      price,
                                      sleepDiarySubscription.price,
                                      currentUser?.subscription?.billing_date,
                                      currentUser?.subscription
                                          ?.last_billing_date
                                  )
                                : undefined;
                        } else {
                            pro_rata_price = amountDue;
                        }
                    }
                } else {
                    const hasActivePaidLumusSubscription =
                        lumusSubscription &&
                        lumusSubscription.active &&
                        !lumusSubscription.plan.name.includes('Trial') &&
                        !lumusSubscription.plan.name.includes('Unlimited');

                    if (hasActivePaidLumusSubscription) {
                        if (currency.format === 'BRL') {
                            pro_rata_price = currentUser?.subscription
                                ?.last_billing_date
                                ? getNewSubscriptionProRataValue(
                                      price,
                                      currentUser?.subscription?.billing_date,
                                      currentUser?.subscription
                                          ?.last_billing_date
                                  )
                                : undefined;
                        } else {
                            pro_rata_price = amountDue;
                        }
                    }
                }
            }
        } else {
            price = getLumusPrice(
                plan.prices,
                numberOfDevices,
                currency.format
            );

            if (lumusSubscription) {
                if (lumusSubscription.active) {
                    if (lumusSubscription.plan.name.includes('Trial')) {
                        // user wants to create a new paid lumus subscription

                        const hasActivePaidSleepDiarySubscription =
                            sleepDiarySubscription &&
                            sleepDiarySubscription.active &&
                            !sleepDiarySubscription.plan.name.includes(
                                'Trial'
                            ) &&
                            !sleepDiarySubscription.plan.name.includes(
                                'Unlimited'
                            );

                        if (hasActivePaidSleepDiarySubscription) {
                            if (currency.format === 'BRL') {
                                pro_rata_price = currentUser?.subscription
                                    ?.last_billing_date
                                    ? getNewSubscriptionProRataValue(
                                          price,
                                          currentUser?.subscription
                                              ?.billing_date,
                                          currentUser?.subscription
                                              ?.last_billing_date
                                      )
                                    : undefined;
                            } else {
                                pro_rata_price = amountDue;
                            }
                        }
                    } else {
                        // user wants to upgrade a paid subscription
                        if (currency.format === 'BRL') {
                            pro_rata_price = currentUser?.subscription
                                ?.last_billing_date
                                ? getPlanUpgradeProRataValue(
                                      price,
                                      lumusSubscription.price,
                                      currentUser?.subscription?.billing_date,
                                      currentUser?.subscription
                                          ?.last_billing_date
                                  )
                                : undefined;
                        } else {
                            pro_rata_price = amountDue;
                        }
                    }
                } else {
                    const hasActivePaidSleepDiarySubscription =
                        sleepDiarySubscription &&
                        sleepDiarySubscription.active &&
                        !sleepDiarySubscription.plan.name.includes('Trial') &&
                        !sleepDiarySubscription.plan.name.includes('Unlimited');

                    if (hasActivePaidSleepDiarySubscription) {
                        if (currency.format === 'BRL') {
                            pro_rata_price = currentUser?.subscription
                                ?.last_billing_date
                                ? getNewSubscriptionProRataValue(
                                      price,
                                      currentUser?.subscription?.billing_date,
                                      currentUser?.subscription
                                          ?.last_billing_date
                                  )
                                : undefined;
                        } else {
                            pro_rata_price = amountDue;
                        }
                    }
                }
            }
        }

        if (pro_rata_price !== undefined && pro_rata_price < 0) {
            pro_rata_price = 0;
        }

        const initialData = {
            name: plan.name,
            max_patients:
                plan.type === 'sleep_diary' ? plan.max_patients : null,
            number_of_devices: plan.type === 'lumus' ? numberOfDevices : null,
            payable_with: texts.payableWithTexts[plan.payable_with],
            fidelity: texts.fidelityTexts[plan.fidelity],
            price: currencyFormatter(price, currency.locale, currency.format),
            pro_rata_price: currencyFormatter(
                pro_rata_price !== undefined ? pro_rata_price : price,
                currency.locale,
                currency.format
            ),
        };

        if (paymentMethod !== null) {
            if (currency.format === 'BRL') {
                initialData['description'] = paymentMethod['description'];
                initialData['display_number'] =
                    paymentMethod['data']['display_number'];
                initialData[
                    'expiration_date'
                ] = `${paymentMethod['data']['month']}/${paymentMethod['data']['year']}`;
            } else {
                initialData['description'] = paymentMethod['description'];
                initialData['display_number'] =
                    '•••• •••• •••• ' + paymentMethod['card']['last4'];
                initialData[
                    'expiration_date'
                ] = `${paymentMethod['card']['exp_month']}/${paymentMethod['card']['exp_year']}`;
            }
        }

        formRef.current.setData(initialData);
    }, [lang]);

    useEffect(() => {
        if (currency.format === 'BRL') {
            if (filledInForm) {
                setFormReady(true);
            } else {
                setFormReady(false);
            }
        } else {
            if (cardNumberComplete && cardExpiryComplete && cardCvcComplete) {
                setFormReady(true);
            } else {
                setFormReady(false);
            }
        }
    }, [cardNumberComplete, cardExpiryComplete, cardCvcComplete, filledInForm]);

    return (
        <Form ref={formRef} onSubmit={handleSubmit} onKeyUp={handleKeyUpForm}>
            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-12">
                    <span>{texts.plantext}</span>
                </FormGroup>
            </FormGroup>

            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-4">
                    <Input
                        name="name"
                        type="text"
                        label={texts.nameText}
                        readOnly
                    />
                </FormGroup>

                {plan.type === 'sleep_diary' && (
                    <FormGroup className="col-xs-12 col-md-3">
                        <Input
                            name="max_patients"
                            type="text"
                            label={texts.maxPatientsText}
                            readOnly
                        />
                    </FormGroup>
                )}

                {plan.type === 'lumus' && (
                    <FormGroup className="col-xs-12 col-md-3">
                        <Input
                            name="number_of_devices"
                            type="text"
                            label={texts.numberOfDevicesText}
                            readOnly
                        />
                    </FormGroup>
                )}
            </FormGroup>

            <hr />

            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-12">
                    <span>{texts.subscriptionText}</span>
                </FormGroup>
            </FormGroup>

            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-2">
                    <Input
                        name="fidelity"
                        type="text"
                        label={texts.fidelityText}
                        readOnly
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-3">
                    <Input
                        name="payable_with"
                        type="text"
                        label={texts.payableWithText}
                        readOnly
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-2">
                    <Input
                        name="price"
                        type="text"
                        label={texts.priceText}
                        readOnly
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-3">
                    <Input
                        name="pro_rata_price"
                        type="text"
                        label={texts.proRataPriceText}
                        readOnly
                    />
                </FormGroup>
            </FormGroup>

            <hr />

            {currency.format === 'BRL' && (
                <>
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-12">
                            <span>{texts.cardDataText}</span>
                        </FormGroup>
                    </FormGroup>

                    {paymentMethod ? (
                        <>
                            {!addNewCard && (
                                <>
                                    <FormGroup row>
                                        <FormGroup className="col-xs-12 col-md-3">
                                            <Input
                                                name="description"
                                                type="text"
                                                label={
                                                    texts.cardDescriptionText
                                                }
                                                readOnly
                                            />
                                        </FormGroup>

                                        <FormGroup className="col-xs-12 col-md-3">
                                            <Input
                                                name="display_number"
                                                type="text"
                                                label={texts.cardNumberText}
                                                readOnly
                                            />
                                        </FormGroup>

                                        <FormGroup className="col-xs-12 col-md-3">
                                            <Input
                                                name="expiration_date"
                                                type="text"
                                                label={texts.expirationDateText}
                                                readOnly
                                            />
                                        </FormGroup>
                                    </FormGroup>

                                    <FormGroup row>
                                        <div className="col-xs-12 col-md-12 text-left">
                                            <Button
                                                type="button"
                                                color="primary"
                                                onClick={() =>
                                                    createNewCardField()
                                                }
                                            >
                                                {texts.addNewCardText}
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </>
                            )}

                            {addNewCard && (
                                <FormGroup row>
                                    <FormGroup className="col-xs-12 col-md-3">
                                        <Input
                                            name="description"
                                            type="text"
                                            label={
                                                texts.requiredCardDescriptionText
                                            }
                                        />
                                    </FormGroup>

                                    <FormGroup className="col-xs-12 col-md-5">
                                        <IuguCreditCardForm
                                            fields={
                                                pageData.fields.new_card_info
                                            }
                                            imgAltTexts={texts.imgAltTexts}
                                            creditCardLabelText={texts.cardText}
                                            iugu={iugu}
                                        />
                                    </FormGroup>

                                    <FormGroup className="col-xs-12 col-md-4">
                                        <Button
                                            color="danger"
                                            type="button"
                                            onClick={() => deleteNewCardField()}
                                            title={texts.deleteCardText}
                                        >
                                            <FaTrash />
                                        </Button>
                                    </FormGroup>
                                </FormGroup>
                            )}
                        </>
                    ) : (
                        <FormGroup row>
                            <FormGroup className="col-xs-12 col-md-3">
                                <Input
                                    name="description"
                                    type="text"
                                    label={texts.requiredCardDescriptionText}
                                />
                            </FormGroup>

                            <FormGroup className="col-xs-12 col-md-5">
                                <IuguCreditCardForm
                                    fields={pageData.fields.new_card_info}
                                    imgAltTexts={texts.imgAltTexts}
                                    creditCardLabelText={texts.cardText}
                                    iugu={iugu}
                                />
                            </FormGroup>
                        </FormGroup>
                    )}
                </>
            )}

            {currency.format !== 'BRL' && (
                <>
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-12">
                            <span>{texts.cardDataText}</span>
                        </FormGroup>
                    </FormGroup>

                    {paymentMethod ? (
                        <>
                            {!addNewCard && (
                                <>
                                    <FormGroup row>
                                        <FormGroup className="col-xs-12 col-md-3">
                                            <Input
                                                name="display_number"
                                                type="text"
                                                label={texts.cardNumberText}
                                                readOnly
                                            />
                                        </FormGroup>

                                        <FormGroup className="col-xs-12 col-md-3">
                                            <Input
                                                name="expiration_date"
                                                type="text"
                                                label={texts.expirationDateText}
                                                readOnly
                                            />
                                        </FormGroup>
                                    </FormGroup>

                                    <FormGroup row>
                                        <div className="col-xs-12 col-md-12 text-left">
                                            <Button
                                                type="button"
                                                color="primary"
                                                onClick={() =>
                                                    createNewCardField()
                                                }
                                            >
                                                {texts.addNewCardText}
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </>
                            )}

                            {addNewCard && (
                                <div className="d-flex">
                                    <div className="col-xs-12 col-md-3 pl-0 pr-0">
                                        <StripeForm
                                            setCardNumberComplete={
                                                setCardNumberComplete
                                            }
                                            setCardExpiryComplete={
                                                setCardExpiryComplete
                                            }
                                            setCardCvcComplete={
                                                setCardCvcComplete
                                            }
                                        />
                                    </div>

                                    <div className="col-xs-12 col-md-1">
                                        <Button
                                            color="danger"
                                            type="button"
                                            onClick={() => deleteNewCardField()}
                                            title={texts.deleteCardText}
                                        >
                                            <FaTrash />
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="d-flex">
                            <div className="col-xs-12 col-md-3 pl-0 pr-0">
                                <StripeForm
                                    setCardNumberComplete={
                                        setCardNumberComplete
                                    }
                                    setCardExpiryComplete={
                                        setCardExpiryComplete
                                    }
                                    setCardCvcComplete={setCardCvcComplete}
                                />
                            </div>
                        </div>
                    )}
                </>
            )}

            <hr />

            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button
                        color="primary"
                        type="submit"
                        disabled={!formReady}
                        className="mr-2"
                    >
                        {texts.confirmText}
                    </Button>

                    <Link
                        to={{
                            pathname:
                                '/account/my_account/change_plan/choose_subscription',
                            state: {
                                plans,
                                currency,
                                paymentMethod,
                                plansGroups,
                                planType: plan.type,
                            },
                        }}
                        className="btn btn-secondary"
                    >
                        {texts.goBackText}
                    </Link>
                </div>
            </FormGroup>
        </Form>
    );
}

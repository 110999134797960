export default {
    pt: {
        customer_admin: null,
        manager: {
            nameLabelText: 'Nome',
            namePlaceholderText: 'Digite o nome do gestor...',
            secondaryIdLabelText: 'ID Secundário',
            secondaryIdPlaceholderText: 'Digite o id secundário do gestor...',
            emailLabelText: 'e-Mail',
            emailPlaceholderText: 'Digite o e-Mail do gestor...',
            activeLabelText: 'Ativo',
            sharePatientsLabelText: 'Compartilhar pacientes?',
            languageLabelText: 'Idioma',
            yesText: 'Sim',
            noText: 'Não',
            portugueseText: 'Português',
            englishText: 'Inglês',
            germanyText: 'Alemão',
            addText: 'Adicionar',
            cancelText: 'Cancelar',
            requiredFieldText: 'Campos obrigatórios!',
            successText: 'Gestor adicionado com sucesso!',
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            nameLabelText: 'Name',
            namePlaceholderText: `Enter the manager's name...`,
            secondaryIdLabelText: 'Secondary ID',
            secondaryIdPlaceholderText: `Enter the manager's secondary id...`,
            emailLabelText: 'e-Mail',
            emailPlaceholderText: `Enter the manager's e-Mail...`,
            activeLabelText: 'Active',
            sharePatientsLabelText: 'Share patients?',
            languageLabelText: 'Language',
            yesText: 'Yes',
            noText: 'No',
            portugueseText: 'Portuguese',
            englishText: 'English',
            germanyText: 'German',
            addText: 'Add',
            cancelText: 'Cancel',
            requiredFieldText: 'Required fields!',
            successText: 'Manager successfully added!',
        },
        condor_admin: null,
    },
};

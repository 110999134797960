import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardFooter } from 'reactstrap';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import history from '../../../../../../services/history';
import { setLoading } from '../../../../../../store/actions/loadingActions';
import { getStripeFutureInvoiceValue } from '../../../../../../services/api/account';
import { ReviewAndPaymentForm } from './components/form';

ReviewAndPayment.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function ReviewAndPayment({ currentUser }) {
    const [plan, setPlan] = useState(null);
    const [plans, setPlans] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [loading, updateLoading] = useState(false);

    const [plansGroups, setPlansGroups] = useState(null);
    const [numberOfDevices, setNumberOfDevices] = useState(null);

    const [prorationDate, setProrationDate] = useState(null);
    const [amountDue, setAmuntDue] = useState(null);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];
    const schemaData = schemaConfig[lang][currentUser.permissions];

    useEffect(() => {
        (async function () {
            updateLoading(true);

            if (history.location.state?.plan) {
                setPlans(history.location.state.plans);
                setCurrency(history.location.state.currency);
                setPaymentMethod(history.location.state.paymentMethod);
                setPlansGroups(history.location.state.plansGroups);
                setNumberOfDevices(history.location.state.numberOfDevices);
                setPlan(history.location.state.plan);

                if (history.location.state.currency.locale === 'en-US') {
                    dispatch(setLoading(true));

                    const data = await getStripeFutureInvoiceValue(
                        history.location.state.plan.id,
                        history.location.state.numberOfDevices
                    );

                    if (data) {
                        setProrationDate(data?.proration_date);
                        setAmuntDue(data?.amount_due);
                    }

                    dispatch(setLoading(false));
                }
            } else {
                history.push(
                    '/account/my_account/change_plan/choose_subscription'
                );
            }

            updateLoading(false);
        })();
    }, []);

    if (loading || plan === null) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <ReviewAndPaymentForm
                    pageData={pageData}
                    schema={
                        paymentMethod || currency.format !== 'BRL'
                            ? schemaData.schema
                            : schemaData.schemaWithNewCard
                    }
                    plan={plan}
                    plans={plans}
                    paymentMethod={paymentMethod}
                    currency={currency}
                    plansGroups={plansGroups}
                    numberOfDevices={numberOfDevices}
                    prorationDate={prorationDate}
                    amountDue={amountDue}
                    texts={{
                        imgAltTexts: pageData.imgAltTexts,
                        payableWithTexts: pageData.payableWithTexts,
                        fidelityTexts: pageData.fidelityTexts,
                        plantext: pageData.plantext,
                        subscriptionText: pageData.subscriptionText,
                        cardDataText: pageData.cardDataText,
                        cardText: pageData.cardText,
                        confirmText: pageData.confirmText,
                        goBackText: pageData.goBackText,
                        requiredFieldText: pageData.requiredFieldText,
                        successText: pageData.successText,
                        paymentFailedText: pageData.paymentFailedText,
                        iuguErrorsText: pageData.iuguErrorsText,
                        stripeErrorsTexts: pageData.stripeErrorsTexts,
                        nameText: pageData.nameText,
                        maxPatientsText: pageData.maxPatientsText,
                        numberOfDevicesText: pageData.numberOfDevicesText,
                        fidelityText: pageData.fidelityText,
                        payableWithText: pageData.payableWithText,
                        priceText: pageData.priceText,
                        proRataPriceText: pageData.proRataPriceText,
                        cardDescriptionText: pageData.cardDescriptionText,
                        cardNumberText: pageData.cardNumberText,
                        expirationDateText: pageData.expirationDateText,
                        requiredCardDescriptionText:
                            pageData.requiredCardDescriptionText,
                        addNewCardText: pageData.addNewCardText,
                        deleteCardText: pageData.deleteCardText,
                    }}
                />
            </CardBody>
            <CardFooter>
                <p
                    dangerouslySetInnerHTML={{
                        __html: pageData.requiredFieldPText,
                    }}
                ></p>
            </CardFooter>
        </Card>
    );
}

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { MyForm as Form } from './components/form';
import history from '../../../services/history';

UserCredentials.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function UserCredentials({ currentUser }) {
    const [user, setUser] = useState({});

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];
    const schemaData = schemaConfig[lang][currentUser.permissions];

    useEffect(() => {
        if (history.location.state?.entity) {
            setUser(history.location.state.entity);
        } else {
            history.push('/users');
        }
    }, []);

    if (Object.entries(user).length === 0) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form
                    user={user}
                    schema={schemaData.schema}
                    texts={{
                        successText: pageData.successText,
                        sendText: pageData.sendText,
                        changeEmailText: pageData.changeEmailText,
                        cancelText: pageData.cancelText,
                        nameText: pageData.nameText,
                        emailText: pageData.emailText,
                        langText: pageData.langText,
                        portugueseText: pageData.portugueseText,
                        englishText: pageData.englishText,
                        germanyText: pageData.germanyText,
                    }}
                />
            </CardBody>
        </Card>
    );
}

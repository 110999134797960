import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

import pageConfig from './page.config';
import { setLoading } from '../../store/actions/loadingActions';
import { fetchCoupons } from '../../services/api/coupons';
import {
    localeDateFormatter,
    currencyFormatter,
} from '../../helpers/formatters';
import { useOrderByProperty } from '../../hooks/useOrderByProperty';
import { Search } from '../../components/search';
import Table from '../../components/table/table';
import { ThWithOrdering } from '../../components/table/thWithOrdering';
import Td from '../../components/table/td';
import ActionsContainer from '../../components/table/actionsContainer';

export default function Coupons({ currentUser }) {
    const [coupons, setCoupons] = useState([]);
    const { activeFilter, filteredData, setFilteredData, orderByProperty } =
        useOrderByProperty(coupons, 'createdAt', 'asc');

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);
    const { dateLocale } = useSelector((state) => state.locale);
    const isLoading = useSelector((state) => state.loading.isLoading);

    const pageData = pageConfig[lang][currentUser.permissions];

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            const data = await fetchCoupons();

            if (data) {
                const formattedCoupons = data?.coupons.map((coupon) => {
                    let tempCurrencyFormat = coupon.currency.includes('BRL')
                        ? {
                              ...pageData.currency_pt_BR,
                          }
                        : {
                              ...pageData.currency_en_US,
                          };

                    return {
                        id: coupon.id,
                        name: coupon.name,
                        percentOff: coupon.percent_off,
                        formattedPercentOff: coupon.percent_off
                            ? `${coupon.percent_off}%`
                            : 'N/A',
                        amountOff: coupon.amount_off,
                        formattedAmountOff: coupon.amount_off
                            ? currencyFormatter(
                                  coupon.amount_off,
                                  tempCurrencyFormat.currencyLocale,
                                  tempCurrencyFormat.currencyFormat
                              )
                            : 'N/A',
                        duration: coupon.duration,
                        currency: coupon.currency,
                        formattedCurrency: coupon.currency.join(', '),
                        descriptor: coupon.descriptor,
                        createdAt: localeDateFormatter(
                            coupon.created_at,
                            dateLocale
                        )
                            ? localeDateFormatter(coupon.created_at, dateLocale)
                            : 'N/A',
                    };
                });

                setCoupons(formattedCoupons);
            }

            dispatch(setLoading(false));
        })();
    }, []);

    if (isLoading || pageData === null) {
        return <></>;
    }

    return (
        <>
            <Row>
                <Col xs="12">
                    <div className={styles.searchAndAddContainer}>
                        <Search
                            searchByProperty={'name'}
                            searchByText={pageData.searchByNameText}
                            entities={coupons}
                            setFilteredEntities={setFilteredData}
                        />
                        <Link
                            to={{
                                pathname: '/coupons/add',
                            }}
                            className="btn btn-primary ml-2"
                            title={pageData.addCouponText}
                        >
                            {pageData.addText}
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Table className={styles.couponsTable}>
                        <thead>
                            <tr>
                                {pageData.ths.map((th) => {
                                    switch (th.property) {
                                        case 'createdAt':
                                        case 'name': {
                                            return (
                                                <ThWithOrdering
                                                    key={th.property}
                                                    property={th.property}
                                                    activeFilterProperty={
                                                        activeFilter.property
                                                    }
                                                    activeOrder={
                                                        activeFilter.order
                                                    }
                                                    orderByProperty={
                                                        orderByProperty
                                                    }
                                                    activeClassName={
                                                        styles.active
                                                    }
                                                    clickableIconClassName={
                                                        styles.clickableIcon
                                                    }
                                                >
                                                    {th.name}
                                                </ThWithOrdering>
                                            );
                                        }
                                        default: {
                                            return (
                                                <th key={th.property}>
                                                    {th.name}
                                                </th>
                                            );
                                        }
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((coupon) => (
                                <tr key={coupon.id}>
                                    {pageData.tds.map((td, index) => {
                                        switch (td.property) {
                                            case 'actions':
                                                return (
                                                    <Td
                                                        key={index}
                                                        title={td.th}
                                                    >
                                                        <ActionsContainer
                                                            actions={
                                                                pageData.actions
                                                            }
                                                            entity={coupon}
                                                        />
                                                    </Td>
                                                );
                                            default:
                                                return (
                                                    <Td
                                                        key={index}
                                                        title={td.th}
                                                    >
                                                        {coupon[td.property]}
                                                    </Td>
                                                );
                                        }
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );
}

Coupons.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

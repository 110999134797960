export default {
    pt: {
        sleepDiaryText: 'Diário de Sono',
        twoFactorAuthenticationText: 'Autenticação de dois fatores',
        insertCodeToCompleteLoginText:
            'Insira um código 2FA para concluir o login',
        insertOtpCodeText: 'Código de autenticação',
        accessToEmailText: 'Não tem acesso ao seu email?',
        clickToInsertRecoveryCodeText: 'Insira um código de recuperação.',
        verifyText: 'Verificar',
        loadingText: 'Aguarde...',
        cancelText: 'Cancelar',
    },
    en: {
        sleepDiaryText: 'Sleep Diary',
        twoFactorAuthenticationText: 'Two-factor authentication',
        insertCodeToCompleteLoginText: 'Insert a 2FA code to complete login',
        insertOtpCodeText: 'Authentication code',
        accessToEmailText: 'Does not have access to your email?',
        clickToInsertRecoveryCodeText: 'Insert a recovery code.',
        verifyText: 'Verify',
        loadingText: 'Loading...',
        cancelText: 'Cancel',
    },
};

import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            sleepDiarySchema: Yup.object().shape({
                name: Yup.string().required('Campo obrigatório'),
                price_brl: Yup.number().required('Campo obrigatório'),
                price_usd: Yup.number().required('Campo obrigatório'),
                discount: Yup.number().required('Campo obrigatório'),
                max_patients: Yup.number().required('Campo obrigatório'),
                interval: Yup.number().required('Campo obrigatório'),
                interval_type: Yup.string().required('Campo obrigatório'),
                max_cycles: Yup.number().required('Campo obrigatório'),
                payable_with: Yup.string().required('Campo obrigatório'),
                active: Yup.bool().required('Campo obrigatório'),
                visible: Yup.bool().required('Campo obrigatório'),
                plan_group: Yup.bool().required('Campo obrigatório'),
                type: Yup.string().required('Campo obrigatório'),
            }),
            sleepDiaryGroupSchema: Yup.object().shape({
                name: Yup.string().required('Campo obrigatório'),
                price_brl: Yup.number().required('Campo obrigatório'),
                price_usd: Yup.number().required('Campo obrigatório'),
                biannual_price_brl: Yup.number().required('Campo obrigatório'),
                biannual_price_usd: Yup.number().required('Campo obrigatório'),
                annual_price_brl: Yup.number().required('Campo obrigatório'),
                annual_price_usd: Yup.number().required('Campo obrigatório'),
                discount: Yup.number().required('Campo obrigatório'),
                max_patients: Yup.number().required('Campo obrigatório'),
                interval: Yup.number().required('Campo obrigatório'),
                interval_type: Yup.string().required('Campo obrigatório'),
                max_cycles: Yup.number().required('Campo obrigatório'),
                payable_with: Yup.string().required('Campo obrigatório'),
                active: Yup.bool().required('Campo obrigatório'),
                visible: Yup.bool().required('Campo obrigatório'),
                plan_group: Yup.bool().required('Campo obrigatório'),
                type: Yup.string().required('Campo obrigatório'),
            }),
            lumusSchema: Yup.object().shape({
                name: Yup.string().required('Campo obrigatório'),
                prices: Yup.array().of(
                    Yup.object().shape({
                        up_to: Yup.number().required('Campo obrigatório'),
                        price_brl: Yup.number().required('Campo obrigatório'),
                        price_usd: Yup.number().required('Campo obrigatório'),
                    })
                ),
                max_patients: Yup.number().required('Required field'),
                discount: Yup.number().required('Campo obrigatório'),
                interval: Yup.number().required('Campo obrigatório'),
                interval_type: Yup.string().required('Campo obrigatório'),
                max_cycles: Yup.number().required('Campo obrigatório'),
                payable_with: Yup.string().required('Campo obrigatório'),
                active: Yup.bool().required('Campo obrigatório'),
                visible: Yup.bool().required('Campo obrigatório'),
                plan_group: Yup.bool().required('Campo obrigatório'),
                type: Yup.string().required('Campo obrigatório'),
            }),
            lumusGroupSchema: Yup.object().shape({
                name: Yup.string().required('Campo obrigatório'),
                prices: Yup.array().of(
                    Yup.object().shape({
                        up_to: Yup.number().required('Campo obrigatório'),
                        price_brl: Yup.number().required('Campo obrigatório'),
                        price_usd: Yup.number().required('Campo obrigatório'),
                    })
                ),
                biannual_prices: Yup.array().of(
                    Yup.object().shape({
                        up_to: Yup.number().required('Campo obrigatório'),
                        price_brl: Yup.number().required('Campo obrigatório'),
                        price_usd: Yup.number().required('Campo obrigatório'),
                    })
                ),
                annual_prices: Yup.array().of(
                    Yup.object().shape({
                        up_to: Yup.number().required('Campo obrigatório'),
                        price_brl: Yup.number().required('Campo obrigatório'),
                        price_usd: Yup.number().required('Campo obrigatório'),
                    })
                ),
                discount: Yup.number().required('Campo obrigatório'),
                max_patients: Yup.number().required('Campo obrigatório'),
                interval: Yup.number().required('Campo obrigatório'),
                interval_type: Yup.string().required('Campo obrigatório'),
                max_cycles: Yup.number().required('Campo obrigatório'),
                payable_with: Yup.string().required('Campo obrigatório'),
                active: Yup.bool().required('Campo obrigatório'),
                visible: Yup.bool().required('Campo obrigatório'),
                plan_group: Yup.bool().required('Campo obrigatório'),
                type: Yup.string().required('Campo obrigatório'),
            }),
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            sleepDiarySchema: Yup.object().shape({
                name: Yup.string().required('Required field'),
                price_brl: Yup.number().required('Required field'),
                price_usd: Yup.number().required('Required field'),
                discount: Yup.number().required('Required field'),
                max_patients: Yup.number().required('Required field'),
                interval: Yup.number().required('Required field'),
                interval_type: Yup.string().required('Required field'),
                max_cycles: Yup.number().required('Required field'),
                payable_with: Yup.string().required('Required field'),
                active: Yup.bool().required('Required field'),
                visible: Yup.bool().required('Required field'),
                plan_group: Yup.bool().required('Required field'),
                type: Yup.string().required('Required field'),
            }),
            sleepDiaryGroupSchema: Yup.object().shape({
                name: Yup.string().required('Required field'),
                price_brl: Yup.number().required('Required field'),
                price_usd: Yup.number().required('Required field'),
                biannual_price_brl: Yup.number().required('Required field'),
                biannual_price_usd: Yup.number().required('Required field'),
                annual_price_brl: Yup.number().required('Required field'),
                annual_price_usd: Yup.number().required('Required field'),
                discount: Yup.number().required('Required field'),
                max_patients: Yup.number().required('Required field'),
                interval: Yup.number().required('Required field'),
                interval_type: Yup.string().required('Required field'),
                max_cycles: Yup.number().required('Required field'),
                payable_with: Yup.string().required('Required field'),
                active: Yup.bool().required('Required field'),
                visible: Yup.bool().required('Required field'),
                plan_group: Yup.bool().required('Required field'),
                type: Yup.string().required('Required field'),
            }),
            lumusSchema: Yup.object().shape({
                name: Yup.string().required('Required field'),
                prices: Yup.array().of(
                    Yup.object().shape({
                        up_to: Yup.number().required('Required field'),
                        price_brl: Yup.number().required('Required field'),
                        price_usd: Yup.number().required('Required field'),
                    })
                ),
                max_patients: Yup.number().required('Required field'),
                discount: Yup.number().required('Required field'),
                interval: Yup.number().required('Required field'),
                interval_type: Yup.string().required('Required field'),
                max_cycles: Yup.number().required('Required field'),
                payable_with: Yup.string().required('Required field'),
                active: Yup.bool().required('Required field'),
                visible: Yup.bool().required('Required field'),
                plan_group: Yup.bool().required('Required field'),
                type: Yup.string().required('Required field'),
            }),
            lumusGroupSchema: Yup.object().shape({
                name: Yup.string().required('Required field'),
                prices: Yup.array().of(
                    Yup.object().shape({
                        up_to: Yup.number().required('Required field'),
                        price_brl: Yup.number().required('Required field'),
                        price_usd: Yup.number().required('Required field'),
                    })
                ),
                biannual_prices: Yup.array().of(
                    Yup.object().shape({
                        up_to: Yup.number().required('Required field'),
                        price_brl: Yup.number().required('Required field'),
                        price_usd: Yup.number().required('Required field'),
                    })
                ),
                annual_prices: Yup.array().of(
                    Yup.object().shape({
                        up_to: Yup.number().required('Required field'),
                        price_brl: Yup.number().required('Required field'),
                        price_usd: Yup.number().required('Required field'),
                    })
                ),
                discount: Yup.number().required('Required field'),
                max_patients: Yup.number().required('Required field'),
                interval: Yup.number().required('Required field'),
                interval_type: Yup.string().required('Required field'),
                max_cycles: Yup.number().required('Required field'),
                payable_with: Yup.string().required('Required field'),
                active: Yup.bool().required('Required field'),
                visible: Yup.bool().required('Required field'),
                plan_group: Yup.bool().required('Required field'),
                type: Yup.string().required('Required field'),
            }),
        },
    },
};

import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function CustomCard({ value, title, icon, className, color }) {
    return (
        <Card
            className={`customCard ${className ?? ''}`}
            style={
                color
                    ? {
                          backgroundColor: color.startsWith('--')
                              ? `var(${color})`
                              : `${color}`,
                      }
                    : undefined
            }
        >
            <CardBody>
                <Row>
                    <Col xs="7">
                        <h3>
                            {typeof value === 'number' ? (
                                value
                            ) : (
                                <FontAwesomeIcon icon={value} />
                            )}
                        </h3>
                        <p>{title}</p>
                    </Col>
                    <Col xs="5" className="text-right">
                        <FontAwesomeIcon icon={icon} className="iconCard" />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}

CustomCard.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
};

export default CustomCard;

import { faEdit, faEnvelope, faTrash } from '@fortawesome/free-solid-svg-icons';

export default {
    pt: {
        customer_admin: null,
        manager: {
            searchByNameText: 'Procurar gestor por nome',
            searchBySecondaryIdText: 'Procurar gestor por id secundário',
            addText: 'Adicionar',
            idText: 'ID',
            nameText: 'Nome',
            secondaryIdText: 'ID Secundário',
            emailText: 'e-Mail',
            activeText: 'Ativo',
            actionsText: 'Ações',
            yesText: 'Sim',
            noText: 'Não',
            editText: 'Editar',
            credentialsText: 'Credenciais',
            deleteText: 'Excluir',
            noManagersText: 'Você não possui gestores cadastrados!',
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            searchByNameText: 'Search manager by name',
            searchBySecondaryIdText: 'Search manager by secondary ID',
            addText: 'Add',
            idText: 'ID',
            nameText: 'Name',
            secondaryIdText: 'Secondary ID',
            emailText: 'e-Mail',
            activeText: 'Active',
            actionsText: 'Actions',
            yesText: 'Yes',
            noText: 'No',
            editText: 'Edit',
            credentialsText: 'Credentials',
            deleteText: 'Delete',
            noManagersText: `You dont't have registered managers!`,
        },
        condor_admin: null,
    },
};

import React from 'react';
import { Card, CardHeader, CardBody, Table } from 'reactstrap';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import { currencyFormatter } from '../../helpers/formatters';

LumusCardPlan.propTypes = {
    name: PropTypes.string.isRequired,
    prices: PropTypes.object.isRequired,
    currency: PropTypes.object.isRequired,
    texts: PropTypes.object.isRequired,
};

export function LumusCardPlan({ name, prices, currency, texts }) {
    return (
        <Card className={`${styles.planCardContainer}`}>
            <CardHeader className={styles.planCardHeader}>
                <p>{name}</p>
            </CardHeader>
            <CardBody className={styles.planCardBody}>
                <Table>
                    <thead>
                        <tr>
                            <th>{texts.numberOfDevicesText}</th>
                            <th>{texts.monthlyText}</th>
                            <th>{texts.biannualText}</th>
                            <th>{texts.annualText}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(prices).map((price, index, arr) => (
                            <tr key={price}>
                                <td>
                                    {`${
                                        Number(price) === 1000000
                                            ? `${texts.fromText} ${
                                                  Number(arr[index - 1]) + 1
                                              }`
                                            : `${texts.upToText} ${price}`
                                    }`}
                                </td>
                                <td className={styles.price}>
                                    {currencyFormatter(
                                        prices[price].monthly,
                                        currency.locale,
                                        currency.format
                                    )}
                                    <small>
                                        <b>{texts.devicesText}</b>
                                    </small>
                                </td>
                                <td className={styles.price}>
                                    {currencyFormatter(
                                        prices[price].biannual,
                                        currency.locale,
                                        currency.format
                                    )}
                                    <small>
                                        <b>{texts.devicesText}</b>
                                    </small>
                                </td>
                                <td className={styles.price}>
                                    {currencyFormatter(
                                        prices[price].annual,
                                        currency.locale,
                                        currency.format
                                    )}
                                    <small>
                                        <b>{texts.devicesText}</b>
                                    </small>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
}

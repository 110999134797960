import api from '../../services/api';
import axios from 'axios';

export function fetchDashboardData(source) {
    const data = api
        .get('/dashboard', {
            cancelToken: source.token,
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.log('error', error);
            }
        });
    return data;
}

export function fetchCompanyReportData(id, date = null) {
    const data = api
        .get(`/dashboard/company-report/${id}`, {
            params: {
                date,
            },
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.log('error', error);
            }
        });
    return data;
}

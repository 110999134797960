import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            searchByNameInput: 'Procurar plano por nome',
            addButtonLabel: 'Adicionar',
            addButtonTitle: 'Adicionar plano',
            ths: [
                {
                    name: 'Nome',
                    property: 'name',
                },
                {
                    name: 'Preço em Reais',
                    property: 'price_brl',
                },
                {
                    name: 'Preço em dólar',
                    property: 'price_usd',
                },
                {
                    name: 'Desconto',
                    property: 'discount',
                },
                {
                    name: 'Número máximo de pacientes',
                    property: 'max_patients',
                },
                {
                    name: 'Ativo',
                    property: 'active',
                },
                {
                    name: 'Visível',
                    property: 'visible',
                },
                {
                    name: 'Criado em',
                    property: 'created_at',
                },
                {
                    name: 'Atualizado em',
                    property: 'updated_at',
                },
                {
                    name: 'Ações',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'Nome',
                    property: 'name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Preço em real',
                    property: 'price_brl',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Preço em dólar',
                    property: 'price_usd',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Desconto',
                    property: 'discount',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Número máximo de pacientes',
                    property: 'max_patients',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Ativo',
                    property: 'active',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Visível',
                    property: 'visible',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Criado em',
                    property: 'created_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Atualizado em',
                    property: 'updated_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Ações',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Editar',
                    className: 'btn btn-secondary',
                    to: 'plans/edit',
                    icon: faEdit,
                },
                {
                    title: 'Excluir',
                    className: 'btn btn-danger ml-2',
                    to: 'plans/delete',
                    icon: faTrash,
                },
            ],
            currency_pt_BR: {
                currencyLocale: 'pt-BR',
                currencyFormat: 'BRL',
            },
            currency_en_US: {
                currencyLocale: 'en-US',
                currencyFormat: 'USD',
            },
            yesText: 'Sim',
            noText: 'Não',
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            searchByNameInput: 'Search plan by name',
            addButtonLabel: 'Add',
            addButtonTitle: 'Add plan',
            ths: [
                {
                    name: 'Name',
                    property: 'name',
                },
                {
                    name: 'Price in Reais',
                    property: 'price_brl',
                },
                {
                    name: 'Price in dollar',
                    property: 'price_usd',
                },
                {
                    name: 'Discount',
                    property: 'discount',
                },
                {
                    name: 'Maximum number of patients',
                    property: 'max_patients',
                },
                {
                    name: 'Active',
                    property: 'active',
                },
                {
                    name: 'Visible',
                    property: 'visible',
                },
                {
                    name: 'Created in',
                    property: 'created_at',
                },
                {
                    name: 'Updated in',
                    property: 'updated_at',
                },
                {
                    name: 'Actions',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'Name',
                    property: 'name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Price in Reais',
                    property: 'price_brl',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Price in dollar',
                    property: 'price_usd',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Discount',
                    property: 'discount',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Maximum number of patients',
                    property: 'max_patients',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Active',
                    property: 'active',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Visible',
                    property: 'visible',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Created in',
                    property: 'created_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Updated in',
                    property: 'updated_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Actions',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Edit',
                    className: 'btn btn-secondary',
                    to: 'plans/edit',
                    icon: faEdit,
                },
                {
                    title: 'Delete',
                    className: 'btn btn-danger ml-2',
                    to: 'plans/delete',
                    icon: faTrash,
                },
            ],
            currency_pt_BR: {
                currencyLocale: 'pt-BR',
                currencyFormat: 'BRL',
            },
            currency_en_US: {
                currencyLocale: 'en-US',
                currencyFormat: 'USD',
            },
            yesText: 'Yes',
            noText: 'No',
        },
    },
};

import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import pageConfig from './page.config';
import history from '../../services/history';

export default function Exception() {
    const currentUser = useSelector((state) => state.auth.currentUser);

    const lang = useSelector((state) => state.lang.lang);

    const type = history.location.state?.type || 'default';

    const data = pageConfig[lang][type];

    let suspendedMessage = null;

    if (type === 'subscriptionExpired') {
        if (currentUser.subscription.suspended_reason) {
            if (currentUser.subscription.suspended_reason.includes('-')) {
                const [suspendedReason, additionalMessage] =
                    currentUser.subscription.suspended_reason.split('-');

                suspendedMessage = `${
                    data.suspendedReasonsTexts[suspendedReason.trim()]
                } - ${additionalMessage.trim()}`;
            } else {
                suspendedMessage =
                    data.suspendedReasonsTexts[
                        currentUser.subscription.suspended_reason
                    ];
            }
        }
    }

    return (
        <div>
            <h5>{data.label}</h5>
            {type === 'subscriptionExpired' ? (
                <>
                    <p>
                        {data.text}{' '}
                        <b>
                            <Link to={data.link}>{data.linkText}</Link>
                        </b>
                        .
                    </p>
                    {suspendedMessage && (
                        <p>
                            {data.reasonText}: {suspendedMessage}
                        </p>
                    )}
                </>
            ) : (
                <p>{data.text}</p>
            )}
        </div>
    );
}

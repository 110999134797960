import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { MyForm as Form } from './components/form';
import { createYupSchema } from '../../../helpers/yupSchemaCreator';
import history from '../../../services/history';

EditCompany.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function EditCompany({ currentUser }) {
    const [company, setCompany] = useState({});

    const [schema, setSchema] = useState(null);

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];

    const { rawSchema } = schemaConfig[lang][currentUser.permissions];

    useEffect(() => {
        if (history.location.state?.entity) {
            const tempcompany = history.location.state.entity;

            const tempRawSchema = [...rawSchema];

            const descriptorIndex = tempRawSchema.findIndex(
                (fieldValidation) => fieldValidation.id === 'descriptor'
            );

            const minPasswordLengthIndex = tempRawSchema[
                descriptorIndex
            ].fields.findIndex(
                (fieldValidation) => fieldValidation.id === 'minPasswordLength'
            );

            let minPasswordLength = 8;

            let minPasswordLengthText = '';

            if ('minPasswordLength' in tempcompany.descriptor) {
                minPasswordLength = tempcompany.descriptor.minPasswordLength;
            }

            if (lang === 'pt') {
                minPasswordLengthText = `O tamanho mínimo da senha não deve ser menor do que ${minPasswordLength}`;
            } else {
                minPasswordLengthText = `The minimum password length must not be lower than ${minPasswordLength}`;
            }

            tempRawSchema[descriptorIndex].fields[
                minPasswordLengthIndex
            ].validations.unshift({
                type: 'min',
                params: [minPasswordLength, minPasswordLengthText],
            });

            setSchema(
                Yup.object().shape(tempRawSchema.reduce(createYupSchema, {}))
            );

            setCompany(tempcompany);
        } else {
            history.push('/companies');
        }
    }, []);

    if (Object.entries(company).length === 0) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form
                    company={company}
                    fields={pageData.fields}
                    schema={schema}
                    texts={{
                        successText: pageData.successText,
                        saveText: pageData.saveText,
                        cancelText: pageData.cancelText,
                        yesText: pageData.yesText,
                        noText: pageData.noText,
                        defaultLanguageOptions: pageData.defaultLanguageOptions,
                    }}
                />
            </CardBody>
        </Card>
    );
}

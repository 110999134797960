import React, { useMemo } from 'react';
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from '@stripe/react-stripe-js';
import { FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';

import FormRow from './formRow';

const useOptions = () => {
    const options = useMemo(
        () => ({
            style: {
                base: {
                    color: '#495057',
                    letterSpacing: '0.05rem',
                    fontFamily: 'Poppins, sans-serif',
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        }),
        []
    );

    return options;
};

export function StripeForm({
    creditCardLabelText,
    setCardNumberComplete,
    setCardExpiryComplete,
    setCardCvcComplete,
}) {
    const options = useOptions();

    return (
        <>
            <FormRow>
                <FormGroup className="col-xs-12 col-md-12">
                    <label
                        dangerouslySetInnerHTML={{
                            __html: creditCardLabelText,
                        }}
                    ></label>
                    <CardNumberElement
                        options={options}
                        onChange={(event) => {
                            setCardNumberComplete(event.complete);
                        }}
                    />
                </FormGroup>
                <FormGroup className="col-xs-6 col-md-6">
                    <CardExpiryElement
                        options={options}
                        onChange={(event) => {
                            setCardExpiryComplete(event.complete);
                        }}
                    />
                </FormGroup>
                <FormGroup className="col-xs-6 col-md-6">
                    <CardCvcElement
                        options={options}
                        onChange={(event) => {
                            setCardCvcComplete(event.complete);
                        }}
                    />
                </FormGroup>
            </FormRow>
        </>
    );
}

StripeForm.propTypes = {
    creditCardLabelText: PropTypes.string.isRequired,
    setCardNumberComplete: PropTypes.func.isRequired,
    setCardExpiryComplete: PropTypes.func.isRequired,
    setCardCvcComplete: PropTypes.func.isRequired,
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from 'prop-types';

import { usePagination, DOTS } from '../../../hooks/usePagination';
import texts from './texts';
import { useEffect } from 'react';

export function CustomPagination({
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
}) {
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    });

    const lang = useSelector((state) => state.lang.lang);

    const { firstText, previousText, nextText, lastText } = texts[lang];

    if (currentPage === 0) {
        return null;
    }

    let lastPage = paginationRange[paginationRange.length - 1];

    useEffect(() => {
        if (currentPage > lastPage) {
            onPageChange(lastPage);
        }
    }, [currentPage, lastPage]);

    const onFirst = () => {
        onPageChange(1);
    };

    const onLast = () => {
        onPageChange(lastPage);
    };

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    return (
        <Pagination>
            <PaginationItem disabled={currentPage === 1}>
                <PaginationLink title={firstText} first onClick={onFirst} />
            </PaginationItem>
            <PaginationItem disabled={currentPage === 1}>
                <PaginationLink
                    title={previousText}
                    previous
                    onClick={onPrevious}
                />
            </PaginationItem>
            {paginationRange.map((pageNumber, index) => {
                if (pageNumber === DOTS) {
                    return (
                        <PaginationItem key={pageNumber + String(index)}>
                            <PaginationLink>&#8230;</PaginationLink>
                        </PaginationItem>
                    );
                }

                return (
                    <PaginationItem
                        key={pageNumber}
                        active={pageNumber === currentPage}
                    >
                        <PaginationLink
                            onClick={() => onPageChange(pageNumber)}
                        >
                            {pageNumber}
                        </PaginationLink>
                    </PaginationItem>
                );
            })}
            <PaginationItem disabled={currentPage === lastPage}>
                <PaginationLink title={nextText} next onClick={onNext} />
            </PaginationItem>
            <PaginationItem disabled={currentPage === lastPage}>
                <PaginationLink title={lastText} last onClick={onLast} />
            </PaginationItem>
        </Pagination>
    );
}

CustomPagination.propTypes = {
    onPageChange: PropTypes.func.isRequired,
    totalCount: PropTypes.number.isRequired,
    siblingCount: PropTypes.number,
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
};

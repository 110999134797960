import api from '../../services/api';

export function fetchPlans(type = null, free = null) {
    const data = api
        .get('/plans', {
            params: {
                type,
                free,
            },
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function addPlan(parms) {
    const data = api
        .post(`/plans`, { ...parms }, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function editPlan(id, parms) {
    const data = api
        .put(`/plans/${id}`, { ...parms }, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function deletePlan(id) {
    const data = api
        .delete(`/plans/${id}`, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

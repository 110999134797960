import React, { useState } from 'react';
import { useMotionValue, Reorder } from 'framer-motion';
import { Switch } from '@headlessui/react';
import PropTypes from 'prop-types';

import styles from './question.module.css';

import { useRaisedShadow } from '../../../hooks/useRaisedShadow';
import { EditModal } from './editModal';
import { DeleteModal } from './deleteModal';

export function Question({
    question,
    answerTypes,
    editMode,
    texts,
    fields,
    schemas,
    handleEditQuestion,
    handleDeleteQuestion,
    className,
}) {
    const y = useMotionValue(0);
    const boxShadow = useRaisedShadow(y);

    const [enabled, setEnabled] = useState(question?.descriptor?.isVisible);

    function handleChangeToogle(newToogleState) {
        handleEditQuestion(question, {
            isVisible: newToogleState,
        });

        setEnabled(newToogleState);
    }

    return editMode && !question.descriptor.default ? (
        <Reorder.Item
            as="div"
            value={question}
            id={question}
            style={{ boxShadow, y }}
            className={`${styles.question} ${className}`}
        >
            <div
                className={
                    question.descriptor.isVisible ? '' : styles.inactiveQuestion
                }
            >
                <p className={styles.questionTitle}>
                    {question.descriptor.fieldtext}
                </p>
                <div className="d-flex">
                    <p className="font-weight-bold">{texts.answerTypeText}:</p>
                    <p>{answerTypes[question.descriptor.type]}</p>
                </div>
                <div className="d-flex">
                    <p className="font-weight-bold">{texts.requiredText}:</p>
                    <p>
                        {question.descriptor.required
                            ? texts.yesText
                            : texts.noText}
                    </p>
                </div>
                <div className="d-flex">
                    <p className="font-weight-bold">{texts.activeText}:</p>
                    <p
                        className={
                            question.descriptor.isVisible
                                ? styles.active
                                : styles.inactive
                        }
                    >
                        {question.descriptor.isVisible
                            ? texts.yesText
                            : texts.noText}
                    </p>
                </div>
            </div>
            {editMode ? (
                <div className={styles.actionsContainer}>
                    {question.descriptor.editable && (
                        <EditModal
                            question={question}
                            texts={texts}
                            fields={fields}
                            schemas={schemas}
                            handleEditQuestion={handleEditQuestion}
                        />
                    )}
                    {!question.descriptor.default && (
                        <DeleteModal
                            question={question}
                            texts={texts}
                            handleDeleteQuestion={handleDeleteQuestion}
                        />
                    )}
                </div>
            ) : null}
        </Reorder.Item>
    ) : (
        <div className={`${styles.question} ${className}`}>
            <div
                className={
                    question.descriptor.isVisible ? '' : styles.inactiveQuestion
                }
            >
                <p className={styles.questionTitle}>
                    {question.descriptor.fieldtext}
                </p>
                <div className="d-flex">
                    <p className="font-weight-bold">{texts.answerTypeText}:</p>
                    <p>{answerTypes[question.descriptor.type]}</p>
                </div>
                <div className="d-flex">
                    <p className="font-weight-bold">{texts.requiredText}:</p>
                    <p>
                        {question.descriptor.required
                            ? texts.yesText
                            : texts.noText}
                    </p>
                </div>
                <div className="d-flex">
                    <p className="font-weight-bold">{texts.activeText}:</p>
                    <p
                        className={
                            question.descriptor.isVisible
                                ? styles.active
                                : styles.inactive
                        }
                    >
                        {question.descriptor.isVisible
                            ? texts.yesText
                            : texts.noText}
                    </p>
                </div>
            </div>
            {editMode ? (
                <div className={styles.actionsContainer}>
                    {question.descriptor.default && (
                        <Switch
                            checked={enabled}
                            onChange={handleChangeToogle}
                            className={`${styles.toggleButton} ${
                                enabled ? styles.enabled : ''
                            }`}
                        >
                            <span
                                className={`${styles.toggleIcon} ${
                                    enabled ? styles.enabled : ''
                                }`}
                            />
                        </Switch>
                    )}
                    {question.descriptor.editable &&
                        !question.descriptor.default && (
                            <EditModal
                                question={question}
                                texts={texts}
                                fields={fields}
                                schemas={schemas}
                                handleEditQuestion={handleEditQuestion}
                            />
                        )}
                    {!question.descriptor.default && (
                        <DeleteModal
                            question={question}
                            texts={texts}
                            handleDeleteQuestion={handleDeleteQuestion}
                        />
                    )}
                </div>
            ) : null}
        </div>
    );
}

Question.propTypes = {
    question: PropTypes.object.isRequired,
    answerTypes: PropTypes.object.isRequired,
    editMode: PropTypes.bool.isRequired,
    texts: PropTypes.object.isRequired,
    fields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    schemas: PropTypes.object,
    handleEditQuestion: PropTypes.func,
    handleDeleteQuestion: PropTypes.func,
    className: PropTypes.string,
};

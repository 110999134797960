import axios from 'axios';

import { store } from '../../store';

export function fetchCountry(userIp) {
    const tempInstance = axios.create({
        baseURL: process.env.REACT_APP_URL_API,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const { lang } = store.getState().lang;

    tempInstance.defaults.headers.lang = lang;

    const data = tempInstance
        .get(`/ip/${userIp}`)
        .then((response) => {
            return response?.data;
        })
        .catch((error) => {
            console.log('error', error);
        });

    return data;
}

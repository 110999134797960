import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Row, Col, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import { Reorder } from 'framer-motion';
import lodash from 'lodash';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { setLoading } from '../../../store/actions/loadingActions';
import history from '../../../services/history';
import { editDiary } from '../../../services/api/diaries';
import { validateForm } from '../../../helpers/formValidator';
import { Question } from '../components/question';
import { AddModal } from '../components/addModal';
import FormFields from '../../../components/form/formFields';

export default function EditDiary({ currentUser }) {
    const formRef = useRef(null);

    const dispatch = useDispatch();
    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];
    const schemas = schemaConfig[lang][currentUser.permissions];

    const [diary, setDiary] = useState({});
    const [editedDefaultQuestions, setEditedDefaultQuestions] = useState(
        pageData?.defaultQuestions ? [...pageData?.defaultQuestions] : []
    );
    const [editedCustomQuestions, setEditedCustomQuestions] = useState([]);

    function convertToObject(defaultQuestionsArray) {
        const convertedDefaultQuestions = defaultQuestionsArray.reduce(
            (acc, { descriptor: { id, isVisible } }) => ({
                ...acc,
                [id]: {
                    isVisible,
                },
            }),
            {}
        );

        return convertedDefaultQuestions;
    }

    function convertToArray(defaultQuestionsObject) {
        let defaultQuestionsArray = [];

        for (let questionId in defaultQuestionsObject) {
            let descriptor = {};

            if (questionId === 'date') {
                descriptor = {
                    id: questionId,
                    type: 'DateInput',
                    fieldtext:
                        pageData.defaultQuestionsTexts[questionId].fieldtext,
                    default: true,
                    required: true,
                    isVisible: defaultQuestionsObject[questionId].isVisible,
                    editable: false,
                };
            } else if (
                questionId === 'sleepDuration' ||
                questionId === 'latencyDuration' ||
                questionId === 'totalDurationOfAwk' ||
                questionId === 'durationOfNaps'
            ) {
                descriptor = {
                    id: questionId,
                    type: 'DurationInput',
                    fieldtext:
                        pageData.defaultQuestionsTexts[questionId].fieldtext,
                    default: true,
                    required: true,
                    isVisible: defaultQuestionsObject[questionId].isVisible,
                    editable: false,
                };
            } else if (
                questionId === 'dayType' ||
                questionId === 'usesMedicine' ||
                questionId === 'triedToSleep'
            ) {
                descriptor = {
                    id: questionId,
                    type: 'ToggleInput',
                    fieldtext:
                        pageData.defaultQuestionsTexts[questionId].fieldtext,
                    textdescriptor:
                        pageData.defaultQuestionsTexts[questionId]
                            .textdescriptor,
                    default: true,
                    required: true,
                    isVisible: defaultQuestionsObject[questionId].isVisible,
                    editable: false,
                };
            } else if (
                questionId === 'wakeTime' ||
                questionId === 'getUpTime' ||
                questionId === 'bedTime' ||
                questionId === 'trySleepStart'
            ) {
                descriptor = {
                    id: questionId,
                    type: 'DateTimeInput',
                    fieldtext:
                        pageData.defaultQuestionsTexts[questionId].fieldtext,
                    default: true,
                    required: true,
                    isVisible: defaultQuestionsObject[questionId].isVisible,
                    editable: false,
                };
            } else if (questionId === 'nOfNaps' || questionId === 'nOfAwk') {
                descriptor = {
                    id: questionId,
                    type: 'IntInput',
                    fieldtext:
                        pageData.defaultQuestionsTexts[questionId].fieldtext,
                    default: true,
                    required: true,
                    isVisible: defaultQuestionsObject[questionId].isVisible,
                    editable: false,
                };
            } else if (questionId === 'sleepSatisfaction') {
                descriptor = {
                    id: questionId,
                    type: 'SliderInput',
                    fieldtext:
                        pageData.defaultQuestionsTexts[questionId].fieldtext,
                    minText: pageData.defaultQuestionsTexts[questionId].minText,
                    maxText: pageData.defaultQuestionsTexts[questionId].maxText,
                    default: true,
                    required: true,
                    isVisible: defaultQuestionsObject[questionId].isVisible,
                    editable: false,
                };
            } else if (questionId === 'sleepSatisfactionQuality') {
                descriptor = {
                    id: questionId,
                    type: 'ChoiceChipsSelect',
                    fieldtext:
                        pageData.defaultQuestionsTexts[questionId].fieldtext,
                    choices: [
                        ...pageData.defaultQuestionsTexts[questionId].choices,
                    ],
                    default: true,
                    required: false,
                    isVisible: defaultQuestionsObject[questionId].isVisible,
                    editable: true,
                };
            } else if (
                questionId === 'comments' ||
                questionId === 'medicineDescription'
            ) {
                descriptor = {
                    id: questionId,
                    type: 'FreeTextInput',
                    fieldtext:
                        pageData.defaultQuestionsTexts[questionId].fieldtext,
                    default: true,
                    required: true,
                    isVisible: defaultQuestionsObject[questionId].isVisible,
                    editable: false,
                };
            }

            defaultQuestionsArray.push({
                descriptor,
            });
        }

        return defaultQuestionsArray;
    }

    async function handleSubmit(data, { reset }, event = undefined) {
        const { isValid, errors } = await validateForm(
            data,
            schemas.diarySchema
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            data.descriptor = {
                defaultQuestions: convertToObject(editedDefaultQuestions),
                customQuestions: editedCustomQuestions,
            };

            dispatch(setLoading(true));

            const response = await editDiary(diary.id, data);

            dispatch(setLoading(false));

            if (response) {
                toast.success(pageData.successResponseText);

                toast.warning(pageData.diaryUpdateWarningText);

                history.push('/custom-diaries');
            }
        }
    }

    function handleAddQuestion(data, answerType) {
        let descriptor = {
            type: data.type,
            fieldtext: data.fieldtext,
            default: false,
            required: false,
            isVisible: data.isVisible,
            editable: true,
        };

        if (answerType === 'ToggleInput') {
            descriptor.textdescriptor = data.textdescriptor;
        } else if (answerType === 'ChoiceChipsSelect') {
            descriptor.choices = data.choices;
        } else if (answerType === 'SliderInput') {
            descriptor.minText = data.minText;
            descriptor.maxText = data.maxText;
        } else if (answerType === 'DateTimeInput') {
            descriptor.hoursBefore = data.hoursBefore;
            descriptor.hoursAfter = data.hoursAfter;
        }

        setEditedCustomQuestions((prevState) => {
            let tempQuestions = [...prevState];

            tempQuestions.push({
                descriptor,
            });

            return tempQuestions;
        });
    }

    function handleEditQuestion(question, params) {
        const tempEditedDefaultQuestions = lodash.cloneDeep(
            editedDefaultQuestions
        );
        const tempEditedCustomQuestions = lodash.cloneDeep(
            editedCustomQuestions
        );

        let questionToEdit = tempEditedDefaultQuestions.find(
            (q) => q.descriptor.fieldtext === question.descriptor.fieldtext
        );

        if (!questionToEdit) {
            questionToEdit = tempEditedCustomQuestions.find(
                (q) => q.descriptor.fieldtext === question.descriptor.fieldtext
            );

            if ('quantity' in params) {
                delete params['quantity'];
            }

            questionToEdit.descriptor = {
                ...questionToEdit.descriptor,
                ...params,
            };

            setEditedCustomQuestions(tempEditedCustomQuestions);
        } else {
            questionToEdit.descriptor = {
                ...questionToEdit.descriptor,
                ...params,
            };

            setEditedDefaultQuestions(tempEditedDefaultQuestions);
        }
    }

    function handleDeleteQuestion(question) {
        const tempEditedCustomQuestions = [...editedCustomQuestions];

        let indexToDelete = tempEditedCustomQuestions.findIndex(
            (q) => q.descriptor.fieldtext === question.descriptor.fieldtext
        );

        if (indexToDelete !== -1) {
            tempEditedCustomQuestions.splice(indexToDelete, 1);
        }

        setEditedCustomQuestions(tempEditedCustomQuestions);
    }

    useEffect(() => {
        if (history.location.state?.entity) {
            const initialData = {};

            let tempDiary = history.location.state.entity;

            setDiary(tempDiary);

            pageData?.fields.diaryFields.forEach((field) => {
                initialData[field.props.name] = tempDiary[field.props.name];
            });

            formRef.current.setData(initialData);
        } else {
            history.push('/custom-diaries');
        }
    }, []);

    useEffect(() => {
        setEditedDefaultQuestions(
            diary?.descriptor?.defaultQuestions
                ? convertToArray({ ...diary?.descriptor?.defaultQuestions })
                : []
        );
        setEditedCustomQuestions(
            diary?.descriptor?.customQuestions
                ? [...diary?.descriptor?.customQuestions]
                : []
        );
    }, [diary]);

    useEffect(() => {
        setEditedDefaultQuestions(
            diary?.descriptor?.defaultQuestions
                ? convertToArray({ ...diary?.descriptor?.defaultQuestions })
                : []
        );
    }, [lang]);

    if (Object.entries(pageData).length === 0) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <Row>
                    <Col xs="12">
                        <Form ref={formRef} onSubmit={handleSubmit}>
                            <FormGroup row>
                                <Col
                                    xs="12"
                                    className={styles.editButtonContainer}
                                >
                                    <Button type="submit" color="primary">
                                        {pageData.saveText}
                                    </Button>
                                    <Link
                                        to="/custom-diaries"
                                        className="btn btn-secondary ml-2"
                                    >
                                        {pageData.cancelText}
                                    </Link>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <FormFields
                                    fields={pageData.fields.diaryFields}
                                />
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col xs="12">
                        <p className={styles.headingText}>
                            {pageData.diaryText}
                        </p>
                    </Col>
                    <Col xs="12">
                        <p className={styles.headingText}>
                            {pageData.defaultQuestionsText}
                        </p>
                    </Col>
                    <Col xs="12">
                        {editedDefaultQuestions.map((question) => (
                            <Question
                                key={question.descriptor.fieldtext}
                                question={question}
                                answerTypes={pageData.answerTypes}
                                editMode={question.descriptor.id !== 'date'}
                                texts={{
                                    yesText: pageData.yesText,
                                    noText: pageData.noText,
                                    requiredText: pageData.requiredText,
                                    activeText: pageData.activeText,
                                    answerTypeText: pageData.answerTypeText,
                                    editText: pageData.editText,
                                    deleteText: pageData.deleteText,
                                    saveText: pageData.saveText,
                                    cancelText: pageData.cancelText,
                                    deleteQuestionText:
                                        pageData.deleteQuestionText,
                                    hoursBeforeText: pageData?.hoursBeforeText,
                                    hoursAfterText: pageData?.hoursAfterText,
                                    hoursBeforeExplainText:
                                        pageData?.hoursBeforeExplainText,
                                    hoursAfterExplainText:
                                        pageData?.hoursAfterExplainText,
                                }}
                                fields={pageData.fields}
                                schemas={schemas}
                                handleEditQuestion={handleEditQuestion}
                                handleDeleteQuestion={handleDeleteQuestion}
                            />
                        ))}
                    </Col>
                    <Col xs="12">
                        <hr className={styles.divider} />
                    </Col>
                    <Col xs="12">
                        <p className={styles.headingText}>
                            {pageData.customQuestionsText}
                        </p>
                    </Col>
                    <Col xs="12">
                        <Reorder.Group
                            as="div"
                            axis="y"
                            values={editedCustomQuestions}
                            onReorder={setEditedCustomQuestions}
                            className={`col-xs-12`}
                        >
                            {editedCustomQuestions.map((question) => (
                                <Question
                                    key={question.descriptor.fieldtext}
                                    question={question}
                                    answerTypes={pageData.answerTypes}
                                    editMode={true}
                                    texts={{
                                        yesText: pageData.yesText,
                                        noText: pageData.noText,
                                        requiredText: pageData.requiredText,
                                        activeText: pageData.activeText,
                                        answerTypeText: pageData.answerTypeText,
                                        editText: pageData.editText,
                                        deleteText: pageData.deleteText,
                                        saveText: pageData.saveText,
                                        cancelText: pageData.cancelText,
                                        deleteQuestionText:
                                            pageData.deleteQuestionText,
                                        hoursBeforeText:
                                            pageData?.hoursBeforeText,
                                        hoursAfterText:
                                            pageData?.hoursAfterText,
                                        hoursBeforeExplainText:
                                            pageData?.hoursBeforeExplainText,
                                        hoursAfterExplainText:
                                            pageData?.hoursAfterExplainText,
                                    }}
                                    fields={pageData.fields}
                                    schemas={schemas}
                                    handleEditQuestion={handleEditQuestion}
                                    handleDeleteQuestion={handleDeleteQuestion}
                                    className={`${styles.customQuestion}`}
                                />
                            ))}
                        </Reorder.Group>
                    </Col>
                    <Col xs="12" className={styles.addButtonContainer}>
                        <AddModal
                            texts={{
                                addQuestionText: pageData.addQuestionText,
                                addText: pageData.addText,
                                cancelText: pageData.cancelText,
                                hoursBeforeText: pageData?.hoursBeforeText,
                                hoursAfterText: pageData?.hoursAfterText,
                                hoursBeforeExplainText:
                                    pageData?.hoursBeforeExplainText,
                                hoursAfterExplainText:
                                    pageData?.hoursAfterExplainText,
                            }}
                            fields={pageData.fields}
                            handleAddQuestion={handleAddQuestion}
                            schemas={schemas}
                        />
                    </Col>
                    <Col xs="12">
                        <FormGroup row>
                            <Col xs="12" className={styles.editButtonContainer}>
                                <Button
                                    type="button"
                                    color="primary"
                                    onClick={() => formRef.current.submitForm()}
                                >
                                    {pageData.saveText}
                                </Button>
                                <Link
                                    to="/custom-diaries"
                                    className="btn btn-secondary ml-2"
                                >
                                    {pageData.cancelText}
                                </Link>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}

EditDiary.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Nav, NavItem, Badge } from 'reactstrap';
import {
    FaUser,
    FaChartLine,
    FaUserTie,
    FaIndustry,
    FaClipboard,
    FaUserCircle,
    FaBook,
    FaGift,
} from 'react-icons/fa';
import { BsSmartwatch } from 'react-icons/bs';
import PropTypes from 'prop-types';

import '../../styles/components/layout/sidebar.css';

export function Sidebar({ pageData, toggled }) {
    const currentUser = useSelector((state) => state.auth.currentUser);

    const lang = useSelector((state) => state.lang.lang);

    return (
        <aside className="sidebar">
            <div className="menu">
                <Nav>
                    <NavItem>
                        <NavLink
                            to="/dashboard"
                            title={pageData.dashboardText}
                            className="nav-link"
                            activeClassName="selected"
                        >
                            <div className={`${toggled ? 'toggled' : ''}`}>
                                <FaChartLine />
                                <span data-cy="dashboard_sidebar_link">
                                    {pageData.dashboardText}
                                </span>
                            </div>
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            to="/users"
                            title={
                                currentUser.permissions === 'condor_admin'
                                    ? pageData.usersText
                                    : pageData.patientsText
                            }
                            className="nav-link"
                            activeClassName="selected"
                        >
                            <div className={`${toggled ? 'toggled' : ''}`}>
                                <FaUser />
                                <span data-cy="users_sidebar_link">
                                    {currentUser.permissions === 'condor_admin'
                                        ? pageData.usersText
                                        : pageData.patientsText}
                                </span>
                            </div>
                        </NavLink>
                    </NavItem>

                    {currentUser.permissions === 'manager' && (
                        <>
                            <NavItem>
                                <NavLink
                                    to="/managers"
                                    title={pageData.managersText}
                                    className="nav-link"
                                    activeClassName="selected"
                                >
                                    <div
                                        className={`${
                                            toggled ? 'toggled' : ''
                                        }`}
                                    >
                                        <FaUserTie />
                                        <span data-cy="managers_sidebar_link">
                                            {pageData.managersText}
                                        </span>
                                    </div>
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    to="/account/my_account"
                                    title={pageData.subscriptionText}
                                    className="nav-link"
                                    activeClassName="selected"
                                >
                                    <div
                                        className={`${
                                            toggled ? 'toggled' : ''
                                        }`}
                                    >
                                        <FaUserCircle />
                                        <span data-cy="subscription_sidebar_link">
                                            {pageData.subscriptionText}
                                        </span>
                                    </div>
                                </NavLink>
                            </NavItem>
                        </>
                    )}

                    <NavItem>
                        <NavLink
                            to="/devices"
                            title={pageData.devicesText}
                            className="nav-link"
                            activeClassName="selected"
                        >
                            <div className={`${toggled ? 'toggled' : ''}`}>
                                <BsSmartwatch />
                                <span data-cy="devices_sidebar_link">
                                    {pageData.devicesText}
                                </span>
                            </div>
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            to="/custom-diaries"
                            title={pageData.customDiariesText}
                            className="nav-link"
                            activeClassName="selected"
                        >
                            <div className={`${toggled ? 'toggled' : ''}`}>
                                <FaBook />

                                <span data-cy="custom_diaries_sidebar_link">
                                    {lang === 'pt'
                                        ? pageData.diariesText
                                        : pageData.customText}{' '}
                                    <sup>
                                        <small>
                                            <Badge color="warning" tag="p" pill>
                                                BETA
                                            </Badge>
                                        </small>
                                    </sup>{' '}
                                    {lang === 'pt'
                                        ? pageData.customText
                                        : pageData.diariesText}
                                </span>
                            </div>
                        </NavLink>
                    </NavItem>

                    {currentUser.permissions === 'condor_admin' && (
                        <>
                            <NavItem>
                                <NavLink
                                    to="/companies"
                                    title={pageData.companiesText}
                                    className="nav-link"
                                    activeClassName="selected"
                                >
                                    <div
                                        className={`${
                                            toggled ? 'toggled' : ''
                                        }`}
                                    >
                                        <FaIndustry />
                                        <span data-cy="companies_sidebar_link">
                                            {pageData.companiesText}
                                        </span>
                                    </div>
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    to="/plans"
                                    title={pageData.plansText}
                                    className="nav-link"
                                    activeClassName="selected"
                                >
                                    <div
                                        className={`${
                                            toggled ? 'toggled' : ''
                                        }`}
                                    >
                                        <FaClipboard />
                                        <span data-cy="plans_sidebar_link">
                                            {pageData.plansText}
                                        </span>
                                    </div>
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    to="/coupons"
                                    title={pageData.couponsText}
                                    className="nav-link"
                                    activeClassName="selected"
                                >
                                    <div
                                        className={`${
                                            toggled ? 'toggled' : ''
                                        }`}
                                    >
                                        <FaGift />
                                        <span data-cy="coupons_sidebar_link">
                                            {pageData.couponsText}
                                        </span>
                                    </div>
                                </NavLink>
                            </NavItem>
                        </>
                    )}
                </Nav>
            </div>
        </aside>
    );
}

Sidebar.propTypes = {
    pageData: PropTypes.object.isRequired,
    toggled: PropTypes.bool.isRequired,
};

import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import './config/reactotron';
import history from './services/history';
import { store, persistor } from './store/index';
import Routes from './routes/index';
import Loading from './components/loading';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const App = () => {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <Elements stripe={stripePromise}>
                    <Loading />
                    <Router history={history}>
                        <ToastContainer autoClose={5000} />
                        <Routes />
                    </Router>
                </Elements>
            </PersistGate>
        </Provider>
    );
};

export default App;

import React from 'react';
import PropTypes from 'prop-types';

import { TitleSection } from './titleSection';
import { PageNumber } from './pageNumber';
import { Chart } from './chart';
import { CurrentWeekTable } from './currentWeekTable';

export function CurrentWeek({
    data,
    textData,
    pageNumbers,
    locale,
    completeWeekDays,
}) {
    const { charts, commentsTable } = data;
    const { titleSectionText, medicineDescriptionTable, commentsTableTexts } =
        textData;

    return (
        <>
            {charts.map((chart, index) => (
                <div key={index} className="pagePrint">
                    <div className="rowPrint">
                        {index === 0 ? (
                            <TitleSection title={titleSectionText} />
                        ) : (
                            <></>
                        )}
                        <Chart
                            data={chart.data}
                            layout={chart.layout}
                            config={chart.config}
                            height={chart.layout.height / 17}
                        />
                    </div>

                    <PageNumber number={pageNumbers[0][index]} />
                </div>
            ))}

            <div className="pagePrint">
                <div className="rowPrint">
                    <CurrentWeekTable
                        data={commentsTable[commentsTable.length - 1]}
                        titleText={medicineDescriptionTable.titleText}
                        ths={medicineDescriptionTable.ths}
                        locale={locale}
                        completeWeekDays={completeWeekDays}
                    />
                </div>

                <PageNumber number={pageNumbers[1][0]} />
            </div>

            <div className="pagePrint">
                <div className="rowPrint">
                    <CurrentWeekTable
                        data={commentsTable[commentsTable.length - 1]}
                        titleText={commentsTableTexts.titleText}
                        ths={commentsTableTexts.ths}
                        locale={locale}
                        isCommentsTable
                        completeWeekDays={completeWeekDays}
                    />
                </div>

                <PageNumber number={pageNumbers[2][0]} />
            </div>
        </>
    );
}

CurrentWeek.propTypes = {
    data: PropTypes.object.isRequired,
    textData: PropTypes.object.isRequired,
    pageNumbers: PropTypes.array.isRequired,
    locale: PropTypes.object.isRequired,
    completeWeekDays: PropTypes.array.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../../components/table/table';
import TableHeaders from '../../../../components/table/tableHeaders';
import Td from '../../../../components/table/td';

export function DeviceTable({ device, textData }) {
    return (
        <Table>
            <thead>
                <tr>
                    <TableHeaders ths={textData.ths} />
                </tr>
            </thead>
            <tbody>
                <tr>
                    {textData.tds.map((td, index) => {
                        switch (td.property) {
                            default:
                                return (
                                    <Td key={index} title={td.th}>
                                        {device[td.property]}
                                    </Td>
                                );
                        }
                    })}
                </tr>
            </tbody>
        </Table>
    );
}

DeviceTable.propTypes = {
    device: PropTypes.object.isRequired,
    textData: PropTypes.object.isRequired,
};

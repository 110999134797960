import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import WeekDays from './weekDays';
import MonthDays from './monthDays';
import useWindowSize from '../../../hooks/useWindowSize';

function RenderDays({ lastNDays, sub, locale }) {
    const windowSize = useWindowSize();

    return windowSize.width >= 1224 ? (
        <>
            <Row className="daysContainer">
                <Col xs="12">
                    <WeekDays lastNDays={lastNDays} locale={locale} />
                </Col>
                <Col xs="12">
                    <MonthDays
                        lastNDays={lastNDays}
                        sub={sub}
                        locale={locale}
                    />
                </Col>
            </Row>
        </>
    ) : (
        <>
            <Row className="daysContainer">
                <Col xs="12">
                    <WeekDays
                        lastNDays={lastNDays}
                        locale={locale}
                        limit={'smaller'}
                    />
                </Col>
                <Col xs="12">
                    <MonthDays
                        lastNDays={lastNDays}
                        sub={sub}
                        locale={locale}
                        limit={'smaller'}
                    />
                </Col>
                <Col xs="12">
                    <WeekDays
                        lastNDays={lastNDays}
                        locale={locale}
                        limit={'bigger'}
                    />
                </Col>
                <Col xs="12">
                    <MonthDays
                        lastNDays={lastNDays}
                        sub={sub}
                        locale={locale}
                        limit={'bigger'}
                    />
                </Col>
            </Row>
        </>
    );
}

RenderDays.propTypes = {
    lastNDays: PropTypes.object.isRequired,
    sub: PropTypes.object.isRequired,
    locale: PropTypes.object.isRequired,
};

export default RenderDays;

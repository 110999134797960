import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import history from '../../services/history';

function ActionsContainer({ actions, entity }) {
    return actions.map((action) => {
        const { to, className, title, icon, ...rest } = action;

        const dataTest = `${entity.id}_${rest['data-cy']}`;

        return (
            <Link
                key={crypto.randomUUID()}
                to={{
                    pathname: to,
                    state: { entity, to: history.location.pathname },
                }}
                className={className}
                title={title}
                data-cy={dataTest}
            >
                <FontAwesomeIcon icon={icon} />
            </Link>
        );
    });
}

ActionsContainer.propTypes = {
    actions: PropTypes.array.isRequired,
    entity: PropTypes.object.isRequired,
};

export default ActionsContainer;

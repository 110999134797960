import fileDownload from 'js-file-download';

import csvApi from './filesApi';

export function fetchRawDataCsv(
    id,
    date = null,
    min_date = null,
    max_date = null
) {
    const data = csvApi
        .get(`/files/raw/${id}`, {
            params: {
                date,
                min_date,
                max_date,
            },
            withCredentials: true,
        })
        .then((response) => {
            const filename = response.headers['content-disposition']
                .split(';')[1]
                .split('=')[1];

            fileDownload(response.data, filename);
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchTablesDataCsv(
    id,
    date = null,
    min_date = null,
    max_date = null
) {
    const data = csvApi
        .get(`/files/tables/${id}`, {
            params: {
                date,
                min_date,
                max_date,
            },
            withCredentials: true,
        })
        .then((response) => {
            const filename = response.headers['content-disposition']
                .split(';')[1]
                .split('=')[1];

            fileDownload(response.data, filename);
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchDecimalsTablesDataCsv(
    id,
    date = null,
    min_date = null,
    max_date = null
) {
    const data = csvApi
        .get(`/files/decimals_tables/${id}`, {
            params: {
                date,
                min_date,
                max_date,
            },
            withCredentials: true,
        })
        .then((response) => {
            const filename = response.headers['content-disposition']
                .split(';')[1]
                .split('=')[1];

            fileDownload(response.data, filename);
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchAddUserTemplateCsv() {
    const data = csvApi
        .get(`/files/add_user_template`, { withCredentials: true })
        .then((response) => {
            const filename = response.headers['content-disposition']
                .split(';')[1]
                .split('=')[1];

            fileDownload(response.data, filename);
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchAddDevicesTemplateCsv() {
    const data = csvApi
        .get(`/files/add_devices_template`, { withCredentials: true })
        .then((response) => {
            const filename = response.headers['content-disposition']
                .split(';')[1]
                .split('=')[1];

            fileDownload(response.data, filename);
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchCustomQuestionsDataCsv(id) {
    const data = csvApi
        .get(`/files/custom_questions/${id}`, { withCredentials: true })
        .then((response) => {
            const filename = response.headers['content-disposition']
                .split(';')[1]
                .split('=')[1];

            fileDownload(response.data, filename);
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchActigraphyData(userId, bodyPart = 'wrist') {
    const data = csvApi
        .get(`/files/actigraphy/${userId}`, {
            params: {
                body_part: bodyPart,
            },
            withCredentials: true,
        })
        .then((response) => {
            const filename = response.headers['content-disposition']
                .split(';')[1]
                .split('=')[1];

            fileDownload(response.data, filename);
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchSystemLogDataCsv(deviceId) {
    const data = csvApi
        .get(`/files/system_log/${deviceId}`, { withCredentials: true })
        .then((response) => {
            const filename = response.headers['content-disposition']
                .split(';')[1]
                .split('=')[1];

            fileDownload(response.data, filename);
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetch2faRecoveryCodes(code1, code2, code3, code4) {
    const data = csvApi
        .get('/files/2fa_recovery_codes', {
            params: {
                code1,
                code2,
                code3,
                code4,
            },
            withCredentials: true,
        })
        .then((response) => {
            const filename = response.headers['content-disposition']
                .split(';')[1]
                .split('=')[1];

            fileDownload(response.data, filename);
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

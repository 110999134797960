export const stepsOptions = {
    pt: {
        addPatient: [
            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'addPatient',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Adicionar paciente',
                text: [
                    `Para simular um novo paciente,
                    preencha o formulário a seguir com suas informações e clique em Adicionar.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.form-control[id="name"]',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'atention',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Adicionar paciente',
                text: [
                    `Aqui você deve digitar o nome ou código usado para identificar seu paciente.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.form-control[id="secondary_id"]',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'atention',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Adicionar paciente',
                text: [
                    `Aqui você pode digitar um identificador secundário.
                Como exemplo você pode digitar algum código interno de sua clínica ou
                o nome do grupo da pesquisa ao qual ele pertence.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.form-control[id="email"]',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'atention',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Adicionar paciente',
                text: [
                    `Aqui você deve digitar o e-mail em que seu paciente irá receber as credenciais
                para usar o aplicativo e preencher o diário.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.active-sheperd',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'atention',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Adicionar paciente',
                text: [
                    `Aqui você escolhe se o usuário está ativo ou não.
                Ativo significa que ele pode preencher o diário.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.form-control[id="trialStart"]',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'atention',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Adicionar paciente',
                text: [
                    `Aqui você pode escolher a data do início da coleta do diário para este paciente.
                Deixar em branco significa que não há data específica para o início.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.form-control[id="trialEnd"]',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'atention',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Adicionar paciente',
                text: [
                    `Aqui você pode escolher a data do término da coleta do diário de sono para este paciente.
                Deixar em branco significa que não há data específica para o final.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.diary-sheperd',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'diary_idForm',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Adicionar paciente',
                text: [
                    `Aqui você pode escolher o diário de sono utilizado pelo paciente.
                    Para utilizar o diário consenso, utilize o "Diário padrão".`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.lang-sheperd',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'atention',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Adicionar paciente',
                text: [
                    `Escolha o idioma do e-mail de boas-vindas, enviado ao paciente.
                É neste e-mail em que ele recebe a sua credencial e instruções para acessar o diário de sono.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.btn-primary[type="submit"]',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'atention',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Adicionar paciente',
                text: [
                    `Com tudo preenchido, clique no botão Adicionar para adicionar o seu paciente.`,
                ],
            },
            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        action() {
                            localStorage.setItem('showAddPatientTour', 'false');
                            return this.complete();
                        },
                        text: 'Próximo',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'atention',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Atenção!',
                text: [
                    `Vamos agora simular um paciente.
                Usando seus próprios dados preencha todos os campos e clique em Adicionar.
                Não se preocupe, você pode usar o mesmo e-mail utilizado para se cadastrar como gestor no sistema.
                Você irá receber novas credênciais de paciente para seguirmos com nossa demonstração.
                Mas lembre-se. As suas credenciais para logar nesse sistema como um gestor continuam sendo as mesmas.`,
                ],
            },
        ],
    },
    en: {
        addPatient: [
            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'addPatient',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Add patient',
                text: [
                    `To simulate a new patient, fill in the form below with the information and click Add.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.form-control[id="name"]',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'atention',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Add patient',
                text: [
                    `Enter the name or code used to identify your patient here.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.form-control[id="secondary_id"]',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'atention',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Add patient',
                text: [
                    `You can enter a secondary identifier here.
                    As an example, you can enter an internal code for your clinic or the name of the subject's group.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.form-control[id="email"]',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'atention',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Add patient',
                text: [
                    `Here, enter the email in which your patient will receive the credentials to use the app,
                    and fill in the diary.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.active-sheperd',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'atention',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Add patient',
                text: [
                    `Choose whether the user is active here. Active means they can fill the diary.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.form-control[id="trialStart"]',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'atention',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Add patient',
                text: [
                    `Choose the start date of the diary collection for the patient here.
                    Leaving it blank means there is no specific start date.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.form-control[id="trialEnd"]',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'atention',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Add patient',
                text: [
                    `Choose the end date of the sleep diary collection for the patient here.
                    Leaving it blank means there is no specific end date.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.diary-sheperd',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'diary_idForm',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Add patient',
                text: [
                    `Choose the sleep diary used by the patient.
                To use the consensus diary, use the "Default diary".`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.lang-sheperd',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'atention',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Add patient',
                text: [
                    `Choose the language of the welcome email sent to the patient.
                    Your patient will receive their credential and instructions for
                    accessing the sleep diary in this email.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.btn-primary[type="submit"]',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'atention',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Add patient',
                text: [
                    `After everything is filled in, click the Add button to add your patient.`,
                ],
            },
            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        action() {
                            localStorage.setItem('showAddPatientTour', 'false');
                            return this.complete();
                        },
                        text: 'Next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'atention',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Atention!',
                text: [
                    `Now let's simulate a patient.
                    Using your own data, fill in all fields and click Add.
                    Don't worry, you can use the same email address used to register as a manager in the system.
                    You will receive new patient credentials for us to follow up with our demo.
                    Keep in mind that your credentials to log in to this system as a manager remain the same.`,
                ],
            },
        ],
    },
};

export const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
            enabled: false,
        },
    },
    useModalOverlay: true,
};

export function getPageNumbers(lastWeekNumber, daysInWeek) {
    let userInfo = [1];
    let weeklyEvolution = [];
    let currentWeek = [];
    let tables = [];

    if (lastWeekNumber > 12) {
        let param = Math.ceil((lastWeekNumber + 1) / 18);

        for (let i = 0; i < param; i++) {
            weeklyEvolution.push(i + 2);
        }
    } else {
        weeklyEvolution = [2];
    }

    if (daysInWeek > 14) {
        let charts = [];
        let comments = [];
        let medication = [];
        let chartsParam = Math.ceil(daysInWeek / 14);
        let commentsParam = 1;
        let medicationParam = 1;
        // let commentsParam = Math.ceil(daysInWeek / 14);
        // let medicationParam = Math.ceil(daysInWeek / 14);

        for (
            let i = weeklyEvolution[weeklyEvolution.length - 1] + 1;
            i <= weeklyEvolution[weeklyEvolution.length - 1] + chartsParam;
            i++
        ) {
            charts.push(i);
        }

        for (
            let i = charts[charts.length - 1] + 1;
            i <= charts[charts.length - 1] + commentsParam;
            i++
        ) {
            comments.push(i);
        }

        for (
            let i = comments[comments.length - 1] + 1;
            i <= comments[comments.length - 1] + medicationParam;
            i++
        ) {
            medication.push(i);
        }

        currentWeek = [charts, comments, medication];
    } else {
        for (
            let i = weeklyEvolution[weeklyEvolution.length - 1] + 1;
            i <= weeklyEvolution[weeklyEvolution.length - 1] + 3;
            i++
        ) {
            currentWeek.push([i]);
        }
    }

    let tablesParam;

    switch (true) {
        case lastWeekNumber < 26:
            tablesParam = 2;
            break;
        case lastWeekNumber < 51:
            tablesParam = 4;
            break;
        default:
            tablesParam = 6;
            break;
    }

    for (
        let i = currentWeek[currentWeek.length - 1][0] + 1;
        i <= currentWeek[currentWeek.length - 1][0] + tablesParam;
        i++
    ) {
        tables.push(i);
    }

    return {
        userInfo,
        weeklyEvolution,
        currentWeek,
        tables,
    };
}

import React, { useEffect, useState, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ShepherdTourContext } from 'react-shepherd';
import PropTypes from 'prop-types';

import styles from './searchAndAddContainer.module.css';

SearchAndAddContainer.propTypes = {
    users: PropTypes.array.isRequired,
    setFilteredUsers: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
    texts: PropTypes.object.isRequired,
};

export function SearchAndAddContainer({
    users,
    setFilteredUsers,
    currentUser,
    texts,
}) {
    const [nameFilter, setNameFilter] = useState('');
    const [secondaryIdFilter, setSecondaryIdFilter] = useState('');

    const {
        searchByNameText,
        searchBySecondaryIdText,
        addText,
        addUserText,
        importUserText,
    } = texts;

    const tour = useContext(ShepherdTourContext);

    function handleNameInputChange(e) {
        setNameFilter(e.target.value);
    }

    function handleSecondaryIdInputChange(e) {
        setSecondaryIdFilter(e.target.value);
    }

    useEffect(() => {
        if (currentUser.permissions === 'manager') {
            const showAddPatientButtonTour = JSON.parse(
                localStorage.getItem('showAddPatientButtonTour')
            );
            const showAppDownloadTour = JSON.parse(
                localStorage.getItem('showAppDownloadTour')
            );

            if (showAddPatientButtonTour || showAppDownloadTour) {
                tour.start();
            }
        }
    }, []);

    useEffect(() => {
        let tempFilteredUsers = [];

        if (nameFilter && secondaryIdFilter) {
            tempFilteredUsers = users.filter((user) =>
                user.secondary_id === null
                    ? ''
                          .toUpperCase()
                          .includes(secondaryIdFilter.toUpperCase()) &&
                      user.name.toUpperCase().includes(nameFilter.toUpperCase())
                    : user.secondary_id
                          .toUpperCase()
                          .includes(secondaryIdFilter.toUpperCase()) &&
                      user.name.toUpperCase().includes(nameFilter.toUpperCase())
            );
        } else if (nameFilter) {
            tempFilteredUsers = users.filter((user) =>
                user.name.toUpperCase().includes(nameFilter.toUpperCase())
            );
        } else if (secondaryIdFilter) {
            tempFilteredUsers = users.filter((user) =>
                user.secondary_id === null
                    ? ''.toUpperCase().includes(secondaryIdFilter.toUpperCase())
                    : user.secondary_id
                          .toUpperCase()
                          .includes(secondaryIdFilter.toUpperCase())
            );
        } else {
            tempFilteredUsers = users;
        }

        setFilteredUsers(tempFilteredUsers);
    }, [nameFilter, secondaryIdFilter]);

    return (
        <Row>
            <Col xs="12">
                <div className={styles.searchAndAddContainer}>
                    <input
                        name="searchName"
                        placeholder={searchByNameText}
                        title={searchByNameText}
                        value={nameFilter}
                        onChange={handleNameInputChange}
                        data-cy="users_search_name_input"
                    />

                    <input
                        name="searchSecondaryId"
                        placeholder={searchBySecondaryIdText}
                        title={searchBySecondaryIdText}
                        value={secondaryIdFilter}
                        onChange={handleSecondaryIdInputChange}
                        className="ml-2"
                        data-cy="users_search_secondary_id_input"
                    />

                    <Link
                        to="users/add"
                        className="btn btn-primary ml-2"
                        title={addUserText}
                        data-cy="users_add_user_link"
                    >
                        {addText}
                    </Link>

                    {currentUser.permissions !== 'condor_admin' && (
                        <Link
                            to="users/import"
                            className="btn btn-primary ml-2"
                            title={importUserText}
                            data-cy="users_import_users_link"
                        >
                            {importUserText}
                        </Link>
                    )}
                </div>
            </Col>
        </Row>
    );
}

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { AccordionContext } from '../../contexts/accordionContext';
import { AccordionHeader } from './accordionHeader';
import { AccordionBody } from './accordionBody';
import { AccordionItem } from './accordionItem';

import './style.scss';

export { AccordionHeader, AccordionBody, AccordionItem };

export function Accordion({
    className,
    innerRef,
    children,
    flush,
    open,
    toggle,
    ...attributes
}) {
    const accordionContext = useMemo(() => ({
        open,
        toggle,
    }));

    return (
        <AccordionContext.Provider value={accordionContext}>
            <div
                {...attributes}
                className={`${className} accordion ${
                    flush ? 'accordion-flush' : ''
                }`}
                ref={innerRef}
            >
                {children}
            </div>
        </AccordionContext.Provider>
    );
}

Accordion.propTypes = {
    className: PropTypes.string,
    innerRef: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.func,
    ]),
    children: PropTypes.node,
    flush: PropTypes.bool,
    open: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
    toggle: PropTypes.func.isRequired,
};

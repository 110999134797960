import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { time, duration } from '../../../../helpers/masks';
import { localeDateFormatter } from '../../../../helpers/formatters';
import { Td } from './editTableResponsiveTd';
import Input from '../../../../components/inputForm/inputText';
import { RenderDurations } from './renderDurations';
import { ButtonContainer } from './buttonContainer';

export function EditTableTds({
    tds,
    texts,
    dayInput,
    dayInputDecimals,
    dayInputStatistics,
    buttonText,
    disabled,
    loading,
    locale,
    index,
    handleClick,
}) {
    const handleKeyUp = useCallback((e) => {
        if (e.currentTarget.hasAttribute('mean')) {
            duration(e);
        } else {
            time(e);
        }
    }, []);

    const updateBackgroundColor = (e) => {
        let element = e.currentTarget;
        let id = element.attributes['id'].value;
        let value = element.value;
        let date = element.attributes['date'].value;
        let upperlimit = Number(element.attributes['upperlimit'].value);
        let lowerlimit = Number(element.attributes['lowerlimit'].value);

        let dt = new Date(date.slice(0, 10) + ' ' + value);
        let intTime =
            dt.getHours() * 3600 + dt.getMinutes() * 60 + dt.getSeconds();

        if (
            id.split('/')[0] === 'latencyDuration' ||
            id.split('/')[0] === 'sleepDuration' ||
            id.split('/')[0].includes('durationOfAwk') ||
            id.split('/')[0].includes('durationOfNaps')
        ) {
            if (lowerlimit <= intTime && intTime <= upperlimit) {
                element.classList.remove('bgPink');
            } else {
                element.classList.add('bgPink');
            }
        }
    };

    return tds.map((td) => {
        let intValue;
        let mean;
        let std;
        let upperLimit;
        let lowerLimit;

        if ('diaryVersion' in dayInput && dayInput?.diaryVersion >= 2) {
            switch (td.property) {
                case 'bedTime':
                case 'trySleepStart':
                case 'wakeTime':
                case 'getUpTime':
                    return (
                        <Td
                            key={`${td.property}/${dayInput.date.slice(0, 10)}`}
                            title={td.th}
                        >
                            {!(dayInputDecimals[td.property] === null) && (
                                <Input
                                    name={`${td.property}/${dayInput.date.slice(
                                        0,
                                        10
                                    )}`}
                                    onKeyUp={handleKeyUp}
                                    className="editDataInput"
                                    disabled={disabled}
                                />
                            )}
                        </Td>
                    );
                case 'latencyDuration':
                    intValue = dayInputDecimals[td.property];
                    mean = dayInputStatistics[td.property].mean;
                    std = dayInputStatistics[td.property].std;
                    upperLimit = mean + 1 * 3600;
                    lowerLimit = mean - 1 * 3600;
                    return (
                        <Td
                            key={`${td.property}/${dayInput.date.slice(0, 10)}`}
                            title={td.th}
                        >
                            {dayInputDecimals[td.property] !== null && (
                                <Input
                                    type="text"
                                    name={`${td.property}/${dayInput.date.slice(
                                        0,
                                        10
                                    )}`}
                                    upperlimit={upperLimit}
                                    lowerlimit={lowerLimit}
                                    mean={mean}
                                    date={dayInput.date}
                                    onKeyUp={handleKeyUp}
                                    onBlur={updateBackgroundColor}
                                    className={`editDataInput ${
                                        std === 0 ||
                                        (lowerLimit <= intValue &&
                                            intValue <= upperLimit)
                                            ? ''
                                            : 'bgPink'
                                    }`}
                                    disabled={disabled}
                                />
                            )}
                        </Td>
                    );
                case 'sleepDuration':
                    intValue = dayInputDecimals[td.property];
                    mean = dayInputStatistics[td.property].mean;
                    std = dayInputStatistics[td.property].std;
                    upperLimit = mean + 3 * 3600;
                    lowerLimit = mean - 3 * 3600;
                    return (
                        <Td
                            key={`${td.property}/${dayInput.date.slice(0, 10)}`}
                            title={td.th}
                        >
                            {dayInputDecimals[td.property] !== null && (
                                <Input
                                    name={`${td.property}/${dayInput.date.slice(
                                        0,
                                        10
                                    )}`}
                                    upperlimit={upperLimit}
                                    lowerlimit={lowerLimit}
                                    mean={mean}
                                    date={dayInput.date}
                                    onKeyUp={handleKeyUp}
                                    onBlur={updateBackgroundColor}
                                    className={`editDataInput ${
                                        std === 0 ||
                                        (lowerLimit <= intValue &&
                                            intValue <= upperLimit)
                                            ? ''
                                            : 'bgPink'
                                    } ${
                                        0 < intValue && intValue < 900
                                            ? 'blueBorder'
                                            : ''
                                    }`}
                                    disabled={disabled}
                                />
                            )}
                        </Td>
                    );
                case 'totalDurationOfAwk':
                    intValue = dayInputDecimals[td.property];
                    mean = dayInputStatistics[td.property].mean;
                    std = dayInputStatistics[td.property].std;
                    upperLimit = mean + 1 * 3600;
                    lowerLimit = mean - 1 * 3600;
                    return (
                        <Td
                            key={`${td.property}/${dayInput.date.slice(0, 10)}`}
                            title={td.th}
                        >
                            {dayInputDecimals[td.property] !== null && (
                                <Input
                                    name={`${td.property}/${dayInput.date.slice(
                                        0,
                                        10
                                    )}`}
                                    upperlimit={upperLimit}
                                    lowerlimit={lowerLimit}
                                    mean={mean}
                                    date={dayInput.date}
                                    onKeyUp={handleKeyUp}
                                    onBlur={updateBackgroundColor}
                                    className={`editDataInput ${
                                        std === 0 ||
                                        (lowerLimit <= intValue &&
                                            intValue <= upperLimit)
                                            ? ''
                                            : 'bgPink'
                                    }`}
                                    disabled={disabled}
                                />
                            )}
                        </Td>
                    );
                case 'durationOfNaps':
                    intValue = dayInputDecimals[td.property];
                    mean = dayInputStatistics[td.property].mean;
                    std = dayInputStatistics[td.property].std;
                    upperLimit = mean + 1 * 3600;
                    lowerLimit = mean - 1 * 3600;
                    return (
                        <Td
                            key={`${td.property}/${dayInput.date.slice(0, 10)}`}
                            title={td.th}
                        >
                            <RenderDurations
                                dayInput={dayInput}
                                property={td.property}
                                handleKeyUp={handleKeyUp}
                                updateBackgroundColor={updateBackgroundColor}
                                stats={{
                                    lowerLimit,
                                    upperLimit,
                                    mean,
                                    std,
                                    intValue,
                                }}
                                disabled={disabled}
                            />
                        </Td>
                    );
                case 'date':
                    return (
                        <Td
                            key={`${td.property}/${dayInput.date.slice(0, 10)}`}
                            title={td.th}
                        >
                            {localeDateFormatter(dayInput[td.property], locale)
                                ? localeDateFormatter(
                                      dayInput[td.property],
                                      locale
                                  )
                                : '--'}
                        </Td>
                    );
                case 'saveButton':
                    return (
                        <Td
                            key={`${td.property}/${dayInput.date.slice(0, 10)}`}
                            title={td.th}
                        >
                            <ButtonContainer
                                buttonText={buttonText}
                                buttonsTexts={texts}
                                loading={loading}
                                index={index}
                                dayInput={dayInput}
                                handleClick={handleClick}
                            />
                        </Td>
                    );
            }
        } else {
            switch (td.property) {
                case 'bedTime':
                case 'wakeTime':
                case 'getUpTime':
                    return (
                        <Td
                            key={`${td.property}/${dayInput.date.slice(0, 10)}`}
                            title={td.th}
                        >
                            {!(dayInputDecimals[td.property] === null) && (
                                <Input
                                    name={`${td.property}/${dayInput.date.slice(
                                        0,
                                        10
                                    )}`}
                                    onKeyUp={handleKeyUp}
                                    className="editDataInput"
                                    disabled={disabled}
                                />
                            )}
                        </Td>
                    );
                case 'latencyDuration':
                    intValue = dayInputDecimals[td.property];
                    mean = dayInputStatistics[td.property].mean;
                    std = dayInputStatistics[td.property].std;
                    upperLimit = mean + 1 * 3600;
                    lowerLimit = mean - 1 * 3600;
                    return (
                        <Td
                            key={`${td.property}/${dayInput.date.slice(0, 10)}`}
                            title={td.th}
                        >
                            {dayInputDecimals[td.property] !== null && (
                                <Input
                                    name={`${td.property}/${dayInput.date.slice(
                                        0,
                                        10
                                    )}`}
                                    upperlimit={upperLimit}
                                    lowerlimit={lowerLimit}
                                    mean={mean}
                                    date={dayInput.date}
                                    onKeyUp={handleKeyUp}
                                    onBlur={updateBackgroundColor}
                                    className={`editDataInput ${
                                        std === 0 ||
                                        (lowerLimit <= intValue &&
                                            intValue <= upperLimit)
                                            ? ''
                                            : 'bgPink'
                                    }`}
                                    disabled={disabled}
                                />
                            )}
                        </Td>
                    );
                case 'sleepDuration':
                    intValue = dayInputDecimals[td.property];
                    mean = dayInputStatistics[td.property].mean;
                    std = dayInputStatistics[td.property].std;
                    upperLimit = mean + 3 * 3600;
                    lowerLimit = mean - 3 * 3600;
                    return (
                        <Td
                            key={`${td.property}/${dayInput.date.slice(0, 10)}`}
                            title={td.th}
                        >
                            {dayInputDecimals[td.property] !== null && (
                                <Input
                                    name={`${td.property}/${dayInput.date.slice(
                                        0,
                                        10
                                    )}`}
                                    upperlimit={upperLimit}
                                    lowerlimit={lowerLimit}
                                    mean={mean}
                                    date={dayInput.date}
                                    onKeyUp={handleKeyUp}
                                    onBlur={updateBackgroundColor}
                                    className={`editDataInput ${
                                        std === 0 ||
                                        (lowerLimit <= intValue &&
                                            intValue <= upperLimit)
                                            ? ''
                                            : 'bgPink'
                                    } ${
                                        0 < intValue && intValue < 900
                                            ? 'blueBorder'
                                            : ''
                                    }`}
                                    disabled={disabled}
                                />
                            )}
                        </Td>
                    );
                case 'durationOfAwk':
                case 'durationOfNaps':
                    intValue = dayInputDecimals[td.property];
                    mean = dayInputStatistics[td.property].mean;
                    std = dayInputStatistics[td.property].std;
                    upperLimit = mean + 1 * 3600;
                    lowerLimit = mean - 1 * 3600;
                    return (
                        <Td
                            key={`${td.property}/${dayInput.date.slice(0, 10)}`}
                            title={td.th}
                        >
                            <RenderDurations
                                dayInput={dayInput}
                                property={td.property}
                                handleKeyUp={handleKeyUp}
                                updateBackgroundColor={updateBackgroundColor}
                                stats={{
                                    lowerLimit,
                                    upperLimit,
                                    mean,
                                    std,
                                    intValue,
                                }}
                                disabled={disabled}
                            />
                        </Td>
                    );
                case 'trySleepStart':
                    return (
                        <Td
                            key={`${td.property}/${dayInput.date.slice(0, 10)}`}
                            title={td.th}
                        ></Td>
                    );
                case 'date':
                    return (
                        <Td
                            key={`${td.property}/${dayInput.date.slice(0, 10)}`}
                            title={td.th}
                        >
                            {localeDateFormatter(dayInput[td.property], locale)
                                ? localeDateFormatter(
                                      dayInput[td.property],
                                      locale
                                  )
                                : '--'}
                        </Td>
                    );
                case 'saveButton':
                    return (
                        <Td
                            key={`${td.property}/${dayInput.date.slice(0, 10)}`}
                            title={td.th}
                        >
                            <ButtonContainer
                                buttonText={buttonText}
                                buttonsTexts={texts}
                                loading={loading}
                                index={index}
                                dayInput={dayInput}
                                handleClick={handleClick}
                            />
                        </Td>
                    );
            }
        }
    });
}

EditTableTds.propTypes = {
    tds: PropTypes.array.isRequired,
    texts: PropTypes.object.isRequired,
    dayInput: PropTypes.object.isRequired,
    dayInputDecimals: PropTypes.object.isRequired,
    dayInputStatistics: PropTypes.object.isRequired,
    buttonText: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    locale: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    handleClick: PropTypes.func.isRequired,
};

export default {
    pt: {
        customer_admin: null,
        manager: {
            nameText: 'Nome',
            emailText: 'e-Mail',
            languageText: 'Idioma',
            portugueseText: 'Português',
            englishText: 'Inglês',
            germanyText: 'Alemão',
            sendText: 'Enviar',
            changeEmailText: 'Modificar e-mail',
            cancelText: 'Cancelar',
            successText: 'Credenciais enviadas com sucesso!',
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            nameText: 'Name',
            emailText: 'e-Mail',
            languageText: 'Language',
            portugueseText: 'Portuguese',
            englishText: 'English',
            germanyText: 'German',
            sendText: 'Submit',
            changeEmailText: 'Change e-mail',
            cancelText: 'Cancel',
            successText: 'Credentials successfully submitted!',
        },
        condor_admin: null,
    },
};

import Input from '../../../components/inputForm/inputText';
import Select from '../../../components/inputForm/inputSelect';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Nome',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Select,
                    props: {
                        name: 'share',
                        label: 'Compartilha pacientes?',
                        options: [
                            { value: true, label: 'Sim' },
                            { value: false, label: 'Não' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'descriptor.saveEmail',
                        label: 'Salvar e-Mails de pacientes?',
                        options: [
                            { value: true, label: 'Sim' },
                            { value: false, label: 'Não' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'descriptor.defaultLanguage',
                        label: 'Idioma padrão',
                        options: [
                            { id: 'pt', value: 'pt', label: 'Português' },
                            { id: 'en', value: 'en', label: 'Inglês' },
                        ],
                    },
                    className: 'col-xs-12 col-md-2 settingsRadioContainer',
                },
                {
                    component: Input,
                    props: {
                        name: 'descriptor.minPasswordLength',
                        type: 'number',
                        label: 'Tamanho mínimo de senha',
                        min: 8,
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            successText: 'Empresa editada com sucesso!',
            saveText: 'Salvar',
            cancelText: 'Cancelar',
            yesText: 'Sim',
            noText: 'Não',
            defaultLanguageOptions: {
                pt: { label: 'Português', value: 'pt' },
                en: { label: 'Inglês', value: 'en' },
            },
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Name',
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Select,
                    props: {
                        name: 'share',
                        label: 'Share patients?',
                        options: [
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'descriptor.saveEmail',
                        label: 'Save patient e-Mails?',
                        options: [
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'descriptor.defaultLanguage',
                        label: 'Default language',
                        options: [
                            { id: 'pt', value: 'pt', label: 'Portuguese' },
                            { id: 'en', value: 'en', label: 'English' },
                        ],
                    },
                    className: 'col-xs-12 col-md-2 settingsRadioContainer',
                },
                {
                    component: Input,
                    props: {
                        name: 'descriptor.minPasswordLength',
                        type: 'number',
                        label: 'Minimum password length',
                        min: 8,
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            successText: 'Company successfully edited!',
            saveText: 'Save',
            cancelText: 'Cancel',
            yesText: 'Yes',
            noText: 'No',
            defaultLanguageOptions: {
                pt: { label: 'Portuguese', value: 'pt' },
                en: { label: 'English', value: 'en' },
            },
        },
    },
};

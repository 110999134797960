import * as Yup from 'yup';

export default {
    pt: {
        schema: Yup.object().shape({
            name: Yup.string().max(50),
            share: Yup.bool(),
            descriptor: Yup.object().shape({
                saveEmail: Yup.bool(),
                defaultLanguage: Yup.string(),
                minPasswordLength: Yup.number()
                    .min(
                        8,
                        'O tamanho mínimo da senha não deve ser menor do que 8'
                    )
                    .max(
                        30,
                        'O tamanho mínimo da senha não deve ser maior do que 30'
                    ),
                hasSpecialCharacterInPassword: Yup.bool(),
                is2faEnabled: Yup.bool(),
                deviceBatteryInfo: Yup.object().shape({
                    sendEmail: Yup.bool().required('Campo obrigatório'),
                    batteryThreshold:
                        Yup.number().required('Campo obrigatório'),
                    emailReceivers: Yup.string().required('Campo obrigatório'),
                }),
            }),
        }),
    },
    en: {
        schema: Yup.object().shape({
            name: Yup.string().max(50),
            share: Yup.bool(),
            descriptor: Yup.object().shape({
                saveEmail: Yup.bool(),
                defaultLanguage: Yup.string(),
                minPasswordLength: Yup.number()
                    .min(
                        8,
                        'The minimum password length must not be lower than 8'
                    )
                    .max(30, 'The minimum password length must not exceed 30'),
                hasSpecialCharacterInPassword: Yup.bool(),
                is2faEnabled: Yup.bool(),
                deviceBatteryInfo: Yup.object().shape({
                    sendEmail: Yup.bool().required('Required field'),
                    batteryThreshold: Yup.number().required('Required field'),
                    emailReceivers: Yup.string().required('Required field'),
                }),
            }),
        }),
    },
};

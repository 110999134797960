import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            searchByNameText: 'Procurar cupom por nome',
            addText: 'Adicionar',
            addCouponText: 'Adicionar cupom',
            ths: [
                {
                    name: 'Nome',
                    property: 'name',
                },
                {
                    name: 'Percentual de desconto',
                    property: 'formattedPercentOff',
                },
                {
                    name: 'Valor de desconto',
                    property: 'formattedAmountOff',
                },
                {
                    name: 'Duração',
                    property: 'duration',
                },
                {
                    name: 'Moedas',
                    property: 'formattedCurrency',
                },
                {
                    name: 'Criado em',
                    property: 'createdAt',
                },
                {
                    name: 'Ações',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'Nome',
                    property: 'name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Percentual de desconto',
                    property: 'formattedPercentOff',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Valor de desconto',
                    property: 'formattedAmountOff',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Duração',
                    property: 'duration',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Moedas',
                    property: 'formattedCurrency',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Criado em',
                    property: 'createdAt',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Ações',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Editar',
                    className: 'btn btn-secondary',
                    to: 'coupons/edit',
                    icon: faEdit,
                },
                {
                    title: 'Excluir',
                    className: 'btn btn-danger ml-2',
                    to: 'coupons/delete',
                    icon: faTrash,
                },
            ],
            currency_pt_BR: {
                currencyLocale: 'pt-BR',
                currencyFormat: 'BRL',
            },
            currency_en_US: {
                currencyLocale: 'en-US',
                currencyFormat: 'USD',
            },
            yesText: 'Sim',
            noText: 'Não',
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            searchByNameText: 'Search coupon by name',
            addText: 'Add',
            addCouponText: 'Add coupon',
            ths: [
                {
                    name: 'Name',
                    property: 'name',
                },
                {
                    name: 'Percent Off',
                    property: 'formattedPercentOff',
                },
                {
                    name: 'Amount off',
                    property: 'formattedAmountOff',
                },
                {
                    name: 'Duration',
                    property: 'duration',
                },
                {
                    name: 'Currency',
                    property: 'formattedCurrency',
                },
                {
                    name: 'Created at',
                    property: 'createdAt',
                },
                {
                    name: 'Actions',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'Name',
                    property: 'name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Percent Off',
                    property: 'formattedPercentOff',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Amount off',
                    property: 'formattedAmountOff',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Duration',
                    property: 'duration',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Currency',
                    property: 'formattedCurrency',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Created at',
                    property: 'createdAt',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Actions',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Edit',
                    className: 'btn btn-secondary',
                    to: 'coupons/edit',
                    icon: faEdit,
                },
                {
                    title: 'Delete',
                    className: 'btn btn-danger ml-2',
                    to: 'coupons/delete',
                    icon: faTrash,
                },
            ],
            currency_pt_BR: {
                currencyLocale: 'pt-BR',
                currencyFormat: 'BRL',
            },
            currency_en_US: {
                currencyLocale: 'en-US',
                currencyFormat: 'USD',
            },
            yesText: 'yes',
            noText: 'No',
        },
    },
};

import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            couponSchema: Yup.array().of(
                Yup.object().shape({
                    coupon_id: Yup.number().required('Campo obrigatório'),
                    month_to_remove: Yup.number()
                        .min(1)
                        .max(12)
                        .required('Campo obrigatório'),
                    year_to_remove: Yup.number()
                        .min(new Date().getFullYear())
                        .max(2999)
                        .required('Campo obrigatório'),
                })
            ),
            extendTrialSchema: Yup.object().shape({
                type: Yup.string().required('Campo obrigatório'),
                days_to_extend: Yup.number()
                    .min(1)
                    .required('Campo obrigatório'),
            }),
            activateTrialSchema: Yup.object().shape({
                type: Yup.string().required('Campo obrigatório'),
                new_expires_at: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
            }),
            activateSchema: Yup.object().shape({
                plan_id: Yup.number().required('Campo obrigatório'),
            }),
            suspendSchema: Yup.object().shape({
                plan_id: Yup.number().required('Campo obrigatório'),
            }),
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            couponSchema: Yup.array().of(
                Yup.object().shape({
                    coupon_id: Yup.number().required('Required field'),
                    month_to_remove: Yup.number()
                        .min(1)
                        .max(12)
                        .required('Required field'),
                    year_to_remove: Yup.number()
                        .min(new Date().getFullYear())
                        .max(2999)
                        .required('Required field'),
                })
            ),
            extendTrialSchema: Yup.object().shape({
                type: Yup.string().required('Required field'),
                days_to_extend: Yup.number().min(1).required('Required field'),
            }),
            activateTrialSchema: Yup.object().shape({
                type: Yup.string().required('Required field'),
                new_expires_at: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
            }),
            activateSchema: Yup.object().shape({
                plan_id: Yup.number().required('Required field'),
            }),
            suspendSchema: Yup.object().shape({
                plan_id: Yup.number().required('Required field'),
            }),
        },
    },
};

import React from 'react';
import PropTypes from 'prop-types';

export function AccordionItem({ className, innerRef, ...attributes }) {
    return (
        <div
            {...attributes}
            className={`${className} accordion-item`}
            ref={innerRef}
        />
    );
}

AccordionItem.propTypes = {
    className: PropTypes.string,
    innerRef: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.func,
    ]),
    children: PropTypes.node,
};

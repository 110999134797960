import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Td from '../../../components/table/td';
import {
    localeDateFormatter,
    currencyFormatter,
} from '../../../helpers/formatters';
import { handleActiveProperty } from '../../../helpers/handleActiveProperty';
import ActionsContainer from '../../../components/table/actionsContainer';

export function TableTds({
    tds,
    texts,
    plan,
    actions,
    locale,
    currency_pt_BR,
    currency_en_US,
}) {
    return tds.map((td, index) => {
        switch (td.property) {
            case 'active':
            case 'visible': {
                let { className, text } = handleActiveProperty(
                    plan[td.property],
                    texts.yesText,
                    texts.noText
                );
                return (
                    <Td key={index} title={td.th} className={className}>
                        {text}
                    </Td>
                );
            }
            case 'created_at':
            case 'updated_at':
                return (
                    <Td key={index} title={td.th}>
                        {localeDateFormatter(plan[td.property], locale)
                            ? localeDateFormatter(plan[td.property], locale)
                            : '--'}
                    </Td>
                );
            case 'discount':
            case 'price_brl':
                return (
                    <Td key={index} title={td.th}>
                        {currencyFormatter(
                            plan[td.property],
                            currency_pt_BR.currencyLocale,
                            currency_pt_BR.currencyFormat
                        )}
                    </Td>
                );
            case 'price_usd':
                return (
                    <Td key={index} title={td.th}>
                        {currencyFormatter(
                            plan[td.property],
                            currency_en_US.currencyLocale,
                            currency_en_US.currencyFormat
                        )}
                    </Td>
                );
            case 'actions':
                return (
                    <Td key={index} title={td.th}>
                        <ActionsContainer actions={actions} entity={plan} />
                    </Td>
                );
            default:
                if (td.hasLink)
                    return (
                        <Td key={index} title={td.th}>
                            <Link
                                to={{
                                    pathname: td.to,
                                    state: { plan: plan },
                                }}
                            >
                                {plan[td.property]}
                            </Link>
                        </Td>
                    );
                return (
                    <Td key={index} title={td.th}>
                        {plan[td.property]}
                    </Td>
                );
        }
    });
}

TableTds.propTypes = {
    tds: PropTypes.array.isRequired,
    texts: PropTypes.object.isRequired,
    plan: PropTypes.object.isRequired,
    actions: PropTypes.array,
    locale: PropTypes.object.isRequired,
    currency_pt_BR: PropTypes.object.isRequired,
    currency_en_US: PropTypes.object.isRequired,
};

import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import styles from './tableContainer.module.css';

import Table from '../../../components/table/table';
import { ThWithOrdering } from '../../../components/table/thWithOrdering';
import TableRows from './tableRows';

function TableContainer({
    ths,
    tds,
    filteredCompanies,
    actions,
    shareText,
    dosentShareText,
    activeFilter,
    orderByProperty,
}) {
    return (
        <Row>
            <Col xs="12">
                <Table className="companiesTable">
                    <thead>
                        <tr>
                            {ths.map((th) => {
                                if (['id', 'name'].includes(th.property)) {
                                    return (
                                        <ThWithOrdering
                                            key={th.property}
                                            property={th.property}
                                            activeFilterProperty={
                                                activeFilter.property
                                            }
                                            activeOrder={activeFilter.order}
                                            orderByProperty={orderByProperty}
                                            activeClassName={styles.active}
                                            clickableIconClassName={
                                                styles.clickableIcon
                                            }
                                        >
                                            {th.name}
                                        </ThWithOrdering>
                                    );
                                }

                                return <th key={th.property}>{th.name}</th>;
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <TableRows
                            companies={filteredCompanies}
                            tds={tds}
                            actions={actions}
                            shareText={shareText}
                            dosentShareText={dosentShareText}
                        />
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}

TableContainer.propTypes = {
    ths: PropTypes.array.isRequired,
    tds: PropTypes.array.isRequired,
    filteredCompanies: PropTypes.array.isRequired,
    actions: PropTypes.array,
    shareText: PropTypes.string.isRequired,
    dosentShareText: PropTypes.string.isRequired,
    activeFilter: PropTypes.object.isRequired,
    orderByProperty: PropTypes.func.isRequired,
};

export default TableContainer;

import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: null,
        manager: {
            schema: Yup.object().shape({
                name: Yup.string().required('O nome é obrigatório'),
                secondary_id: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                email: Yup.string()
                    .email('Formato inválido')
                    .required('O e-Mail é obrigatório'),
                active: Yup.bool().required(
                    'O estado do usuário é obrigatório'
                ),
                lang: Yup.string().required('O idioma é obrigatório'),
            }),
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            schema: Yup.object().shape({
                name: Yup.string().required('Name is required'),
                secondary_id: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                email: Yup.string()
                    .email('Invalid format')
                    .required('e-Mail is required'),
                active: Yup.bool().required('User state is required'),
                lang: Yup.string().required('Language is required'),
            }),
        },
        condor_admin: null,
    },
};

import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: {
            diarySchema: Yup.object().shape({
                name: Yup.string().required('O nome do diário é obrigatório'),
            }),
            schemaWithoutOptions: Yup.object().shape({
                fieldtext: Yup.string().required(
                    'O título da questão é obrigatório'
                ),
                type: Yup.string().required('O tipo de resposta é obrigatório'),
                isVisible: Yup.bool()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('É obrigatório informar se está ativa'),
            }),
            schemaWithMinMaxText: Yup.object().shape({
                fieldtext: Yup.string().required(
                    'O título da questão é obrigatório'
                ),
                type: Yup.string().required('O tipo de resposta é obrigatório'),
                isVisible: Yup.bool()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('É obrigatório informar se está ativa'),
                minText: Yup.string().required('O valor mínimo é obrigatório'),
                maxText: Yup.string().required('O valor máximo é obrigatório'),
            }),
            schemaWithMinMaxDateTime: Yup.object().shape({
                fieldtext: Yup.string().required(
                    'O título da questão é obrigatório'
                ),
                type: Yup.string().required('O tipo de resposta é obrigatório'),
                isVisible: Yup.bool()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('É obrigatório informar se está ativa'),
                hoursBefore: Yup.number().required(
                    'O tempo mínimo é obrigatório'
                ),
                hoursAfter: Yup.number().required(
                    'O tempo máximo é obrigatório'
                ),
            }),
            schemaWithOptions: Yup.object().shape({
                fieldtext: Yup.string().required(
                    'O título da questão é obrigatório'
                ),
                type: Yup.string().required('O tipo de resposta é obrigatório'),
                isVisible: Yup.bool()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('É obrigatório informar se está ativa'),
                textdescriptor: Yup.array().of(
                    Yup.string().required('A opção de resposta é obrigatória')
                ),
            }),
            schemaWithChoices: Yup.object().shape({
                fieldtext: Yup.string().required(
                    'O título da questão é obrigatório'
                ),
                type: Yup.string().required('O tipo de resposta é obrigatório'),
                isVisible: Yup.bool()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('É obrigatório informar se está ativa'),
                quantity: Yup.number().required(
                    'A quantidade de opções é obrigatória'
                ),
                choices: Yup.array().of(
                    Yup.string().required('A escolha da resposta é obrigatória')
                ),
            }),
        },
        manager: {
            diarySchema: Yup.object().shape({
                name: Yup.string().required('O nome do diário é obrigatório'),
            }),
            schemaWithoutOptions: Yup.object().shape({
                fieldtext: Yup.string().required(
                    'O título da questão é obrigatório'
                ),
                type: Yup.string().required('O tipo de resposta é obrigatório'),
                isVisible: Yup.bool()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('É obrigatório informar se está ativa'),
            }),
            schemaWithMinMaxText: Yup.object().shape({
                fieldtext: Yup.string().required(
                    'O título da questão é obrigatório'
                ),
                type: Yup.string().required('O tipo de resposta é obrigatório'),
                isVisible: Yup.bool()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('É obrigatório informar se está ativa'),
                minText: Yup.string().required('O valor mínimo é obrigatório'),
                maxText: Yup.string().required('O valor máximo é obrigatório'),
            }),
            schemaWithMinMaxDateTime: Yup.object().shape({
                fieldtext: Yup.string().required(
                    'O título da questão é obrigatório'
                ),
                type: Yup.string().required('O tipo de resposta é obrigatório'),
                isVisible: Yup.bool()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('É obrigatório informar se está ativa'),
                hoursBefore: Yup.number().required(
                    'O tempo mínimo é obrigatório'
                ),
                hoursAfter: Yup.number().required(
                    'O tempo máximo é obrigatório'
                ),
            }),
            schemaWithOptions: Yup.object().shape({
                fieldtext: Yup.string().required(
                    'O título da questão é obrigatório'
                ),
                type: Yup.string().required('O tipo de resposta é obrigatório'),
                isVisible: Yup.bool()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('É obrigatório informar se está ativa'),
                textdescriptor: Yup.array().of(
                    Yup.string().required('A opção de resposta é obrigatória')
                ),
            }),
            schemaWithChoices: Yup.object().shape({
                fieldtext: Yup.string().required(
                    'O título da questão é obrigatório'
                ),
                type: Yup.string().required('O tipo de resposta é obrigatório'),
                isVisible: Yup.bool()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('É obrigatório informar se está ativa'),
                quantity: Yup.number().required(
                    'A quantidade de opções é obrigatória'
                ),
                choices: Yup.array().of(
                    Yup.string().required('A escolha da resposta é obrigatória')
                ),
            }),
        },
        condor_admin: null,
    },
    en: {
        customer_admin: {
            diarySchema: Yup.object().shape({
                name: Yup.string().required('Name is required'),
            }),
            schemaWithoutOptions: Yup.object().shape({
                fieldtext: Yup.string().required('Question title is required'),
                type: Yup.string().required('Answer type is required'),
                isVisible: Yup.bool()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('It is required to inform if it is active'),
            }),
            schemaWithMinMaxText: Yup.object().shape({
                fieldtext: Yup.string().required('Question title is required'),
                type: Yup.string().required('Answer type is required'),
                isVisible: Yup.bool()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('It is required to inform if it is active'),
                minText: Yup.string().required('Minimum value is required'),
                maxText: Yup.string().required('Maximum value is required'),
            }),
            schemaWithMinMaxDateTime: Yup.object().shape({
                fieldtext: Yup.string().required('Question title is required'),
                type: Yup.string().required('Answer type is required'),
                isVisible: Yup.bool()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('It is required to inform if it is active'),
                minTime: Yup.string()
                    .required('Minimum time is required')
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                hoursBefore: Yup.number().required(
                    'It is required to inform the number of previous hours'
                ),
                hoursAfter: Yup.number().required(
                    'It is required to inform the number of subsequent hours'
                ),
            }),
            schemaWithOptions: Yup.object().shape({
                fieldtext: Yup.string().required('Question title is required'),
                type: Yup.string().required('Answer type is required'),
                isVisible: Yup.bool()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('It is required to inform if it is active'),
                textdescriptor: Yup.array().of(
                    Yup.string().required('Answer option is required')
                ),
            }),
            schemaWithChoices: Yup.object().shape({
                fieldtext: Yup.string().required('Question title is required'),
                type: Yup.string().required('Answer type is required'),
                isVisible: Yup.bool()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('It is required to inform if it is active'),
                quantity: Yup.number().required(
                    'The number of choices is required'
                ),
                choices: Yup.array().of(
                    Yup.string().required('Answer choice is required')
                ),
            }),
        },
        manager: {
            diarySchema: Yup.object().shape({
                name: Yup.string().required('Name is required'),
            }),
            schemaWithoutOptions: Yup.object().shape({
                fieldtext: Yup.string().required('Question title is required'),
                type: Yup.string().required('Answer type is required'),
                isVisible: Yup.bool()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('It is required to inform if it is active'),
            }),
            schemaWithMinMaxText: Yup.object().shape({
                fieldtext: Yup.string().required('Question title is required'),
                type: Yup.string().required('Answer type is required'),
                isVisible: Yup.bool()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('It is required to inform if it is active'),
                minText: Yup.string().required('Minimum value is required'),
                maxText: Yup.string().required('Maximum value is required'),
            }),
            schemaWithMinMaxDateTime: Yup.object().shape({
                fieldtext: Yup.string().required('Question title is required'),
                type: Yup.string().required('Answer type is required'),
                isVisible: Yup.bool()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('It is required to inform if it is active'),
                minTime: Yup.string()
                    .required('Minimum time is required')
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                hoursBefore: Yup.number().required(
                    'It is required to inform the number of previous hours'
                ),
                hoursAfter: Yup.number().required(
                    'It is required to inform the number of subsequent hours'
                ),
            }),
            schemaWithOptions: Yup.object().shape({
                fieldtext: Yup.string().required('Question title is required'),
                type: Yup.string().required('Answer type is required'),
                isVisible: Yup.bool()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('It is required to inform if it is active'),
                textdescriptor: Yup.array().of(
                    Yup.string().required('Answer option is required')
                ),
            }),
            schemaWithChoices: Yup.object().shape({
                fieldtext: Yup.string().required('Question title is required'),
                type: Yup.string().required('Answer type is required'),
                isVisible: Yup.bool()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('It is required to inform if it is active'),
                quantity: Yup.number().required(
                    'The number of choices is required'
                ),
                choices: Yup.array().of(
                    Yup.string().required('Answer choice is required')
                ),
            }),
        },
        condor_admin: null,
    },
};

import api from '../../services/api';

export function fetchPlans(planType = null) {
    const data = api
        .get('/register/plans', {
            params: {
                type: planType,
            },
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchPlan(name) {
    const data = api
        .get(`/register/plans/${name}`, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchPlanEvent(name, coupon_id) {
    const data = api
        .get(`/register/event/${name}/${coupon_id}`, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function createTrialSubscription(parms) {
    const data = api
        .post(`/register/trial`, { ...parms }, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function createIuguSubscriptionIntent(parms) {
    const data = api
        .post(`/register/national`, { ...parms }, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function createStripeSubscriptionIntent(parms) {
    const data = api
        .post(
            `/register/international`,
            { ...parms },
            { withCredentials: true }
        )
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function verifyRecaptchaToken(parms) {
    const data = api
        .post(`/validate_recaptcha`, { ...parms }, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

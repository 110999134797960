import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

const InputText = ({ name, label, className, required = false, ...rest }) => {
    const inputRef = useRef(null);

    const { fieldName, defaultValue, registerField, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return (
        <>
            {label && (
                <label
                    htmlFor={fieldName}
                    dangerouslySetInnerHTML={{
                        __html: required
                            ? `${label} <b style="color: darkred">*</b>`
                            : label,
                    }}
                    data-cy={`${rest['data-cy']}_label`}
                ></label>
            )}

            <input
                id={fieldName}
                ref={inputRef}
                defaultValue={defaultValue}
                {...rest}
                className={`form-control ${className}`}
                data-cy={rest['data-cy']}
            />

            {error && (
                <span
                    className="text-danger"
                    data-cy={`${rest['data-cy']}-error`}
                >
                    {error}
                </span>
            )}
        </>
    );
};

InputText.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    className: PropTypes.string,
    required: PropTypes.bool,
    dataCy: PropTypes.string,
};

export default InputText;

const shapeY0 = -1000000;
const shapeY1 = 1000000;

export const temperatureLowerScaleFactor = 0.85;
export const temperatureHigherScaleFactor = 1.05;
export const activityHigherScaleFactor = 1.1;

export function createActivityTrace(x, y, color) {
    return {
        x: x,
        y: y,
        type: 'bar',
        yaxis: 'y1',
        xaxis: 'x1',
        hoverinfo: 'none',
        marker: {
            color,
            line: {
                width: 1,
                color,
            },
        },
        name: 'activity',
    };
}

export function createTemperatureTrace(x, y, color) {
    return {
        x: x,
        y: y,
        type: 'scatter',
        yaxis: 'y3',
        xaxis: 'x1',
        hoverinfo: 'none',
        mode: 'lines',
        line: {
            shape: 'linear',
            color,
            width: 2,
        },
        name: 'temperature',
    };
}

export function createLightTrace(x, y, color, name, width = 2) {
    return {
        x: x,
        y: y,
        type: 'scatter',
        yaxis: 'y2',
        xaxis: 'x1',
        hoverinfo: 'none',
        mode: 'lines',
        line: { shape: 'linear', color, width },
        name,
    };
}

export function createNoDataShapes(x, range, color) {
    let positions = [];

    if (x.length > 0) {
        if (range[0] != x[0]) {
            positions.push([range[0], x[0]]);
        }

        if (range[range.length - 1] != x[x.length - 1]) {
            positions.push([x[x.length - 1], range[range.length - 1]]);
        }
    } else {
        positions.push([range[0], range[range.length - 1]]);
    }

    let shapes = [];

    for (let position of positions) {
        shapes.push({
            type: 'rect',
            xref: 'x1',
            yref: 'y1',
            x0: position[0],
            y0: shapeY0,
            x1: position[1],
            y1: shapeY1,
            fillcolor: color,
            opacity: 0.2,
            line: { width: 0 },
        });
    }

    return shapes;
}

export function createEventShapes(x, event, color) {
    let shapes = [];

    let xValues = [];

    for (let i = 0; i < event.length; i++) {
        if (event[i] > 0) {
            xValues.push(x[i]);
        }
    }

    for (let i = 0; i < xValues.length; i++) {
        shapes.push({
            type: 'line',
            x0: xValues[i],
            y0: shapeY0,
            x1: xValues[i],
            y1: shapeY1,
            xref: 'x1',
            yref: 'y1',
            line: {
                color,
                width: 2,
                dash: 'dash',
            },
        });
    }

    return shapes;
}

export function createSleepDiaryShapes(sleepDiaryDates, color) {
    let shapes = [];

    for (let date of sleepDiaryDates) {
        shapes.push({
            type: 'line',
            x0: date,
            y0: shapeY0,
            x1: date,
            y1: shapeY1,
            xref: 'x1',
            yref: 'y1',
            line: {
                color,
                width: 2,
                dash: 'dash',
            },
        });
    }

    return shapes;
}

export function createOffwristShapes(logTimes, state, part, color) {
    let positions = []; // lista que guarda os x mínimos e x máximos obtidos
    let xMin = null; // x min correspondente aos inícios dos estados de offwrist
    let xMax = null; // x max correspondente aos finais dos estados de offwrist

    for (let i = 0; i < logTimes.length; i++) {
        if (part === 'wrist' ? state[i] === 4 : state[i] === 1) {
            if (xMin === null) {
                xMin = logTimes[i];
                xMax = logTimes[i];
            } else {
                xMax = logTimes[i];
            }
        } else {
            if (xMin !== null && xMax !== null) {
                positions.push([xMin, xMax]);

                xMin = null;
                xMax = null;
            }
        }
    }

    if (xMin !== null) {
        positions.push([xMin, logTimes[logTimes.length - 1]]);

        xMin = null;
        xMax = null;
    }

    let shapes = [];

    for (let position of positions) {
        shapes.push({
            type: 'rect',
            x0: position[0],
            y0: shapeY0,
            x1: position[1],
            y1: shapeY1,
            xref: 'x1',
            yref: 'y1',
            fillcolor: color,
            opacity: 0.2,
            line: { width: 0 },
        });
    }

    return shapes;
}

export function createSleepingShapes(logTimes, state, color) {
    let positions = []; // lista que guarda os x mínimos e x máximos obtidos
    let xMin = null; // x min correspondente aos inícios dos estados de offwrist
    let xMax = null; // x max correspondente aos finais dos estados de offwrist

    for (let i = 0; i < logTimes.length; i++) {
        if (state[i] === 1) {
            if (xMin === null) {
                xMin = logTimes[i];
                xMax = logTimes[i];
            } else {
                xMax = logTimes[i];
            }
        } else {
            if (xMin !== null && xMax !== null) {
                positions.push([xMin, xMax]);

                xMin = null;
                xMax = null;
            }
        }
    }

    if (xMin !== null) {
        positions.push([xMin, logTimes[logTimes.length - 1]]);

        xMin = null;
        xMax = null;
    }

    let shapes = [];

    for (let position of positions) {
        shapes.push({
            type: 'rect',
            x0: position[0],
            y0: shapeY0,
            x1: position[1],
            y1: shapeY1,
            xref: 'x1',
            yref: 'y1',
            fillcolor: color,
            opacity: 0.2,
            line: { width: 0 },
        });
    }

    return shapes;
}

export function createRestingShapes(logTimes, state, color) {
    let positions = []; // lista que guarda os x mínimos e x máximos obtidos
    let xMin = null; // x min correspondente aos inícios dos estados de offwrist
    let xMax = null; // x max correspondente aos finais dos estados de offwrist

    for (let i = 0; i < logTimes.length; i++) {
        if (state[i] === 2) {
            if (xMin === null) {
                xMin = logTimes[i];
                xMax = logTimes[i];
            } else {
                xMax = logTimes[i];
            }
        } else {
            if (xMin !== null && xMax !== null) {
                positions.push([xMin, xMax]);

                xMin = null;
                xMax = null;
            }
        }
    }

    if (xMin !== null) {
        positions.push([xMin, logTimes[logTimes.length - 1]]);

        xMin = null;
        xMax = null;
    }

    let shapes = [];

    for (let position of positions) {
        shapes.push({
            type: 'rect',
            x0: position[0],
            y0: shapeY0,
            x1: position[1],
            y1: shapeY1,
            xref: 'x1',
            yref: 'y1',
            fillcolor: color,
            opacity: 0.2,
            line: { width: 0 },
        });
    }

    return shapes;
}

export function connectLightValues(
    necklaceValues,
    wristValues,
    dataSize = 1440
) {
    const data_names = [
        'log_times',
        'lux_photopic',
        'lux_melanopic',
        'f1',
        'f2',
        'f3',
        'f4',
        'f5',
        'f6',
        'f7',
        'f8',
        'clear',
        'nir',
    ];

    const newNecklaceValues = {};

    for (let data_name of data_names) {
        newNecklaceValues[data_name] = {
            smaller: [],
            bigger: [],
        };
    }

    if (necklaceValues.log_times.length < dataSize) {
        let smaller = {};

        for (let data_name of data_names) {
            smaller[data_name] = [];
        }

        let bigger = {};

        for (let data_name of data_names) {
            bigger[data_name] = [];
        }

        for (let i = 0; i < wristValues.log_times.length; i++) {
            if (wristValues.log_times[i] < necklaceValues.log_times[0]) {
                for (let data_name of data_names) {
                    smaller[data_name].push(wristValues[data_name][i]);
                }
            } else if (
                wristValues.log_times[i] >
                necklaceValues.log_times[necklaceValues.log_times.length - 1]
            ) {
                for (let data_name of data_names) {
                    bigger[data_name].push(wristValues[data_name][i]);
                }
            }
        }

        for (let data_name of data_names) {
            newNecklaceValues[data_name].smaller = [...smaller[data_name]];
            newNecklaceValues[data_name].bigger = [...bigger[data_name]];
        }
    }

    return newNecklaceValues;
}

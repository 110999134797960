import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { PrivateLayout } from '../components/layout/privateLayout';
import { validateUserPermissions } from '../helpers/validateUserPermissions';
import { logout } from '../store/actions/authActions';

RouteWrapper.propTypes = {
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
        .isRequired,
    pageName: PropTypes.string,
    roles: PropTypes.array,
    isPrivate: PropTypes.bool.isRequired,
};

export default function RouteWrapper({
    component: Component,
    pageName,
    isPrivate = false,
    roles = [],
    ...rest
}) {
    const {
        signed,
        currentUser,
        completedLoginFlow,
        maxDateToCompleteLoginFlow,
    } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    if (
        signed &&
        !completedLoginFlow &&
        pageName !== 'verifyTwoFactorAuthentication'
    ) {
        if (
            typeof maxDateToCompleteLoginFlow !== 'number' ||
            maxDateToCompleteLoginFlow <= Date.now()
        ) {
            dispatch(logout());
            return null;
        }

        return <Redirect to="/two_factor_authentication/verify" />;
    }

    if (!signed && isPrivate) {
        return <Redirect to="/" />;
    }

    if (signed && !isPrivate) {
        return <Redirect to="/dashboard" />;
    }

    const userHasValidPermissions = validateUserPermissions({
        currentUser,
        roles,
    });

    if (!userHasValidPermissions) {
        return (
            <Redirect
                to={{
                    pathname: '/exception',
                    state: { type: 'unauthorized' },
                }}
            />
        );
    }

    let userHasActiveSubscription = false;

    currentUser?.subscription?.plans.forEach((subscriptionPlan) => {
        if (subscriptionPlan.active) {
            userHasActiveSubscription = true;
        }
    });

    if (
        signed &&
        !userHasActiveSubscription &&
        pageName !== 'exception' &&
        !rest.path.includes('/account')
    ) {
        return (
            <Redirect
                to={{
                    pathname: '/exception',
                    state: { type: 'subscriptionExpired' },
                }}
            />
        );
    }

    return (
        <Route
            {...rest}
            render={(props) => {
                if (signed) {
                    if (
                        !['print', 'verifyTwoFactorAuthentication'].find(
                            (name) => name === pageName
                        )
                    ) {
                        return (
                            <PrivateLayout pageName={pageName}>
                                <Component
                                    {...props}
                                    currentUser={currentUser}
                                />
                            </PrivateLayout>
                        );
                    } else {
                        return (
                            <Component {...props} currentUser={currentUser} />
                        );
                    }
                } else {
                    return <Component {...props} />;
                }
            }}
        />
    );
}

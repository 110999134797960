import history from '../../services/history';

export const stepsOptions = {
    pt: {
        welcome: [
            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: true,
                highlightClass: 'blackBorder',
                id: 'welcome',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Bem vindo ao Diário de Sono!',
                text: [
                    'Siga o nosso tutorial para aprender a utilizar o sistema do Diário de Sono!',
                ],
            },
            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: true,
                highlightClass: 'blackBorder',
                id: 'dashboard',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Dashboard',
                text: [
                    `O Dashboard é a primeira tela que você vê quando loga no sistema.
                Nele você pode acompanhar um panorama geral da sua coleta de dados.`,
                ],
            },
            {
                arrow: true,
                attachTo: { element: '.cardDashboard', on: 'bottom' },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'activePatients',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Pacientes ativos',
                text: ['Aqui você encontra a quantidade de pacientes ativos.'],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.dashboardTableRow',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'attendanceTable',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Tabela de Acompanhamento',
                text: [
                    'Na tabela de acompanhamento, são listados os pacientes que estão preenchendo o diário (ativos), e informações da coleta.',
                ],
            },
            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'addFirstPatient',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Inserir primeiro paciente',
                text: ['Agora vamos inserir o seu primeiro paciente!'],
            },
            {
                arrow: true,
                attachTo: {
                    element:
                        '.nav-link[title="Pacientes"], .nav-link[title="Patients"]',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            history.push('/users');
                            return this.next();
                        },
                        text: 'Próximo',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'patientsPage',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Página de pacientes',
                text: ['Acesse a página de Pacientes.'],
            },
        ],
        notFilledYet: [
            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: true,
                highlightClass: 'blackBorder',
                id: 'notFilledYet',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Ainda não preencheu!',
                text: [
                    `Parece que você ainda não preencheu nenhuma informação no diário de sono.
                Recarregue essa página quando tiver preechido um dia de dado no aplicativo.`,
                ],
            },
        ],
        firstPatient: [
            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'firstDataInput',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Primeiro dado!',
                text: [`Pronto, temos o seu primeiro dado preenchido!`],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.dashboardTable tbody td:last-child ',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'last10days',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Últimos 10 dias',
                text: [
                    `Este campo da tabela mostra o preenchimento do diário nos últimos 10 dias.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.circle.grey',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'grayCircle',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Círculo cinza',
                text: [
                    `Um círculo cinza representa que o dia em questão é anterior ao inicio da coleta.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.circle.green',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'greenCircle',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Círculo verde',
                text: [
                    `O círculo verde representa os dias em que o paciente preencheu o diário.`,
                ],
            },
            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Voltar',
                        type: 'back',
                    },
                    {
                        text: 'Próximo',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'redCircle',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Círculo vermelho',
                text: [
                    `Dias não preenchidos pelo paciente aparecerão com um círculo vermelho.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.dashboardTable tbody td:first-child ',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Pular tutorial',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'firstPatient',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Página de relatório',
                text: [
                    `Para acessar o relatório do seu paciente através do Dashboard,
                    basta clicar no nome do paciente.`,
                ],
            },
        ],
    },
    en: {
        welcome: [
            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: true,
                highlightClass: 'blackBorder',
                id: 'welcome',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Welcome to Sleep Diary!',
                text: [
                    'Follow our tutorial and learn how to use the Sleep Diary system!',
                ],
            },
            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: true,
                highlightClass: 'blackBorder',
                id: 'dashboard',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Dashboard',
                text: [
                    'The Dashboard is the first screen you see when you log into the system. Here you can follow an overview of your data collection.',
                ],
            },
            {
                arrow: true,
                attachTo: { element: '.cardDashboard', on: 'bottom' },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'activePatients',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Active patients',
                text: ['Find the number of active patients here.'],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.dashboardTableRow',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'attendanceTable',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Attendance table',
                text: [
                    'The patients who are filling out the diary (active) and collection information are listed in the follow-up table.',
                ],
            },
            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'addFirstPatient',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Insert first patient',
                text: [`Now let's enter your first patient!`],
            },
            {
                arrow: true,
                attachTo: {
                    element:
                        '.nav-link[title="Pacientes"], .nav-link[title="Patients"]',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            history.push('/users');
                            return this.next();
                        },
                        text: 'Next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'patientsPage',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Patients page',
                text: ['Access the Patients page.'],
            },
        ],
        notFilledYet: [
            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: true,
                highlightClass: 'blackBorder',
                id: 'notFilledYet',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Not filled in yet!',
                text: [
                    `It seems like you haven't filled in any information in your sleep diary yet.
                    Reload this page when you have filled in a day of data in the app.`,
                ],
            },
        ],
        firstPatient: [
            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'firstDataInput',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'First data!',
                text: [`Your first data is filled in!`],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.dashboardTable tbody td:last-child ',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'last10days',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Last 10 days',
                text: [
                    `This field in the table shows the diary completion for the last 10 days.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.circle.grey',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'grayCircle',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Gray Circle',
                text: [
                    `A gray circle represents that the day in question is prior to the start of the trial.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.circle.green',
                    on: 'top',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'greenCircle',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Green Circle',
                text: [
                    `The green circle represents the days the patient filled out the diary.`,
                ],
            },
            {
                arrow: false,
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                    {
                        secondary: true,
                        text: 'Back',
                        type: 'back',
                    },
                    {
                        text: 'Next',
                        type: 'next',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'redCircle',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Red Circle',
                text: [
                    `Days not filled in by the patient will appear with a red circle.`,
                ],
            },
            {
                arrow: true,
                attachTo: {
                    element: '.dashboardTable tbody td:first-child ',
                    on: 'bottom',
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 300);
                    });
                },
                buttons: [
                    {
                        action() {
                            localStorage.setItem('showWelcomeTour', 'false');
                            localStorage.setItem(
                                'showAddPatientButtonTour',
                                'false'
                            );
                            localStorage.setItem('showAddPatientTour', 'false');
                            localStorage.setItem(
                                'showAppDownloadTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showFirstPatientTour',
                                'false'
                            );
                            localStorage.setItem(
                                'showNotFilledYetTour',
                                'false'
                            );
                            localStorage.setItem('showReportTour', 'false');
                            return this.complete();
                        },
                        secondary: true,
                        text: 'Skip tutorial',
                    },
                ],
                canClickTarget: false,
                highlightClass: 'blackBorder',
                id: 'firstPatient',
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 0,
                popperOptions: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 12] } },
                    ],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                title: 'Report page',
                text: [
                    `To access your patient's report through the Dashboard, simply click on the patient's name.`,
                ],
            },
        ],
    },
};

export const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
            enabled: false,
        },
    },
    useModalOverlay: true,
};

import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: null,
        manager: {
            schemaWithCard: Yup.object().shape({
                description: Yup.string(),
                masked_number: Yup.string(),
                expiration_date: Yup.string(),
                new_description: Yup.string().required(),
                number: Yup.string().required(),
                verification_value: Yup.string().required(),
                full_name: Yup.string().required(),
                expiration: Yup.string().required(),
            }),
            schema: Yup.object().shape({
                description: Yup.string(),
                masked_number: Yup.string(),
                expiration_date: Yup.string(),
            }),
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            schemaWithCard: Yup.object().shape({
                description: Yup.string(),
                masked_number: Yup.string(),
                expiration_date: Yup.string(),
                new_description: Yup.string().required(),
                number: Yup.string().required(),
                verification_value: Yup.string().required(),
                full_name: Yup.string().required(),
                expiration: Yup.string().required(),
            }),
            schema: Yup.object().shape({
                description: Yup.string(),
                masked_number: Yup.string(),
                expiration_date: Yup.string(),
            }),
        },
        condor_admin: null,
    },
};

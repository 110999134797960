import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import '../../styles/components/layout/privateLayout.css';

import layoutConfig from './layout.config';
import { logout } from '../../store/actions/authActions';
import { setLang } from '../../store/actions/langActions';
import { Header } from './header';
import { Sidebar } from './sidebar';
import { Content } from './content';
import { Footer } from './footer';

export function PrivateLayout({ children, pageName }) {
    const lang = useSelector((state) => state.lang.lang);
    const currentUser = useSelector((state) => state.auth.currentUser);

    const dispatch = useDispatch();

    const [toggled, setToggled] = useState(false);
    const [buttons, setButtons] = useState([
        {
            name: 'pt',
            text: 'Português',
            disabled: lang === 'pt' ? true : false,
        },
        {
            name: 'en',
            text: 'English',
            disabled: lang === 'en' ? true : false,
        },
    ]);

    const pageData =
        layoutConfig[lang][currentUser === null ? {} : currentUser.permissions];

    function toggleSidebar() {
        setToggled(!toggled);
    }

    const handleChangeLang = (selectedLang) => {
        setButtons(
            buttons.map((button) => ({
                ...button,
                disabled: selectedLang === button.name ? true : false,
            }))
        );
        dispatch(setLang(selectedLang));
    };

    const handleLogout = () => {
        dispatch(logout());
    };

    if (currentUser === null) {
        return <></>;
    }

    return (
        <motion.div
            layoutScroll
            className={`privateLayout ${toggled ? 'toggled' : ''}`}
        >
            <Header
                currentUser={currentUser}
                pageData={pageData.header}
                handleLogout={handleLogout}
                toggleSidebar={toggleSidebar}
                buttons={buttons}
                handleChangeLang={handleChangeLang}
            />
            <Sidebar
                currentUser={currentUser}
                toggled={toggled}
                pageData={pageData.sidebar}
            />
            <Content pageData={pageData.content} pageName={pageName}>
                {children}
            </Content>
            <Footer />
        </motion.div>
    );
}

PrivateLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
        .isRequired,
    pageName: PropTypes.string,
};

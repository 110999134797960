import React from 'react';
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { FaFileDownload, FaFilePdf, FaFileImage } from 'react-icons/fa';
import PropTypes from 'prop-types';

import { exportAs } from '../../../../helpers/exportAs';

export function ExportButton({
    texts,
    userName,
    sectionName,
    componentRef,
    disabled,
    numberOfWeeks,
}) {
    async function handleDownloadSectionImage(element, sectionName) {
        await exportAs(
            'png',
            element,
            `${userName}_${sectionName}`,
            numberOfWeeks
        );
    }

    async function handleDownloadSectionPDF(element, sectionName) {
        await exportAs(
            'pdf',
            element,
            `${userName}_${sectionName}`,
            numberOfWeeks
        );
    }

    return (
        <UncontrolledButtonDropdown disabled={disabled}>
            <DropdownToggle
                caret
                color="info"
                data-cy={`user_report_${sectionName}_export_button_dropdown`}
            >
                <FaFileDownload /> {texts.exportText}
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem header>{texts.formatText}</DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                    onClick={() =>
                        handleDownloadSectionImage(componentRef, sectionName)
                    }
                    data-cy={`user_report_${sectionName}_export_png_button`}
                >
                    <FaFileImage /> {texts.pngText}
                </DropdownItem>
                <DropdownItem
                    onClick={() =>
                        handleDownloadSectionPDF(componentRef, sectionName)
                    }
                    data-cy={`user_report_${sectionName}_export_pdf_button`}
                >
                    <FaFilePdf /> {texts.pdfText}
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );
}

ExportButton.propTypes = {
    texts: PropTypes.object,
    userName: PropTypes.string,
    sectionName: PropTypes.string,
    componentRef: PropTypes.object,
    disabled: PropTypes.bool,
    numberOfWeeks: PropTypes.number,
};

import axios from 'axios';

import { failureResponse } from './validations';

const filesApi = axios.create({
    baseURL: process.env.REACT_APP_URL_API,
    // responseType: 'blob',
});

filesApi.interceptors.response.use(function (response) {
    return response;
}, failureResponse);

export default filesApi;

import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: null,
        manager: {
            schema: Yup.object().shape({
                name: Yup.string(),
                email: Yup.string().email('Formato inválido'),
                lang: Yup.string().required('Idioma é obrigatório'),
            }),
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            schema: Yup.object().shape({
                name: Yup.string(),
                email: Yup.string().email('Invalid format'),
                lang: Yup.string().required('Language is required'),
            }),
        },
        condor_admin: null,
    },
};

export const matricialData = [
    [
        0.5659553824992769, -0.0002240038362191897, -0.029002874894157107,
        -0.0207552314969549, 0.00032389263256187836, -0.008371404817513495,
        0.018969764821349575, -0.02515058927166642, -0.008083250688799002,
        -0.0011834686391282402,
    ],
    [
        -0.08290211313525954, 0.35594566920737364, -0.08543429237570818,
        0.03386043086396088, -0.019935287858605693, 0.0112666289396553,
        0.005312893276381804, -0.004687288167040625, -0.008941591666418572,
        -0.0012439243528988495,
    ],
    [
        0.02777699088767349, -0.052680856192182546, 0.22763013648062724,
        -0.05298164331202315, 0.0384268732094597, -0.021542224494119466,
        0.004250149672806458, -0.0038709366449722893, -0.0024259576804581684,
        -0.002180171455108848,
    ],
    [
        -0.004261583215032684, 0.010056892432124421, -0.029672534719886512,
        0.1479310511537957, -0.018949174693562366, 0.002288883103137816,
        0.005511447185953882, -0.007873566253536761, -0.00023642974352214207,
        -0.003929961597128618,
    ],
    [
        -0.004283976961056622, -0.003043624888637234, -0.003819635247523951,
        -0.006142674265547202, 0.1270510333473235, -0.03509410250139381,
        0.007827928325229775, -0.010173521372497406, 0.004243367040798462,
        -0.005264367948100071,
    ],
    [
        0.012537492737650896, 0.009008812754894116, 0.010569491218347899,
        0.007248166377326943, 0.024020140658540537, 0.09381782160086954,
        -0.010834030772627989, 0.017191695745855978, -0.02161815272560388,
        0.005930770005672233,
    ],
    [
        -0.053739127853880575, 0.0007467015602315996, -0.10712246002591996,
        -0.013844378115550171, -0.06531274452316893, 0.0222434351942411,
        0.03435849142138636, -0.017580084225315318, 0.0584204279922389,
        -0.03193539429125636,
    ],
    [
        0.024325960079124798, -0.008477066524108068, 0.06351778313444356,
        -0.01366906942081414, 0.053034673065297966, 0.010616287805589764,
        -0.029149448200399775, 0.06969344558984858, -0.02617757461086967,
        0.008918209909552388,
    ],
    [
        -0.0190989787238234, -0.0540554372366554, 0.06232264359790275,
        -0.04731579373149724, 0.023966466481676756, -0.03566314420221903,
        -0.032323803531926064, 0.0025047506081505707, 0.04286863080487802,
        -0.021216810118263808,
    ],
];

export const matricialWavelength = [
    415, 440, 470, 510, 550, 583, 620, 670, 750, 900,
];

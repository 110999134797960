import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../components/inputForm/inputText';

export function RenderDurations({
    dayInput,
    property,
    handleKeyUp,
    updateBackgroundColor,
    stats,
    disabled,
}) {
    const { lowerLimit, upperLimit, mean, std, intValue } = stats;

    if (dayInput[property] === null) {
        return null;
    }

    if (dayInput[property].length === 1) {
        return (
            <>
                <Input
                    name={`${property}0/${dayInput.date.slice(0, 10)}`}
                    upperlimit={upperLimit}
                    lowerlimit={lowerLimit}
                    mean={mean}
                    date={dayInput.date}
                    onKeyUp={handleKeyUp}
                    onBlur={updateBackgroundColor}
                    className={`editDataInput ${
                        std === 0 ||
                        (lowerLimit <= intValue[0] && intValue[0] <= upperLimit)
                            ? ''
                            : 'bgPink'
                    }`}
                    disabled={disabled}
                />
            </>
        );
    } else {
        return (
            <>
                <div className="durationWrapper">
                    <div className="durationContainer">
                        {dayInput[property].map((duration, index) => {
                            if (index % 2 === 0) {
                                return (
                                    <Input
                                        key={`${property}${index}/${dayInput.date.slice(
                                            0,
                                            10
                                        )}`}
                                        name={`${property}${index}/${dayInput.date.slice(
                                            0,
                                            10
                                        )}`}
                                        upperlimit={upperLimit}
                                        lowerlimit={lowerLimit}
                                        mean={mean}
                                        date={dayInput.date}
                                        onKeyUp={handleKeyUp}
                                        onBlur={updateBackgroundColor}
                                        className={`editDataInput ${
                                            std === 0 ||
                                            (lowerLimit <= intValue[index] &&
                                                intValue[index] <= upperLimit)
                                                ? ''
                                                : 'bgPink'
                                        }`}
                                        disabled={disabled}
                                    />
                                );
                            }
                        })}
                    </div>
                    <div className="durationContainer">
                        {dayInput[property].map((duration, index) => {
                            if (index % 2 !== 0) {
                                return (
                                    <Input
                                        key={`${property}${index}/${dayInput.date.slice(
                                            0,
                                            10
                                        )}`}
                                        name={`${property}${index}/${dayInput.date.slice(
                                            0,
                                            10
                                        )}`}
                                        upperlimit={upperLimit}
                                        lowerlimit={lowerLimit}
                                        mean={mean}
                                        date={dayInput.date}
                                        onKeyUp={handleKeyUp}
                                        onBlur={updateBackgroundColor}
                                        className={`editDataInput ${
                                            std === 0 ||
                                            (lowerLimit <= intValue[index] &&
                                                intValue[index] <= upperLimit)
                                                ? ''
                                                : 'bgPink'
                                        }`}
                                        disabled={disabled}
                                    />
                                );
                            }
                        })}
                    </div>
                </div>
            </>
        );
    }
}

RenderDurations.propTypes = {
    dayInput: PropTypes.object.isRequired,
    property: PropTypes.string.isRequired,
    handleKeyUp: PropTypes.func.isRequired,
    updateBackgroundColor: PropTypes.func.isRequired,
    stats: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
};

import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './searchAndAddContainer.module.css';

function SearchAndAddContainer({
    companies,
    setFilteredCompanies,
    searchByNameInput,
    addButtonLabel,
    addButtonTitle,
}) {
    const [nameFilter, setNameFilter] = useState('');

    function handleNameInputChange(e) {
        setNameFilter(e.target.value);
    }
    useEffect(() => {
        let tempFilteredCompanies = [];

        if (nameFilter) {
            tempFilteredCompanies = companies.filter((user) =>
                user.name.toUpperCase().includes(nameFilter.toUpperCase())
            );
        } else {
            tempFilteredCompanies = companies;
        }

        setFilteredCompanies(tempFilteredCompanies);
    }, [nameFilter]);

    return (
        <Row>
            <Col xs="12">
                <div className={styles.searchAndAddContainer}>
                    <input
                        name="searchName"
                        placeholder={searchByNameInput}
                        title={searchByNameInput}
                        value={nameFilter}
                        onChange={handleNameInputChange}
                    />
                    <Link
                        to={{
                            pathname: '/companies/add',
                        }}
                        className="btn btn-primary ml-2"
                        title={addButtonTitle}
                    >
                        {addButtonLabel}
                    </Link>
                </div>
            </Col>
        </Row>
    );
}

SearchAndAddContainer.propTypes = {
    companies: PropTypes.array.isRequired,
    setFilteredCompanies: PropTypes.func.isRequired,
    searchByNameInput: PropTypes.string.isRequired,
    addButtonLabel: PropTypes.string.isRequired,
    addButtonTitle: PropTypes.string.isRequired,
};

export default SearchAndAddContainer;

import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, FormGroup } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import {
    CardNumberElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import styles from './form.module.scss';

import { validateForm } from '../../../helpers/formValidator';
import { currencyFormatter } from '../../../helpers/formatters';
import { setLoading } from '../../../store/actions/loadingActions';
import FormRow from '../../../components/form/formRow';
import { IuguCreditCardForm } from '../../../components/form/iuguCreditCardForm';
import { StripeForm } from '../../../components/form/stripeCreditCardForm';
import {
    createTrialSubscription,
    createIuguSubscriptionIntent,
    createStripeSubscriptionIntent,
} from '../../../services/api/register';
import history from '../../../services/history';
import useIugu from '../../../hooks/useIugu';
import useWindowSize from '../../../hooks/useWindowSize';

RegisterForm.propTypes = {
    pageData: PropTypes.object.isRequired,
    schema: PropTypes.object.isRequired,
    currentType: PropTypes.string.isRequired,
    setCurrentType: PropTypes.func.isRequired,
    typeOptions: PropTypes.array.isRequired,
    planOptions: PropTypes.array.isRequired,
    setPlanOptions: PropTypes.func.isRequired,
    fidelityOptions: PropTypes.array.isRequired,
    setFidelityOptions: PropTypes.func.isRequired,
    plan: PropTypes.object.isRequired,
    setPlan: PropTypes.func.isRequired,
    plansGroupsByType: PropTypes.object.isRequired,
    setCardNumberComplete: PropTypes.func.isRequired,
    setCardExpiryComplete: PropTypes.func.isRequired,
    setCardCvcComplete: PropTypes.func.isRequired,
    setFilledInForm: PropTypes.func.isRequired,
    formReady: PropTypes.bool.isRequired,
    handleRecaptchaChange: PropTypes.func.isRequired,
    notARobot: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    locale: PropTypes.object.isRequired,
};

export function RegisterForm({
    pageData,
    schema,
    currentType,
    setCurrentType,
    typeOptions,
    planOptions,
    setPlanOptions,
    fidelityOptions,
    setFidelityOptions,
    plan,
    setPlan,
    plansGroupsByType,
    setCardNumberComplete,
    setCardExpiryComplete,
    setCardCvcComplete,
    setFilledInForm,
    formReady,
    handleRecaptchaChange,
    notARobot,
    lang,
    locale,
}) {
    const iugu = useIugu();
    const stripe = useStripe();
    const elements = useElements();
    const windowSize = useWindowSize();

    const [showCreditCardFields, setShowCreditCardFields] = useState(false);
    const [userIuguFlowData, setUserIuguFlowData] = useState(null);
    const [userStripeFlowData, setUserStripeFlowData] = useState(null);

    const formRef = useRef(null);

    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.loading.isLoading);

    function handleKeyUpForm() {
        const formData = formRef.current.getData();

        let empty = 0;

        for (let key in formData) {
            if (formData[key] === '') {
                empty += 1;
            }
        }

        setFilledInForm(empty ? false : true);
    }

    async function handleSubmit(data, { reset }, event) {
        event.preventDefault();

        const { parsedData, isValid, errors } = await validateForm(
            data,
            plan.type === 'sleep_diary' ? schema.sleep_diary : schema.lumus
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(setLoading(true));

            let isSuccess = false;

            const sendData = {
                company_name: parsedData.company_name,
                share: parsedData.share,
                name: parsedData.name,
                email: parsedData.email,
                password: parsedData.password,
                type: parsedData.type,
                fidelity: parsedData.fidelity,
                number_of_devices: parsedData?.number_of_devices,
                lang,
                locale: locale.currencyLocale,
                plan_id: plan.id,
            };

            if (plan.name.toLowerCase().includes('trial')) {
                const response = await createTrialSubscription(sendData);

                dispatch(setLoading(false));

                if (response) {
                    isSuccess = true;
                }
            } else {
                if (locale.currencyFormat === 'BRL') {
                    // avoid sending the form if iugu script has not yet been loaded
                    if (!iugu.impl()) {
                        dispatch(setLoading(false));

                        return;
                    }

                    // check if user has already started iugu subscription flow
                    if (userIuguFlowData) {
                        for (const [key, value] of Object.entries(
                            userIuguFlowData
                        )) {
                            sendData[key] = value;
                        }
                    }

                    // create iugu credit card token
                    const createTokenResponse = await iugu.createPaymentToken(
                        event.target
                    );

                    if (createTokenResponse?.errors) {
                        dispatch(setLoading(false));

                        if ('incorrect_number' in createTokenResponse.errors) {
                            toast.error(
                                pageData.iuguErrorsText.numberErrorText
                            );
                        } else if (
                            'incorrect_cvv' in createTokenResponse.errors
                        ) {
                            toast.error(pageData.iuguErrorsText.cvvErrorText);
                        } else if (
                            'incorrect_fullName' in createTokenResponse.errors
                        ) {
                            toast.error(
                                pageData.iuguErrorsText.fullNameErrorText
                            );
                        } else if (
                            'incorrect_expiration' in createTokenResponse.errors
                        ) {
                            toast.error(
                                pageData.iuguErrorsText.expirationErrorText
                            );
                        } else {
                            toast.error(
                                pageData.iuguErrorsText.defaultErrorText
                            );
                        }

                        return;
                    }

                    sendData.token = createTokenResponse.id;

                    // create iugu subscription intent
                    const response = await createIuguSubscriptionIntent(
                        sendData
                    );

                    dispatch(setLoading(false));

                    if (response) {
                        if (
                            response?.companyId !== null &&
                            response?.userIuguId !== null &&
                            response?.userId !== null &&
                            response?.paymentMethodStatus === 'succeeded' &&
                            response?.subscriptionId !== null &&
                            response?.subscriptionIuguId !== null
                        ) {
                            isSuccess = true;
                        } else {
                            toast.error(pageData?.failedRegister);

                            setUserIuguFlowData({ ...response });

                            return;
                        }
                    }
                } else {
                    // avoid sending the form if stripe promise has not yet been fulfilled
                    if (!stripe || !elements) {
                        dispatch(setLoading(false));

                        return;
                    }

                    // check if user has already started stripe subscription flow
                    if (userStripeFlowData) {
                        for (const [key, value] of Object.entries(
                            userStripeFlowData
                        )) {
                            sendData[key] = value;
                        }
                    }

                    // create stripe subscription intent
                    let tempClientSecret;

                    if (!userStripeFlowData?.clientSecret) {
                        sendData.price_id = plan.stripe_id;

                        const response = await createStripeSubscriptionIntent(
                            sendData
                        );

                        if (response) {
                            setUserStripeFlowData({ ...response });

                            if (
                                response?.companyId !== null &&
                                response?.userStripeId !== null &&
                                response?.userId !== null &&
                                response?.subscriptionStripeId !== null &&
                                response?.subscriptionId !== null &&
                                response?.clientSecret !== null
                            ) {
                                tempClientSecret = response.clientSecret;
                            } else {
                                toast.error(pageData?.failedRegister);

                                dispatch(setLoading(false));

                                return;
                            }
                        } else {
                            dispatch(setLoading(false));

                            return;
                        }
                    }

                    // confirm user payment on stripe
                    const result = await stripe.confirmCardPayment(
                        tempClientSecret ?? userStripeFlowData.clientSecret,
                        {
                            payment_method: {
                                card: elements.getElement(CardNumberElement),
                                billing_details: {
                                    name: sendData.company_name,
                                },
                            },
                        }
                    );

                    dispatch(setLoading(false));

                    if (result.error) {
                        if (result.error.code in pageData?.stripeErrors) {
                            if (
                                result.error.code === 'card_declined' &&
                                result.error.decline_code in
                                    pageData?.stripeErrors.card_decline_codes
                            ) {
                                toast.error(
                                    pageData?.stripeErrors?.card_decline_codes[
                                        result.error.decline_code
                                    ]
                                );
                            } else {
                                toast.error(
                                    pageData?.stripeErrors[result.error.code]
                                );
                            }
                        } else {
                            toast.error(pageData?.paymentFailed);
                        }

                        return;
                    } else {
                        if (result.paymentIntent.status === 'succeeded') {
                            isSuccess = true;
                        }
                    }
                }
            }

            if (isSuccess) {
                toast.success(pageData.successRegister, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: false,
                    style:
                        windowSize.width <= 480
                            ? {}
                            : {
                                  width: '400px',
                                  height: '100px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginLeft: '-45px',
                              },
                });

                history.push('/login');

                return;
            }
        }
    }

    function handleTypeChange(type) {
        if (type && type !== formRef.current.getFieldValue('type')) {
            let tempPlanGroup = {};

            let tempPlanOptions = [];

            let tempFidelityOptions = [];

            if (type === 'sleep_diary') {
                tempPlanGroup = plansGroupsByType[type];

                tempPlanOptions = plansGroupsByType[type].map((planGroup) => ({
                    id: planGroup.plan_name,
                    label: `${planGroup.plan_name} - ${planGroup.max_patients} ${pageData.patientsText}`,
                    value: planGroup.plan_name,
                }));
            } else {
                tempPlanGroup = plansGroupsByType[type];

                tempPlanOptions = plansGroupsByType[type].map((planGroup) => ({
                    id: planGroup.plan_name,
                    label: `${planGroup.plan_name}`,
                    value: planGroup.plan_name,
                }));
            }

            const planGroup = tempPlanGroup[0];

            planGroup.fidelities = planGroup.fidelities.sort().reverse();

            if (type === 'sleep_diary') {
                tempFidelityOptions = planGroup.fidelities.map((fidelity) => ({
                    id: fidelity,
                    label: `${
                        pageData.fidelityTexts[fidelity]
                    } - ${currencyFormatter(
                        planGroup.plan_prices[fidelity],
                        locale.currencyLocale,
                        locale.currencyFormat
                    )}`,
                    value: fidelity,
                }));
            } else {
                tempFidelityOptions = planGroup.fidelities.map((fidelity) => ({
                    id: fidelity,
                    label: `${pageData.fidelityTexts[fidelity]} - ${
                        pageData.fromText
                    } ${currencyFormatter(
                        planGroup.plan_prices[fidelity][0]['price'],
                        locale.currencyLocale,
                        locale.currencyFormat
                    )}`,
                    value: fidelity,
                }));

                formRef.current.setFieldValue('number_of_devices', 1);
            }

            formRef.current.setFieldValue('plan', {
                label: tempPlanOptions[0]?.label,
                value: tempPlanOptions[0]?.value,
            });

            formRef.current.setFieldValue('fidelity', {
                label: tempFidelityOptions[0]?.label,
                value: tempFidelityOptions[0]?.value,
            });

            setCurrentType(type);

            setFidelityOptions(tempFidelityOptions);

            setPlanOptions(tempPlanOptions);

            setPlan(
                planGroup.plans.find(
                    (plan) => plan.fidelity === tempFidelityOptions[0]?.value
                )
            );

            setShowCreditCardFields(!planGroup.plan_name.includes('Trial'));

            if (type === 'lumus') {
                setFilledInForm(false);
            }
        }
    }

    function handlePlanChange(plan_name) {
        if (plan_name && plan_name !== formRef.current.getFieldValue('plan')) {
            const planGroup = plansGroupsByType[currentType].find(
                (planGroup) => planGroup.plan_name === plan_name
            );

            if (planGroup) {
                formRef.current.setFieldValue(
                    'max_patients',
                    planGroup.max_patients
                );

                planGroup.fidelities = planGroup.fidelities.sort().reverse();

                let tempFidelityOptions = [];

                if (currentType === 'sleep_diary') {
                    tempFidelityOptions = planGroup.fidelities.map(
                        (fidelity) => ({
                            id: fidelity,
                            label: `${
                                pageData.fidelityTexts[fidelity]
                            } - ${currencyFormatter(
                                planGroup.plan_prices[fidelity],
                                locale.currencyLocale,
                                locale.currencyFormat
                            )}`,
                            value: fidelity,
                        })
                    );
                } else {
                    tempFidelityOptions = planGroup.fidelities.map(
                        (fidelity) => ({
                            id: fidelity,
                            label: `${pageData.fidelityTexts[fidelity]} - ${
                                pageData.fromText
                            } ${currencyFormatter(
                                planGroup.plan_prices[fidelity][0]['price'],
                                locale.currencyLocale,
                                locale.currencyFormat
                            )}`,
                            value: fidelity,
                        })
                    );

                    formRef.current.setFieldValue('number_of_devices', 1);
                }

                setFidelityOptions(tempFidelityOptions);

                formRef.current.setFieldValue('fidelity', {
                    label: tempFidelityOptions[0]?.label,
                    value: tempFidelityOptions[0]?.value,
                });

                let tempPlan = planGroup.plans.find(
                    (plan) => plan.fidelity === tempFidelityOptions[0]?.value
                );

                setPlan(tempPlan);

                setShowCreditCardFields(!planGroup.plan_name.includes('Trial'));
            } else {
                formRef.current.clearField('max_patients');
            }
        }
    }

    function handleFidelityChange(fidelity) {
        if (
            fidelity &&
            fidelity !== formRef.current.getFieldValue('fidelity')
        ) {
            const plan_name = formRef.current.getFieldValue('plan');

            const planGroup = plansGroupsByType[currentType].find(
                (planGroup) => planGroup.plan_name === plan_name
            );

            if (planGroup) {
                const selectedPlan = planGroup.plans.find(
                    (plan) => plan.fidelity === fidelity
                );

                if (
                    selectedPlan.type === 'lumus' &&
                    formRef.current.getFieldValue('number_of_devices')
                ) {
                    const numberOfDevices = Number(
                        formRef.current.getFieldValue('number_of_devices')
                    );

                    const unitPrice = getUnitPrice(
                        selectedPlan.prices,
                        numberOfDevices
                    );

                    formRef.current.setFieldValue(
                        'unit_price',
                        currencyFormatter(
                            unitPrice,
                            locale.currencyLocale,
                            locale.currencyFormat
                        )
                    );

                    formRef.current.setFieldValue(
                        'price',
                        currencyFormatter(
                            unitPrice * numberOfDevices,
                            locale.currencyLocale,
                            locale.currencyFormat
                        )
                    );
                }

                setPlan(selectedPlan);
            }
        }
    }

    function handleNumberOfDevicesChange(newNumber) {
        const newNumberOfDevices = Number(newNumber);

        const unitPrice = getUnitPrice(plan.prices, newNumberOfDevices);

        formRef.current.setFieldValue(
            'unit_price',
            currencyFormatter(
                unitPrice,
                locale.currencyLocale,
                locale.currencyFormat
            )
        );

        formRef.current.setFieldValue(
            'price',
            currencyFormatter(
                unitPrice * newNumberOfDevices,
                locale.currencyLocale,
                locale.currencyFormat
            )
        );

        handleKeyUpForm();
    }

    function getUnitPrice(prices, numberOfDevices) {
        let unitPrice = 0;

        for (let i = 0; i < prices.length; i++) {
            if (numberOfDevices <= prices[i].up_to) {
                unitPrice =
                    locale.currencyLocale === 'pt-BR'
                        ? prices[i].price_brl
                        : prices[i].price_usd;

                break;
            }
        }

        return unitPrice;
    }

    useEffect(() => {
        formRef.current.reset();

        const planGroup = plansGroupsByType['sleep_diary'].find(
            (plan) => plan.plan_name === 'Standard'
        );

        formRef.current.setData({
            price: currencyFormatter(
                planGroup.plan_prices.monthly,
                locale.currencyLocale,
                locale.currencyFormat
            ),
            type: {
                label: typeOptions[0]?.label,
                value: typeOptions[0]?.value,
            },
            plan: {
                label: `${planGroup.plan_name} - ${planGroup.max_patients} ${pageData.patientsText}`,
                value: planGroup.plan_name,
            },
            fidelity: {
                label: fidelityOptions[0]?.label,
                value: fidelityOptions[0]?.value,
            },
        });
    }, []);

    return (
        <Form
            ref={formRef}
            onSubmit={handleSubmit}
            onKeyUp={handleKeyUpForm}
            className={styles.registerForm}
        >
            <FormRow>
                <FormGroup className="col-md-12 text-center">
                    <h5>{pageData.formLabel}</h5>
                </FormGroup>
            </FormRow>

            {pageData.fields.companyInfo.map((field) => (
                <FormRow key={field.props.name}>
                    <FormGroup
                        className={`${field.className} ${
                            field.props.name === 'share'
                                ? styles.registerRadioContainer
                                : ''
                        }`}
                    >
                        <field.component {...field.props} />
                    </FormGroup>
                </FormRow>
            ))}

            {pageData.fields.planInfo.map((field) => {
                if (field.props.name === 'type') {
                    return (
                        <FormRow key={field.props.name}>
                            <FormGroup className={field.className}>
                                <field.component
                                    {...field.props}
                                    options={typeOptions}
                                    onChange={(e) => handleTypeChange(e?.value)}
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                    styles={{
                                        menuPortal: (provided) => ({
                                            ...provided,
                                            zIndex: 9999,
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            </FormGroup>
                        </FormRow>
                    );
                } else if (field.props.name === 'plan') {
                    return (
                        <FormRow key={field.props.name}>
                            <FormGroup className={field.className}>
                                <field.component
                                    {...field.props}
                                    options={planOptions}
                                    onChange={(e) => handlePlanChange(e?.value)}
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                    styles={{
                                        menuPortal: (provided) => ({
                                            ...provided,
                                            zIndex: 9999,
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            </FormGroup>
                        </FormRow>
                    );
                } else if (field.props.name === 'fidelity') {
                    return (
                        <FormRow key={field.props.name}>
                            <FormGroup className={field.className}>
                                <field.component
                                    {...field.props}
                                    options={fidelityOptions}
                                    onChange={(e) =>
                                        handleFidelityChange(e?.value)
                                    }
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                    styles={{
                                        menuPortal: (provided) => ({
                                            ...provided,
                                            zIndex: 9999,
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            </FormGroup>
                        </FormRow>
                    );
                } else if (field.props.name === 'number_of_devices') {
                    return currentType === 'lumus' ? (
                        <FormRow key={field.props.name}>
                            <FormGroup className={field.className}>
                                <field.component
                                    {...field.props}
                                    onChange={(e) =>
                                        handleNumberOfDevicesChange(
                                            e?.target?.value
                                        )
                                    }
                                />
                            </FormGroup>
                        </FormRow>
                    ) : null;
                } else if (field.props.name === 'unit_price') {
                    return currentType === 'lumus' ? (
                        <FormRow key={field.props.name}>
                            <FormGroup className={field.className}>
                                <field.component {...field.props} />
                            </FormGroup>
                        </FormRow>
                    ) : null;
                } else if (field.props.name === 'price') {
                    return currentType === 'lumus' ? (
                        <FormRow key={field.props.name}>
                            <FormGroup className={field.className}>
                                <field.component {...field.props} />
                            </FormGroup>
                        </FormRow>
                    ) : null;
                } else {
                    return (
                        <FormRow key={field.props.name}>
                            <FormGroup className={field.className}>
                                <field.component {...field.props} />
                            </FormGroup>
                        </FormRow>
                    );
                }
            })}

            {showCreditCardFields && locale.currencyFormat === 'BRL' && (
                <IuguCreditCardForm
                    fields={pageData.fields.new_card_info}
                    imgAltTexts={pageData.imgAltTexts}
                    creditCardLabelText={pageData.creditCardLabelText}
                    iugu={iugu}
                />
            )}

            {showCreditCardFields && locale.currencyFormat === 'USD' && (
                <StripeForm
                    creditCardLabelText={pageData.creditCardLabelText}
                    setCardNumberComplete={setCardNumberComplete}
                    setCardExpiryComplete={setCardExpiryComplete}
                    setCardCvcComplete={setCardCvcComplete}
                />
            )}

            {formReady && (
                <FormRow>
                    <FormGroup className="col-md-12 mt-3 d-flex justify-content-center">
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={handleRecaptchaChange}
                            hl={
                                locale.currencyLocale === 'pt-BR'
                                    ? 'pt-BR'
                                    : 'en'
                            }
                            size={
                                windowSize.width <= 450 ? 'compact' : 'normal'
                            }
                        />
                    </FormGroup>
                </FormRow>
            )}

            <FormRow>
                <Button
                    color="primary"
                    type="submit"
                    className="col-md-12 mt-4"
                    disabled={!notARobot || isLoading}
                >
                    {isLoading ? pageData.creating : pageData.createAccount}
                </Button>
            </FormRow>

            <FormRow>
                <FormGroup className="col-md-12 mt-3 text-center">
                    <span>
                        {pageData.alreadyHasAnAccount}
                        <Link to="/login">{pageData.accessIt}</Link>.
                    </span>
                </FormGroup>
            </FormRow>
        </Form>
    );
}

import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import { PlanCard } from '../../../../components/planCard';
import Input from '../../../../components/inputForm/inputText';
import {
    currencyFormatter,
    localeDateFormatter,
} from '../../../../helpers/formatters';
import { setLoading } from '../../../../store/actions/loadingActions';
import { cancelSubscription } from '../../../../services/api/account';
import history from '../../../../services/history';

MyAccountForm.propTypes = {
    currentUser: PropTypes.object.isRequired,
    paymentMethod: PropTypes.object,
    currency: PropTypes.object,
    pendingInvoice: PropTypes.object,
    texts: PropTypes.shape({
        subscriptionAndBillingText: PropTypes.string.isRequired,
        emailText: PropTypes.string.isRequired,
        changeEmailText: PropTypes.string.isRequired,
        cardText: PropTypes.string.isRequired,
        managePaymentInformationText: PropTypes.string.isRequired,
        priceText: PropTypes.string.isRequired,
        subscriptionExpiresOnText: PropTypes.string.isRequired,
        nextBillingDateText: PropTypes.string.isRequired,
        plansText: PropTypes.string.isRequired,
        monthlyPaymentFrequencyText: PropTypes.string.isRequired,
        fidelityInputText: PropTypes.object.isRequired,
        unlimitedActivePatientsText: PropTypes.string.isRequired,
        activePatientsText: PropTypes.string.isRequired,
        unlimitedActiveDevicesText: PropTypes.string.isRequired,
        activeDevicesText: PropTypes.string.isRequired,
        expiresAtText: PropTypes.string.isRequired,
        activeText: PropTypes.string.isRequired,
        suspendedText: PropTypes.string.isRequired,
        expiredText: PropTypes.string.isRequired,
        cancelText: PropTypes.string.isRequired,
        attentionText: PropTypes.string.isRequired,
        closeText: PropTypes.string.isRequired,
        unsubscribeText: PropTypes.string.isRequired,
        subscriptionTypeText: PropTypes.object.isRequired,
        cancelSuccessText: PropTypes.string.isRequired,
        wrongFormatText: PropTypes.string.isRequired,
        testTrialDiaryPlanText: PropTypes.string.isRequired,
        testTrialLumusPlanText: PropTypes.string.isRequired,
        signDiaryPlanText: PropTypes.string.isRequired,
        changeDiaryPlanText: PropTypes.string.isRequired,
        signLumusPlanText: PropTypes.string.isRequired,
        changeLumusPlanText: PropTypes.string.isRequired,
        payPendingInvoiceText: PropTypes.string.isRequired,
    }).isRequired,
};

export function MyAccountForm({
    currentUser,
    paymentMethod,
    currency,
    pendingInvoice,
    texts,
}) {
    const formRef = useRef(null);

    const dispatch = useDispatch();

    const { dateLocale } = useSelector((state) => state.locale);
    const lang = useSelector((state) => state.lang.lang);

    const sleepDiarySubscription = currentUser.subscription.plans.find(
        (subscriptionPlan) => subscriptionPlan.type === 'sleep_diary'
    );

    const lumusSubscription = currentUser.subscription.plans.find(
        (subscriptionPlan) => subscriptionPlan.type === 'lumus'
    );

    const handleClick = async (planId, subscriptionType) => {
        dispatch(setLoading(true));

        const response = await cancelSubscription({
            subscription_id: currentUser.subscription.id,
            plan_id: planId,
        });

        dispatch(setLoading(false));

        if (response) {
            let expiresAt =
                subscriptionType === 'sleep_diary'
                    ? sleepDiarySubscription?.expires_at
                    : lumusSubscription?.expires_at;

            toast.success(
                `${texts.cancelSuccessText} ${
                    localeDateFormatter(expiresAt, dateLocale)
                        ? localeDateFormatter(expiresAt, dateLocale)
                        : texts.wrongFormatText
                }`,
                {
                    autoClose: 10000,
                }
            );

            history.replace('/account/my_account');
        }
    };

    useEffect(() => {
        formRef.current.reset();

        formRef.current.setData({
            email: currentUser.email,
            payment_method: paymentMethod
                ? currency.locale === 'pt-BR'
                    ? paymentMethod?.data?.display_number
                    : '•••• •••• •••• ' + paymentMethod?.card?.last4
                : undefined,
            price: currencyFormatter(
                currentUser?.subscription.price,
                currency.locale,
                currency.format
            ),
            expires_at:
                currentUser?.subscription?.expires_at &&
                currentUser?.subscription?.suspended
                    ? localeDateFormatter(
                          currentUser.subscription.expires_at,
                          dateLocale
                      )
                        ? localeDateFormatter(
                              currentUser.subscription.expires_at,
                              dateLocale
                          )
                        : texts.wrongFormatText
                    : undefined,
            billing_date: currentUser?.subscription?.billing_date
                ? localeDateFormatter(
                      currentUser.subscription.billing_date,
                      dateLocale
                  )
                    ? localeDateFormatter(
                          currentUser.subscription.billing_date,
                          dateLocale
                      )
                    : texts.wrongFormatText
                : undefined,
        });
    }, [lang]);

    return (
        <Form ref={formRef}>
            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-12">
                    <span>{texts.subscriptionAndBillingText}</span>
                </FormGroup>
            </FormGroup>

            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-4">
                    <Input
                        name="email"
                        type="text"
                        label={texts.emailText}
                        readOnly
                    />
                    <span>
                        <b>
                            <Link to="/account/my_profile">
                                {texts.changeEmailText}
                            </Link>
                        </b>
                    </span>
                </FormGroup>
            </FormGroup>

            <FormGroup row>
                {paymentMethod && (
                    <FormGroup className="col-xs-12 col-md-3">
                        <Input
                            name="payment_method"
                            type="text"
                            label={texts.cardText}
                            readOnly
                        />
                        <span>
                            <b>
                                <Link
                                    to={{
                                        state: {
                                            paymentMethod,
                                        },
                                        pathname:
                                            '/account/my_account/update_payment_method',
                                    }}
                                >
                                    {texts.managePaymentInformationText}
                                </Link>
                            </b>
                        </span>
                    </FormGroup>
                )}

                <FormGroup className="col-xs-12 col-md-2">
                    <Input
                        name="price"
                        type="text"
                        label={texts.priceText}
                        readOnly
                    />
                </FormGroup>

                {currentUser?.subscription?.expires_at &&
                    currentUser?.subscription?.suspended && (
                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="expires_at"
                                type="text"
                                label={texts.subscriptionExpiresOnText}
                                readOnly
                            />
                        </FormGroup>
                    )}

                {currentUser?.subscription?.billing_date && (
                    <FormGroup className="col-xs-12 col-md-3">
                        <Input
                            name="billing_date"
                            type="text"
                            label={texts.nextBillingDateText}
                            readOnly
                        />
                    </FormGroup>
                )}
            </FormGroup>

            <hr />

            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-12">
                    <span>{texts.plansText}</span>
                </FormGroup>
            </FormGroup>

            <FormGroup row className={styles.planCardsContainer}>
                {currentUser?.subscription?.plans?.map((subscriptionPlan) => (
                    <PlanCard
                        key={subscriptionPlan.type}
                        planName={subscriptionPlan?.plan?.name}
                        price={currencyFormatter(
                            subscriptionPlan.price,
                            currency.locale,
                            currency.format
                        )}
                        paymentFrequency={texts.monthlyPaymentFrequencyText}
                        fidelity={
                            texts.fidelityInputText[subscriptionPlan.fidelity]
                        }
                        numberOfEntities={
                            subscriptionPlan?.type === 'sleep_diary'
                                ? subscriptionPlan?.plan?.name === 'Unlimited'
                                    ? texts.unlimitedActivePatientsText
                                    : `${subscriptionPlan?.plan?.max_patients} ${texts.activePatientsText}`
                                : subscriptionPlan?.plan?.name ===
                                  'Lumus Unlimited'
                                ? texts.unlimitedActiveDevicesText
                                : `${subscriptionPlan?.max_devices} ${texts.activeDevicesText}`
                        }
                        active={subscriptionPlan.active}
                        suspended={subscriptionPlan.suspended}
                        expiresIn={
                            subscriptionPlan?.expires_at
                                ? localeDateFormatter(
                                      subscriptionPlan?.expires_at,
                                      dateLocale
                                  )
                                    ? `${
                                          texts.expiresAtText
                                      } ${localeDateFormatter(
                                          subscriptionPlan?.expires_at,
                                          dateLocale
                                      )}`
                                    : null
                                : null
                        }
                        trialSubscriptionExpiresIn={
                            subscriptionPlan.plan.name
                                .toLowerCase()
                                .includes('trial')
                                ? localeDateFormatter(
                                      subscriptionPlan?.expires_at,
                                      dateLocale
                                  )
                                    ? `${
                                          texts.expiresAtText
                                      } ${localeDateFormatter(
                                          subscriptionPlan?.expires_at,
                                          dateLocale
                                      )}`
                                    : null
                                : null
                        }
                        cancelDate={
                            subscriptionPlan.active &&
                            subscriptionPlan.suspended
                                ? localeDateFormatter(
                                      subscriptionPlan?.expires_at,
                                      dateLocale
                                  )
                                    ? `${
                                          texts.expiresAtText
                                      } ${localeDateFormatter(
                                          subscriptionPlan?.expires_at,
                                          dateLocale
                                      )}`
                                    : null
                                : null
                        }
                        texts={{
                            activeText: texts.activeText,
                            suspendedText: texts.suspendedText,
                            expiredText: texts.expiredText,
                            cancelText: texts.cancelText,
                            attentionText: texts.attentionText,
                            warningCancelText:
                                texts[
                                    `${subscriptionPlan.fidelity}WarningCancelText`
                                ],
                            closeText: texts.closeText,
                            unsubscribeText: texts.unsubscribeText,
                        }}
                        subscriptionType={
                            texts.subscriptionTypeText[subscriptionPlan.type]
                        }
                        showButton
                        onClick={() =>
                            handleClick(
                                subscriptionPlan?.plan?.id,
                                subscriptionPlan?.type
                            )
                        }
                    />
                ))}
            </FormGroup>

            <hr />

            <FormGroup row className="my-0">
                <div className="col-xs-12 col-md-12 text-left">
                    {pendingInvoice ? (
                        <Link
                            to={{
                                pathname:
                                    '/account/my_account/pay_pending_invoice',
                                state: {
                                    currency,
                                    paymentMethod,
                                    pendingInvoice,
                                },
                            }}
                            className="btn btn-primary mr-2"
                        >
                            {texts.payPendingInvoiceText}
                        </Link>
                    ) : (
                        <>
                            {sleepDiarySubscription ? (
                                <Link
                                    to={{
                                        pathname:
                                            '/account/my_account/change_plan',
                                        state: {
                                            currency,
                                            paymentMethod,
                                            type: 'sleep_diary',
                                        },
                                    }}
                                    className="btn btn-primary mr-2"
                                >
                                    {!sleepDiarySubscription?.active
                                        ? texts.signDiaryPlanText
                                        : texts.changeDiaryPlanText}
                                </Link>
                            ) : (
                                <Link
                                    to={{
                                        pathname:
                                            '/account/my_account/sign_trial_subscription',
                                        state: {
                                            planType: 'sleep_diary',
                                            currency,
                                        },
                                    }}
                                    className="btn btn-primary mr-2"
                                >
                                    {texts.testTrialDiaryPlanText}
                                </Link>
                            )}

                            {lumusSubscription ? (
                                <Link
                                    to={{
                                        pathname:
                                            '/account/my_account/change_plan',
                                        state: {
                                            currency,
                                            paymentMethod,
                                            type: 'lumus',
                                        },
                                    }}
                                    className="btn btn-primary mr-2"
                                >
                                    {!lumusSubscription?.active
                                        ? texts.signLumusPlanText
                                        : texts.changeLumusPlanText}
                                </Link>
                            ) : (
                                <Link
                                    to={{
                                        pathname:
                                            '/account/my_account/sign_trial_subscription',
                                        state: {
                                            planType: 'lumus',
                                            currency,
                                        },
                                    }}
                                    className="btn btn-primary mr-2"
                                >
                                    {texts.testTrialLumusPlanText}
                                </Link>
                            )}
                        </>
                    )}
                </div>
            </FormGroup>
        </Form>
    );
}

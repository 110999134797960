import React from 'react';
import { Button } from 'reactstrap';
import {
    faCheckCircle,
    faTimesCircle,
    faEdit,
    faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

export function ButtonContainer({
    buttonText,
    buttonsTexts,
    loading,
    index,
    dayInput,
    handleClick,
}) {
    if (buttonText === buttonsTexts.saveButtonText) {
        return (
            <div className="d-flex flex-row justify-content-center align-items-center">
                <Button
                    id={buttonsTexts.saveButtonText}
                    className="mr-2"
                    type="button"
                    onClick={(e) => handleClick(e, index, dayInput)}
                    disabled={loading}
                    title={buttonsTexts.saveButtonText}
                    color="success"
                >
                    <FontAwesomeIcon icon={faCheckCircle} />
                </Button>
                <Button
                    id={buttonsTexts.cancelButtonText}
                    type="button"
                    onClick={(e) => handleClick(e, index, dayInput)}
                    disabled={loading}
                    title={buttonsTexts.cancelButtonText}
                    color="danger"
                >
                    <FontAwesomeIcon icon={faTimesCircle} />
                </Button>
            </div>
        );
    } else {
        return buttonText === buttonsTexts.editButtonText ? (
            <Button
                id={buttonsTexts.editButtonText}
                type="button"
                onClick={(e) => handleClick(e, index, dayInput)}
                disabled={loading}
                title={buttonsTexts.editButtonText}
            >
                <FontAwesomeIcon icon={faEdit} />
            </Button>
        ) : (
            <Button
                id={buttonsTexts.loadingButtonText}
                type="button"
                onClick={(e) => handleClick(e, index, dayInput)}
                disabled={loading}
                title={buttonsTexts.loadingButtonText}
            >
                <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
            </Button>
        );
    }
}

ButtonContainer.propTypes = {
    buttonText: PropTypes.string.isRequired,
    buttonsTexts: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    dayInput: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired,
};

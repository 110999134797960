import * as Yup from 'yup';

export default {
    pt: {
        schema: Yup.object().shape({
            name: Yup.string().max(50),
            secondary_id: Yup.string().max(50),
            email: Yup.string().email('Formato inválido'),
        }),
        rawSchemaPassword: [
            {
                id: 'password',
                validationType: 'string',
                validations: [
                    {
                        type: 'max',
                        params: [
                            30,
                            'A senha deve ter no máximo 30 caracteres',
                        ],
                    },
                    {
                        type: 'oneOf',
                        params: [
                            [Yup.ref('confirm_password'), null],
                            'As senhas devem ser iguais',
                        ],
                    },
                ],
            },
            {
                id: 'confirm_password',
                validationType: 'string',
                validations: [
                    {
                        type: 'max',
                        params: [
                            30,
                            'A senha deve ter no máximo 30 caracteres',
                        ],
                    },
                    {
                        type: 'oneOf',
                        params: [
                            [Yup.ref('password'), null],
                            'As senhas devem ser iguais',
                        ],
                    },
                ],
            },
        ],
    },
    en: {
        schema: Yup.object().shape({
            name: Yup.string().max(50),
            secondary_id: Yup.string().max(50),
            email: Yup.string().email('Invalid format'),
        }),
        rawSchemaPassword: [
            {
                id: 'password',
                validationType: 'string',
                validations: [
                    {
                        type: 'max',
                        params: [30, 'Password must be at most 30 characters'],
                    },
                    {
                        type: 'oneOf',
                        params: [
                            [Yup.ref('confirm_password'), null],
                            'Passwords must match',
                        ],
                    },
                ],
            },
            {
                id: 'confirm_password',
                validationType: 'string',
                validations: [
                    {
                        type: 'max',
                        params: [30, 'Password must be at most 30 characters'],
                    },
                    {
                        type: 'oneOf',
                        params: [
                            [Yup.ref('password'), null],
                            'Passwords must match',
                        ],
                    },
                ],
            },
        ],
    },
};

import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import FormFields from '../../../../components/form/formFields';
import { setLoading } from '../../../../store/actions/loadingActions';
import { deleteCoupon } from '../../../../services/api/coupons';
import history from '../../../../services/history';

export function DeleteCouponForm({ coupon, fields, texts, lang }) {
    const formRef = useRef(null);

    const dispatch = useDispatch();

    const {
        deleteSuccessText,
        deleteText,
        cancelText,
        currencyText,
        percentageText,
        amountText,
    } = texts;

    async function handleSubmit(data) {
        dispatch(setLoading(true));

        const response = await deleteCoupon(coupon.id);

        dispatch(setLoading(false));

        if (response) {
            toast.success(deleteSuccessText);
            history.push('/coupons');
        }
    }

    useEffect(() => {
        formRef.current.reset();

        let initialData = fields.commonFields.reduce(
            (acc, { props: { name } }) => ({
                ...acc,
                [name]: coupon[name],
            }),
            {}
        );

        initialData = fields.percentFields.reduce(
            (acc, { props: { name } }) => ({
                ...acc,
                [name]: coupon[name],
            }),
            initialData
        );

        initialData = fields.amountFields.reduce(
            (acc, { props: { name } }) => ({
                ...acc,
                [name]: coupon[name],
            }),
            initialData
        );

        formRef.current.setData(initialData);
        formRef.current.setFieldValue('currency[0]', coupon.currency[0]);
        formRef.current.setFieldValue('currency[1]', coupon.currency[1]);
        formRef.current.setFieldValue(
            'is_percent',
            coupon.percentOff ? percentageText : amountText
        );
    }, [lang]);

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <FormGroup row>
                <FormFields fields={fields.commonFields} />
            </FormGroup>
            {coupon?.percentOff ? (
                <FormGroup row>
                    <FormFields fields={fields.percentFields} />
                </FormGroup>
            ) : (
                <FormGroup row>
                    <FormFields fields={fields.amountFields} />
                </FormGroup>
            )}
            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-12">
                    <span>{currencyText}</span>
                </FormGroup>
            </FormGroup>
            <FormGroup row>
                <FormFields fields={fields.currencyFields} />
            </FormGroup>
            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button color="primary mr-2" type="submit">
                        {deleteText}
                    </Button>
                    <Link to="/coupons" className="btn btn-secondary">
                        {cancelText}
                    </Link>
                </div>
            </FormGroup>
        </Form>
    );
}

DeleteCouponForm.propTypes = {
    coupon: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    texts: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

export function CardBodyPrice({ planPrice, planPaymentTypeText, fidelityText }) {
    return (
        <>
            <div className="planItemCardPrice">
                <div className="price">
                    <h3>{planPrice}</h3>
                    <small>
                        <b>{planPaymentTypeText}</b>
                    </small>
                </div>
                {fidelityText && (
                    <div className="fidelity">
                        <p>{fidelityText}</p>
                    </div>
                )}
            </div>
        </>
    );
}

CardBodyPrice.propTypes = {
    planPrice: PropTypes.string.isRequired,
    planPaymentTypeText: PropTypes.string.isRequired,
    fidelityText: PropTypes.string,
};

import api from '../../services/api';

export function fetchDiaries() {
    const data = api
        .get('/diaries', { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function addDiary(parms) {
    const data = api
        .post('/diaries', { ...parms }, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function editDiary(id, parms) {
    const data = api
        .put(`/diaries/${id}`, { ...parms }, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function deleteDiary(id) {
    const data = api
        .delete(`/diaries/${id}`, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function duplicateDiary(id) {
    const data = api
        .post(`/diaries/duplicate/${id}`, {}, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

import api from '../../services/api';

export function addCoupon(parms) {
    const data = api
        .post(`/coupons`, { ...parms }, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchCoupons() {
    const data = api
        .get('/coupons', {
            params: {},
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchCouponsByCurrency(currency) {
    const data = api
        .get('/coupons', {
            params: {
                currency,
            },
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function editCoupon(id, parms) {
    const data = api
        .put(`/coupons/${id}`, { ...parms }, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function deleteCoupon(id) {
    const data = api
        .delete(`/coupons/${id}`, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

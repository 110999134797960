import * as Yup from 'yup';

export default {
    pt: {
        schema: Yup.object().shape({
            password: Yup.string()
                .min(8, 'A senha deve ter no mínimo 8 caracteres')
                .max(30, 'A senha deve ter no máximo 30 caracteres')
                .oneOf(
                    [Yup.ref('confirm_password'), null],
                    'As senhas devem ser iguais'
                )
                .required('Campo obrigatório'),
            confirm_password: Yup.string()
                .min(8, 'A senha deve ter no mínimo 8 caracteres')
                .max(30, 'A senha deve ter no máximo 30 caracteres')
                .oneOf(
                    [Yup.ref('password'), null],
                    'As senhas devem ser iguais'
                )
                .required('Campo obrigatório'),
        }),
    },
    en: {
        schema: Yup.object().shape({
            password: Yup.string()
                .min(8, 'Password must be at least 8 characters')
                .max(30, 'Password must be at most 30 characters')
                .oneOf(
                    [Yup.ref('confirm_password'), null],
                    'Passwords must match'
                )
                .required('Required field'),
            confirm_password: Yup.string()
                .min(8, 'Password must be at least 8 characters')
                .max(30, 'Password must be at most 30 characters')
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Required field'),
        }),
    },
};

import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            schema: Yup.object().shape({
                name: Yup.string(),
                price_brl: Yup.number(),
                price_usd: Yup.number(),
                discount: Yup.number(),
                max_patients: Yup.number(),
                interval: Yup.number(),
                interval_type: Yup.string(),
                max_cycles: Yup.number(),
                payable_with: Yup.string(),
                active: Yup.bool(),
                visible: Yup.bool(),
                type: Yup.string(),
                prices: Yup.array().of(
                    Yup.object().shape({
                        up_to: Yup.number().required('Campo obrigatório'),
                        price_brl: Yup.number().required('Campo obrigatório'),
                        price_usd: Yup.number().required('Campo obrigatório'),
                    })
                ),
            }),
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            schema: Yup.object().shape({
                name: Yup.string(),
                price_brl: Yup.number(),
                price_usd: Yup.number(),
                discount: Yup.number(),
                max_patients: Yup.number(),
                interval: Yup.number(),
                interval_type: Yup.string(),
                max_cycles: Yup.number(),
                payable_with: Yup.string(),
                active: Yup.bool(),
                visible: Yup.bool(),
                type: Yup.string(),
                prices: Yup.array().of(
                    Yup.object().shape({
                        up_to: Yup.number().required('Required field'),
                        price_brl: Yup.number().required('Required field'),
                        price_usd: Yup.number().required('Required field'),
                    })
                ),
            }),
        },
    },
};

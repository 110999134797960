import React, { useContext, useEffect } from 'react';
import { ShepherdTourContext } from 'react-shepherd';
import PropTypes from 'prop-types';

import Table from '../../../../components/table/table';
import Td from '../../../../components/table/td';
import { localeDateFormatter } from '../../../../helpers/formatters';

UserTable.propTypes = {
    user: PropTypes.object.isRequired,
    textData: PropTypes.object.isRequired,
    locale: PropTypes.object.isRequired,
    currentWeek: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    steps: PropTypes.array.isRequired,
};

export function UserTable({
    user,
    textData,
    locale,
    currentWeek,
    currentUser,
    steps,
}) {
    const tour = useContext(ShepherdTourContext);

    const { weekNumber, weekReference } = currentWeek;

    useEffect(() => {
        (function () {
            if (currentUser.permissions === 'manager') {
                if (currentWeek?.weekNumber !== null) {
                    const showReportTour = JSON.parse(
                        localStorage.getItem('showReportTour')
                    );

                    if (showReportTour) {
                        tour.start();
                    }
                }
            }
        })();
    }, [steps]);

    return (
        <Table>
            <thead>
                <tr>
                    <th>{textData.nameText}</th>
                    <th>{textData.trialStartText}</th>
                    <th>{textData.currentWeekText}</th>
                    <th>{textData.weekText}</th>
                    <th>{textData.secondaryIdText}</th>
                    <th>{textData.emailText}</th>
                    <th>{textData.activeText}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <Td
                        title={textData.nameText}
                        data-cy="user_report_user_table_name"
                    >
                        {user.name}
                    </Td>
                    <Td
                        title={textData.trialStartText}
                        data-cy="user_report_user_table_trial_start"
                    >
                        {user.trials_configurations.trialStart
                            ? localeDateFormatter(
                                  user.trials_configurations.trialStart,
                                  locale
                              )
                                ? localeDateFormatter(
                                      user.trials_configurations.trialStart,
                                      locale
                                  )
                                : textData.noDataText
                            : textData.noDataText}
                    </Td>
                    <Td
                        title={textData.currentWeekText}
                        data-cy="user_report_user_table_current_week"
                    >
                        {weekNumber ?? textData.noDataText}
                    </Td>
                    <Td
                        title={textData.weekText}
                        data-cy="user_report_user_table_week_reference"
                    >
                        {weekReference?.start && weekReference?.end
                            ? `${
                                  localeDateFormatter(
                                      weekReference.start,
                                      locale
                                  ) ?? '--'
                              } - ${
                                  localeDateFormatter(
                                      weekReference.end,
                                      locale
                                  ) ?? '--'
                              }`
                            : textData.noDataText}
                    </Td>
                    <Td
                        title={textData.secondaryIdText}
                        data-cy="user_report_user_table_secondary_id"
                    >
                        {user.secondary_id}
                    </Td>
                    <Td
                        title={textData.emailText}
                        data-cy="user_report_user_table_email"
                    >
                        {user.email}
                    </Td>
                    <Td
                        title={textData.activeText}
                        className={`${user.active ? 'textGreen' : 'textRed'}`}
                        data-cy="user_report_user_table_active"
                    >
                        {user.active ? textData.yesText : textData.noText}
                    </Td>
                </tr>
            </tbody>
        </Table>
    );
}

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Td from '../../../components/table/td';
import ActionsContainer from '../../../components/table/actionsContainer';

function TableTds({ tds, company, actions, shareText, dosentShareText }) {
    return tds.map((td, index) => {
        switch (td.property) {
            case 'share':
                return (
                    <Td key={index} title={td.th}>
                        {company.share ? shareText : dosentShareText}
                    </Td>
                );
            case 'actions':
                return (
                    <Td key={index} title={td.th}>
                        <ActionsContainer actions={actions} entity={company} />
                    </Td>
                );
            case 'saveEmail':
                return (
                    <Td key={index} title={td.th}>
                        {company.descriptor.saveEmail
                            ? shareText
                            : dosentShareText}
                    </Td>
                );
            default:
                if (td.hasLink)
                    return (
                        <Td key={index} title={td.th}>
                            <Link
                                to={{
                                    pathname: td.to,
                                    state: { company: company },
                                }}
                            >
                                {company[td.property]}
                            </Link>
                        </Td>
                    );
                return (
                    <Td key={index} title={td.th}>
                        {company[td.property]}
                    </Td>
                );
        }
    });
}

TableTds.propTypes = {
    tds: PropTypes.array.isRequired,
    company: PropTypes.object.isRequired,
    actions: PropTypes.array,
    shareText: PropTypes.string.isRequired,
    dosentShareText: PropTypes.string.isRequired,
};

export default TableTds;

import Input from '../../../components/inputForm/inputText';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'serial_no',
                        type: 'text',
                        label: 'Número de série',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'brand',
                        type: 'text',
                        label: 'Marca',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'model',
                        type: 'text',
                        label: 'Modelo',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'company_id',
                        type: 'text',
                        label: 'Empresa',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'purchased_at',
                        type: 'date',
                        label: 'Data de compra',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
            ],
            successText: 'Dispositivo excluído com sucesso!',
            deleteText: 'Excluir',
            cancelText: 'Cancelar',
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'serial_no',
                        type: 'text',
                        label: 'Serial number',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'brand',
                        type: 'text',
                        label: 'Brand',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'model',
                        type: 'text',
                        label: 'Model',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'company_id',
                        type: 'text',
                        label: 'Company',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Input,
                    props: {
                        name: 'purchased_at',
                        type: 'date',
                        label: 'Purchase date',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-5',
                },
            ],
            successText: 'Device successfully deleted!',
            deleteText: 'Delete',
            cancelText: 'Cancel',
        },
    },
};

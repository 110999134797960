import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
    filledDaysPercentageType,
    trialStartType,
    currentWeekType,
} from '../../../helpers/handleType';
import Td from '../../../components/table/td';
import RenderDays from './renderDays';
import { getFilledDaysPercentage } from '../../../helpers/customerDashboard';

function TableTds({ tds, sub, lastNDays, locale }) {
    const percentage = getFilledDaysPercentage(sub);

    const { filledDaysPercentage, className } = filledDaysPercentageType(
        percentage,
        tds[3]['noDataText']
    );

    return tds.map((td, index) => {
        switch (td.property) {
            case 'trialStart':
                return (
                    <Td key={index} title={td.th}>
                        {trialStartType(
                            sub.trials_configurations.trialStart,
                            locale,
                            td.noDataText
                        )}
                    </Td>
                );
            case 'currentWeek':
                return (
                    <Td key={index} title={td.th}>
                        {currentWeekType(sub.current_week, td.noDataText)}
                    </Td>
                );
            case 'filledDaysPercentage':
                return (
                    <Td key={index} title={td.th} className={className}>
                        {filledDaysPercentage}
                    </Td>
                );
            case 'filling':
                return (
                    <Td key={index} title={td.th}>
                        <RenderDays
                            lastNDays={lastNDays}
                            sub={sub}
                            locale={locale}
                        />
                    </Td>
                );
            case 'device':
                return (
                    <Td key={index} title={td.th}>
                        <div className="devicesContainer">
                            {sub['devices'].length > 0 ? (
                                sub['devices'].map((device) => (
                                    <p key={device['serial_no']}>
                                        {device['serial_no']}
                                    </p>
                                ))
                            ) : (
                                <p>--</p>
                            )}
                        </div>
                    </Td>
                );
            default:
                if (td.hasLink)
                    return (
                        <Td key={index} title={td.th}>
                            <Link
                                to={{
                                    pathname: td.to,
                                    state: { entity: sub },
                                }}
                            >
                                {sub[td.property]}
                            </Link>
                        </Td>
                    );
                return (
                    <Td key={index} title={td.th}>
                        {sub[td.property]}
                    </Td>
                );
        }
    });
}

TableTds.propTypes = {
    tds: PropTypes.array.isRequired,
    sub: PropTypes.object.isRequired,
    lastNDays: PropTypes.object.isRequired,
    locale: PropTypes.object.isRequired,
};

export default TableTds;

export default {
    pt: {
        pageTitle: 'Diário de Sono',
        pageSubtitle: 'Login',
        loginLabel: 'ID da Empresa',
        loginLabel2: 'ID do Usuário',
        passwordLabel: 'Senha',
        forgotPasswordText: 'Esqueceu sua senha? ',
        forgotPasswordText2: 'Clique aqui para redefiní-la.',
        loginButtonText: 'Entrar',
        loginButtonLoadingText: 'Aguarde...',
        selectLanguageProps: {
            name: 'lang',
            label: 'Idioma',
            options: [
                { value: 'pt', label: 'Português' },
                { value: 'en', label: 'English' },
            ],
        },
    },
    en: {
        pageTitle: 'Sleep Diary',
        pageSubtitle: 'Login',
        loginLabel: 'Company ID',
        loginLabel2: 'User ID',
        passwordLabel: 'Password',
        forgotPasswordText: 'Forgot your password? ',
        forgotPasswordText2: 'Click here to reset it.',
        loginButtonText: 'Login',
        loginButtonLoadingText: 'Loading...',
        selectLanguageProps: {
            name: 'lang',
            label: 'Language',
            options: [
                { value: 'pt', label: 'Português' },
                { value: 'en', label: 'English' },
            ],
        },
    },
};

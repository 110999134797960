export default {
    pt: {
        firstText: 'Primeira',
        previousText: 'Anterior',
        nextText: 'Próxima',
        lastText: 'Última',
    },
    en: {
        firstText: 'First',
        previousText: 'Previous',
        nextText: 'Next',
        lastText: 'Last',
    },
};

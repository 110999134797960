import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import { Button, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';

import Input from '../../../../components/inputForm/inputText';
import { localeDateFormatter } from '../../../../helpers/formatters';

MyForm.propTypes = {
    formRef: PropTypes.object.isRequired,
    pageData: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    diary: PropTypes.object,
    devicesFields: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export function MyForm({
    formRef,
    pageData,
    user,
    diary,
    devicesFields,
    handleSubmit,
}) {
    const { dateLocale } = useSelector((state) => state.locale);

    useEffect(() => {
        formRef.current.reset();

        const userTypes = {
            1: pageData.patientText,
            2: pageData.managerText,
            3: pageData.condorAdminText,
            4: pageData.managerAdminText,
        };

        formRef.current.setData({
            name: user.name,
            secondary_id: user.secondary_id,
            email: user.email,
            role_id: userTypes[user.role_id],
            active: user.active ? pageData.yesText : pageData.noText,
            trialStart: localeDateFormatter(
                user.trials_configurations.trialStart,
                dateLocale
            ),
            trialEnd: localeDateFormatter(
                user.trials_configurations.trialEnd,
                dateLocale
            ),
            diary_id: diary?.name,
            devices: user?.devices?.map((deviceInfo) => ({
                device_id: deviceInfo.device.serial_no,
                body_part: pageData.bodyPartTexts[deviceInfo.body_part],
                received_at: localeDateFormatter(
                    deviceInfo.received_at,
                    dateLocale
                ),
            })),
        });
    }, []);

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-5">
                    <Input
                        name="name"
                        type="text"
                        label={pageData.nameText}
                        data-cy="delete_user_name_input"
                        readOnly
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-3">
                    <Input
                        name="secondary_id"
                        type="text"
                        label={pageData.secondaryIdText}
                        data-cy="delete_user_secondary_id_input"
                        readOnly
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-3">
                    <Input
                        name="email"
                        type="text"
                        label={pageData.emailText}
                        data-cy="delete_user_email_input"
                        readOnly
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-3">
                    <Input
                        name="role_id"
                        type="text"
                        label={pageData.userTypeText}
                        data-cy="delete_user_role_id_input"
                        readOnly
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-3">
                    <Input
                        name="active"
                        type="text"
                        label={pageData.activeText}
                        data-cy="delete_user_active_input"
                        readOnly
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-3">
                    <Input
                        name="trialStart"
                        type="text"
                        label={pageData.trialStartText}
                        data-cy="delete_user_trialStart_input"
                        readOnly
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-3">
                    <Input
                        name="trialEnd"
                        type="text"
                        label={pageData.trialEndText}
                        data-cy="delete_user_trialEnd_input"
                        readOnly
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-3">
                    <Input
                        name="diary_id"
                        type="text"
                        label={pageData.diaryText}
                        data-cy="delete_user_diary_id_input"
                        readOnly
                    />
                </FormGroup>
            </FormGroup>

            {user?.devices?.length > 0 && (
                <>
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-12">
                            <span>{pageData.devicesText}</span>
                        </FormGroup>
                    </FormGroup>

                    {devicesFields.map((deviceFields, index) => (
                        <React.Fragment key={`${deviceFields[0].key}`}>
                            <FormGroup row>
                                <Scope path={`devices[${index}]`}>
                                    {deviceFields.map((field) => (
                                        <FormGroup
                                            key={`${field.props.name}${field.key}`}
                                            className={field.className}
                                        >
                                            <field.component {...field.props} />
                                        </FormGroup>
                                    ))}
                                </Scope>
                            </FormGroup>

                            <hr />
                        </React.Fragment>
                    ))}
                </>
            )}

            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button
                        color="primary"
                        className="mr-2"
                        type="submit"
                        data-cy="delete_user_delete_button"
                    >
                        {pageData.deleteText}
                    </Button>

                    <Link
                        to={'/users'}
                        className="btn btn-secondary"
                        data-cy="delete_user_cancel_link"
                    >
                        {pageData.cancelText}
                    </Link>
                </div>
            </FormGroup>
        </Form>
    );
}

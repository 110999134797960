import { LANG } from '../constants';

export function setLang(data) {
    return {
        type: LANG.CHANGE_LANG_REQUEST,
        payload: data,
    };
}

export function changeLang(lang) {
    return {
        type: LANG.CHANGE_LANG,
        payload: { lang },
    };
}

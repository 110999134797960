export default {
    pt: {
        customer_admin: {
            noPatientsText: 'Você não possui pacientes cadastrados!',
            activePatientsCardTitle: 'Pacientes ativos',
            activePatientsToBeAddedCardTitle:
                'Pacientes ativos disponíveis para serem adicionados',
            activeDevicesText: 'Dispositivos ativos',
            remainingDevicesQuotaText: 'Dispositivos ativos restantes',
            tableTitle: 'Acompanhamento',
            ths: [
                'Paciente',
                'Início',
                'Semana Atual',
                'Porcentagem de dias preenchidos',
                'Preenchimento dos últimos 10 dias',
                'Dispositivos',
            ],
            tds: [
                {
                    th: 'Paciente',
                    property: 'name',
                    hasLink: true,
                    to: 'users/report/',
                },
                {
                    th: 'Início',
                    property: 'trialStart',
                    hasLink: false,
                    to: '',
                    noDataText: 'Não há dados preenchidos',
                },
                {
                    th: 'Semana Atual',
                    property: 'currentWeek',
                    hasLink: false,
                    to: '',
                    noDataText: 'Não há dados preenchidos',
                },
                {
                    th: 'Porcentagem de dias preenchidos',
                    property: 'filledDaysPercentage',
                    hasLink: false,
                    to: '',
                    noDataText: 'Não há dados preenchidos',
                },
                {
                    th: 'Preenchimento dos últimos 10 dias',
                    property: 'filling',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Dispositivos',
                    property: 'device',
                    hasLink: false,
                    to: '',
                    noDataText: '--',
                },
            ],
        },
        manager: {
            noPatientsText: 'Você não possui pacientes cadastrados!',
            activePatientsCardTitle: 'Pacientes ativos',
            activePatientsToBeAddedCardTitle:
                'Pacientes ativos disponíveis para serem adicionados',
            activeDevicesText: 'Dispositivos ativos',
            remainingDevicesQuotaText: 'Dispositivos ativos restantes',
            tableTitle: 'Acompanhamento',
            ths: [
                'Paciente',
                'Início',
                'Semana Atual',
                'Porcentagem de dias preenchidos',
                'Preenchimento dos últimos 10 dias',
                'Dispositivos',
            ],
            tds: [
                {
                    th: 'Paciente',
                    property: 'name',
                    hasLink: true,
                    to: 'users/report/',
                },
                {
                    th: 'Início',
                    property: 'trialStart',
                    hasLink: false,
                    to: '',
                    noDataText: 'Não há dados preenchidos',
                },
                {
                    th: 'Semana Atual',
                    property: 'currentWeek',
                    hasLink: false,
                    to: '',
                    noDataText: 'Não há dados preenchidos',
                },
                {
                    th: 'Porcentagem de dias preenchidos',
                    property: 'filledDaysPercentage',
                    hasLink: false,
                    to: '',
                    noDataText: 'Não há dados preenchidos',
                },
                {
                    th: 'Preenchimento dos últimos 10 dias',
                    property: 'filling',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Dispositivos',
                    property: 'device',
                    hasLink: false,
                    to: '',
                    noDataText: '--',
                },
            ],
            next: 'Próximo',
            back: 'Voltar',
        },
        condor_admin: {
            noCustomersText: 'Você não possui clientes cadastrados!',
            activePatientsText: 'Pacientes ativos',
            customersText: 'Gestores cadastrados',
            activeCustomersText: 'Gestores ativos',
            companiesByPlanText: 'Empresas por plano',
            usageReportText: 'Relatório de uso',
            showText: 'Mostrar',
            showingText: 'Mostrando',
            toText: 'a',
            ofText: 'de',
            entriesText: 'entradas',
            yesText: 'Sim',
            noText: 'Não',
            dayText: 'Dia',
            weekText: 'Semana',
            monthText: 'Mês',
            newPatientsText: 'Novos pacientes',
            newCustomersText: 'Novos gestores',
            visitsText: 'Visitas',
            dataInputText: 'Entrada de dados',
            newSubscriptionsText: 'Novas assinaturas',
            averageNewPatientsText: 'Média de novos pacientes',
            averageNewCustomersText: 'Média de novos gestores',
            averageNewVisitsText: 'Média de visitas',
            averageNewDataInputText: 'Média de entrada de dados',
            averageNewSubscriptionsText: 'Média de novas assinaturas',
            byDayText: 'Por dia',
            byWeekText: 'Por semana',
            byMonthText: 'Por mês',
            activeDevicesText: 'Dispositivos ativos',
            wrongFormatText: 'Formato incorreto',
            ths: [
                {
                    name: 'Empresa',
                    property: 'company_name',
                },
                {
                    name: 'Plano',
                    property: 'plan_name',
                },
                {
                    name: 'Pacientes ativos',
                    property: 'active_patients',
                },
                {
                    name: 'Dispositivos ativos',
                    property: 'active_devices',
                },
                {
                    name: 'Assinatura ativa',
                    property: 'formattedSubIsActive',
                },
                {
                    name: 'Assinatura suspensa',
                    property: 'formattedSubIsSuspended',
                },
                {
                    name: 'Data de cobrança',
                    property: 'formattedBillingDate',
                },
                {
                    name: 'Data de expiração',
                    property: 'formattedExpiresAt',
                },
                {
                    name: 'Última visita',
                    property: 'formattedLastVisit',
                },
                {
                    name: 'Último dado inserido',
                    property: 'formattedLastDataInput',
                },
            ],
            filterThs: [
                {
                    name: 'Empresa',
                    property: 'company_name',
                },
                {
                    name: 'Plano',
                    property: 'plan_name',
                },
                {
                    name: 'Pacientes ativos',
                    property: 'active_patients',
                },
                {
                    name: 'Dispositivos ativos',
                    property: 'active_devices',
                },
                {
                    name: 'Assinatura ativa',
                    property: 'formattedSubIsActive',
                },
                {
                    name: 'Assinatura suspensa',
                    property: 'formattedSubIsSuspended',
                },
                {
                    name: 'Data de cobrança',
                    property: 'formattedBillingDate',
                },
                {
                    name: 'Data de expiração',
                    property: 'formattedExpiresAt',
                },
                {
                    name: 'Última visita',
                    property: 'formattedLastVisit',
                },
                {
                    name: 'Último dado inserido',
                    property: 'formattedLastDataInput',
                },
            ],
            tds: {
                company_name: 'Empresa',
                plan_name: 'Plano',
                active_patients: 'Pacientes ativos',
                active_devices: 'Dispositivos ativos',
                formattedSubIsActive: 'Assinatura ativa',
                formattedSubIsSuspended: 'Assinatura suspensa',
                formattedBillingDate: 'Data de cobrança',
                formattedExpiresAt: 'Data de expiração',
                formattedLastVisit: 'Última visita',
                formattedLastDataInput: 'Último dado inserido',
            },
        },
    },
    en: {
        customer_admin: {
            noPatientsText: `You don't have registered patients!`,
            activePatientsCardTitle: 'Active patients',
            activePatientsToBeAddedCardTitle: 'Remaining active patients quota',
            activeDevicesText: 'Active devices',
            remainingDevicesQuotaText: 'Remaining active devices quota',
            tableTitle: 'Follow-up',
            ths: [
                'Patient',
                'Start',
                'Current Week',
                'Percentage of days filled',
                'Previous 10 days',
                'Devices',
            ],
            tds: [
                {
                    th: 'Patient',
                    property: 'name',
                    hasLink: true,
                    to: 'users/report/',
                },
                {
                    th: 'Start',
                    property: 'trialStart',
                    hasLink: false,
                    to: '',
                    noDataText: 'No data filled',
                },
                {
                    th: 'Current Week',
                    property: 'currentWeek',
                    hasLink: false,
                    to: '',
                    noDataText: 'No data filled',
                },
                {
                    th: 'Percentage of days filled',
                    property: 'filledDaysPercentage',
                    hasLink: false,
                    to: '',
                    noDataText: 'No data filled',
                },
                {
                    th: 'Previous 10 days',
                    property: 'filling',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Devices',
                    property: 'device',
                    hasLink: false,
                    to: '',
                    noDataText: '--',
                },
            ],
        },
        manager: {
            noPatientsText: `You don't have registered patients!`,
            activePatientsCardTitle: 'Active patients',
            activePatientsToBeAddedCardTitle: 'Remaining active patients quota',
            activeDevicesText: 'Active devices',
            remainingDevicesQuotaText: 'Remaining active devices quota',
            tableTitle: 'Follow-up',
            ths: [
                'Patient',
                'Start',
                'Current Week',
                'Percentage of days filled',
                'Previous 10 days',
                'Devices',
            ],
            tds: [
                {
                    th: 'Patient',
                    property: 'name',
                    hasLink: true,
                    to: 'users/report/',
                },
                {
                    th: 'Start',
                    property: 'trialStart',
                    hasLink: false,
                    to: '',
                    noDataText: 'No data filled',
                },
                {
                    th: 'Current Week',
                    property: 'currentWeek',
                    hasLink: false,
                    to: '',
                    noDataText: 'No data filled',
                },
                {
                    th: 'Percentage of days filled',
                    property: 'filledDaysPercentage',
                    hasLink: false,
                    to: '',
                    noDataText: 'No data filled',
                },
                {
                    th: 'Previous 10 days',
                    property: 'filling',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Devices',
                    property: 'device',
                    hasLink: false,
                    to: '',
                    noDataText: '--',
                },
            ],
            next: 'Next',
            back: 'Back',
        },
        condor_admin: {
            noCustomersText: `You don't have registered customers!`,
            activePatientsText: 'Active patients',
            customersText: 'Registered managers',
            activeCustomersText: 'Active managers',
            companiesByPlanText: 'Companies by plan',
            usageReportText: 'Usage report',
            showText: 'Show',
            showingText: 'Showing',
            toText: 'to',
            ofText: 'of',
            entriesText: 'entries',
            yesText: 'Yes',
            noText: 'No',
            dayText: 'Day',
            weekText: 'Week',
            monthText: 'Month',
            newPatientsText: 'New patients',
            newCustomersText: 'New customers',
            visitsText: 'Visits',
            dataInputText: 'Data input',
            newSubscriptionsText: 'New subscriptions',
            averageNewPatientsText: 'Average of new patients',
            averageNewCustomersText: 'Average of new customers',
            averageNewDataInputText: 'Average data input',
            averageNewVisitsText: 'Average of visits',
            averageNewSubscriptionsText: 'Average of new subscriptions',
            byDayText: 'By day',
            byWeekText: 'By week',
            byMonthText: 'By month',
            activeDevicesText: 'Active devices',
            wrongFormatText: 'Wrong format',
            ths: [
                {
                    name: 'Company',
                    property: 'company_name',
                },
                {
                    name: 'Plan',
                    property: 'plan_name',
                },
                {
                    name: 'Active patients',
                    property: 'active_patients',
                },
                {
                    name: 'Active devices',
                    property: 'active_devices',
                },
                {
                    name: 'Active subscription',
                    property: 'formattedSubIsActive',
                },
                {
                    name: 'Suspended subscription',
                    property: 'formattedSubIsSuspended',
                },
                {
                    name: 'Billing date',
                    property: 'formattedBillingDate',
                },
                {
                    name: 'Expiration date',
                    property: 'formattedExpiresAt',
                },
                {
                    name: 'Last visit',
                    property: 'formattedLastVisit',
                },
                {
                    name: 'Last data input',
                    property: 'formattedLastDataInput',
                },
            ],
            filterThs: [
                {
                    name: 'Company',
                    property: 'company_name',
                },
                {
                    name: 'Plan',
                    property: 'plan_name',
                },
                {
                    name: 'Active patients',
                    property: 'active_patients',
                },
                {
                    name: 'Active devices',
                    property: 'active_devices',
                },
                {
                    name: 'Active subscription',
                    property: 'formattedSubIsActive',
                },
                {
                    name: 'Suspended subscription',
                    property: 'formattedSubIsSuspended',
                },
                {
                    name: 'Billing date',
                    property: 'formattedBillingDate',
                },
                {
                    name: 'Expiration date',
                    property: 'formattedExpiresAt',
                },
                {
                    name: 'Last visit',
                    property: 'formattedLastVisit',
                },
                {
                    name: 'Last data input',
                    property: 'formattedLastDataInput',
                },
            ],
            tds: {
                company_name: 'Company',
                plan_name: 'Plan',
                active_patients: 'Active patients',
                active_devices: 'Active devices',
                formattedSubIsActive: 'Active subscription',
                formattedSubIsSuspended: 'Suspended subscription',
                formattedBillingDate: 'Billing date',
                formattedExpiresAt: 'Expiration date',
                formattedLastVisit: 'Last visit',
                formattedLastDataInput: 'Last data input',
            },
        },
    },
};

import api from '../../services/api';

export function editDataInput(user_id, date, parms) {
    const data = api
        .put(
            `/data_input/edit/${user_id}/${date}`,
            { ...parms },
            { withCredentials: true }
        )
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

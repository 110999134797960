import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AccordionContext } from '../../contexts/accordionContext';

export function AccordionHeader({
    className,
    innerRef,
    children,
    targetId,
    ...attributes
}) {
    const { open, toggle } = useContext(AccordionContext);

    return (
        <h2
            {...attributes}
            className={`${className} accordion-header`}
            ref={innerRef}
        >
            <button
                type="button"
                className={`${
                    !(Array.isArray(open)
                        ? open.includes(targetId)
                        : open === targetId)
                        ? 'collapsed'
                        : ''
                } accordion-button`}
                onClick={() => toggle(open === targetId ? [] : targetId)}
            >
                {children}
            </button>
        </h2>
    );
}

AccordionHeader.propTypes = {
    className: PropTypes.string,
    innerRef: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.func,
    ]),
    children: PropTypes.node,
    targetId: PropTypes.string.isRequired,
};

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, Button, FormGroup } from 'reactstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { setLoading } from '../../../store/actions/loadingActions';
import history from '../../../services/history';
import { editUser } from '../../../services/api/users';
import { validateForm } from '../../../helpers/formValidator';
import Input from '../../../components/inputForm/inputText';
import Select from '../../../components/inputForm/inputSelect';

EditManager.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function EditManager({ currentUser }) {
    const formRef = useRef(null);

    const [manager, setManager] = useState({});

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];

    const { schema } = schemaConfig[lang][currentUser.permissions];

    async function handleSubmit(data) {
        const { parsedData, isValid, errors } = await validateForm(
            data,
            schema
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(setLoading(true));

            const response = await editUser(manager.id, parsedData);

            dispatch(setLoading(false));

            if (response) {
                toast.success(pageData.successText);

                history.push('/managers');
            }
        }
    }

    useEffect(() => {
        if (history.location.state?.user) {
            let tempUser = history.location.state.user;

            const initialData = {
                name: tempUser.name,
                secondary_id: tempUser.secondary_id,
                email: tempUser.email,
                active: tempUser.active,
                share: tempUser.share,
            };

            formRef.current.setData(initialData);

            formRef.current.setFieldValue('active', {
                label: tempUser.active ? pageData.yesText : pageData.noText,
                value: tempUser.active,
            });

            if (tempUser?.share !== undefined) {
                formRef.current.setFieldValue('share', {
                    label: tempUser.share ? pageData.yesText : pageData.noText,
                    value: tempUser.share,
                });
            }

            setManager(tempUser);
        } else {
            history.push('/managers');
        }
    }, []);

    useEffect(() => {
        if (
            formRef.current.getFieldValue('share') !== undefined &&
            formRef.current.getFieldValue('share') !== ''
        ) {
            formRef.current.setFieldValue('share', {
                label: formRef.current.getFieldValue('share')
                    ? pageData.yesText
                    : pageData.noText,
                value: formRef.current.getFieldValue('share'),
            });
        }

        if (
            formRef.current.getFieldValue('active') !== undefined &&
            formRef.current.getFieldValue('active') !== ''
        ) {
            formRef.current.setFieldValue('active', {
                label: formRef.current.getFieldValue('active')
                    ? pageData.yesText
                    : pageData.noText,
                value: formRef.current.getFieldValue('active'),
            });
        }
    }, [lang]);

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-5">
                            <Input
                                name="name"
                                type="text"
                                label={pageData.nameText}
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="secondary_id"
                                type="text"
                                label={pageData.secondaryIdText}
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="email"
                                type="text"
                                label={pageData.emailText}
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-3">
                            <Select
                                name="active"
                                label={pageData.activeText}
                                options={[
                                    { value: false, label: pageData.noText },
                                    { value: true, label: pageData.yesText },
                                ]}
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-3">
                            <Select
                                name="share"
                                label={pageData.sharePatientsText}
                                options={[
                                    { value: false, label: pageData.noText },
                                    { value: true, label: pageData.yesText },
                                ]}
                            />
                        </FormGroup>
                    </FormGroup>

                    <FormGroup row>
                        <div className="col-xs-12 col-md-12 text-left">
                            <Button
                                color="primary"
                                type="submit"
                                className="mr-2"
                            >
                                {pageData.saveText}
                            </Button>
                            <Link to="/managers" className="btn btn-secondary">
                                {pageData.cancelText}
                            </Link>
                        </div>
                    </FormGroup>
                </Form>
            </CardBody>
        </Card>
    );
}

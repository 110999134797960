import { LOADING } from '../constants';

export function setLoading(data) {
    return {
        type: LOADING.LOADING_REQUEST,
        payload: data,
    };
}

export function showLoading() {
    return {
        type: LOADING.SHOW_LOADING,
    };
}

export function hideLoading() {
    return {
        type: LOADING.HIDE_LOADING,
    };
}

export function resetLoadingState() {
    return {
        type: LOADING.RESET_STATE,
    };
}

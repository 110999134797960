import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Badge, Alert, Button, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

import pageConfig from './page.config';
import { setLoading } from '../../store/actions/loadingActions';
import { fetchDiaries, duplicateDiary } from '../../services/api/diaries';
import { localeDateFormatter } from '../../helpers/formatters';
import { useOrderByProperty } from '../../hooks/useOrderByProperty';
import { Search } from '../../components/search';
import Table from '../../components/table/table';
import { ThWithOrdering } from '../../components/table/thWithOrdering';
import Td from '../../components/table/td';
import ActionsContainer from '../../components/table/actionsContainer';

export default function Diaries({ currentUser }) {
    const [diaries, setDiaries] = useState([]);
    const { activeFilter, filteredData, setFilteredData, orderByProperty } =
        useOrderByProperty(diaries, 'id', 'asc');
    const [duplicating, setDuplicating] = useState(false);
    const [currentDiaryId, setCurrentDiaryId] = useState(null);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);
    const { dateLocale } = useSelector((state) => state.locale);
    const isLoading = useSelector((state) => state.loading.isLoading);

    const pageData = pageConfig[lang][currentUser.permissions];

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            let data = await fetchDiaries();

            if (data) {
                if (data?.diaries.length === 0) {
                    toast.info(pageData.noDiariesText);
                }

                let formattedDiaries = data?.diaries.map((diary) => {
                    return {
                        id: diary.id,
                        company_id: diary.company_id,
                        user_id: diary.user_id,
                        diary_code: diary.diary_code,
                        name: diary.name,
                        descriptor: diary.descriptor,
                        created_at: localeDateFormatter(
                            diary.created_at,
                            dateLocale
                        )
                            ? localeDateFormatter(diary.created_at, dateLocale)
                            : '--',
                        updated_at: localeDateFormatter(
                            diary.updated_at,
                            dateLocale
                        )
                            ? localeDateFormatter(diary.updated_at, dateLocale)
                            : '--',
                    };
                });

                setDiaries(formattedDiaries);
            }

            dispatch(setLoading(false));
        })();
    }, []);

    async function handleClickDuplicateDiary(diaryId) {
        setCurrentDiaryId(diaryId);

        setDuplicating(true);

        const response = await duplicateDiary(diaryId);

        if (response) {
            toast.success(pageData.duplicateDiarySuccessText);

            setDiaries((prevState) => {
                const newState = [...prevState];

                newState.push({
                    id: response['diary'].id,
                    company_id: response['diary'].company_id,
                    user_id: response['diary'].user_id,
                    diary_code: response['diary'].diary_code,
                    name: response['diary'].name,
                    descriptor: response['diary'].descriptor,
                    created_at: localeDateFormatter(
                        response['diary'].created_at,
                        dateLocale
                    )
                        ? localeDateFormatter(
                              response['diary'].created_at,
                              dateLocale
                          )
                        : '--',
                    updated_at: localeDateFormatter(
                        response['diary'].updated_at,
                        dateLocale
                    )
                        ? localeDateFormatter(
                              response['diary'].updated_at,
                              dateLocale
                          )
                        : '--',
                });

                return newState;
            });

            setFilteredData((prevState) => {
                const newState = [...prevState];

                newState.push({
                    id: response['diary'].id,
                    company_id: response['diary'].company_id,
                    user_id: response['diary'].user_id,
                    diary_code: response['diary'].diary_code,
                    name: response['diary'].name,
                    descriptor: response['diary'].descriptor,
                    created_at: localeDateFormatter(
                        response['diary'].created_at,
                        dateLocale
                    )
                        ? localeDateFormatter(
                              response['diary'].created_at,
                              dateLocale
                          )
                        : '--',
                    updated_at: localeDateFormatter(
                        response['diary'].updated_at,
                        dateLocale
                    )
                        ? localeDateFormatter(
                              response['diary'].updated_at,
                              dateLocale
                          )
                        : '--',
                });

                return newState;
            });
        }

        setDuplicating(false);

        setCurrentDiaryId(diaryId);
    }

    if (isLoading || pageData === null) {
        return <></>;
    }

    return (
        <>
            <Row>
                <Col xs="12">
                    <Alert color="info" className={styles.alertPadding}>
                        <span className={styles.betaTesting}>
                            <Badge pill tag="p" color="warning">
                                BETA
                            </Badge>{' '}
                            {pageData.testingPeriodText}
                        </span>
                    </Alert>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <div
                        className={`
                        ${styles.searchAndAddContainer}
                        ${duplicating ? styles.disabled : ''}
                    `}
                    >
                        <Search
                            searchByProperty={'name'}
                            searchByText={pageData.searchByNameText}
                            entities={diaries}
                            setFilteredEntities={setFilteredData}
                        />
                        <Link
                            to={{
                                pathname: 'custom-diaries/add',
                            }}
                            className="btn btn-primary ml-2"
                            title={pageData?.addText}
                        >
                            {pageData?.addText}
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Table className={styles.diariesTable}>
                        <thead>
                            <tr>
                                {pageData.ths.map((th) => {
                                    switch (th.property) {
                                        case 'id':
                                        case 'name': {
                                            return (
                                                <ThWithOrdering
                                                    key={th.property}
                                                    property={th.property}
                                                    activeFilterProperty={
                                                        activeFilter.property
                                                    }
                                                    activeOrder={
                                                        activeFilter.order
                                                    }
                                                    orderByProperty={
                                                        orderByProperty
                                                    }
                                                    activeClassName={
                                                        styles.active
                                                    }
                                                    clickableIconClassName={
                                                        styles.clickableIcon
                                                    }
                                                >
                                                    {th.name}
                                                </ThWithOrdering>
                                            );
                                        }
                                        default: {
                                            return (
                                                <th key={th.property}>
                                                    {th.name}
                                                </th>
                                            );
                                        }
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((diary) => (
                                <tr key={diary.id}>
                                    {pageData.tds.map((td, index) => {
                                        switch (td.property) {
                                            case 'actions':
                                                return (
                                                    <Td
                                                        key={index}
                                                        title={td.th}
                                                        className={`
                                                            ${
                                                                duplicating
                                                                    ? styles.disabled
                                                                    : ''
                                                            }
                                                        `}
                                                    >
                                                        {duplicating &&
                                                            diary.id ===
                                                                currentDiaryId && (
                                                                <Spinner size="sm" />
                                                            )}
                                                        <Button
                                                            type="button"
                                                            color="primary"
                                                            title={
                                                                pageData.duplicateDiaryText
                                                            }
                                                            onClick={() =>
                                                                handleClickDuplicateDiary(
                                                                    diary.id
                                                                )
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faCopy}
                                                            />
                                                        </Button>
                                                        <ActionsContainer
                                                            actions={
                                                                pageData.actions
                                                            }
                                                            entity={diary}
                                                        />
                                                    </Td>
                                                );
                                            default:
                                                return (
                                                    <Td
                                                        key={index}
                                                        title={td.th}
                                                    >
                                                        {diary[td.property]}
                                                    </Td>
                                                );
                                        }
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );
}

Diaries.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

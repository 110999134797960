import React from 'react';
import { Scope } from '@unform/core';
import { FormGroup, Button } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';
import PropTypes from 'prop-types';

export function DeviceFormFields({
    pageData,
    devicesFields,
    handleAddClick,
    handleRemoveClick,
}) {
    return (
        <>
            <hr />
            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-12">
                    <span data-cy="edit_user_devices_text">
                        {pageData.devicesText}
                    </span>
                </FormGroup>
            </FormGroup>
            {devicesFields.map((deviceFields, index) => (
                <React.Fragment key={`${deviceFields[0].key}`}>
                    <FormGroup row>
                        <Scope path={`devices[${index}]`}>
                            {deviceFields.map((field) => (
                                <FormGroup
                                    key={`${field.props.name}${field.key}`}
                                    className={field.className}
                                >
                                    <field.component
                                        {...field.props}
                                        className={`${field.props.className}_${index}`}
                                        data-cy={`${field.props['data-cy']}_${index}`}
                                    />
                                </FormGroup>
                            ))}
                            <FormGroup>
                                <div className="col-xs-12 col-md-12 text-left d-flex align-items-end h-100">
                                    <Button
                                        color="danger"
                                        type="button"
                                        onClick={() => handleRemoveClick(index)}
                                        title={pageData.deleteDeviceText}
                                        data-cy={`edit_user_delete_device_button_${index}`}
                                    >
                                        <FaTrash />
                                    </Button>
                                </div>
                            </FormGroup>
                        </Scope>
                    </FormGroup>
                    <hr />
                </React.Fragment>
            ))}
            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button
                        color="primary"
                        type="button"
                        onClick={handleAddClick}
                        title={pageData.addDeviceText}
                        data-cy="edit_user_add_device_button"
                    >
                        {pageData.addDeviceText}
                    </Button>
                </div>
            </FormGroup>
            <hr />
        </>
    );
}

DeviceFormFields.propTypes = {
    pageData: PropTypes.object.isRequired,
    devicesFields: PropTypes.array.isRequired,
    handleAddClick: PropTypes.func.isRequired,
    handleRemoveClick: PropTypes.func.isRequired,
};

export function getLumusPriceByFidelity(prices, numberOfDevices, fidelity) {
    let finalPrice = 0;

    for (let price of Object.keys(prices)) {
        let amount = Number(price);

        if (numberOfDevices <= amount) {
            finalPrice = prices[price][fidelity] * numberOfDevices;
            break;
        }
    }

    return finalPrice;
}

export function getLumusPrice(prices, numberOfDevices, currencyFormat) {
    let finalPrice = 0;

    for (let price of prices) {
        if (numberOfDevices <= price.up_to) {
            finalPrice =
                currencyFormat === 'BRL'
                    ? price.price_brl * numberOfDevices
                    : price.price_usd * numberOfDevices;
            break;
        }
    }

    return finalPrice;
}

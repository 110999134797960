export function handleDate(stringDate) {
    if (typeof stringDate !== 'string') {
        return null;
    }

    if (stringDate.length < 10) {
        return null;
    }

    try {
        let date = new Date(stringDate);

        let day = Number(stringDate.slice(8, 10));

        let month = Number(stringDate.slice(5, 7));

        let year = Number(stringDate.slice(0, 4));

        if (day !== date.getDate()) {
            date.setDate(date.getDate() + (day - date.getDate()));
        }

        if (month !== date.getMonth() + 1) {
            date.setMonth(date.getMonth() + (month - 1 - date.getMonth()));
        }

        if (year !== date.getFullYear()) {
            date.setFullYear(date.getFullYear() + (year - date.getFullYear()));
        }

        date.setHours(0, 0, 0, 0);

        return date;
    } catch (e) {
        return null;
    }
}

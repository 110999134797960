import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { Button, Card, CardBody, CardFooter, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { setLoading } from '../../../store/actions/loadingActions';
import { validateForm } from '../../../helpers/formValidator';
import { fetchCompany } from '../../../services/api/account';
import { addManager } from '../../../services/api/users';
import history from '../../../services/history';
import Input from '../../../components/inputForm/inputText';
import Select from '../../../components/inputForm/inputSelect';

AddManager.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function AddManager({ currentUser }) {
    const formRef = useRef(null);

    const [company, setCompany] = useState(null);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];

    const { schema } = schemaConfig[lang][currentUser.permissions];

    const languageOptions = [
        { value: 'pt', label: pageData.portugueseText },
        { value: 'en', label: pageData.englishText },
        { value: 'ge', label: pageData.germanyText },
    ];

    async function handleSubmit(data) {
        const { parsedData, isValid, errors } = await validateForm(
            data,
            schema
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(setLoading(true));

            const response = await addManager(parsedData);

            dispatch(setLoading(false));

            if (response) {
                toast.success(pageData.successText);

                history.push('/managers');
            }
        }
    }

    useEffect(() => {
        formRef.current.reset();

        (async function () {
            dispatch(setLoading(true));

            const data = await fetchCompany();

            if (data) {
                const companyDefaultLanguage = languageOptions.find(
                    (option) =>
                        option.value ===
                        data?.company?.descriptor?.defaultLanguage
                );

                if (companyDefaultLanguage) {
                    formRef.current.setFieldValue('lang', {
                        value: companyDefaultLanguage.value,
                        label: companyDefaultLanguage.label,
                    });
                }

                formRef.current.setFieldValue('share', {
                    label: data?.company?.share
                        ? pageData.yesText
                        : pageData.noText,
                    value: data?.company?.share,
                });

                setCompany(data?.company);
            }

            dispatch(setLoading(false));

            formRef.current.setFieldValue('active', {
                label: pageData.yesText,
                value: true,
            });
        })();
    }, []);

    useEffect(() => {
        const languageOption = languageOptions.find(
            (option) => option.value === formRef.current.getFieldValue('lang')
        );

        if (languageOption) {
            formRef.current.setFieldValue('lang', {
                label: languageOption.label,
                value: languageOption.value,
            });
        }

        formRef.current.setFieldValue('active', {
            label: formRef.current.getFieldValue('active')
                ? pageData.yesText
                : pageData.noText,
            value: formRef.current.getFieldValue('active'),
        });

        formRef.current.setFieldValue('share', {
            label: formRef.current.getFieldValue('share')
                ? pageData.yesText
                : pageData.noText,
            value: formRef.current.getFieldValue('share'),
        });
    }, [lang]);

    return (
        <>
            <Card className="cardShadow">
                <CardBody>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <FormGroup row>
                            <FormGroup className="col-xs-12 col-md-5">
                                <Input
                                    name="name"
                                    type="text"
                                    label={pageData.nameLabelText}
                                    placeholder={pageData.namePlaceholderText}
                                    required
                                />
                            </FormGroup>

                            <FormGroup className="col-xs-12 col-md-3">
                                <Input
                                    name="secondary_id"
                                    type="text"
                                    label={pageData.secondaryIdLabelText}
                                    placeholder={
                                        pageData.secondaryIdPlaceholderText
                                    }
                                />
                            </FormGroup>

                            <FormGroup className="col-xs-12 col-md-3">
                                <Input
                                    name="email"
                                    type="text"
                                    label={pageData.emailLabelText}
                                    placeholder={pageData.emailPlaceholderText}
                                    required
                                />
                            </FormGroup>

                            <FormGroup className="col-xs-12 col-md-3">
                                <Select
                                    name="active"
                                    label={pageData.activeLabelText}
                                    options={[
                                        {
                                            value: false,
                                            label: pageData.noText,
                                        },
                                        {
                                            value: true,
                                            label: pageData.yesText,
                                        },
                                    ]}
                                    required
                                />
                            </FormGroup>

                            <FormGroup className="col-xs-12 col-md-3">
                                <Select
                                    name="share"
                                    label={pageData.sharePatientsLabelText}
                                    options={[
                                        {
                                            value: false,
                                            label: pageData.noText,
                                        },
                                        {
                                            value: true,
                                            label: pageData.yesText,
                                        },
                                    ]}
                                    required
                                />
                            </FormGroup>

                            <FormGroup className="col-xs-12 col-md-3">
                                <Select
                                    name="lang"
                                    label={pageData.languageLabelText}
                                    options={languageOptions}
                                    required
                                />
                            </FormGroup>
                        </FormGroup>

                        <FormGroup row>
                            <div className="col-xs-12 col-md-12 text-left">
                                <Button
                                    color="primary"
                                    className="mr-2"
                                    type="submit"
                                >
                                    {pageData.addText}
                                </Button>

                                <Link
                                    to="/managers"
                                    className="btn btn-secondary"
                                >
                                    {pageData.cancelText}
                                </Link>
                            </div>
                        </FormGroup>
                    </Form>
                </CardBody>
                <CardFooter>
                    <p>
                        <b style={{ color: 'darkred' }}>*</b>{' '}
                        {pageData.requiredFieldText}
                    </p>
                </CardFooter>
            </Card>
        </>
    );
}

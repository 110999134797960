import React, { forwardRef } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import Card from '../../../../components/card/card';
import { Plot } from '../../../../components/plot';

export const WeeklyEvolution = forwardRef(
    ({ data, textData, visible }, ref) => {
        const { cardTitle } = textData;

        const { chart } = data;

        if (Object.entries(chart).length === 0) {
            return <></>;
        }

        return (
            <div className="row weeklyEvolution col-xs-12" ref={ref}>
                <Col xs="12">
                    <Card
                        title={cardTitle}
                        isTable
                        textSize="lg"
                        textCenter
                        data-cy="user_report_weekly_evolution_card_title"
                    >
                        <Row>
                            <Col
                                xs="12"
                                className="text-center chartContainer"
                                style={{
                                    height: `${chart.layout.height / 16}rem`,
                                }}
                            >
                                {visible && (
                                    <Plot
                                        data={chart.data}
                                        layout={chart.layout}
                                        config={chart.config}
                                        useResizeHandler={true}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </div>
        );
    }
);

WeeklyEvolution.displayName = 'WeeklyEvolution';

WeeklyEvolution.propTypes = {
    data: PropTypes.shape({
        chart: PropTypes.shape({
            data: PropTypes.array,
            layout: PropTypes.object,
            config: PropTypes.object,
        }),
    }).isRequired,
    textData: PropTypes.shape({
        cardTitle: PropTypes.string.isRequired,
    }).isRequired,
    visible: PropTypes.bool.isRequired,
};

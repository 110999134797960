import * as Yup from 'yup';

export const validateForm = async (data, schema) => {
    try {
        const parsedData = await schema.validate(data, {
            abortEarly: false,
            stripUnknown: true,
        });

        return { parsedData, isValid: true, errors: {} };
    } catch (err) {
        const errorMessages = {};
        if (err instanceof Yup.ValidationError) {
            err.inner.forEach((error) => {
                errorMessages[error.path] = error.message;
            });
        }
        return { parsedData: data, isValid: false, errors: errorMessages };
    }
};

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            serialNumberText: 'Número de série',
            serialNumberPlaceholderText:
                'Digite o número de série do dispositivo...',
            brandText: 'Marca',
            brandPlaceholderText: 'Digite a marca do dispositivo...',
            modelText: 'Modelo',
            companyText: 'Empresa',
            purchasedAtText: 'Data de compra',
            addText: 'Adicionar',
            cancelText: 'Cancelar',
            requiredFieldText: 'Campos obrigatórios!',
            successText: 'Dispositivo adicionado com sucesso!',
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            serialNumberText: 'Serial number',
            serialNumberPlaceholderText: `Enter the device's serial number...`,
            brandText: 'Brand',
            brandPlaceholderText: `Enter the device's brand...`,
            modelText: 'Model',
            companyText: 'Company',
            purchasedAtText: 'Purchase date',
            addText: 'Add',
            cancelText: 'Cancel',
            requiredFieldText: 'Required fields!',
            successText: 'Device successfully added!',
        },
    },
};

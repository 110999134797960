export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            rawSchema: [
                {
                    id: 'name',
                    validationType: 'string',
                    validations: [],
                },
                {
                    id: 'share',
                    validationType: 'bool',
                    validations: [],
                },
                {
                    id: 'descriptor',
                    validationType: 'object',
                    validations: [],
                    fields: [
                        {
                            id: 'saveEmail',
                            validationType: 'bool',
                            validations: [],
                        },
                        {
                            id: 'defaultLanguage',
                            validationType: 'string',
                            validations: [],
                        },
                        {
                            id: 'minPasswordLength',
                            validationType: 'number',
                            validations: [
                                {
                                    type: 'max',
                                    params: [
                                        30,
                                        'O tamanho mínimo da senha não pode passar de 30',
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            rawSchema: [
                {
                    id: 'name',
                    validationType: 'string',
                    validations: [],
                },
                {
                    id: 'share',
                    validationType: 'bool',
                    validations: [],
                },
                {
                    id: 'descriptor',
                    validationType: 'object',
                    validations: [],
                    fields: [
                        {
                            id: 'saveEmail',
                            validationType: 'bool',
                            validations: [],
                        },
                        {
                            id: 'defaultLanguage',
                            validationType: 'string',
                            validations: [],
                        },
                        {
                            id: 'minPasswordLength',
                            validationType: 'number',
                            validations: [
                                {
                                    type: 'max',
                                    params: [
                                        30,
                                        'The minimum password length must not exceed 30',
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    },
};

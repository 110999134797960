import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

export default function Radio({
    name,
    label,
    className = '',
    options,
    ...rest
}) {
    const inputRefs = useRef([]);

    const {
        fieldName,
        registerField,
        defaultValue = '',
        error,
    } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRefs,
            getValue: (refs) => {
                return refs.current.find((input) => input?.checked)?.value;
            },
            setValue: (refs, id) => {
                const inputRef = refs.current.find((ref) => ref.id === id);
                if (inputRef) inputRef.checked = true;
            },
            clearValue: (refs) => {
                const inputRef = refs.current.find(
                    (ref) => ref.checked === true
                );
                if (inputRef) inputRef.checked = false;
            },
        });
    }, [fieldName, registerField]);

    return (
        <>
            {label && (
                <label
                    htmlFor={fieldName}
                    dangerouslySetInnerHTML={{
                        __html: label,
                    }}
                ></label>
            )}

            {options.map((option, index) => (
                <span key={option.id}>
                    <input
                        id={option.id}
                        ref={(ref) => {
                            inputRefs.current[index] = ref;
                        }}
                        type="radio"
                        name={name}
                        defaultChecked={defaultValue.includes(option.id)}
                        value={option.value}
                        {...rest}
                        className={`ml-2 ${className}`}
                    />

                    <label
                        htmlFor={option.id}
                        key={option.id}
                        className="ml-2 mr-2"
                    >
                        {option.label}
                    </label>
                </span>
            ))}

            {error && <span className="text-danger">{error}</span>}
        </>
    );
}

Radio.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    className: PropTypes.string,
    options: PropTypes.array,
};

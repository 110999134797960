import { handleDate } from './dateFormat';

export function handleActiveProperty(active, activeText, inactiveText) {
    let text;
    let className;

    if (active) {
        className = 'textGreen';
        text = activeText;
    } else {
        className = 'textRed';
        text = inactiveText;
    }

    return { className, text };
}

export function handlePastDate(stringDate) {
    if (typeof stringDate !== 'string') {
        return '';
    }

    if (!stringDate) {
        return '';
    }

    try {
        const now = new Date();

        const newDate = handleDate(stringDate);

        if (newDate === null) {
            return '';
        }

        if (newDate) {
            if (newDate <= now) {
                return 'textRed';
            } else {
                return 'textGreen';
            }
        } else {
            return 'textRed';
        }
    } catch (e) {
        return '';
    }
}

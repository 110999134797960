import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: null,
        manager: {
            schemaWithOldCard: Yup.object().shape({
                plan_name: Yup.string(),
                due_date: Yup.string(),
                price: Yup.string(),
                description: Yup.string(),
                display_number: Yup.string(),
                expiration_date: Yup.string(),
            }),
            schemaWithNewCard: Yup.object().shape({
                plan_name: Yup.string(),
                due_date: Yup.string(),
                price: Yup.string(),
                new_description: Yup.string().required('Campo obrigatório'),
                number: Yup.string().required('Campo obrigatório'),
                verification_value: Yup.string().required('Campo obrigatório'),
                full_name: Yup.string().required('Campo obrigatório'),
                expiration: Yup.string().required('Campo obrigatório'),
            }),
            schemaWithoutCard: Yup.object().shape({
                plan_name: Yup.string(),
                due_date: Yup.string(),
                price: Yup.string(),
            }),
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            schemaWithOldCard: Yup.object().shape({
                plan_name: Yup.string(),
                due_date: Yup.string(),
                price: Yup.string(),
                description: Yup.string(),
                display_number: Yup.string(),
                expiration_date: Yup.string(),
            }),
            schemaWithNewCard: Yup.object().shape({
                plan_name: Yup.string(),
                due_date: Yup.string(),
                price: Yup.string(),
                new_description: Yup.string().required('Required field'),
                number: Yup.string().required('Required field'),
                verification_value: Yup.string().required('Required field'),
                full_name: Yup.string().required('Required field'),
                expiration: Yup.string().required('Required field'),
            }),
            schemaWithoutCard: Yup.object().shape({
                plan_name: Yup.string(),
                due_date: Yup.string(),
                price: Yup.string(),
            }),
        },
        condor_admin: null,
    },
};

import Input from '../../../components/inputForm/inputText';
import { Checkbox } from '../../../components/inputForm/inputCheckbox';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: {
                commonFields: [
                    {
                        component: Input,
                        props: {
                            name: 'name',
                            type: 'text',
                            label: 'Nome',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'duration',
                            type: 'number',
                            label: 'Duração (meses)',
                            min: 0,
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'is_percent',
                            label: 'Tipo de cupom',
                            type: 'text',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                percentFields: [
                    {
                        component: Input,
                        props: {
                            name: 'percentOff',
                            type: 'number',
                            label: 'Percentual de desconto',
                            min: 0,
                            step: 0.5,
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                amountFields: [
                    {
                        component: Input,
                        props: {
                            name: 'amountOff',
                            type: 'number',
                            label: 'Valor de desconto (centavos)',
                            min: 0,
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                currencyFields: [
                    {
                        component: Checkbox,
                        props: {
                            name: 'currency[0]',
                            type: 'checkbox',
                            label: 'Real: ',
                            value: 'BRL',
                            disabled: true,
                        },
                        className: 'col-xs-12 col-md-1',
                    },
                    {
                        component: Checkbox,
                        props: {
                            name: 'currency[1]',
                            type: 'checkbox',
                            label: 'Dólar: ',
                            value: 'USD',
                            disabled: true,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                ],
            },
            deleteSuccessText: 'Cupom excluído com sucesso!',
            deleteText: 'Excluir',
            cancelText: 'Cancelar',
            currencyText: 'Moedas',
            percentageText: 'Porcentagem',
            amountText: 'Valor',
            selectCurrencyErrorText:
                'É obrigatório selecionar ao menos uma moeda',
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: {
                commonFields: [
                    {
                        component: Input,
                        props: {
                            name: 'name',
                            type: 'text',
                            label: 'Name',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'duration',
                            type: 'number',
                            label: 'Duration (months)',
                            min: 0,
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'is_percent',
                            label: 'Coupon type',
                            type: 'text',
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                percentFields: [
                    {
                        component: Input,
                        props: {
                            name: 'percentOff',
                            type: 'number',
                            label: 'Percent off',
                            min: 0,
                            step: 0.5,
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                amountFields: [
                    {
                        component: Input,
                        props: {
                            name: 'amountOff',
                            type: 'number',
                            label: 'Amount off (cents)',
                            min: 0,
                            readOnly: true,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                currencyFields: [
                    {
                        component: Checkbox,
                        props: {
                            name: 'currency[0]',
                            type: 'checkbox',
                            label: 'Real: ',
                            value: 'BRL',
                            disabled: true,
                        },
                        className: 'col-xs-12 col-md-1',
                    },
                    {
                        component: Checkbox,
                        props: {
                            name: 'currency[1]',
                            type: 'checkbox',
                            label: 'Dollar: ',
                            value: 'USD',
                            disabled: true,
                        },
                        className: 'col-xs-12 col-md-2',
                    },
                ],
            },
            deleteSuccessText: 'Coupon successfully deleted!',
            deleteText: 'Delete',
            cancelText: 'Cancel',
            currencyText: 'Currency',
            percentageText: 'Percentage',
            amountText: 'Value',
            selectCurrencyErrorText:
                'It is required to select at least one currency',
        },
    },
};

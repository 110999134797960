import React from 'react';
import PropTypes from 'prop-types';

export function TableWrapper({ data, titleText, ths, tds }) {
    return (
        <>
            <div className="contentPrint">
                <div className="col-12Print">
                    <div className="cardPrint">
                        <div className="card-headerPrint card-tablePrint">
                            <div className="card-titlePrint">
                                <p className="pCardTitlePrint">{titleText}</p>
                            </div>
                        </div>
                        <div className="card-bodyPrint">
                            <div className="rowPrint">
                                <div className="col-12Print">
                                    <table className="col-12Print tablePrint">
                                        <thead>
                                            <tr>
                                                {ths.map((th) => {
                                                    return (
                                                        <th
                                                            key={th}
                                                            className="thPrint text-center"
                                                            style={{
                                                                verticalAlign:
                                                                    'middle',
                                                            }}
                                                        >
                                                            {th}
                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((day) => {
                                                return (
                                                    <tr key={day.week}>
                                                        {tds.map((td) => {
                                                            return (
                                                                <td
                                                                    key={`${day.week}-${td.property}`}
                                                                    className="text-center tdPrint"
                                                                    style={{
                                                                        verticalAlign:
                                                                            'middle',
                                                                    }}
                                                                >
                                                                    {
                                                                        day[
                                                                            td
                                                                                .property
                                                                        ]
                                                                    }
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

TableWrapper.propTypes = {
    data: PropTypes.array.isRequired,
    titleText: PropTypes.string.isRequired,
    ths: PropTypes.array.isRequired,
    tds: PropTypes.array.isRequired,
};

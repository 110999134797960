import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { Button, Card, CardBody, CardFooter, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { setLoading } from '../../../store/actions/loadingActions';
import { validateForm } from '../../../helpers/formValidator';
import { addDevice } from '../../../services/api/devices';
import { fetchCompanies } from '../../../services/api/companies';
import history from '../../../services/history';
import Input from '../../../components/inputForm/inputText';
import Select from '../../../components/inputForm/inputSelect';

AddDevice.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function AddDevice({ currentUser }) {
    const formRef = useRef(null);

    const [companies, setCompanies] = useState([]);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];
    const schemaData = schemaConfig[lang][currentUser.permissions];

    async function handleSubmit(data) {
        const { parsedData, isValid, errors } = await validateForm(
            data,
            schemaData.schema
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(setLoading(true));

            const response = await addDevice(parsedData);

            dispatch(setLoading(false));

            if (response) {
                toast.success(pageData.successText);

                history.push('/devices');
            }
        }
    }

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            const data = await fetchCompanies();

            setCompanies(data?.companies);

            dispatch(setLoading(false));
        })();
    }, []);

    if (pageData === null) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-5">
                            <Input
                                name="serial_no"
                                type="number"
                                label={pageData.serialNumberText}
                                placeholder={
                                    pageData.serialNumberPlaceholderText
                                }
                                required
                                data-cy="add_device_serial_no_input"
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-5">
                            <Input
                                name="brand"
                                type="text"
                                label={pageData.brandText}
                                placeholder={pageData.brandPlaceholderText}
                                defaultValue="Condor Instruments"
                                readOnly
                                required
                                data-cy="add_device_brand_input"
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-5">
                            <Select
                                name="model"
                                label={pageData.modelText}
                                options={[
                                    { value: 'ActLumus', label: 'ActLumus' },
                                ]}
                                required
                                className="add_device_model_input"
                                data-cy="add_device_model_input"
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-5">
                            <Select
                                name="company_id"
                                label={pageData.companyText}
                                options={companies.map((company) => {
                                    return {
                                        value: company.id,
                                        label: company.name,
                                    };
                                })}
                                className="add_device_company_id_input"
                                data-cy="add_device_company_id_input"
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-5">
                            <Input
                                name="purchased_at"
                                type="date"
                                label={pageData.purchasedAtText}
                                data-cy="add_device_purchased_at_input"
                            />
                        </FormGroup>
                    </FormGroup>

                    <hr />

                    <FormGroup row>
                        <div className="col-xs-12 col-md-12 text-left">
                            <Button
                                color="primary"
                                className="mr-2"
                                type="submit"
                                data-cy="add_device_add_button"
                            >
                                {pageData.addText}
                            </Button>
                            <Link
                                to="/devices"
                                className="btn btn-secondary"
                                data-cy="add_device_cancel_link"
                            >
                                {pageData.cancelText}
                            </Link>
                        </div>
                    </FormGroup>
                </Form>
            </CardBody>
            <CardFooter>
                <p data-cy="add_device_required_fields_text">
                    <b style={{ color: 'darkred' }}>*</b>{' '}
                    {pageData.requiredFieldText}
                </p>
            </CardFooter>
        </Card>
    );
}

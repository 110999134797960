import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody, CardFooter, Button } from 'reactstrap';
import PropTypes from 'prop-types';

CardItem.propTypes = {
    price: PropTypes.string.isRequired,
    save: PropTypes.string.isRequired,
    fidelity: PropTypes.string.isRequired,
    state: PropTypes.object.isRequired,
    texts: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
};

export function CardItem({
    price,
    save,
    fidelity,
    texts,
    state,
    disabled = false,
}) {
    return (
        <div className="subscriptionItem">
            <Card className="planItemCard">
                <CardHeader className="planItemCardHeader">
                    <div className="planItemCardHeaderContent">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: texts.fidelityText,
                            }}
                        ></span>
                    </div>
                </CardHeader>
                <CardBody className="planItemCardBody">
                    <ul className="planItemCardInfo">
                        <li>{texts.payableWithText}</li>
                        <li>
                            {price}
                            <small>
                                <b>{texts.planPaymentTypeText}</b>
                            </small>
                        </li>
                        <li>{`${texts.saveMoneyText} ${save}`}</li>
                    </ul>
                </CardBody>
                <div className="planItemCardAction">
                    {disabled ? (
                        <Button color="primary" disabled>
                            {texts.chooseButtonText}
                        </Button>
                    ) : (
                        <Link
                            className="btn btn-primary"
                            to={{
                                pathname:
                                    '/account/my_account/change_plan/choose_subscription/review_and_payment',
                                state,
                            }}
                        >
                            {texts.chooseButtonText}
                        </Link>
                    )}
                </div>
                {fidelity !== 'monthly' && (
                    <CardFooter
                        className="text-center"
                        dangerouslySetInnerHTML={{
                            __html: texts.fineCancellationText,
                        }}
                    ></CardFooter>
                )}
            </Card>
        </div>
    );
}

export const AUTH = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    VERIFY_2FA_REQUEST: 'VERIFY_2FA_REQUEST',
    VERIFY_2FA_SUCCESS: 'VERIFY_2FA_SUCCESS',
    VERIFY_2FA_FAILURE: 'VERIFY_2FA_FAILURE',
    LOGOUT: 'LOGOUT',
    UPDATE_CURRENT_USER_REQUEST: 'UPDATE_CURRENT_USER_REQUEST',
    UPDATE_CURRENT_USER_SUCCESS: 'UPDATE_CURRENT_USER_SUCCESS',
    UPDATE_CURRENT_USER_FAILURE: 'UPDATE_CURRENT_USER_FAILURE',
    UPDATE_CURRENT_USER_SUBSCRIPTION_REQUEST:
        'UPDATE_CURRENT_USER_SUBSCRIPTION_REQUEST',
    UPDATE_CURRENT_USER_SUBSCRIPTION: 'UPDATE_CURRENT_USER_SUBSCRIPTION',
    UPDATE_CURRENT_USER_TRIALS_CONFIGURATIONS_REQUEST:
        'UPDATE_CURRENT_USER_TRIALS_CONFIGURATIONS_REQUEST',
    RESET_STATE: 'RESET_STATE',
};

export const LOADING = {
    LOADING_REQUEST: 'LOADING_REQUEST',
    SHOW_LOADING: 'SHOW_LOADING',
    HIDE_LOADING: 'HIDE_LOADING',
    RESET_STATE: 'RESET_STATE',
};

export const LANG = {
    CHANGE_LANG_REQUEST: 'CHANGE_LANG_REQUEST',
    CHANGE_LANG: 'CHANGE_LANG',
};

export const LOCALE = {
    CHANGE_LOCALE_REQUEST: 'CHANGE_LOCALE_REQUEST',
    CHANGE_LOCALE: 'CHANGE_LOCALE',
};

export const REPORT = {
    UPDATE_REPORT_REQUEST: 'UPDATE_REPORT_REQUEST',
    UPDATE_REPORT: 'UPDATE_REPORT',

    UPDATE_DATES_REQUEST: 'UPDATE_DATES_REQUEST',
    UPDATE_DATES: 'UPDATE_DATES',

    UPDATE_DAYS_IN_WEEK_REQUEST: 'UPDATE_DAYS_IN_WEEK_REQUEST',
    UPDATE_DAYS_IN_WEEK: 'UPDATE_DAYS_IN_WEEK',

    UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
    UPDATE_USER: 'UPDATE_USER',

    UPDATE_WEEK_REFERENCE_REQUEST: 'UPDATE_WEEK_REFERENCE_REQUEST',
    UPDATE_WEEK_REFERENCE_SUCCESS: 'UPDATE_WEEK_REFERENCE_SUCCESS',
    UPDATE_WEEK_REFERENCE_FAILURE: 'UPDATE_WEEK_REFERENCE_FAILURE',

    UPDATE_CALENDAR_REQUEST: 'UPDATE_CALENDAR_REQUEST',
    UPDATE_CALENDAR_SUCCESS: 'UPDATE_CALENDAR_SUCCESS',
    UPDATE_CALENDAR_FAILURE: 'UPDATE_CALENDAR_FAILURE',

    UPDATE_WEEKLY_EVOLUTION_REQUEST: 'UPDATE_WEEKLY_EVOLUTION_REQUEST',
    UPDATE_WEEKLY_EVOLUTION_SUCCESS: 'UPDATE_WEEKLY_EVOLUTION_SUCCESS',
    UPDATE_WEEKLY_EVOLUTION_FAILURE: 'UPDATE_WEEKLY_EVOLUTION_FAILURE',

    UPDATE_DAILY_EVOLUTION_REQUEST: 'UPDATE_DAILY_EVOLUTION_REQUEST',
    UPDATE_DAILY_EVOLUTION_SUCCESS: 'UPDATE_DAILY_EVOLUTION_SUCCESS',
    UPDATE_DAILY_EVOLUTION_FAILURE: 'UPDATE_DAILY_EVOLUTION_FAILURE',

    UPDATE_AVERAGE_TABLE_REQUEST: 'UPDATE_AVERAGE_TABLE_REQUEST',
    UPDATE_AVERAGE_TABLE_SUCCESS: 'UPDATE_AVERAGE_TABLE_SUCCESS',
    UPDATE_AVERAGE_TABLE_FAILURE: 'UPDATE_AVERAGE_TABLE_FAILURE',

    UPDATE_EDITABLE_TABLE_REQUEST: 'UPDATE_EDITABLE_TABLE_REQUEST',
    UPDATE_EDITABLE_TABLE_SUCCESS: 'UPDATE_EDITABLE_TABLE_SUCCESS',
    UPDATE_EDITABLE_TABLE_FAILURE: 'UPDATE_EDITABLE_TABLE_FAILURE',

    UPDATE_EDITABLE_TABLE_WEEKS_REQUEST: 'UPDATE_EDITABLE_TABLE_WEEKS_REQUEST',
    UPDATE_EDITABLE_TABLE_WEEKS_SUCCESS: 'UPDATE_EDITABLE_TABLE_WEEKS_SUCCESS',
    UPDATE_EDITABLE_TABLE_WEEKS_FAILURE: 'UPDATE_EDITABLE_TABLE_WEEKS_FAILURE',

    UPDATE_ACTOGRAM_REQUEST: 'UPDATE_ACTOGRAM_REQUEST',
    UPDATE_ACTOGRAM_SUCCESS: 'UPDATE_ACTOGRAM_SUCCESS',
    UPDATE_ACTOGRAM_FAILURE: 'UPDATE_ACTOGRAM_FAILURE',

    UPDATE_SLEEP_SCORING_REQUEST: 'UPDATE_SLEEP_SCORING_REQUEST',
    UPDATE_SLEEP_SCORING_SUCCESS: 'UPDATE_SLEEP_SCORING_SUCCESS',
    UPDATE_SLEEP_SCORING_FAILURE: 'UPDATE_SLEEP_SCORING_FAILURE',

    RESET_STATE: 'RESET_STATE',
};

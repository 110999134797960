import { toPng } from 'html-to-image';
import { jsPDF } from 'jspdf';

export const exportAs = async (
    extension,
    element,
    filename,
    numberOfWeeks = 28
) => {
    const filter = (node) => {
        const exclusionClasses = [
            'modebar-container',
            'gl-container',
            'hoverlayer',
            'clips',
            'gradients',
            'patterns',
            'layer-below',
            'polarlayer',
            'smithlayer',
            'ternarylayer',
            'geolayer',
            'funnelarealayer',
            'pielayer',
            'iciclelayer',
            'treemaplayer',
            'glimages',
            'sunburstlayer',
            'draglayer',
            'cursor-crosshair',
            'indicatorlayer',
            'layer-above',
            'selectionlayer',
            'menulayer',
            'zoomlayer',
            'g-gtitle',
            'g-xtitle',
            'g-2xtitle',
            'g-3xtitle',
            'g-4xtitle',
            'g-5xtitle',
            'g-6xtitle',
            'g-7xtitle',
            'g-8xtitle',
            'g-ytitle',
            'g-2ytitle',
            'g-3ytitle',
            'g-4ytitle',
            'g-5ytitle',
            'g-6ytitle',
            'g-7ytitle',
            'g-8ytitle',
            'scrollbar',
            'bglayer',
            'layer-subplot',
            'minor-gridlayer',
            'xlines-below',
            'ylines-below',
            'overlines-below',
            'xaxislayer-below',
            'yaxislayer-below',
            'overaxes-below',
            'overplot',
            'xlines-above crisp',
            'ylines-above crisp',
            'overlines-above',
            'overaxes-above',
        ];

        return !exclusionClasses.some((classname) =>
            node.classList?.contains(classname)
        );
    };

    toPng(element, {
        cacheBust: true,
        skipFonts: true,
        preferredFontFormat: 'Poppins',
        skipAutoScale: true,
        quality: 1,
        backgroundColor: 'white',
        filter,
    })
        .then((dataUrl) => {
            if (extension === 'png') {
                downloadPNG(dataUrl, filename);
            } else if (extension === 'pdf') {
                downloadPDF(dataUrl, filename, numberOfWeeks);
            }
        })
        .catch((err) => {
            console.log(err);
        });
};

const downloadPNG = (blob, fileName) => {
    const fakeLink = window.document.createElement('a');
    fakeLink.style = 'display:none;';
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
};

const downloadPDF = (blob, fileName, numberOfWeeks) => {
    const tempPdf = new jsPDF({
        putOnlyUsedFonts: true,
    });
    const imgProperties = tempPdf.getImageProperties(blob);

    const tempPdfWidth = tempPdf.internal.pageSize.getWidth();
    const tempPdfHeight =
        (imgProperties.height * tempPdfWidth) / imgProperties.width;

    if (numberOfWeeks > 20) {
        const pdf = new jsPDF({
            putOnlyUsedFonts: true,
            format: [imgProperties.width / 2, imgProperties.height / 2],
        });

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(blob, 'JPEG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`${fileName}.pdf`);
    } else {
        tempPdf.addImage(blob, 'JPEG', 0, 0, tempPdfWidth, tempPdfHeight);
        tempPdf.save(`${fileName}.pdf`);
    }
};

import produce from 'immer';

import { AUTH } from '../constants';

const INITIAL_STATE = {
    currentUser: null,
    signed: false,
    loading: false,
    completedLoginFlow: false,
    maxDateToCompleteLoginFlow: null,
};

export default function auth(state = INITIAL_STATE, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case AUTH.LOGIN_REQUEST: {
                draft.loading = true;
                break;
            }
            case AUTH.LOGIN_SUCCESS: {
                draft.currentUser = action.payload.currentUser;
                draft.signed = true;
                draft.loading = false;
                draft.completedLoginFlow = action.payload.completedLoginFlow;
                draft.maxDateToCompleteLoginFlow =
                    action.payload.maxDateToCompleteLoginFlow;
                break;
            }
            case AUTH.LOGIN_FAILURE: {
                draft.loading = false;
                break;
            }
            case AUTH.VERIFY_2FA_REQUEST: {
                draft.loading = true;
                break;
            }
            case AUTH.VERIFY_2FA_SUCCESS: {
                draft.loading = false;
                draft.completedLoginFlow = true;
                draft.maxDateToCompleteLoginFlow = null;
                break;
            }
            case AUTH.VERIFY_2FA_FAILURE: {
                draft.loading = false;
                break;
            }
            case AUTH.LOGOUT: {
                draft.currentUser = null;
                draft.signed = false;
                draft.completedLoginFlow = false;
                draft.maxDateToCompleteLoginFlow = null;
                break;
            }
            case AUTH.UPDATE_CURRENT_USER_REQUEST: {
                break;
            }
            case AUTH.UPDATE_CURRENT_USER_SUCCESS: {
                draft.currentUser = action.payload.currentUser;
                break;
            }
            case AUTH.UPDATE_CURRENT_USER_FAILURE: {
                break;
            }
            case AUTH.UPDATE_CURRENT_USER_SUBSCRIPTION_REQUEST: {
                break;
            }
            case AUTH.UPDATE_CURRENT_USER_SUBSCRIPTION: {
                draft.currentUser = action.payload.currentUser;
                break;
            }
            case AUTH.UPDATE_CURRENT_USER_TRIALS_CONFIGURATIONS_REQUEST: {
                break;
            }
            case AUTH.RESET_STATE: {
                draft.currentUser = null;
                draft.signed = false;
                draft.loading = false;
                draft.completedLoginFlow = false;
                draft.maxDateToCompleteLoginFlow = null;
                break;
            }
        }
    });
}

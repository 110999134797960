import api from '../../services/api';

export function addCompany(parms) {
    const data = api
        .post(`/companies`, { ...parms }, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchCompanies() {
    const data = api
        .get('/companies', { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function editCompany(id, parms) {
    const data = api
        .put(`/companies/${id}`, { ...parms }, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function deleteCompany(id) {
    const data = api
        .delete(`/companies/${id}`, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

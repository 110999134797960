import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { FaEdit, FaEnvelope, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import pageConfig from './page.config';
import { setLoading } from '../../store/actions/loadingActions';
import { fetchManagers } from '../../services/api/users';
import { useOrderByProperty } from '../../hooks/useOrderByProperty';
import Table from '../../components/table/table';
import { ThWithOrdering } from '../../components/table/thWithOrdering';
import Td from '../../components/table/td';

Managers.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function Managers({ currentUser }) {
    const [users, setUsers] = useState([]);
    const { activeFilter, filteredData, setFilteredData, orderByProperty } =
        useOrderByProperty(users, 'name', 'asc');
    const [nameFilter, setNameFilter] = useState('');
    const [secondaryIdFilter, setSecondaryIdFilter] = useState('');
    const [ready, setReady] = useState(false);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);
    const isLoading = useSelector((state) => state.loading.isLoading);

    const pageData = pageConfig[lang][currentUser.permissions];

    function handleNameInputChange(e) {
        setNameFilter(e.target.value);
    }

    function handleSecondaryIdInputChange(e) {
        setSecondaryIdFilter(e.target.value);
    }

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            const data = await fetchManagers();

            if (data?.managers?.length === 0) {
                toast.info(pageData.noManagersText);
            }

            setUsers(data?.managers);
            setFilteredData(data?.managers);

            setReady(true);

            dispatch(setLoading(false));
        })();
    }, []);

    useEffect(() => {
        let tempFilteredUsers = [];

        if (nameFilter && secondaryIdFilter) {
            tempFilteredUsers = users.filter((user) =>
                user.secondary_id === null
                    ? ''
                          .toUpperCase()
                          .includes(secondaryIdFilter.toUpperCase()) &&
                      user.name.toUpperCase().includes(nameFilter.toUpperCase())
                    : user.secondary_id
                          .toUpperCase()
                          .includes(secondaryIdFilter.toUpperCase()) &&
                      user.name.toUpperCase().includes(nameFilter.toUpperCase())
            );
        } else if (nameFilter) {
            tempFilteredUsers = users.filter((user) =>
                user.name.toUpperCase().includes(nameFilter.toUpperCase())
            );
        } else if (secondaryIdFilter) {
            tempFilteredUsers = users.filter((user) =>
                user.secondary_id === null
                    ? ''.toUpperCase().includes(secondaryIdFilter.toUpperCase())
                    : user.secondary_id
                          .toUpperCase()
                          .includes(secondaryIdFilter.toUpperCase())
            );
        } else {
            tempFilteredUsers = users;
        }

        setFilteredData(tempFilteredUsers);
    }, [nameFilter, secondaryIdFilter]);

    if (isLoading || !ready) {
        return <></>;
    }

    return (
        <>
            <Row>
                <Col xs="12">
                    <div className={styles.searchAndAddContainer}>
                        <input
                            name="searchName"
                            placeholder={pageData.searchByNameText}
                            title={pageData.searchByNameText}
                            value={nameFilter}
                            onChange={handleNameInputChange}
                        />
                        <input
                            name="searchSecondaryId"
                            placeholder={pageData.searchBySecondaryIdText}
                            title={pageData.searchBySecondaryIdText}
                            value={secondaryIdFilter}
                            onChange={handleSecondaryIdInputChange}
                            className="ml-2"
                        />
                        <Link
                            to="managers/add"
                            className="btn btn-primary ml-2"
                            title={pageData.addText}
                        >
                            {pageData.addText}
                        </Link>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs="12">
                    <Table className={styles.usersTable}>
                        <thead>
                            <tr>
                                <ThWithOrdering
                                    property="id"
                                    activeFilterProperty={activeFilter.property}
                                    activeOrder={activeFilter.order}
                                    orderByProperty={orderByProperty}
                                    activeClassName={styles.active}
                                    clickableIconClassName={
                                        styles.clickableIcon
                                    }
                                >
                                    {pageData.idText}
                                </ThWithOrdering>
                                <ThWithOrdering
                                    property="name"
                                    activeFilterProperty={activeFilter.property}
                                    activeOrder={activeFilter.order}
                                    orderByProperty={orderByProperty}
                                    activeClassName={styles.active}
                                    clickableIconClassName={
                                        styles.clickableIcon
                                    }
                                >
                                    {pageData.nameText}
                                </ThWithOrdering>
                                <ThWithOrdering
                                    property="secondary_id"
                                    activeFilterProperty={activeFilter.property}
                                    activeOrder={activeFilter.order}
                                    orderByProperty={orderByProperty}
                                    activeClassName={styles.active}
                                    clickableIconClassName={
                                        styles.clickableIcon
                                    }
                                >
                                    {pageData.secondaryIdText}
                                </ThWithOrdering>
                                <th>{pageData.emailText}</th>
                                <th>{pageData.activeText}</th>
                                <th>{pageData.actionsText}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((user) => (
                                <tr key={user.id}>
                                    <Td title={pageData.idText}>
                                        {user.id === null ? '' : user.id}
                                    </Td>
                                    <Td title={pageData.nameText}>
                                        {user.name === null ? '' : user.name}
                                    </Td>
                                    <Td title={pageData.secondaryIdText}>
                                        {user.secondary_id === null
                                            ? ''
                                            : user.secondary_id}
                                    </Td>
                                    <Td title={pageData.emailText}>
                                        {user.email === null ? '' : user.email}
                                    </Td>
                                    <Td
                                        title={pageData.activeText}
                                        className={
                                            user.active
                                                ? 'textGreen'
                                                : 'textRed'
                                        }
                                    >
                                        {user.active
                                            ? pageData.yesText
                                            : pageData.noText}
                                    </Td>
                                    <Td title={pageData.actionsText}>
                                        <Link
                                            to={{
                                                pathname: 'managers/edit',
                                                state: {
                                                    user,
                                                },
                                            }}
                                            className="btn btn-secondary ml-2"
                                            title={pageData.editText}
                                        >
                                            <FaEdit />
                                        </Link>
                                        <Link
                                            to={{
                                                pathname:
                                                    'managers/credentials',
                                                state: {
                                                    user,
                                                },
                                            }}
                                            className="btn btn-info ml-2"
                                            title={pageData.credentialsText}
                                        >
                                            <FaEnvelope />
                                        </Link>
                                        <Link
                                            to={{
                                                pathname: 'managers/delete',
                                                state: {
                                                    user,
                                                },
                                            }}
                                            className="btn btn-danger ml-2"
                                            title={pageData.deleteText}
                                        >
                                            <FaTrash />
                                        </Link>
                                    </Td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );
}

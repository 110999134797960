import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Row, Col, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import { Reorder } from 'framer-motion';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

import pageConfig from './page.config';
import { setLoading } from '../../../store/actions/loadingActions';
import history from '../../../services/history';
import { deleteDiary } from '../../../services/api/diaries';
import { Question } from '../components/question';
import FormFields from '../../../components/form/formFields';

export default function DeleteDiary({ currentUser }) {
    const formRef = useRef(null);

    const dispatch = useDispatch();
    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];

    const [diary, setDiary] = useState({});
    const [editedDefaultQuestions, setEditedDefaultQuestions] = useState(
        pageData?.defaultQuestions ? [...pageData?.defaultQuestions] : []
    );
    const [editedCustomQuestions, setEditedCustomQuestions] = useState([]);

    function convertToArray(defaultQuestionsObject) {
        let defaultQuestionsArray = [];

        for (let questionId in defaultQuestionsObject) {
            let descriptor = {};

            switch (questionId) {
                case 'date': {
                    descriptor = {
                        id: questionId,
                        type: 'DateInput',
                        fieldtext:
                            pageData.defaultQuestionsTexts[questionId]
                                .fieldtext,
                        default: true,
                        required: true,
                        isVisible: defaultQuestionsObject[questionId].isVisible,
                        editable: false,
                    };
                    break;
                }
                case 'sleepDuration':
                case 'latencyDuration':
                case 'totalDurationOfAwk':
                case 'durationOfNaps': {
                    descriptor = {
                        id: questionId,
                        type: 'DurationInput',
                        fieldtext:
                            pageData.defaultQuestionsTexts[questionId]
                                .fieldtext,
                        default: true,
                        required: true,
                        isVisible: defaultQuestionsObject[questionId].isVisible,
                        editable: false,
                    };
                    break;
                }
                case 'dayType':
                case 'usesMedicine':
                case 'triedToSleep': {
                    descriptor = {
                        id: questionId,
                        type: 'ToggleInput',
                        fieldtext:
                            pageData.defaultQuestionsTexts[questionId]
                                .fieldtext,
                        textdescriptor:
                            pageData.defaultQuestionsTexts[questionId]
                                .textdescriptor,
                        default: true,
                        required: true,
                        isVisible: defaultQuestionsObject[questionId].isVisible,
                        editable: false,
                    };
                    break;
                }
                case 'wakeTime':
                case 'getUpTime':
                case 'bedTime':
                case 'trySleepStart': {
                    descriptor = {
                        id: questionId,
                        type: 'DateTimeInput',
                        fieldtext:
                            pageData.defaultQuestionsTexts[questionId]
                                .fieldtext,
                        default: true,
                        required: true,
                        isVisible: defaultQuestionsObject[questionId].isVisible,
                        editable: false,
                    };
                    break;
                }
                case 'nOfNaps':
                case 'nOfAwk': {
                    descriptor = {
                        id: questionId,
                        type: 'IntInput',
                        fieldtext:
                            pageData.defaultQuestionsTexts[questionId]
                                .fieldtext,
                        default: true,
                        required: true,
                        isVisible: defaultQuestionsObject[questionId].isVisible,
                        editable: false,
                    };
                    break;
                }
                case 'sleepSatisfaction': {
                    descriptor = {
                        id: questionId,
                        type: 'SliderInput',
                        fieldtext:
                            pageData.defaultQuestionsTexts[questionId]
                                .fieldtext,
                        minText:
                            pageData.defaultQuestionsTexts[questionId].minText,
                        maxText:
                            pageData.defaultQuestionsTexts[questionId].maxText,
                        default: true,
                        required: false,
                        isVisible: defaultQuestionsObject[questionId].isVisible,
                        editable: true,
                    };
                    break;
                }
                case 'sleepSatisfactionQuality': {
                    descriptor = {
                        id: questionId,
                        type: 'ChoiceChipsSelect',
                        fieldtext:
                            pageData.defaultQuestionsTexts[questionId]
                                .fieldtext,
                        choices: [
                            ...pageData.defaultQuestionsTexts[questionId]
                                .choices,
                        ],
                        default: true,
                        required: false,
                        isVisible: defaultQuestionsObject[questionId].isVisible,
                        editable: true,
                    };
                    break;
                }
                case 'comments':
                case 'medicineDescription': {
                    descriptor = {
                        id: questionId,
                        type: 'FreeTextInput',
                        fieldtext:
                            pageData.defaultQuestionsTexts[questionId]
                                .fieldtext,
                        default: true,
                        required: true,
                        isVisible: defaultQuestionsObject[questionId].isVisible,
                        editable: false,
                    };
                    break;
                }
            }

            defaultQuestionsArray.push({
                descriptor,
            });
        }

        return defaultQuestionsArray;
    }

    async function handleSubmit(data, { reset }, event) {
        event.preventDefault();

        dispatch(setLoading(true));

        const response = await deleteDiary(diary.id);

        dispatch(setLoading(false));

        if (response) {
            toast.success(pageData.successResponseText);
            history.push('/custom-diaries');
        }
    }

    useEffect(() => {
        if (history.location.state?.entity) {
            const initialData = {};

            let tempDiary = history.location.state.entity;

            setDiary(tempDiary);

            pageData?.fields.map((field) => {
                switch (field.props.name) {
                    default:
                        initialData[field.props.name] =
                            tempDiary[field.props.name];
                        break;
                }
            });

            formRef.current.setData(initialData);
        } else {
            history.push('/custom-diaries');
        }
    }, []);

    useEffect(() => {
        setEditedDefaultQuestions(
            diary?.descriptor?.defaultQuestions
                ? convertToArray({ ...diary?.descriptor?.defaultQuestions })
                : []
        );
        setEditedCustomQuestions(
            diary?.descriptor?.customQuestions
                ? [...diary?.descriptor?.customQuestions]
                : []
        );
    }, [diary, lang]);

    if (pageData) {
        return (
            <Card className="cardShadow">
                <CardBody>
                    <Row>
                        <Col xs="12">
                            <Form ref={formRef} onSubmit={handleSubmit}>
                                <FormGroup row>
                                    <Col
                                        xs="12"
                                        className={styles.editButtonContainer}
                                    >
                                        <Button type="submit" color="primary">
                                            {pageData.deleteText}
                                        </Button>
                                        <Link
                                            to="/custom-diaries"
                                            className="btn btn-secondary ml-2"
                                        >
                                            {pageData.cancelText}
                                        </Link>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <FormFields fields={pageData.fields} />
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col xs="12">
                            <p className={styles.headingText}>
                                {pageData.diaryText}
                            </p>
                        </Col>
                        <Col xs="12">
                            <p className={styles.headingText}>
                                {pageData.defaultQuestionsText}
                            </p>
                        </Col>
                        <Col xs="12">
                            {editedDefaultQuestions.map((question) => (
                                <Question
                                    key={question.descriptor.fieldtext}
                                    question={question}
                                    answerTypes={pageData.answerTypes}
                                    editMode={false}
                                    texts={{
                                        yesText: pageData.yesText,
                                        noText: pageData.noText,
                                        requiredText: pageData.requiredText,
                                        activeText: pageData.activeText,
                                        answerTypeText: pageData.answerTypeText,
                                        deleteText: pageData.deleteText,
                                        cancelText: pageData.cancelText,
                                    }}
                                    fields={pageData.fields}
                                />
                            ))}
                        </Col>
                        <Col xs="12">
                            <hr className={styles.divider} />
                        </Col>
                        <Col xs="12">
                            <p className={styles.headingText}>
                                {pageData.customQuestionsText}
                            </p>
                        </Col>
                        <Col xs="12">
                            <Reorder.Group
                                as="div"
                                axis="y"
                                values={editedCustomQuestions}
                                onReorder={setEditedCustomQuestions}
                                className={`col-xs-12`}
                            >
                                {editedCustomQuestions.map((question) => (
                                    <Question
                                        key={question.descriptor.fieldtext}
                                        question={question}
                                        answerTypes={pageData.answerTypes}
                                        editMode={false}
                                        texts={{
                                            yesText: pageData.yesText,
                                            noText: pageData.noText,
                                            requiredText: pageData.requiredText,
                                            activeText: pageData.activeText,
                                            answerTypeText:
                                                pageData.answerTypeText,
                                            deleteText: pageData.deleteText,
                                            cancelText: pageData.cancelText,
                                        }}
                                        fields={pageData.fields}
                                    />
                                ))}
                            </Reorder.Group>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    } else {
        return <></>;
    }
}

DeleteDiary.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

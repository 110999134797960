import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardHeader, CardBody, Button, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import pageConfig from './page.config';
import { setLoading } from '../../../../store/actions/loadingActions';
import {
    fetchTrialPlan,
    createNewTrialSubscription,
} from '../../../../services/api/account';
import { currencyFormatter } from '../../../../helpers/formatters';
import history from '../../../../services/history';

CreateTrialSubscription.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function CreateTrialSubscription({ currentUser }) {
    const [plan, setPlan] = useState(null);
    const [creatingSubscription, setCreatingSubscription] = useState(false);
    const [planType, setPlanType] = useState(null);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);
    const isLoading = useSelector((state) => state.loading.isLoading);
    const { currencyLocale, currencyFormat } = useSelector(
        (state) => state.locale
    );

    const pageData = pageConfig[lang][currentUser.permissions];

    async function handleClick() {
        setCreatingSubscription(true);

        const response = await createNewTrialSubscription(
            plan.id,
            currentUser?.subscription?.locale ?? currencyLocale
        );

        if (response) {
            toast.success(pageData.successText);

            history.push('/account/my_account');
        }

        setCreatingSubscription(false);
    }

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            const tempPlanType = history.location.state?.planType;

            setPlanType(tempPlanType);

            if (
                currentUser.subscription.plans.find(
                    (plan) => plan.type === tempPlanType
                )
            ) {
                toast.warning(pageData.userAlreadyHasSubscriptionText, {
                    autoClose: 10000,
                });

                history.push('/account/my_account');

                return;
            }

            const data = await fetchTrialPlan(tempPlanType);

            setPlan(data?.plan);

            dispatch(setLoading(false));
        })();
    }, []);

    if (isLoading || plan === null) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody className={styles.container}>
                <div className={styles.planItem}>
                    <Card className={styles.planItemCard}>
                        <CardHeader className={styles.planItemCardHeader}>
                            <div className={styles.planItemCardHeaderContent}>
                                <span>{plan.name}</span>
                            </div>
                        </CardHeader>
                        <CardBody className={styles.planItemCardBody}>
                            <div className={styles.planItemCardPrice}>
                                <div className={styles.price}>
                                    <h3>
                                        {currencyFormatter(
                                            plan.price,
                                            currencyLocale,
                                            currencyFormat
                                        )}
                                    </h3>
                                    <small>
                                        <b>
                                            {
                                                pageData.monthlyPaymentFrequencyText
                                            }
                                        </b>
                                    </small>
                                </div>
                                <div className={styles.fidelity}>
                                    <p>
                                        {pageData.planSubscriptionDurationText}
                                    </p>
                                </div>
                            </div>

                            <ul className={styles.planItemCardInfo}>
                                <li>
                                    {planType === 'sleep_diary'
                                        ? pageData.amountOfPatientsText
                                        : pageData.amountOfDevicesText}
                                </li>
                            </ul>
                        </CardBody>
                        <div className={styles.planItemCardAction}>
                            <Button color="primary" onClick={handleClick}>
                                {creatingSubscription && <Spinner size="sm" />}{' '}
                                {pageData.signSubscriptionText}
                            </Button>
                        </div>
                    </Card>
                </div>
            </CardBody>
        </Card>
    );
}

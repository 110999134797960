import Input from '../../../components/inputForm/inputText';

export default {
    pt: {
        customer_admin: null,
        manager: {
            nameText: 'Nome',
            secondaryIdText: 'ID Secundário',
            emailText: 'e-Mail',
            activeText: 'Ativo',
            sharePatientsText: 'Compartilha pacientes?',
            yesText: 'Sim',
            noText: 'Não',
            deleteText: 'Excluir',
            cancelText: 'Cancelar',
            successText: 'Gestor excluído com sucesso!',
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            nameText: 'Name',
            secondaryIdText: 'Secondary',
            emailText: 'e-Mail',
            activeText: 'Active',
            sharePatientsText: 'Share patients?',
            yesText: 'Yes',
            noText: 'No',
            deleteText: 'Delete',
            cancelText: 'Cancel',
            successText: 'Manager successfully deleted!',
        },
        condor_admin: null,
    },
};

import Input from '../../../components/inputForm/inputText';
import Select from '../../../components/inputForm/inputSelect';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: {
                company: [
                    {
                        component: Input,
                        props: {
                            name: 'name',
                            type: 'text',
                            label: 'Nome <b style="color: darkred">*</b>',
                            placeholder: 'Digite o nome da empresa...',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'share',
                            label: 'Compartilha pacientes? <b style="color: darkred">*</b>',
                            options: [
                                { value: true, label: 'Sim' },
                                { value: false, label: 'Não' },
                            ],
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'descriptor.saveEmail',
                            label: 'Salvar e-Mail de pacientes? <b style="color: darkred">*</b>',
                            options: [
                                { value: true, label: 'Sim' },
                                { value: false, label: 'Não' },
                            ],
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'descriptor.defaultLanguage',
                            label: 'Idioma padrão <b style="color: darkred">*</b>',
                            options: [
                                { value: 'pt', label: 'Português' },
                                { value: 'en', label: 'Inglês' },
                            ],
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'descriptor.minPasswordLength',
                            type: 'number',
                            label: 'Tamanho mínimo de senha <b style="color: darkred">*</b>',
                            min: 8,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                user: [
                    {
                        component: Input,
                        props: {
                            name: 'user_name',
                            type: 'text',
                            label: 'Nome <b style="color: darkred">*</b>',
                            placeholder: 'Digite o nome do usuário...',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'secondary_id',
                            type: 'text',
                            label: 'ID Secundário',
                            placeholder: 'Digite o id secundário do usuário...',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'email',
                            type: 'text',
                            label: 'e-Mail <b style="color: darkred">*</b>',
                            placeholder: 'Digite o e-Mail do usuário...',
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'role_id',
                            label: 'Tipo de usuário <b style="color: darkred">*</b>',
                            options: [
                                { value: 4, label: 'Gestor Administrador' },
                            ],
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'active',
                            label: 'Ativo <b style="color: darkred">*</b>',
                            options: [
                                { value: true, label: 'Sim' },
                                { value: false, label: 'Não' },
                            ],
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'plan',
                            label: 'Plano <b style="color: darkred">*</b>',
                            options: [
                                { value: 'Trial 30', label: 'Trial 30' },
                                { value: 'Trial 60', label: 'Trial 60' },
                                { value: 'Unlimited', label: 'Unlimited' },
                                {
                                    value: 'Lumus Unlimited',
                                    label: 'Lumus Unlimited',
                                },
                                {
                                    value: 'Lumus Trial 30',
                                    label: 'Lumus Trial 30',
                                },
                            ],
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
            },
            companySpanText: 'Empresa',
            userSpanText: 'Gestor Administrador',
            addSuccessText: 'Empresa adicionada com sucesso!',
            addButtonText: 'Adicionar',
            cancelButtonText: 'Cancelar',
            requiredFieldPText:
                '<b style="color: darkred">*</b> Campos obrigatórios!',
            yesText: 'Sim',
            noText: 'Não',
            roleIdOptions: {
                manager: { value: 4, label: 'Gestor Administrador' },
            },
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: {
                company: [
                    {
                        component: Input,
                        props: {
                            name: 'name',
                            type: 'text',
                            label: 'Name <b style="color: darkred">*</b>',
                            placeholder: `Enter the company name...`,
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'share',
                            label: 'Share patients? <b style="color: darkred">*</b>',
                            options: [
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                            ],
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'descriptor.saveEmail',
                            label: 'Save patient e-Mails? <b style="color: darkred">*</b>',
                            options: [
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                            ],
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'descriptor.defaultLanguage',
                            label: 'Default language <b style="color: darkred">*</b>',
                            options: [
                                { value: 'pt', label: 'Portuguese' },
                                { value: 'en', label: 'English' },
                            ],
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'descriptor.minPasswordLength',
                            type: 'number',
                            label: 'Minimum password length <b style="color: darkred">*</b>',
                            min: 8,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
                user: [
                    {
                        component: Input,
                        props: {
                            name: 'user_name',
                            type: 'text',
                            label: 'Nome <b style="color: darkred">*</b>',
                            placeholder: `Enter the username...`,
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'secondary_id',
                            type: 'text',
                            label: 'Secondary ID',
                            placeholder: `Enter the secondary user id...`,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'email',
                            type: 'text',
                            label: 'e-Mail <b style="color: darkred">*</b>',
                            placeholder: `Enter the user's e-mail...`,
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'role_id',
                            label: 'User type <b style="color: darkred">*</b>',
                            options: [{ value: 4, label: 'Manager Admin' }],
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'active',
                            label: 'Active <b style="color: darkred">*</b>',
                            options: [
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                            ],
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'plan',
                            label: 'Plan <b style="color: darkred">*</b>',
                            options: [
                                { value: 'Trial 30', label: 'Trial 30' },
                                { value: 'Trial 60', label: 'Trial 60' },
                                { value: 'Unlimited', label: 'Unlimited' },
                                {
                                    value: 'Lumus Unlimited',
                                    label: 'Lumus Unlimited',
                                },
                                {
                                    value: 'Lumus Trial 30',
                                    label: 'Lumus Trial 30',
                                },
                            ],
                        },
                        className: 'col-xs-12 col-md-3',
                    },
                ],
            },
            companySpanText: 'Company',
            userSpanText: 'Manager Admin',
            addSuccessText: 'Company successfully added!',
            addButtonText: 'Add',
            cancelButtonText: 'Cancel',
            requiredFieldPText:
                '<b style="color: darkred">*</b> Required fields!',
            yesText: 'Yes',
            noText: 'No',
            roleIdOptions: {
                manager: { value: 4, label: 'Manager Admin' },
            },
        },
    },
};

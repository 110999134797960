import React from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';

import Card from '../../../../components/card/card';
import Table from '../../../../components/table/table';
import Td from '../../../../components/table/td';

Tables.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    textData: PropTypes.object.isRequired,
};

export function Tables({ data, textData }) {
    const { sleepStatisticsTitle, napStatisticsTitle } = textData;

    if (data === null || data.length === 0) {
        return <></>;
    }

    return (
        <>
            <Col xs="12" className="reportTable">
                <Card
                    title={sleepStatisticsTitle}
                    isTable={true}
                    textSize="lg"
                    textCenter
                >
                    <Table>
                        <thead>
                            <tr>
                                <th>{textData.weekText}</th>
                                <th>{textData.bedTimeText}</th>
                                <th>{textData.trySleepStartText}</th>
                                <th>{textData.getUpTimeText}</th>
                                <th>{textData.timeInBedText}</th>
                                <th>{textData.sleepDurationText}</th>
                                <th>{textData.sleepEfficiencyText}</th>
                                <th>{textData.latencyDurationText}</th>
                                <th>{textData.nOfAwkText}</th>
                                <th>{textData.wasoText}</th>
                                <th>{textData.inertiaText}</th>
                                <th>{textData.sleepSatisfactionText}</th>
                                <th>{textData.usesMedicineText}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {data.map((rowData, index) => (
                                <tr key={`average_sleep_table_${index}`}>
                                    <Td
                                        title={textData.weekText}
                                        data-cy={`average_sleep_table_week_${index}`}
                                    >
                                        {rowData.week}
                                    </Td>
                                    <Td
                                        title={textData.bedTimeText}
                                        data-cy={`average_sleep_table_bedTime_${index}`}
                                    >
                                        {rowData.bedTime}
                                    </Td>
                                    <Td
                                        title={textData.trySleepStartText}
                                        data-cy={`average_sleep_table_trySleepStart_${index}`}
                                    >
                                        {rowData.trySleepStart}
                                    </Td>
                                    <Td
                                        title={textData.getUpTimeText}
                                        data-cy={`average_sleep_table_getUpTime_${index}`}
                                    >
                                        {rowData.getUpTime}
                                    </Td>
                                    <Td
                                        title={textData.timeInBedText}
                                        data-cy={`average_sleep_table_timeInBed_${index}`}
                                    >
                                        {rowData.timeInBed}
                                    </Td>
                                    <Td
                                        title={textData.sleepDurationText}
                                        data-cy={`average_sleep_table_sleepDuration_${index}`}
                                    >
                                        {rowData.sleepDuration}
                                    </Td>
                                    <Td
                                        title={textData.sleepEfficiencyText}
                                        data-cy={`average_sleep_table_sleepEfficiency_${index}`}
                                    >
                                        {rowData.sleepEfficiency}
                                    </Td>
                                    <Td
                                        title={textData.latencyDurationText}
                                        data-cy={`average_sleep_table_latencyDuration_${index}`}
                                    >
                                        {rowData.latencyDuration}
                                    </Td>
                                    <Td
                                        title={textData.nOfAwkText}
                                        data-cy={`average_sleep_table_nOfAwk_${index}`}
                                    >
                                        {rowData.nOfAwk}
                                    </Td>
                                    <Td
                                        title={textData.wasoText}
                                        data-cy={`average_sleep_table_WASO_${index}`}
                                    >
                                        {rowData.WASO}
                                    </Td>
                                    <Td
                                        title={textData.inertiaText}
                                        data-cy={`average_sleep_table_inertia_${index}`}
                                    >
                                        {rowData.inertia}
                                    </Td>
                                    <Td
                                        title={textData.sleepSatisfactionText}
                                        data-cy={`average_sleep_table_sleepSatisfaction_${index}`}
                                    >
                                        {rowData.sleepSatisfaction}
                                    </Td>
                                    <Td
                                        title={textData.usesMedicineText}
                                        data-cy={`average_sleep_table_usesMedicine_${index}`}
                                    >
                                        {rowData.usesMedicine}
                                    </Td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card>
            </Col>

            <Col xs="12" className="reportTable">
                <Card
                    title={napStatisticsTitle}
                    isTable={true}
                    textSize="lg"
                    textCenter
                >
                    <Table>
                        <thead>
                            <tr>
                                <th>{textData.weekText}</th>
                                <th>{textData.numberOfNapsText}</th>
                                <th>{textData.totalNapsTimeText}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {data.map((rowData, index) => (
                                <tr key={`average_naps_table_${index}`}>
                                    <Td
                                        title={textData.weekText}
                                        data-cy={`average_naps_table_week_${index}`}
                                    >
                                        {rowData.week}
                                    </Td>
                                    <Td
                                        title={textData.numberOfNapsText}
                                        data-cy={`average_naps_table_nOfNaps_${index}`}
                                    >
                                        {rowData.nOfNaps}
                                    </Td>
                                    <Td
                                        title={textData.totalNapsTimeText}
                                        data-cy={`average_naps_table_TNT_${index}`}
                                    >
                                        {rowData.TNT}
                                    </Td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card>
            </Col>
        </>
    );
}

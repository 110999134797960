import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, FormGroup } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import {
    CardNumberElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import styles from './form.module.scss';

import { validateForm } from '../../../../helpers/formValidator';
import { currencyFormatter } from '../../../../helpers/formatters';
import { setLoading } from '../../../../store/actions/loadingActions';
import FormRow from '../../../../components/form/formRow';
import { IuguCreditCardForm } from '../../../../components/form/iuguCreditCardForm';
import { StripeForm } from '../../../../components/form/stripeCreditCardForm';
import {
    createTrialSubscription,
    createIuguSubscriptionIntent,
    createStripeSubscriptionIntent,
} from '../../../../services/api/register';
import history from '../../../../services/history';
import useIugu from '../../../../hooks/useIugu';
import useWindowSize from '../../../../hooks/useWindowSize';

RegisterForm.propTypes = {
    pageData: PropTypes.object.isRequired,
    schema: PropTypes.object.isRequired,
    plan: PropTypes.object.isRequired,
    price: PropTypes.number.isRequired,
    showCreditCardFields: PropTypes.bool.isRequired,
    setFilledInForm: PropTypes.func.isRequired,
    setCardNumberComplete: PropTypes.func.isRequired,
    setCardExpiryComplete: PropTypes.func.isRequired,
    setCardCvcComplete: PropTypes.func.isRequired,
    formReady: PropTypes.bool.isRequired,
    handleRecaptchaChange: PropTypes.func.isRequired,
    notARobot: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    locale: PropTypes.object.isRequired,
    planFields: PropTypes.array.isRequired,
    companyFields: PropTypes.array.isRequired,
};

export function RegisterForm({
    pageData,
    schema,
    plan,
    price,
    showCreditCardFields,
    setFilledInForm,
    setCardNumberComplete,
    setCardExpiryComplete,
    setCardCvcComplete,
    formReady,
    handleRecaptchaChange,
    notARobot,
    lang,
    locale,
    planFields,
    companyFields,
}) {
    const iugu = useIugu();
    const stripe = useStripe();
    const elements = useElements();
    const windowSize = useWindowSize();

    const [userIuguFlowData, setUserIuguFlowData] = useState(null);
    const [userStripeFlowData, setUserStripeFlowData] = useState(null);

    const formRef = useRef(null);

    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.loading.isLoading);

    function handleKeyUpForm() {
        const formData = formRef.current.getData();

        let empty = 0;

        for (let key in formData) {
            if (formData[key] === '') {
                empty += 1;
            }
        }

        setFilledInForm(empty ? false : true);
    }

    async function handleSubmit(data, { reset }, event) {
        event.preventDefault();

        const { parsedData, isValid, errors } = await validateForm(
            data,
            plan.name.includes('Trial')
                ? schema.trial
                : plan.type === 'sleep_diary'
                ? schema.sleep_diary
                : schema.lumus
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(setLoading(true));

            let isSuccess = false;

            const split = plan.name.split(' ');

            let fidelity = '';

            if (split.length > 1) {
                if (split[1] === '6') {
                    fidelity = 'biannual';
                } else if (split[1] === '12') {
                    fidelity = 'annual';
                }
            } else {
                fidelity = 'monthly';
            }

            const sendData = {
                company_name: parsedData.company_name,
                name: parsedData.name,
                email: parsedData.email,
                share: parsedData.share,
                password: parsedData.password,
                type: parsedData.type,
                fidelity,
                number_of_devices: parsedData?.number_of_devices,
                lang,
                locale: locale.currencyLocale,
                plan_id: plan.id,
            };

            if (plan.name.toLowerCase().includes('trial')) {
                const response = await createTrialSubscription(sendData);

                dispatch(setLoading(false));

                if (response) {
                    isSuccess = true;
                }
            } else {
                if (locale.currencyFormat === 'BRL') {
                    // avoid sending the form if iugu script has not yet been loaded
                    if (!iugu.impl()) {
                        dispatch(setLoading(false));

                        return;
                    }

                    // check if user has already started iugu subscription flow
                    if (userIuguFlowData) {
                        for (const [key, value] of Object.entries(
                            userIuguFlowData
                        )) {
                            sendData[key] = value;
                        }
                    }

                    // create iugu credit card token
                    const createTokenResponse = await iugu.createPaymentToken(
                        event.target
                    );

                    if (createTokenResponse?.errors) {
                        dispatch(setLoading(false));

                        if ('incorrect_number' in createTokenResponse.errors) {
                            toast.error(
                                pageData.iuguErrorsText.numberErrorText
                            );
                        } else if (
                            'incorrect_cvv' in createTokenResponse.errors
                        ) {
                            toast.error(pageData.iuguErrorsText.cvvErrorText);
                        } else if (
                            'incorrect_fullName' in createTokenResponse.errors
                        ) {
                            toast.error(
                                pageData.iuguErrorsText.fullNameErrorText
                            );
                        } else if (
                            'incorrect_expiration' in createTokenResponse.errors
                        ) {
                            toast.error(
                                pageData.iuguErrorsText.expirationErrorText
                            );
                        } else {
                            toast.error(
                                pageData.iuguErrorsText.defaultErrorText
                            );
                        }

                        return;
                    }

                    sendData.token = createTokenResponse.id;

                    // create iugu subscription intent
                    const response = await createIuguSubscriptionIntent(
                        sendData
                    );

                    dispatch(setLoading(false));

                    if (response) {
                        if (
                            response?.companyId !== null &&
                            response?.userIuguId !== null &&
                            response?.userId !== null &&
                            response?.paymentMethodStatus === 'succeeded' &&
                            response?.subscriptionId !== null &&
                            response?.subscriptionIuguId !== null
                        ) {
                            isSuccess = true;
                        } else {
                            toast.error(pageData?.failedRegister);

                            setUserIuguFlowData({ ...response });

                            return;
                        }
                    }
                } else {
                    // avoid sending the form if stripe promise has not yet been fulfilled
                    if (!stripe || !elements) {
                        dispatch(setLoading(false));

                        return;
                    }

                    // check if user has already started stripe subscription flow
                    if (userStripeFlowData) {
                        for (const [key, value] of Object.entries(
                            userStripeFlowData
                        )) {
                            sendData[key] = value;
                        }
                    }

                    // create stripe subscription intent
                    let tempClientSecret;

                    if (!userStripeFlowData?.clientSecret) {
                        sendData.price_id = plan.stripe_id;

                        const response = await createStripeSubscriptionIntent(
                            sendData
                        );

                        if (response) {
                            setUserStripeFlowData({ ...response });

                            if (
                                response?.companyId !== null &&
                                response?.userStripeId !== null &&
                                response?.userId !== null &&
                                response?.subscriptionStripeId !== null &&
                                response?.subscriptionId !== null &&
                                response?.clientSecret !== null
                            ) {
                                tempClientSecret = response.clientSecret;
                            } else {
                                toast.error(pageData?.failedRegister);

                                dispatch(setLoading(false));

                                return;
                            }
                        } else {
                            dispatch(setLoading(false));

                            return;
                        }
                    }

                    // confirm user payment on stripe
                    const result = await stripe.confirmCardPayment(
                        tempClientSecret ?? userStripeFlowData.clientSecret,
                        {
                            payment_method: {
                                card: elements.getElement(CardNumberElement),
                                billing_details: {
                                    name: sendData.company_name,
                                },
                            },
                        }
                    );

                    dispatch(setLoading(false));

                    if (result.error) {
                        if (result.error.code in pageData?.stripeErrors) {
                            if (
                                result.error.code === 'card_declined' &&
                                result.error.decline_code in
                                    pageData?.stripeErrors.card_decline_codes
                            ) {
                                toast.error(
                                    pageData?.stripeErrors?.card_decline_codes[
                                        result.error.decline_code
                                    ]
                                );
                            } else {
                                toast.error(
                                    pageData?.stripeErrors[result.error.code]
                                );
                            }
                        } else {
                            toast.error(pageData?.paymentFailed);
                        }

                        return;
                    } else {
                        if (result.paymentIntent.status === 'succeeded') {
                            isSuccess = true;
                        }
                    }
                }
            }

            if (isSuccess) {
                toast.success(pageData.successRegister, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: false,
                    style:
                        windowSize.width <= 480
                            ? {}
                            : {
                                  width: '400px',
                                  height: '100px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginLeft: '-45px',
                              },
                });

                history.push('/login');

                return;
            }
        }
    }

    function handleNumberOfDevicesChange(newNumber) {
        const newNumberOfDevices = Number(newNumber);

        const unitPrice = getUnitPrice(plan.prices, newNumberOfDevices);

        formRef.current.setFieldValue(
            'unit_price',
            currencyFormatter(
                unitPrice,
                locale.currencyLocale,
                locale.currencyFormat
            )
        );

        formRef.current.setFieldValue(
            'price',
            currencyFormatter(
                unitPrice * newNumberOfDevices,
                locale.currencyLocale,
                locale.currencyFormat
            )
        );

        handleKeyUpForm();
    }

    function getUnitPrice(prices, numberOfDevices) {
        let unitPrice = 0;

        for (let i = 0; i < prices.length; i++) {
            if (numberOfDevices <= prices[i].up_to) {
                unitPrice =
                    locale.currencyLocale === 'pt-BR'
                        ? prices[i].price_brl
                        : prices[i].price_usd;

                break;
            }
        }

        return unitPrice;
    }

    useEffect(() => {
        formRef.current.reset();

        const unitPrice = getUnitPrice(plan.prices, 1);

        formRef.current.setData({
            plan:
                plan.type === 'lumus' && !plan.name.includes('Trial')
                    ? `${plan.name} - ${pageData.fromText} ${currencyFormatter(
                          plan.prices[0][
                              locale.currencyLocale === 'pt-BR'
                                  ? 'price_brl'
                                  : 'price_usd'
                          ],
                          locale.currencyLocale,
                          locale.currencyFormat
                      )}`
                    : plan.name,
            max_patients: plan.max_patients,
            number_of_devices: plan.name.toLowerCase().includes('trial')
                ? 5
                : 1,
            unit_price:
                plan.type === 'lumus'
                    ? plan.name.toLowerCase().includes('trial')
                        ? currencyFormatter(
                              0,
                              locale.currencyLocale,
                              locale.currencyFormat
                          )
                        : currencyFormatter(
                              unitPrice,
                              locale.currencyLocale,
                              locale.currencyFormat
                          )
                    : currencyFormatter(
                          price,
                          locale.currencyLocale,
                          locale.currencyFormat
                      ),
            price:
                plan.type === 'lumus'
                    ? plan.name.toLowerCase().includes('trial')
                        ? currencyFormatter(
                              0,
                              locale.currencyLocale,
                              locale.currencyFormat
                          )
                        : currencyFormatter(
                              unitPrice,
                              locale.currencyLocale,
                              locale.currencyFormat
                          )
                    : currencyFormatter(
                          price,
                          locale.currencyLocale,
                          locale.currencyFormat
                      ),
        });
    }, []);

    return (
        <Form
            ref={formRef}
            onSubmit={handleSubmit}
            onKeyUp={handleKeyUpForm}
            className={`${styles.registerForm}`}
        >
            <FormRow>
                <FormGroup className="col-md-12 text-center">
                    <h5>{pageData.formLabel}</h5>
                </FormGroup>
            </FormRow>
            {companyFields.map((field) => {
                switch (field.props.name) {
                    case 'share': {
                        return (
                            <FormRow key={field.props.name}>
                                <FormGroup
                                    className={`${field.className} ${styles.registerRadioContainer}`}
                                >
                                    <field.component {...field.props} />
                                </FormGroup>
                            </FormRow>
                        );
                    }
                    default: {
                        return (
                            <FormRow key={field.props.name}>
                                <FormGroup className={field.className}>
                                    <field.component {...field.props} />
                                </FormGroup>
                            </FormRow>
                        );
                    }
                }
            })}
            {planFields.map((field) => {
                switch (field.props.name) {
                    case 'number_of_devices': {
                        return plan.type === 'lumus' ? (
                            <FormRow key={field.props.name}>
                                <FormGroup className={field.className}>
                                    <field.component
                                        {...field.props}
                                        onChange={(e) =>
                                            handleNumberOfDevicesChange(
                                                e?.target?.value
                                            )
                                        }
                                        disabled={plan.name.includes('Trial')}
                                    />
                                </FormGroup>
                            </FormRow>
                        ) : null;
                    }
                    case 'unit_price': {
                        return plan.type === 'lumus' ? (
                            <FormRow key={field.props.name}>
                                <FormGroup className={field.className}>
                                    <field.component {...field.props} />
                                </FormGroup>
                            </FormRow>
                        ) : null;
                    }
                    default: {
                        return (
                            <FormRow key={field.props.name}>
                                <FormGroup className={field.className}>
                                    <field.component {...field.props} />
                                </FormGroup>
                            </FormRow>
                        );
                    }
                }
            })}
            {showCreditCardFields && locale.currencyFormat === 'BRL' && (
                <IuguCreditCardForm
                    fields={pageData.fields.new_card_info}
                    imgAltTexts={pageData.imgAltTexts}
                    creditCardLabelText={pageData.creditCardLabelText}
                    iugu={iugu}
                />
            )}
            {showCreditCardFields && locale.currencyFormat === 'USD' && (
                <StripeForm
                    creditCardLabelText={pageData.creditCardLabelText}
                    setCardNumberComplete={setCardNumberComplete}
                    setCardExpiryComplete={setCardExpiryComplete}
                    setCardCvcComplete={setCardCvcComplete}
                />
            )}
            {formReady && (
                <FormRow>
                    <FormGroup className="col-md-12 mt-3 d-flex justify-content-center">
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={handleRecaptchaChange}
                            hl={
                                locale.currencyLocale === 'pt-BR'
                                    ? 'pt-BR'
                                    : 'en'
                            }
                            size={
                                windowSize.width <= 450 ? 'compact' : 'normal'
                            }
                        />
                    </FormGroup>
                </FormRow>
            )}
            <FormRow>
                <Button
                    color="primary"
                    type="submit"
                    className="col-md-12 mt-4"
                    disabled={!notARobot || isLoading}
                >
                    {isLoading ? pageData.creating : pageData.createAccount}
                </Button>
            </FormRow>
            <FormRow>
                <FormGroup className="col-md-12 mt-3 text-center">
                    <span>
                        {pageData.alreadyHasAnAccount}
                        <Link to="/login">{pageData.accessIt}</Link>.
                    </span>
                </FormGroup>
            </FormRow>
        </Form>
    );
}

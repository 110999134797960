import React from 'react';
import { Link } from 'react-router-dom';
import { FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';

function FormFields({ fields }) {
    return fields.map((field) => {
        return (
            <FormGroup key={field.props.name} className={field.className}>
                <field.component {...field.props} />
                {field.hasLink && (
                    <span>
                        <b>
                            <Link to={field.to}>{field.linkText}</Link>
                        </b>
                    </span>
                )}
            </FormGroup>
        );
    });
}

FormFields.propTypes = {
    fields: PropTypes.array.isRequired,
};

export default FormFields;

import produce from 'immer';

import { REPORT } from '../constants';

const INITIAL_STATE = {
    reportData: {
        weekReference: {
            data: null,
            // loading: true,
            // status: 'pending',
        },
        calendar: {
            data: null,
            // loading: true,
            // status: 'pending',
        },
        weeklyEvolution: {
            data: null,
            // loading: true,
            // status: 'pending',
        },
        dailyEvolution: {
            data: null,
            // loading: true,
            // status: 'pending',
        },
        averageTable: {
            data: null,
            // loading: true,
            // status: 'pending',
        },
        editableTable: {
            data: null,
            // loading: true,
            // status: 'pending',
        },
        actogram: {
            data: null,
            // loading: true,
            // status: 'pending',
        },
        sleepScoring: {
            data: null,
            // loading: true,
            // status: 'pending',
        },
    },
    user: null,
    minDate: null,
    maxDate: null,
    daysInWeek: 7,
};

export default function report(state = INITIAL_STATE, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case REPORT.UPDATE_REPORT_REQUEST: {
                break;
            }
            case REPORT.UPDATE_REPORT: {
                draft.reportData = action.payload.reportData;
                draft.user = action.payload.user;
                draft.minDate = action.payload.minDate;
                draft.maxDate = action.payload.maxDate;
                draft.daysInWeek = action.payload.daysInWeek;
                break;
            }
            case REPORT.UPDATE_USER_REQUEST: {
                break;
            }
            case REPORT.UPDATE_USER: {
                draft.user = action.payload.data;
                break;
            }
            case REPORT.UPDATE_DATES_REQUEST: {
                break;
            }
            case REPORT.UPDATE_DATES: {
                draft.minDate = action.payload.data.minDate;
                draft.maxDate = action.payload.data.maxDate;
                break;
            }
            case REPORT.UPDATE_DAYS_IN_WEEK_REQUEST: {
                break;
            }
            case REPORT.UPDATE_DAYS_IN_WEEK: {
                draft.daysInWeek = action.payload.data;
                break;
            }
            case REPORT.UPDATE_WEEK_REFERENCE_REQUEST: {
                // draft.reportData.weekReference.loading = true;
                // draft.reportData.weekReference.status = 'pending';
                break;
            }
            case REPORT.UPDATE_WEEK_REFERENCE_SUCCESS: {
                // draft.user.trials_configurations.trialStart = action.payload.data.trialStart;
                draft.reportData.weekReference.data = action.payload.data;
                // draft.reportData.weekReference.loading = false;
                // draft.reportData.weekReference.status = action.payload.status;
                break;
            }
            case REPORT.UPDATE_WEEK_REFERENCE_FAILURE: {
                // draft.reportData.weekReference.loading = false;
                // draft.reportData.weekReference.status = action.payload.status;
                break;
            }
            case REPORT.UPDATE_CALENDAR_REQUEST: {
                // draft.reportData.calendar.loading = true;
                break;
            }
            case REPORT.UPDATE_CALENDAR_SUCCESS: {
                draft.reportData.calendar.data = action.payload.data;
                // draft.reportData.calendar.loading = false;
                // draft.reportData.calendar.status = action.payload.status;
                break;
            }
            case REPORT.UPDATE_CALENDAR_FAILURE: {
                // draft.reportData.calendar.loading = false;
                break;
            }
            case REPORT.UPDATE_WEEKLY_EVOLUTION_REQUEST: {
                // draft.reportData.weeklyEvolution.loading = true;
                break;
            }
            case REPORT.UPDATE_WEEKLY_EVOLUTION_SUCCESS: {
                draft.reportData.weeklyEvolution.data = action.payload.data;
                // draft.reportData.weeklyEvolution.loading = false;
                // draft.reportData.weeklyEvolution.status = action.payload.status;
                break;
            }
            case REPORT.UPDATE_WEEKLY_EVOLUTION_FAILURE: {
                // draft.reportData.weeklyEvolution.loading = false;
                // draft.reportData.weeklyEvolution.status = action.payload.status;
                break;
            }
            case REPORT.UPDATE_DAILY_EVOLUTION_REQUEST: {
                // draft.reportData.dailyEvolution.loading = true;
                break;
            }
            case REPORT.UPDATE_DAILY_EVOLUTION_SUCCESS: {
                draft.reportData.dailyEvolution.data = action.payload.data;
                // draft.reportData.dailyEvolution.loading = false;
                // draft.reportData.dailyEvolution.status = action.payload.status;
                break;
            }
            case REPORT.UPDATE_DAILY_EVOLUTION_FAILURE: {
                // draft.reportData.dailyEvolution.loading = true;
                break;
            }
            case REPORT.UPDATE_AVERAGE_TABLE_REQUEST: {
                // draft.reportData.averageTable.loading = true;
                break;
            }
            case REPORT.UPDATE_AVERAGE_TABLE_SUCCESS: {
                draft.reportData.averageTable.data = action.payload.data;
                // draft.reportData.averageTable.loading = false;
                // draft.reportData.averageTable.status = action.payload.status;
                break;
            }
            case REPORT.UPDATE_AVERAGE_TABLE_FAILURE: {
                // draft.reportData.averageTable.loading = true;
                break;
            }
            case REPORT.UPDATE_EDITABLE_TABLE_REQUEST: {
                // draft.reportData.editableTable.loading = true;
                break;
            }
            case REPORT.UPDATE_EDITABLE_TABLE_SUCCESS: {
                draft.reportData.editableTable.data = action.payload.data;
                // draft.reportData.editableTable.loading = false;
                // draft.reportData.editableTable.status = action.payload.status;
                break;
            }
            case REPORT.UPDATE_EDITABLE_TABLE_FAILURE: {
                // draft.reportData.editableTable.loading = true;
                break;
            }
            case REPORT.UPDATE_EDITABLE_TABLE_WEEKS_REQUEST: {
                // draft.reportData.editableTable.loading = true;
                break;
            }
            case REPORT.UPDATE_EDITABLE_TABLE_WEEKS_SUCCESS: {
                draft.reportData.editableTable.data.weeks = action.payload.data;
                // draft.reportData.editableTable.loading = false;
                // draft.reportData.editableTable.status = action.payload.status;
                break;
            }
            case REPORT.UPDATE_EDITABLE_TABLE_WEEKS_FAILURE: {
                // draft.reportData.editableTable.loading = true;
                break;
            }
            case REPORT.UPDATE_ACTOGRAM_REQUEST: {
                // draft.reportData.actogram.loading = true;
                break;
            }
            case REPORT.UPDATE_ACTOGRAM_SUCCESS: {
                draft.reportData.actogram.data = action.payload.data;
                // draft.reportData.actogram.loading = false;
                // draft.reportData.actogram.status = action.payload.status;
                break;
            }
            case REPORT.UPDATE_ACTOGRAM_FAILURE: {
                // draft.reportData.actogram.loading = true;
                break;
            }
            case REPORT.UPDATE_SLEEP_SCORING_REQUEST: {
                // draft.reportData.sleepScoring.loading = true;
                break;
            }
            case REPORT.UPDATE_SLEEP_SCORING_SUCCESS: {
                draft.reportData.sleepScoring.data = action.payload.data;
                // draft.reportData.sleepScoring.loading = false;
                // draft.reportData.sleepScoring.status = action.payload.status;
                break;
            }
            case REPORT.UPDATE_SLEEP_SCORING_FAILURE: {
                // draft.reportData.sleepScoring.loading = true;
                break;
            }
            case REPORT.RESET_STATE: {
                draft.reportData = INITIAL_STATE.reportData;
                draft.user = INITIAL_STATE.user;
                draft.minDate = INITIAL_STATE.minDate;
                draft.maxDate = INITIAL_STATE.maxDate;
                draft.daysInWeek = INITIAL_STATE.daysInWeek;

                break;
            }
        }
    });
}

import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            schema: Yup.object().shape({
                name: Yup.string(),
                price_brl: Yup.number(),
                price_usd: Yup.number(),
                discount: Yup.number(),
                max_patients: Yup.number(),
                interval: Yup.number(),
                interval_type: Yup.string(),
                max_cycles: Yup.number(),
                payable_with: Yup.string(),
                active: Yup.string(),
                visible: Yup.string(),
                type: Yup.string(),
            }),
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            schema: Yup.object().shape({
                name: Yup.string(),
                price_brl: Yup.number(),
                price_usd: Yup.number(),
                discount: Yup.number(),
                max_patients: Yup.number(),
                interval: Yup.number(),
                interval_type: Yup.string(),
                max_cycles: Yup.number(),
                payable_with: Yup.string(),
                active: Yup.string(),
                visible: Yup.string(),
                type: Yup.string(),
            }),
        },
    },
};

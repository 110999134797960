import Input from '../../../components/inputForm/inputText';

export default {
    pt: {
        customer_admin: null,
        manager: {
            fidelityInputText: {
                monthly: 'Mensal',
                biannual: 'Semestral',
                annual: 'Anual',
            },
            subscriptionTypeText: {
                sleep_diary: 'Diário de sono',
                lumus: 'Lumus',
            },
            cancelSubscriptionText: 'Cancelar assinatura',
            subscriptionAndBillingText: 'Assinatura e cobrança',
            plansText: 'Planos',
            changeDiaryPlanText: 'Alterar plano do diário',
            changeLumusPlanText: 'Alterar plano do lumus',
            changeSubscriptionText: 'Alterar plano do diário',
            signPlanText: 'Assinar plano',
            signDiaryPlanText: 'Assinar plano do diário',
            signLumusPlanText: 'Assinar plano do lumus',
            cancelSubscriptionButtonText: 'Cancelar assinatura',
            cancelButtonText: 'Voltar',
            activePatientsText: 'pacientes ativos',
            activeDevicesText: 'dispositivos ativos',
            unlimitedActivePatientsText: 'Pacientes ativos ilimitados',
            unlimitedActiveDevicesText: 'Dispositivos ativos ilimitados',
            monthlyPaymentFrequencyText: '/mês',
            testTrialDiaryPlanText: 'Testar plano trial de diário de sono',
            testTrialLumusPlanText: 'Testar plano trial de dispositivos lumus',
            expiresAtText: 'Expira em',
            activeText: 'Ativa',
            suspendedText: 'Suspensa',
            expiredText: 'Expirada',
            cancelText: 'Cancelar',
            attentionText: 'Atenção',
            closeText: 'Fechar',
            unsubscribeText: 'Cancelar assinatura',
            cancelSuccessText: `Assinatura cancelada com sucesso!
            Sua empresa pode utilizar funcionalidades relacionadas ao plano até`,
            monthlyWarningCancelText: `Ao cancelar, os usuários da empresa
            perderão acesso à funcionalidades do sistema após a expiração da assinatura!`,
            biannualWarningCancelText: `Sua empresa possui uma assinatura com fidelidade.
            Sendo assim, a assinatura continuará sendo cobrada. Para evitar essa cobrança,
            entre em contato para pagar uma multa de cancelamento.
            Ao cancelar, os usuários da empresa perderão acesso à funcionalidades do sistema
            após a expiração da assinatura!`,
            annualWarningCancelText: `Sua empresa possui uma assinatura com fidelidade.
            Sendo assim, a assinatura continuará sendo cobrada. Para evitar essa cobrança,
            entre em contato para pagar uma multa de cancelamento.
            Ao cancelar, os usuários da empresa perderão acesso à funcionalidades do sistema
            após a expiração da assinatura!`,
            wrongFormatText: 'Formato incorreto',
            changeEmailText: 'Alterar e-Mail',
            emailText: 'e-Mail',
            cardText: 'Cartão',
            managePaymentInformationText: 'Gerenciar informações de pagamento',
            priceText: 'Preço',
            subscriptionExpiresOnText: 'Assinatura expira em',
            nextBillingDateText: 'Próxima data de cobrança',
            payPendingInvoiceText: 'Pagar fatura pendente',
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            fidelityInputText: {
                monthly: 'Monthly',
                biannual: 'Semestral',
                annual: 'Annual',
            },
            subscriptionTypeText: {
                sleep_diary: 'Sleep diary',
                lumus: 'Lumus',
            },
            cancelSubscriptionText: 'Cancel subscription',
            subscriptionAndBillingText: 'Subscription and Billing',
            plansText: 'Plans',
            changeDiaryPlanText: 'Change sleep diary plan',
            changeLumusPlanText: 'Change lumus plan',
            changeSubscriptionText: 'Change sleep diary plan',
            signPlanText: 'Sign plan',
            signDiaryPlanText: 'Sign diary plan',
            signLumusPlanText: 'Sign lumus plan',
            cancelSubscriptionButtonText: 'Unsubscribe',
            cancelButtonText: 'Return',
            activePatientsText: 'active patients',
            activeDevicesText: 'active devices',
            unlimitedActivePatientsText: 'Unlimited active patients',
            unlimitedActiveDevicesText: 'Unlimited active devices',
            monthlyPaymentFrequencyText: '/mo',
            testTrialDiaryPlanText: 'Test trial plan for sleep diary',
            testTrialLumusPlanText: 'Test trial plan for lumus devices',
            expiresAtText: 'Expires at',
            activeText: 'Active',
            suspendedText: 'Suspended',
            expiredText: 'Expired',
            cancelText: 'Cancel',
            attentionText: 'Attention',
            closeText: 'Close',
            unsubscribeText: 'Unsubscribe',
            cancelSuccessText: `Subscription successfully canceled!
            Your company can use features related to the plan until`,
            monthlyWarningCancelText: `To unsubscribe, the company users
            will lose access to system functionality after the subscription expires!`,
            biannualWarningCancelText: `Your company has a loyalty subscription.
            Therefore, the subscription will continue to be charged. To avoid this charge,
            contact us to pay a cancellation fee.
            To unsubscribe, the company users
            will lose access to system functionality after the subscription expires!`,
            annualWarningCancelText: `Your company has a loyalty subscription.
            Therefore, the subscription will continue to be charged. To avoid this charge,
            contact us to pay a cancellation fee.
            To unsubscribe, the company users
            will lose access to system functionality after the subscription expires!`,
            wrongFormatText: 'Incorrect format',
            changeEmailText: 'Change e-Mail',
            emailText: 'e-Mail',
            cardText: 'Card',
            managePaymentInformationText: 'Manage payment information',
            priceText: 'Price',
            subscriptionExpiresOnText: 'Subscription expires on',
            nextBillingDateText: 'Next billing date',
            payPendingInvoiceText: 'Pay pending invoice',
        },
        condor_admin: null,
    },
};

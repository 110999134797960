import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import FormFields from '../../../../components/form/formFields';
import { setLoading } from '../../../../store/actions/loadingActions';
import { deleteCompany } from '../../../../services/api/companies';
import history from '../../../../services/history';

MyForm.propTypes = {
    company: PropTypes.object.isRequired,
    fields: PropTypes.array.isRequired,
    texts: PropTypes.shape({
        successText: PropTypes.string.isRequired,
        deleteText: PropTypes.string.isRequired,
        cancelText: PropTypes.string.isRequired,
        yesText: PropTypes.string.isRequired,
        noText: PropTypes.string.isRequired,
        notDefinedText: PropTypes.string.isRequired,
        defaultLanguageOptions: PropTypes.shape({
            pt: PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            }).isRequired,
            en: PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
};

export function MyForm({ company, fields, texts }) {
    const formRef = useRef(null);

    const isFirstRender = useRef(true);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    async function handleSubmit(data) {
        dispatch(setLoading(true));

        const response = await deleteCompany(company.id);

        dispatch(setLoading(false));

        if (response) {
            toast.success(texts.successText);

            history.push('/companies');
        }
    }

    useEffect(() => {
        let initialData = {};

        if (isFirstRender.current) {
            isFirstRender.current = false;

            formRef.current.reset();

            initialData = {
                name: company.name,
                share: company.share ? texts.yesText : texts.noText,
                descriptor: {
                    saveEmail: company.descriptor.saveEmail
                        ? texts.yesText
                        : texts.noText,
                    defaultLanguage:
                        texts.defaultLanguageOptions[
                            company.descriptor.defaultLanguage
                        ]?.label ?? texts.notDefinedText,
                    minPasswordLength:
                        company.descriptor.minPasswordLength ?? 8,
                },
            };
        } else {
            initialData = {
                name: formRef.current.getFieldValue('name'),
                share: formRef.current.getFieldValue('share')
                    ? texts.yesText
                    : texts.noText,
                descriptor: {
                    saveEmail: formRef.current.getFieldValue(
                        'descriptor.saveEmail'
                    )
                        ? texts.yesText
                        : texts.noText,
                    defaultLanguage:
                        texts.defaultLanguageOptions[
                            company.descriptor.defaultLanguage
                        ]?.label ?? texts.notDefinedText,
                    minPasswordLength: formRef.current.getFieldValue(
                        'descriptor.minPasswordLength'
                    ),
                },
            };
        }

        formRef.current.setData(initialData);
    }, [lang]);

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <FormGroup row>
                <FormFields fields={fields} />
            </FormGroup>
            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button color="primary mr-2" type="submit">
                        {texts.deleteText}
                    </Button>
                    <Link to="/companies" className="btn btn-secondary">
                        {texts.cancelText}
                    </Link>
                </div>
            </FormGroup>
        </Form>
    );
}

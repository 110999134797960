import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';

import {
    currencyFormatter,
    localeDateFormatter,
} from '../../../../helpers/formatters';
import FormFields from '../../../../components/form/formFields';
import { SubscriptionPlanFields } from './subscriptionPlanFields';
import { CouponFields } from './couponFields';

MyForm.propTypes = {
    company: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    subscription: PropTypes.object.isRequired,
    setSubscription: PropTypes.func.isRequired,
    fields: PropTypes.object.isRequired,
    couponsFields: PropTypes.array.isRequired,
    schemas: PropTypes.object.isRequired,
    addCouponField: PropTypes.func.isRequired,
    removeCouponField: PropTypes.func.isRequired,
    currency: PropTypes.object.isRequired,
    texts: PropTypes.shape({
        companyText: PropTypes.string.isRequired,
        subscriptionText: PropTypes.string.isRequired,
        subscriptionPlansText: PropTypes.string.isRequired,
        couponsText: PropTypes.string.isRequired,
        addCouponText: PropTypes.string.isRequired,
        deleteCouponText: PropTypes.string.isRequired,
        applyCouponSuccessText: PropTypes.string.isRequired,
        updateCouponSuccessText: PropTypes.string.isRequired,
        removeCouponSuccessText: PropTypes.string.isRequired,
        editText: PropTypes.string.isRequired,
        cancelText: PropTypes.string.isRequired,
        yesText: PropTypes.string.isRequired,
        noText: PropTypes.string.isRequired,
        successText: PropTypes.string.isRequired,
        subscriptionTypeText: PropTypes.shape({
            sleep_diary: PropTypes.string.isRequired,
            lumus: PropTypes.string.isRequired,
        }).isRequired,
        actionOptionsTexts: PropTypes.shape({
            extend_trial: PropTypes.string.isRequired,
            activate_trial: PropTypes.string.isRequired,
            activate: PropTypes.string.isRequired,
            suspend: PropTypes.string.isRequired,
        }).isRequired,
        goBackText: PropTypes.string.isRequired,
        suspendedReasonsTexts: PropTypes.shape({
            trial_expired: PropTypes.string.isRequired,
            invalid_payment_method: PropTypes.string.isRequired,
            invoice_expired: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    coupons: PropTypes.array.isRequired,
    actionOptions: PropTypes.object.isRequired,
};

export function MyForm({
    company,
    user,
    subscription,
    setSubscription,
    fields,
    couponsFields,
    schemas,
    addCouponField,
    removeCouponField,
    currency,
    texts,
    coupons,
    actionOptions,
}) {
    const formRef = useRef(null);

    const { dateLocale } = useSelector((state) => state.locale);
    const lang = useSelector((state) => state.lang.lang);

    useEffect(() => {
        let suspendedMessage = null;

        if (subscription.suspended_reason) {
            if (subscription.suspended_reason.includes('-')) {
                const [suspendedReason, additionalMessage] =
                    subscription.suspended_reason.split('-');

                suspendedMessage = `${
                    texts.suspendedReasonsTexts[suspendedReason.trim()]
                } - ${additionalMessage.trim()}`;
            } else {
                suspendedMessage =
                    texts.suspendedReasonsTexts[subscription.suspended_reason];
            }
        }

        let initialData = {
            name: company.name,
            user_name: user.name,
            email: user.email,
            price: currencyFormatter(
                subscription.price,
                currency.locale,
                currency.format
            ),
            billing_date: subscription.billing_date
                ? localeDateFormatter(subscription.billing_date, dateLocale)
                    ? localeDateFormatter(subscription.billing_date, dateLocale)
                    : 'N/A'
                : 'N/A',
            expires_at: subscription.expires_at
                ? localeDateFormatter(subscription.expires_at, dateLocale)
                    ? localeDateFormatter(subscription.expires_at, dateLocale)
                    : 'N/A'
                : 'N/A',
            coupons: subscription.discounts.map((discount, index) => ({
                coupon_id: {
                    value: discount.coupon_id,
                    label: coupons.find((c) => c.id === discount.coupon_id)
                        .name,
                },
                month_to_remove: discount.month_to_remove,
                year_to_remove: discount.year_to_remove,
            })),
            suspended_reason: suspendedMessage,
            subscriptionPlans: subscription.plans.map((subscriptionPlan) => ({
                active: subscriptionPlan.active ? texts.yesText : texts.noText,
                suspended: subscriptionPlan.suspended
                    ? texts.yesText
                    : texts.noText,
                price: currencyFormatter(
                    subscriptionPlan.price,
                    currency.locale,
                    currency.format
                ),
                expires_at: subscriptionPlan.expires_at
                    ? subscriptionPlan.expires_at?.slice(0, 10)
                    : 'N/A',
                days_to_extend: 0,
            })),
        };

        formRef.current.setData(initialData);

        let active = formRef.current.getFieldValue('active');
        let suspended = formRef.current.getFieldValue('suspended');

        if (active !== undefined && active !== '') {
            formRef.current.setFieldValue('active', {
                label: active ? texts.yesText : texts.noText,
                value: active,
            });
        } else {
            formRef.current.setFieldValue('active', {
                label: subscription.active ? texts.yesText : texts.noText,
                value: subscription.active,
            });
        }

        if (suspended !== undefined && suspended !== '') {
            formRef.current.setFieldValue('suspended', {
                label: suspended ? texts.yesText : texts.noText,
                value: suspended,
            });
        } else {
            formRef.current.setFieldValue('suspended', {
                label: subscription.suspended ? texts.yesText : texts.noText,
                value: subscription.suspended,
            });
        }

        subscription.plans.forEach((subscriptionPlan, index) => {
            formRef.current.setFieldValue(
                `subscriptionPlans[${index}].plan_id`,
                {
                    label: subscriptionPlan.plan.name,
                    value: subscriptionPlan.plan.id,
                }
            );

            formRef.current.setFieldValue(`subscriptionPlans[${index}].type`, {
                label: texts.subscriptionTypeText[subscriptionPlan.type],
                value: subscriptionPlan.type,
            });
        });
    }, [lang, subscription]);

    return (
        <Form ref={formRef}>
            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-12">
                    <span>{texts.companyText}</span>
                </FormGroup>
            </FormGroup>

            <FormGroup row>
                <FormFields fields={fields.company} />
            </FormGroup>

            <hr />

            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-12">
                    <span>{texts.subscriptionText}</span>
                </FormGroup>
            </FormGroup>

            <FormGroup row>
                {fields.subscription.map((field) => {
                    if (field.props.name === 'suspended_reason') {
                        return (
                            subscription.suspended_reason && (
                                <FormGroup
                                    key={field.props.name}
                                    className={`${field.className} ${
                                        subscription.suspended_reason.includes(
                                            'invalid_payment_method'
                                        )
                                            ? 'col-md-6'
                                            : 'col-md-3'
                                    }`}
                                >
                                    <field.component {...field.props} />
                                </FormGroup>
                            )
                        );
                    } else {
                        return (
                            <FormGroup
                                key={field.props.name}
                                className={field.className}
                            >
                                <field.component {...field.props} />
                            </FormGroup>
                        );
                    }
                })}
            </FormGroup>

            <hr />

            <SubscriptionPlanFields
                formRef={formRef}
                subscription={subscription}
                setSubscription={setSubscription}
                fields={fields.subscriptionPlans}
                texts={{
                    subscriptionPlansText: texts.subscriptionPlansText,
                    editText: texts.editText,
                    cancelText: texts.cancelText,
                    yesText: texts.yesText,
                    noText: texts.noText,
                    actionOptionsTexts: texts.actionOptionsTexts,
                    successText: texts.successText,
                }}
                actionOptions={actionOptions}
                schemas={{
                    extendTrialSchema: schemas.extendTrialSchema,
                    activateTrialSchema: schemas.activateTrialSchema,
                    activateSchema: schemas.activateSchema,
                    suspendSchema: schemas.suspendSchema,
                }}
            />

            <CouponFields
                formRef={formRef}
                company={company}
                subscription={subscription}
                setSubscription={setSubscription}
                fields={couponsFields}
                schema={schemas.couponSchema}
                addCouponField={addCouponField}
                removeCouponField={removeCouponField}
                texts={{
                    couponsText: texts.couponsText,
                    addCouponText: texts.addCouponText,
                    deleteCouponText: texts.deleteCouponText,
                    applyCouponSuccessText: texts.applyCouponSuccessText,
                    updateCouponSuccessText: texts.updateCouponSuccessText,
                    removeCouponSuccessText: texts.removeCouponSuccessText,
                    editText: texts.editText,
                    cancelText: texts.cancelText,
                    successText: texts.successText,
                }}
                coupons={coupons}
            />

            <hr />

            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Link to="/companies" className="btn btn-secondary">
                        {texts.goBackText}
                    </Link>
                </div>
            </FormGroup>
        </Form>
    );
}

import React from 'react';
import PropTypes from 'prop-types';

import { TableWrapper } from './tableWrapper';
import { TitleSection } from './titleSection';
import { PageNumber } from './pageNumber';

export function Tables({ data, textData, pageNumbers }) {
    const { titleSectionText, sleepStatistics, napsStatistics } = textData;

    switch (true) {
        case data.length < 12:
            return (
                <>
                    <div className="pagePrint">
                        <div className="rowPrint">
                            <TitleSection title={titleSectionText} />
                            <TableWrapper
                                data={data}
                                titleText={sleepStatistics.titleText}
                                ths={sleepStatistics.ths}
                                tds={sleepStatistics.tds}
                            />
                        </div>

                        <div
                            className="rowPrint"
                            style={{ paddingTop: '10px' }}
                        >
                            <TableWrapper
                                data={data}
                                titleText={napsStatistics.titleText}
                                ths={napsStatistics.ths}
                                tds={napsStatistics.tds}
                            />
                        </div>

                        <PageNumber number={pageNumbers[0]} />
                    </div>
                </>
            );
        case data.length < 23:
            return (
                <>
                    <div className="pagePrint">
                        <div className="rowPrint">
                            <TitleSection title={titleSectionText} />
                            <TableWrapper
                                data={data}
                                titleText={sleepStatistics.titleText}
                                ths={sleepStatistics.ths}
                                tds={sleepStatistics.tds}
                            />
                        </div>

                        <PageNumber number={pageNumbers[0]} />
                    </div>

                    <div className="pagePrint">
                        <div className="rowPrint">
                            <TableWrapper
                                data={data}
                                titleText={napsStatistics.titleText}
                                ths={napsStatistics.ths}
                                tds={napsStatistics.tds}
                            />
                        </div>

                        <PageNumber number={pageNumbers[1]} />
                    </div>
                </>
            );
        default:
            return (
                <>
                    <div className="pagePrint">
                        <div className="rowPrint">
                            <TitleSection title={titleSectionText} />
                            <TableWrapper
                                data={data.slice(0, parseInt(data.length / 2))}
                                titleText={sleepStatistics.titleText}
                                ths={sleepStatistics.ths}
                                tds={sleepStatistics.tds}
                            />
                        </div>

                        <PageNumber number={pageNumbers[0]} />
                    </div>

                    <div className="pagePrint">
                        <div className="rowPrint">
                            <TitleSection title={titleSectionText} />
                            <TableWrapper
                                data={data.slice(parseInt(data.length / 2))}
                                titleText={sleepStatistics.titleText}
                                ths={sleepStatistics.ths}
                                tds={sleepStatistics.tds}
                            />
                        </div>

                        <PageNumber number={pageNumbers[1]} />
                    </div>

                    <div className="pagePrint">
                        <div className="rowPrint">
                            <TableWrapper
                                data={data.slice(0, parseInt(data.length / 2))}
                                titleText={napsStatistics.titleText}
                                ths={napsStatistics.ths}
                                tds={napsStatistics.tds}
                            />
                        </div>

                        <PageNumber number={pageNumbers[2]} />
                    </div>

                    <div className="pagePrint">
                        <div className="rowPrint">
                            <TableWrapper
                                data={data.slice(parseInt(data.length / 2))}
                                titleText={napsStatistics.titleText}
                                ths={napsStatistics.ths}
                                tds={napsStatistics.tds}
                            />
                        </div>

                        <PageNumber number={pageNumbers[3]} />
                    </div>
                </>
            );
    }
}

Tables.propTypes = {
    data: PropTypes.array.isRequired,
    textData: PropTypes.object.isRequired,
    pageNumbers: PropTypes.array.isRequired,
};

import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { Button, Card, CardBody, FormGroup } from 'reactstrap';

import styles from './styles.module.scss';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { validateForm } from '../../../helpers/formValidator';
import { setLang } from '../../../store/actions/langActions';
import { verify2faRequest, logout } from '../../../store/actions/authActions';
import FormRow from '../../../components/form/formRow';
import Input from '../../../components/inputForm/inputText';

export default function VerifyTwoFactorAuthenticationPage() {
    const formRef = useRef(null);

    const dispatch = useDispatch();

    const { currentUser, loading } = useSelector((state) => state.auth);

    const lang = useSelector((state) => state.lang.lang);

    const [activeLanguage, setActiveLanguage] = useState('pt');

    const pageData = pageConfig[lang];

    const { schema } = schemaConfig[lang];

    async function handleSubmit(data) {
        const { parsedData, isValid, errors } = await validateForm(
            data,
            schema
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(verify2faRequest(parsedData));
        }
    }

    function handleChangeLang(lang) {
        setActiveLanguage(lang);

        dispatch(setLang(lang));
    }

    function handleCancel2faFlow() {
        dispatch(logout());
    }

    return (
        <div className={styles.cardContainer}>
            <Card className={`shadow rounded ${styles.contentContainer}`}>
                <CardBody>
                    <div className="text-center mb-4">
                        <h2>
                            <b>{pageData.sleepDiaryText}</b>
                        </h2>
                    </div>

                    <div className="text-center mb-4">
                        <h4>
                            <b>{pageData.twoFactorAuthenticationText}</b>
                        </h4>
                    </div>

                    <div className="text-center mb-4">
                        <p>{pageData.insertCodeToCompleteLoginText}</p>
                    </div>

                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <FormRow>
                            <FormGroup className="col-md-12">
                                <Input
                                    name="otp_code"
                                    type="text"
                                    label={pageData.insertOtpCodeText}
                                />
                            </FormGroup>
                        </FormRow>

                        {currentUser.permissions === 'manager' && (
                            <FormRow>
                                <FormGroup className="col-md-12">
                                    <span>
                                        {pageData.accessToEmailText}{' '}
                                        {pageData.clickToInsertRecoveryCodeText}
                                    </span>
                                </FormGroup>
                            </FormRow>
                        )}

                        <FormRow>
                            <div className="d-flex justify-content-center w-100">
                                <Button
                                    color="primary"
                                    type="submit"
                                    className="mr-2"
                                    disabled={loading}
                                >
                                    {loading
                                        ? pageData.loadingText
                                        : pageData.verifyText}
                                </Button>

                                <Button
                                    type="button"
                                    color="secondary"
                                    disabled={loading}
                                    onClick={() => handleCancel2faFlow()}
                                >
                                    {pageData.cancelText}
                                </Button>
                            </div>
                        </FormRow>

                        <hr />

                        <FormRow>
                            <div
                                className={`col-md-12 ${styles.langContainer}`}
                            >
                                <button
                                    type="button"
                                    disabled={activeLanguage === 'pt'}
                                    name="pt"
                                    onClick={(e) =>
                                        handleChangeLang(e.target.name)
                                    }
                                >
                                    Português
                                </button>

                                <button
                                    type="button"
                                    disabled={activeLanguage === 'en'}
                                    name="en"
                                    onClick={(e) =>
                                        handleChangeLang(e.target.name)
                                    }
                                >
                                    English
                                </button>
                            </div>
                        </FormRow>
                    </Form>
                </CardBody>
            </Card>
        </div>
    );
}

import React from 'react';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardTitle,
    CardSubtitle,
    CardBody,
    CardFooter,
} from 'reactstrap';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import PropTypes from 'prop-types';

import { ChartLabel } from '../../../../components/chartLabel';

const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    name,
    system,
}) => {
    const RADIAN = Math.PI / 180;

    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill={index === 0 ? '#DC5318' : system ? '#18DCB5' : '#6DDC18'}
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline="central"
        >
            {`${name} ${(percent * 100).toFixed(2)}%`}
        </text>
    );
};

Memory.propTypes = {
    data: PropTypes.shape({
        systemMemoryUsageData: PropTypes.arrayOf(PropTypes.number),
        sensorsMemoryUsageData: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
    textData: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
};

export function Memory({ data, textData, visible }) {
    const { systemMemoryUsageData, sensorsMemoryUsageData } = data;

    const systemColors = ['#DC5318', '#18DCB5'];
    const sensorsColors = ['#DC5318', '#6DDC18'];

    const systemPlotData = systemMemoryUsageData?.map((value, index) => {
        if (index === 0) {
            return {
                name: textData.usedText,
                value,
                system: true,
            };
        } else {
            return {
                name: textData.availableText,
                value,
                system: true,
            };
        }
    });

    const sensorPlotData = sensorsMemoryUsageData?.map((value, index) => {
        if (index === 0) {
            return {
                name: textData.usedText,
                value,
                sensors: true,
            };
        } else {
            return {
                name: textData.availableText,
                value,
                sensors: true,
            };
        }
    });

    return (
        <>
            <Col xs="12" md="6">
                <Card className={`cardShadow`}>
                    <CardHeader className="">
                        <CardTitle className="">
                            <h5>{textData.systemMemoryUsageText}</h5>
                        </CardTitle>
                        <CardSubtitle className="">
                            {textData.systemMemoryUsageChartText}
                        </CardSubtitle>
                    </CardHeader>
                    <CardBody>
                        <Row className="">
                            <Col
                                xs="12"
                                className={`${
                                    systemMemoryUsageData && visible
                                        ? 'text-center chartContainer'
                                        : 'text-center'
                                }`}
                                style={{
                                    height: `${
                                        systemMemoryUsageData && visible
                                            ? '15rem'
                                            : ''
                                    }`,
                                }}
                            >
                                {systemMemoryUsageData && visible ? (
                                    <ResponsiveContainer
                                        width="100%"
                                        height="100%"
                                    >
                                        <PieChart width={400} height={400}>
                                            <Pie
                                                dataKey="value"
                                                data={systemPlotData}
                                                cx="50%"
                                                cy="50%"
                                                outerRadius={80}
                                                label={renderCustomizedLabel}
                                            >
                                                {systemPlotData.map(
                                                    (entry, index) => (
                                                        <Cell
                                                            key={`cell-${index}`}
                                                            fill={
                                                                systemColors[
                                                                    index %
                                                                        systemColors.length
                                                                ]
                                                            }
                                                        />
                                                    )
                                                )}
                                            </Pie>
                                            <Tooltip
                                                formatter={(value) =>
                                                    `${value.toFixed(2)} %`
                                                }
                                            />
                                        </PieChart>
                                    </ResponsiveContainer>
                                ) : (
                                    textData.noDataText
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <ChartLabel
                            name={textData.availableText}
                            color={'#18DCB5'}
                        />
                        <ChartLabel
                            name={textData.usedText}
                            color={'#DC5318'}
                        />
                    </CardFooter>
                </Card>
            </Col>
            <Col xs="12" md="6">
                <Card className={`cardShadow`}>
                    <CardHeader className="">
                        <CardTitle className="">
                            <h5>{textData.sensorsMemoryUsageText}</h5>
                        </CardTitle>
                        <CardSubtitle>
                            {textData.sensorsMemoryUsageChartText}
                        </CardSubtitle>
                    </CardHeader>
                    <CardBody>
                        <Row className="">
                            <Col
                                xs="12"
                                className={`${
                                    sensorsMemoryUsageData && visible
                                        ? 'text-center chartContainer'
                                        : 'text-center'
                                }`}
                                style={{
                                    height: `${
                                        sensorsMemoryUsageData && visible
                                            ? '15rem'
                                            : ''
                                    }`,
                                }}
                            >
                                {sensorsMemoryUsageData && visible ? (
                                    <ResponsiveContainer
                                        width="100%"
                                        height="100%"
                                    >
                                        <PieChart width={400} height={400}>
                                            <Pie
                                                dataKey="value"
                                                data={sensorPlotData}
                                                cx="50%"
                                                cy="50%"
                                                outerRadius={80}
                                                label={renderCustomizedLabel}
                                            >
                                                {sensorPlotData.map(
                                                    (entry, index) => (
                                                        <Cell
                                                            key={`cell-${index}`}
                                                            fill={
                                                                sensorsColors[
                                                                    index %
                                                                        sensorsColors.length
                                                                ]
                                                            }
                                                        />
                                                    )
                                                )}
                                            </Pie>
                                            <Tooltip
                                                formatter={(value) =>
                                                    `${value.toFixed(2)} %`
                                                }
                                            />
                                        </PieChart>
                                    </ResponsiveContainer>
                                ) : (
                                    textData.noDataText
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <ChartLabel
                            name={textData.availableText}
                            color={'#6DDC18'}
                        />
                        <ChartLabel
                            name={textData.usedText}
                            color={'#DC5318'}
                        />
                    </CardFooter>
                </Card>
            </Col>
        </>
    );
}

import React from 'react';
import PropTypes from 'prop-types';

import Td from '../../../../../../components/table/td';
import { currencyFormatter } from '../../../../../../helpers/formatters';

export function TableTds({ tds, plan, price, currency }) {
    return tds.map((td, index) => {
        switch (td.property) {
            case 'price':
                return (
                    <Td key={index} title={td.th}>
                        {currencyFormatter(
                            price,
                            currency.locale,
                            currency.format
                        )}
                    </Td>
                );
            default:
                return (
                    <Td key={index} title={td.th}>
                        {plan[td.property]}
                    </Td>
                );
        }
    });
}

TableTds.propTypes = {
    tds: PropTypes.array.isRequired,
    plan: PropTypes.object.isRequired,
    price: PropTypes.number.isRequired,
    currency: PropTypes.object.isRequired,
};

import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: {
            schema: Yup.object().shape({
                name: Yup.string(),
                secondary_id: Yup.string(),
                email: Yup.string().email('Formato inválido'),
                active: Yup.bool(),
                trialStart: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                trialEnd: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                devices: Yup.array().of(
                    Yup.object().shape({
                        device_id: Yup.string().required('Campo obrigatório'),
                        body_part: Yup.string().required('Campo obrigatório'),
                        received_at: Yup.date()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('Campo obrigatório'),
                    })
                ),
                diary_id: Yup.string(),
            }),
        },
        manager: {
            schema: Yup.object().shape({
                name: Yup.string(),
                secondary_id: Yup.string(),
                email: Yup.string().email('Formato inválido'),
                active: Yup.bool(),
                trialStart: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                trialEnd: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                devices: Yup.array().of(
                    Yup.object().shape({
                        device_id: Yup.string().required('Campo obrigatório'),
                        body_part: Yup.string().required('Campo obrigatório'),
                        received_at: Yup.date()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('Campo obrigatório'),
                    })
                ),
                diary_id: Yup.string(),
            }),
        },
        condor_admin: {
            schema: Yup.object().shape({
                name: Yup.string(),
                secondary_id: Yup.string(),
                email: Yup.string().email('Formato inválido'),
                active: Yup.bool(),
            }),
        },
    },
    en: {
        customer_admin: {
            schema: Yup.object().shape({
                name: Yup.string(),
                secondary_id: Yup.string(),
                email: Yup.string().email('Invalid format'),
                active: Yup.bool(),
                trialStart: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                trialEnd: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                devices: Yup.array().of(
                    Yup.object().shape({
                        device_id: Yup.string().required('Required field'),
                        body_part: Yup.string().required('Required field'),
                        received_at: Yup.date()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('Required field'),
                    })
                ),
                diary_id: Yup.string(),
            }),
        },
        manager: {
            schema: Yup.object().shape({
                name: Yup.string(),
                secondary_id: Yup.string(),
                email: Yup.string().email('Invalid format'),
                active: Yup.bool(),
                trialStart: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                trialEnd: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                devices: Yup.array().of(
                    Yup.object().shape({
                        device_id: Yup.string().required('Required field'),
                        body_part: Yup.string().required('Required field'),
                        received_at: Yup.date()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('Required field'),
                    })
                ),
                diary_id: Yup.string(),
            }),
        },
        condor_admin: {
            schema: Yup.object().shape({
                name: Yup.string(),
                secondary_id: Yup.string(),
                email: Yup.string().email('Invalid format'),
                active: Yup.bool(),
            }),
        },
    },
};

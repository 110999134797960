import { all, takeLatest, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { store } from '../../store';
import api from '../../services/api';
import history from '../../services/history';
import {
    loginSuccess,
    loginFailure,
    verify2faSuccess,
    verify2faFailure,
    updateCurrentUserSuccess,
    updateCurrentUserFailure,
    updateCurrentUserSubscription,
    resetAuthState,
} from '../actions/authActions';
import { setLoading, resetLoadingState } from '../actions/loadingActions';
import { resetReportState } from '../actions/reportActions';
import { AUTH } from '../constants';
import customDiariesPageConfig from '../../pages/diaries/page.config';
import myProfilePageConfig from '../../pages/account/myProfile/page.config';

export function* signIn({ payload }) {
    try {
        const { company_id, user_id, password } = payload;

        const response = yield call(
            api.post,
            '/auth/login',
            {
                company_id,
                user_id,
                password,
            },
            { withCredentials: true }
        );

        const { current_user, completed_auth_flow } = response;

        yield put(
            loginSuccess(
                current_user,
                completed_auth_flow,
                completed_auth_flow ? null : Date.now() + 120000
            )
        );
    } catch (error) {
        yield put(loginFailure());
    }
}

export function* verify2fa({ payload }) {
    try {
        const { otp_code } = payload;

        yield call(
            api.post,
            '/auth/verify_2fa',
            {
                otp_code,
            },
            { withCredentials: true }
        );

        yield put(verify2faSuccess());

        history.push('/dashboard');
    } catch (error) {
        yield put(verify2faFailure());
    }
}

export function* signOut() {
    yield call(api.post, '/auth/logout', {}, { withCredentials: true });

    // reset all states
    yield put(resetAuthState());
    yield put(resetLoadingState());
    yield put(resetReportState());

    history.push('/');
}

export function* updateCurrentUser({ payload }) {
    const { lang } = store.getState().lang;

    const { successText } = myProfilePageConfig[lang];

    try {
        yield put(setLoading(true));

        const response = yield call(api.put, `/account/edit_profile`, payload, {
            withCredentials: true,
        });

        const { currentUser } = response;

        yield put(setLoading(false));

        yield put(updateCurrentUserSuccess(currentUser));

        toast.success(successText);
    } catch (error) {
        yield put(setLoading(false));

        yield put(updateCurrentUserFailure());
    }
}

export function* setCurrentUserTrialsConfigurations({ payload }) {
    const { lang } = store.getState().lang;
    const { currentUser } = store.getState().auth;

    const { successResponseText } =
        customDiariesPageConfig[lang][currentUser.permissions];

    try {
        const { id } = payload;

        delete payload.id;

        yield put(setLoading(true));

        const response = yield call(api.put, `/users/edit/${id}`, payload);

        yield put(setLoading(false));

        if (response) {
            yield put(
                updateCurrentUserSuccess({
                    ...currentUser,
                    trials_configurations: {
                        ...currentUser.trials_configurations,
                        customQuestion: payload.customQuestion,
                    },
                })
            );

            toast.success(successResponseText);
        }
    } catch (error) {
        yield put(setLoading(false));
        yield put(updateCurrentUserFailure());
    }
}

export function* setCurrentUserSubscription({ payload }) {
    const { currentUser } = payload;

    yield put(updateCurrentUserSubscription({ ...currentUser }));
}

export default all([
    takeLatest(AUTH.LOGIN_REQUEST, signIn),
    takeLatest(AUTH.VERIFY_2FA_REQUEST, verify2fa),
    takeLatest(AUTH.UPDATE_CURRENT_USER_REQUEST, updateCurrentUser),
    takeLatest(
        AUTH.UPDATE_CURRENT_USER_SUBSCRIPTION_REQUEST,
        setCurrentUserSubscription
    ),
    takeLatest(
        AUTH.UPDATE_CURRENT_USER_TRIALS_CONFIGURATIONS_REQUEST,
        setCurrentUserTrialsConfigurations
    ),
    takeLatest(AUTH.LOGOUT, signOut),
]);

import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';

import persistReducers from './persistReducers';
import rootReducer from './reducers/rootReducer';
import rootSaga from './sagas/rootSaga';
import validateRehydrateMiddleware from './rehydrateMiddleware';

const sagaMonitor =
    process.env.NODE_ENV === 'development'
        ? console.tron.createSagaMonitor()
        : null;
const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

const enhancer =
    process.env.NODE_ENV === 'development'
        ? compose(
              console.tron.createEnhancer(),
              applyMiddleware(sagaMiddleware, validateRehydrateMiddleware)
          )
        : applyMiddleware(sagaMiddleware, validateRehydrateMiddleware);

const store = createStore(persistReducers(rootReducer), enhancer);
const persistor = persistStore(store, null, () => {});

sagaMiddleware.run(rootSaga);

export { store, persistor };

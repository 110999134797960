import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import FormFields from '../../../../components/form/formFields';
import { setLoading } from '../../../../store/actions/loadingActions';
import { validateForm } from '../../../../helpers/formValidator';
import { editCompany } from '../../../../services/api/companies';
import history from '../../../../services/history';

MyForm.propTypes = {
    company: PropTypes.object.isRequired,
    fields: PropTypes.array.isRequired,
    schema: PropTypes.object.isRequired,
    texts: PropTypes.shape({
        successText: PropTypes.string.isRequired,
        saveText: PropTypes.string.isRequired,
        cancelText: PropTypes.string.isRequired,
        yesText: PropTypes.string.isRequired,
        noText: PropTypes.string.isRequired,
        defaultLanguageOptions: PropTypes.shape({
            pt: PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            }).isRequired,
            en: PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
};

export function MyForm({ company, fields, schema, texts }) {
    const formRef = useRef(null);

    const isFirstRender = useRef(true);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    async function handleSubmit(data) {
        const { parsedData, isValid, errors } = await validateForm(
            data,
            schema
        );

        if (isValid) {
            formRef.current.setErrors(errors);

            dispatch(setLoading(true));

            const response = await editCompany(company.id, parsedData);

            dispatch(setLoading(false));

            if (response) {
                toast.success(texts.successText);
                history.push('/companies');
            }
        } else {
            formRef.current.setErrors(errors);
        }
    }

    useEffect(() => {
        let initialData = {};

        if (isFirstRender.current) {
            isFirstRender.current = false;

            formRef.current.reset();

            initialData = {
                name: company.name,
                share: {
                    label: company.share ? texts.yesText : texts.noText,
                    value: company.share,
                },
                descriptor: {
                    saveEmail: {
                        label: company.descriptor.saveEmail
                            ? texts.yesText
                            : texts.noText,
                        value: company.descriptor.saveEmail,
                    },
                    defaultLanguage: {
                        label: texts.defaultLanguageOptions[
                            company.descriptor.defaultLanguage
                        ]?.label,
                        value: texts.defaultLanguageOptions[
                            company.descriptor.defaultLanguage
                        ]?.value,
                    },
                    minPasswordLength:
                        company.descriptor.minPasswordLength ?? 8,
                },
            };
        } else {
            initialData = {
                name: formRef.current.getFieldValue('name'),
                share: {
                    label: formRef.current.getFieldValue('share')
                        ? texts.yesText
                        : texts.noText,
                    value: formRef.current.getFieldValue('share')
                        ? true
                        : false,
                },
                descriptor: {
                    saveEmail: {
                        label: formRef.current.getFieldValue(
                            'descriptor.saveEmail'
                        )
                            ? texts.yesText
                            : texts.noText,
                        value: formRef.current.getFieldValue(
                            'descriptor.saveEmail'
                        ),
                    },
                    defaultLanguage: {
                        label: texts.defaultLanguageOptions[
                            formRef.current.getFieldValue(
                                'descriptor.defaultLanguage'
                            )
                        ]?.label,
                        value: texts.defaultLanguageOptions[
                            formRef.current.getFieldValue(
                                'descriptor.defaultLanguage'
                            )
                        ]?.value,
                    },
                    minPasswordLength: formRef.current.getFieldValue(
                        'descriptor.minPasswordLength'
                    ),
                },
            };
        }

        formRef.current.setData(initialData);
    }, [lang]);

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <FormGroup row>
                <FormFields fields={fields} />
            </FormGroup>
            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button color="primary mr-2" type="submit">
                        {texts.saveText}
                    </Button>
                    <Link to="/companies" className="btn btn-secondary">
                        {texts.cancelText}
                    </Link>
                </div>
            </FormGroup>
        </Form>
    );
}

import { AUTH } from '../constants';

export function loginRequest(data) {
    return {
        type: AUTH.LOGIN_REQUEST,
        payload: data,
    };
}

export function loginSuccess(
    currentUser,
    completedLoginFlow,
    maxDateToCompleteLoginFlow
) {
    return {
        type: AUTH.LOGIN_SUCCESS,
        payload: {
            currentUser,
            completedLoginFlow,
            maxDateToCompleteLoginFlow,
        },
    };
}

export function loginFailure() {
    return {
        type: AUTH.LOGIN_FAILURE,
    };
}

export function verify2faRequest(data) {
    return {
        type: AUTH.VERIFY_2FA_REQUEST,
        payload: data,
    };
}

export function verify2faSuccess() {
    return {
        type: AUTH.VERIFY_2FA_SUCCESS,
    };
}

export function verify2faFailure() {
    return {
        type: AUTH.VERIFY_2FA_FAILURE,
    };
}

export function logout() {
    return {
        type: AUTH.LOGOUT,
    };
}

export function updateCurrentUserRequest(data) {
    return {
        type: AUTH.UPDATE_CURRENT_USER_REQUEST,
        payload: data,
    };
}

export function updateCurrentUserSuccess(currentUser) {
    return {
        type: AUTH.UPDATE_CURRENT_USER_SUCCESS,
        payload: { currentUser },
    };
}

export function updateCurrentUserFailure() {
    return {
        type: AUTH.UPDATE_CURRENT_USER_FAILURE,
    };
}

export function updateCurrentUserSubscriptionRequest(data) {
    return {
        type: AUTH.UPDATE_CURRENT_USER_SUBSCRIPTION_REQUEST,
        payload: data,
    };
}

export function updateCurrentUserSubscription(currentUser) {
    return {
        type: AUTH.UPDATE_CURRENT_USER_SUBSCRIPTION,
        payload: { currentUser },
    };
}

export function updateCurrentUserTrialsConfigurationsRequest(data) {
    return {
        type: AUTH.UPDATE_CURRENT_USER_TRIALS_CONFIGURATIONS_REQUEST,
        payload: data,
    };
}

export function resetAuthState() {
    return {
        type: AUTH.RESET_STATE,
    };
}

import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { Button, Card, CardBody, CardHeader, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';
import lodash from 'lodash';
import PropTypes from 'prop-types';

import schemaConfig from './schema.config';
import pageConfig from './page.config';
import { updateCurrentUserRequest } from '../../../store/actions/authActions';
import { setLoading } from '../../../store/actions/loadingActions';
import { validateForm } from '../../../helpers/formValidator';
import { createYupSchema } from '../../../helpers/yupSchemaCreator';
import { hasSpecialCharacter } from '../../../helpers/checkSpecialCharacters';
import { fetchCompany } from '../../../services/api/account';

MyProfile.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function MyProfile({ currentUser }) {
    const formRef = useRef(null);
    const formRefPassword = useRef(null);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const { schema, rawSchemaPassword } = schemaConfig[lang];
    const pageData = pageConfig[lang];

    const [company, setCompany] = useState(null);
    const [passwordSchema, setPasswordSchema] = useState(null);

    const [editModeForm, setEditModeForm] = useState(false);
    const [editModePasswordForm, setEditModePasswordForm] = useState(false);

    async function handleSubmit(data) {
        const { parsedData, isValid, errors } = await validateForm(
            data,
            schema
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(updateCurrentUserRequest(parsedData));

            setEditModeForm(false);
        }
    }

    async function handleSubmitPassword(data) {
        const { parsedData, isValid, errors } = await validateForm(
            data,
            passwordSchema
        );

        formRefPassword.current.setErrors(errors);

        if (isValid) {
            dispatch(updateCurrentUserRequest(parsedData));

            setEditModePasswordForm(false);
        }
    }

    function cancelEditModeForm() {
        setEditModeForm(false);

        formRef.current.reset();

        formRef.current.setData({
            name: currentUser.name,
            secondary_id: currentUser.secondary_id,
            email: currentUser.email,
        });
    }

    function cancelEditModePasswordForm() {
        setEditModePasswordForm(false);

        formRefPassword.current.reset();

        formRefPassword.current.setData({
            password: '',
            confirmPassword: '',
        });
    }

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            const data = await fetchCompany();

            dispatch(setLoading(false));

            if (Object.entries(data).length > 0) {
                const tempRawSchema = lodash.cloneDeep(rawSchemaPassword);

                const passwordIndex = tempRawSchema.findIndex(
                    (fieldValidation) => fieldValidation.id === 'password'
                );

                let minPasswordLength = 8;

                let minPasswordLengthText = '';

                if ('minPasswordLength' in data.company.descriptor) {
                    minPasswordLength =
                        data.company.descriptor.minPasswordLength;
                }

                if (lang === 'pt') {
                    minPasswordLengthText = `A senha deve ter no mínimo ${minPasswordLength} caracteres`;
                } else {
                    minPasswordLengthText = `Password must be at least ${minPasswordLength} characters`;
                }

                tempRawSchema[passwordIndex].validations.unshift({
                    type: 'test',
                    params: [
                        'empty-or-min-characters-check',
                        minPasswordLengthText,
                        (value) => !value || value.length >= minPasswordLength,
                    ],
                });

                tempRawSchema[passwordIndex + 1].validations.unshift({
                    type: 'test',
                    params: [
                        'empty-or-min-characters-check',
                        minPasswordLengthText,
                        (value) => !value || value.length >= minPasswordLength,
                    ],
                });

                // check for special characters
                let hasSpecialCharacterInPassword = false;

                if (
                    'hasSpecialCharacterInPassword' in data.company.descriptor
                ) {
                    hasSpecialCharacterInPassword =
                        data.company.descriptor.hasSpecialCharacterInPassword;
                }

                if (hasSpecialCharacterInPassword) {
                    tempRawSchema[passwordIndex].validations.push({
                        type: 'test',
                        params: [
                            'special-characters-check',
                            pageData.passwordMustContainSpecialCharacterText,
                            (value) => hasSpecialCharacter(value),
                        ],
                    });

                    tempRawSchema[passwordIndex + 1].validations.push({
                        type: 'test',
                        params: [
                            'special-characters-check',
                            pageData.passwordMustContainSpecialCharacterText,
                            (value) => hasSpecialCharacter(value),
                        ],
                    });
                }

                setPasswordSchema(
                    Yup.object().shape(
                        tempRawSchema.reduce(createYupSchema, {})
                    )
                );

                setCompany(data?.company);

                formRef.current.reset();

                formRef.current.setData({
                    name: currentUser.name,
                    secondary_id: currentUser.secondary_id,
                    email: currentUser.email,
                });
            }
        })();
    }, []);

    return (
        <div className="d-flex flex-column">
            <Card className="cardShadow">
                <CardHeader className="d-flex justify-content-between align-items-center">
                    <p>{pageData.nameAndEmailText}</p>

                    <div>
                        <Button
                            type="button"
                            onClick={() => setEditModeForm(true)}
                            disabled={editModeForm}
                        >
                            {pageData.editText}
                        </Button>
                    </div>
                </CardHeader>

                <CardBody>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <Row>
                            {pageData.fields.map((field) => {
                                return (
                                    <FormGroup
                                        key={field.props.name}
                                        className={field.className}
                                    >
                                        <field.component
                                            {...field.props}
                                            disabled={!editModeForm}
                                        />
                                    </FormGroup>
                                );
                            })}
                        </Row>

                        {editModeForm && (
                            <Row>
                                <div className="col-xs-12 col-md-12 text-left">
                                    <Button
                                        type="submit"
                                        className="mr-2"
                                        color="primary"
                                    >
                                        {pageData.saveText}
                                    </Button>

                                    <Button
                                        type="button"
                                        className="mr-2"
                                        color="secondary"
                                        onClick={() => cancelEditModeForm()}
                                    >
                                        {pageData.cancelText}
                                    </Button>
                                </div>
                            </Row>
                        )}
                    </Form>
                </CardBody>
            </Card>

            <Card className="cardShadow mt-4">
                <CardHeader className="d-flex justify-content-between align-items-center">
                    <p>{pageData.passwordText}</p>

                    <div>
                        <Button
                            type="button"
                            onClick={() => setEditModePasswordForm(true)}
                            disabled={editModePasswordForm}
                        >
                            {pageData.editText}
                        </Button>
                    </div>
                </CardHeader>

                <CardBody>
                    <Form ref={formRefPassword} onSubmit={handleSubmitPassword}>
                        <Row>
                            {pageData.passwordFields.map((field) => {
                                return (
                                    <FormGroup
                                        key={field.props.name}
                                        className={field.className}
                                    >
                                        <field.component
                                            {...field.props}
                                            disabled={!editModePasswordForm}
                                        />
                                    </FormGroup>
                                );
                            })}
                        </Row>

                        {editModePasswordForm && (
                            <Row>
                                <div className="col-xs-12 col-md-12 text-left">
                                    <Button
                                        className="mr-2"
                                        color="primary"
                                        type="submit"
                                    >
                                        {pageData.saveText}
                                    </Button>

                                    <Button
                                        type="button"
                                        className="mr-2"
                                        color="secondary"
                                        onClick={() =>
                                            cancelEditModePasswordForm()
                                        }
                                    >
                                        {pageData.cancelText}
                                    </Button>
                                </div>
                            </Row>
                        )}
                    </Form>
                </CardBody>
            </Card>
        </div>
    );
}

import React from 'react';
import { FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';

FormFields.propTypes = {
    fields: PropTypes.array.isRequired,
    handlePlanGroup: PropTypes.func.isRequired,
    planGroup: PropTypes.bool.isRequired,
    handlePlanType: PropTypes.func.isRequired,
    planType: PropTypes.string.isRequired,
};

export function FormFields({
    fields,
    handlePlanGroup,
    planGroup,
    handlePlanType,
    planType,
}) {
    return fields.map((field) => {
        switch (field.props.name) {
            case 'biannual_price_brl':
            case 'biannual_price_usd':
            case 'annual_price_brl':
            case 'annual_price_usd':
                return (
                    <FormGroup
                        key={field.props.name}
                        className={planGroup ? field.className : 'hidden'}
                    >
                        <field.component {...field.props} />
                    </FormGroup>
                );
            case 'price_brl':
            case 'price_usd':
                return planType === 'sleep_diary' ? (
                    <FormGroup
                        key={field.props.name}
                        className={field.className}
                    >
                        <field.component {...field.props} />
                    </FormGroup>
                ) : null;
            case 'plan_group':
                return (
                    <FormGroup
                        key={field.props.name}
                        className={field.className}
                    >
                        <field.component
                            {...field.props}
                            onChange={(e) => handlePlanGroup(Boolean(e?.value))}
                        />
                    </FormGroup>
                );
            case 'type':
                return (
                    <FormGroup
                        key={field.props.name}
                        className={field.className}
                    >
                        <field.component
                            {...field.props}
                            onChange={(e) => handlePlanType(e?.value)}
                        />
                    </FormGroup>
                );
            default:
                return (
                    <FormGroup
                        key={field.props.name}
                        className={field.className}
                    >
                        <field.component {...field.props} />
                    </FormGroup>
                );
        }
    });
}

import React from 'react';
import { Col } from 'reactstrap';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    ComposedChart,
    Line,
    Area,
    Label,
} from 'recharts';
import PropTypes from 'prop-types';

MatriceChart.propTypes = {
    width: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
};

const newColors = [
    'rgb(121, 0, 141)',
    'rgb(118, 0, 237)',
    'rgb(0, 40, 255)',
    'rgb(0, 213, 255)',
    'rgb(31, 255, 0)',
    'rgb(179, 255, 0)',
    'rgb(255, 223, 0)',
    'rgb(255, 79, 0)',
    'rgb(223, 0, 0)',
    'rgb(158, 0, 0)',
];

export function MatriceChart({ width, data, onClose }) {
    const chartWidth = width - (68 * width) / 100;

    return (
        <Col
            style={{
                // width: '100%',
                width: chartWidth,
                height: `15rem`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            className="channelsChartContainer"
        >
            <AiOutlineCloseCircle className="closeIcon" onClick={onClose} />
            <ResponsiveContainer width={chartWidth} height="100%">
                <ComposedChart
                    data={data}
                    margin={{
                        top: 30,
                        bottom: 20,
                        left: 25,
                        right: 25,
                    }}
                >
                    <defs>
                        <linearGradient id="lightSpectrum">
                            <stop
                                offset="0%"
                                stopColor={newColors[0]}
                                stopOpacity={0.8}
                            />
                            <stop
                                offset="5%"
                                stopColor={newColors[1]}
                                stopOpacity={0.8}
                            />
                            <stop
                                offset="10%"
                                stopColor={newColors[2]}
                                stopOpacity={0.8}
                            />
                            <stop
                                offset="24%"
                                stopColor={newColors[3]}
                                stopOpacity={0.8}
                            />
                            <stop
                                offset="40%"
                                stopColor={newColors[4]}
                                stopOpacity={0.8}
                            />
                            <stop
                                offset="50%"
                                stopColor={newColors[5]}
                                stopOpacity={0.8}
                            />
                            <stop
                                offset="60%"
                                stopColor={newColors[6]}
                                stopOpacity={0.8}
                            />
                            <stop
                                offset="70%"
                                stopColor={newColors[7]}
                                stopOpacity={0.8}
                            />
                            <stop
                                offset="80%"
                                stopColor={newColors[8]}
                                stopOpacity={0.8}
                            />
                            <stop
                                offset="100%"
                                stopColor={newColors[9]}
                                stopOpacity={0.8}
                            />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="wavelength"
                        type="number"
                        domain={[390, 750]}
                        tick={{
                            fontSize: 12,
                            fontWeight: 'bold',
                        }}
                        interval={0}
                        ticks={[
                            390, 415, 445, 480, 515, 555, 590, 630, 680, 750,
                        ]}
                    >
                        <Label
                            value="wavelength (nm)"
                            position="insideBottom"
                            offset={-5}
                            fontWeight="bold"
                        />
                    </XAxis>
                    <YAxis type="number" />
                    <Tooltip
                        formatter={(value) => value.toExponential(2)}
                        labelFormatter={(value) => `${value}nm`}
                    />
                    <Area
                        type="monotone"
                        dataKey="line"
                        fillOpacity={1}
                        fill="url(#lightSpectrum)"
                        stroke="#fff"
                    />
                    <Line
                        type="monotone"
                        dataKey="line"
                        stroke="#000"
                        strokeWidth={0}
                        connectNulls
                        dot={false}
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </Col>
    );
}

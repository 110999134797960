import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

export function Checkbox({ name, label, className = '', value, ...rest }) {
    const inputRef = useRef();
    const { fieldName, defaultValue, registerField, error } = useField(name);

    const defaultChecked = defaultValue === value;

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: (ref) => {
                return ref.current.checked;
            },
            clearValue: (ref) => {
                ref.current.checked = defaultChecked;
            },
            setValue: (ref, value) => {
                ref.current.checked = value;
            },
        });
    }, [defaultValue, fieldName, registerField, defaultChecked]);

    return (
        <>
            {label && (
                <label
                    htmlFor={fieldName}
                    dangerouslySetInnerHTML={{
                        __html: label,
                    }}
                ></label>
            )}

            <input
                id={fieldName}
                ref={inputRef}
                type="checkbox"
                name={name}
                value={value}
                defaultChecked={defaultChecked}
                {...rest}
                className={`ml-2 ${className}`}
            />

            {error && <span className="text-danger">{error}</span>}
        </>
    );
}

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    className: PropTypes.string,
};

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Plot from '../../../../components/plot';
import {
    localeWeekDayFormatter,
    timeFormatter,
} from '../../../../helpers/formatters';
import {
    matricialWavelength,
    matricialData,
} from '../../../../helpers/lightChannelsData';
// import { MatriceChart } from './matriceChart';

SleepScoringChart.propTypes = {
    chartData: PropTypes.array.isRequired,
    layout: PropTypes.object,
    data: PropTypes.shape({
        wrist: PropTypes.shape({
            date: PropTypes.string,
            range: PropTypes.array,
            log_times: PropTypes.array,
            activity: PropTypes.array,
            temperature: PropTypes.array,
            f1: PropTypes.array,
            f2: PropTypes.array,
            f3: PropTypes.array,
            f4: PropTypes.array,
            f5: PropTypes.array,
            f6: PropTypes.array,
            f7: PropTypes.array,
            f8: PropTypes.array,
            clear: PropTypes.array,
            nir: PropTypes.array,
            lux_photopic: PropTypes.array,
            lux_melanopic: PropTypes.array,
            event: PropTypes.array,
            state: PropTypes.array,
            offwrist: PropTypes.array,
            sleep_diary_dates: PropTypes.array,
        }),
        necklace: PropTypes.shape({
            date: PropTypes.string,
            range: PropTypes.array,
            log_times: PropTypes.array,
            activity: PropTypes.array,
            temperature: PropTypes.array,
            f1: PropTypes.array,
            f2: PropTypes.array,
            f3: PropTypes.array,
            f4: PropTypes.array,
            f5: PropTypes.array,
            f6: PropTypes.array,
            f7: PropTypes.array,
            f8: PropTypes.array,
            clear: PropTypes.array,
            nir: PropTypes.array,
            lux_photopic: PropTypes.array,
            lux_melanopic: PropTypes.array,
            event: PropTypes.array,
            state: PropTypes.array,
            offwrist: PropTypes.array,
            sleep_diary_dates: PropTypes.array,
        }),
    }).isRequired,
    chartsMode: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    setLayouts: PropTypes.func.isRequired,
    setChannelsChart: PropTypes.func.isRequired,
    // channelsChart: PropTypes.object,
    // onCloseChannelsChart: PropTypes.func,
};

export function SleepScoringChart({
    chartData,
    layout,
    data,
    index,
    chartsMode,
    setLayouts,
    setChannelsChart,
    // channelsChart,
    // onCloseChannelsChart,
}) {
    const lang = useSelector((state) => state.lang.lang);
    const { dateLocale } = useSelector((state) => state.locale);

    const [hoverValues, setHoverValues] = useState({});
    const [hoverPoint, setHoverPoint] = useState({});

    const config = {
        displayModeBar: false,
        staticPlot: false,
        responsive: true,
        edits: {
            axisTitleText: false,
            shapePosition: false,
            titleText: false,
        },
        scrollZoom: false,
        showAxisDragHandles: false,
    };

    function getXIndex(x) {
        let xIndex = -1;

        if (chartsMode === 'mixed') {
            xIndex = data?.necklace?.log_times?.findIndex(
                (value) => value === x
            );

            if (xIndex === -1) {
                xIndex = data?.necklace?.log_times?.findIndex(
                    (value) =>
                        value.slice(0, 17) + '00' === x.slice(0, 17) + '00'
                );
                return { xIndex, isFromWrist: false };
            }

            if (xIndex === undefined) {
                xIndex = data.wrist.log_times.findIndex((value) => value === x);
                return { xIndex, isFromWrist: true };
            }

            return { xIndex, isFromWrist: false };
        } else if (chartsMode === 'necklace') {
            xIndex = data.necklace.log_times.findIndex((value) => value === x);
            return { xIndex, isFromWrist: false };
        } else {
            xIndex = data.wrist.log_times.findIndex((value) => value === x);
            return { xIndex, isFromWrist: true };
        }
    }

    function handleClick(e) {
        if (e?.points && e?.points.length > 0) {
            const x = e.points[0].x;

            let { xIndex, isFromWrist } = getXIndex(x);

            if (xIndex !== -1) {
                const y = matricialData.map((waveArray) => {
                    return waveArray.reduce((acc, current, currentIndex) => {
                        if (isFromWrist) {
                            if (currentIndex <= 7) {
                                return (
                                    acc +
                                    current *
                                        data.wrist[`f${currentIndex + 1}`][
                                            xIndex
                                        ]
                                );
                            } else if (currentIndex === 8) {
                                return (
                                    acc + current * data.wrist[`clear`][xIndex]
                                );
                            } else if (currentIndex === 9) {
                                return (
                                    acc + current * data.wrist[`nir`][xIndex]
                                );
                            } else {
                                return acc;
                            }
                        } else {
                            if (currentIndex <= 7) {
                                return (
                                    acc +
                                    current *
                                        data.necklace[`f${currentIndex + 1}`][
                                            xIndex
                                        ]
                                );
                            } else if (currentIndex === 8) {
                                return (
                                    acc +
                                    current * data.necklace[`clear`][xIndex]
                                );
                            } else if (currentIndex === 9) {
                                return (
                                    acc + current * data.necklace[`nir`][xIndex]
                                );
                            } else {
                                return acc;
                            }
                        }
                    }, 0);
                });

                setLayouts((prevState) => {
                    return prevState.map((layout, i) => {
                        const newLayout = { ...layout };

                        const shapeIndex = newLayout.shapes.findIndex(
                            (shape) => shape.name === 'clickLine'
                        );

                        const newShapes = [...newLayout.shapes];

                        if (shapeIndex !== -1) {
                            newShapes.splice(shapeIndex, 1);
                        }

                        if (i === index) {
                            return {
                                ...newLayout,
                                shapes: [
                                    ...newShapes,
                                    {
                                        name: 'clickLine',
                                        type: 'line',
                                        x0: x,
                                        y0: -1000000,
                                        x1: x,
                                        y1: 1000000,
                                        xref: 'x1',
                                        yref: 'y1',
                                        line: {
                                            color: 'black',
                                            width: 2,
                                            dash: 'dash',
                                        },
                                    },
                                ],
                            };
                        } else {
                            return {
                                ...newLayout,
                                shapes: newShapes,
                            };
                        }
                    });
                });

                const data2 = y
                    .map((value, i) => ({
                        wavelength: matricialWavelength[i],
                        value: value,
                    }))
                    .filter(
                        (point) =>
                            point.wavelength >= 415 && point.wavelength <= 750
                    );

                setChannelsChart({
                    data2: [
                        {
                            390: data2[0].value < 0 ? 0 : data2[0].value / 2,
                            line: data2[0].value < 0 ? 0 : data2[0].value / 2,
                            wavelength: 390,
                        },
                        {
                            415: data2[0].value < 0 ? 0 : data2[0].value,
                            line: data2[0].value < 0 ? 0 : data2[0].value,
                            wavelength: 415,
                        },
                        {
                            445: data2[1].value < 0 ? 0 : data2[1].value,
                            line: data2[1].value < 0 ? 0 : data2[1].value,
                            wavelength: 445,
                        },
                        {
                            480: data2[2].value < 0 ? 0 : data2[2].value,
                            line: data2[2].value < 0 ? 0 : data2[2].value,
                            wavelength: 480,
                        },
                        {
                            515: data2[3].value < 0 ? 0 : data2[3].value,
                            line: data2[3].value < 0 ? 0 : data2[3].value,
                            wavelength: 515,
                        },
                        {
                            555: data2[4].value < 0 ? 0 : data2[4].value,
                            line: data2[4].value < 0 ? 0 : data2[4].value,
                            wavelength: 555,
                        },
                        {
                            590: data2[5].value < 0 ? 0 : data2[5].value,
                            line: data2[5].value < 0 ? 0 : data2[5].value,
                            wavelength: 590,
                        },
                        {
                            630: data2[6].value < 0 ? 0 : data2[6].value,
                            line: data2[6].value < 0 ? 0 : data2[6].value,
                            wavelength: 630,
                        },
                        {
                            680: data2[7].value < 0 ? 0 : data2[7].value,
                            line: data2[7].value < 0 ? 0 : data2[7].value,
                            wavelength: 680,
                        },
                        {
                            750: data2[8].value < 0 ? 0 : data2[8].value,
                            line: data2[8].value < 0 ? 0 : data2[8].value,
                            wavelength: 750,
                        },
                    ],
                });
            }
        }
    }

    function handleHover(e) {
        let x = 100 + 14;

        if (e.event.layerX <= 100 + (30 * 16) / 2) {
            x = 100 + 14;
        } else if (
            e.event.layerX >=
            100 +
                Number(e.event?.target?.attributes?.width?.value) -
                (30 * 16) / 2
        ) {
            x =
                Number(e.event?.target?.attributes?.width?.value) -
                100 -
                24 -
                (30 * 16) / 2;
        } else {
            x = e.event.layerX + 14 - (30 * 16) / 2;
        }

        setHoverPoint({
            x,
        });

        if (e?.points && e?.points.length > 0) {
            let activityValue = e?.points?.find(
                (curveData) => curveData?.data?.name === 'activity'
            )?.y;
            let temperatureValue = e?.points?.find(
                (curveData) => curveData?.data?.name === 'temperature'
            )?.y;
            let melanopicValue = e?.points?.find(
                (curveData) => curveData?.data?.name === 'melanopic'
            )?.y;
            let photopicValue = e?.points?.find(
                (curveData) => curveData?.data?.name === 'photopic'
            )?.y;

            let weekDay = '';
            let time = '';

            try {
                weekDay = localeWeekDayFormatter(
                    e.points[0].x.slice(0, 11),
                    dateLocale,
                    lang
                );

                if (weekDay === null) {
                    weekDay = '';
                }
            } catch (e) {
                weekDay = '';
            }

            try {
                time = timeFormatter(e.points[0].x.slice(11), dateLocale, lang);

                if (time === null) {
                    time = '';
                }
            } catch (e) {
                time = '';
            }

            const xHoverValue = `${weekDay} ${time}`;

            setHoverValues({
                x: xHoverValue,
                ac:
                    activityValue > 100
                        ? Math.round(activityValue)
                        : Number(activityValue.toFixed(2)),
                te:
                    temperatureValue > 100
                        ? Math.round(temperatureValue)
                        : Number(temperatureValue.toFixed(2)),
                ph:
                    photopicValue > 100
                        ? Math.round(photopicValue)
                        : Number(photopicValue.toFixed(2)),
                me:
                    melanopicValue > 100
                        ? Math.round(melanopicValue)
                        : Number(melanopicValue.toFixed(2)),
                index,
            });
        }
    }

    function handleUnhover() {
        setHoverValues({});
        setHoverPoint({});
    }

    return (
        <>
            <Plot
                data={chartData}
                layout={layout}
                config={config}
                onClick={(e) => handleClick(e, index)}
                onHover={(e) => handleHover(e, index)}
                onUnhover={() => handleUnhover()}
            />
            {Object.keys(hoverValues).length > 0 &&
                index === hoverValues['index'] && (
                    <div
                        style={{
                            left: `${hoverPoint.x}px`,
                        }}
                        className="sleepScoringChartTooltip"
                    >
                        <p>{hoverValues['x']},</p>
                        <p>ac: {hoverValues['ac']},</p>
                        <p>te: {hoverValues['te']}</p>
                        <p>me: {hoverValues['me']}</p>
                        {/* <MatriceChart
                            width={200}
                            data={channelsChart.data2}
                            onClose={onCloseChannelsChart}
                        /> */}
                    </div>
                )}
        </>
    );
}

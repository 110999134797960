import {
    all,
    takeLatest,
    put,
    // call
} from 'redux-saga/effects';
// import { toast } from 'react-toastify';

// import { store } from '../../store';
import {
    updateReport,
    updateUser,
    updateDates,
    updateDaysInWeek,
    updateWeekReferenceSuccess,
    // updateWeekReferenceFailure,
    updateCalendarSuccess,
    // updateCalendarFailure,
    updateWeeklyEvolutionSuccess,
    // updateWeeklyEvolutionFailure,
    updateDailyEvolutionSuccess,
    // updateDailyEvolutionFailure,
    updateAverageTableSuccess,
    // updateAverageTableFailure,
    updateEditableTableSuccess,
    // updateEditableTableFailure,
    updateEditableTableWeeksSuccess,
    // updateEditableTableWeeksFailure,
    updateActogramSuccess,
    // updateActogramFailure,
    updateSleepScoringSuccess,
    // updateSleepScoringFailure,
} from '../actions/reportActions';
// import { setLoading } from '../actions/loadingActions';
// import {
//     fetchCurrentWeekReferenceData,
//     fetchCalendarData,
//     fetchWeeklyEvolutionData,
//     fetchDailyEvolutionData,
//     fetchAverageTableData,
//     fetchEditData,
//     fetchActogramData,
//     calculateSleepStates,
//     fetchSleepScoringStatus,
//     fetchSleepScoringData,
// } from '../../services/api/users';
import { REPORT } from '../constants';
// import pageConfig from '../../pages/users/userReport/page.config';

export function* setReport({ payload }) {
    const { user, reportData, minDate, maxDate, daysInWeek } = payload;

    yield put(updateReport(reportData, user, minDate, maxDate, daysInWeek));
}

export function* setDates({ payload }) {
    const { minDate, maxDate } = payload;

    yield put(updateDates({ minDate, maxDate }));
}

export function* setDaysInWeek({ payload }) {
    const { daysInWeek } = payload;

    yield put(updateDaysInWeek(daysInWeek));
}

export function* setUser({ payload }) {
    const { user } = payload;

    yield put(updateUser(user));
}

export function* setWeekReference({ payload }) {
    const { currentWeekReference } = payload;

    yield put(updateWeekReferenceSuccess(currentWeekReference));

    // const { lang } = store.getState().lang;
    // const { currentUser } = store.getState().auth;

    // const { noDataInputText } = pageConfig[lang][currentUser.permissions];

    // const { userId, date, minDate, maxDate, daysInWeek } = payload;

    // yield put(setLoading(true));

    // try {
    //     const data = yield call(
    //         fetchCurrentWeekReferenceData,
    //         userId,
    //         date,
    //         minDate,
    //         maxDate,
    //         daysInWeek
    //     );

    //     if (data.currentWeekReference.weekNumber === null) {
    //         toast.info(noDataInputText);
    //     }

    //     yield put(updateWeekReferenceSuccess(data));
    // } catch (error) {
    //     console.log('error', error);
    //     yield put(updateWeekReferenceFailure());
    // }

    // yield put(setLoading(false));
}

export function* setCalendar({ payload }) {
    const { calendar } = payload;

    yield put(updateCalendarSuccess(calendar));
    // const { data, minDate, maxDate, daysInWeek } = payload;

    // yield put(updateCalendar(data, minDate, maxDate, daysInWeek));
}

export function* setWeeklyEvolution({ payload }) {
    const { weeklyEvolution } = payload;

    yield put(updateWeeklyEvolutionSuccess(weeklyEvolution));

    // const { userId, date, minDate, maxDate, daysInWeek } = payload;

    // try {
    //     const data = yield call(
    //         fetchWeeklyEvolutionData,
    //         userId,
    //         date,
    //         minDate,
    //         maxDate,
    //         daysInWeek
    //     );
    //     console.log('setWeeklyEvolution data', data);

    //     yield put(updateWeeklyEvolutionSuccess(data.weeklyEvolution));
    // } catch (error) {
    //     console.log('error', error);
    //     yield put(updateWeeklyEvolutionFailure());
    // }
}

export function* setDailyEvolution({ payload }) {
    const { dailyEvolution } = payload;

    yield put(updateDailyEvolutionSuccess(dailyEvolution));
    // const { data, minDate, maxDate, daysInWeek } = payload;

    // yield put(updateDailyEvolution(data, minDate, maxDate, daysInWeek));
}

export function* setAverageTable({ payload }) {
    const { data } = payload;

    yield put(updateAverageTableSuccess(data));
    // const { data, minDate, maxDate, daysInWeek } = payload;

    // yield put(updateAverageTable(data, minDate, maxDate, daysInWeek));
}

export function* setEditableTable({ payload }) {
    const { data } = payload;
    // const { data, minDate, maxDate, daysInWeek } = payload;

    yield put(updateEditableTableSuccess(data));
}

export function* setEditableTableWeeks({ payload }) {
    const { data } = payload;
    // const { data, minDate, maxDate, daysInWeek } = payload;

    yield put(updateEditableTableWeeksSuccess(data));
}

export function* setActogram({ payload }) {
    const { data } = payload;
    // const { data, minDate, maxDate, daysInWeek } = payload;

    yield put(updateActogramSuccess(data));
}

export function* setSleepScoring({ payload }) {
    const { data } = payload;
    // const { data, minDate, maxDate, daysInWeek } = payload;

    yield put(updateSleepScoringSuccess(data));
}

export default all([
    takeLatest(REPORT.UPDATE_REPORT_REQUEST, setReport),
    takeLatest(REPORT.UPDATE_USER_REQUEST, setUser),
    takeLatest(REPORT.UPDATE_DATES_REQUEST, setDates),
    takeLatest(REPORT.UPDATE_DAYS_IN_WEEK_REQUEST, setDaysInWeek),
    takeLatest(REPORT.UPDATE_WEEK_REFERENCE_REQUEST, setWeekReference),
    takeLatest(REPORT.UPDATE_CALENDAR_REQUEST, setCalendar),
    takeLatest(REPORT.UPDATE_WEEKLY_EVOLUTION_REQUEST, setWeeklyEvolution),
    takeLatest(REPORT.UPDATE_DAILY_EVOLUTION_REQUEST, setDailyEvolution),
    takeLatest(REPORT.UPDATE_AVERAGE_TABLE_REQUEST, setAverageTable),
    takeLatest(REPORT.UPDATE_EDITABLE_TABLE_REQUEST, setEditableTable),
    takeLatest(
        REPORT.UPDATE_EDITABLE_TABLE_WEEKS_REQUEST,
        setEditableTableWeeks
    ),
    takeLatest(REPORT.UPDATE_ACTOGRAM_REQUEST, setActogram),
    takeLatest(REPORT.UPDATE_SLEEP_SCORING_REQUEST, setSleepScoring),
]);

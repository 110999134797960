import produce from 'immer';

import { LANG } from '../constants';

const INITIAL_STATE = {
    lang: 'pt',
};

export default function lang(state = INITIAL_STATE, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case LANG.CHANGE_LANG_REQUEST: {
                break;
            }
            case LANG.CHANGE_LANG: {
                draft.lang = action.payload.lang;
                break;
            }
        }
    });
}

import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { Button, Card, CardBody, CardFooter, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import history from '../../../services/history';
import { addCoupon } from '../../../services/api/coupons';
import { validateForm } from '../../../helpers/formValidator';
import { setLoading } from '../../../store/actions/loadingActions';
import { FormFields } from './components/formFields';

const allowedCurrencies = ['BRL', 'USD'];

export default function AddCoupon({ currentUser }) {
    const [isPercent, setIsPercent] = useState(true);

    const formRef = useRef(null);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];
    const { percentOffSchema, amountOffSchema } =
        schemaConfig[lang][currentUser.permissions];

    async function handleSubmit(data) {
        const { isValid, errors } = await validateForm(
            data,
            isPercent ? percentOffSchema : amountOffSchema
        );

        if (
            !formRef.current.getFieldValue('currency[0]') &&
            !formRef.current.getFieldValue('currency[1]')
        ) {
            formRef.current.setErrors(errors);
            formRef.current.setFieldError(
                'currency[1]',
                pageData.selectCurrencyErrorText
            );
            return;
        }

        if (isValid) {
            formRef.current.setErrors(errors);

            const sendData = {
                name: data.name,
                percent_off: data?.percentOff ? Number(data.percentOff) : null,
                amount_off: data?.amountOff ? Number(data.amountOff) : null,
                duration: Number(data.duration),
                currency: allowedCurrencies.filter(
                    (_, index) => data.currency[index]
                ),
            };

            dispatch(setLoading(true));

            const response = await addCoupon(sendData);

            dispatch(setLoading(false));

            if (response) {
                toast.success(pageData.addSuccessText);
                history.push('/coupons');
            }
        } else {
            formRef.current.setErrors(errors);
        }
    }

    function handlePercentOrAmount(isPercent) {
        setIsPercent(isPercent);
    }

    useEffect(() => {
        formRef.current.setFieldValue('is_percent', {
            label: pageData.percentageText,
            value: true,
        });
    }, []);

    useEffect(() => {
        formRef.current.setFieldValue('is_percent', {
            label: pageData.percentageText,
            value: true,
        });
    }, [lang]);

    if (pageData === null) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <FormGroup row>
                        <FormFields
                            fields={pageData.fields.commonFields}
                            handlePercentOrAmount={handlePercentOrAmount}
                        />
                    </FormGroup>
                    {isPercent ? (
                        <FormGroup row>
                            <FormFields
                                fields={pageData.fields.percentFields}
                            />
                        </FormGroup>
                    ) : (
                        <FormGroup row>
                            <FormFields fields={pageData.fields.amountFields} />
                        </FormGroup>
                    )}
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-12">
                            <span>{pageData.currencyText}</span>
                        </FormGroup>
                    </FormGroup>
                    <FormGroup row>
                        <FormFields fields={pageData.fields.currencyFields} />
                    </FormGroup>
                    <FormGroup row>
                        <div className="col-xs-12 col-md-12 text-left">
                            <Button color="primary mr-2" type="submit">
                                {pageData.addText}
                            </Button>
                            <Link to="/coupons" className="btn btn-secondary">
                                {pageData.cancelText}
                            </Link>
                        </div>
                    </FormGroup>
                </Form>
            </CardBody>
            <CardFooter>
                <p
                    dangerouslySetInnerHTML={{
                        __html: pageData.requiredFieldText,
                    }}
                ></p>
            </CardFooter>
        </Card>
    );
}

AddCoupon.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

import Input from '../../../components/inputForm/inputText';
import Select from '../../../components/inputForm/inputSelect';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Nome',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'price_brl',
                        type: 'number',
                        label: 'Preço em Reais (centavos)',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'price_usd',
                        type: 'number',
                        label: 'Preço em dólar (centavos)',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'discount',
                        type: 'number',
                        label: 'Desconto (centavos)',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'max_patients',
                        type: 'number',
                        label: 'Número máximo de pacientes',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'interval',
                        type: 'number',
                        label: 'Intervalo de pagamento',
                        min: 1,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'interval_type',
                        label: 'Tipo de intervalo',
                        options: [{ value: 'months', label: 'Meses' }],
                    },
                    className: 'col-xs-12 col-md-2',
                },
                {
                    component: Input,
                    props: {
                        name: 'max_cycles',
                        type: 'number',
                        label: 'Máximo de meses para expirar (0 para indeterminado)',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Select,
                    props: {
                        name: 'payable_with',
                        label: 'Tipo de pagamento',
                        options: [
                            {
                                value: 'credit_card',
                                label: 'Cartão de crédito',
                            },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'active',
                        label: 'Ativo',
                        options: [
                            { value: false, label: 'Não' },
                            { value: true, label: 'Sim' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'visible',
                        label: 'Visível',
                        options: [
                            { value: false, label: 'Não' },
                            { value: true, label: 'Sim' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'type',
                        label: 'Tipo',
                        options: [
                            { value: 'sleep_diary', label: 'Diário de Sono' },
                            { value: 'lumus', label: 'Dispositivos Lumus' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            lumusPricesFields: [
                {
                    component: Input,
                    props: {
                        name: 'up_to',
                        type: 'number',
                        label: 'Quantidade de dispositivos <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'price_brl',
                        type: 'number',
                        label: 'Preço em Reais (centavos) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'price_usd',
                        type: 'number',
                        label: 'Preço em dólar (centavos) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            editSuccessText: 'Plano editado com sucesso!',
            editButtonText: 'Salvar',
            cancelButtonText: 'Cancelar',
            intervalTypeOptions: {
                months: 'Meses',
            },
            payableWithOptions: {
                credit_card: 'Cartão de crédito',
            },
            yesText: 'Sim',
            noText: 'Não',
            planTypes: {
                sleep_diary: 'Diário de Sono',
                lumus: 'Dispositivos Lumus',
            },
            numberOfDevicesText:
                'Quantidade de dispositivos <b style="color: darkred">*</b>',
            priceBRLText:
                'Preço em Reais (centavos) <b style="color: darkred">*</b>',
            priceUSDText:
                'Preço em dólar (centavos) <b style="color: darkred">*</b>',
            pricesText: 'Preços',
            deletePriceText: 'Remover preço',
            addPriceText: 'Adicionar preço',
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Name',
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'price_brl',
                        type: 'number',
                        label: 'Price in reais (cents)',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'price_usd',
                        type: 'number',
                        label: 'Price in dollar (cents)',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'discount',
                        type: 'number',
                        label: 'Discount (cents)',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'max_patients',
                        type: 'number',
                        label: 'Maximum number of patients',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'interval',
                        type: 'number',
                        label: 'Range of payment',
                        min: 1,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'interval_type',
                        label: 'Interval type',
                        options: [{ value: 'months', label: 'Months' }],
                    },
                    className: 'col-xs-12 col-md-2',
                },
                {
                    component: Input,
                    props: {
                        name: 'max_cycles',
                        type: 'number',
                        label: 'Maximum months to expire (0 for indefinite)',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-5',
                },
                {
                    component: Select,
                    props: {
                        name: 'payable_with',
                        label: 'Payment type',
                        options: [
                            {
                                value: 'credit_card',
                                label: 'Credit card',
                            },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'active',
                        label: 'Active',
                        options: [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'visible',
                        label: 'Visible',
                        options: [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Select,
                    props: {
                        name: 'type',
                        label: 'Type',
                        options: [
                            { value: 'sleep_diary', label: 'Sleep Diary' },
                            { value: 'lumus', label: 'Lumus Devices' },
                        ],
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            lumusPricesFields: [
                {
                    component: Input,
                    props: {
                        name: 'up_to',
                        type: 'number',
                        label: 'Number of devices <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'price_brl',
                        type: 'number',
                        label: 'Price in Reais (cents) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
                {
                    component: Input,
                    props: {
                        name: 'price_usd',
                        type: 'number',
                        label: 'Price in dollar (cents) <b style="color: darkred">*</b>',
                        min: 0,
                    },
                    className: 'col-xs-12 col-md-3',
                },
            ],
            editSuccessText: 'Plan successfully edited!',
            editButtonText: 'Edit',
            cancelButtonText: 'Cancel',
            intervalTypeOptions: {
                months: 'Months',
            },
            payableWithOptions: {
                credit_card: 'Credit card',
            },
            yesText: 'Yes',
            noText: 'No',
            planTypes: {
                sleep_diary: 'Sleep Diary',
                lumus: 'Lumus Devices',
            },
            numberOfDevicesText:
                'Number of devices <b style="color: darkred">*</b>',
            priceBRLText:
                'Price in Reais (cents) <b style="color: darkred">*</b>',
            priceUSDText:
                'Price in dollar (cents) <b style="color: darkred">*</b>',
            pricesText: 'Prices',
            deletePriceText: 'Remove price',
            addPriceText: 'Add price',
        },
    },
};

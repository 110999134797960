import React from 'react';
import PropTypes from 'prop-types';

import { Plot } from '../../../components/plot';

export function AverageChartsContainer({ charts, type, colors }) {
    for (let i = 0; i < charts[type].data.length; i++) {
        charts[type].data[i] = {
            ...charts[type].data[i],
            marker: {
                color: colors[i],
            },
            line: {
                color: colors[i],
            },
        };
    }

    return (
        <>
            <Plot
                data={charts[type].data}
                layout={charts[type].layout}
                config={charts[type].config}
                useResizeHandler={true}
                style={{
                    width: '100%',
                    height: '100%',
                }}
            />
        </>
    );
}

AverageChartsContainer.propTypes = {
    charts: PropTypes.shape({
        daily: PropTypes.shape({
            data: PropTypes.array.isRequired,
            layout: PropTypes.object.isRequired,
            config: PropTypes.object.isRequired,
        }),
        weekly: PropTypes.shape({
            data: PropTypes.array.isRequired,
            layout: PropTypes.object.isRequired,
            config: PropTypes.object.isRequired,
        }),
        monthly: PropTypes.shape({
            data: PropTypes.array.isRequired,
            layout: PropTypes.object.isRequired,
            config: PropTypes.object.isRequired,
        }),
    }).isRequired,
    type: PropTypes.oneOf(['daily', 'weekly', 'monthly']).isRequired,
    colors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

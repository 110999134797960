import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import lodash from 'lodash';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { history } from '../../../services/history';
import { fetchCouponsByCurrency } from '../../../services/api/coupons';
import { fetchCompanySubscription } from '../../../services/api/account';
import { MyForm as Form } from './components/form';
import { setLoading as showLoading } from '../../../store/actions/loadingActions';

CompanySubscription.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function CompanySubscription({ currentUser }) {
    const [loading, setLoading] = useState(true);
    const [company, setCompany] = useState({});
    const [user, setUser] = useState({});
    const [subscription, setSubscription] = useState({});
    const [coupons, setCoupons] = useState(null);
    const [couponsOptions, setCouponsOptions] = useState([]);
    const [couponsFields, setCouponsFields] = useState([]);
    const [currency, setCurrency] = useState({});
    const [actionOptions, setActionOptions] = useState({
        sleep_diary: [],
        lumus: [],
    });

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];
    const schemas = schemaConfig[lang][currentUser.permissions];

    function removeCouponField(index) {
        let tempCoupons = lodash.cloneDeep(couponsFields);
        tempCoupons.splice(index, 1);

        setCouponsFields(tempCoupons);
    }

    function addCouponField() {
        let tempCouponsFields = lodash.cloneDeep(pageData.fields.coupon);
        let couponFieldsUUID = crypto.randomUUID();

        tempCouponsFields[0].props.options = couponsOptions;
        tempCouponsFields[0].key = couponFieldsUUID;
        tempCouponsFields[1].key = couponFieldsUUID;
        tempCouponsFields[2].key = couponFieldsUUID;
        tempCouponsFields[2].props.min = new Date().getFullYear();

        setCouponsFields([...couponsFields, tempCouponsFields]);
    }

    useEffect(() => {
        if (history.location.state?.entity) {
            setCompany(history.location.state.entity);
        } else {
            history.push('/companies');
        }

        (async function () {
            dispatch(showLoading(true));
            setLoading(true);

            const subscriptionData = await fetchCompanySubscription(
                history.location.state?.entity.id
            );

            const couponsData = await fetchCouponsByCurrency(
                subscriptionData?.subscription?.locale === 'pt-BR'
                    ? 'BRL'
                    : 'USD'
            );

            if (subscriptionData) {
                setCurrency({
                    locale:
                        subscriptionData?.subscription?.locale === 'pt-BR'
                            ? 'pt-BR'
                            : 'en-US',
                    format:
                        subscriptionData?.subscription?.locale === 'pt-BR'
                            ? 'BRL'
                            : 'USD',
                });

                setUser({
                    ...subscriptionData?.user,
                    user_name: subscriptionData?.user.name,
                });

                setSubscription(subscriptionData?.subscription);

                let tempActionOptions = {
                    sleep_diary: [],
                    lumus: [],
                };

                subscriptionData?.subscription.plans.forEach(
                    (subscriptionPlan) => {
                        if (subscriptionPlan.plan.name.includes('Trial')) {
                            if (subscriptionPlan.active) {
                                tempActionOptions[subscriptionPlan.type].push(
                                    'extend_trial'
                                );
                                tempActionOptions[subscriptionPlan.type].push(
                                    'suspend'
                                );
                            } else {
                                tempActionOptions[subscriptionPlan.type].push(
                                    'activate_trial'
                                );
                            }
                        } else {
                            if (subscriptionPlan.active) {
                                tempActionOptions[subscriptionPlan.type].push(
                                    'suspend'
                                );
                            } else {
                                tempActionOptions[subscriptionPlan.type].push(
                                    'activate'
                                );
                            }
                        }
                    }
                );

                setActionOptions(tempActionOptions);
            }

            if (couponsData) {
                let tempCouponsOptions = couponsData?.coupons?.map(
                    (coupon) => ({
                        value: coupon.id,
                        label: coupon.name,
                    })
                );

                setCouponsFields(
                    subscriptionData?.subscription?.discounts?.map(
                        (discount) => {
                            let tempCouponsFields = lodash.cloneDeep(
                                pageData.fields.coupon
                            );
                            let couponFieldsUUID = crypto.randomUUID();

                            tempCouponsFields[0].props.options =
                                tempCouponsOptions;
                            tempCouponsFields[0].key = couponFieldsUUID;
                            tempCouponsFields[1].key = couponFieldsUUID;
                            tempCouponsFields[2].key = couponFieldsUUID;
                            tempCouponsFields[2].props.min =
                                new Date().getFullYear();

                            return tempCouponsFields;
                        }
                    )
                );
                setCouponsOptions(tempCouponsOptions);
                setCoupons(couponsData?.coupons);
            }

            dispatch(showLoading(false));
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        if (couponsFields.length > 0) {
            let tempCouponsFields = lodash.cloneDeep(couponsFields);

            tempCouponsFields = tempCouponsFields.map((couponFields) => {
                couponFields[0].props.label = pageData.couponNameText;
                couponFields[1].props.label = pageData.expiryMonthText;
                couponFields[2].props.label = pageData.expiryYearText;

                return couponFields;
            });

            setCouponsFields(tempCouponsFields);
        }

        let tempActionOptions = {
            sleep_diary: [],
            lumus: [],
        };

        subscription?.plans?.forEach((subscriptionPlan) => {
            if (subscriptionPlan.plan.name.includes('Trial')) {
                if (subscriptionPlan.active) {
                    tempActionOptions[subscriptionPlan.type].push(
                        'extend_trial'
                    );
                    tempActionOptions[subscriptionPlan.type].push('suspend');
                } else {
                    tempActionOptions[subscriptionPlan.type].push(
                        'activate_trial'
                    );
                }
            } else {
                if (subscriptionPlan.active) {
                    tempActionOptions[subscriptionPlan.type].push('suspend');
                } else {
                    tempActionOptions[subscriptionPlan.type].push('activate');
                }
            }
        });

        setActionOptions(tempActionOptions);
    }, [lang, subscription]);

    if (loading || coupons === null) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form
                    company={company}
                    user={user}
                    subscription={subscription}
                    setSubscription={setSubscription}
                    coupons={coupons}
                    couponsFields={couponsFields}
                    fields={pageData.fields}
                    schemas={schemas}
                    currency={currency}
                    addCouponField={addCouponField}
                    removeCouponField={removeCouponField}
                    actionOptions={actionOptions}
                    texts={{
                        fidelityTexts: pageData.fidelityTexts,
                        companyText: pageData.companyText,
                        subscriptionText: pageData.subscriptionText,
                        subscriptionPlansText: pageData.subscriptionPlansText,
                        couponsText: pageData.couponsText,
                        addCouponText: pageData.addCouponText,
                        deleteCouponText: pageData.deleteCouponText,
                        applyCouponSuccessText: pageData.applyCouponSuccessText,
                        updateCouponSuccessText:
                            pageData.updateCouponSuccessText,
                        removeCouponSuccessText:
                            pageData.removeCouponSuccessText,
                        editText: pageData.editText,
                        cancelText: pageData.cancelText,
                        yesText: pageData.yesText,
                        noText: pageData.noText,
                        successText: pageData.successText,
                        monthlyPaymentFrequencyText:
                            pageData.monthlyPaymentFrequencyText,
                        unlimitedActivePatientsText:
                            pageData.unlimitedActivePatientsText,
                        activePatientsText: pageData.activePatientsText,
                        unlimitedActiveDevicesText:
                            pageData.unlimitedActiveDevicesText,
                        activeDevicesText: pageData.activeDevicesText,
                        expiresAtText: pageData.expiresAtText,
                        activeText: pageData.activeText,
                        suspendedText: pageData.suspendedText,
                        expiredText: pageData.expiredText,
                        attentionText: pageData.attentionText,
                        closeText: pageData.closeText,
                        unsubscribeText: pageData.unsubscribeText,
                        subscriptionTypeText: pageData.subscriptionTypeText,
                        actionOptionsTexts: pageData.actionOptionsTexts,
                        goBackText: pageData.goBackText,
                        suspendedReasonsTexts: pageData.suspendedReasonsTexts,
                    }}
                />
            </CardBody>
        </Card>
    );
}

export default {
    pt: {
        customer_admin: {
            successText: 'Credenciais enviadas com sucesso!',
            sendText: 'Enviar',
            changeEmailText: 'Modificar e-mail',
            cancelText: 'Cancelar',
            nameText: 'Nome',
            emailText: 'e-Mail <b style="color: darkred">*</b>',
            langText: 'Idioma <b style="color: darkred">*</b>',
            portugueseText: 'Português',
            englishText: 'Inglês',
            germanyText: 'Alemão',
        },
        manager: {
            successText: 'Credenciais enviadas com sucesso!',
            sendText: 'Enviar',
            changeEmailText: 'Modificar e-mail',
            cancelText: 'Cancelar',
            nameText: 'Nome',
            emailText: 'e-Mail <b style="color: darkred">*</b>',
            langText: 'Idioma <b style="color: darkred">*</b>',
            portugueseText: 'Português',
            englishText: 'Inglês',
            germanyText: 'Alemão',
        },
        condor_admin: {
            successText: 'Credenciais enviadas com sucesso!',
            sendText: 'Enviar',
            changeEmailText: 'Modificar e-mail',
            cancelText: 'Cancelar',
            nameText: 'Nome',
            emailText: 'e-Mail <b style="color: darkred">*</b>',
            langText: 'Idioma <b style="color: darkred">*</b>',
            portugueseText: 'Português',
            englishText: 'Inglês',
            germanyText: 'Alemão',
        },
    },
    en: {
        customer_admin: {
            successText: 'Credentials successfully submitted!',
            sendText: 'Submit',
            changeEmailText: 'Change e-mail',
            cancelText: 'Cancel',
            nameText: 'Name',
            emailText: 'e-Mail <b style="color: darkred">*</b>',
            langText: 'Language <b style="color: darkred">*</b>',
            portugueseText: 'Portuguese',
            englishText: 'English',
            germanyText: 'German',
        },
        manager: {
            successText: 'Credentials successfully submitted!',
            sendText: 'Submit',
            changeEmailText: 'Change e-mail',
            cancelText: 'Cancel',
            nameText: 'Name',
            emailText: 'e-Mail <b style="color: darkred">*</b>',
            langText: 'Language <b style="color: darkred">*</b>',
            portugueseText: 'Portuguese',
            englishText: 'English',
            germanyText: 'German',
        },
        condor_admin: {
            successText: 'Credentials successfully submitted!',
            sendText: 'Submit',
            changeEmailText: 'Change e-mail',
            cancelText: 'Cancel',
            nameText: 'Name',
            emailText: 'e-Mail <b style="color: darkred">*</b>',
            langText: 'Language <b style="color: darkred">*</b>',
            portugueseText: 'Portuguese',
            englishText: 'English',
            germanyText: 'German',
        },
    },
};

import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import styles from './addModal.module.css';
import { CustomForm as Form } from './form';

export function AddModal({ texts, fields, handleAddQuestion, schemas }) {
    const [modal, setModal] = useState(false);

    function toggle() {
        setModal(!modal);
    }

    return (
        <>
            <Button
                title={texts.addQuestionText}
                type="button"
                color="primary"
                onClick={toggle}
            >
                <FontAwesomeIcon icon={faPlus} />
            </Button>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader className={styles.headerContainer}>
                    {texts.addText}
                </ModalHeader>
                <ModalBody className={styles.bodyContainer}>
                    <Form
                        schemas={schemas}
                        fields={fields}
                        texts={texts}
                        toggle={toggle}
                        handleAddQuestion={handleAddQuestion}
                    />
                </ModalBody>
            </Modal>
        </>
    );
}

AddModal.propTypes = {
    texts: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    handleAddQuestion: PropTypes.func.isRequired,
    schemas: PropTypes.object.isRequired,
};

import Input from '../../components/inputForm/inputText';
import Select from '../../components/inputForm/inputSelect';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

export default {
    pt: {
        customer_admin: {
            searchByNameText: 'Procurar diário por nome',
            editText: 'Editar',
            saveText: 'Salvar',
            deleteText: 'Excluir',
            activateText: 'Ativar',
            deactivateText: 'Desativar',
            addText: 'Adicionar',
            addQuestionText: 'Adicionar pergunta',
            cancelText: 'Cancelar',
            yesText: 'Sim',
            noText: 'Não',
            activeText: 'Ativa',
            requiredText: 'Obrigatória',
            questionTitleText: 'Título da pergunta',
            answerTypeText: 'Tipo de resposta',
            multipleChoiceQuantityText: 'Quantidade de escolhas',
            choiceText: 'Escolha',
            fewText: 'Pouco',
            lotText: 'Muito',
            noDiariesText: 'Você não possui diários customizados.',
            ths: [
                {
                    name: 'ID',
                    property: 'id',
                },
                {
                    name: 'Nome',
                    property: 'name',
                },
                {
                    name: 'Criado em',
                    property: 'created_at',
                },
                {
                    name: 'Atualizado em',
                    property: 'updated_at',
                },
                {
                    name: 'Ações',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'ID',
                    property: 'id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Nome',
                    property: 'name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Criado em',
                    property: 'created_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Atualizado em',
                    property: 'updated_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Ações',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Editar',
                    className: 'btn btn-secondary ml-2',
                    to: 'custom-diaries/edit',
                    icon: faEdit,
                },
                {
                    title: 'Excluir',
                    className: 'btn btn-danger ml-2',
                    to: 'custom-diaries/delete',
                    icon: faTrash,
                },
            ],
            deleteQuestionText: 'Tem certeza que deseja excluir a pergunta: ',
            addSuccessText: 'Questões do diário adicionadas com sucesso!',
            successResponseText: 'Questões do diário editadas com sucesso!',
            failureResponseText:
                'Não foi possível editar as questões do diário.',
            diaryText: 'Diário',
            defaultQuestionsText: 'Perguntas padrão',
            customQuestionsText: 'Perguntas customizadas',
            answerTypes: {
                DateInput: 'Data',
                DateTimeInput: 'Data e tempo',
                DurationInput: 'Tempo',
                IntInput: 'Número',
                SliderInput: 'Escala visual',
                ToggleInput: 'Sim/Não',
                FreeTextInput: 'Texto',
                ChoiceChipsSelect: 'Múltipla escolha',
            },
            fields: {
                diaryFields: [
                    {
                        component: Input,
                        props: {
                            name: 'name',
                            type: 'text',
                            label: 'Nome',
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                ],
                commonFields: [
                    {
                        component: Input,
                        props: {
                            name: 'fieldtext',
                            type: 'text',
                            label: 'Pergunta',
                        },
                        className: 'col-xs-12 col-md-12',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'type',
                            type: 'text',
                            label: 'Tipo de resposta',
                            options: [
                                {
                                    id: 'DateTimeInput',
                                    value: 'DateTimeInput',
                                    label: 'Data e tempo',
                                },
                                {
                                    id: 'DurationInput',
                                    value: 'DurationInput',
                                    label: 'Tempo',
                                },
                                {
                                    id: 'IntInput',
                                    value: 'IntInput',
                                    label: 'Número',
                                },
                                {
                                    id: 'SliderInput',
                                    value: 'SliderInput',
                                    label: 'Escala visual',
                                },
                                {
                                    id: 'ToggleInput',
                                    value: 'ToggleInput',
                                    label: 'Sim/Não',
                                },
                                {
                                    id: 'FreeTextInput',
                                    value: 'FreeTextInput',
                                    label: 'Texto',
                                },
                                {
                                    id: 'ChoiceChipsSelect',
                                    value: 'ChoiceChipsSelect',
                                    label: 'Múltipla escolha',
                                },
                            ],
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'isVisible',
                            label: 'Ativa',
                            options: [
                                { value: true, label: 'Sim' },
                                { value: false, label: 'Não' },
                            ],
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                ],
                DateTimeInput: [
                    {
                        component: Input,
                        props: {
                            name: 'hoursBefore',
                            type: 'number',
                            label: 'Horas antes',
                            min: 0,
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'hoursAfter',
                            type: 'number',
                            label: 'Horas depois',
                            min: 0,
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                DurationInput: [],
                IntInput: [],
                SliderInput: [
                    {
                        component: Input,
                        props: {
                            name: 'minText',
                            type: 'text',
                            label: 'Mínimo',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'maxText',
                            type: 'text',
                            label: 'Máximo',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                ToggleInput: [
                    {
                        component: Input,
                        props: {
                            name: 'textdescriptor[0]',
                            type: 'text',
                            label: 'Opção 1',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'textdescriptor[1]',
                            type: 'text',
                            label: 'Opção 2',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                FreeTextInput: [],
                ChoiceChipsSelect: {
                    fields: [
                        {
                            component: Input,
                            props: {
                                name: 'quantity',
                                type: 'number',
                                label: 'Quantidade de escolhas',
                                min: 1,
                                max: 5,
                            },
                            className: 'col-xs-12 col-md-6',
                        },
                    ],
                    choices: [
                        {
                            component: Input,
                            props: {
                                name: 'choices[0]',
                                type: 'text',
                                label: 'Escolha 1',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[1]',
                                type: 'text',
                                label: 'Escolha 2',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[2]',
                                type: 'text',
                                label: 'Escolha 3',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[3]',
                                type: 'text',
                                label: 'Escolha 4',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[4]',
                                type: 'text',
                                label: 'Escolha 5',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[5]',
                                type: 'text',
                                label: 'Escolha 6',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[6]',
                                type: 'text',
                                label: 'Escolha 7',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[7]',
                                type: 'text',
                                label: 'Escolha 8',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[8]',
                                type: 'text',
                                label: 'Escolha 9',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                    ],
                },
            },
            testingPeriodText: `Nova funcionalidade em período de testes!
            Em caso de problemas, entre em contato pelo e-mail suporte_diario@condorinst.com.br.`,
            duplicateDiaryText: 'Duplicar diário',
            duplicateDiarySuccessText: 'Diário duplicado com sucesso!',
        },
        manager: {
            searchByNameText: 'Procurar diário por nome',
            editText: 'Editar',
            saveText: 'Salvar',
            deleteText: 'Excluir',
            activateText: 'Ativar',
            deactivateText: 'Desativar',
            addText: 'Adicionar',
            addQuestionText: 'Adicionar pergunta',
            cancelText: 'Cancelar',
            yesText: 'Sim',
            noText: 'Não',
            activeText: 'Ativa',
            requiredText: 'Obrigatória',
            questionTitleText: 'Título da pergunta',
            answerTypeText: 'Tipo de resposta',
            multipleChoiceQuantityText: 'Quantidade de escolhas',
            choiceText: 'Escolha',
            fewText: 'Pouco',
            lotText: 'Muito',
            noDiariesText: 'Você não possui diários customizados.',
            ths: [
                {
                    name: 'ID',
                    property: 'id',
                },
                {
                    name: 'Nome',
                    property: 'name',
                },
                {
                    name: 'Criado em',
                    property: 'created_at',
                },
                {
                    name: 'Atualizado em',
                    property: 'updated_at',
                },
                {
                    name: 'Ações',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'ID',
                    property: 'id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Nome',
                    property: 'name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Criado em',
                    property: 'created_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Atualizado em',
                    property: 'updated_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Ações',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Editar',
                    className: 'btn btn-secondary ml-2',
                    to: 'custom-diaries/edit',
                    icon: faEdit,
                },
                {
                    title: 'Excluir',
                    className: 'btn btn-danger ml-2',
                    to: 'custom-diaries/delete',
                    icon: faTrash,
                },
            ],
            deleteQuestionText: 'Tem certeza que deseja excluir a pergunta: ',
            addSuccessText: 'Questões do diário adicionadas com sucesso!',
            successResponseText: 'Questões do diário editadas com sucesso!',
            failureResponseText:
                'Não foi possível editar as questões do diário.',
            diaryText: 'Diário',
            defaultQuestionsText: 'Perguntas padrão',
            customQuestionsText: 'Perguntas customizadas',
            answerTypes: {
                DateInput: 'Data',
                DateTimeInput: 'Data e tempo',
                DurationInput: 'Tempo',
                IntInput: 'Número',
                SliderInput: 'Escala visual',
                ToggleInput: 'Sim/Não',
                FreeTextInput: 'Texto',
                ChoiceChipsSelect: 'Múltipla escolha',
            },
            fields: {
                diaryFields: [
                    {
                        component: Input,
                        props: {
                            name: 'name',
                            type: 'text',
                            label: 'Nome',
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                ],
                commonFields: [
                    {
                        component: Input,
                        props: {
                            name: 'fieldtext',
                            type: 'text',
                            label: 'Pergunta',
                        },
                        className: 'col-xs-12 col-md-12',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'type',
                            type: 'text',
                            label: 'Tipo de resposta',
                            options: [
                                {
                                    id: 'DateTimeInput',
                                    value: 'DateTimeInput',
                                    label: 'Data e tempo',
                                },
                                {
                                    id: 'DurationInput',
                                    value: 'DurationInput',
                                    label: 'Tempo',
                                },
                                {
                                    id: 'IntInput',
                                    value: 'IntInput',
                                    label: 'Número',
                                },
                                {
                                    id: 'SliderInput',
                                    value: 'SliderInput',
                                    label: 'Escala visual',
                                },
                                {
                                    id: 'ToggleInput',
                                    value: 'ToggleInput',
                                    label: 'Sim/Não',
                                },
                                {
                                    id: 'FreeTextInput',
                                    value: 'FreeTextInput',
                                    label: 'Texto',
                                },
                                {
                                    id: 'ChoiceChipsSelect',
                                    value: 'ChoiceChipsSelect',
                                    label: 'Múltipla escolha',
                                },
                            ],
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'isVisible',
                            label: 'Ativa',
                            options: [
                                { value: true, label: 'Sim' },
                                { value: false, label: 'Não' },
                            ],
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                ],
                DateTimeInput: [
                    {
                        component: Input,
                        props: {
                            name: 'hoursBefore',
                            type: 'number',
                            label: 'Horas antes',
                            min: 0,
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'hoursAfter',
                            type: 'number',
                            label: 'Horas depois',
                            min: 0,
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                DurationInput: [],
                IntInput: [],
                SliderInput: [
                    {
                        component: Input,
                        props: {
                            name: 'minText',
                            type: 'text',
                            label: 'Mínimo',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'maxText',
                            type: 'text',
                            label: 'Máximo',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                ToggleInput: [
                    {
                        component: Input,
                        props: {
                            name: 'textdescriptor[0]',
                            type: 'text',
                            label: 'Opção 1',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'textdescriptor[1]',
                            type: 'text',
                            label: 'Opção 2',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                FreeTextInput: [],
                ChoiceChipsSelect: {
                    fields: [
                        {
                            component: Input,
                            props: {
                                name: 'quantity',
                                type: 'number',
                                label: 'Quantidade de escolhas',
                                min: 1,
                                max: 5,
                            },
                            className: 'col-xs-12 col-md-6',
                        },
                    ],
                    choices: [
                        {
                            component: Input,
                            props: {
                                name: 'choices[0]',
                                type: 'text',
                                label: 'Escolha 1',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[1]',
                                type: 'text',
                                label: 'Escolha 2',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[2]',
                                type: 'text',
                                label: 'Escolha 3',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[3]',
                                type: 'text',
                                label: 'Escolha 4',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[4]',
                                type: 'text',
                                label: 'Escolha 5',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[5]',
                                type: 'text',
                                label: 'Escolha 6',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[6]',
                                type: 'text',
                                label: 'Escolha 7',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[7]',
                                type: 'text',
                                label: 'Escolha 8',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[8]',
                                type: 'text',
                                label: 'Escolha 9',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                    ],
                },
            },
            testingPeriodText: `Nova funcionalidade em período de testes!
            Em caso de problemas, entre em contato pelo e-mail suporte_diario@condorinst.com.br.`,
            duplicateDiaryText: 'Duplicar diário',
            duplicateDiarySuccessText: 'Diário duplicado com sucesso!',
        },
        condor_admin: null,
    },
    en: {
        customer_admin: {
            searchByNameText: 'Search diary by name',
            editText: 'Edit',
            saveText: 'Save',
            deleteText: 'Delete',
            activateText: 'Activate',
            deactivateText: 'Deactivate',
            addText: 'Add',
            addQuestionText: 'Add question',
            cancelText: 'Cancel',
            yesText: 'Yes',
            noText: 'No',
            activeText: 'Active',
            requiredText: 'Required',
            questionTitleText: 'Question title',
            answerTypeText: 'Answer type',
            multipleChoiceQuantityText: 'Choices quantity',
            choiceText: 'Choice',
            fewText: 'Little',
            lotText: 'Much',
            noDiariesText: `You dont't have customized diaries.`,
            ths: [
                {
                    name: 'ID',
                    property: 'id',
                },
                {
                    name: 'Name',
                    property: 'name',
                },
                {
                    name: 'Created at',
                    property: 'created_at',
                },
                {
                    name: 'Updated at',
                    property: 'updated_at',
                },
                {
                    name: 'Actions',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'ID',
                    property: 'id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Name',
                    property: 'name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Created at',
                    property: 'created_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Updated at',
                    property: 'updated_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Actions',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Edit',
                    className: 'btn btn-secondary ml-2',
                    to: 'custom-diaries/edit',
                    icon: faEdit,
                },
                {
                    title: 'Delete',
                    className: 'btn btn-danger ml-2',
                    to: 'custom-diaries/delete',
                    icon: faTrash,
                },
            ],
            deleteQuestionText:
                'Are you sure you want to delete the question: ',
            addSuccessText: 'Diary questions successfully added!',
            successResponseText: 'Diary questions successfully edited!',
            failureResponseText: 'It was not possible to edit diary questions.',
            diaryText: 'Diary',
            defaultQuestionsText: 'Default questions',
            customQuestionsText: 'Customized questions',
            answerTypes: {
                DateInput: 'Date',
                DateTimeInput: 'Date and time',
                DurationInput: 'Time',
                IntInput: 'Number',
                SliderInput: 'Visual Scale',
                ToggleInput: 'Yes/No',
                FreeTextInput: 'Text',
                ChoiceChipsSelect: 'Multiple choice',
            },
            fields: {
                diaryFields: [
                    {
                        component: Input,
                        props: {
                            name: 'name',
                            type: 'text',
                            label: 'Name',
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                ],
                commonFields: [
                    {
                        component: Input,
                        props: {
                            name: 'fieldtext',
                            type: 'text',
                            label: 'Question',
                        },
                        className: 'col-xs-12 col-md-12',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'type',
                            type: 'text',
                            label: 'Answer type',
                            options: [
                                {
                                    id: 'DateTimeInput',
                                    value: 'DateTimeInput',
                                    label: 'Date and time',
                                },
                                {
                                    id: 'DurationInput',
                                    value: 'DurationInput',
                                    label: 'Time',
                                },
                                {
                                    id: 'IntInput',
                                    value: 'IntInput',
                                    label: 'Number',
                                },
                                {
                                    id: 'SliderInput',
                                    value: 'SliderInput',
                                    label: 'Visual Scale',
                                },
                                {
                                    id: 'ToggleInput',
                                    value: 'ToggleInput',
                                    label: 'Yes/No',
                                },
                                {
                                    id: 'FreeTextInput',
                                    value: 'FreeTextInput',
                                    label: 'Text',
                                },
                                {
                                    id: 'ChoiceChipsSelect',
                                    value: 'ChoiceChipsSelect',
                                    label: 'Multiple choice',
                                },
                            ],
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'isVisible',
                            label: 'Active',
                            options: [
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                            ],
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                ],
                DateTimeInput: [
                    {
                        component: Input,
                        props: {
                            name: 'hoursBefore',
                            type: 'number',
                            label: 'Hours before',
                            min: 0,
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'hoursAfter',
                            type: 'number',
                            label: 'Hours after',
                            min: 0,
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                DurationInput: [],
                IntInput: [],
                SliderInput: [
                    {
                        component: Input,
                        props: {
                            name: 'minText',
                            type: 'text',
                            label: 'Minimum',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'maxText',
                            type: 'text',
                            label: 'Maximum',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                ToggleInput: [
                    {
                        component: Input,
                        props: {
                            name: 'textdescriptor[0]',
                            type: 'text',
                            label: 'Option 1',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'textdescriptor[1]',
                            type: 'text',
                            label: 'Option 2',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                FreeTextInput: [],
                ChoiceChipsSelect: {
                    fields: [
                        {
                            component: Input,
                            props: {
                                name: 'quantity',
                                type: 'number',
                                label: 'Choices quantity',
                                min: 1,
                                max: 5,
                            },
                            className: 'col-xs-12 col-md-6',
                        },
                    ],
                    choices: [
                        {
                            component: Input,
                            props: {
                                name: 'choices[0]',
                                type: 'text',
                                label: 'Choice 1',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[1]',
                                type: 'text',
                                label: 'Choice 2',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[2]',
                                type: 'text',
                                label: 'Choice 3',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[3]',
                                type: 'text',
                                label: 'Choice 4',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[4]',
                                type: 'text',
                                label: 'Choice 5',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[5]',
                                type: 'text',
                                label: 'Choice 6',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[6]',
                                type: 'text',
                                label: 'Choice 7',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[7]',
                                type: 'text',
                                label: 'Choice 8',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[8]',
                                type: 'text',
                                label: 'Choice 9',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                    ],
                },
            },
            testingPeriodText: `New functionality in testing period!
            In case of problems, please contact us by email at suporte_diario@condorinst.com.br.`,
            duplicateDiaryText: 'Duplicate diary',
            duplicateDiarySuccessText: 'Diary duplicated successfully!',
        },
        manager: {
            searchByNameText: 'Search diary by name',
            editText: 'Edit',
            saveText: 'Save',
            deleteText: 'Delete',
            activateText: 'Activate',
            deactivateText: 'Deactivate',
            addText: 'Add',
            addQuestionText: 'Add question',
            cancelText: 'Cancel',
            yesText: 'Yes',
            noText: 'No',
            activeText: 'Active',
            requiredText: 'Required',
            questionTitleText: 'Question title',
            answerTypeText: 'Answer type',
            multipleChoiceQuantityText: 'Choices quantity',
            choiceText: 'Choice',
            fewText: 'Little',
            lotText: 'Much',
            noDiariesText: `You dont't have customized diaries.`,
            ths: [
                {
                    name: 'ID',
                    property: 'id',
                },
                {
                    name: 'Name',
                    property: 'name',
                },
                {
                    name: 'Created at',
                    property: 'created_at',
                },
                {
                    name: 'Updated at',
                    property: 'updated_at',
                },
                {
                    name: 'Actions',
                    property: 'actions',
                },
            ],
            tds: [
                {
                    th: 'ID',
                    property: 'id',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Name',
                    property: 'name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Created at',
                    property: 'created_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Updated at',
                    property: 'updated_at',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Actions',
                    property: 'actions',
                    hasLink: false,
                    to: '',
                },
            ],
            actions: [
                {
                    title: 'Edit',
                    className: 'btn btn-secondary ml-2',
                    to: 'custom-diaries/edit',
                    icon: faEdit,
                },
                {
                    title: 'Delete',
                    className: 'btn btn-danger ml-2',
                    to: 'custom-diaries/delete',
                    icon: faTrash,
                },
            ],
            deleteQuestionText:
                'Are you sure you want to delete the question: ',
            addSuccessText: 'Diary questions successfully added!',
            successResponseText: 'Diary questions successfully edited!',
            failureResponseText: 'It was not possible to edit diary questions.',
            diaryText: 'Diary',
            defaultQuestionsText: 'Default questions',
            customQuestionsText: 'Customized questions',
            answerTypes: {
                DateInput: 'Date',
                DateTimeInput: 'Date and time',
                DurationInput: 'Time',
                IntInput: 'Number',
                SliderInput: 'Visual Scale',
                ToggleInput: 'Yes/No',
                FreeTextInput: 'Text',
                ChoiceChipsSelect: 'Multiple choice',
            },
            fields: {
                diaryFields: [
                    {
                        component: Input,
                        props: {
                            name: 'name',
                            type: 'text',
                            label: 'Name',
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                ],
                commonFields: [
                    {
                        component: Input,
                        props: {
                            name: 'fieldtext',
                            type: 'text',
                            label: 'Question',
                        },
                        className: 'col-xs-12 col-md-12',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'type',
                            type: 'text',
                            label: 'Answer type',
                            options: [
                                {
                                    id: 'DateTimeInput',
                                    value: 'DateTimeInput',
                                    label: 'Date and time',
                                },
                                {
                                    id: 'DurationInput',
                                    value: 'DurationInput',
                                    label: 'Time',
                                },
                                {
                                    id: 'IntInput',
                                    value: 'IntInput',
                                    label: 'Number',
                                },
                                {
                                    id: 'SliderInput',
                                    value: 'SliderInput',
                                    label: 'Visual Scale',
                                },
                                {
                                    id: 'ToggleInput',
                                    value: 'ToggleInput',
                                    label: 'Yes/No',
                                },
                                {
                                    id: 'FreeTextInput',
                                    value: 'FreeTextInput',
                                    label: 'Text',
                                },
                                {
                                    id: 'ChoiceChipsSelect',
                                    value: 'ChoiceChipsSelect',
                                    label: 'Multiple choice',
                                },
                            ],
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                    {
                        component: Select,
                        props: {
                            name: 'isVisible',
                            label: 'Active',
                            options: [
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                            ],
                        },
                        className: 'col-xs-12 col-md-6',
                    },
                ],
                DateTimeInput: [
                    {
                        component: Input,
                        props: {
                            name: 'hoursBefore',
                            type: 'number',
                            label: 'Hours before',
                            min: 0,
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'hoursAfter',
                            type: 'number',
                            label: 'Hours after',
                            min: 0,
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                DurationInput: [],
                IntInput: [],
                SliderInput: [
                    {
                        component: Input,
                        props: {
                            name: 'minText',
                            type: 'text',
                            label: 'Minimum',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'maxText',
                            type: 'text',
                            label: 'Maximum',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                ToggleInput: [
                    {
                        component: Input,
                        props: {
                            name: 'textdescriptor[0]',
                            type: 'text',
                            label: 'Option 1',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                    {
                        component: Input,
                        props: {
                            name: 'textdescriptor[1]',
                            type: 'text',
                            label: 'Option 2',
                        },
                        className: 'col-xs-12 col-md-5',
                    },
                ],
                FreeTextInput: [],
                ChoiceChipsSelect: {
                    fields: [
                        {
                            component: Input,
                            props: {
                                name: 'quantity',
                                type: 'number',
                                label: 'Choices quantity',
                                min: 1,
                                max: 5,
                            },
                            className: 'col-xs-12 col-md-6',
                        },
                    ],
                    choices: [
                        {
                            component: Input,
                            props: {
                                name: 'choices[0]',
                                type: 'text',
                                label: 'Choice 1',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[1]',
                                type: 'text',
                                label: 'Choice 2',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[2]',
                                type: 'text',
                                label: 'Choice 3',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[3]',
                                type: 'text',
                                label: 'Choice 4',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[4]',
                                type: 'text',
                                label: 'Choice 5',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[5]',
                                type: 'text',
                                label: 'Choice 6',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[6]',
                                type: 'text',
                                label: 'Choice 7',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[7]',
                                type: 'text',
                                label: 'Choice 8',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                        {
                            component: Input,
                            props: {
                                name: 'choices[8]',
                                type: 'text',
                                label: 'Choice 9',
                            },
                            className: 'col-xs-12 col-md-5',
                        },
                    ],
                },
            },
            testingPeriodText: `New functionality in testing period!
            In case of problems, please contact us by email at suporte_diario@condorinst.com.br.`,
            duplicateDiaryText: 'Duplicate diary',
            duplicateDiarySuccessText: 'Diary duplicated successfully!',
        },
        condor_admin: null,
    },
};

import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';

Disable2faModal.propTypes = {
    onDisable2fa: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    texts: PropTypes.shape({
        disableTwoFactorAuthenticationText: PropTypes.string.isRequired,
        sureToDisableTwoFactorAuthenticationText: PropTypes.string.isRequired,
        disableText: PropTypes.string.isRequired,
        cancelText: PropTypes.string.isRequired,
    }).isRequired,
};

export function Disable2faModal({ onDisable2fa, toggle, isModalOpen, texts }) {
    async function handleDisable2fa() {
        await onDisable2fa();

        toggle();
    }

    return (
        <Modal isOpen={isModalOpen} toggle={toggle} keyboard={false}>
            <ModalHeader toggle={toggle}>
                {texts.disableTwoFactorAuthenticationText}
            </ModalHeader>

            <ModalBody>
                <p>{texts.sureToDisableTwoFactorAuthenticationText}</p>
            </ModalBody>

            <ModalFooter>
                <Button color="primary" onClick={() => handleDisable2fa()}>
                    {texts.disableText}
                </Button>

                <Button color="secondary" onClick={toggle}>
                    {texts.cancelText}
                </Button>
            </ModalFooter>
        </Modal>
    );
}

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import { MyForm as Form } from './components/form';
import history from '../../../services/history';

DeleteCompany.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function DeleteCompany({ currentUser }) {
    const [company, setCompany] = useState({});

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];

    useEffect(() => {
        if (history.location.state?.entity) {
            setCompany(history.location.state.entity);
        } else {
            history.push('/companies');
        }
    }, []);

    if (Object.entries(company).length === 0) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form
                    company={company}
                    fields={pageData.fields}
                    texts={{
                        successText: pageData.successText,
                        deleteText: pageData.deleteText,
                        cancelText: pageData.cancelText,
                        yesText: pageData.yesText,
                        noText: pageData.noText,
                        notDefinedText: pageData.notDefinedText,
                        defaultLanguageOptions: pageData.defaultLanguageOptions,
                    }}
                />
            </CardBody>
        </Card>
    );
}

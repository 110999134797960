export default {
    pt: {
        customer_admin: null,
        manager: {
            ths: ['Nome', 'Preço', 'Número máximo de pacientes'],
            tds: [
                {
                    th: 'Nome',
                    property: 'name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Preço',
                    property: 'price',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Número máximo de pacientes',
                    property: 'max_patients',
                    hasLink: false,
                    to: '',
                },
            ],
            planPaymentTypeText: {
                months: '/mês',
                weeks: '/semana',
            },
            cardTitle: 'Escolher assinatura',
            payableWith: {
                credit_card: 'Cartão de crédito',
            },
            subscriptionPaymentTypeText: {
                months: '/mês',
                weeks: '/semana',
            },
            subscriptionFidelityText: {
                monthly: 'Mensal',
                biannual: 'Semestral <b style="color: darkred">*</b>',
                annual: 'Anual <b style="color: darkred">*</b>',
            },
            saveMoneyText: 'Você economiza',
            currentSubscriptionButtonText: 'Assinatura atual',
            chooseText: 'Escolher',
            fineCancellationText: {
                monthly: ``,
                biannual: `<b style="color: darkred">*</b> Atenção! Há multa em caso de cancelamento antes do
                tempo escolhido.`,
                annual: `<b style="color: darkred">*</b> Atenção! Há multa em caso de cancelamento antes do
                tempo escolhido.`,
            },
            cancelButtonText: 'Voltar',
            numberOfDevicesText: 'Quantidade de dispositivos',
            devicesText: '/dispositivo',
            monthlyText: 'Mensal',
            biannualText: 'Semestral',
            annualText: 'Anual',
            creditCardText: 'Cartão de crédito',
            upToText: 'Até',
            fromText: 'A partir de',
            fidelityText: {
                monthly: 'Sem fidelidade',
                biannual: 'Fidelidade de 6 meses',
                annual: 'Fidelidade de 1 ano',
            },
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            ths: ['Name', 'Price', 'Maximum number of patients'],
            tds: [
                {
                    th: 'Name',
                    property: 'name',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Price',
                    property: 'price',
                    hasLink: false,
                    to: '',
                },
                {
                    th: 'Maximum number of patients',
                    property: 'max_patients',
                    hasLink: false,
                    to: '',
                },
            ],
            planPaymentTypeText: {
                months: '/mo',
                weeks: '/week',
            },
            cardTitle: 'Choose subscription',
            payableWith: {
                credit_card: 'Credit card',
            },
            subscriptionPaymentTypeText: {
                months: '/mo',
                weeks: '/week',
            },
            subscriptionFidelityText: {
                monthly: 'Monthly <b style="color: darkred">*</b>',
                biannual: 'Semestral <b style="color: darkred">*</b>',
                annual: 'Annual <b style="color: darkred">*</b>',
            },
            saveMoneyText: 'You save',
            currentSubscriptionButtonText: 'Current subscription',
            chooseText: 'Choose',
            fineCancellationText: {
                monthly: ``,
                biannual: `<b style="color: darkred">*</b> Attention! There is a fine in case of cancellation before the
                chosen period.`,
                annual: `<b style="color: darkred">*</b> Attention! There is a fine in case of cancellation before the
                chosen period.`,
            },
            cancelButtonText: 'Return',
            numberOfDevicesText: 'Amount of devices',
            devicesText: '/device',
            monthlyText: 'Monthly',
            biannualText: 'Biannual',
            annualText: 'Annual',
            creditCardText: 'Credit card',
            upToText: 'Up to',
            fromText: 'From',
            fidelityText: {
                monthly: 'No fidelity',
                biannual: '6-month fidelity',
                annual: '1-year fidelity',
            },
        },
        condor_admin: null,
    },
};

export default {
    pt: {
        customer_admin: null,
        manager: {
            planPaymentTypeText: {
                months: '/mês',
                weeks: '/semana',
            },
            fidelityText: {
                monthly: 'Sem fidelidade',
                biannual: 'Fidelidade de 6 meses',
                annual: 'Fidelidade de 1 ano',
            },
            maxPatientsText: 'pacientes',
            currentPlanButtonText: 'Plano atual',
            chooseText: 'Escolher',
            chooseSubscriptionText: 'Escolher assinatura',
            cancelButtonText: 'Voltar',
            numberOfDevicesText: 'Quantidade de dispositivos',
            devicesText: '/dispositivo',
            monthlyText: 'Mensal',
            biannualText: 'Semestral',
            annualText: 'Anual',
            creditCardText: 'Cartão de crédito',
            saveMoneyText: 'Você economiza',
            fineCancelationText:
                'Atenção! Há multa em caso de cancelamento antes do tempo escolhido.',
            upToText: 'Até',
            fromText: 'A partir de',
        },
        condor_admin: null,
    },
    en: {
        customer_admin: null,
        manager: {
            planPaymentTypeText: {
                months: '/mo',
                weeks: '/week',
            },
            fidelityText: {
                monthly: 'No fidelity',
                biannual: '6-month fidelity',
                annual: '1-year fidelity',
            },
            maxPatientsText: 'patients',
            currentPlanButtonText: 'Current plan',
            chooseText: 'Choose',
            chooseSubscriptionText: 'Choose subscription',
            cancelButtonText: 'Return',
            numberOfDevicesText: 'Amount of devices',
            devicesText: '/device',
            monthlyText: 'Monthly',
            biannualText: 'Biannual',
            annualText: 'Annual',
            creditCardText: 'Credit card',
            saveMoneyText: 'You save',
            fineCancelationText:
                'Attention! There is a fine in case of cancellation before the chosen period.',
            upToText: 'Up to',
            fromText: 'From',
        },
        condor_admin: null,
    },
};

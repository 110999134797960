import { all, takeLatest, put } from 'redux-saga/effects';

import api from '../../services/api';
import csvApi from '../../services/api/filesApi';
import { changeLocale } from '../actions/localeActions';
import { LOCALE } from '../constants';

export function* setLocale({ payload }) {
    const { dateLocale, currencyLocale, currencyFormat } = payload;

    api.defaults.headers.Locale = currencyLocale;
    csvApi.defaults.headers.Locale = currencyLocale;

    yield put(changeLocale(dateLocale, currencyLocale, currencyFormat));
}

export function setLocaleHeader({ payload }) {
    if (!payload) return;

    const { currencyLocale } = payload.locale;

    if (currencyLocale) {
        api.defaults.headers.Locale = currencyLocale;
        csvApi.defaults.headers.Locale = currencyLocale;
    }
}

export default all([
    takeLatest('persist/REHYDRATE', setLocaleHeader),
    takeLatest(LOCALE.CHANGE_LOCALE_REQUEST, setLocale),
]);

import React from 'react';
import PropTypes from 'prop-types';

import { TableTds } from './tableTds';

export function TableRows({
    plans,
    tds,
    texts,
    actions,
    locale,
    currency_pt_BR,
    currency_en_US,
}) {
    return plans.map((plan) => {
        return (
            <tr key={plan.id}>
                <TableTds
                    tds={tds}
                    texts={texts}
                    plan={plan}
                    actions={actions}
                    locale={locale}
                    currency_pt_BR={currency_pt_BR}
                    currency_en_US={currency_en_US}
                />
            </tr>
        );
    });
}

TableRows.propTypes = {
    plans: PropTypes.array.isRequired,
    tds: PropTypes.array.isRequired,
    texts: PropTypes.object.isRequired,
    actions: PropTypes.array,
    locale: PropTypes.object.isRequired,
    currency_pt_BR: PropTypes.object.isRequired,
    currency_en_US: PropTypes.object.isRequired,
};

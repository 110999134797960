import React from 'react';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import PropTypes from 'prop-types';

export function TableHeadersWithFilters({
    ths,
    filterThs,
    orderByProperty,
    activeFilter,
    classNames,
}) {
    return ths.map((th) => {
        let thIsInFilter = filterThs.find(
            (filterTh) => filterTh.property === th.property
        );

        if (thIsInFilter) {
            return (
                <th key={th.property}>
                    <div className="d-flex justify-content-center align-items-center">
                        {th.name}
                        <div className="ml-2 d-flex flex-column">
                            <FontAwesomeIcon
                                icon={faCaretUp}
                                className={classnames({
                                    [classNames.active]:
                                        activeFilter.property === th.property &&
                                        activeFilter.order === 'asc',
                                    [classNames.clickableIcon]: true,
                                })}
                                onClick={() =>
                                    orderByProperty(th.property, 'asc')
                                }
                            />
                            <FontAwesomeIcon
                                icon={faCaretDown}
                                className={classnames({
                                    [classNames.active]:
                                        activeFilter.property === th.property &&
                                        activeFilter.order === 'desc',
                                    [classNames.clickableIcon]: true,
                                })}
                                onClick={() =>
                                    orderByProperty(th.property, 'desc')
                                }
                            />
                        </div>
                    </div>
                </th>
            );
        } else {
            return <th key={th.property}>{th.name}</th>;
        }
    });
}

TableHeadersWithFilters.propTypes = {
    ths: PropTypes.array.isRequired,
    filterThs: PropTypes.array.isRequired,
    orderByProperty: PropTypes.func.isRequired,
    activeFilter: PropTypes.object.isRequired,
    classNames: PropTypes.object.isRequired,
};

import Input from '../../../components/inputForm/inputText';

export default {
    pt: {
        customer_admin: {
            deleteText: 'Excluir',
            cancelText: 'Cancelar',
            yesText: 'Sim',
            noText: 'Não',
            activeText: 'Ativa',
            requiredText: 'Obrigatória',
            answerTypeText: 'Tipo de resposta',
            successResponseText: 'Diário excluído com sucesso!',
            diaryText: 'Diário',
            defaultQuestionsText: 'Perguntas padrão',
            customQuestionsText: 'Perguntas customizadas',
            answerTypes: {
                DateInput: 'Data',
                DateTimeInput: 'Data e hora',
                DurationInput: 'Duração',
                IntInput: 'Número',
                SliderInput: 'Escala visual',
                ToggleInput: 'Sim/Não',
                FreeTextInput: 'Texto',
                ChoiceChipsSelect: 'Múltipla escolha',
            },
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Nome',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-6',
                },
            ],
            defaultQuestionsTexts: {
                date: { fieldtext: 'De qual dia estamos falando?' },
                sleepDuration: {
                    fieldtext:
                        'Ao todo, quanto tempo acha que dormiu na noite passada?',
                },
                triedToSleep: {
                    fieldtext: 'Você tentou dormir na noite passada?',
                    textdescriptor: ['Sim', 'Não'],
                },
                wakeTime: { fieldtext: 'A que horas você acordou?' },
                getUpTime: { fieldtext: 'A que horas se levantou da cama?' },
                bedTime: { fieldtext: 'A que horas você foi para a cama?' },
                trySleepStart: { fieldtext: 'A que horas você tentou dormir?' },
                latencyDuration: {
                    fieldtext: 'Quanto tempo demorou a cair no sono?',
                },
                nOfAwk: {
                    fieldtext:
                        'Quantas vezes você despertou ao longo da noite?',
                },
                totalDurationOfAwk: {
                    fieldtext: 'Qual a duração total dos despertares?',
                },
                sleepSatisfaction: {
                    fieldtext: 'O quanto satisfeito(a) você está com seu sono?',
                    minText: 'Pouco',
                    maxText: 'Muito',
                },
                sleepSatisfactionQuality: {
                    fieldtext: 'Como você avalia a qualidade do seu sono?',
                    choices: ['Péssimo', 'Ruim', 'Razoável', 'Bom', 'Ótimo'],
                },
                nOfNaps: { fieldtext: 'Quantas vezes você cochilou ontem?' },
                durationOfNaps: { fieldtext: 'Qual a duração dos cochilos?' },
                usesMedicine: {
                    fieldtext:
                        'Você tomou medicamentos para dormir na noite passada?',
                    textdescriptor: ['Sim', 'Não'],
                },
                medicineDescription: { fieldtext: 'Descreva os medicamentos:' },
                dayType: {
                    fieldtext:
                        'Hoje é um dia útil (de trabalho, estudo, etc...)?',
                    textdescriptor: ['Sim', 'Não'],
                },
                comments: { fieldtext: 'Você: tem algo a comentar?' },
            },
            defaultQuestions: [
                {
                    descriptor: {
                        id: 'date',
                        type: 'DateInput',
                        fieldtext: 'De qual dia estamos falando?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'sleepDuration',
                        type: 'DurationInput',
                        fieldtext:
                            'Ao todo, quanto tempo acha que dormiu na noite passada?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'triedToSleep',
                        type: 'ToggleInput',
                        fieldtext: 'Você tentou dormir na noite passada?',
                        textdescriptor: ['Sim', 'Não'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'wakeTime',
                        type: 'DateTimeInput',
                        fieldtext: 'A que horas você acordou?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'getUpTime',
                        type: 'DateTimeInput',
                        fieldtext: 'A que horas se levantou da cama?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'bedTime',
                        type: 'DateTimeInput',
                        fieldtext: 'A que horas você foi para a cama?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'trySleepStart',
                        type: 'DateTimeInput',
                        fieldtext: 'A que horas você tentou dormir?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'latencyDuration',
                        type: 'DurationInput',
                        fieldtext: 'Quanto tempo demorou a cair no sono?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'nOfAwk',
                        type: 'IntInput',
                        fieldtext:
                            'Quantas vezes você despertou ao longo da noite?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'totalDurationOfAwk',
                        type: 'DurationInput',
                        fieldtext: 'Qual a duração total dos despertares?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'sleepSatisfaction',
                        type: 'SliderInput',
                        fieldtext:
                            'O quanto satisfeito(a) você está com seu sono?',
                        minText: 'Pouco',
                        maxText: 'Muito',
                        default: true,
                        required: false,
                        isVisible: true,
                        editable: true,
                    },
                },
                {
                    descriptor: {
                        id: 'sleepSatisfactionQuality',
                        type: 'ChoiceChipsSelect',
                        fieldtext: 'Como você avalia a qualidade do seu sono?',
                        choices: [
                            'Péssimo',
                            'Ruim',
                            'Razoável',
                            'Bom',
                            'Ótimo',
                        ],
                        default: true,
                        required: false,
                        isVisible: false,
                        editable: true,
                    },
                },
                {
                    descriptor: {
                        id: 'nOfNaps',
                        type: 'IntInput',
                        fieldtext: 'Quantas vezes você cochilou ontem?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'durationOfNaps',
                        type: 'DurationInput',
                        fieldtext: 'Qual a duração dos cochilos?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'usesMedicine',
                        type: 'ToggleInput',
                        fieldtext:
                            'Você tomou medicamentos para dormir na noite passada?',
                        textdescriptor: ['Sim', 'Não'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'medicineDescription',
                        type: 'FreeTextInput',
                        fieldtext: 'Descreva os medicamentos:',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'dayType',
                        type: 'ToggleInput',
                        fieldtext:
                            'Hoje é um dia útil (de trabalho, estudo, etc...)?',
                        textdescriptor: ['Sim', 'Não'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'comments',
                        type: 'FreeTextInput',
                        fieldtext: 'Você: tem algo a comentar?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
            ],
        },
        manager: {
            deleteText: 'Excluir',
            cancelText: 'Cancelar',
            yesText: 'Sim',
            noText: 'Não',
            activeText: 'Ativa',
            requiredText: 'Obrigatória',
            answerTypeText: 'Tipo de resposta',
            successResponseText: 'Diário excluído com sucesso!',
            diaryText: 'Diário',
            defaultQuestionsText: 'Perguntas padrão',
            customQuestionsText: 'Perguntas customizadas',
            answerTypes: {
                DateInput: 'Data',
                DateTimeInput: 'Data e hora',
                DurationInput: 'Duração',
                IntInput: 'Número',
                SliderInput: 'Escala visual',
                ToggleInput: 'Sim/Não',
                FreeTextInput: 'Texto',
                ChoiceChipsSelect: 'Múltipla escolha',
            },
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Nome',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-6',
                },
            ],
            defaultQuestionsTexts: {
                date: { fieldtext: 'De qual dia estamos falando?' },
                sleepDuration: {
                    fieldtext:
                        'Ao todo, quanto tempo acha que dormiu na noite passada?',
                },
                triedToSleep: {
                    fieldtext: 'Você tentou dormir na noite passada?',
                    textdescriptor: ['Sim', 'Não'],
                },
                wakeTime: { fieldtext: 'A que horas você acordou?' },
                getUpTime: { fieldtext: 'A que horas se levantou da cama?' },
                bedTime: { fieldtext: 'A que horas você foi para a cama?' },
                trySleepStart: { fieldtext: 'A que horas você tentou dormir?' },
                latencyDuration: {
                    fieldtext: 'Quanto tempo demorou a cair no sono?',
                },
                nOfAwk: {
                    fieldtext:
                        'Quantas vezes você despertou ao longo da noite?',
                },
                totalDurationOfAwk: {
                    fieldtext: 'Qual a duração total dos despertares?',
                },
                sleepSatisfaction: {
                    fieldtext: 'O quanto satisfeito(a) você está com seu sono?',
                    minText: 'Pouco',
                    maxText: 'Muito',
                },
                sleepSatisfactionQuality: {
                    fieldtext: 'Como você avalia a qualidade do seu sono?',
                    choices: ['Péssimo', 'Ruim', 'Razoável', 'Bom', 'Ótimo'],
                },
                nOfNaps: { fieldtext: 'Quantas vezes você cochilou ontem?' },
                durationOfNaps: { fieldtext: 'Qual a duração dos cochilos?' },
                usesMedicine: {
                    fieldtext:
                        'Você tomou medicamentos para dormir na noite passada?',
                    textdescriptor: ['Sim', 'Não'],
                },
                medicineDescription: { fieldtext: 'Descreva os medicamentos:' },
                dayType: {
                    fieldtext:
                        'Hoje é um dia útil (de trabalho, estudo, etc...)?',
                    textdescriptor: ['Sim', 'Não'],
                },
                comments: { fieldtext: 'Você: tem algo a comentar?' },
            },
            defaultQuestions: [
                {
                    descriptor: {
                        id: 'date',
                        type: 'DateInput',
                        fieldtext: 'De qual dia estamos falando?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'sleepDuration',
                        type: 'DurationInput',
                        fieldtext:
                            'Ao todo, quanto tempo acha que dormiu na noite passada?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'triedToSleep',
                        type: 'ToggleInput',
                        fieldtext: 'Você tentou dormir na noite passada?',
                        textdescriptor: ['Sim', 'Não'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'wakeTime',
                        type: 'DateTimeInput',
                        fieldtext: 'A que horas você acordou?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'getUpTime',
                        type: 'DateTimeInput',
                        fieldtext: 'A que horas se levantou da cama?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'bedTime',
                        type: 'DateTimeInput',
                        fieldtext: 'A que horas você foi para a cama?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'trySleepStart',
                        type: 'DateTimeInput',
                        fieldtext: 'A que horas você tentou dormir?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'latencyDuration',
                        type: 'DurationInput',
                        fieldtext: 'Quanto tempo demorou a cair no sono?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'nOfAwk',
                        type: 'IntInput',
                        fieldtext:
                            'Quantas vezes você despertou ao longo da noite?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'totalDurationOfAwk',
                        type: 'DurationInput',
                        fieldtext: 'Qual a duração total dos despertares?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'sleepSatisfaction',
                        type: 'SliderInput',
                        fieldtext:
                            'O quanto satisfeito(a) você está com seu sono?',
                        minText: 'Pouco',
                        maxText: 'Muito',
                        default: true,
                        required: false,
                        isVisible: true,
                        editable: true,
                    },
                },
                {
                    descriptor: {
                        id: 'sleepSatisfactionQuality',
                        type: 'ChoiceChipsSelect',
                        fieldtext: 'Como você avalia a qualidade do seu sono?',
                        choices: [
                            'Péssimo',
                            'Ruim',
                            'Razoável',
                            'Bom',
                            'Ótimo',
                        ],
                        default: true,
                        required: false,
                        isVisible: false,
                        editable: true,
                    },
                },
                {
                    descriptor: {
                        id: 'nOfNaps',
                        type: 'IntInput',
                        fieldtext: 'Quantas vezes você cochilou ontem?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'durationOfNaps',
                        type: 'DurationInput',
                        fieldtext: 'Qual a duração dos cochilos?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'usesMedicine',
                        type: 'ToggleInput',
                        fieldtext:
                            'Você tomou medicamentos para dormir na noite passada?',
                        textdescriptor: ['Sim', 'Não'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'medicineDescription',
                        type: 'FreeTextInput',
                        fieldtext: 'Descreva os medicamentos:',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'dayType',
                        type: 'ToggleInput',
                        fieldtext:
                            'Hoje é um dia útil (de trabalho, estudo, etc...)?',
                        textdescriptor: ['Sim', 'Não'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'comments',
                        type: 'FreeTextInput',
                        fieldtext: 'Você: tem algo a comentar?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
            ],
        },
        condor_admin: null,
    },
    en: {
        customer_admin: {
            deleteText: 'Delete',
            cancelText: 'Cancel',
            yesText: 'Yes',
            noText: 'No',
            activeText: 'Active',
            requiredText: 'Required',
            answerTypeText: 'Answer type',
            successResponseText: 'Diary successfully deleted!',
            diaryText: 'Diary',
            defaultQuestionsText: 'Default questions',
            customQuestionsText: 'Customized questions',
            answerTypes: {
                DateInput: 'Date',
                DateTimeInput: 'Date and time',
                DurationInput: 'Duration',
                IntInput: 'Number',
                SliderInput: 'Visual Scale',
                ToggleInput: 'Yes/No',
                FreeTextInput: 'Text',
                ChoiceChipsSelect: 'Multiple choice',
            },
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Name',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-6',
                },
            ],
            defaultQuestionsTexts: {
                date: { fieldtext: 'What day are we talking about?' },
                sleepDuration: {
                    fieldtext: 'In total, how long did you sleep last night?',
                },
                triedToSleep: {
                    fieldtext: 'Did you try to sleep last night?',
                    textdescriptor: ['Yes', 'No'],
                },
                wakeTime: { fieldtext: 'What time was your final awakening?' },
                getUpTime: {
                    fieldtext: 'What time did you get out of bed for the day?',
                },
                bedTime: { fieldtext: 'What time did you get into bed?' },
                trySleepStart: {
                    fieldtext: 'What time did you try to go to sleep?',
                },
                latencyDuration: {
                    fieldtext: 'How long did it take you to fall asleep?',
                },
                nOfAwk: {
                    fieldtext:
                        'How many times did you wake up, not counting your final awakening?',
                },
                totalDurationOfAwk: {
                    fieldtext: 'In total, how long did these awakenings last?',
                },
                sleepSatisfaction: {
                    fieldtext: 'How satisfied are you with your sleep?',
                    minText: 'Little',
                    maxText: 'Much',
                },
                sleepSatisfactionQuality: {
                    fieldtext: 'How would you rate the quality of your sleep?',
                    choices: ['Very poor', 'Poor', 'Fair', 'Good', 'Very good'],
                },
                nOfNaps: {
                    fieldtext: 'How many times did you nap or doze yesterday?',
                },
                durationOfNaps: {
                    fieldtext: 'How long did the naps or dozes last?',
                },
                usesMedicine: {
                    fieldtext:
                        'Did you take any medication(s) to help you sleep?',
                    textdescriptor: ['Yes', 'No'],
                },
                medicineDescription: {
                    fieldtext:
                        'If so, list medication(s), dose, and time taken:',
                },
                dayType: {
                    fieldtext: 'Is today a working day (work, study, etc ...)?',
                    textdescriptor: ['Yes', 'No'],
                },
                comments: { fieldtext: 'Do you: have anything to say?' },
            },
            defaultQuestions: [
                {
                    descriptor: {
                        id: 'date',
                        type: 'DateInput',
                        fieldtext: 'What day are we talking about?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'sleepDuration',
                        type: 'DurationInput',
                        fieldtext:
                            'In total, how long did you sleep last night?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'triedToSleep',
                        type: 'ToggleInput',
                        fieldtext: 'Did you try to sleep last night?',
                        textdescriptor: ['Yes', 'No'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'wakeTime',
                        type: 'DateTimeInput',
                        fieldtext: 'What time was your final awakening?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'getUpTime',
                        type: 'DateTimeInput',
                        fieldtext:
                            'What time did you get out of bed for the day?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'bedTime',
                        type: 'DateTimeInput',
                        fieldtext: 'What time did you get into bed?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'trySleepStart',
                        type: 'DateTimeInput',
                        fieldtext: 'What time did you try to go to sleep?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'latencyDuration',
                        type: 'DurationInput',
                        fieldtext: 'How long did it take you to fall asleep?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'nOfAwk',
                        type: 'IntInput',
                        fieldtext:
                            'How many times did you wake up, not counting your final awakening?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'totalDurationOfAwk',
                        type: 'DurationInput',
                        fieldtext:
                            'In total, how long did these awakenings last?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'sleepSatisfaction',
                        type: 'SliderInput',
                        fieldtext: 'How satisfied are you with your sleep?',
                        minText: 'Little',
                        maxText: 'Much',
                        default: true,
                        required: false,
                        isVisible: true,
                        editable: true,
                    },
                },
                {
                    descriptor: {
                        id: 'sleepSatisfactionQuality',
                        type: 'ChoiceChipsSelect',
                        fieldtext:
                            'How would you rate the quality of your sleep?',
                        choices: [
                            'Very poor',
                            'Poor',
                            'Fair',
                            'Good',
                            'Very good',
                        ],
                        default: true,
                        required: false,
                        isVisible: false,
                        editable: true,
                    },
                },
                {
                    descriptor: {
                        id: 'nOfNaps',
                        type: 'IntInput',
                        fieldtext:
                            'How many times did you nap or doze yesterday?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'durationOfNaps',
                        type: 'DurationInput',
                        fieldtext: 'How long did the naps or dozes last?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'usesMedicine',
                        type: 'ToggleInput',
                        fieldtext:
                            'Did you take any medication(s) to help you sleep?',
                        textdescriptor: ['Yes', 'No'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'medicineDescription',
                        type: 'FreeTextInput',
                        fieldtext:
                            'If so, list medication(s), dose, and time taken:',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'dayType',
                        type: 'ToggleInput',
                        fieldtext:
                            'Is today a working day (work, study, etc ...)?',
                        textdescriptor: ['Yes', 'No'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'comments',
                        type: 'FreeTextInput',
                        fieldtext: 'Do you: have anything to say?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
            ],
        },
        manager: {
            deleteText: 'Delete',
            cancelText: 'Cancel',
            yesText: 'Yes',
            noText: 'No',
            activeText: 'Active',
            requiredText: 'Required',
            answerTypeText: 'Answer type',
            successResponseText: 'Diary successfully deleted!',
            diaryText: 'Diary',
            defaultQuestionsText: 'Default questions',
            customQuestionsText: 'Customized questions',
            answerTypes: {
                DateInput: 'Date',
                DateTimeInput: 'Date and time',
                DurationInput: 'Duration',
                IntInput: 'Number',
                SliderInput: 'Visual Scale',
                ToggleInput: 'Yes/No',
                FreeTextInput: 'Text',
                ChoiceChipsSelect: 'Multiple choice',
            },
            fields: [
                {
                    component: Input,
                    props: {
                        name: 'name',
                        type: 'text',
                        label: 'Name',
                        readOnly: true,
                    },
                    className: 'col-xs-12 col-md-6',
                },
            ],
            defaultQuestionsTexts: {
                date: { fieldtext: 'What day are we talking about?' },
                sleepDuration: {
                    fieldtext: 'In total, how long did you sleep last night?',
                },
                triedToSleep: {
                    fieldtext: 'Did you try to sleep last night?',
                    textdescriptor: ['Yes', 'No'],
                },
                wakeTime: { fieldtext: 'What time was your final awakening?' },
                getUpTime: {
                    fieldtext: 'What time did you get out of bed for the day?',
                },
                bedTime: { fieldtext: 'What time did you get into bed?' },
                trySleepStart: {
                    fieldtext: 'What time did you try to go to sleep?',
                },
                latencyDuration: {
                    fieldtext: 'How long did it take you to fall asleep?',
                },
                nOfAwk: {
                    fieldtext:
                        'How many times did you wake up, not counting your final awakening?',
                },
                totalDurationOfAwk: {
                    fieldtext: 'In total, how long did these awakenings last?',
                },
                sleepSatisfaction: {
                    fieldtext: 'How satisfied are you with your sleep?',
                    minText: 'Little',
                    maxText: 'Much',
                },
                sleepSatisfactionQuality: {
                    fieldtext: 'How would you rate the quality of your sleep?',
                    choices: ['Very poor', 'Poor', 'Fair', 'Good', 'Very good'],
                },
                nOfNaps: {
                    fieldtext: 'How many times did you nap or doze yesterday?',
                },
                durationOfNaps: {
                    fieldtext: 'How long did the naps or dozes last?',
                },
                usesMedicine: {
                    fieldtext:
                        'Did you take any medication(s) to help you sleep?',
                    textdescriptor: ['Yes', 'No'],
                },
                medicineDescription: {
                    fieldtext:
                        'If so, list medication(s), dose, and time taken:',
                },
                dayType: {
                    fieldtext: 'Is today a working day (work, study, etc ...)?',
                    textdescriptor: ['Yes', 'No'],
                },
                comments: { fieldtext: 'Do you: have anything to say?' },
            },
            defaultQuestions: [
                {
                    descriptor: {
                        id: 'date',
                        type: 'DateInput',
                        fieldtext: 'What day are we talking about?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'sleepDuration',
                        type: 'DurationInput',
                        fieldtext:
                            'In total, how long did you sleep last night?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'triedToSleep',
                        type: 'ToggleInput',
                        fieldtext: 'Did you try to sleep last night?',
                        textdescriptor: ['Yes', 'No'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'wakeTime',
                        type: 'DateTimeInput',
                        fieldtext: 'What time was your final awakening?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'getUpTime',
                        type: 'DateTimeInput',
                        fieldtext:
                            'What time did you get out of bed for the day?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'bedTime',
                        type: 'DateTimeInput',
                        fieldtext: 'What time did you get into bed?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'trySleepStart',
                        type: 'DateTimeInput',
                        fieldtext: 'What time did you try to go to sleep?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'latencyDuration',
                        type: 'DurationInput',
                        fieldtext: 'How long did it take you to fall asleep?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'nOfAwk',
                        type: 'IntInput',
                        fieldtext:
                            'How many times did you wake up, not counting your final awakening?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'totalDurationOfAwk',
                        type: 'DurationInput',
                        fieldtext:
                            'In total, how long did these awakenings last?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'sleepSatisfaction',
                        type: 'SliderInput',
                        fieldtext: 'How satisfied are you with your sleep?',
                        minText: 'Little',
                        maxText: 'Much',
                        default: true,
                        required: false,
                        isVisible: true,
                        editable: true,
                    },
                },
                {
                    descriptor: {
                        id: 'sleepSatisfactionQuality',
                        type: 'ChoiceChipsSelect',
                        fieldtext:
                            'How would you rate the quality of your sleep?',
                        choices: [
                            'Very poor',
                            'Poor',
                            'Fair',
                            'Good',
                            'Very good',
                        ],
                        default: true,
                        required: false,
                        isVisible: false,
                        editable: true,
                    },
                },
                {
                    descriptor: {
                        id: 'nOfNaps',
                        type: 'IntInput',
                        fieldtext:
                            'How many times did you nap or doze yesterday?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'durationOfNaps',
                        type: 'DurationInput',
                        fieldtext: 'How long did the naps or dozes last?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'usesMedicine',
                        type: 'ToggleInput',
                        fieldtext:
                            'Did you take any medication(s) to help you sleep?',
                        textdescriptor: ['Yes', 'No'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'medicineDescription',
                        type: 'FreeTextInput',
                        fieldtext:
                            'If so, list medication(s), dose, and time taken:',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'dayType',
                        type: 'ToggleInput',
                        fieldtext:
                            'Is today a working day (work, study, etc ...)?',
                        textdescriptor: ['Yes', 'No'],
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
                {
                    descriptor: {
                        id: 'comments',
                        type: 'FreeTextInput',
                        fieldtext: 'Do you: have anything to say?',
                        default: true,
                        required: true,
                        isVisible: true,
                        editable: false,
                    },
                },
            ],
        },
        condor_admin: null,
    },
};

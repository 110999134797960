import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Scope } from '@unform/core';
import { Button, FormGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import {
    addSubscriptionDiscount,
    updateSubscriptionDiscount,
    deleteSubscriptionDiscount,
} from '../../../../services/api/account';
import { validateForm } from '../../../../helpers/formValidator';
import { getMonthToRemoveDiscount } from '../../../../helpers/subscriptionHelpers';
import { setLoading } from '../../../../store/actions/loadingActions';

CouponFields.propTypes = {
    formRef: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    subscription: PropTypes.object.isRequired,
    setSubscription: PropTypes.func.isRequired,
    fields: PropTypes.array.isRequired,
    schema: PropTypes.object.isRequired,
    addCouponField: PropTypes.func.isRequired,
    removeCouponField: PropTypes.func.isRequired,
    texts: PropTypes.shape({
        couponsText: PropTypes.string.isRequired,
        addCouponText: PropTypes.string.isRequired,
        deleteCouponText: PropTypes.string.isRequired,
        applyCouponSuccessText: PropTypes.string.isRequired,
        updateCouponSuccessText: PropTypes.string.isRequired,
        removeCouponSuccessText: PropTypes.string.isRequired,
        editText: PropTypes.string.isRequired,
        cancelText: PropTypes.string.isRequired,
    }).isRequired,
    coupons: PropTypes.array.isRequired,
};

export function CouponFields({
    formRef,
    company,
    subscription,
    setSubscription,
    fields,
    schema,
    addCouponField,
    removeCouponField,
    texts,
    coupons,
}) {
    const [showAddCouponButton, setShowAddCouponButton] = useState(
        subscription.discounts.length === 0
    );
    const [disabledSaveButton, setDisabledSaveButton] = useState(true);
    const [disabledCancelButton, setDisabledCancelButton] = useState(true);

    const dispatch = useDispatch();

    async function handleSaveCoupon() {
        const data = formRef.current.getData().coupons;

        const { parsedData, isValid, errors } = await validateForm(
            data,
            schema
        );

        let hasToRemove = false;
        let hasToUpdate = false;
        let couponToUpdate;
        let hasToAdd = false;
        let couponToAdd;

        let couponsToHandle = parsedData;

        if (subscription.discounts.length > 0) {
            if (couponsToHandle) {
                couponToUpdate = couponsToHandle.find(
                    (coupon) =>
                        coupon.coupon_id === subscription.discounts[0].coupon_id
                );

                if (couponToUpdate) {
                    hasToUpdate = true;
                } else {
                    hasToRemove = true;
                    hasToAdd = true;
                    couponToAdd = couponsToHandle[0];
                }
            } else {
                hasToRemove = true;
            }
        } else {
            if (couponsToHandle) {
                hasToAdd = true;
                couponToAdd = couponsToHandle[0];
            }
            // else {
            //     return;
            // }
        }

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(setLoading(true));

            let removeResponse;
            let updateResponse;
            let addResponse;

            if (hasToRemove) {
                removeResponse = await deleteSubscriptionDiscount(company.id);

                if (removeResponse) {
                    toast.success(texts.removeCouponSuccessText);

                    setSubscription(removeResponse.subscription);

                    setDisabledSaveButton(true);
                    setDisabledCancelButton(true);
                }
            }

            if (hasToUpdate) {
                updateResponse = await updateSubscriptionDiscount(company.id, {
                    coupon_id: couponToUpdate.coupon_id,
                    month_to_remove: Number(couponToUpdate.month_to_remove),
                    year_to_remove: Number(couponToUpdate.year_to_remove),
                });

                if (updateResponse) {
                    toast.success(texts.updateCouponSuccessText);

                    setSubscription(updateResponse.subscription);

                    setDisabledSaveButton(true);
                    setDisabledCancelButton(true);
                }
            }

            if (hasToAdd) {
                addResponse = await addSubscriptionDiscount(company.id, {
                    coupon_id: couponToAdd.coupon_id,
                    month_to_remove: Number(couponToAdd.month_to_remove),
                    year_to_remove: Number(couponToAdd.year_to_remove),
                });

                if (addResponse) {
                    toast.success(texts.applyCouponSuccessText);

                    setSubscription(addResponse.subscription);

                    setDisabledSaveButton(true);
                    setDisabledCancelButton(true);
                }
            }

            dispatch(setLoading(false));
        }
    }

    function handleAddCouponClick() {
        addCouponField();
        setShowAddCouponButton(false);
    }

    function handleRemoveCouponClick(index) {
        if (fields.length === 1) {
            setShowAddCouponButton(true);
        }

        removeCouponField(index);

        if (subscription.discounts.length > 0) {
            setDisabledSaveButton(false);
        } else {
            setDisabledSaveButton(true);
            setDisabledCancelButton(true);
        }
    }

    function handleSetCouponInitialData() {
        let monthToRemove;
        let yearToRemove;

        const selectedCouponId = formRef.current.getFieldValue(
            'coupons[0].coupon_id'
        );

        if (
            subscription.discounts.length > 0 &&
            selectedCouponId === subscription.discounts[0].coupon_id
        ) {
            monthToRemove = subscription.discounts[0].month_to_remove;
            yearToRemove = subscription.discounts[0].year_to_remove;
        } else {
            const coupon = coupons.find((cpn) => cpn.id === selectedCouponId);

            if (!coupon) {
                return;
            }

            let monthAndYearToRemove = getMonthToRemoveDiscount(
                coupon.duration,
                subscription.billing_date
            );

            monthToRemove = monthAndYearToRemove.monthToRemove;
            yearToRemove = monthAndYearToRemove.yearToRemove;
        }

        formRef.current.setFieldValue(
            'coupons[0].month_to_remove',
            monthToRemove
        );
        formRef.current.setFieldValue(
            'coupons[0].year_to_remove',
            yearToRemove
        );
    }

    function handleChangeCoupon(inputName) {
        setTimeout(function () {
            if (inputName === 'coupon_id') {
                handleSetCouponInitialData();
            }

            const formData = formRef.current.getData();

            if (subscription.discounts.length > 0) {
                if (formData.coupons.length > 0) {
                    if (
                        formData.coupons[0].coupon_id === '' ||
                        formData.coupons[0].month_to_remove === '' ||
                        formData.coupons[0].year_to_remove === ''
                    ) {
                        setDisabledSaveButton(true);
                        setDisabledCancelButton(true);
                    } else {
                        if (
                            subscription.discounts[0].coupon_id !==
                                formData.coupons[0].coupon_id ||
                            subscription.discounts[0].month_to_remove !==
                                Number(formData.coupons[0].month_to_remove) ||
                            subscription.discounts[0].year_to_remove !==
                                Number(formData.coupons[0].year_to_remove)
                        ) {
                            setDisabledSaveButton(false);
                            setDisabledCancelButton(false);
                        } else {
                            setDisabledSaveButton(true);
                            setDisabledCancelButton(true);
                        }
                    }
                } else {
                    setDisabledSaveButton(false);
                    setDisabledCancelButton(false);
                }
            } else {
                if (formData.coupons.length > 0) {
                    if (
                        !!formData.coupons[0].coupon_id &&
                        !!formData.coupons[0].month_to_remove &&
                        !!formData.coupons[0].year_to_remove
                    ) {
                        setDisabledSaveButton(false);
                        setDisabledCancelButton(false);
                    } else {
                        setDisabledSaveButton(true);
                        setDisabledCancelButton(true);
                    }
                }
            }
        }, 100);
    }

    function handleResetCoupon() {
        setTimeout(function () {
            const formData = formRef.current.getData();

            if (subscription.discounts.length > 0) {
                formRef.current.setFieldValue(`coupons[0].coupon_id`, {
                    value: subscription.discounts[0].coupon_id,
                    label: coupons.find(
                        (c) => c.id === subscription.discounts[0].coupon_id
                    ).name,
                });

                formRef.current.setFieldValue(
                    'coupons[0].month_to_remove',
                    subscription.discounts[0].monthToRemove
                );

                formRef.current.setFieldValue(
                    'coupons[0].year_to_remove',
                    subscription.discounts[0].yearToRemove
                );
            } else {
                if (formData?.coupons && formData.coupons.length > 0) {
                    if (
                        !!formData.coupons[0].coupon_id &&
                        !!formData.coupons[0].month_to_remove &&
                        !!formData.coupons[0].year_to_remove
                    ) {
                        formRef.current.setFieldValue(`coupons[0].coupon_id`, {
                            value: null,
                            label: null,
                        });

                        formRef.current.setFieldValue(
                            'coupons[0].month_to_remove',
                            null
                        );

                        formRef.current.setFieldValue(
                            'coupons[0].year_to_remove',
                            null
                        );
                    }
                }
            }
        }, 100);
    }

    return (
        <>
            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-12">
                    <span>{texts.couponsText}</span>
                </FormGroup>
            </FormGroup>

            {fields.map((couponFields, index) => (
                <React.Fragment key={`${couponFields[0].key}`}>
                    <FormGroup row>
                        <Scope path={`coupons[${index}]`}>
                            {couponFields.map((field) => (
                                <FormGroup
                                    key={`${field.props.name}${field.key}`}
                                    className={field.className}
                                >
                                    <field.component
                                        {...field.props}
                                        onChange={() =>
                                            handleChangeCoupon(field.props.name)
                                        }
                                    />
                                </FormGroup>
                            ))}
                            <FormGroup>
                                <div className="col-xs-12 col-md-12 text-left d-flex align-items-end h-100">
                                    <Button
                                        color="danger"
                                        type="button"
                                        onClick={() =>
                                            handleRemoveCouponClick(index)
                                        }
                                        title={texts.deleteCouponText}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </div>
                            </FormGroup>
                        </Scope>
                    </FormGroup>
                </React.Fragment>
            ))}

            {showAddCouponButton && (
                <FormGroup row>
                    <div className="col-xs-12 col-md-12 text-left">
                        <Button
                            color="primary"
                            type="button"
                            onClick={handleAddCouponClick}
                            title={texts.addCouponText}
                        >
                            {texts.addCouponText}
                        </Button>
                    </div>
                </FormGroup>
            )}

            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button
                        color="primary mr-2"
                        type="button"
                        disabled={disabledSaveButton}
                        onClick={() => handleSaveCoupon()}
                    >
                        {texts.editText}
                    </Button>
                    <Button
                        type="button"
                        className="btn btn-secondary"
                        disabled={disabledCancelButton}
                        onClick={() => handleResetCoupon()}
                    >
                        {texts.cancelText}
                    </Button>
                </div>
            </FormGroup>
        </>
    );
}

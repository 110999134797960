import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { ptBR, enUS } from 'date-fns/locale';

import styles from './styles.module.css';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { currencyFormatter } from '../../../helpers/formatters';
import { setLoading } from '../../../store/actions/loadingActions';
import { setLocale as updateLocale } from '../../../store/actions/localeActions';
import { verifyRecaptchaToken } from '../../../services/api/register';
import { fetchPlanEvent } from '../../../services/api/register';
import { RegisterForm } from './components/form';

export default function RegisterEvent() {
    const { uuid, lang } = useParams();

    const pageDisabled = true;

    if (pageDisabled) {
        return <Redirect to={`/register/${lang}/Trial 30`} />;
    }

    let eventData = {};

    if (uuid === 'dee495f5-b373-330c-9a65-fbe0221e37e2') {
        eventData = {
            plan: {
                name: 'Standard',
                price_brl: 24999,
                price_usd: 12499,
                max_patients: 10,
                group: {
                    monthly: {
                        price_brl: 24999,
                        price_usd: 12499,
                    },
                    biannual: {
                        price_brl: 14999,
                        price_usd: 7499,
                    },
                    annual: {
                        price_brl: 12999,
                        price_usd: 6499,
                    },
                },
            },
            coupon: {
                id: 35,
                duration: 2,
                percent_off: 99.0,
            },
            hasDiscount: true,
        };
    }

    if (uuid === '33ed2176-5cd3-33fa-813c-a3e14135240f') {
        eventData = {
            plan: {
                name: 'Standard',
                price_brl: 24999,
                price_usd: 12499,
                max_patients: 10,
                group: {
                    monthly: {
                        price_brl: 24999,
                        price_usd: 0,
                    },
                    biannual: {
                        price_brl: 14999,
                        price_usd: 7499,
                    },
                    annual: {
                        price_brl: 12999,
                        price_usd: 6499,
                    },
                },
            },
            coupon: {
                id: 34,
                duration: 3,
                percent_off: 99.0,
            },
            hasDiscount: true,
        };
    }

    const [plan, setPlan] = useState(null);
    const [coupon, setCoupon] = useState(null);
    const [price, setPrice] = useState(null);
    const [planGroupPrices, setPlanGroupPrices] = useState({});
    const [signUpText, setSignUpText] = useState('');
    const [locale, setLocale] = useState({});

    const [cardNumberComplete, setCardNumberComplete] = useState(false);
    const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
    const [cardCvcComplete, setCardCvcComplete] = useState(false);

    const [filledInForm, setFilledInForm] = useState(false);
    const [formReady, setFormReady] = useState(false);
    const [notARobot, setNotARobot] = useState(false);

    const [loading, updateLoading] = useState(false);

    const dispatch = useDispatch();

    const pageData = pageConfig[lang];
    const { schema } = schemaConfig[lang];

    async function handleRecaptchaChange(value) {
        if (value !== null) {
            const response = await verifyRecaptchaToken({
                response: value,
            });
            if (response) {
                setNotARobot(true);
            }
        } else {
            setNotARobot(false);
        }
    }

    function createSignUpText(planName, formattedPrice, discountDuration) {
        return `${pageData.signUpText} ${planName} ${pageData.payOnlyText} ${formattedPrice}${pageData.byMonthText} ${discountDuration} ${pageData.monthsText}`;
    }

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));
            updateLoading(true);

            let tempLocale;

            if (lang === 'pt') {
                tempLocale = {
                    dateLocale: ptBR,
                    currencyLocale: 'pt-BR',
                    currencyFormat: 'BRL',
                };
            } else {
                tempLocale = {
                    dateLocale: enUS,
                    currencyLocale: 'en-US',
                    currencyFormat: 'USD',
                };
            }

            dispatch(updateLocale(tempLocale));

            setLocale(tempLocale);

            const data = await fetchPlanEvent(
                eventData.plan.name,
                eventData.coupon.id
            );

            if (data) {
                let tempPlanPrice;
                let tempDiscountPrice;
                let finalPrice;
                let finalPriceFormatted;
                let groupPricesFormatted;

                if (Object.entries(data?.plan).length > 0) {
                    tempPlanPrice =
                        lang == 'pt'
                            ? data?.plan?.price_brl
                            : data?.plan?.price_usd;

                    tempDiscountPrice =
                        (tempPlanPrice * data?.coupon?.percent_off) / 100;

                    finalPrice = tempPlanPrice - tempDiscountPrice;

                    finalPriceFormatted = currencyFormatter(
                        finalPrice,
                        tempLocale.currencyLocale,
                        tempLocale.currencyFormat
                    );

                    groupPricesFormatted = {
                        monthly: {
                            price:
                                lang == 'pt'
                                    ? data?.plan?.group?.monthly?.price_brl
                                    : data?.plan?.group?.monthly?.price_usd,
                            formattedPrice: currencyFormatter(
                                lang == 'pt'
                                    ? data?.plan?.group?.monthly?.price_brl
                                    : data?.plan?.group?.monthly?.price_usd,
                                tempLocale.currencyLocale,
                                tempLocale.currencyFormat
                            ),
                        },
                        biannual: {
                            price:
                                lang == 'pt'
                                    ? data?.plan?.group?.biannual?.price_brl
                                    : data?.plan?.group?.biannual?.price_usd,
                            formattedPrice: currencyFormatter(
                                lang == 'pt'
                                    ? data?.plan?.group?.biannual?.price_brl
                                    : data?.plan?.group?.biannual?.price_usd,
                                tempLocale.currencyLocale,
                                tempLocale.currencyFormat
                            ),
                        },
                        annual: {
                            price:
                                lang == 'pt'
                                    ? data?.plan?.group?.annual?.price_brl
                                    : data?.plan?.group?.annual?.price_usd,
                            formattedPrice: currencyFormatter(
                                lang == 'pt'
                                    ? data?.plan?.group?.annual?.price_brl
                                    : data?.plan?.group?.annual?.price_usd,
                                tempLocale.currencyLocale,
                                tempLocale.currencyFormat
                            ),
                        },
                    };

                    setPlan(data?.plan);

                    setCoupon(data?.coupon);

                    setPrice(finalPriceFormatted);

                    setPlanGroupPrices(groupPricesFormatted);
                } else {
                    tempPlanPrice =
                        lang == 'pt'
                            ? eventData.plan?.price_brl
                            : eventData.plan?.price_usd;

                    tempDiscountPrice =
                        (tempPlanPrice * eventData.coupon?.percent_off) / 100;

                    finalPrice = tempPlanPrice - tempDiscountPrice;

                    finalPriceFormatted = currencyFormatter(
                        finalPrice,
                        tempLocale.currencyLocale,
                        tempLocale.currencyFormat
                    );

                    groupPricesFormatted = {
                        monthly: {
                            price:
                                lang == 'pt'
                                    ? eventData.plan?.group?.monthly?.price_brl
                                    : eventData.plan?.group?.monthly?.price_usd,
                            formattedPrice: currencyFormatter(
                                lang == 'pt'
                                    ? eventData.plan?.group?.monthly?.price_brl
                                    : eventData.plan?.group?.monthly?.price_usd,
                                tempLocale.currencyLocale,
                                tempLocale.currencyFormat
                            ),
                        },
                        biannual: {
                            price:
                                lang == 'pt'
                                    ? eventData.plan?.group?.biannual?.price_brl
                                    : eventData.plan?.group?.biannual
                                          ?.price_usd,
                            formattedPrice: currencyFormatter(
                                lang == 'pt'
                                    ? eventData.plan?.group?.biannual?.price_brl
                                    : eventData.plan?.group?.biannual
                                          ?.price_usd,
                                tempLocale.currencyLocale,
                                tempLocale.currencyFormat
                            ),
                        },
                        annual: {
                            price:
                                lang == 'pt'
                                    ? eventData.plan?.group?.annual?.price_brl
                                    : eventData.plan?.group?.annual?.price_usd,
                            formattedPrice: currencyFormatter(
                                lang == 'pt'
                                    ? eventData.plan?.group?.annual?.price_brl
                                    : eventData.plan?.group?.annual?.price_usd,
                                tempLocale.currencyLocale,
                                tempLocale.currencyFormat
                            ),
                        },
                    };

                    setPlan(eventData.plan);

                    setCoupon(eventData.coupon);

                    setPrice(finalPriceFormatted);

                    setPlanGroupPrices(groupPricesFormatted);
                }

                setSignUpText(
                    createSignUpText(
                        eventData.plan.name,
                        finalPriceFormatted,
                        eventData.coupon.duration
                    )
                );
            }

            dispatch(setLoading(false));
            updateLoading(false);
        })();
    }, []);

    useEffect(() => {
        if (
            (cardNumberComplete &&
                cardExpiryComplete &&
                cardCvcComplete &&
                filledInForm) ||
            ((locale?.currencyLocale === 'pt-BR' ||
                plan?.name === 'Trial 30') &&
                filledInForm)
        ) {
            setCardNumberComplete(true);
            setCardCvcComplete(true);
            setCardExpiryComplete(true);
            setFormReady(true);
        } else {
            setFormReady(false);
            setNotARobot(false);
        }
    }, [cardNumberComplete, cardExpiryComplete, cardCvcComplete, filledInForm]);

    if (loading || plan === null) {
        return <></>;
    }

    return (
        <div className={styles.registerContainerEvent}>
            <header className={styles.registerHeaderContainerEvent}>
                <h1>{pageData.pageTitle}</h1>
                <h2>{pageData.pageSubtitle}</h2>
                <h4>{pageData.pageSubtitle2}</h4>
            </header>
            <main className={styles.registerMainContainerEvent}>
                <section className={`shadow ${styles.registerSectionEvent}`}>
                    <Card>
                        {eventData.hasDiscount && (
                            <CardHeader className={styles.cardHeaderEvent}>
                                <h3>{signUpText}!</h3>
                            </CardHeader>
                        )}
                        <CardBody>
                            <RegisterForm
                                pageData={pageData}
                                schema={schema}
                                plan={plan}
                                coupon={coupon}
                                price={price}
                                hasDiscount={eventData.hasDiscount}
                                setCardNumberComplete={setCardNumberComplete}
                                setCardExpiryComplete={setCardExpiryComplete}
                                setCardCvcComplete={setCardCvcComplete}
                                setFilledInForm={setFilledInForm}
                                formReady={formReady}
                                handleRecaptchaChange={handleRecaptchaChange}
                                notARobot={notARobot}
                                lang={lang}
                                locale={locale}
                            />
                        </CardBody>
                        <CardFooter>
                            <div className={styles.pricesEvent}>
                                <p>
                                    <b style={{ color: 'darkred' }}>*</b>{' '}
                                    {pageData.requiredFieldsText}!
                                </p>
                                {eventData.hasDiscount && (
                                    <>
                                        <p>
                                            <b style={{ color: 'darkred' }}>
                                                **
                                            </b>{' '}
                                            {
                                                pageData.planValuesAfterDiscountText
                                            }{' '}
                                            {coupon?.duration}{' '}
                                            {pageData.monthsText}:
                                        </p>
                                        <ul>
                                            {planGroupPrices?.monthly
                                                ?.price && (
                                                <li>
                                                    {
                                                        planGroupPrices?.monthly
                                                            .formattedPrice
                                                    }{' '}
                                                    {pageData.noLoyaltyText}.
                                                </li>
                                            )}
                                            {planGroupPrices?.biannual
                                                ?.price && (
                                                <li>
                                                    {
                                                        planGroupPrices
                                                            ?.biannual
                                                            .formattedPrice
                                                    }{' '}
                                                    {
                                                        pageData.sixMonthLoyaltyText
                                                    }
                                                    .
                                                </li>
                                            )}
                                            {planGroupPrices?.annual?.price && (
                                                <li>
                                                    {
                                                        planGroupPrices?.annual
                                                            .formattedPrice
                                                    }{' '}
                                                    {
                                                        pageData.oneYearLoyaltyText
                                                    }
                                                    .
                                                </li>
                                            )}
                                        </ul>
                                    </>
                                )}
                            </div>
                        </CardFooter>
                    </Card>
                </section>
            </main>
            <footer className={styles.registerFooterEvent}>
                <div>
                    <p>
                        <img src="/assets/logo.png" alt="Logo Condor" />{' '}
                        {pageData.pageFooter}
                    </p>
                </div>
            </footer>
        </div>
    );
}

import * as Yup from 'yup';

export default {
    pt: {
        schema: Yup.object().shape({
            otp_code: Yup.string().required('Campo obrigatório'),
        }),
    },
    en: {
        schema: Yup.object().shape({
            otp_code: Yup.string().required('Required field'),
        }),
    },
};
